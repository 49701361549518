@charset 'utf-8';
.r-pick{
    &__list{
        margin-bottom: 30px;
    }
    &__item{
        list-style:none;
        margin-bottom: 40px;
        a{
            position: relative;
            &::after{
                position: absolute;
                right: 10px;
                bottom: 10px;
                @include media-breakpoint-down(sm) {
                    right: 5px;
                }
            }
        }
    }
    &__link{
        text-decoration: none;
        display: flex;
        align-items: center;
        background: $color-gray-light2;
        @include media-breakpoint-down(sm) {
            display: block;
        }
        figure{
            margin-bottom: 0;
            flex: 0 0 50%;
            img{
                width: 100%;
                min-height: 270px;
                object-fit: cover;
                aspect-ratio: 2/1;
            }
        }
    }
    &__text{
        &_box{
            flex: 0 0 50%;
            padding: 30px 70px 30px 30px;
            position: relative;
            @include media-breakpoint-down(sm) {
                padding: 20px 45px 20px 20px;
            }
            &-head{
                .text{
                    @include fz(2.4rem);
                    font-weight: bold;
                }
                border-bottom: 1px solid $color-gray-dark;
            }
            &-bottom{
                .gakubu{
                    border-right: 1px solid $color-gray-dark;
                    border-left: 1px solid $color-gray-dark;
                    padding: 0 5px;
                    margin: 0 5px;
                }
                .name{

                }
            }
            &::after{
                content: "";
                background-image: url(/research_pickup/img/arrow.png);
                background-repeat: no-repeat;
                background-size: 100%;
                width: 45px;
                height: 45px;
                display: block;
                position: absolute;
                top: 50%;
                right: 0;
                transform: translateY(-50%);
                @include media-breakpoint-down(sm) {
                    width: 30px;
                    height: 30px;
                }
            }
        }
    }


    &.detail{
        main.contents{
            padding-top: 130px!important;
        @include media-breakpoint-down(sm) {
            padding-top: 80px!important;
        }
            
        }
        .r-pick{
            &__mv{
                img{
                    width: 100%;
                }
            }
            &__ttlbox{
                background: $color-white;
                padding: 50px;
                max-width: 1200px;
                margin: -50px auto 0;
                position: relative;
                z-index: 1;
                @include media-breakpoint-down(sm) {
                    margin: -30px auto 0;
                    padding: 20px;
                    width: 90%;
                }
                .r-pick__text_box-bottom{
                    .name{
                        display: block;
                        @include fz(1.8rem,1.6rem);
                        font-weight: bold;
                        color: $color-main;
                    }
                    .post-sys__date{
                        color: $color-black;
                        @include fz(1.6rem);
                        margin-right: 0px;
                    }
                    .gakubu{
                        border-right: none;
                    }
                }
            }
            &__ttl{
                @include fz(3.4rem,2.4rem);
                @include ff-serif();
                font-weight: bold;
            }
        }
    }
}