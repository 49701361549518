@charset 'utf-8';
.gfooter {
	position: relative;
	z-index: 1;
	background-color: $color-white;
	border-top: 1px solid $color-gray-light;
	a{
		text-decoration: none;
	}
	&-link{
		position: relative;
		padding-left: 15px;
		display: inline-block;
		&::before{
			font-family: fontawesome;
			content: "\f105";
			position: absolute;
			color: $color-black;
			left: 0;
			top: 50%;
			transform: translateY(-50%);
		}
	}
	&__logo{
		max-width: 200px;
		margin-right: 60px;
		@include media-breakpoint-down(sm) {
			margin: 0 auto 20px;
			margin-right: auto;
		}
	}
	&__sns{
		text-align: center;
		img{
			max-width: 40px;
			margin: 0 5px;
		}
		&-box{
			display: flex;
			justify-content: space-between;
			margin: 40px 0 20px;
			@include media-breakpoint-down(sm) {
				display: block;
			}
		}
		&-block{
		
		}
		&-head{
			display: flex;
			align-items: center;
			margin-bottom: 40px;
			@include media-breakpoint-down(sm) {
				display: block;
			}
		}
		&-btn{
			text-decoration: none;
			border: 1px solid $color-black;
			padding: 3px 15px;
			border-radius: 30px;
			display: flex;
			align-items: center;
			@include media-breakpoint-down(sm) {
				display: flex;
				justify-content: center;
				width: 120px;
				margin: 20px auto 0;
			}
			&::before{
				font-family: fontawesome;
				content: "\f105";
				color: $color-black;
				margin-right: 5px;
				height: 29px;
			}
		}
	}
	&__mainmenu{
		&-inner{
			display: flex;
			align-items: flex-start;
	
			@include media-breakpoint-down(sm) {
				display: block;
			
			}
		}
		&-list{
			display: flex;
			list-style: none;
			flex-wrap: wrap;
		
			@include media-breakpoint-down(sm) {
				width: 100%;
				justify-content: space-between;
			}
		}
		&-item{
			width: 25%;
			margin-bottom: 10px;
			@include media-breakpoint-down(md) {
				width: auto;
				margin-right: 30px;
			}
			@include media-breakpoint-down(sm) {
				width: 50%;
				margin-right: 0px;
			}
		}
		&-subblock{
			width: 25%;
			@include media-breakpoint-down(sm) {
				width: 100%;
				margin-top: 10px;
			}
		}
		&-sublist{
			list-style: none;
			@include media-breakpoint-down(sm) {
				display: flex;
				flex-wrap: wrap;
				justify-content: space-between;
				margin-top: 10px;
			}
		}
		&-subitem{
			margin-bottom: 10px;
			@include media-breakpoint-down(sm) {
				width: 50%;
			}
		}
		&-subtitle{
			@include fz(1.4rem);
			font-weight: bold;
			border-bottom: 1px solid $color-black;
			padding-bottom: 5px;
			margin-bottom: 5px;
		}
	}

	&__school{
		background: $color-gray-light2;
		padding-top: 20px;
		padding-bottom: 20px;
		&-inner{
			width: 100%;
			margin: 0 auto;
			max-width:1200px;
			@include media-breakpoint-down(sm) {
				width: 100%;
			}
		}
		&-title{
			font-weight: bold;
			padding-bottom: 10px;
			text-align: center;
			margin-bottom: 10px;
			border-bottom: 2px solid $color-white;
			@include media-breakpoint-down(sm) {
				text-align: left;
			}
		}
		&-list{
			list-style: none;
			display: flex;
			justify-content: center;
			gap: 30px;
			flex-wrap: wrap;
			@include media-breakpoint-down(sm) {
				justify-content: start;
				gap: 0px;
			}
		}
		&-item{
			@include media-breakpoint-down(sm) {
				width: 50%;
				margin-top: 10px;
			}
		}
	}

	&__policy{
		margin: 40px 0 30px;
		@include fz(1.4rem);
		@include media-breakpoint-down(sm) {
			margin: 20px 0 30px;
		}
		&-list{
			list-style: none;
			display: flex;
			justify-content: center;
			align-items: center;
			@include media-breakpoint-down(sm) {
				display: block;
				text-align: center;
			}
		}
		&-item{
			border-right: 1px solid $color-black;
			padding: 0 20px;
			@include media-breakpoint-down(sm) {
				border-right: none;
				margin-bottom: 10px;
			}
			&:last-child{
				border-right: none;
			}
			&.style2{
				padding: 0;
				a{
					border: 1px solid $color-black;
					border-radius: 30px;
					padding: 5px 8px;
					display: inline-block;
					text-align: center;
				}
				&-prev{
					border-right: none;
				}
			}
		}
	}



	//英語サイト
	&.en{
		.gfooter__sns{
			&-block{
				@include media-breakpoint-down(sm) {
					width: 100%;
				}
			}
			&-head{
				margin: 40px 0 20px;
				@include media-breakpoint-down(sm) {
					
				}
			}
		}
		.gfooter__mainmenu{
			&-item{
				@include media-breakpoint-down(sm) {
					width: 50%;
				}
			}
			&-sublist{
				@include media-breakpoint-down(sm) {
					display: block;
				}
			}
			&-subitem{
				@include media-breakpoint-down(sm) {
					width: 100%;
					margin: 30px 0;
				}
			}
		}
		.gfooter__policy{
			@include media-breakpoint-down(sm) {
				margin-bottom: 60px;
			}
		}
	}
}

.copy {
	text-align: center;
	font-size: 1.1rem;
	background-color: $color-main;
	color: $color-white;
	padding: 10px 0;
}

.pagetop {
	position: fixed;
		bottom: 40px;
		right: 30px;
		z-index: 9999;
		width: 40px;
		height: 40px;
		overflow: hidden;
		transition: .3s;
		opacity: 0;
		visibility: hidden;
		@include media-breakpoint-down(sm) {
			bottom: 60px;
			right: 10px;
		}
		&.active{
			opacity: 1;
			visibility: visible;
		}
		a {
			position: relative;
			background-color: $color-white;
			border: 1px solid $color-main;
			width: 100%;
			height: 100%;
			border-radius: 50%;
			display: block;
			padding: .5em 15px;
			text-align: center;
			&::before {
				content: '';
				background-image: url(/img/common/pagetop_arrow.svg);
				background-repeat: no-repeat;
				display: inline-block;
				width: 10px;
				height: 15px;
				position: absolute;
				top: 50%;
				left: 50%;
				transform: translate(-50%,-50%);
			}
		}
	@include media-breakpoint-up(md) {
		
	}
}





.en{
	.gfooter__sns-box{
		align-items: flex-end;
	}
	.gfooter__mainmenu-subblock{
		width: auto!important;
	}
	.gfooter__sns-block{
		width: 70%;
	}
	.gfooter__mainmenu-item{
		width: 16%;
	}
}
