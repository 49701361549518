@charset 'utf-8';
//----
// font-family関連
// [thin:100][extra-light:200][light:300][regular:400][medium:500][semi-bold:600][bold:700][black:900]

$font-family-en-gothic: sans-serif !default;
$font-family-en-mincho: serif !default;
// --------

@mixin ff-en($weight: 400) {
  font-family: $font-family-en-serif;
  font-weight: $weight;
}
@mixin ff-barlow($weight: 400) {
  font-family: $font-family-barlow;
  font-weight: $weight;
}
@mixin ff-serif($weight: 400) {
  font-family: $font-family-serif;
  font-weight: $weight;
}
@mixin ff-gothic($weight: 400) {
  font-family: $font-family-sans-serif;
  font-weight: $weight;
}
@mixin ff-icon() {
  font-family: "FontAwesome";
}


// --------
@mixin fz($fz_pc: 1.6rem, $fz_sp: $fz_pc) {
  @include media-breakpoint-up(md) {
    font-size: $fz_pc;
  }
  @include media-breakpoint-down(sm) {
    font-size: $fz_sp;
  }
}
// --------
@mixin my($mt_pc: 30px, $mt_sp: $mt_pc) {
  @include media-breakpoint-up(md) {
    margin-top: $mt_pc;
    margin-bottom: $mt_pc;
  }
  @include media-breakpoint-down(sm) {
    margin-top: $mt_sp;
    margin-bottom: $mt_sp;
  }
}
@mixin mt($mt_pc: 30px, $mt_sp: $mt_pc) {
  @include media-breakpoint-up(md) {
    margin-top: $mt_pc;
  }
  @include media-breakpoint-down(sm) {
    margin-top: $mt_sp;
  }
}
@mixin mb($mb_pc: 30px, $mb_sp: $mb_pc) {
  @include media-breakpoint-up(md) {
    margin-bottom: $mb_pc;
  }
  @include media-breakpoint-down(sm) {
    margin-bottom: $mb_sp;
  }
}
// --------
@mixin py($pt_pc: 30px, $pt_sp: $pt_pc) {
  @include media-breakpoint-up(md) {
    padding-top: $pt_pc;
    padding-bottom: $pt_pc;
  }
  @include media-breakpoint-down(sm) {
    padding-top: $pt_sp;
    padding-bottom: $pt_sp;
  }
}
@mixin pt($pt_pc: 30px, $pt_sp: $pt_pc) {
  @include media-breakpoint-up(md) {
    padding-top: $pt_pc;
  }
  @include media-breakpoint-down(sm) {
    padding-top: $pt_sp;
  }
}
@mixin pb($pb_pc: 30px, $pb_sp: $pb_pc) {
  @include media-breakpoint-up(md) {
    padding-bottom: $pb_pc;
  }
  @include media-breakpoint-down(sm) {
    padding-bottom: $pb_sp;
  }
}
//----
// text-align: justify;
// https://qiita.com/nabe_kurage/items/ddd1659f1798217d198f
// https://caniuse.com/#search=text-justify
// @include taj();
@mixin taj() {
  text-align: justify;
  text-justify: inter-ideograph; // for IE
}

// list__resetは下位に「!important」が必要なので使い勝手が悪い
// 「/vendor/bootstrap/mixins/_lists.scss#list-unstyled」でも良いかも？
@mixin list-reset {
  margin: 0;
  padding: 0;
  list-style: none;
}
@mixin btn-reset {
  background-color: transparent;
  border: none;
  cursor: pointer;
  outline: none;
  padding: 0;
  appearance: none;
}
@mixin input-reset {
  background-color: $color-white;
  border: 1px solid $color-gray;
  border-radius: 0;
  padding: 0;
  min-width: 0; // for Firefox padding
  appearance: none;
}
@mixin line-clamp($line: 1) {
  @if $line==1 {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  } @else {
    overflow: hidden;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: $line;
  }
}
@mixin nohover {
  @include hover(1, none);
}
@mixin hover($opacity: 0.6, $decoration: none) {
  transition: all 0.2s linear;
  color: currentColor;
  &:hover {
    color: currentColor;
    text-decoration: $decoration;
    opacity: $opacity;
  }
}
@mixin imghover {
  @include hover(1, none);
  figure{
    overflow: hidden;
  }
  img{
    transition: all 0.6s;
  }
  &:hover {
    a{
      opacity: 1;
    }
    img{
      transform: scale(1.1);
    }
  }
}
// 通常はz-indexの関係上afterが良い。（※外部リンクやファイルアイコンと被る場合のみbeforeで代用可）
@mixin stretched-link($PseudoElement: after) {
  &::#{$PseudoElement} {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: 2;
    pointer-events: inherit;
    content: "";
    background-color: rgba($color-white, 0);
  }
}
@mixin after-border($direction: bottom, $width: 10px, $height: 10px, $bgcolor: $color-black, $PseudoElement: after) {
  position: relative;
  &::#{$PseudoElement} {
    content: "";
    display: block;
    position: absolute;
    z-index: 1;
    width: $width;
    height: $height;
    background: $bgcolor;
    @if $direction==top {
      top: 0;
      left: 50%;
      @if $width!=1px {
        transform: translateX(-50%);
      }
    } @else if $direction==top_over {
      top: auto;
      bottom: 100%;
      left: 50%;
      @if $width!=1px {
        transform: translateX(-50%);
      }
    } @else if $direction==top-left {
      top: 0;
      left: 0;
    } @else if $direction==top-right {
      top: 0;
      right: 0;
    } @else if $direction==top_over-left {
      top: auto;
      bottom: 100%;
      left: 0;
    } @else if $direction==top_over-right {
      top: auto;
      bottom: 100%;
      right: 0;
    } @else if $direction==bottom {
      bottom: 0;
      left: 50%;
      @if $width!=1px {
        transform: translateX(-50%);
      }
    } @else if $direction==bottom_over {
      top: 100%;
      bottom: auto;
      left: 50%;
      @if $width!=1px {
        transform: translateX(-50%);
      }
    } @else if $direction==bottom-left {
      bottom: 0;
      left: 0;
    } @else if $direction==bottom-right {
      bottom: 0;
      right: 0;
    } @else if $direction==bottom_over-left {
      top: 100%;
      bottom: auto;
      left: 0;
    } @else if $direction==bottom_over-right {
      top: 100%;
      bottom: auto;
      right: 0;
    } @else if $direction==left {
      left: 0;
      top: 50%;
      @if $height!=1px {
        transform: translateY(-50%);
      }
    } @else if $direction==left_over {
      left: auto;
      right: 100%;
      top: 50%;
      @if $height!=1px {
        transform: translateY(-50%);
      }
    } @else if $direction==right {
      right: 0;
      top: 50%;
      @if $height!=1px {
        transform: translateY(-50%);
      }
    } @else if $direction==right_over {
      left: 100%;
      right: auto;
      top: 50%;
      @if $height!=1px {
        transform: translateY(-50%);
      }
    } @else if $direction==center {
      left: 50%;
      top: 50%;
      transform: translate(-50%, -50%);
    }
  }
}

@mixin after-border-inline($width: 10px, $height: 10px, $margin-left: 10px, $margin-right: 10px, $bgcolor: $color-black, $PseudoElement: after) {

  display: flex;
  align-items: center;
  &::#{$PseudoElement} {
    content: "";
    width: $width;
    height: $height;
    background-color: $bgcolor;
    margin-right: $margin-left;
    margin-left: $margin-right;
  }
}
//----
// https://fontawesome.com/v4.7.0/icons/
@mixin arrow_direction($direction: right) {
  @if $direction==left {
    content: "\f053"; // 左矢印(fa-chevron-left)(fa-angle-left:f104)
  } @else if $direction==left_slim {
    content: "\f104"; // 左矢印(fa-chevron-left)(fa-angle-left:f104)
  } @else if $direction==right {
    content: "\f054"; // 右矢印(fa-chevron-right)(fa-angle-right:f105)(fa-chevron-circle-right:f138)
  } @else if $direction==right_slim {
    content: "\f105"; // 右矢印(fa-chevron-right)(fa-angle-right:f105)(fa-chevron-circle-right:f138)
  } @else if $direction==right2 {
    content: "\f138"; // 右矢印(fa-chevron-right)(fa-angle-right:f105)(fa-chevron-circle-right:f138)
  } @else if $direction==top {
    content: "\f077"; // 上矢印(fa-chevron-up)(fa-angle-up:Unicode:f106)
  } @else if $direction==bottom {
    content: "\f078"; // 下矢印(fa-chevron-down)(fa-angle-down:f107)
  } @else if $direction==bottom_slim {
    content: "\f107"; // 下矢印(fa-chevron-down)(fa-angle-down:f107)
  } @else if $direction==mail {
    content: "\f003 "; // 下矢印(fa-envelope-o)(fa-envelope:f0e0)
  } @else if $direction==pdf {
    content: "\f1c1"; // PDF(fa-file-pdf-o)
  } @else if $direction==plus {
    content: "\f067"; // +(fa-plus)
  } @else if $direction==plus_slim {
    content: "＋"; // ＋
    font-family: $font-family-sans-serif;
  } @else if $direction==minus {
    content: "\f068"; // -(fa-minus)
  } @else if $direction==minus_slim {
    content: "ー"; // ＋
    font-family: $font-family-sans-serif;
  }
}
@mixin after_arrow($position: right, $direction: right, $parent_position: true, $pos: 10px) {
  $PseudoElement: before;
  @if $position==right {
    $PseudoElement: after;
  }
  @if $parent_position {
    position: relative;
    z-index: 1;
  }
  &::#{$PseudoElement} {
    color: currentColor;
    font-family: fontawesome;
    @include arrow_direction($direction);
    display: inline-block; // for Edge+IE
    @at-root .ie11 & {
      display: inline-block !important;
      text-decoration: none;
    }
    position: absolute;
    z-index: 1;
    @if $position==right {
      top: 50%;
      transform: translateY(-50%);
      right: $pos;
    } @else if $position==left {
      top: 50%;
      transform: translateY(-50%);
      left: $pos;
    } @else if $position==top {
      left: 50%;
      transform: translateX(-50%);
      top: $pos;
    } @else if $position==bottom {
      left: 50%;
      transform: translateX(-50%);
      bottom: $pos;
    }
    transition: 0.2s;
  }
}
@mixin arrow_flex($position: left, $direction: right, $color: currentColor) {
  $PseudoElement: before;
  @if $position==right {
    $PseudoElement: after;
  }
  display: flex;
  align-items: center;
  color: currentColor;
  &:hover {
    color: currentColor;
    text-decoration: none;
  }
  &::#{$PseudoElement} {
    color: $color;
    font-family: fontawesome;
    @include arrow_direction($direction);
    text-decoration: none;
    @at-root .ie11 & {
      display: inline-block !important;
      text-decoration: none;
    }
    @if $position==left {
      margin-right: 0.5em;
    } @else {
      margin-left: 0.5em;
    }
    @if str-index($direction, "_slim") {
      font-size: 1em;
    } @else {
      font-size: 1rem;
    }
    transition: 0.2s;
  }
}
@mixin arrow_inline_flex($position: left, $direction: right, $color: currentColor) {
  @include arrow_flex($position, $direction, $color);
  display: inline-flex;
}
@mixin after_arrow_inline($position: right, $direction: right) {
  @if $position==right or $position==bottom {
    &::before {
      order: 2;
    }
    &::after {
      order: 1;
    }
  }
  @if $position==top or $position==bottom {
    flex-direction: column;
  }
  &::before {
    color: currentColor;
    font-family: fontawesome;
    @include arrow_direction($direction);
    display: inline-block; // for Edge+IE
    @at-root .ie11 & {
      display: inline-block !important;
      text-decoration: none;
    }
    @if $position==left {
      margin-right: 0.5em;
    } @else if $position==right {
      margin-right: 0.5em;
      margin-left: 0.5em;
    }
    font-size: 1rem;
    transition: 0.2s;
  }
}
@mixin after_arrow_img($position: right, $width: "", $height: "", $img: "", $pos:10px) {
  position: relative;
  z-index: 1;
  &::after {
    content: "";
    display: block;
    position: absolute;
    z-index: 1;
    width: $width;
    height: $height;
    @if str-index($img, "") >0 {
      background: $img no-repeat;
    } @else {
      background: url($img) no-repeat;
    }
    top: 50%;
    transform: translateY(-50%);
    @if $position==right {
      right: $pos;
    } @else {
      left: $pos;
    }
    transition: 0.2s;
  }
}
@mixin after_arrow_img_inline($position: right, $width: "", $height: "", $img: "") {
  &::after {
    content: "";
    display: inline-block;
    z-index: 1;
    width: $width;
    height: $height;
    @if str-index($img, "") >0 {
      background: $img no-repeat;
    } @else {
      background: url($img) no-repeat;
    }
    transition: 0.2s;
  }
}
@mixin arrow_inline($direction: right, $PseudoElement: before) {
  &::#{$PseudoElement} {
    font-family: fontawesome;
    @include arrow_direction($direction);
    font-weight: normal;
    display: inline-block;
    //font-size: smaller;
    @if ($PseudoElement==before) {
      margin-right: 0.3em;
    } @else {
      margin-left: 0.3em;
    }
  }
}
//----
// @mixin pblock() {
//   width: auto;
//   max-width: $content-inner;
//   margin-inline: auto;
// }
// @mixin pblock-slim() {
//   width: auto;
//   max-width: $content-inner-slim;
//   margin-inline: auto;
// }
// @mixin pblock-wide() {
//   width: auto;
//   max-width: $content-inner-wide;
//   margin-inline: auto;
// }
// @mixin pblock-full() {
//   width: auto;
//   max-width: none;
//   margin-inline: auto;
// }
// @mixin pblock-over($position: full) {
//   @if $position==full {
//     margin-inline: calc(-50vw + 50%);
//   } @else if $position==left {
//     margin-left: calc(-50vw + 50%);
//   } @else if $position==right {
//     margin-right: calc(-50vw + 50%);
//   }
// }
// @mixin sp-full-margin($bgcolor: "") {
//   @if ($bgcolor!= "") {
//     background: $bgcolor;
//   }
//   @include media-breakpoint-up(md) {
//   }
//   @include media-breakpoint-down(sm) {
//     margin-inline: -20px;
//     @if ($bgcolor!= "") {
//       padding-inline: 20px;
//     }
//   }
// }
@mixin media-sp {
  @media screen and (max-width: 767px) {
    @content;
  }
}
@mixin media-pc {
  @media print, screen and (min-width: 768px) {
    @content;
  }
}
@mixin media-min-pc {
  @media print, screen and (min-width: 769px) and (max-width: 1355px) {
    @content;
  }
}

@include media-pc {
  .sp_only {
    display: none !important;
  }
}
@include media-sp {
  .pc_only {
    display: none !important;
  }
}

// ex)
//   ul.xxx__list>li.xxx__item>a.xxx>img.xxx
// xxx__list {
//   @include list-reset();
//   @include flex-between(3, 30px, 30px, 1, 15px, 15px);
//   @include flex-start(3, 30px, 30px, 1, 15px, 15px);
// }
@mixin flex-start($pc-cols: 0, $pc-margin: 0, $pc-margin-bottom: 0, $sp-cols: 0, $sp-margin: 0, $sp-margin-bottom: 0, $wrap: wrap) {
  @include flex-base(flex-start, $pc-cols, $pc-margin, $pc-margin-bottom, $sp-cols, $sp-margin, $sp-margin-bottom, $wrap);
}
@mixin flex-between($pc-cols: 0, $pc-margin: 0, $pc-margin-bottom: 0, $sp-cols: 0, $sp-margin: 0, $sp-margin-bottom: 0, $wrap: wrap) {
  @include flex-base(space-between, $pc-cols, $pc-margin, $pc-margin-bottom, $sp-cols, $sp-margin, $sp-margin-bottom, $wrap);
}
@mixin flex-base($flex-type, $pc-cols: 0, $pc-margin: 0, $pc-margin-bottom: 0, $sp-cols: 0, $sp-margin: 0, $sp-margin-bottom: 0, $wrap: wrap) {
  display: flex;
  justify-content: $flex-type;
  @if $pc-cols>0 {
    @include media-breakpoint-up(md) {
      @if $wrap==wrap {
        flex-wrap: wrap;
      }
      > * {
        @if $pc-cols==1 {
          width: 100%;
        } @else if $pc-cols>=2 {
          @if $wrap==wrap and $pc-margin==0 {
            width: calc((100% - #{$pc-margin} * #{$pc-cols - 1}) / #{$pc-cols} - 0.1px); // Edgeも場合によっては段落ちするので-0.1px
          } @else {
            width: calc((100% - #{$pc-margin} * #{$pc-cols - 1}) / #{$pc-cols});
          }
          @if $flex-type==flex-start {
            @at-root .ie11 & {
              width: calc((100% - #{$pc-margin} * #{$pc-cols - 1}) / #{$pc-cols} - 0.1px);
            }
            &:not(:nth-child(#{$pc-cols}n)) {
              margin-right: #{$pc-margin};
            }
          }
        }
        @if $pc-margin-bottom>0 {
          &:nth-last-child(n + #{$pc-cols + 1}) {
            margin-bottom: $pc-margin-bottom;
          }
        }
        img {
          width: 100%;
        }
      }
    }
  }
  @if $sp-cols>0 {
    @include media-breakpoint-down(sm) {
      @if $wrap==wrap {
        flex-wrap: wrap;
      }
      > * {
        @if $sp-cols==1 {
          width: 100%;
        } @else if $sp-cols>=2 {
          @if $wrap==wrap and $sp-margin==0 {
            width: calc((100% - #{$sp-margin} * #{$sp-cols - 1}) / #{$sp-cols} - 0.1px); // Edgeも場合によっては段落ちするので-0.1px
          } @else {
            width: calc((100% - #{$sp-margin} * #{$sp-cols - 1}) / #{$sp-cols});
          }
          @if $flex-type==flex-start {
            @at-root .ie11 & {
              width: calc((100% - #{$sp-margin} * #{$sp-cols - 1}) / #{$sp-cols} - 0.1px);
            }
            &:not(:nth-child(#{$sp-cols}n)) {
              margin-right: #{$sp-margin};
            }
          }
        }
        @if $sp-margin-bottom>0 {
          &:nth-last-child(n + #{$sp-cols + 1}) {
            margin-bottom: $sp-margin-bottom;
          }
        }
        img {
          width: 100%;
        }
      }
    }
  } @else {
    @include media-breakpoint-down(sm) {
      flex-wrap: wrap;
      margin-bottom: $sp-margin-bottom * -1;
      *:not(:last-child) {
        margin-right: #{$sp-margin};
        margin-bottom: $sp-margin-bottom;
      }
    }
  }
}
@mixin flex-center($direction: row) {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: $direction;
}
@mixin v-center {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
}
@mixin h-center {
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
}
// position: relative;
// &::before {
//   @include cover-fill(rgba($color-base-main, 0.1));
// }
@mixin cover-fill($fill-color: transparent) {
  content: "";
  display: block;
  position: absolute;
  z-index: 1;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: $fill-color;
}
@mixin cover-fill-bg($bgcolor: #fff) {
  // position: relative;
  &::after {
    content: "";
    display: block;
    position: absolute;
    z-index: -1;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    width: 500%;
    transform: translateX(-50%);
    background: $bgcolor;
  }
}
@mixin box-center($posx: center, $posy: center) {
  position: absolute;
  @if $posx==center and $posy==center {
    top: 50%;
    left: 50%;
    bottom: auto;
    right: auto;
    transform: translate(-50%, -50%);
  } @else {
    @if $posx==left {
      left: 0;
      right: auto;
    } @else if $posx==center {
      left: 50%;
      right: auto;
      transform: translateX(-50%);
    } @else if $posx==right {
      left: auto;
      right: 0;
    } @else if $posx==left_over {
      left: auto;
      right: 100%;
    } @else if $posx==right_over {
      left: 100%;
      right: auto;
    }
    @if $posy==top {
      top: 0;
      bottom: auto;
    } @else if $posy==center {
      top: 50%;
      bottom: auto;
      transform: translateY(-50%);
    } @else if $posy==bottom {
      top: auto;
      bottom: 0;
    } @else if $posy==top_over {
      top: auto;
      bottom: 100%;
    } @else if $posy==bottom_over {
      top: 100%;
      bottom: auto;
    }
  }
}
// ex)
// xxx {
//   position: relative;
//   &::after {
//     @include triangle(bottom, $color-base-main, 20px, 10px);
//     @include box-center(center, bottom);
//     bottom: -25px;
//   }
// }
@mixin triangle($direction: left, $color: #999, $width: 10px, $height: 0) {
  $pos: (0, 0, 0, 0);
  $clr: (transparent, transparent, transparent, transparent);
  @if $direction==left {
    @if $height==0 {
      $height: $width / 1.732 * 2;
      $height: ceil($height * 10) / 10;
    }
    $pos: ($height / 2, $width, $height / 2, 0);
    $clr: (transparent, $color, transparent, transparent);
  } @else if $direction==top {
    @if $height==0 {
      $height: $width * 1.732 / 2;
      $height: ceil($height * 10) / 10;
    }
    $pos: (0, $width / 2, $height, $width / 2);
    $clr: (transparent, transparent, $color, transparent);
  } @else if $direction==right {
    @if $height==0 {
      $height: $width / 1.732 * 2;
      $height: ceil($height * 10) / 10;
    }
    $pos: ($height / 2, 0, $height / 2, $width);
    $clr: (transparent, transparent, transparent, $color);
  } @else if $direction==bottom {
    @if $height==0 {
      $height: $width * 1.732 / 2;
      $height: ceil($height * 10) / 10;
    }
    $pos: ($height, $width / 2, 0, $width / 2);
    $clr: ($color, transparent, transparent, transparent);
  }
  display: block;
  content: "";
  width: 0;
  height: 0;
  border-style: solid;
  border-width: nth($pos, 1) nth($pos, 2) nth($pos, 3) nth($pos, 4);
  border-color: nth($clr, 1) nth($clr, 2) nth($clr, 3) nth($clr, 4);
}

// supports
// @include supports(backdrop-filter, blur(1px)) {
//   backdrop-filter: blur(1px);
// }
// @include supports(filter, drop-shadow(0px, 0px, 2px, #fff)) {
//   filter: drop-shadow(0px, 0px, 2px, #fff);
// }
@mixin supports($property, $value) {
  $style: "#{$property} : #{$value}";
  $style: "(#{$style})";

  @supports (#{$style}) {
    #{$property}: #{$value};
    @content;
  }
}

// supports not
@mixin unsupports($property, $value) {
  $style: "#{$property} : #{$value}";
  $style: "(#{$style})";

  @supports not (#{$style}) {
    @content;
  }
}
@mixin text-shadow($offset: 1px, $blur-radius: 0px, $shadow-color: $color-white) {
  text-shadow: #{$offset * 1} #{$offset * 1} $blur-radius $shadow-color, #{$offset * -1} #{$offset * 1} $blur-radius $shadow-color, #{$offset * 1} #{$offset * -1} $blur-radius $shadow-color, #{$offset * -1} #{$offset * -1} $blur-radius $shadow-color;
}

@mixin bg-ptn-frame($color-ptn1: $color-white, $frame-width: 50px, $frame-border-width: 1px, $frame-height: $frame-width, $frame-border-height: $frame-border-width) {
  background-image: linear-gradient($color-ptn1, $color-ptn1), linear-gradient($color-ptn1, $color-ptn1), linear-gradient($color-ptn1, $color-ptn1), linear-gradient($color-ptn1, $color-ptn1), linear-gradient($color-ptn1, $color-ptn1), linear-gradient($color-ptn1, $color-ptn1), linear-gradient($color-ptn1, $color-ptn1), linear-gradient($color-ptn1, $color-ptn1);
  background-position: left top, left top, right top, right top, left bottom, left bottom, right bottom, right bottom;
  background-size: $frame-border-height $frame-height, $frame-width $frame-border-width;
  background-repeat: no-repeat;
}
