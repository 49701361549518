@charset 'utf-8';
.page.research {

	.research {
		&__achievement {
			&-list {
				display: flex;
				margin: -20px;
				@include media-breakpoint-down(sm) {
					display: block;
					margin: -10px 0;
				}
				&_item {
					width: 33.3333%;
					padding: 20px;
					text-align: center;
					@include media-breakpoint-down(sm) {
						width: 100%;
						padding: 10px 20px;
					}
				}
			}
			&-notes {
				text-align: center;
				&_list {
					display: inline-block;
					list-style: none;
					text-align: left;
				}
			}
		}
	}
}

// pickup slider
.research__pickup {
	padding: 60px 0;
	background-color: $color-black;
	@include media-breakpoint-down(sm) {
		padding: 40px 0 60px;
	}
	&-inner {
		position: relative;
		padding-left: 320px;
		@include media-breakpoint-down(sm) {
			padding: 0;
		}
	}
	&-ttl {
		position: absolute;
		left: 0;
		top: 0;
		@include ff-en();
		@include fz(8rem,5rem);
		color: $color-white;
		@include media-breakpoint-down(sm) {
			position: static;
			margin-bottom: 100px;
		}
		span{
			@include ff-serif();
			@include fz(3rem,1.8rem);
			display: block;
		}
	}
	&-block {
		flex: 1 0 0%;
		margin-right: -85px;
		padding: 30px 0;
		background-color: $color-white;
		@include media-breakpoint-down(lg) {
			margin-right: -40px;
		}
		@include media-breakpoint-down(sm) {
			margin: 0 -20px;
			padding: 20px 0 30px 35px;
		}
	}
	&-slider{
		position: relative;
		.slick-list {
			@include media-breakpoint-down(sm) {
				padding-right: 15px;
			}
		}
		&_item {
			margin: 0 10px !important;
			@include media-breakpoint-up(md) {
				min-height: 240px;
			}
			@include media-breakpoint-down(sm) {
				margin: 0 20px 0 0 !important;
			}
		}
		&_txt {
			@include fz(1.8rem, 1.6rem);
			font-weight: bold;
		}
		.slick-pagination{
			position: absolute;
			transform: translateX(-100%);
			left: -130px;
			bottom: 35%;
			display: flex;
			align-items: center;
			height: 30px;
			@include ff-en();
			color: $color-white;
			@include media-breakpoint-down(sm) {
				display: none;
				// justify-content: flex-end;
				// position: static;
				// transform: translateX(0);
				// padding: 10px 20px 0 0;
				// color: $color-black;
			}
			&::after{
				content: "";
				width: 1px;
				height: 15px;
				background-color: $color-white;
				margin: 0 20px 0 30px;
				@include media-breakpoint-down(sm) {
					content: none;
				}
			}
			span{
				margin: 0 5px;
			}
		}
		.slick-arrow{
			position: absolute;
			top: auto;
			bottom: 35%;
			z-index: 1;
			height: 30px;
			@include media-breakpoint-down(sm) {
				top: 30%;
				bottom: auto;
				background: $color-black-dark;
				padding: 20px;
			}
			&::after{
				background-repeat: no-repeat;
				width: 20px;
				height: 10px;
				@include media-breakpoint-down(sm) {
					position: absolute;
					width: 10px!important;
					height: 19px!important;
					background-size: 100%;
				}
			}
		}
		.slick-next {
			left: -60px !important;
			transform: translateX(-100%);
			@include media-breakpoint-down(sm) {
				left: auto !important;
				right: -40px !important;
			}
			&::before{
				content: "" !important;
				background: url("/img/common/arrow_next_white2.svg") no-repeat center center / contain;
				@include media-breakpoint-down(sm) {
					background-image: url("/img/common/arrow_next_white.svg")!important;
				}
			}
		}
		.slick-prev {
			left: -100px !important;
			transform: translateX(-100%);
			@include media-breakpoint-down(sm) {
				left: 5px !important;
			}
			&::before{
				content: "" !important;
				background: url("/img/common/arrow_prev_white2.svg") no-repeat center center / contain;
				@include media-breakpoint-down(sm) {
					background-image: url("/img/common/arrow_prev_white.svg")!important;
				}
			}
		}
	}
	.page-btn_style1{
		position: absolute;
		left: -60px;
		bottom: 10%;
		transform: translateX(-100%);
		width: 120px;
		@include media-breakpoint-down(sm) {
			left: -15px;
			top: -100px;
			transform: translate(0);
			height: 40px;
		}
		&::before, &::after {
			background-color: $color-white;
		}
		a {
			color: $color-white;
			text-decoration: none;
			&::after{
				background-image: url("/img/common/arrow_next_white2.svg");
			}
		}
	}
}
