@charset 'utf-8';
.about-75{
	.breadcrumb-item{
		color: $color-black!important;
		text-shadow: none!important;
		&+ .breadcrumb-item::before{
		color: $color-black!important;
		}
		a{
			color: $color-black!important;
			text-shadow: none!important;
		}
	}
	.page__news-item{
		a{
			&.no-link{
				pointer-events: none;
				&::after{
					content: none;
				}
			}
		}
	}
	.about__sitebnr-btnlist{
		background-image: url(/about/75th/img/column-mv.jpg)!important;
	}
	&__mv{
		overflow: hidden;
		position: relative;
		.mv-slider{
			margin-inline: auto;
			overflow: hidden;
			width: 100vw;
			li{
				position: relative;
				&::after{
					content: "";
					position: absolute;
					width: 100%;
					height: 100%;
					background-color: rgba(0,0,0,.3);
					top: 0;
					left: 0;
				}
				img {
					width: 100%;
					@include media-breakpoint-down(sm) {
						object-fit:contain;
					}
				}
			}
			@keyframes zoomUp {
				0% {
				  transform: scale(1);
				}
				100% {
				  transform: scale(1.15);
				}
			}
			.add-animation {
				animation: zoomUp 10s linear 0s normal both;
			}
		}
		&-title{
			position: absolute;
			z-index: 1;
			color: $color-white;
			margin-left: 50px;
			@include media-breakpoint-down(sm) {
				margin-left: 20px;
			}
			.page-ttl_style1-ttl{
				padding-top: 30px;
				@include media-breakpoint-down(sm) {
					font-size: 2.6rem;
				}
			}
		}
	}
	&__block{
		margin: 130px auto;
		@include media-breakpoint-down(sm) {
			margin: 60px auto;
		}
	}
	&__leadblock{
		max-width: 800px;
	}
	&__title{
		@include fz(3rem,2.2rem);
		@include ff-serif();
		font-weight: bold;
		text-align: center;
	}
	&__lead{
		text-align: center;
		@include media-breakpoint-down(sm) {
			text-align: justify;
		}
	}

	&__message{
		position: relative;
		&::after{
			content: "";
			width: 100%;
			height: 90%;
			background: linear-gradient(#004a98 0%, #152740 100%);
			display: block;
			position: absolute;
			z-index: -1;
			bottom: -60px;
			@include media-breakpoint-down(sm) {
				bottom: auto;
				top: 50px;
				height: 100%;
			}
		}
		&_block{
			max-width: 1300px;
			margin: 0 auto;
			@include media-breakpoint-down(sm) {
				padding: 0 10px;
			}
		}
		&_box{
			display: flex;
			justify-content: center;
			gap: 80px;
			margin-top: -150px;
			position: relative;
			z-index: 1;
			margin-left: 50px;
			margin-right: 50px;
			@include media-breakpoint-down(sm) {
				display: block;
				margin-top: 20px;
				margin-left: auto;
				margin-right: auto;
			}
		}
		&_item{
			background-color: $color-white;
			max-width: 540px;
			padding: 30px;
			flex: 1;
			@include media-breakpoint-down(sm) {
				margin: 0 auto 50px;
			}
			.name{
				text-align: right;
				margin-top: 30px;
				span{
					font-weight: bold;
					@include fz(1.8rem);
					margin-left:10px;
				}
			}
		}
	}
	&__ceremony{
		background-image: url(/about/75th/img/ceremony.jpg);
		padding: 180px 0;
		background-size: cover;
		background-position: bottom;
		margin-top: 60px;
		@include media-breakpoint-down(sm) {
			padding: 80px 0;
		}
		&_title{
			@include fz(4.8rem,3rem);
			@include ff-serif();
			font-weight: bold;
			text-align: center;
		}
		&_day{
			text-align: center;
			color: #004A98;//非対応のブラウザでの文字色を設定
			background: -webkit-linear-gradient(top, #004A98 30%, #152740);//背景色にグラデーションを指定
			-webkit-background-clip: text;//テキストでくり抜く
			-webkit-text-fill-color: transparent;//くり抜いた部分は背景を表示
			@include ff-en();
			.day{
				@include fz(7rem,4rem);
				line-height: 1;
			}
			.yobi{
				@include fz(4.6rem,3rem);
				margin-left: 10px;
			}
		}
		&_text{
			max-width: 800px;
			margin: 60px auto 100px;
			@include media-breakpoint-down(sm) {
				margin: 40px auto 60px;
			}
		}
	}
	&__slider{
		&_block{
			display: flex;
			gap: 80px;
			@include media-breakpoint-down(sm) {
				display: block;
			}
		}
		&_box{
			&:only-child{
				width: 80%;
				margin: 0 auto;
				@include media-breakpoint-down(sm) {
					width: 100%;
				}
			}
			&:first-child:nth-last-child(2),
			&:first-child:nth-last-child(2) ~ * {
				flex: 1;
			}
			@include media-breakpoint-down(sm) {
				margin-bottom: 80px;
			}
			.item{
				list-style: none;
				a{
					text-decoration: none;
					}
				&:hover{
					a{
					text-decoration: none;
					}
				}
				.img{
					img{
						object-fit: cover;
						aspect-ratio: 4/2.5;
					}
				}
				.slider-text{
					color: $color-main;
					font-weight: bold;
					margin-top: 5px;
					@include fz(1.8rem,1.6rem);
				}
			}
			.slider{
				padding: 0 30px;
				.slide-arrow{
					position: absolute;
					top: unquote('min(19vw, 150px)'); ;
					// transform: translateY(-50%);
					cursor: pointer;
				}
				.prev-arrow{
					left: 0!important;
				}
				.next-arrow{
					right: 0!important;
				}
			}
		}
		&_title{
			color: $color-main;
			@include ff-serif();
			@include fz(3.4rem,2.8rem);
			font-weight: bold;
			text-align: center;
			margin-bottom: 30px;
		}
		&_lead{
			margin-bottom: 30px;
		}
	}

	.about__sitebnr-btnlist{
		@include media-breakpoint-down(sm) {
			background-position: 75% center;
		}
	}
}
