@charset 'utf-8';
.sdgs {
    .page__news-list{
        height: 235px;
    }
    .main_block {
        text-align: center;
    }
    .sdgs_cross {
        display: inline-block;
        position: relative;
        width: 60px;
        height: 60px;
        &::before,
        &::after {
            content: "";
            position: absolute;
            top: 50%;
            left: 50%;
            width: 1px;
            height: 60px;
            background-color: #333;
        }
        &::before {
            transform: translate(-50%,-50%) rotate(45deg);
        }
        &::after {
            transform: translate(-50%,-50%) rotate(-45deg);
        }
    }
    .sdgs_line {
        position: relative;
        margin: 60px 0;
        &::before {
            content: "";
            display: inline-block;
            width: 100px;
            height: 1px;
            background-color: #333;
            position: absolute;
            top: 30px;
            left: 50%;
            transform: translate(-50%);
        }
    }

}