@charset 'utf-8';
//------------------------------------------
//  Original
//------------------------------------------
$content-inner: 1080px;
//$content-inner-sub: 1024px;
//$content-inner-slim: 800px;
$content-inner-wide: 1200px;

$block-gutter-vertical: 120px;
$block-gutter-vertical1-pc: $block-gutter-vertical;
$block-gutter-vertical1-sp: 90px;
$block-gutter-vertical2: 60px;
$block-gutter-vertical2-pc: $block-gutter-vertical2;
$block-gutter-vertical2-sp: $block-gutter-vertical2;
$block-gutter-vertical3: 30px;
$block-gutter-vertical3-pc: $block-gutter-vertical3;
$block-gutter-vertical3-sp: $block-gutter-vertical3;

//------------------------------------------
//  colors
//------------------------------------------
$color-black-dark: #000;
$color-black: #333;
$color-black2: #152740;
$color-black-light: #646464;
$color-gray: #CCC;
$color-gray-light: #D3D3D3;
$color-gray-light2: #EFEFEF;
$color-gray-dark: #999;
$color-white: #fff;
$color-main-dark: #BB112C;
$color-main: #004A98;
$color-main-dark: #0c2444;
$color-main-bg: #F2D8DD;
$color-main2: #390518;
$color-main2-light: lighten(mix($color-main2, white, 70%), 10%);
$color-sub1: #AE67BE;
$color-sub1-light: lighten(mix($color-sub1, white, 70%), 10%);
$color-sub2: #D96D74;
$color-sub2-light: lighten(mix($color-sub2, white, 70%), 10%);
$color-sub3: #F6EBB3;
$color-sub3-light: lighten(mix($color-sub3, white, 70%), 10%);
$color-sub4: #ffb0b0;
$color-sub4-light: lighten(mix($color-sub4, white, 90%), 5%);
$color-anchor: $color-black;
$color-red: #E81826;
$color-blue: blue;
$color-green: green;
$color-body: #fff;
$color-text: $color-black;
// --




$color-grd-related: linear-gradient(to bottom, $color-main 0%, $color-black2 100%);




// $color-grd45: linear-gradient(135deg, #caa68d 0%, #825907 100%);
// --
$color-keys: (
  black: $color-black,
  white: $color-white,
  red: $color-red,
  // rits: $color-rits,
);
// --
$color-base-red: $color-red;
$color-base-bg-gray: $color-gray-light;
$color-base-bg-dark: $color-gray-dark;
// --
$color-text: $color-black;
$color-table-head: $color-main;
$color-table-sub-head: $color-white;
// --
$color-base-main: $color-main;
$color-base-sub: $color-sub1;
$color-base-btn: $color-base-main;
$color-base-btn-text: $color-text;
// -- 選択時のカラー設定
$color-selection-background: $color-main;
$color-selection-text-color: $color-white;
// -- リンク関連
$link-decoration: underline !default;
//$link-hover-decoration: none !default;
$link-color: $color-text !default;
//$link-hover-color: darken($color-main, 15%) !default;

// ---------------------------------------
// Bootstrap
// [origine] -> vender/bootstrap/_variables.scss
// ---------------------------------------
//  font colors
$font-family-sans-serif:"游ゴシック Medium", "Yu Gothic Medium", "游ゴシック体", YuGothic, "ヒラギノ角ゴ ProN W3", "Hiragino Kaku Gothic ProN", "メイリオ", Meiryo, Verdana, Roboto, "Droid Sans", sans-serif;
$font-family-serif:'Noto Serif JP', "游明朝", "Hiragino Mincho ProN", "ヒラギノ明朝 W3 JIS2004", "Times New Roman", Times, serif;
$font-family-serif-shippori:'Shippori Mincho B1','Noto Serif JP', "游明朝", "Hiragino Mincho ProN", "ヒラギノ明朝 W3 JIS2004", "Times New Roman", Times, serif;
$font-family-en-sans-serif:Roboto,"游ゴシック Medium", "Yu Gothic Medium", "游ゴシック体", YuGothic, "ヒラギノ角ゴ ProN W3", "Hiragino Kaku Gothic ProN", "メイリオ", Meiryo, Verdana, "Droid Sans", sans-serif;
$font-family-en-serif:"Crimson Text", Times, serif;
$font-family-barlow:"Barlow", Times, serif;
$font-family-garamond:'EB Garamond','Noto Serif JP', "游明朝", "Hiragino Mincho ProN", "ヒラギノ明朝 W3 JIS2004", "Times New Roman", Times, serif;

// Breakpoints
$grid-breakpoints: (
  xs: 0,
  sm: 576px,
  md: 768px,
  lg: $content-inner,
  llg: 1280px,
  xl: 1400px,
  xxl: 1980px,
);

// Sizes
$sizes: (
  5: 5%,
  10: 10%,
  15: 15%,
  20: 20%,
  25: 25%,
  30: 30%,
  35: 35%,
  40: 40%,
  45: 45%,
  50: 50%,
  60: 60%,
  70: 70%,
  75: 75%,
  80: 80%,
  90: 90%,
  100: 100%,
  auto: auto,
);

$sizes-em: (
  1: 1em,
  2: 2em,
  3: 3em,
  4: 4em,
  5: 5em,
  6: 6em,
  7: 7em,
  8: 8em,
  9: 9em,
  10: 10em,
);
$sizes-px: (
  50: 50px,
  100: 100px,
  150: 150px,
  200: 200px,
  250: 250px,
  300: 300px,
  350: 350px,
  400: 400px,
  450: 450px,
  500: 500px,
  550: 550px,
  600: 600px,
  650: 650px,
  700: 700px,
  750: 750px,
  800: 800px,
  850: 850px,
  900: 900px,
  950: 950px,
  1000: 1000px,
  1050: 1050px,
  1100: 1100px,
  1150: 1150px,
  1200: 1200px,
);

// font size
$font-size-base: 1.6rem;
$lead-font-size: ($font-size-base * 2);
$h1-font-size: $font-size-base * 2.75;
$h2-font-size: $font-size-base * 2;
$h3-font-size: $font-size-base * 1.75;
$h4-font-size: $font-size-base * 1.3;
$h5-font-size: $font-size-base * 1.2;
$h6-font-size: $font-size-base;
$line-height-base: 1.8;
$headings-line-height: 1.3;
$small-font-size:85%;

// breadcomb
$breadcrumb-bg: transparent;

// table
$table-border-color: $color-gray;

// --- override ---
//$color-sub-btn: #3974b6;
//$pager-base-color: $color-black;
// $ptag-colors: ($color-main, $color-red);
// $pblock-kuzu-sp-hidden: true;
$ft-pc-margin-top-page: 0px; //150px;
$ft-pc-margin-top-home: 0px;
$ft-sp-margin-top-page: 0px; //90px;
$ft-sp-margin-top-home: 0px;
$main-pc-margin-bottom-page: 120px;
$main-sp-margin-bottom-page: 90px;
$main-pc-margin-bottom-home: 0px;
$main-sp-margin-bottom-home: 0px;
//$content-header-fixed-pc: 118px;
//$content-header-fixed-sp: 60px;
//$ft-sp-margin-bottom: 60px;
// $ptag-base-color: $color-black;
// $ptag-colors: ($color-main);
// $slick-dot-color: rgba($color-white, 0.4);
// $slick-dot-color-active: $color-white;
// $embed-responsive-aspect-ratios: ((10 7), (3, 2));
$overflows: auto, hidden, visible !default;

// ---- slick
// $slick-arrow-box-w: 60px;
// $slick-arrow-box-h: 60px;
// $slick-arrow-color: #baa0c1;
// $slick-arrow-bgcolor: rgba($color-white, 0.6);
