@charset 'utf-8';
.page.about{
	//Aboutインデックスページ
	.page__lead-title{
		img{
			min-width: 50px;
		}
	}
	&.mv-1{
		.about{
			&__sitebnr{
				&-list{

				}
				&-item{
					// min-height: 500px;
					width: 100%;
					display: block;
					background-size: cover;
					&.site1{
						background-image: url("/about/img/about_site1bnr.jpg");
						@include media-breakpoint-down(sm) {
							background-image: url("/about/img/about_site1bnr-sp.jpg");
						}
						.about__sitebnr-box{
							border-right: 1px solid $color-white;
							text-align: right;
							padding-right: 20px;
						}
						.about__sitebnr-link{
							padding-right: 100px;
							@include media-breakpoint-down(sm) {
								padding-right: 20px;
								padding-left: 20px;
							}
						}
					}
					&.site2{
						background-image: url("/about/img/about_site2bnr.jpg");
						@include media-breakpoint-down(sm) {
							background-image: url("/about/img/about_site2bnr-sp.jpg");
						}
						.about__sitebnr-box{
							border-left: 1px solid $color-white;
							text-align: left;
							padding-left: 20px;
						
						}
						.about__sitebnr-link{
							padding-left: 100px;
							@include media-breakpoint-down(sm) {
								padding-right: 20px;
								padding-left: 20px;
							}
						}
					}
				}
				&-link{
					text-decoration: none;
					display: block;
					height: 100%;
					padding-bottom: 30px;
					@include media-breakpoint-down(sm) {
						padding-bottom: 120px;
					}
				}
				&-box{
					padding-top: 205px;
					width: 100%;
					margin: 0 auto;
					@include media-breakpoint-down(sm) {
						padding-top: 40px;
					}
				}
				&-title{
					color: $color-white;
					@include ff-en();
					@include fz(6rem,3.6rem);
				}
				&-text{
					color: $color-white;
				}

				&-btnlist{
					background-image: url("/about/img/about_btnbg.jpg");
					padding: 95px 0;
					width: 100%;
					background-size: cover;
					@include media-breakpoint-down(sm) {
						padding: 70px 0;
						background-image: url("/about/img/about_btnbg-sp.jpg");
					}
				}
				&-btnbox{
					display: flex;
					@include media-breakpoint-down(sm) {
						display: block;
					}
					.page-btn_style1{
						margin-right: 60px;
						width: calc(50% - 30px);
						@include media-breakpoint-down(sm) {
							width: 100%;
							margin-bottom: 30px;
						}
						&:nth-child(even){
							margin-right: 0;
							@include media-breakpoint-down(sm) {
								margin-bottom: 0;
							}
						}
						a{
							color: $color-white;
							font-weight: bold;
						}
						&::before,&::after{
							content: "";
							background-color: $color-white;
						}
					}
				}
			}

			&__info{
				&_box{
					&-head{
						display: flex;
						flex-wrap: wrap;
						@include media-breakpoint-down(sm) {
							display: block;
						}
						.btn_style{
							width: calc(50% - 22.5px);
							margin-right: 45px;
							@include media-breakpoint-down(sm) {
								width: 100%;
								margin-right: 0px;
							}
							&:nth-child(even){
								margin-right: 0;
							}
						}
					}
					&-bottom{
						display: flex;
						margin-top: 40px;
						@include media-breakpoint-down(sm) {
							display: block;
						}
						.page-imglink{
							width: calc(50% - 22.5px);
							margin-right: 45px;
							@include media-breakpoint-down(sm) {
								width: 100%;
								margin-right: 0px;
							}
							&:nth-child(even){
								margin-right: 0;
							}
						}
					}
				}
			}
			&__linklist{
				&_box{
					display: flex;
					border-left: 3px solid $color-main;
					@include media-breakpoint-down(sm) {
						display: block;
						border-left: none;
					}
				}
				&_title{
					flex: 0 0 240px;
					font-weight: bold;
					@include fz(2rem,2rem);
					padding-left: 15px;
					color: $color-main;
					@include media-breakpoint-down(sm) {
						border-bottom: 3px solid $color-main;
						margin-bottom: 30px;
					}
				}
				&_list{
					display: flex;
					flex-wrap: wrap;
					width: 100%;
					@include media-breakpoint-down(sm) {
						display: block;
						border-left: none;
					}
					.btn_style{
						width: calc(50% - 22.5px);
						margin-right: 45px!important;
						@include list-reset();
						@include media-breakpoint-down(sm) {
							width: 100%;
							margin-right: 0;
						}
						&:nth-child(even){
							margin-right: 0!important;
						}
					}
				}
			}
		}	
	}
}