// [_text.scss]拡張
.ff {
  // ff__gothic {
  // ff__mincho {
  &__en {
    @include ff-en();
  }
}

// ---------------------------------------------
//  base-layout
// ---------------------------------------------

$content-inner: 1080px !default;
main.contents {
  @include media-breakpoint-down(sm) {
    padding-top: 70px;
  }
}
.page {
  &.mv-1 {
    .block {
      padding: 80px 0;
      &:last-child {
        padding-bottom: 80px !important;
        @include media-breakpoint-up(md) {
          padding-bottom: 120px !important;
        }
      }
    }
    main.contents {
      padding-bottom: 0px;
    }
  }
  &.post-sys {
    &.detail {
      main.contents {
        padding-top: 120px;
        @include media-breakpoint-up(md) {
          padding-top: 210px;
        }
      }
      .block {
        .bg-gray {
          padding-left: 30px;
          padding-right: 30px;
          @include media-breakpoint-down(sm) {
            padding-left: 20px;
            padding-right: 20px;
          }
        }
        .inner-wide {
          @include media-breakpoint-down(sm) {
            padding-left: 0px;
            padding-right: 0px;
          }
        }
      }
    }
  }
  main.contents {
    padding-bottom: 80px;
    @include media-breakpoint-up(md) {
      padding-top: 130px;
      padding-bottom: 120px;
    }
    .block {
      padding-top: 80px;
      padding-bottom: 30px;
      &:first-child {
        padding-top: 0;
      }
      &:last-child {
        padding-bottom: 0;
      }
      &.bg-gray {
        background: $color-gray-light2;
        padding-bottom: 80px;
        // &:last-child{
        //   padding-bottom: 80px;
        // }
      }
      &-top {
        padding-top: 80px;
      }
    }
  }
}

a[class] {
  color: currentColor;
}
a {
  &:hover {
    text-decoration: none;
  }
}

// インナーコンテンツ幅
.inner {
  @include media-breakpoint-up(md) {
    max-width: $content-inner;
    margin: 0 auto;
  }
  @include media-breakpoint-down(lg) {
    padding-right: 20px;
    padding-left: 20px;
  }
  &-flexible {
    padding: 0 85px;
    // max-width: 1800px;
    @include media-breakpoint-down(lg) {
      padding: 0 40px;
    }
    @include media-breakpoint-down(sm) {
      padding: 0 20px;
    }
  }
  &-wide {
    max-width: $content-inner-wide;
    margin: 0 auto;
    @include media-breakpoint-down(lg) {
      padding-right: 20px;
      padding-left: 20px;
    }
  }
}

//パンクズ
.breadcrumb {
  padding: 0;
  &-item {
    &.active {
      color: $color-black;
    }
    a {
      color: $color-black;
      text-decoration: none;
      &:hover {
        text-decoration: underline;
      }
    }
    & + .breadcrumb-item::before {
      content: "ー";
      color: $color-black;
    }
  }
}

.page {
  //ボタンスタイル
  &-btn {
    &_style {
      &1 {
        a {
          padding: 10px 0;
          display: flex;
          justify-content: space-between;
          align-items: center;
          text-decoration: none;
          &::after {
            content: "";
            background-image: url("/img/common/arrow_next_white2.svg");
            background-repeat: no-repeat;
            width: 20px;
            height: 10px;
            margin-left: 10px;
          }
          &:hover {
            opacity: 1;
          }
        }
        &::before,
        &::after {
          content: "";
          background-color: $color-black;
          height: 1px;
          width: 100%;
          display: block;
          transition: 0.15s linear;
          position: relative;
          bottom: 0;
        }
        &:hover {
          &::after {
            position: relative;
            bottom: -5px;
          }
        }
      }
    }
  }

  //画像リンク
  &-imglink {
    a {
      text-decoration: none;
      &::after {
        content: none;
      }
    }
    &_box {
      position: relative;
      @include media-breakpoint-up(md) {
        width: 100%;
      }
      &::after {
        content: "";
        background-color: $color-white;
        width: 45px;
        height: 45px;
        position: absolute;
        bottom: 0px;
        right: 0px;
      }
      &::before {
        content: "";
        background-image: url("/img/common/arrow_next_black.svg");
        background-repeat: no-repeat;
        width: 20px;
        height: 10px;
        position: absolute;
        right: 10px;
        bottom: 15px;
        z-index: 1;
      }
    }
    &_text {
      @include fz(2.2rem, 1.8rem);
      font-weight: bold;
    }
  }
}

ul {
  padding-left: 0;
  &:not([class]) {
    padding-left: 1em;
  }
}

.arrow {
  display: block;
  &-r {
    position: relative;
    padding-right: 14px;
    // @include media-breakpoint-down(sm) {
    //   padding-right: 0;
    // }
    &::after {
      font-family: fontawesome;
      content: "\f105";
      position: absolute;
      color: $color-black;
      transition: 0.3s;
      right: 0;
      top: 50%;
      transform: translateY(-50%);
    }
    &.yazirushi {
      &::after {
        content: "";
        background-image: url(/img/common/arrow_next_black.svg);
        background-repeat: no-repeat;
        width: 15px;
        height: 7px;
        margin-left: 10px;
      }
    }
  }
}

.gheader li,
.gheader ol,
.gnav li,
.gnav ol {
  list-style: none;
  a {
    text-decoration: none;
  }
}

.nakaguro {
  color: transparent;
  display: inline-block;
  position: relative;
}
.nakaguro::before {
  content: "";
  width: 3px;
  height: 3px;
  border-radius: 50%;
  background: $color-white;
  margin: auto;
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
}

.slider,
.post {
  &-label {
    &-1 {
      @include fz(1.2rem);
      color: $color-white;
      background-color: $color-main;
      padding: 1px 10px;
      display: inline-block;
      border-radius: 20px;
      margin: 0 3px;
    }
    &-2 {
      @include fz(1.4rem);
    }
  }
  &-text {
    &-1 {
      @include fz(1.8rem, 1.6rem);
      color: $color-white;
      display: block;
    }
    &-2 {
      display: block;
    }
    &-3 {
      display: block;
      @include fz(1.4rem);
      color: $color-white;
    }
  }
  &-date {
    &inner-wide {
      @include fz(1.4rem);
      color: $color-white;
      @include ff-barlow();
    }
    &-2 {
      @include fz(1.4rem);
      @include ff-barlow();
    }
  }

  &-event {
    &-date {
      span {
        line-height: 1;
        text-align: center;
      }
      .year {
        @include fz(1.2rem);
        @include ff-barlow();
        color: $color-main;
        margin-bottom: 10px;
      }
      .month {
        @include fz(1.8rem);
        @include ff-barlow();
      }
      .day {
        @include fz(5.2rem);
        @include ff-barlow();
        word-break: normal;
      }
    }
    &-content {
      font-weight: bold;
    }
    &-hostday {
      @include fz(1.4rem);
      &::before {
        content: "";
        background-image: url("/img/common/time_icon.svg");
        background-repeat: no-repeat;
        width: 16px;
        height: 16px;
        display: inline-block;
        margin-right: 5px;
        vertical-align: sub;
      }
    }
    &-place {
      @include fz(1.4rem);
      &::before {
        content: "";
        background-image: url("/img/common/place_icon.svg");
        background-repeat: no-repeat;
        width: 16px;
        height: 18px;
        display: inline-block;
        margin: 0 1.5px;
        vertical-align: sub;
        text-align: center;
      }
    }
    &-important {
      @include fz(1.4rem);
      color: $color-red;
    }
  }
}

.scroll-hint-icon {
  top: 50%;
  transform: translateY(-50%);
}

.post-sys {
  &__list {
    display: flex;
    flex-wrap: wrap;
  }
  &__item {
    list-style: none;
    margin-bottom: 30px;
    @include media-breakpoint-down(sm) {
      width: 100%;
    }
    &.column-3 {
      @include media-breakpoint-up(md) {
        width: calc(33.333% - 20px);
        margin-right: 30px;
        margin-bottom: 60px;
        &:nth-child(3n) {
          margin-right: 0;
        }
      }
    }
    &.column-4 {
      @include media-breakpoint-up(md) {
        width: calc(25% - 20px);
        margin-right: 26.6px;
        margin-bottom: 60px;
        &:nth-child(4n) {
          margin-right: 0;
        }
      }
    }

    &.fixed-item {
      position: relative;
      &::before {
        content: "";
        width: 30px;
        height: 30px;
        background-image: url("/img/common/news_fixed_icon.png");
        position: absolute;
        top: 0;
        right: 0;
        z-index: 1;
      }
    }
  }
  &__link {
    text-decoration: none;
  }
  &__thumnail {
    margin-bottom: 5px;
    border: 2px solid $color-gray-light;
    img {
      object-fit: cover;
      width: 100%;
      aspect-ratio: 4/2.5;
    }
    &.blogid-147 {
      background-color: #efefef;
      img {
        object-fit: contain !important;
        width: 100% !important;
        height: 100% !important;
      }
    }
  }
  &__important {
    border: 1px solid $color-red;
    color: $color-red;
    display: inline-block;
    @include fz(1.4rem);
    padding: 0px 10px;
    line-height: 1.5;
    vertical-align: middle;
  }
  &__date {
    @include fz(1.4rem);
    @include ff-barlow();
    margin-bottom: 5px;
    display: inline-block;
  }
  &__cate {
    @include fz(1.2rem);
    background: $color-gray-light2;
    display: inline-block;
    padding: 3px;
    margin-bottom: 5px;
  }
  &__media {
    border-left: 1px solid #333;
    padding-left: 10px;
    margin-left: 10px;
    display: inline-block;
    line-height: 1;
  }

  &__related {
    background: $color-grd-related;
    padding: 40px;
    @include media-breakpoint-down(sm) {
      padding: 40px 20px;
    }
    &_area {
      margin-bottom: 80px;
    }
    &_ttl {
      color: $color-white;
      @include fz(2.4rem);
      font-weight: bold;
      border: 1px solid $color-white;
      display: inline-block;
      @include ff-serif();
      padding: 0 20px;
      @include media-breakpoint-down(sm) {
        padding: 0 20px;
      }
    }
    .btn_style2 {
      border-bottom: 1px solid $color-white;
      a {
        color: $color-white;
        &:hover {
          opacity: 0.3;
        }
      }
    }
    .ttl_style3 {
      color: $color-white;
      &::before {
        background: $color-white;
      }
    }
    .post-sys__item {
      margin-bottom: 0;
      @include media-breakpoint-down(sm) {
        margin-bottom: 30px;
      }
    }
    .post-sys__text {
      color: $color-white;
    }
  }

  &__relatednews {
    background: $color-gray-light2;
    padding: 40px;
    margin-top: 40px;
    @include media-breakpoint-down(sm) {
      padding: 40px 20px;
    }
    &_ttl {
      color: $color-main;
      @include fz(2.4rem);
      font-weight: bold;
      border: 1px solid $color-main;
      display: inline-block;
      @include ff-serif();
      padding: 0 20px;
      margin-bottom: 30px;
      @include media-breakpoint-down(sm) {
        padding: 0 20px;
      }
    }
    .post-sys {
      &__date {
        color: $color-black !important;
        font-size: 1.4rem !important;
      }
      &__cate {
        margin-bottom: 5px !important;
        background: $color-white;
      }
    }
  }

  &.detail {
    .post-sys {
      &__date {
        @include fz(1.8rem);
        color: $color-main;
        vertical-align: middle;
        margin-right: 10px;
      }
      &__cate {
        margin-bottom: 0px;
      }
      &__important {
        margin-bottom: 0px;
      }
      &__contents {
        margin: 0 0 80px;
      }
    }
  }
}

.img-arrow {
  position: relative;
  display: block;
  &::after {
    content: "";
    position: absolute;
    background: url(/img/common/img_arrow.jpg);
    width: 45px;
    height: 45px;
    display: block;
    right: 0;
    bottom: 0;
  }
}

.cookie {
  background-color: $color-main;
  padding: 20px 50px;
  z-index: 99999;
  display: none;
  &.firstTime {
    display: block !important;
  }
  @include media-breakpoint-down(sm) {
    padding: 10px 20px 20px;
    bottom: 60px;
  }
  &__inner {
    display: flex;
    justify-content: space-between;
    align-items: center;
    max-width: 1000px;
    margin: 0 auto;
    @include media-breakpoint-down(sm) {
      display: block;
    }
  }
  &__btn {
    display: flex;
    @include media-breakpoint-down(sm) {
      justify-content: center;
      width: 100%;
    }
    &_item {
      @include media-breakpoint-down(sm) {
        flex: 0 0 50%;
      }
      &:first-child {
        margin-right: 20px;
      }
    }
  }
  p {
    color: $color-white;
  }
  .plink_type_underline {
    text-decoration: underline;
  }
  .cookie__link {
    padding: 15px 20px;
    border: 1px solid $color-white;
    color: $color-white;
    display: block;
    min-width: 120px;
    text-align: center;
    @include media-breakpoint-down(sm) {
      padding: 10px;
      min-width: auto;
      width: 100%;
    }
    &.true {
      background: $color-white;
      color: $color-main;
    }
  }
}
