@charset 'utf-8';
.knowledge-search{
	&__mv{
        background-image: url("/about/knowledge_search/img/mv.jpg");
        background-size: cover;
        min-height: 450px;
        display: flex;
        align-items: center;
        justify-content: center;
        @include media-breakpoint-down(sm) {
            background-image: url("/about/knowledge_search/img/mv-sp.jpg");
        }
        &_content{
            max-width:960px;
            margin: 0 auto;
            @include media-breakpoint-down(lg) {
                padding-right: 20px;
                padding-left: 20px;
            }
        }
        &_tag{
            display: flex;
            flex-wrap: wrap;
            margin-top: 60px;
            .tag_item{
                margin-bottom: 20px;
                margin-right: 20px;
                &:last-child{
                    margin-right: 0;
                }
                a{
                    text-decoration: none;
                    @include fz(1.6rem,1.4rem);
                    color: $color-main;
                    font-weight: bold;
                    background: $color-white;
                    padding: 5px 10px;
                    border-radius: 20px;
                    &::before{
                        content: "#";
                        margin-right: 3px;
                    }
                }
            }
        }
    }

    &__news{
        margin: 80px 0;
        &_box{
            display: flex;
            align-items: flex-start;
            @include media-breakpoint-down(sm) {
                display: block;
            }
        }
        &_title{
            flex: 0 0 17%;
        }
        &_list{
            display: flex;
            @include media-breakpoint-down(sm) {
                display: block;
            }
        }
        &_item{
            position: relative;
            img{
                width: 100%;
                object-fit: cover;
                aspect-ratio: 4/3;
            }
            figure{
                position: relative;
                margin-bottom: 0;
                &::after{
                    content: "";
                    position: absolute;
                    top: 0;
                    width: 100%;
                    height: 100%;
                    background: linear-gradient(rgba(0,0,0,0)45%,rgba(0,0,0,.5));
                    display: block;
                }
            }
            &.main{
                @include media-breakpoint-down(sm) {
                    margin-bottom: 20px;
                }
                .post-inner{
                    padding: 20px;
                }
                .title{
                    overflow: hidden;display: 
                    -webkit-box;
                    -webkit-box-orient: vertical;
                    -webkit-line-clamp: 3;
                    @include media-breakpoint-down(sm) {
                        -webkit-line-clamp: 2;
                    }
                }
            }
            &.sub{
                .post-inner{
                    padding: 10px;
                }
                .title{
                    overflow: hidden;display: 
                    -webkit-box;
                    -webkit-box-orient: vertical;
                    -webkit-line-clamp: 2;
                    @include media-breakpoint-down(sm) {
                        -webkit-line-clamp: 1;
                    }
                }
            }
            .post-inner{
                position: absolute;
                bottom: 0;
                color: $color-white;
                font-weight: bold;
                .title{
                    @include fz(2.2rem,2rem);
                    margin-bottom: 0;
                    line-height: 1.2;
                }
                .date{
                    @include ff-serif();
                    @include fz(1.4rem,1.4rem);
                }
                .tag_item{
                    margin-right: 5px;
                    text-decoration: none;
                    @include fz(1.4rem,1.2rem);
                    color: $color-main;
                    font-weight: bold;
                    background: $color-white;
                    padding: 0px 10px;
                    border-radius: 20px;
                    display: inline-block;
                    &::before{
                        content: "#";
                        margin-right: 3px;
                    }
                    &:last-child{
                        margin-right: 0;
                    }
                }
            }
            &.main{
                flex: 0 0 50%;
                width: calc(50% - 20px);
                margin-right: 10px;
                @include media-breakpoint-down(sm) {
                    width: 100%;
                    margin-right: 0px;
                }
                @include media-breakpoint-up(md) {
                    &:last-child{
                        margin-right: 0;
                    }
                }
            }
            &.sub{
                display: flex;
                flex-wrap: wrap;
                .sub{
                    &-box{
                      
                    }
                    &-item{
                        width: calc(50% - 5px);
                        margin-right: 10px;
                        position: relative;
                        &:nth-child(2n){
                            margin-right: 0;
                        }
                        &:nth-child(1),&:nth-child(2){
                            margin-bottom: 15px;
                        }
                        figure{
                            position: relative;
                            margin-bottom: 0;
                            &::after{
                                content: "";
                                position: absolute;
                                top: 0;
                                width: 100%;
                                height: 100%;
                                background: linear-gradient(rgba(0,0,0,0)25%,rgba(0,0,0,.5));
                                display: block;
                            }
                        }
                    }
                }
            }

        }
    }

    &__keyword{
        margin: 80px 0;
        .keyword-bg{
            background-image: url("/about/knowledge_search/img/keyword-bg.jpg");
            background-size: 100%;
            width: 100%;
            height: 100%;
            display: block;

            .svg-inner{
                padding: 80px 200px;
            }
        }
    }



    &__btn{
        text-align: right;
        margin-top: 10px;
        a{
            color: $color-main;
            @include fz(1.8rem);
            @include ff-en();
            text-decoration: none;
            position: relative;
            &:hover{
                &::after{
                    bottom: -5px;
                }
            }
            &::after{
                content: "";
                width: 100%;
                height: 1px;
                background: $color-main;
                position: absolute;
                bottom: 0;
                left: 50%;
                transform: translateX(-50%);
                transition: .15s linear;
            }
        }
    }

    &__gd{
        &_title{
            img{
                display: block;
                margin: 0 auto 40px;
            }
            @include media-breakpoint-down(sm) {
                max-width: none;
                margin: 0;
                img{
                    margin: 0 auto 50px;
                }
            }
        }
        &_flex{
            display: flex;
            justify-content: space-between;
            gap: 80px;
            @include media-breakpoint-down(sm) {
                display: block;
            }
            a{
                text-decoration: none;
            }
        }
        &_list{
            &-left{
                display: flex;
                justify-content: space-between;
                flex-wrap: wrap;
                @include media-breakpoint-down(sm) {
                    display: block;
                    margin-bottom: 60px;
                }
            }
            &-right{
                flex: 0 0 280px;
            }
        }
        &_item{
            &-left{
                width: calc(50% - 10px);
                margin-right: 15px;
                @include media-breakpoint-down(sm) {
                    width: 100%;
                    margin-right: 0;
                    margin-bottom: 30px;
                }
                @include media-breakpoint-up(md) {
                    &:nth-child(2n){
                        margin-right: 0;
                    }
                    &:nth-child(1),&:nth-child(2){
                        margin-bottom: 30px;
                    }
                }
                img{
                    object-fit: cover;
                    aspect-ratio: 7/3;
                    width: 100%;
                    border: 2px solid $color-gray;
                }
                .title{
                    margin-top: 10px;
                }
                .tag_item{
                    display: inline-block;
                    background-color: $color-white;
                    color: $color-main;
                    box-shadow: 0px 0px 10px -4px $color-black;
                    border-radius: 30px;
                    padding: 2px 10px;
                    @include fz(1.4rem);
                    margin-right: 10px;
                    &::before{
                        content: "#";
                        margin-right: 3px;
                    }
                }
            }
            &-right{
                border-top: 1px solid $color-black;
                a{
                    padding: 20px 20px 20px 5px;
                    display: block;
                    position: relative;
                    &::after{
                        font-family: 'FontAwesome';
                        content: '\f105' ;
                        position: absolute;
                        display: inline-block;
                        top: 50%;
                        transform: translateY(-50%);
                        right: 5px;
                        color: $color-main;
                    }
                    p{
                        overflow: hidden;
                        display: -webkit-box;
                        -webkit-box-orient: vertical;
                        -webkit-line-clamp: 2;
                    }
                }
                &:last-child{
                    border-bottom: 1px solid $color-black;
                }
            }
        }
        &_tabbox{
            position: relative;
            padding-bottom: 100px;
            @include media-breakpoint-down(sm) {
                padding-bottom: 40px;
            }
            .tab-opacity{
                opacity: 0;
                visibility: hidden;
            }
            .image-tab{
                position: absolute;
                top: 0;
            }
        }
        &_list{
            @include media-breakpoint-down(sm) {
                display: flex;
                flex-wrap: wrap;
                justify-content: space-between;
            }
        }
        &_item{
            @include media-breakpoint-down(sm) {
                list-style: none;
                width: 48%;
                margin-bottom: 15px;
                a{
                    box-shadow: 0px 0px 15px -10px $color-black;
                    border-radius: 10px;
                    display: block;
                    text-decoration: none;
                    background: $color-gray;
                    color: $color-white;
                    padding: 10px 10px 20px 10px;
                    text-align: center;
                    @include ff-serif();
                    @include fz(1.8rem);
                    position: relative;
                    &::after{
                        font-family: 'FontAwesome';
                        content: '\f107' ;
                        position: absolute;
                        display: inline-block;
                        left: 50%;
                        transform: translateX(-50%);
                        bottom: -5px;
                        color: $color-main;
                    }
                }
            }
        }
    }
}

.knowledge_title{
    background: $color-main;
    padding: 5px 10px;
    width: 200px;
    color: $color-white;
    text-align: center;
    margin-bottom: 40px;
    // &.webpage{
    //     margin-top: 60px;
    //     @include media-breakpoint-down(sm) {
    //         margin-top: 30px;
    //     }
    // }
    @include media-breakpoint-down(sm) {
        width: 100%;
    }
}

.knowledge_row{
    display: flex;
    justify-content: space-between;
    &_item{
        width: 49%;
    }
}