@charset 'utf-8';
// .block{
// 	padding-top: 80px;
// 	&:first-child{
// 		padding-bottom: 80px;
// 	}
// 	&.bg-gray{
// 		background: $color-gray-light2;
// 		&:last-child{
// 			padding-bottom: 80px;
// 		  }
// 	}
// }


.mt-be-column{
	iframe[src^="https://www.youtube.com/"] {
		width: 100%;
	}
}
iframe[src^="https://www.google.com/maps/"] {
	width: 100%;
	height: auto;
	aspect-ratio: 16/9;
	@include media-breakpoint-down(sm) {
		aspect-ratio: 1/1;
	}
}

iframe[src^="https://www.youtube.com/"] {
	width: 50%;
	height: auto;
	aspect-ratio: 16/9;
	@include media-breakpoint-down(sm) {
		width: 100%;
	}
}

.indent{
	padding-left: 1em;
}

.bg-gray{
	background-color: $color-gray-light2;
	.box_style1{
		background-color: $color-white;
	}
}

.page-ttl{
	&_style {
		&1 {
			&-ttl{
				@include fz(3.4rem,3rem);
				@include ff-serif();
				@include mb(0px);
				font-weight: bold;
				display: flex;
				justify-content: space-between;
				padding-top: 80px;
				padding-bottom: 40px;
				@include media-breakpoint-down(sm) {
					display: block;
					padding-top: 40px;
				}
				.subcategory{
					display: flex;
					color: $color-main;
					@include ff-en();
					@include fz(2rem,1.8rem);
					align-items: center;
					@include media-breakpoint-down(sm) {
						margin-top: 5px;
					}
					&::before{
						content: "";
						background: $color-main;
						width: 50px;
						height: 1px;
						margin-right: 5px;
					}
				}
			}
			.page-ttl_style1-mv{
				text-align: center;
			}
			
		}

		&2{
			position: relative;
			background: $color-white;
				&::before{
					content: "";
					width: 50%;
					height: 100%;
					background: linear-gradient(#004a98 0%, #152740 100%);
					display: block;
					position: absolute;
					z-index: 0;
					@include media-breakpoint-down(sm) {
						// width: 100%;
					}
				}
				&-inner{
					display: flex;
					align-items: flex-start;
					position: relative;
					z-index: 1;
					padding-top: 120px;
					padding-bottom: 80px;
					@include media-breakpoint-down(sm) {
						// display: block;
						padding-top: 60px;
						padding-bottom: 70px;
					}
					.ttl_style6{
						-ms-writing-mode: tb-rl;
						writing-mode: vertical-rl;
						color: $color-white;
						padding: 0;
						@include media-breakpoint-down(sm) {
							// writing-mode:horizontal-tb;
							 margin-left: 0;
						     margin-right: 15px;
							font-size: 4rem;
							// display: flex;
							// flex-direction: row-reverse;
							// align-items: center;
							// justify-content: center;
						}
						span{
							color: $color-white;
							@include media-breakpoint-down(sm) {
								// margin-top: 10px;
								// margin-bottom: 16px;
								// display: block;
							}
						}
					}
				}
				&-box{
					background: $color-white;
					width: 100%;
					padding: 30px 20px;
					margin-bottom: 30px;
					@include media-breakpoint-down(sm) {
						padding: 30px 20px 30px 20px;
					}
					&:last-child{
						margin-bottom: 0px;
					}
				}
		}

		&3{
			&-ttl{
				@include fz(3.4rem,3rem);
				@include ff-serif();
				text-align: center;
				font-weight: 600;
				display: flex;
				flex-direction: column;
				align-items: center;
				justify-content: center;
				padding: 80px 0 20px;
				&::after{
					content: "";
					height: 50px;
					width: 1px;
					background: $color-black;
					margin-top: 12px;
				}
			}
		}

		&4{
			&-ttl{
				position: absolute;
				top: 0;
				left: 50%;
				transform: translateX(-50%);
				color: $color-white;
				display: flex;
				justify-content: flex-start;
				flex-direction: column;
				align-items: center;
				height: 100%;
				@include ff-serif();
				@include fz(3.4rem,2rem);
				font-weight: 600;
				&::before{
					content: "";
					background: $color-white;
					width: 1px;
					height: 40%;

				}
			}
			&-mv{
				position: relative;
				text-align: center;
			}
		}
	}
}

.ttl_style {
	&1 {
		@include fz(3rem,2.4rem);
		@include ff-serif();
		@include mt(90px);
		@include mb(70px);
		font-weight: bold;
		@include media-breakpoint-down(sm) {
			@include mt(70px);
			@include mb(50px);
		}
		&::before{
			content: "";
			background: $color-main;
			width: 135px;
			height: 5px;
			display: block;
			@include mb(40px);
			@include media-breakpoint-down(sm) {
				@include mb(20px);
			}
		}
	}

	&2 {
		@include fz(2.6rem, 2.2rem);
		@include ff-serif();
		font-weight: bold;
		@include mt(90px);
		@include mb(40px);
		&::after{
			content: "";
			width: 100%;
			height: 3px;
			display: block;
			@include mt(10px);
			background:linear-gradient(90deg,$color-main 0%,$color-main 100px,$color-gray-light 100px,$color-gray-light 100%);	  
		}
	}

	&3 {
		@include fz(2.4rem,2rem);
		font-weight: bold;
		display: flex;
		line-height: 1;
		@include mt(60px);
		@include mb(30px);
		&::before{
			content: "";
			background: $color-main;
			width: 5px;
			margin-right: 10px;
		}
	}

	&4 {
		@include mt(40px);
		@include mb(30px);
		@include pb(10px);
		@include fz(2.0rem,1.8rem);
		color: $color-main;
		border-bottom: 1px solid $color-black;
		font-weight: bold;
	}

	&5 {
		@include mt(30px);
		@include mb(30px);
		@include fz(1.8rem,1.6rem);
		color: $color-black;
		font-weight: bold;
		&.color__main{
			color: $color-main;
		}
	}

	&6 {
		@include ff-en();
		@include fz(8rem,6rem);
		line-height: 1.0;
		margin-bottom: 30px;
		span{
			color:$color-main;
			@include ff-gothic();
			@include fz(1.8rem,1.6rem);
			font-weight: bold;
			display: block;
			margin-top: 7px;
		}
	}

	&7 {
		@include fz(1.8rem,1.6rem);
		color: $color-main;
		display: flex;
		align-items: flex-start;
		font-weight: bold;
		&::before{
			content: "";
			height: 2px;
			width: 20px;
			background-color: $color-main;
			margin-top: 16px;
			font-weight: bold;
			margin-right: 10px;
		}
	}
}

 h2:not([class]){
 	@extend .ttl_style1;
	margin-top: 0!important;
 }
 h3:not([class]){
 	@extend .ttl_style2;
	 margin-top: 0!important;
 }
 h4:not([class]){
 	@extend .ttl_style3;
	 margin-top: 0!important;
 }
 h5:not([class]){
 	@extend .ttl_style4;
	 margin-top: 0!important;
 }
 h6:not([class]){
 	@extend .ttl_style5;
	 margin-top: 0!important;
 }


.lead_style {
	&1 {
		@include fz(2.6rem,2.2rem);
		@include mb(30px);
		@include ff-serif();
		font-weight: bold;
	}
}

[class^="ttl_style"]+[class^="ttl_style"],
[class^="ttl_style"]:first-child {
	margin-top: 0;
 }


// @for $i from 1 through 6 {
// 	h#{$i}:first-child{
// 		margin-top: 0;
// 	}
// 	@for $j from $i through 6 {
// 		h#{$i} + h#{$j}{
// 			margin-top: 0 !important;
// 		}
// 	}
// }


.link_style {
	&1 {
		margin: 10px 0;

		a {
			color: $color-black;
			position: relative;
			padding-left: 15px;

			&::before {
				content: '\f105';
				font-family: 'FontAwesome';
				position: absolute;
				left: 0px;
				top: 50%;
				transform: translateY(-50%);
			}

			&:hover {
				color: $color-black;
			}
		}

	}
}


.btn_style {
	margin: 10px 0;

	&.w-half{
		a{
			width: 50%;
			@include media-breakpoint-down(sm) {
				width: 100% !important;
			  }
		}
	}

	&.m-center{
		a{
			margin: 0 auto;
		}
	}

	&.m-right{
		a{
			margin: 0 0 0 auto;
		}
	}

	a {
		display: block;
		width: 100%;
		padding: 15px 30px 15px 20px;
		position: relative;
		text-decoration: none;

		&::after {
			position: absolute;
			right: 20px;
			top: 50%;
			transform: translateY(-50%);
		}

		&:hover {
			text-decoration: none;
			opacity: 1;
		}
	}

	&1 {
		a {
			border: 1px solid $color-black;
			&:not([target="_blank"]):not([href*=".pdf"]):not([href*=".doc"]):not([href*=".docx"]):not([href*=".xls"]):not([href*=".xlsx"]){
				&::after{
					content: "";
					background-image: url(/img/common/arrow_next_black.svg);
					background-repeat: no-repeat;
					width: 15px;
					height: 7px;			
					margin-left: 10px;
				}
			}
			&:hover {
				background: $color-main;
				border: 1px solid $color-main;
				color: $color-white;
				&::after {
					color:$color-white !important;
				}
				&:not([target="_blank"]):not([href*=".pdf"]):not([href*=".doc"]):not([href*=".docx"]):not([href*=".xls"]):not([href*=".xlsx"]){
					&::after{
						background-image: url(/img/common/arrow_next_white2.svg);
					}
				}
			}
		}
	}


	&2 {
		margin:0;
		a {
			border-bottom: 1px solid $color-black;
			padding:12px 2.5em 12px 0;
			&:not([target="_blank"]):not([href*=".pdf"]):not([href*=".doc"]):not([href*=".docx"]):not([href*=".xls"]):not([href*=".xlsx"]){
				&::after{
					font-family: 'FontAwesome';
					content: '\f105' ;
					position: absolute;
					display: inline-block;
				}
			}
			&::after {
				right: 10px;
			}
			@include media-breakpoint-down(sm) {
			}
			&:hover {
				color: $color-main;
				&:not([target="_blank"]):not([href*=".pdf"]):not([href*=".doc"]):not([href*=".docx"]):not([href*=".xls"]):not([href*=".xlsx"]){
					&::after{
						// background-image: url(/img/common/icon_arrow_cercle_w.svg);
					}
				}
			}
		}
	}


	&3 {
		a {
			border: 1px solid $color-main;
			background-color: $color-main;
			color: $color-white;
			&::after{
				color:$color-white;
			}
			&:not([target="_blank"]):not([href*=".pdf"]):not([href*=".doc"]):not([href*=".docx"]):not([href*=".xls"]):not([href*=".xlsx"]){
				&::after{
					content: "";
					background-image: url(/img/common/arrow_next_white2.svg);
					background-repeat: no-repeat;
					width: 15px;
					height: 7px;			
					margin-left: 10px;
				}
			}
			&:hover {
				background: $color-white;
				border: 1px solid $color-main;
				color: $color-main;
				&[target="_blank"]{
					&::after{
						color: $color-black;
					}
				}
				&[href*=".pdf"]{
					&::after{
						color: red;
					}
				}
				&[href*=".doc"][href*=".docx"]{
					&::after{
						color: blue;
					}
				}
				&[href*=".xls"][href*=".xlsx"]{
					&::after{
						color: green;
					}
				}
				&:not([target="_blank"]):not([href*=".pdf"]):not([href*=".doc"]):not([href*=".docx"]):not([href*=".xls"]):not([href*=".xlsx"]){
					&::after{
						background-image: url(/img/common/arrow_next_main.svg);
					}
				}
			}
		}
	}

	&_adm {
		a{
			display: flex;
			align-items: center;
			height: 80px;
			background: $color-white;
			border: 1px solid $color-white;
			@include fz(1.8rem,1.6rem);
			font-weight: bold;
			line-height: 1.56;
			&::after{
				content: "";
				background-image: url(/img/common/arrow_next_black.svg);
				background-repeat: no-repeat;
				width: 15px;
				height: 7px;			
				margin-left: 10px;
			}
			&:hover {
				background: $color-main;
				border: 1px solid $color-main;
				color: $color-white;
				&::after{
					background-image: url(/img/common/arrow_next_white2.svg);
				}
			}
			img {
				position: absolute;
				left: 30px;
				top: 50%;
				transform: translateY(-50%);
				display: inline-block;
				width: 50px;
			}
		}
		&.text-center{
			a{
				justify-content: center;
			}
		}
		&.text-right{
			a{
				justify-content: flex-end;
			}
		}
	}

}

.tab_style1 {
  display: flex;
  flex-wrap: wrap;
  padding-left: 0;
  justify-content: space-between;
  margin-bottom: 1em;


  li {
    list-style: none;
    margin-top: 1em !important;
    text-align: center;
    border-bottom: 3px solid $color-gray-light;
    width: 24%;
	@include media-breakpoint-down(sm) {
		margin-top: 0!important;
	}
		&:first-child{
			&:nth-last-child(2){
				&, & ~ li{
					width: 48%;
				}
			} 
			&:nth-last-child(3),&:nth-last-child(6){
				&, & ~  li{
					width: 32.5%;
				}
			} 
			&:nth-last-child(4),&:nth-last-child(7),&:nth-last-child(8){
				&, & ~  li{
					width: 24%;
				}
			} 
			&:nth-last-child(5),&:nth-last-child(n + 9){
				&, & ~  li{
					width: 19%;
				}
			}
		}

    @include media-breakpoint-down(sm) {
      width: 100% !important;
      border-bottom: 0;
      padding: 0;
    }
    &.active {
		border-bottom: 3px solid $color-main;
      a,p {
		position: relative;
		color: $color-main;
      }
    }
    a,p {
		padding-bottom: 5px;
		text-decoration: none;
		display: block;
		color:$color-gray-light;
		font-weight: bold;
      @include media-breakpoint-down(sm) {
        padding: 1em 0;
        &:hover {
          background: $color-gray-light;
        }
      }
    }
  }

  &box {
    position: relative;
		margin-top:1em;
  }
  &head {
    @include media-breakpoint-down(sm) {
      width: 100%;
      display: block;
      padding: 1em;
      border: 1px solid $color-black;
      cursor: pointer;
      &:hover {
        text-decoration: none;
      }
      &.dropdown-toggle {
        display: flex;
        justify-content: space-between;
        align-items: center;
        &::after {
          font-family: FontAwesome;
          content: '\f107';
          border: none;
					color:$color-main;
        }
      }
    }
  }
}

ul{
	&:not([class])>li {
		list-style: none;
		position: relative;
		padding-left: 1.2em;
		margin-bottom: 5px;

		&::before {
			content: "";
			position: absolute;
			top: 0.6em;
			left: 0;
			display: inline-flex;
			justify-content: center;
			align-items: center;
			width: 6px;
			height: 6px;
			@include fz(1.6rem);
			text-align: center;
			background: $color-main;
			@include ff-en();
		}
	}
}
ol {
	padding-left: 0;

	&:not([class])>li {
		list-style: none;
		counter-increment: item;
		position: relative;
		padding-left: 2.2em;
		margin-bottom: 5px;

		&::before {
			position: absolute;
			top: 0.2em;
			left: 0;
			display: inline-flex;
			justify-content: center;
			align-items: center;
			content: counter(item);
			width: 24px;
			height: 24px;
			@include fz(1.6rem);
			text-align: center;
			color: #fff;
			background: $color-main;
			@include ff-en();
		}

		a {
			color: $color-black;
			text-decoration: underline;

			&:hover {
				color: $color-black;
			}
		}
	}
}

.list{
	&_style1{
		padding-left: 1em;
		list-style-type: '\f105';
		>li{
			margin-bottom: 5px;
			a{
				text-decoration: none;
			}
			&::marker{
				content: "\f105";
				color:$color-main;
				font-family: fontawesome;
				letter-spacing: 0.4em;
			}
		}
	}
}

.tag{
	&_style1{
		@include list-reset();
		display: inline-block;
		>li{
			display: inline-block;
			color:$color-white;
			background:$color-main;
			padding: 0 0.4em;
			&:not(:first-child){
				margin-left: 10px;
			}
		}
	}
}

.box{
	&_style1{
		border: 1px solid $color-gray-light;
		padding: 30px!important;
		@include media-breakpoint-down(sm) {
			padding: 30px 15px;
		}
		& + &{
			@include mt(40px, 30px);
		}
		&.bg-gray{
			background-color: $color-gray-light2;
		}
	}
}

.pagelink{
	&_style1{
		@include list-reset();
		display: flex;
		flex-wrap: wrap;
		justify-content: center;
		@include media-breakpoint-down(sm) {
			display: block;
		}
		li{
			border-right: 1px solid $color-gray-dark;
			border-left: 1px solid $color-gray-dark;
			margin-left: -1px;
			margin-bottom: 30px;
			@include media-breakpoint-down(sm) {
				margin-left: 0;
				border-right: none;
				border-left: none;
				border-bottom: 1px solid $color-gray-dark;
			}
			&:last-child{
				border-right: 1px solid $color-gray-dark;
				@include media-breakpoint-down(sm) {
					border-right: none;
				}
			}
			a{
				padding: 0 20px;
				display: block;
				position: relative;
				text-decoration: none;
				@include media-breakpoint-down(sm) {
					padding: 0 27px 0 10px;
				}
				&::after{
					font-family: fontawesome;
					content: "\f107";
					position: absolute;
					color: $color-main;
					transition: .3s;
					left: 50%;
					bottom: -25px;
					transform: translateX(-50%);
					@include media-breakpoint-down(sm) {
						left: auto;
						transform: none;
						bottom: 0;
						right: 10px;
					}
				}
			}
		}
	}
}

/****
** アコーディオン
***/
summary::-webkit-details-marker {
	display: none;
}

// *+.accordion {
	// margin-top: 10px;
// }

div[id]{
	& + .accordion{
		border-top:none;
		&:first-of-type{
			border-top: 1px solid $color-black;
		}
	}
    // padding-top: 80px;
    // margin-top: 80px;
	// @include media-breakpoint-down(sm) {
	// 	padding-top: 70px;
	// 	margin-top: -70px;
	// }
}
div[id]:empty{
	visibility: hidden;
	padding-top: 70px!important;
	margin-top: -70px!important;
	@include media-breakpoint-down(sm) {
		padding-top: 40px!important;
		margin-top: -40px!important;
	}
}


.accordion{
	& + &{
		border-top:none;
	}

	border-top:1px solid $color-black;
	border-bottom:1px solid $color-black;

	//ページないリンク用
	// div[id]{
	// 	padding-top: 180px;
	// 	margin-top: -180px;
	// 	visibility: hidden;
	// 	@include media-breakpoint-down(sm) {
	// 		padding-top: 125px;
	// 		margin-top: -125px;
	// 	}
	// }

	&__trigger {
		display: flex;
		justify-content: space-between;
		align-items: center;
		padding: 20px 25px 20px;
		font-weight: bold;
		@include fz(2.0rem,1.8rem);
	}
	&__icon {
		position: relative;
		display: block;
		width: 30px;
		height: 30px;
		border-radius: 50%;
		padding:2px;
		min-width: 30px;
		margin-left: 10px;
		&::before, &::after{
			display: flex;
			justify-content: center;
			align-items: center;
			content: "";
			position: absolute;
			background: $color-black;
			transition: all 0.25s ease-in-out;
			transform-origin: center center;
			top: 50%;
			left: 50%;
			width: 25px;
			height: 1px;
	
		}
		&::before {
			transform: translateX(-50%) rotate(90deg);
		}
		&::after {
			transform: translateX(-50%);
		}
	}
	&__target {
		overflow: hidden;
		height: auto;
	}
	&__inner {
		padding: 25px 25px 60px 25px;

	}
	&.faq{
		& + &{
			border-top: none;
		}
		border-top:1px solid $color-gray-light;
		border-bottom:1px solid $color-gray-light;
		.accordion{
			&__trigger {
				color: $color-main-dark;
				position: relative;
				flex:1;
				padding: 25px 50px 25px 70px;
				@include fz(1.8rem, 1.6rem);
				&::before{
					position: absolute;
					top:-0.1em;
					left:10px;
					width:1em;
					content:'Q';
					@include ff-en();
					@include fz(4rem, 3.6rem);
					color:$color-main;
					@include media-breakpoint-up(md) {
						left:25px;
						top: 0em;
					}
				}
			}
			&__icon {
				position: absolute;
				top: 50%;
				right:10px;
				transform: translateY(-50%);
				width: 30px;
				height: 30px;
				border-radius: 50%;
				background-color: $color-gray-light;
				&::before, &::after{
					background: $color-main;
					width: 20px;
					height: 1px;
				}
			}
			&__inner {
				padding: 25px 25px 60px 70px;
				background-color: $color-gray-light2;
				position: relative;
				&::before{
					position: absolute;
					top:0.1em;
					left:10px;
					width:1em;
					content:'A';
					color: $color-black;
					@include ff-en();
					@include fz(4rem, 3.6rem);
					@include media-breakpoint-up(md) {
						top:0;
						left:25px;
					}
				}
			}
		}
		// &[open]{
		// 	.accordion__trigger{
		// 		background-color: $color-white;
		// 		color: $color-black;
		// 	}
		// 	.accordion__icon{
		// 		&::before, &::after {
		// 			background: $color-main;
		// 		}
		// 		&::before {
		// 			transform: translateX(-50%) rotate(135deg);
		// 		}
		// 		&::after {
		// 			transform: translateX(-50%) rotate(45deg);
		// 		}
		// 	}
		// }
	}
	&.system{
		// border-color: $color-gray-light;
		border:none;
		@include mb(90px, 60px);
		.accordion{
			&__trigger {
				background: $color-main;
				color: $color-white;
				position: relative;
				flex:1;
				padding: 15px 70px 15px 15px;
				@include fz(1.8rem, 1.6rem);
				@include ff-serif();
				@include media-breakpoint-up(md) {
					padding: 15px 85px 15px 30px;
				}
			}
			&__icon {
				position: absolute;
				top:0;
				right:10px;
				width: 60px;
				height:100%;
				border-radius: 0%;
				background: $color-gray-light;
				&::before,&::after {
					background: $color-main;
					height:2px;
				}
				&::before {
					transform: translateX(-50%) rotate(90deg);
				}
				&::after {
					transform: translateX(-50%);
				}
			}
			&__inner {
				background: $color-gray-light;
				padding: 20px 15px;
				@include media-breakpoint-up(md) {
					padding: 40px 60px;
				}
				position: relative;
			}
		}
	}
}

.accordion[open]{
	& > .accordion__trigger{
		background-color: $color-main;
		color: $color-white;
		.accordion__icon{
			&::before, &::after {
				background: $color-white;
			}
			&::before {
				transform: translateX(-50%) rotate(135deg);
			}
			&::after {
				transform: translateX(-50%) rotate(45deg);
			}
		}
	}
	&.faq{
		.accordion__trigger::before{
			color: $color-white;
		}
		.accordion__icon{
			&::before, &::after {
				background: $color-main;
			}
		}
	}
}


// .accordion+.accordion {
// 	margin-top: 10px;
// }



.mt-be-columns{
	& + .mt-be-columns{
		@include media-breakpoint-down(sm) {
			margin-top: 20px !important;
		}
	}

	@include media-breakpoint-down(sm) {
		display: block !important;
	}
	&#{&}_gap30{
		gap: 30px;
	}

	@include media-breakpoint-up(md) {
		width: 100%;
		display: flex;
		gap: 60px;
	}
	&.sp-column-block{
		display: flex!important;
		gap: 30px;
	}

	&.sp-column-block{
		.mt-be-column{
			margin-top: 0px!important;
		}
	}

	.mt-be-column{
		width: 100%;
		& + .mt-be-column{
			@include media-breakpoint-down(sm) {
				margin-top: 20px;
			}
		}
	}
	

  @at-root .flex-3-7#{&} {
    gap: 30px;
		> .mt-be-column {
			&:first-child {
				width: 100%;
				@include media-breakpoint-up(md) {
					width: 30%;
				}
			}
			&:nth-child(2) {
				width: 100%;
				@include media-breakpoint-up(md) {
					width: 70%;
				}
			}
		}
		
	}
	@at-root .flex-7-3#{&} {
    gap: 30px;
		> .mt-be-column {
			&:first-child {
				width: 100%;
				@include media-breakpoint-up(md) {
					width: 70%;
				}
			}
			&:nth-child(2) {
				width: 100%;
				@include media-breakpoint-up(md) {
					width: 30%;
				}
			}
		}
		&.flex-row-reverse{
			@include media-breakpoint-down(sm) {
				display: flex!important;
				flex-direction: column-reverse!important;
			}
		}
	}
  @at-root .flex-4-1#{&} {
    gap: 30px;
		> .mt-be-column {
			&:first-child {
				width: 100%;
				@include media-breakpoint-up(md) {
					width: 80%;
				}
			}
			&:nth-child(2) {
				width: 100%;
				@include media-breakpoint-up(md) {
					width: 20%;
				}
			}
		}

		&.flex-row-reverse{
			@include media-breakpoint-down(sm) {
				display: flex!important;
				flex-direction: column-reverse!important;
			}
		}
		
	}
	@at-root .flex-1-4#{&} {
		gap: 30px;
			> .mt-be-column {
				&:first-child {
					width: 100%;
					@include media-breakpoint-up(md) {
						width: 20%;
					}
				}
				&:nth-child(2) {
					width: 100%;
					@include media-breakpoint-up(md) {
						width: 80%;
					}
				}
			}
			&.flex-row-reverse{
				@include media-breakpoint-down(sm) {
					display: flex!important;
					flex-direction: column-reverse!important;
				}
			}
	}

	@at-root .flex-3-2#{&} {
		gap: 30px;
			> .mt-be-column {
				&:first-child {
					width: 100%;
					@include media-breakpoint-up(md) {
						width: 60%;
					}
				}
				&:nth-child(2) {
					width: 100%;
					@include media-breakpoint-up(md) {
						width: 40%;
					}
				}
			}
			&.flex-row-reverse{
				@include media-breakpoint-down(sm) {
					display: flex!important;
					flex-direction: column-reverse!important;
				}
			}
	}
	@at-root .flex-2-3#{&} {
		gap: 30px;
			> .mt-be-column {
				&:first-child {
					width: 100%;
					@include media-breakpoint-up(md) {
						width: 40%;
					}
				}
				&:nth-child(2) {
					width: 100%;
					@include media-breakpoint-up(md) {
						width: 60%;
					}
				}
			}
			&.flex-row-reverse{
				@include media-breakpoint-down(sm) {
					display: flex!important;
					flex-direction: column-reverse!important;
				}
			}
	}
}

.table_style{
	&1{
		tr{
			&:first-child{
				th{
					background: $color-main;
					color: $color-white;
					text-align: center;
				}
			}
		}
	}
}

table{
	width: 100%;
	tbody{
		tr{
			th,td{
				padding: 10px ;
				border: 1px solid $color-gray-light!important;
				@include media-breakpoint-down(sm) {
					padding: 5px;
				}
			}
			th{
				background: $color-gray-light2;
				color: $color-main;
			}
		}
	}
}

.js-scrollable{
	table{
		min-width: 945px;
		@include media-breakpoint-down(sm) {
			min-width: 715px!important;
		}
	}
	img{
		@include media-breakpoint-down(sm) {
			width: auto !important;
			max-width: 200%!important;
		}
	}
	.scroll-hint-icon{
		background: $color-main;
		width: 100px;
		height: 100px;
		border-radius: 50%;
	}
	&.sp-scrollable{
		table{
			min-width: auto!important;
			@include media-breakpoint-down(sm) {
				min-width: 715px!important;
			}
		}
	}
}

@include media-breakpoint-down(sm) {
.sp-down{
	table{
		border: none;
		td{
		}
		th,td{
			display: block!important;;
			width: 100%!important;
			height: auto!important;
		}

		// tr{
		// 	border-bottom: 2px solid $color-main;
		// 	&:last-child{
		// 		border-bottom:none;
		// 		td{
		// 			border-bottom:1px solid $color-gray-light!important;
		// 		}
		// 	}
		// }
	}
}
.admissions{
	.sp-down{
		table{
			tr{
				border-bottom: 2px solid $color-main;
				&:last-child{
					border-bottom:none;
					td{
						border-bottom:1px solid $color-gray-light!important;
					}
				}
			}
		}
		
	}
}
}

.simple-table{
	&__key{
		background-color: $color-main-bg;
		font-weight: bold;
		text-align: center;
		border:1px solid $color-gray;
		margin-bottom: 0;
		padding: .7rem;
	}
	&__val{
		border:1px solid $color-gray;
		padding: .7rem;
		border-top:none;
	}
	&__tr{
		&:not(:last-child){
			.simple-table__val{
				border-bottom:none;
			}

		}
	}
}

div[class^="border-"]{
	border-color: $color-gray !important;
	margin: 1em 0;
}







html{
	&.en{
		
.page-ttl{
	&_style {
		&1 {
			&-ttl{
				@include fz(3.4rem,2.8rem);
				@include ff-en();
			}
		}

		&3{
			&-ttl{
				@include fz(3.4rem,2.8rem);
				@include ff-en();
			}
		}

		&4{
			&-ttl{
				@include ff-en();
				@include fz(3.4rem,2.8rem);
				font-weight: 600;
			}
		}
	}
}

.ttl_style {
	&1 {
		@include fz(3rem,2.6rem);
		@include ff-en();
	}

	&2 {
		@include fz(2.6rem,2rem);
		@include ff-en();
		font-weight: bold;
	}

}



.lead_style {
	&1 {
		@include fz(2.6rem);
		@include ff-en();
		font-weight: bold;
	}
}

	}
}
