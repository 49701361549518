@charset 'utf-8';
.page.granddesign2039 {
	.breadcrumb {
		&-item{
			&.active{
				color: $color-white;
			}
			a{
				color: $color-white;
			}
			& + .breadcrumb-item::before {
				color: $color-white;
			}
		}
	}

	main.contents {
		padding-bottom: 0;
	}

	.granddesign2039 {
		&__wrapper {
			position: relative;
			z-index: 0;
			background: url("/about/granddesign2039/img/bg_gradation1.png") repeat-x center top / auto auto;
			@include media-breakpoint-down(sm) {
				background-image: url("/about/granddesign2039/img/bg_gradation1-sp.png");
				overflow: hidden;
			}
		}

		&__header {
			padding: 90px 0;
			@include media-breakpoint-down(sm) {
				padding: 50px 0;
			}
			&-ttl {
				@include ff-en();
				@include fz(10rem,4rem);
				color: $color-white;
				line-height: 1.1;
				text-align: center;
				white-space: nowrap;
			}
		}

		&__main {
			// position: relative;
			padding: 60px 0 120px;
			background: url("/about/granddesign2039/img/bg_main.jpg") no-repeat center bottom / 100% auto;
			@include media-breakpoint-down(sm) {
				padding: 30px 0 90px;
				background-image: url("/about/granddesign2039/img/bg_main-sp.jpg");
			}
		}

		&__pagelink {
			position: sticky;
			left: 18px;
			top: 180px;
			z-index: 5;
			width: 120px;
			margin: 0;
			@include media-breakpoint-down(sm) {
				position: static;
				width: 100%;
				margin-bottom: 100px;
			}
			&::before {
				position: absolute;
				left: 0;
				top: 50%;
				transform: translateY(-50%);
				content: "";
				display: block;
				width: 120px;
				height: 240px;
				background-color: $color-white;
				border-radius: 0 120px 120px 0;
				opacity: 0.7;
				@include media-breakpoint-down(sm) {
					content: none;
				}
			}
			&-list {
				margin: 0;
				padding-left: 20px;
				list-style: none;
				@include media-breakpoint-down(sm) {
					padding: 0 20px;
				}
				li {
					position: relative;
					padding: 5px 0 5px 11px;
					@include fz(1.4rem, 1.6rem);
					line-height: 1;
					@include media-breakpoint-up(md) {
						@include ff-en();
					}
					@include media-breakpoint-down(sm) {
						padding: 0;
						border-bottom: 1px solid $color-white;
					}
					&:not(:last-child) {
						&::after {
							position: absolute;
							left: 2.5px;
							top: 3px;
							content: "";
							display: block;
							width: 0;
							height: calc(100% + 6px);
							border-right: 1px dotted  $color-gray-dark;
							@include media-breakpoint-down(sm) {
								content: none;
							}
						}
					}
					&::before {
						position: absolute;
						left: 0;
						top: 8px;
						content: "";
						display: inline-block;
						width: 6px;
						height: 6px;
						border-radius: 100%;
						background-color: $color-gray-dark;
						@include media-breakpoint-down(sm) {
							content: none;
						}
					}
					a {
						text-decoration: none;
						@include media-breakpoint-down(sm) {
							display: block;
							padding: 16px 0;
							color: $color-white;
						}
						&::after{
							@include media-breakpoint-down(sm) {
								font-family: fontawesome;
								content: "\f107";
								position: absolute;
								right: 0;
								top: 50%;
								transform: translateY(-50%);
								line-height: 1;
							}
						}
					}
				}
			}
		}

		&__inner {
			max-width: 840px;
			padding-left: 20px;
			padding-right: 20px;
		}

		&__ttl {
			&-style1 {
				position: relative;
				margin-bottom: 30px;
				padding-bottom: 40px;
				@include ff-en();
				@include fz(10rem,6rem);
				color: $color-gray-dark;
				font-weight: bold;
				line-height: 1;
				text-align: center;
				white-space: nowrap;
				&::after {
					position: absolute;
					left: 50%;
					bottom: 0;
					transform: translateX(-50%);
					content: "";
					width: 1px;
					height: 36px;
					background-color: $color-gray-dark;
				}
			}
			&-style2 {
				margin-bottom: 1em;
				@include ff-serif();
				@include fz(2.6rem,2.4rem);
				color: $color-main;
				font-weight: bold;
				line-height: 1.67;
				text-align: center;
			}
		}

		&__about {
			position: relative;
			z-index: 2;
			padding: 60px 0;
			text-align: center;
			@include media-breakpoint-down(sm) {
				padding: 40px 0;
				text-align: left;
			}
			&::before {
				content: "";
				position: absolute;
				left: 50%;
				top: -160px;
				z-index: -1;
				transform: translateX(-50%);
				display: block;
				width: 900px;
				height: 900px;
				background: url("/about/granddesign2039/img/bg_round1.png") no-repeat center center / contain;
				@include media-breakpoint-down(sm) {
					top: -100px;
					width: 800px;
					height: 800px;
				}
			}
		}

		&__mission {
			position: relative;
			z-index: 1;
			padding: 60px 0;
			text-align: center;
			@include media-breakpoint-down(sm) {
				padding: 40px 0;
				text-align: left;
			}
			&::before {
				content: "";
				position: absolute;
				left: 50%;
				top: -600px;
				z-index: -1;
				transform: translateX(-50%);
				display: block;
				width: 1200px;
				max-width: 100%;
				height: 1200px;
				background: url("/about/granddesign2039/img/bg_round2.png") no-repeat center center / contain;
				@include media-breakpoint-down(sm) {
					top: -220px;
					width: 800px;
					max-width: initial;
					height: 800px;
				}
			}
			&-list {
				padding: 0;
				list-style: none;
				counter-reset: number 0;
				border-top: 1px dotted $color-gray-dark;
				&_item {
					padding: 40px 0;
					border-bottom: 1px dotted $color-gray-dark;
				}
				&_ttl {
					position: relative;
					margin-bottom: 20px;
					padding-top: 20px;
					@include ff-serif();
					@include fz(2rem,1.8rem);
					font-weight: bold;
					line-height: 1.8;
					text-align: center;
					&::before {
						position: absolute;
						left: 50%;
						top: -30px;
						transform: translateX(-50%);
						counter-increment: number 1;
						content: counter(number);
						@include ff-en();
						@include fz(10rem);
						color: #00abc0;
						font-style: italic;
						line-height: 1;
						opacity: .3;
						@include media-breakpoint-down(sm) {
							width: 60px;
						}
					}
				}
			}
		}

		&__vision {
			position: relative;
			z-index: 1;
			padding: 60px 0 0;
			text-align: center;
			@include media-breakpoint-down(sm) {
				padding: 40px 0 0;
				text-align: left;
			}
			&::before {
				@include media-breakpoint-down(sm) {
					content: "";
					position: absolute;
					left: 0;
					top: 0;
					z-index: -1;
					display: block;
					width: 100%;
					height: 3654px;
					background: url("/about/granddesign2039/img/bg_gradation2-sp.png") repeat-x center center / auto auto;
				}
			}
			&-block {
				position: relative;
				z-index: 0;
				padding: 50px 0 60px;
				@include media-breakpoint-down(sm) {
					padding: 40px 0;
				}
				&::before {
					content: "";
					position: absolute;
					left: 0;
					top: 0;
					z-index: -1;
					width: 92.8%;
					height: 100%;
					background-image: linear-gradient(to right, rgba(255, 255, 255, 1), rgba(255, 255, 255, 0) 98%);
					@include media-breakpoint-down(sm) {
						width: 100%;
					}
				}
				&.-reverse::before {
					left: auto;
					right: 0;
					background-image: linear-gradient(to left, rgba(255, 255, 255, 1), rgba(255, 255, 255, 0) 98%);
				}
				&_ttl {
					@include ff-serif();
					@include fz(2rem,1.8rem);
					color: $color-main;
					font-weight: bold;
					line-height: 1.8;
					text-align: center;
				}
			}
		}

		&__strategy {
			position: relative;
			z-index: 0;
			padding: 240px 0 60px;
			text-align: center;
			@include media-breakpoint-down(sm) {
				z-index: 1;
				padding: 120px 0 0;
				text-align: left;
			}
			&::before {
				content: "";
				position: absolute;
				left: 0;
				top: 100px;
				transform: translateY(-50%);
				z-index: -1;
				display: block;
				width: 100%;
				height: 3687px;
				background: url("/about/granddesign2039/img/bg_gradation2.png") repeat-x center center / auto auto;
				@include media-breakpoint-down(sm) {
					content: none;
				}
			}
			&-inner {
				position: relative;
				z-index: 0;
				&::after {
					content: "";
					position: absolute;
					left: 50%;
					top: -300px;
					z-index: -1;
					transform: translateX(-50%);
					display: block;
					width: 900px;
					height: 900px;
					background: url("/about/granddesign2039/img/bg_round1.png") no-repeat center center / contain;
					@include media-breakpoint-down(sm) {
						top: -180px;
					}
				}
			}
			.granddesign2039__ttl-style1 {
				@include fz(9rem,6rem);
			}
			&-nav {
				display: flex;
				flex-wrap: wrap;
				justify-content: space-between;
				padding: 0;
				list-style: none;
				li {
					@include media-breakpoint-down(sm) {
						width: 50%;
						padding: 2.66vw 0;
					}
					a {
						position: relative;
						display: flex;
						align-items: center;
						justify-content: center;
						width: 180px;
						height: 180px;
						background: $color-white;
						border-radius: 100%;
						@include fz(2rem);
						color: $color-main;
						font-weight: bold;
						line-height: 1.56;
						text-align: center;
						text-decoration: none;
						@include media-breakpoint-down(sm) {
							width: 40vw;
							height: 40vw;
							margin: 0 auto;
						}
						&::after{
							font-family: fontawesome;
							content: "\f107";
							position: absolute;
							left: 50%;
							bottom: 25px;
							transform: translateX(-50%);
							transition: .3s;
							line-height: 1;
						}
					}
				}
			}
			&-wrapper {
				margin-top: -40px;
				padding-top: 100px;
				@include media-breakpoint-down(sm) {
					margin-top: 0px;
    			padding-top: 40px;
				}
				&_ttl {
					margin: 0;
					padding: 25px;
					background-color: $color-main;
					@include fz(2rem,1.8rem);
					color: $color-white;
					text-align: center;
					@include media-breakpoint-down(sm) {
						padding: 15px;
					}
				}
			}
			&-block {
				text-align: left;
				counter-reset: number 0;
				background: $color-white;
				&_item {
					.accordion__trigger {
						padding-left: 32px;
						line-height: 1.6;
					}
					&[open]{
						.accordion__trigger{
							background-color: $color-white;
							color: $color-black;
						}
						.accordion__icon{
							&::before, &::after {
								background: $color-main;
							}
							&::before {
								transform: translateX(-50%) rotate(135deg);
							}
							&::after {
								transform: translateX(-50%) rotate(45deg);
							}
						}
					}
				}
				&_ttl {
					position: relative;
					z-index: 0;
					&::before {
						position: absolute;
						left: -5px;
						top: -15px;
						z-index: -1;
						width: 50px;
						counter-increment: number 1;
						content: counter(number);
						@include ff-en();
						@include fz(9rem,6rem);
						color: $color-gray-light;
						font-style: italic;
						line-height: 1;
						text-align: center;
						@include media-breakpoint-down(sm) {
							left: -12px;
							top: -8px;
						}
					}
				}
			}
		}

		&__leaflet {
			&-bnr {
				display: block;
				background-color: $color-white;
				line-height: 1;
				a {
					&::after {
						content: none;
					}
				}
			}
		}

	}
}
