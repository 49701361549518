@charset "UTF-8";
// @include media-breakpoint-down(sm) {}
// @include media-breakpoint-up(md) {}

// https://kenwheeler.github.io/slick/
// https://tr.you84815.space/slick/

$slick-element-margin: 0px !default;
$slick-extension-padding: 0px !default;
$slick-extension-arrow-position: 0px !default;
$slick-arrow-box-w: 50px !default;
$slick-arrow-box-h: 50px !default;
$slick-arrow-box-margin: 15px !default;
$slick-arrow-font-size: 2rem !default;
$slick-arrow-box-font-size: 3rem !default;
$slick-arrow-color: $color-white !default;
$slick-arrow-bgcolor: $color-text !default;

@mixin slick-responsive($fnNamePrefix, $fnNameSuffix) {
  &.#{$fnNamePrefix}-#{$fnNameSuffix} {
    @content;
  }
  @include media-breakpoint-down(sm) {
    &.#{$fnNamePrefix}-sp-#{$fnNameSuffix} {
      @content;
    }
  }
  @include media-breakpoint-up(md) {
    &.#{$fnNamePrefix}-pc-#{$fnNameSuffix} {
      @content;
    }
  }
}

// --------
// * {
//   min-width: 0 !important;
//   min-height: 0 !important;
// }
.slick-slider {
  // margin-left: -20px;
  // margin-right: -20px;
  &.slick-initialized {
    &.row {
      margin-left: -20px;
      margin-right: -20px;
    }
  }
  .slick-arrow {
    z-index: 1;
    // <button class="slick-prev slick-arrow" aria-label="Previous" type="button" style="">Previous</button>
    &.slick-prev {
      left: $slick-extension-arrow-position;
      &::before {
        content: "\f104"; // <
      }
    }
    // <button class="slick-next slick-arrow" aria-label="Next" type="button" style="">Next</button>
    &.slick-next {
      right: $slick-extension-arrow-position;
      &::before {
        content: "\f105"; // >
      }
    }
    &::before {
      font-family: "FontAwesome";
      font-size: $slick-arrow-font-size;
      display: inline-block;
      width: 1em;
      height: 1em;
      position: absolute;
      top: 50%;
      left: 50%;
      margin-top: -0.5em;
      margin-left: -0.5em;
      line-height: 1;
    }
  }
  > .slick-list {
    margin-left: $slick-element-margin;
    margin-right: $slick-element-margin;
    margin-bottom: 0 !important;
    > .slick-track {
      > .slick-slide {
        border: none;
        margin-left: $slick-extension-padding / 2;
        margin-right: $slick-extension-padding / 2;
        img{
          width: 100%;
        }
      }
    }
  }
  > .slick-counter {
    &-current {
    }
    &-total {
    }
  }
  // -------- .slick-gutter-[20|40|60]
  $gutter_space_list: (
    20: 20px,
    40: 40px,
    60: 60px,
  );
  @each $size, $size_px in $gutter_space_list {
    @include slick-responsive("slick", "gutter-#{$size}") {
      > .slick-list {
        margin-left: -1 * $size_px/2;
        margin-right: -1 * $size_px/2;
        > .slick-track {
          > .slick-slide {
            margin-left: $size_px/2;
            margin-right: $size_px/2;
            &.col {
              padding-left: 0;
              padding-right: 0;
            }
          }
        }
      }
    }
  }
  // -------- .slick-margin-arrow
  @include slick-responsive("slick", "margin-arrow") {
    > .slick-list {
      margin-left: $slick-arrow-box-w;
      margin-right: $slick-arrow-box-w;
    }
  }
  // -------- .slick-type-fade
  @include slick-responsive("slick-type", "fade") {
    > .slick-list {
      margin-left: 0;
      margin-right: 0;
      > .slick-track {
        > .slick-slide {
          margin-left: 0;
          margin-right: 0;
        }
      }
    }
  }
  // -------- .slick-type-noarrow
  @include slick-responsive("slick-type", "noarrow") {
    > .slick-arrow {
      display: none !important;
    }
  }
  // -------- .slick-type-arrow
  @include slick-responsive("slick-type", "arrow") {
    > .slick-arrow {
      width: $slick-element-margin;
      height: 100%;
      &::before {
        font-size: $slick-arrow-font-size;
        color: $slick-arrow-bgcolor;
      }
      transition: 0.2s;
      &:hover {
        background: rgba($color-gray, 0.3);
      }
    }
  }
  // -------- .slick-type-arrow-box
  @include slick-responsive("slick-type", "arrow-box") {
    > .slick-arrow {
      font-size: $slick-arrow-box-font-size;
      width: $slick-arrow-box-w !important;
      height: $slick-arrow-box-h !important;
      background: $slick-arrow-bgcolor;
      box-shadow: 3px 3px 3px 0 rgba($black, 0.3);
      &::before {
        color: $slick-arrow-color;
      }
    }
  }
  // -------- .slick-type-arrow-circle
  @include slick-responsive("slick-type", "arrow-circle") {
    > .slick-arrow {
      font-size: $slick-arrow-box-font-size;
      width: $slick-arrow-box-w !important;
      height: $slick-arrow-box-h !important;
      background: $slick-arrow-bgcolor;
      border-radius: 50rem;
      &::before {
        color: $slick-arrow-color;
      }
    }
  }
  // -------- .slick-type-arrow-out
  @include slick-responsive("slick-type", "arrow-outside") {
    > .slick-arrow {
      &.slick-prev {
        left: #{$slick-arrow-box-w * -1 - $slick-arrow-box-margin};
      }
      &.slick-next {
        right: #{$slick-arrow-box-w * -1 - $slick-arrow-box-margin};
      }
    }
  }
  // -------- .slick-type-nodot
  @include slick-responsive("slick-type", "nodot") {
    > .slick-dots {
      display: none !important;
    }
  }
  // ---- Custom
  & {
    &#slider-xxx {
    }
  }
}

// ---- Dots
// <ul class="slick-dots" style="" role="tablist">
//  <li class="" role="presentation">
//   <button type="button" role="tab" id="slick-slide-control30" aria-controls="slick-slide30" aria-label="1 of 2" tabindex="0" aria-selected="true">1</button>
//  </li>
//  <li role="presentation" class="slick-active">
//   <button type="button" role="tab" id="slick-slide-control31" aria-controls="slick-slide31" aria-label="2 of 2" tabindex="-1">2</button>
//  </li>
//  <li role="presentation" class="">
//   <button type="button" role="tab" id="slick-slide-control32" aria-controls="slick-slide32" aria-label="3 of 2" tabindex="-1">3</button>
//  </li>
//  <li role="presentation" class="">
//   <button type="button" role="tab" id="slick-slide-control33" aria-controls="slick-slide33" aria-label="4 of 2" tabindex="-1">4</button>
//  </li>
// </ul>

$slick-dot-color: $color-white !default;
$slick-dot-color-active: $color-white !default;
$slick-dot-color1: rgba($color-white, 0.4) !default;
$slick-dot-color1-active: $color-white !default;
$slick-dot-color2: rgba($color-main, 0.4) !default;
$slick-dot-color2-active: $color-main !default;
$slick-dot-size: 10px !default;
$slick-dot-margin: 20px !default;
$slick-opacity-default: 1 !default;
$slick-opacity-on-hover: 0.75 !default;
$slick-opacity-not-active: 1 !default;

.slick-slider.slick-dotted {
  margin-bottom: 60px !important;
  .slick-dots {
    position: absolute;
    bottom: 0;
    margin: 0;
    bottom: -30px;
    display: flex;
    justify-content: center;
    li {
      height: $slick-dot-size;
      width: $slick-dot-size;
      margin-left: $slick-dot-margin / 2;
      margin-right: $slick-dot-margin / 2;
      cursor: pointer;
      button {
        height: 100%;
        width: 100%;
        padding: 0;
        &:hover,
        &:focus {
          &:before {
            opacity: $slick-opacity-on-hover;
          }
        }
        &:before {
          content: "";
          width: 100%;
          height: 100%;
          background: $slick-dot-color;
          border-radius: 50%;
          opacity: $slick-opacity-not-active;
        }
      }
      &.slick-active button:before {
        background: $slick-dot-color-active;
        opacity: $slick-opacity-default;
      }
    }
  }
  &.slick-type-dot1 {
    .slick-dots {
      li {
        width: 100%;
        max-width: 50px;
        height: 4px;
        margin-left: 5px;
        margin-right: 5px;
        button:before {
          border-radius: 0;
        }
      }
      .slick-active {
      }
    }
  }
  &.slick-type-dot-color-white {
    li {
      button {
        &:before {
          background: $slick-dot-color1;
        }
      }
      &.slick-active button:before {
        background: $slick-dot-color1-active;
      }
    }
  }
  &.slick-type-dot-color-main {
    li {
      button {
        &:before {
          background: $slick-dot-color2;
        }
      }
      &.slick-active button:before {
        background: $slick-dot-color2-active;
      }
    }
  }
}
