@charset 'utf-8';
.page.admissions {
	&.gakubu{
		.page__mv-copy{
			padding-top: 0!important;
			&-title{
				span{
					font-size: 6rem;
					@include media-breakpoint-down(sm) {
						font-size: 3rem;
					}
				}
			}
			@include media-breakpoint-down(sm) {
				height: 200px!important;
			}
		}
		.admissions__mv-btnlist{
			padding-bottom: 250px!important;
		}

		.accordion__inner{
			span{
				padding: 0.5rem;
			}
		}


		.admissions__gakubu_gaiyo{
			.mt-be-columns{
				@include media-breakpoint-down(sm) {
					padding: 0 10px 10px 10px;
					border: 1px solid $color-main;
				}
			}
		}

		.block{
			// div[id]{
			// 	padding-top: 65px;
			// 	margin-top: -65px;
			// 	@include media-breakpoint-down(sm) {
			// 		padding-top: 0;
			// 		margin-top: 0;
			// 	}
			// }
		}

		picture{
			img{
				margin: 0 auto;
				display: block;
			}
		}
	}
	
	.page__mv {
		padding-bottom: 0;
		.page__mv-copy {
			height: 300px;
			padding-top: 0!important;
			@include media-breakpoint-down(sm) {
				height: 150px!important;
				padding-top: 0!important;
			}
			&::before {
				content: none;
			}
		}
	}

	.page__news-box {
		.page__news-list{
			height: 400px;
			@include media-breakpoint-down(sm) {
				height: 350px;
			}
		}
		.page__event-list{
			height: 470px;
			@include media-breakpoint-down(sm) {
				height: auto;
			}
		}
		.page-imglink_box::after{
			background-color: $color-gray-light2;
		}
	}

	.admissions {
		&__mv {
			&-btnlist {
				&_row {
					display: flex;
					gap: 30px;
					width: 100%;
					@include media-breakpoint-down(sm) {
						display: block;
					}
				}
				&_item {
					width: 100%;
				}
				&_btn{
					a{
						display: flex;
						align-items: center;
						justify-content: center;
						height: 80px;
						background: $color-white;
						border: 1px solid $color-white;
						@include fz(1.8rem,1.6rem);
						font-weight: bold;
						line-height: 1.56;
						text-align: center;
						&::after{
							content: "";
							background-image: url(/img/common/arrow_next_black.svg);
							background-repeat: no-repeat;
							width: 15px;
							height: 7px;			
							margin-left: 10px;
						}
						&:hover {
							background: $color-main;
							border: 1px solid $color-main;
							color: $color-white;
							&::after{
								background-image: url(/img/common/arrow_next_white2.svg);
							}
						}
						img {
							// position: absolute;
							// left: 30px;
							// top: 50%;
							// transform: translateY(-50%);
							display: inline-block;
							width: 50px;
							margin-right: 10px;
						}
					}

					&.mainbg-style{
						a{
							background: $color-main!important;
							color: $color-white!important;
							&::after{
								background-image: url(/img/common/arrow_next_white2.svg);
							}
							&:hover{
								background: $color-white!important;
								color: $color-main!important;
								&::after{
									background-image: url(/img/common/arrow_next_black.svg);
								}
							}
						}
					}
				}

				.btn_style a{
					@include media-breakpoint-down(sm) {
						padding: 15px 25px 15px 20px!important;
						&::after{
							right: 10px!important;
						}
					}
				}
			}
		}
		&__linklist{
			&_box{
				display: flex;
				border-left: 3px solid $color-main;
				@include media-breakpoint-down(sm) {
					display: block;
					border-left: none;
				}
			}
			&_title{
				flex: 0 0 240px;
				font-weight: bold;
				@include fz(2rem,2rem);
				padding-left: 15px;
				color: $color-main;
				@include media-breakpoint-down(sm) {
					border-bottom: 3px solid $color-main;
					margin-bottom: 30px;
				}
			}
			&_main{
				width: 100%;
			}
			&_list{
				display: flex;
				flex-wrap: wrap;
				width: 100%;
				@include media-breakpoint-down(sm) {
					display: block;
					border-left: none;
				}
				.btn_style{
					width: calc(50% - 22.5px);
					margin-right: 45px!important;
					@include list-reset();
					@include media-breakpoint-down(sm) {
						width: 100%;
						margin-right: 0;
					}
					&:nth-child(even){
						margin-right: 0!important;
					}
				}
			}
		}


		//学部入試
		&__gakubu{
			&_gaiyo{
				background: $color-white;
				padding: 60px;
				position: relative;
				top: -150px;
				margin-bottom: -150px;
				@include media-breakpoint-down(sm) {
					padding: 20px;
				}
				.lead_style1{
					text-align: center;
				}
				.list_style1{
					list-style: none;
					padding-left: 0;
					li{
						a{
							padding-top:10px;
							position: relative;
							padding-left: 50px;
							padding-bottom: 10px;
							font-weight: bold;
							text-decoration: none;
							border-bottom: 1px solid $color-gray-light;
							display: block;
							&::after{
								font-family: 'FontAwesome';
								content: '\f107' ;
								position: absolute;
								display: inline-block;
								top: 50%;
								transform: translateY(-50%);
								left: 25px;
								color: $color-main;
							}
						}
						&::marker{
							content: none!important;
						}
					}
				}
				.mt-be-columns{
					align-items: center;
					@include media-breakpoint-down(sm) {
						margin-bottom: 20px;
					}
					.mt-be-column{
						span{
							padding: 0.5rem;
						}
					}
				}
				.ttl_style4{
					border-bottom: 1px solid $color-black;
					font-weight: bold;
					padding-bottom: 10px;
					padding-left: 20px;
					margin-bottom: 10px!important;
				}
			}
		}
	}




}
