@charset 'utf-8';

//各ページMV
.mv-movieover,.mv-moviewrap{
	// @include media-breakpoint-down(sm) {
	// 	display: none;
	// }
}

.overlay, .youtube {

	position: fixed;
	top: 0;
	right: 0;
	min-width: 100%;
	min-height: 100%;
	width: 177.77777778vh!important; /* 16:9 の幅なので 177.77% (= 16 ÷ 9) */
	height: 56.25vw!important; /* 16:9 の高さなので 56.25% (= 9 ÷ 16) */
	
	@include media-breakpoint-down(sm) {
		right: auto;
		left: 50%;
		transform: translateX(-50%);
	}
}
 
.overlay{
	z-index: -1;
}
 
.youtube{
	z-index: -2;
}

#movieStart{
	background-size: cover!important;
	top: 70px;
	right: 0;
	position: fixed;
	width: 100%;
	height: 100%;
	visibility: inherit;
	z-index: -1;
	@include media-breakpoint-down(sm) {
		right: auto;
		left: 50%;
		transform: translateX(-50%);
		background-position: center;
	}
  }

  .home{
	#movieStart{
		background: url("/video/home/top_moviethum.jpg") no-repeat 0 0;
		@include media-breakpoint-down(sm) {
			background: url("/video/home/top_moviethum.jpg") no-repeat center center;
		}
	}
  }
  .about{
	#movieStart{
		background: url("/video/about/about_moviethum.jpg") no-repeat 0 0;
		@include media-breakpoint-down(sm) {
			background: url("/video/about/about_moviethum.jpg") no-repeat 70% 30%;
		}
	}
  }

  .academics{
	#movieStart{
		background: url("/video/academics/academics_moviethum.jpg") no-repeat 0 0;
		@include media-breakpoint-down(sm) {
			background: url("/video/academics/academics_moviethum.jpg") no-repeat center top;
		}
	}
  }

  .research{
	#movieStart{
		background: url("/video/research/research_moviethum.jpg") no-repeat 0 0;
		@include media-breakpoint-down(sm) {
			background: url("/video/research/research_moviethum.jpg") no-repeat 45% top;
		}
	}
  }
  
  .global{
	#movieStart{
		background: url("/video/global/global_moviethum.jpg") no-repeat 0 0;
		@include media-breakpoint-down(sm) {
			background: url("/video/global/global_moviethum.jpg") no-repeat 45% top;
		}
	}
  }
    
  .contribution{
	#movieStart{
		background: url("/video/contribution/contribution_moviethum.jpg") no-repeat 0 0;
		@include media-breakpoint-down(sm) {
			background: url("/video/contribution/contribution_moviethum.jpg") no-repeat 45% top;
		}
	}
  }
   
  .career{
	#movieStart{
		background: url("/video/career/career_moviethum.jpg") no-repeat 0 0;
		@include media-breakpoint-down(sm) {
			background: url("/video/career/career_moviethum.jpg") no-repeat 45% top;
		}
	}
  }
   
  .life{
	#movieStart{
		background: url("/video/life/life_moviethum.jpg") no-repeat 0 0;
		@include media-breakpoint-down(sm) {
			background: url("/video/life/life_moviethum.jpg") no-repeat 40% top;
		}
	}
  }

  .device-js-on #movieStart{
	display: none;
  }

.pc{
	@include media-breakpoint-down(sm) {
		display: none;
	}
}

.sp{
	@include media-breakpoint-up(md) {
		display: none;
	}
}




.contents {
	// @include media-breakpoint-up(sm) {
	// }
	// @include media-breakpoint-up(md) {
	// }
	// @include media-breakpoint-up(lg) {
	// }
	// @include media-breakpoint-up(xl) {
	// }
	width: 100%;
	@include media-breakpoint-up(lg) {
		// min-width: $content-inner;
		margin-left: auto;
		margin-right: auto;
	}
	main {
		padding: $block-gutter-vertical 0;
		@include safe-area-spacing-l-r;
	}
}
// ----------------------------------------------
// .header_category
// ----------------------------------------------
.header_category {
	padding: $grid-gutter-width $grid-gutter-width / 2;
	background-color: map-get($grays, '100');
	text-align: center;
	&__wrapper {
		@extend .inner;
		@include safe-area-spacing-l-r;
	}
	&__in {
	}
	&__en {
	}
	&__ttl {
	}
	@include media-breakpoint-up(md) {
	}
}


.pagination{
	justify-content: center;
	align-items: center;
	@include ff-en();
	@include fz(1.8rem);
	.link_page{
		color: $color-gray-light;
	}
	.current_page{
		color: $color-main;
		background: $color-gray-light2;
		padding: 5px 12px;
		border-radius: 50%;;
	}
	.link_last,.link_first{
		@include fz(1.2rem);
		font-weight: bold;
		padding-top: 3px;
	}
	.link_first{
		margin-right: 10px;
	}
	.link_last{
		margin-left: 10px;
	}
	span,a{
		margin: 0 5px;
		text-decoration: none;
	}
}