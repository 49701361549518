@charset 'utf-8';

.history {
    li {
        list-style: none;
    }

    .timeline-bg-01 {
        color: #fff;
        padding: 0px 0 0;
        background-image: url(../about/history/img/bg.jpg);
        background-repeat: no-repeat;
        background-size: cover;
    }

    .timeline-contents-inner {
        // position: relative;
        max-width: 1080px;
        width: 100%;
        margin: 0 auto;
        @include media-breakpoint-down(sm) {
			max-width: 100%;
		}
    }

    .timeline-list-01 {
        margin-left: 165px;
        padding: 50px 0 20px;
        border-left: 1px dotted rgba($color: #EFEFEF, $alpha: 1.0);
        @include media-breakpoint-down(sm) {
			margin-left: 10%;
            padding: 20px 0 20px;
		}
    }

    .fadein {
        opacity: 0;
        transform: translateY(20px);
        transition: all 1s;
    }

    .timeline-item {
        // position: relative;
        // display: flex;
        justify-content: space-around;
        max-width: 780px;
        @include media-breakpoint-down(sm) {
			max-width: 90%;
		}
    }

    .timeline-year-01,
    .timeline-year-02 {
        // position: absolute;
        top: 0;
        left: -11px;
        width: 100%;
        color: #fff;
        @include ff-en();
        font-size: 80px;
        font-style: italic;
        font-weight: 500;
        line-height: 1.6;
        margin-top: 50px;
        @include media-breakpoint-down(sm) {
			font-size: 50px;
		}
    }

    .timeline-details {
        max-width: 630px;
        width: 100%;
        margin-left: 100px;
        @include media-breakpoint-down(sm) {
			margin-left: 13%;
            width: 90%;
		}
    }

    .timeline-text {
        font-size: 16px;
        line-height: 1.75;
        span{
            margin-bottom: 20px;
        }
    }

    .timeline-details span {
        display: block;
        font-size: 24px;
        font-weight: 700;
        line-height: 1.5;
        @include media-breakpoint-down(sm) {
			font-size: 21px;
		}
    }

    .timeline-img {
        text-align: center;
        margin-top: 60px;
    }

    .timeline-img img {
        max-width: 100%;
    }

    .timeline-year-02:before {
        content: "";
        display: inline-block;
        width: 44px;
        height: 44px;
        background-image: url(../about/history/img/blue_btn.png);
        background-repeat: no-repeat;
        vertical-align: middle;
        margin-right: 56px;
        position: relative;
        left: -22px;
        @include media-breakpoint-down(sm) {
			margin-right: 10%;
            background-size: 22px;
            width: 22px;
            height: 22px;
		}
    }

    .timeline-year-022:before {
        content: "";
        display: inline-block;
        width: 44px;
        height: 44px;
        background-image: url(../about/history/img/blue_btn.png);
        background-repeat: no-repeat;
        vertical-align: middle;
        margin-right: 56px;
        position: relative;
        left: -22px;
        @include media-breakpoint-down(sm) {
			// margin-right: 7%;
            background-size: 22px;
            margin-right: 0;
            background-size: 22px;
            width: 50px;
            height: 22px;

            
		}
    }

    .timeline-year-01:before {
        content: "";
        display: inline-block;
        width: 16px;
        height: 16px;
        background-image: url(../about/history/img/white_btn.png);
        vertical-align: middle;
        margin-right: 85px;
        position: relative;
        left: -8px;
        @include media-breakpoint-down(sm) {
			margin-right: 10%;
		}
    }

    .top {
        height: 270px;
        font-size: 36px;
        line-height: 1.39;
        font-weight: 800;
        text-align: center;
        @include ff-serif();
        padding-top: 100px;
        // background-image: url(../about/history/img/history.svg);
        // background-repeat: no-repeat;
        // background-position: 50% 70%;
        @include media-breakpoint-down(sm) {
			height: 100px;
            background-size: contain;
            width: 80%;
            margin: auto;
            font-size: 24px;
            padding-top: 30px;
		}
        img{
            margin-top: -30px;
        }
        .title{
            @include ff-serif();
            font-weight: 700;
        }
    }

    .timeline-year-022 {
        font-size: 30px;
        line-height: 1.5;
        @include ff-serif();
        top: 0;
        left: -11px;
        width: 100%;
        color: #fff;
        font-weight: 700;
        line-height: 1.6;
        margin-bottom: 20px;
        @include media-breakpoint-down(sm) {
			font-size: 24px;
            display: flex;
		}
    }

    .timeline-details:first-child {
        display: flex;
        justify-content: space-between;
        @include media-breakpoint-down(sm) {
			display: block;
		}

    }

    ul li:nth-child(3) {
        .timeline-img {
            width: 45%;
            @include media-breakpoint-down(sm) {
                width: 100%;
            }
        }
    }

    .timeline-year-022:nth-child(2):before {
        opacity: 1;
    }

    .timeline-year-022:first-child {
        margin-top: 100px;
    }

    .ayumi {
        max-width: 630px;
        margin: 60px auto 120px 100px;
        @include media-breakpoint-down(sm) {
            margin: 50px 10%;
        }
        .page-btn_style1::before,
        .page-btn_style1::after {
            background-color: #fff;
        }

        a {
            color: #fff;
        }

    }

    .timeline-item{
        &.text-bg{
            position: relative;
            .text-bg-item {
                position: absolute;
                top: 0;
                right: 0;
                background-size: contain;
                background-repeat: no-repeat;
                width: 95px;
                height: 100%;
                &.en{
                    
                }
                &.old{
                    background-image: url(/about/history/img/kigen.svg);
                }
                &.old-en{
                    background-image: url(/en/about/history/img/kigen.svg);
                }
                &.now{
                    background-image: url(/about/history/img/genzai.svg);
                    top: -32px;
                    @include media-breakpoint-down(sm) {
                        top: 0;
                    }
                }
                &.now-en{
                    background-image: url(/en/about/history/img/genzai.svg);
                }
            }
            .timeline-year-022,.timeline-year-02{
                margin-left: -12px;
                @include media-breakpoint-down(sm) {
                    padding-top: 40px;
                }
            }
        }
    }
    ul li:first-child {
        // position: relative;

        // .timeline-year-02,
        // .timeline-details {
        //     position: absolute;
        // }



        .timeline-details {
            top: 175px;
            @include media-breakpoint-down(sm) {
                top: 130px;
            }
        }

        .timeline-year-02:before {
            left: -11px;
            @include media-breakpoint-down(sm) {
                left: 0px;
            }

        }
    }
    .timeline-list-01 li:nth-child(2){
        @include media-breakpoint-down(sm) {
            margin-top: 60px;
        }
    }
    // .timeline-list-01 li:nth-child(8){
    //     @include media-breakpoint-down(sm) {
    //         margin-top: 70px;
    //     }
    // }
    .timeline-list-01 li:nth-child(8) {
        // position: relative;
        margin-top: 120px;
        @include media-breakpoint-down(sm) {
            margin-top: 60px;
        }
        .timeline-year-022 {
            // position: absolute;
            padding-left: 8px;
            @include media-breakpoint-down(sm) {
                margin-left: 0%;
                padding-left: 0px;
            }
        }

        .timeline-year-022:nth-child(2) {
            top: 40px;
        }

        .timeline-details {
            // position: absolute;
        }

        .kigen {
            text-align: right;
            max-width: 700px;
            padding-bottom: 70px;
            @include media-breakpoint-down(sm) {
                padding-bottom: 150px;
            }
        }

        .timeline-details {
            top: 107px;
            @include media-breakpoint-down(sm) {
                top: 140px;
            }
        }

        .timeline-year-022:before {
            left: -18px;
            @include media-breakpoint-down(sm) {
                left: -12px;
                top: 8px;
                // content: none;
            }
        }
    }

    .timeline-list-01 li:nth-child(9) {
        .timeline-img{
            @include media-breakpoint-down(sm) {
                margin-top: 60px;
            }
        }
    }

    [data-emergence=hidden] {
        opacity: 0;
    }

    [data-emergence=visible] {
        opacity: 1;
        transform: translateX(0);
        transition: 2s;
    }




    &.en{
        .top {
            height: 200px;
            @include media-breakpoint-down(sm) {
                height: 140px;
            }
    }
}
}





