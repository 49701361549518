@charset 'utf-8';
.events{
	&__post_end{
		color: $color-red;
		@include fz(2rem,1.8rem);
		font-weight: bold;
		margin-bottom: 20px;
	}
	&__menu{
		display: flex;
		align-items: flex-start;
		@include media-breakpoint-down(sm) {
			flex-direction: column;
		}
		&:first-child{
			margin-bottom: 10px;
		}
		&_box{
			background: $color-main;
			padding: 30px;
			margin-bottom: 40px;
			@include media-breakpoint-down(sm) {
				padding: 20px;
			}
		}
		&_ttl{
			background: $color-white;
			padding: 5px 10px;
			margin-right: 25px;
			flex: 0 0 105px;
			@include media-breakpoint-down(sm) {
				flex: 0 0 auto;
				margin-right: 0;
				margin-bottom: 10px;
				padding: 2px 5px;
			}
		}
		&_text{
			color: $color-white;
		}
		span{
			display: block;
			font-weight: bold;
		}
		.post-event-hostday{
			color: $color-main;
		}
		.post-event-place{
			color: $color-main;
		}
	}
	.post-sys{
		&__thumnail{
			position: relative;
		}
	}
	.post-event-date{
		position: absolute;
		top: 0;
		left: 0;
		background: $color-white;
		padding: 5px 10px;
		span{
			display: block;
		}
		.year{
			@include fz(1.2rem);
			margin-bottom: 0;
		}
		.month{
			@include fz(1.6rem);
			margin: 3px 0;
		}
		.day{
			@include fz(3rem);
			word-break: normal;
		}
	}
	.post-event-text{
		.post-label-1{
			display: inline-block!important;
		}
		span{
			display: block;
		}
	}



	&.detail{
		.post-sys__date{
			@include fz(3rem,2.4rem);
			@include media-breakpoint-down(sm) {
				margin-bottom: 0;
			}
		}
		.post-label-1{
			font-family: $font-family-sans-serif;
			@include fz(1.6rem,1.4rem);
			font-weight: normal;
			line-height: 1;
			padding: 5px 10px;
		}
	}



	&.event-end{
		.more-btn{
			cursor: pointer;
			max-width: 300px;
			background-color: $color-main;
			color: $color-white;
			text-align: center;
			padding: 10px;
			margin: 50px auto  0;
		}

		.post-sys__item.is-hidden{
			display: none;
		}
	}
}