@charset 'utf-8';
.page{
	//インデックスページ
	&.mv-1{
		.block{
			background: $color-white;
		}
		//パンクズ
		.breadcrumb {
			&-item{
				text-shadow: 0px 2px 3px rgba(0, 0, 0,.8);
			  &.active{
				color: $color-white;
			  }
			  a{
				color: $color-white;
			  }
			  & + .breadcrumb-item::before {
				content: "ー";
				color: $color-white;
			  }
			  color: $color-white;
			}
		  }
		.page{
			&__mv{
				position: relative;
				padding-bottom: 330px;
				@include media-breakpoint-down(sm) {
					padding-bottom: 60px;
				}
				&-video{
					position: fixed;
					z-index: -2;
					width: 100%;
					height: 100vh;
					object-fit: cover;
					img{
						width: 100%;
						height: 100vh;
						object-fit: cover;
					}
				}
				&-copy{
					color: $color-white;
					height: 400px;
					display: flex;
					justify-content: center;
					flex-direction: column;
					align-items: center;
					text-shadow: 0px 3px 6px rgba(0, 0, 0, .8);
					@include media-breakpoint-down(sm) {
						height: 350px;
					}
					&::before{
						content: "";
						background: $color-white;
						width: 1px;
						height: 100%;
						margin-bottom: 30px;
						@include media-breakpoint-down(sm) {
							content: none;
						}
					}
					&-title{
						@include ff-en();
						@include fz(9rem,5rem);
						text-align: center;
						span{
							font-family: nomal;
							@include ff-serif();
							@include fz(3rem,1.8rem);
							display: block;
						}
					}
				}
			}
			&__lead{
				position: relative;
				padding-top: 150px;
				padding-bottom: 150px;
				@include media-breakpoint-down(sm) {
					padding-top: 60px;
					padding-bottom: 60px;
				}
				&-box{
					display: flex;
					padding-left: 360px;
					text-shadow: 0px 2px 3px rgba(0, 0, 0, .8);
					@include media-breakpoint-down(sm) {
						display: block;
						padding-left: 0px;
					}
				}
				&-bgtext{
					color: $color-gray-light2;
					opacity: .2;
					@include fz(9rem,4rem);
					@include ff-en();
					line-height: 1.25;
					position: absolute;
					text-indent: -6rem;
					padding-left: 6rem;
					@include media-breakpoint-down(sm) {
						writing-mode:horizontal-tb;
						right: 0;
						text-indent: -1rem;
						padding-left: 1rem;
						padding-top: 14px;
						line-height: 1;
					}
				}
				&-title{
					color: $color-white;
					-ms-writing-mode: tb-rl;
					writing-mode: vertical-rl;
					@include ff-serif();
					font-weight: 500;
					@include media-breakpoint-down(sm) {
						writing-mode:horizontal-tb;
						padding-bottom: 40px
					}
					.nakaguro{
						&.academics{
							height: 30px;
							&::before{
								width: 5px;
								height: 5px;
							}
						}
						@include media-breakpoint-down(sm) {
							display: none;
						}
					}
				}
				&-text{
					@include ff-serif();
					color: $color-white;
					font-weight: 500;
					margin-left: 30px;
					line-height: 2.2;
					@include media-breakpoint-down(sm) {
						margin-left: 0px;
					}
				}
			}
		}
	}

	&__news{
		&-block{
			display: flex;
			gap: 20px;
			align-items: flex-start;
			height: 100%;
			@include media-breakpoint-down(sm) {
				display: block;
			}
		}
		&-flex{
			padding: 40px 10px 40px 40px;
			display: flex;
			@include media-breakpoint-down(sm) {
				display: block;
				padding: 40px 10px 40px 20px;
			}
			&.event{
				flex-direction: row-reverse;
				justify-content: space-between;
				padding: 40px 40px 40px 40px;
				@include media-breakpoint-down(sm) {
					display: block;
					padding: 40px 10px 40px 20px;
				}
			}
		}
		&-box{
			background: $color-gray-light2;
			width: 100%;
			@include media-breakpoint-down(sm) {
				margin-bottom: 30px;
				padding-bottom: 30px;
			}
			&.box{
				&-left{
					.home__btn-style1{
						margin: 0 40px 30px auto;
						a{
							text-decoration: none;
						}
					}
				}
				&-right{
					.home__btn-style1{
						margin: 0 150px 30px auto;
						@include media-breakpoint-down(sm) {
							margin: 0 40px 30px auto;
						}
						a{
							text-decoration: none;
						}
					}
				}
			}

		}
		&-title{
			flex: 0 0 40px;
			margin-right: 40px;
			@include media-breakpoint-down(sm) {
				margin-right: 0px;
				margin-bottom: 30px;
			}
		}
		&-list{
			height: 620px;
			overflow: auto;
			padding-right: 30px;
			margin-top: 70px;
			width: 100%;
			@include media-breakpoint-down(sm) {
				height: 350px;
				padding-right: 20px;
				margin-top: 0px;
			}
			.home__btn-style1{
				margin: 0 0 0 auto;
				a{
					text-decoration: none;
				}
			}
		}
		&-item{
			list-style: none;
			margin-bottom: 20px;
			&:last-child{
				margin-bottom: 0;
			}
			&.fixed-item{
				.page__news-textbox{
					&::before{
						content:"";
						width: 30px;
						height: 30px;
						background-image: url("/img/common/news_fixed_icon.png");
						margin-right: 10px;
						z-index: 1;
					}
				}
			}
		}
		&-link{
			text-decoration: none;
			border-bottom: 1px solid $color-gray-dark;
			padding-bottom: 10px;
		}
		&-textbox{
			display: flex;
			line-height: 1;
			flex-wrap: wrap;
			align-items: center;
			margin-bottom: 5px;
		}
		&-cate{
			@include fz(1.4rem);
			border-left: 1px solid $color-black;
			padding-left: 10px;
			margin-left: 10px;
			margin-bottom: 8px;
		}
		&-date{
			@include ff-barlow();
			@include fz(1.4rem);
			margin-bottom: 8px;
		}
		&.news_only{
			.page__news-list{
				height: 300px;
			}
			.page__news-list{
				margin-top: 0;
			}
		}
	}

	&__event{
		&-link{
			display: flex;
			.post-event-date{
				flex: 0 0 75px;
			}
		}
		&-item{
			list-style: none;
			margin-bottom: 30px;
			&:last-child{
				margin-bottom: 0;
			}
		}
		&-title{
			flex: 0 0 40px;
			margin-left: 30px;
			@include media-breakpoint-down(sm) {
				margin-left: 0px;
				margin-bottom: 30px;
			}
		}
		&-list{
			height: 620px;
			overflow: auto;
			margin-top: 70px;
			width: 100%;
			@include media-breakpoint-down(sm) {
				height: 400px;
				padding-right: 20px;
				margin-top: 0px;
			}
			.home__btn-style1{
				margin: 0 0 0 auto;
				a{
					text-decoration: none;
				}
			}
		}
		&-link{
			text-decoration: none;
			.post-event{
				&-date{
					text-align: center;
					border-right: 2px solid $color-main;
					padding-right: 20px;
					margin-right: 20px;
					span{
						display: block;
					}
				}
				&-content{
					display: block;
				}
				&-hostday{
					margin-bottom: 0px;
				}
				&-place{
					margin-bottom: 0px;
				}
			}
		}
	}


	&__bnrstyle{
		&1{
			border: 1px solid $color-gray-light;
			width: 100%;
			img{
				object-fit: cover;
				aspect-ratio: 3/1;
				width: 100%;
			}
			.arrow-r{
				&::after{
					right: 30px;
					@include media-breakpoint-down(sm) {
						right: 10px;
					}
				}
			}
			&-text{
				@include fz(2.2rem,1.6rem);
				font-weight: bold;
				flex: 0 0 50%;
				padding: 0 40px;
				@include media-breakpoint-down(sm) {
					padding: 20px 30px 20px 20px;
				}
			}
			&-link{
				display: flex;
				align-items: center;
				text-decoration: none;
				@include media-breakpoint-down(sm) {
					display: block;
				}
				figure{
					flex: 0 0 50%;
					margin-bottom: 0;
				}
				&::after{
					content: none!important;
				}
			}
		}

	}



	.recommend{
		&__box{
			background: $color-gray-light2;
			padding: 60px 0;
			border-left: 10px solid $color-main;
		}
		&__ttl{
			@include fz(6rem,3rem);
			@include ff-serif();
		}
		&__list{
			margin-left: 1em;
			display: flex;
			flex-wrap: wrap;
			@include media-breakpoint-down(sm) {
				margin-left: 0;
			}
		}
		&__item{
			flex: 0 0 49%;
			list-style: none;
		}
		&__link{
			text-decoration: none;
			display: inline-flex;
			align-items: flex-start;
			&::before{
				content: "";
				background-color: $color-main;
				width: 10px;
				flex: 0 0 10px;
				height: 1px;
				margin-right: 15px;
				margin-top: 11px;
			}
		}
		&__slide{
			position: relative;
			li{
				list-style: none;
			}
			.slide-arrow{
				position: absolute;
				right: 0;
				bottom: 0px;
				cursor: pointer;
				&.prev-arrow{
					right: 45px;
				}
			}
			&_text{
				@include fz(2rem,1.6rem);
				font-weight: bold;
				margin-top: 5px;
			}

			&_cap{
				.slick-arrow{
					&::before{
						content: none;
					}
				}
			}
		}
	}

	.special_bnr{
		position: relative;
		.title{
			position: absolute;
			top: 50px;
			left: 40px;
			@include fz(4rem,2.8rem);
			font-weight: bold;
			color: $color-white;
			@include ff-en();
			line-height: 1.2;
			@media screen and (min-width:768px) and ( max-width:1100px) {
				top: 20px;
			}
			@include media-breakpoint-down(sm) {
				top: 30px;
				left: 20px;
			}

			&.ja{
				@include fz(3.4rem,2.3rem);
			}
		}
		.text{
			position: absolute;
			bottom: 40px;
			color: $color-white;
			padding: 40px;
			@include fz(1.8rem,1.2rem);
			@media screen and (min-width:768px) and ( max-width:1100px) {
				padding: 0 40px;
				bottom: 20px;
			}
			@media screen and (min-width:768px) and ( max-width:1100px) {
				font-size: unquote('min(1.4rem, 1.5vw)'); 
			}
			@include media-breakpoint-down(sm) {
				padding: 20px;
				bottom: 10px;
				line-height: 1.4;
			}
		}
	}

	.home__innovative-link figure::after{
		content: none;
	}


	&.en{
		.page__news-list{
			margin-top: 70px;
			height: 235px;
		}
	}
	
}






.contact{
	.box_style1{
		padding: 30px!important;
	}
}