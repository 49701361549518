@charset 'utf-8';
* {
	min-height:0;
	min-width :0;
	}
	

.home {

	// @include media-breakpoint-up(sm) {
	// }
	// @include media-breakpoint-up(md) {
	// }
	// @include media-breakpoint-up(lg) {
	// }
	// @include media-breakpoint-up(xl) {
	// }
	// background-image: url("/video/home/sample.jpg");
	// background-size: cover;
	// background-repeat: no-repeat;
	// position: fixed;
	// width: 100%;
	// height: 100vh;
	// display: block;
	a{
		text-decoration: none;
	  }
	main{
		// @include media-breakpoint-down(md) {
		// 	z-index: -1;
		// 	position: relative;
		// }
	}
	&__btn{
		&-style{
			&1{
				width: 110px;
				a{
					padding: 5px 0;
					display: flex;
					justify-content: space-between;
					align-items: center;
					@include ff-en();
					&::after{
						content: "";
						background-image: url("/img/common/arrow_next_black.svg");
						background-repeat: no-repeat;
						width: 20px;
						height: 10px;
						margin-left: 10px;
					}
					&:hover{
						opacity: 1;
					}
				}
				&::before,&::after{
					content: "";
					background-color: $color-black;
					height: 1px;
					width: 100%;
					display: block;
					transition: .15s linear;
					position: relative;
					bottom: 0;
				}
				&:hover{
					&::after{
						position: relative;
						bottom: -5px;
					}
				}
			}
		}
	}
	&__wrapper{
		position: relative;
		// height: 100vh;
		.top-box{
			display: flex;
			flex-direction: column;
			@include media-breakpoint-down(sm) {
				flex-direction: column-reverse;
			}
		}
	}
	&__important{
		@include fz(1.4rem);
		position: relative;
		z-index: 2;
		display: none;
		overflow: hidden;
		top: 0;
		&.active{
			display: flex;
			@include media-breakpoint-down(sm) {
				display: block;
			}
		}
		.slick-list{
			z-index: 1;
		}
		.slick-dotted{
			margin-bottom: 0px !important;
		}
		.slick-dots{
			justify-content: flex-start!important;
			left: -70px;
			top: 13px;		
			bottom: auto!important;
			@include media-breakpoint-down(sm) {
				left: 150px;
				top: -22px;
			}
		}
		.slick-dots > li:first-child:last-child {
			display: none;
		}
		.slick-dots li{
			margin-left: 0px!important;
			@include media-breakpoint-down(sm) {
				margin-right: 20px!important;
			}
			&.slick-active{
				position: relative;
				button{
					color: #feff50!important;
				}
				&::after{
					content: "";
					position: absolute;
					width: 8px;
					height: 1px;
					background-color: #feff50;
					bottom: -5px;
					left: 1px;
				}
			}
		}
		.slick-dots li button{
			font-size:initial;
			color:white;
			background:initial;
		}
		.slick-dots li button:before{
			content: none !important;;
		}
		
		&-title{
			background: $color-black-dark;
			color: $color-white;
			padding: 5px 0;
			flex: 0 0 300px;
			text-align: center;
			@include media-breakpoint-down(sm) {
				width: 100% ;
				text-align: left;
				padding-left: 20px;
			}
		}
		&-list{
			width: calc(100% - 200px);
			background: $color-red;
			@include media-breakpoint-down(sm) {
				width: 100% ;
			}
		}
		&-item{
			list-style: none;
			color: $color-white;
			margin-left: 20px;
		}
		&-link{
			text-decoration: none;
			padding: 5px 0 5px 20px;
			display: block;
			white-space: nowrap;
			overflow: hidden;
			text-overflow: ellipsis;
			@include media-breakpoint-down(sm) {
				padding: 10px 0 10px 20px;
			}
			.post-date-1{
				border-right: 1px solid $color-white;
				margin-right: 10px;
				padding-right: 10px;
				line-height: 1;
				@include media-breakpoint-down(sm) {
					display: block;
					border-right: none;
					margin-bottom: 5px;
				}
			}
		}
	}
	&__mv{
		position: relative;
		padding-bottom: 1100px;
		@include media-breakpoint-down(sm) {
			padding-bottom: 60px;
		}
		// &::before{
		// 	content: "";
		// 	width: 100%;
		// 	height: 100%;
		// 	background: rgba(0,0,0,.1);
		// 	position: absolute;
		// 	top: 0;
		// }
		&-video{
			position: fixed;
			z-index: -1;
			width: 100%;
			height: 100vh;
			object-fit: cover;
			img{
				width: 100%;
				height: 100vh;
				object-fit: cover;
			}
		}
		&-copy{
			&-box{
				display: flex;
				align-items: flex-end;
				position: absolute;
				left: 100px;
				top: 250px;
				@include media-breakpoint-down(md) {
					left: 20px;
				}
				@include media-breakpoint-down(sm) {
					display: flex;
					align-items: flex-start;
					flex-direction: column-reverse;
					position: static;
					padding: 100px 20px 0;
				}
			}
			&-title{
				padding-left: 320px;
				padding-bottom: 350px;
				width: 100%;
				@include media-breakpoint-down(sm) {
					padding-left: 30px;
					padding-bottom: 0px;
					width: 30%;
					max-width: 100px;
					margin-bottom: 30px;
				}
				img{
					width: 100%;
				}
			}
			&-text{
				position: absolute;
				top: 475px;
				right: 0px;
				color: $color-white;
				@include ff-serif();
				@include fz(1.7rem,1.6rem);
				font-weight: 100;
				line-height: 2.4;
				text-shadow:0px 3px 6px #000;
				@include media-breakpoint-down(sm) {
					position: static;
					width: 100%;
					max-width: 300px;
				}
			}
		}
	}


	&__about{
		background: $color-white;
		overflow-x: hidden;
		&-inner{
			width: 100%;
			display: block;
			padding-top: 160px;
			background-image: url("/img/home/about_bk.jpg");
			background-repeat: no-repeat;
			background-size: cover;
			display: block;
			margin-top: 350px;
			@include media-breakpoint-down(sm) {
				padding-top: 0;
				margin-top: 550px;
			}
		}
		&-content{
			display: flex;
			margin-left: 85px;
			min-width: 1600px;
			margin-top: -352px;
			@include media-breakpoint-down(md) {
				margin-left: 20px;
				margin-top: -325px;
			}
			@include media-breakpoint-down(sm) {
				display: block;
				margin-left: 0px;
				margin-right: 0px;
				position: relative;
				padding-top: 70px;

				min-width:auto;
			}
		}
		&-title{
			margin-right: 30px;
			@include media-breakpoint-down(sm) {
				margin-right: 0;
				position: absolute;
				top: -146px;
				left: 50%;
				transform: translateX(-50%);
			}
			img{
				max-width: 140px;
				@include media-breakpoint-down(md) {
					max-width: 120px;
				}
				@include media-breakpoint-down(sm) {
					max-width: 140px;
				}
			}
		}
		&-slider1{
			width: 80%;
			@include media-breakpoint-down(sm) {
				width: 100%;
				padding: 0 20px;
			}
			.home{
				&-slider1{
					&-item{
						padding: 0 15px;
						@include media-breakpoint-down(sm) {
							padding: 0 ;
						}
					}
					&-link{
						@include imghover;
						img{
							aspect-ratio: 4/3;
							object-fit: cover;
						}
						figure{
							position: relative;
							&::before{
								content: "";
								background-image: url("/img/common/arrow_next_black.svg");
								background-position: center;
								background-repeat: no-repeat;
								width: 20px;
								height: 10px;
								background-color: $color-white;
								position: absolute;
								bottom: 0;
								right: -1px;
								opacity: 1;
								z-index: 2;
								padding: 20px;
							}
						}
					}
					
					&-num{
						position: absolute;
						@include ff-en();
						top: 0;
						right: 0;
						@include fz(1.4rem);
						line-height: 1;
						border-left: 1px solid $color-black;
						padding-top: 10px;
						padding-right: 10px;
						padding-left: 8px;
						span{
							@include fz(5.6rem);
							font-weight: 100;
							display: block;
							line-height: .8;
							margin-top: 5px;
							letter-spacing: 3px;
						}
					}
					&-text{
						color: $color-white;
					}
					.slick-arrow{
						background: $color-black-dark;
						padding: 20px;
						@include media-breakpoint-up(md) {
							background: none;
						}
						&::before{
							content:""!important;
							position: absolute;
							top: 50%;
							left: 15px;
							margin: 0 auto;
							text-align: centers;
							transform: translateY(-50%);
							width: 10px;
							height: 19px;
							opacity: 1;
							margin-top:0em;
						  }
					}
					  .slick-prev{
						left: -20px;
						@include media-breakpoint-up(md) {
							left: -165px;
							top: auto!important;
							bottom: -65px;
						}
						@media (max-width: 1080px) {
							bottom: -95px;
						}
					  }
					  .slick-next{
						right: -20px;
						@include media-breakpoint-up(md) {
							right: auto;
							left: -115px;
							top: auto!important;
							bottom: -65px;
						}
						@media (max-width: 1080px) {
							bottom: -95px;
						}
					  }
					  .slick-next:before{
						background-image: url("/img/common/arrow_next_white.svg")!important;
						background-repeat: no-repeat;
						background-size: 100%;
						@include media-breakpoint-up(md) {
							width: 15px;
							height: 30px;
						}
					  }
					
					  .slick-prev:before{
						background-image: url("/img/common/arrow_prev_white.svg")!important;
						background-repeat: no-repeat;
						background-size: 100%;
						@include media-breakpoint-up(md) {
							width: 15px;
							height: 30px;
						}
					  }
				}
			}
		}
		&-slider2{
			width: 100%;
			background-color: rgba(0,0,0,0.6);
			margin-top: 60px;
			@include media-breakpoint-down(md) {
				margin-top: 30px;
			}
			.home{
				&-slider2{
					max-width: 1600px;
					margin: 0 auto;
					padding: 70px 100px;
					@include media-breakpoint-down(md) {
						max-width: none;
						padding: 40px 70px;
					}
					@include media-breakpoint-down(sm) {
						padding: 20px;
					}
					&-item{
						padding: 0 10px;
					}
					.slick-arrow:before{
						content:""!important;
						position: absolute;
						top: -4px;
						width: 20px;
						height: 33px;
						opacity: 1;
						margin-top:0em;
					  }
					  .slick-prev{
						left: 50px;
						@include media-breakpoint-down(md) {
							left: 20px;
						}
					  }
					  .slick-next{
						right: 50px;
						@include media-breakpoint-down(md) {
							right: 20px;
						}
					  }
					  .slick-next:before{
						background-image: url("/img/common/arrow_next_white.svg")!important;
						background-repeat: no-repeat;
					  }
					
					  .slick-prev:before{
						background-image: url("/img/common/arrow_prev_white.svg")!important;
						background-repeat: no-repeat;
					  }
				}
			}
		}
	}

	&__section{
		position: relative;
		background: $color-white;
		padding-bottom: 160px;
		&::before{
			content: "";
			background: linear-gradient(#004a98 0%, #152740 100%);
			width: 300px;
			height: 100%;
			position: absolute;
			z-index: 0;
			@include media-breakpoint-down(md) {
				width: 262px;
			}
			@include media-breakpoint-down(sm) {
				width: 129px;
			}
		}
	}

	&__discover{
		background: $color-white;
		overflow-x: hidden!important;
		
		&-inner{
			padding: 160px 0;
			position: relative;
			z-index: 1;
			@include media-breakpoint-down(sm) {
				padding: 80px 0;
			}
		}
		&-titlebox{
			display: flex;
			justify-content: space-between;
			align-items: flex-end;
			margin-left: 85px;
			padding-right: 30px;
			@include media-breakpoint-down(md) {
				display: block;
				margin-left: 70px;
			}
			@include media-breakpoint-down(sm) {
				margin-left: 20px;
			}
		}
		&-title{
			max-width: 500px;
			@include media-breakpoint-down(md) {
				max-width: 450px;
			}
			@include media-breakpoint-down(sm) {
				max-width: 265px;
			}
		}
		&-text{
			@include fz(1.4rem);
			padding-bottom: 40px;
			margin-left: 20px;
			@include media-breakpoint-down(md) {
				margin: 0 0 0 auto;
				width: 50%;
			}
		}
		&-slider{
			position: relative;
			.swiper-option{
				position: absolute;
				right: 100px;
				bottom: -50px;
				display: flex;
				@include media-breakpoint-down(sm) {
					position: static;
					display: block
				}
			}
			.swiper-pagination{
				@include ff-en();
				display: flex;
				align-items: center;
				position: static;
				@include media-breakpoint-down(sm) {
					justify-content: flex-end;
					padding: 10px 20px 0 0;
				}
				&::after{
					content: "";
					width: 1px;
					height: 15px;
					background-color: $color-black;
					margin: 0 20px 0 30px;
					@include media-breakpoint-down(sm) {
						content: none;
					}
				}
				span{
					margin: 0 5px;
				}
			}
			.swiper-arrow-box{
				position: relative;
				@include media-breakpoint-down(sm) {
					position: absolute;
					top: 50%;
					transform: translateY(-50%);
					z-index: 1;
					width: 100%;
				}
			}
			.swiper-arrow{
				position: absolute;
				@include media-breakpoint-down(sm) {
					background: $color-black-dark;
					padding: 20px;
				}
				&::after{
					background-repeat: no-repeat;
					width: 20px;
					height: 10px;
					@include media-breakpoint-down(sm) {
						position: absolute;
						width: 10px!important;
						height: 19px!important;
						background-size: 100%;
					}
				}
			}
			.swiper-button-next{
				right: -70px;
				@include media-breakpoint-down(sm) {
					right: 0;
				}
				&::after{
					content: "";
					background-image: url("/img/common/arrow_next_black.svg");
					@include media-breakpoint-down(sm) {
						background-image: url("/img/common/arrow_next_white.svg")!important;
					}
				}
			}
			.swiper-button-prev{
				right: 0px;
				@include media-breakpoint-down(sm) {
					right: auto;
					left: 0;
				}
				&::after{
					content: "";
					background-image: url("/img/common/arrow_prev_black.svg");
					@include media-breakpoint-down(sm) {
						background-image: url("/img/common/arrow_prev_white.svg")!important;
					}
				}
			}
			.home__btn-style1{
				position: absolute;
				right: 100px;
				bottom: -160px;
				@include media-breakpoint-down(sm) {
					right: 20px;
					bottom: -120px;
				}
			}
		}
		.slideswiper{
			 min-width: 1400px;
			 @include media-breakpoint-down(sm) {
				min-width: auto;
				overflow-x: hidden;
				margin: 0 20px;
			}
			.swiper-wrapper{
				margin-left: 85px;
				@include media-breakpoint-down(sm) {
					margin-left: auto!important;
				}
				.swiper-slide-active{
					transform: scale(1.2)!important;
					margin: 0 40px 0 30px!important;
					@include media-breakpoint-down(sm) {
						transform: none!important;
						margin: auto!important;
					}
				}
				.swiper-slide{
					transform: scale(1);
					transform-origin: top;
					// margin: 0 -50px;
					transition: opacity .5s, transform .5s;
					@include imghover;
					@include media-breakpoint-down(sm) {
						transform: none;
						transform-origin: none;
					}
					img{
						width: 100%;
						object-fit: cover;
						height: 445px;
						@include media-breakpoint-down(sm) {
						
						}
					}
					a{
						display: block;
						position: relative;
						&::before{
							content: "";
							background-image: url("/img/common/arrow_next_black.svg");
							background-position: center;
							background-repeat: no-repeat;
							width: 20px;
							height: 10px;
							background-color: $color-white;
							position: absolute;
							bottom: 0;
							right: -1px;
							opacity: 1;
							z-index: 2;
							padding: 20px;
							@include media-breakpoint-down(sm) {
								right: 0;
								bottom: -1px;
							}
						}
						&::after{
							content: "";
							width: 100%;
							height: 100%;
							background: linear-gradient(rgba(0,0,0,0) 0%,rgba(0,0,0,.4) 100%);
							display: block;
							position: absolute;
							bottom: 0;
						}
					}
				}
			}

			.slider-content{
				position: absolute;
				bottom: 0;
				padding: 20px;
				z-index: 1;
			}
		}
	}

	&__innovative{
		background: $color-white;
		@include media-breakpoint-down(sm) {
			overflow-x: hidden;
		}
		&-inner{
			position: relative;
			z-index: 1;
			background: $color-white;
			padding: 0 0px 40px 40px;
			@include media-breakpoint-down(sm) {
				padding: 20px 0px 20px 20px;
			}
		}
		&-box{
			display: flex;
			justify-content: space-between;
			@include media-breakpoint-down(sm) {
				display: block;
				width: 100%;
			}
			&sp{
				@include media-breakpoint-down(sm) {
					display: flex;
				}
			}
		}
		&-link{
			@include imghover;
			&::after{
				display: none;
			}
				display: block;
				position: relative;
				.post-content{
					position: absolute;
					bottom: 0;
					padding: 20px 40px 20px 20px;
					z-index: 1;
					.post-date-1{
						color: $color-white;
					}
					@include media-breakpoint-down(sm) {
						position: static;
						padding: 10px 0px 0px 0px;
						.post-date-1,.post-text-1,.post-text-3{
							color: $color-black;
						}
						.post-text-1{
							font-weight: bold;
							margin-top: 5px;
						}
					}
				}
				figure{
					position: relative;
					display: block;
					&::before{
						content: "";
						background-image: url("/img/common/arrow_next_black.svg");
						background-position: center;
						background-repeat: no-repeat;
						width: 20px;
						height: 10px;
						background-color: $color-white;
						position: absolute;
						bottom: 0;
						right: -1px;
						opacity: 1;
						z-index: 2;
						padding: 20px;
					}
					&::after{
						content: "";
						width: 100%;
						height: 100%;
						background: linear-gradient(rgba(0,0,0,0) 0%,rgba(0,0,0,.4) 100%);
						display: block;
						position: absolute;
						bottom: 0;
						@include media-breakpoint-down(sm) {
							content: none;
						}
					}
					img{
						aspect-ratio: 4/3;
						object-fit: cover;
						width: 100%!important;
					}
				}
		}
		&-mainpost{
			width: 50%;
			padding-top: 60px;
			@include media-breakpoint-down(sm) {
				width: 100%;
			}
			.home__btn-style1{
				margin: 40px 0 0 auto;
			}
		}
		&-post{
			width: 50%;
			padding-top: 60px;
			@include media-breakpoint-down(sm) {
				width: 100%;
				padding-top: 30px;
			}
			&-title{
				@include fz(1.8rem,1.6rem);
				font-weight: bold;
				border-bottom: 1px solid $color-black;
				padding-bottom: 10px;
				margin-bottom: 0;
			}
			&-item{
				list-style: none;
				border-bottom: 1px solid $color-gray-light;
				padding: 14px 0;
			}
			&-link{
				position: relative;
				padding-right: 15px;
				display: block;
				&:hover{
					&::after{
						right: -5px;
					}
				}
				&::after{
					font-family: fontawesome;
					content: "\f105";
					position: absolute;
					color: $color-black;
					transition: .3s;
					right: 0;
					top: 50%;
					transform: translateY(-50%);
				}
				.post-date-1{
					color: $color-gray-dark;
				}
			}
			.home__btn-style1{
				margin: 40px 0 0 auto;
			}
		}
		&-title{
			margin: 0 40px 0 60px;
			width: 60px;
			@include media-breakpoint-down(sm) {
				flex: 0 0 40px;
				margin: 0px 20px 0 0;
			}
		}
	}

	&__research-c{
		margin-top: 40px;
		&-title{
			margin-bottom: 30px;
			max-width: 420px;
			@include media-breakpoint-down(sm) {
				width: 80%;
				max-width: 350px;
			}
		}
		&-list{
			@include media-breakpoint-down(sm) {
				width: 100vw;
				padding-bottom: 50px;
			}
			.slick-arrow{
				background: $color-black-dark;
				padding: 20px;
				&::before{
					content:""!important;
					position: absolute;
					top: 50%;
					left: 15px;
					margin: 0 auto;
					text-align: centers;
					transform: translateY(-50%);
					width: 10px;
					height: 19px;
					opacity: 1;
					margin-top:0em;
				  }
			}
			  .slick-prev{
				top: 48%!important;
				left: -20px!important;
				@include media-breakpoint-down(sm) {
					top: auto!important;
					left: 5px!important;
					bottom: 0;
					transform:none;
				}
			  }
			  .slick-next{
				top: 48%!important;
				right: -20px!important;
				@include media-breakpoint-down(sm) {
					top: auto!important;
					right: auto!important;
					left: 55px;
					bottom: 0;
					transform:none;
				}
			  }
			  .slick-next:before{
				background-image: url("/img/common/arrow_next_white.svg")!important;
				background-repeat: no-repeat;
				background-size: 100%;
			  }
			
			  .slick-prev:before{
				background-image: url("/img/common/arrow_prev_white.svg")!important;
				background-repeat: no-repeat;
				background-size: 100%;
			  }
		}
		&-item{
			a{
				display: block;
				position: relative;
				margin: 0 2.5px;
			.post-content{
				position: absolute;
				bottom: 0;
				padding: 0 0 10px 10px;
				font-weight: bold;
				z-index: 1;
				.post-text-1{
					@include fz(1.4rem);
					line-height: 1.2;
				}
			}
			&::after{    
				content: "";
				width: 100%;
				height: 100%;
				background: linear-gradient(rgba(0,0,0,0) 0%,rgba(0,0,0,.4) 100%);
				display: block;
				position: absolute;
				bottom: 0;
			}
			}
		}
	}

	&__news{
		@include media-breakpoint-down(sm) {
			border-left: 5px solid $color-black;
			padding: 60px 15px;
		}
		&-area{
			position: relative;
			z-index: 1;
			margin: 160px 0;
			@include media-breakpoint-down(sm) {
				margin: 0;
				padding: 0!important;
			}
			&-inner{
				position: relative;
				padding: 60px 80px;
				@include media-breakpoint-down(md) {
					padding:30px 40px;
				}
				@include media-breakpoint-down(sm) {
					padding:0;
				}
				&::after{
					content: "";
					display: block;
					width: 100%;
					position: absolute;
					top: 0;
					left: 0;
					right: 0;
					bottom: 0;
					backdrop-filter: blur(10px);
					filter: brightness(1.4);
					background: rgba(255, 255, 255, 0.6);
					z-index: -1;
				}
			}
		}
		.home__btn-style1{
			margin: 40px 0 0 auto;
		}
		&-inner{
			display: flex;
			align-items: flex-start;
			@include media-breakpoint-down(sm) {
				display: block;
			}
		}
		&-title{
			flex: 0 0 40px;
			margin-right: 60px;
			display: flex;
			@include media-breakpoint-down(sm) {
				width: 35%;
				max-width: 120px;
			}
		}
		&-list{
			list-style: none;
			display: grid;
			grid-template-columns: 1fr 1fr 1fr 1fr;
			gap: 20px;
			padding-top:65px;
			@include media-breakpoint-down(md) {
				grid-template-columns: 1fr 1fr;
			}
			@include media-breakpoint-down(sm) {
				display: block;
				padding-top: 10px;
			}
		}
		&-item{
			@include imghover;
			@include media-breakpoint-down(sm) {
				margin-bottom: 20px;
			}
			.post-text-box{
				display: block;
			}
			.post-text-2{
				margin-top: 5px;
				@include media-breakpoint-down(sm) {
					margin-top: 0;
				}
			}
			.post-label-2{
				border-left: 1px solid $color-black;
				padding-left: 10px;
				margin-left: 5px;
			}
			figure{
				img{
					width: 100%;
					aspect-ratio: 4/2.5;
					object-fit: cover;
				}
			}
			&.fixed-item{
				position: relative;
				&::before{
					content:"";
					width: 30px;
					height: 30px;
					background-image: url("/img/common/news_fixed_icon.png");
					position: absolute;
					top: 0;
					right: 0;
					z-index: 1;
				}
			}
		}
	}

	&__press{
		padding-left: 100px;
		margin: 60px 0;
		@include media-breakpoint-down(sm) {
			margin: 40px 0 0 ;
			padding: 0 20px;
		}
		&-wrap{
			display: flex;
			align-items: flex-end;
			@include media-breakpoint-down(sm) {
				display: block;
			}
			.home__btn-style1{
				flex: 0 0 110px;
				margin-left: 30px;
				@include media-breakpoint-down(sm) {
					margin-top: 20px;
					margin-left: auto;
				}
			}
		}
		&-inner{
			display: flex;
			align-items: flex-start;
			// justify-content: space-between;
			width: 100%;
			@include media-breakpoint-down(sm) {
				display: block;
			}
		}
		&-box{
			display: flex;
			align-items: center;
			white-space: nowrap;
			flex: 0 0 150px;
			@include media-breakpoint-down(sm) {
				margin-bottom: 10px ;
			}
			.post-press-title{
				color: $color-main;
				font-weight: bold;
				@include fz(1.8rem);
				display: flex;
				align-items: center;
				margin-right: 16px;
				&::after{
					content: "";
					width: 1px;
					height: 20px;
					background: $color-black;
					display: block;
					margin-left: 16px;
				}
			}

		}
		&-text{
			margin: 0 20px;
			@include media-breakpoint-down(sm) {
				margin: 0 ;
			}
		}
		&-item{
			margin-top: 16px;
			&:first-child{
				margin-top: 0;
			}
			&box{
			}
		}
	}

	&__event{
		margin-top: 40px;
		@include media-breakpoint-down(sm) {
			border-left: 5px solid $color-main;
			margin-top: 0;
			padding: 0px 15px 60px;
		}
		.home__btn-style1{
			margin: 40px 0 0 auto;
		}
		&-inner{
			display: flex;
			align-items: flex-start;
			@include media-breakpoint-down(sm) {
				display: block;
			}
		}
		&-title{
			flex: 0 0 40px;
			margin-right: 60px;
			display: flex;
			@include media-breakpoint-down(sm) {
				width: 35%;
				max-width: 120px;
			}
		}
		&-post{
			flex: 1;
		}
		&-list{
			list-style: none;
			padding-top:65px;
			display: flex;
			justify-content: space-between;
			flex-wrap: wrap;
			@include media-breakpoint-down(sm) {
				display: block;
				padding-top: 10px;
			}
		}
		&-item{
			width: 45%;
			@include media-breakpoint-down(sm) {
				width: 100%;
				margin-bottom: 20px;
			}
			&:nth-child(n+3){
				margin-top: 40px;
				@include media-breakpoint-down(sm) {
					margin-top: auto;
				}
			}
			//display: none;
		}
		&-link{
			display: inline-flex;
			width: 100%;
			.post-label-1{
				display: inline-block;
			}
			.post-event{
				&-content{
					margin: 5px 0;
				}
				&-date{
					border-right: 1px solid $color-main;
					padding-right: 21px;
					margin-right: 30px;
					flex: 0 0 80px;
					@include media-breakpoint-down(md) {
						padding-right: 15px;
						margin-right: 15px;
					}
				}
			}
			span{
				display: block;
			}
		}
	}

	&__data{
		&-inner{
			position: relative;
			z-index: 1;
			background: $color-white;
			padding: 120px 0 60px;
			&.inner-flexible{
				@include media-breakpoint-down(md) {
					padding: 60px 40px 60px;
				}
				@include media-breakpoint-down(sm) {
					padding: 80px 0px 80px;
				}
			}
		}
		&-title{
			text-align: center;
			margin-bottom: 60px;
			img{
				width: 250px;
				@include media-breakpoint-down(sm) {
					width: 140px;
				}
			}
		}
		&-list{
			list-style: none;
			padding: 0 60px;
			margin-top: 60px;
			max-width: 1400px;
			margin: 0 auto;
			@include media-breakpoint-down(sm) {
				padding: 0px;
			}
			.slick-arrow{
				@include media-breakpoint-down(sm) {
					background: $color-black-dark;
					padding: 20px;
					margin-top: 30px;
				}
				&::before{
					content:""!important;
					position: absolute;
					top: 0;
					width: 25px;
					height: 46px;
					opacity: 1;
					margin-top:0em;
					background-size: 100%;
					@include media-breakpoint-down(sm) {
						transform: translateY(-50%);
						width: 10px;
						height: 19px;
						margin: 0 auto;
						top: 50%;
						transform: translateY(-50%);
						left: 15px;
					}
				}
			}
			  .slick-prev{
				left: 20px!important;
			  }
			  .slick-next{
				right: 20px!important;
			  }
			  .slick-next:before{
				background-image: url("/img/common/arrow_next_black2.svg")!important;
				background-repeat: no-repeat;
				@include media-breakpoint-down(sm) {
					background-image: url("/img/common/arrow_next_white.svg")!important;
				}
			  }
			
			  .slick-prev:before{
				background-image: url("/img/common/arrow_prev_black2.svg")!important;
				background-repeat: no-repeat;
				@include media-breakpoint-down(sm) {
					background-image: url("/img/common/arrow_prev_white.svg")!important;
				}
			  }
		}
		&-item{
			margin: 0 10px!important;
		}
	}

	&__special{
		background: $color-white;
		padding: 120px 0 180px;
		@include media-breakpoint-down(sm) {
			padding: 0 0 100px;
		}
		&-inner{
			position: relative;
			z-index: 1;
			padding-top: 60px;
			padding-bottom: 60px;
			max-width: 1850px;
			// margin: -30% auto 0 auto;
			margin: -510px auto 0 auto;
			@media screen and (max-width:1355px) { 
				padding: 0 30px;
				margin: -450px auto 0 auto;
			}
			@include media-breakpoint-down(sm) {
				margin: -370px auto 0 auto;
			}
		}
		&-bg{
			img{
				object-fit:cover;
				width: 100%;
				// height: 31.2%;
				height: 545px;
				@include media-breakpoint-down(sm) {
					height: 400px;
				}
			}
		}
		&-block{
			display: flex;
			@include media-breakpoint-down(sm) {
				display: grid;
				grid-template-rows: 370px 1fr 1fr;
			}
		}
		&-title{
			margin: 0 60px;
			// flex: 0 0 2.9%;
			flex: 0 0 50px;
			@media screen and (max-width:1355px) { 
				margin: 0 30px;
			}
			@include media-breakpoint-down(sm) {
				order: 1;
				display: flex;
				flex-direction: row-reverse;
				justify-content: space-between;
				margin: 0;
			}
			img{
				width: 100%!important;
				
			}
			.title{
				@include media-breakpoint-down(sm) {
					width: 20%!important;
					max-width: 80px;
				}
			}
			.text{
				@include media-breakpoint-down(sm) {
					width: 33%!important;
					max-width: 110px;
				}
			}
		}
		&-text{
			
		}
		&-box{
			max-width: 655px;
			margin: 165px auto 0 auto;
			width: 45%;
			@include media-breakpoint-down(sm) {
				order: 2;
				margin: 0;
				width: 100%;
			}
			.home__btn-style1{
				margin: 40px auto 0;
			}
			&.box1{
				@include media-breakpoint-down(sm) {
					order: 2;
					margin-top: -100px;
				}
			}
			&.box2{
				@include media-breakpoint-down(sm) {
					order: 3;
				}
			}
		}
	}


	&__test{
		background-color: $color-white;
		padding: 60px 0;
		.inner-wide{
			padding: 0 40px;
			ul:not([class]) > li{
				margin-bottom: 0;
			}
			h2{
				@include ff-serif();
				margin-top: 40px;
				margin-bottom: 50px;
				border: none;
				&::after{
					content: none;
				}
			}
			ul{
				display: flex;
				flex-wrap: wrap;
				li{
					list-style: none;
					margin: 0 30px 0 0 ;
					width: calc(50% - 20px);
					&:nth-child(2n){
						margin-right: 0;
					}
					a{
						border-bottom: 1px solid $color-black;
						padding-bottom: 14px;
						margin-bottom: 14px;
						display: block;
						position: relative;
						padding-right: 20px;
						&::after{
							font-family: 'FontAwesome';
							content: '\f105' ;
							position: absolute;
							display: inline-block;
							right: 10px;
						}
					}
				}
			}
		}
		.resize1{
			a{
				@include fz(1.7rem);
			}
		}
		.resize2{
			a{
				@include fz(1.8rem);
			}
		}
		&_box{
			display: flex;
			border-left: 3px solid $color-main;
		}
		&_ttl{
			flex: 0 0 240px;
			font-weight: bold;
			@include fz(2rem);
			padding-left: 15px;
		}
	}

	//英語サイト
	&.en{
		.home__mv-copy{
			&-box{
				left: 50px;
				top: calc(100vh - 390px);
				@include media-breakpoint-down(sm) {
					padding: 200px 20px 0;
				}
			}
			&-title{
				padding: 0;
				width: 65vw;
				max-width: 750px;
				@include media-breakpoint-down(sm) {
					width: 125px;
				}
			}
		}

		.home__about{
			&-content{
				display: block;
				@include media-breakpoint-down(sm) {
					margin-top: 0;
					padding-bottom: 70px;
				}
			}
			&-title{
				@include ff-en();
				padding-left: 15px;
				@include fz(7rem,4.8rem);
				line-height: 1;
				margin-bottom: 30px;
				@include media-breakpoint-down(sm) {
					padding-left: 20px;
					transform: none;
					position: static;
					margin-top: 60px;
				}
			}
			&-inner{
				padding-bottom: 100px;
				padding-top: 15px;
				margin-top: 530px;
				@include media-breakpoint-down(sm) {
					margin-top: 0;
					padding-bottom: 0;
					padding-top: 0;
				}
			}
		}

		.home__section{
			@include media-breakpoint-down(sm) {
				padding-bottom: 100px;
			}
			&::before{
				width: 300px;
				@include media-breakpoint-down(sm) {
					width: 120px;
				}
			}
		}
		.home__discover{

			&-titlebox{
				margin-left: 130px;
				padding-right: 0px;
				align-items: center;
				display: grid;
				grid-template-columns: 500px 1fr;
				@include media-breakpoint-down(md) {
					margin-left: 130px;
				
				}
				@include media-breakpoint-down(sm) {
					display: block;
					margin-left: 0px;
				}
			}
			&-title{
				flex: 0 0 500px;
				@include media-breakpoint-down(md) {
					max-width: 500px;
				}
				@include media-breakpoint-down(sm) {
					max-width: 260px;
					padding-left: 20px;
				}
			}
		}
		.home__btn_en{
			&-list{
				display: flex;
				justify-content: space-between;
				list-style-type: none;
				padding: 0 60px;
				@include media-breakpoint-down(sm) {
					display: block;
					padding: 0 40px;
				}
			}
			&-item{
				position: relative;
				width: 30%;
				border-radius: 30px;
				margin-right: 40px;
				@include media-breakpoint-down(sm) {
					width: 100%;
					margin-bottom: 20px;
				}
				&:last-child{
					margin-right: 0px;
					@include media-breakpoint-down(sm) {
						margin-bottom: 0px;
					}
				}
				a{
					figure{
						position: relative;
						.textbox{
							position: absolute;
							top: 50%;
							left: 50%;
							transform: translate(-50%,-50%);
							z-index: 1;
							color: $color-white;
							.text{
								@include ff-en();
								@include fz(4rem);
								white-space:nowrap;
							}
							.home__btn-style1::before, .home__btn-style1::after{
								background-color: $color-white;
							}
							.home__btn-style1 {
								margin: 0 auto;
								span{
									padding: 5px 0;
									display: flex;
									justify-content: space-between;
									align-items: center;
									@include ff-en();
									font-weight: 400;
									&::after{
										content: "";
										background-image: url(/img/common/arrow_next_white2.svg);
										background-repeat: no-repeat;
										width: 20px;
										height: 8px;
										margin-left: 10px;
									}
								}
							}

						}
					}
				}
			}
		}

		.home__news-area{
			margin-bottom: 0;
		}
		.en-sns{
			position: relative;
			background-image: url("/img/home/en_sns-bg.svg");
			background-size: contain;
			background-repeat: no-repeat;
			width: 710px;
			height: 370px;
			margin: 80px auto 0;
			@include media-breakpoint-down(sm) {
				left: 50%;
				transform: translateX(-50%);
				background-position: center;
			}
			&_inner{
				position: absolute;
				left: 50%;
				transform: translateX(-50%);
				bottom: 30px
			}
			&_title{
				@include ff-en();
				@include fz(5rem,4rem);
				color: $color-main;
				display: flex;
				flex-direction: column-reverse;
				justify-content: center;
				align-items: center;
				line-height: 1;
				margin-bottom: 30px;
				&::before{
					content: "";
					width: 1px;
					height: 40px;
					background: $color-main;
					margin-top: 10px;
				}
			}
			.home__btn-style1{
				margin: 20px auto 0;
			}
		}
	}
}