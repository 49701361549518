@charset 'utf-8';

.discover{
    &.graduate{
        .discover_top_title{
            span{
                @include fz(3rem,1.8rem);
                display: block;
            }
        }
    }
    &_menu {
        .breadcrumb-item.active{
            color: $color-white;
        }
        .breadcrumb-item + .breadcrumb-item::before{
            color: $color-white;
        }
        .breadcrumb-item a{
            color: $color-white;
        }
    }
    &_top {
        background: linear-gradient(#152740 0%,#004a98 100%);
        color: $color-white;
        padding: 50px 0 150px;
        @include media-breakpoint-down(sm) {
            padding: 50px 0 100px;
        }
        &_title {
            @include fz(12rem,7rem);
            margin: 0;
            @include ff-en();
        }
        &_txt {
            margin: 60px 0 90px 0;
        }
    }

    &_main_wrap {
        width: 100%;
        .post-label-1 {
            margin-left: 10px;
            @include fz(1.4rem,1.2rem);
        }
    }

    &_inner{
        width: 30%;
        background-color: $color-white;
        margin-bottom: 50px;
        &:nth-child(3n+2) {
            margin-top: 70px;
            @include media-breakpoint-down(sm) {
                margin-top: 0px;
            }
        }
        @include media-breakpoint-down(sm) {
            width: 100%;
        }
        &_wrap {
            list-style: none;
            display: flex;
            justify-content: flex-start;
            flex-wrap: wrap;
            gap: 5%;
            @include media-breakpoint-down(sm) {
                display: block;
            }
            .post-sys__date {
                color: $color-main;
                @include fz(1.8rem,1.6rem);
            }
        }
        a{
            text-decoration: none;
        }
        img{
            object-fit: cover;
            aspect-ratio: 3/4;
            width: 100%;
        }

        .page-imglink_box{
            position: relative;
        }
        .text-box{
            position: absolute;
            bottom: 0;
            padding: 20px 45px 20px 20px;
        }
    }
    &_list{
        &_title{
            padding-top: 10px;
            @include fz(1.8rem,1.6rem);
            font-weight: bold;
        }
        &_name{
            @include fz(1.4rem);
        }
    }



    &_detail{
        &_title{ 
           .post-sys__date{
            color: $color-black!important;
            border-left: 1px solid $color-gray-dark;
            margin-left: 10px;
            padding-left: 10px;
           }
        }
        &_name{
            @include fz(2.4rem,1.8rem);
            color: $color-main;
            font-weight: bold;
            display: inline-block;
        }
        &_mv{
            position: relative;
            padding-bottom: 115px;
            margin-top: 40px;
            @include media-breakpoint-down(sm) {
                margin-top: 20px;
                padding-bottom: 60px;
            }
            &::before{
                content: "Discover";
                position: absolute;
                right: 30px;
                bottom: 0;
                @include ff-en();
                @include fz(14rem,7rem);
                color: $color-white;
                z-index: 1;
                line-height: 0.7;
                opacity: .6;
            }
            &::after{
                content: "";
                width: 100%;
                height: 230px;
                background: linear-gradient(#004a98 0%,#152740 100%);
                display: block;
                position: absolute;
                bottom: 0;
                z-index: 0;
                @include media-breakpoint-down(sm) {
                    height: 135px;
                }
            }
            &-inner{
                max-width: 1300px;
                margin: 0 auto;
                position: relative;
                z-index: 1;
                img{
                    // width: 100%;
                    // object-fit: cover;
                    // aspect-ratio: 2.5/1;
                    // @include media-breakpoint-down(sm) {
                    //     aspect-ratio: 3/2;
                    //     padding: 0 20px;
                    // }
                }
            }
        }
    }

    &_category{
        background: $color-white;
        box-shadow: 0px 5px 15px 0px rgba(0, 0, 0, 0.35);
        margin-top: -130px;
        padding: 30px;
        margin-bottom: 100px;
        border-bottom: 5px solid $color-main;
        &_item{
            margin-bottom: 30px;
            @include media-breakpoint-down(sm) {
                margin-bottom: 15px;
            }
            &:last-of-type{
                margin-bottom: 0;
            }

            select{
                width: 100%;
                border: 1px solid $color-main;
                padding: 10px 0
            }
        }
        &_title{
            @include ff-serif();
            @include fz(2rem,1.8rem);
            color: $color-main;
            font-weight: bold;
        }
        &_list{
            list-style: none;
            li{
                display: inline-block;
                border: 1px solid $color-main;
                margin-bottom: 5px;
                a{
                    display: block;
                    text-decoration: none;
                    color: $color-main;
                    padding: 3px 10px;
                    &:hover{
                        text-decoration: none;
                    }
                }
                &.active{
                    background-color: $color-main;
                   a{
                    color: $color-white;
                   }
                }
            }
        }
        &_all{
            a{
                background: $color-main;
                color: $color-white;
                padding: 10px;
                text-decoration: none;
                max-width: 200px;
                margin: 0 auto;
                display: block;
                text-align: center;
                &:hover{
                    text-decoration: none;
                }
            }
        }
    }
}