@charset 'utf-8';
.page.career {
	.career {
		&__point {
			&-tab {
				li {
					width: 50% !important;
					border: none;
					a {
						border-bottom: 3px solid $color-gray-light;
						&.active {
							color: $color-main;
							border-bottom: 3px solid $color-main;
						}
					}
				}
			}
			&-lead {
				@include fz(2.6rem,2.2rem);
      	@include ff-serif();
				color: $color-main;
				font-weight: bold;
				line-height: 1.5;
			}
		}

		&__numbers {
			&-title {
				font-feature-settings: normal;
				&_en {
					font-size: 100%;
					@include media-breakpoint-down(sm) {
						position: absolute;
						writing-mode: horizontal-tb;
						left: 75px;
						top: 0;
						margin-top: 40px;
						color: $color-main;
					}
				}
			}
			.page-ttl_style2::before {
				@include media-breakpoint-down(sm) {
					width: 80px;
				}
			}
			.page-ttl_style2-block {
				overflow: hidden !important;
				margin-right: -85px;
				padding: 30px 0 30px 30px;
				background-color: $color-white;
				@include media-breakpoint-down(lg) {
					margin-right: -40px;
				}
				@include media-breakpoint-down(sm) {
					margin-top: 60px;
					margin-right: -20px;
					padding: 20px 0 40px 20px;
				}
			}

			&-slider{
				position: relative;
				padding-bottom: 80px;
				@include media-breakpoint-down(sm) {
					padding-bottom: 120px;
				}
				.slick-slider {
					position: relative;
				}
				.slick-list {
					padding-right: 10%;
				}
				&_item {
					margin-right: 30px !important;
					@include media-breakpoint-down(sm) {
						margin-right: 20px !important;
					}
				}
				.slick-pagination{
					position: absolute;
					left: 34px;
					bottom: -30px;
					transform: translate(0, 100%);
					display: flex;
					align-items: center;
					height: 30px;
					@include ff-en();
					@include media-breakpoint-down(sm) {
						left: 0;
						bottom: -20px;
					}
					&::after{
						content: "";
						width: 1px;
						height: 15px;
						background-color: $color-black;
						margin: 0 20px 0 25px;
					}
					span{
						margin: 0 5px;
					}
				}
				.slick-arrow{
					position: absolute;
					top: auto;
					bottom: -30px;
					transform: translate(0, 100%);
					z-index: 1;
					height: 30px;
					@include media-breakpoint-down(sm) {
						bottom: -20px;
					}
					&::before{
						@include media-breakpoint-down(sm) {
							width: 17px;
						}
					}
				}
				.slick-next {
					left: 176px;
					@include media-breakpoint-down(sm) {
						left: 127px;
					}
					&::before{
						content: "" !important;
						background: url("/img/common/arrow_next_black.svg") no-repeat center center / contain;
					}
				}
				.slick-prev {
					left: 136px;
					@include media-breakpoint-down(sm) {
						left: 90px;
					}
					&::before{
						content: "" !important;
						background: url("/img/common/arrow_prev_black.svg") no-repeat center center / contain;
					}
				}
			}
			.page-btn_style1{
				position: absolute;
				right: 96px;
				bottom: 0;
				width: 120px;
				@include media-breakpoint-down(sm) {
					left: 0;
					right: auto;
				}
				a {
					text-decoration: none;
					&::after{
						background-image: url("/img/common/arrow_next_black.svg");
					}
				}
			}
		}

		&__center {
			&-time {
				&_item {
					display: flex;
					dt {
						width: 6em;
					}
					dd {
						flex: 1 0 0%;
					}
				}
			}
		}
	}

	.page__bnrstyle1-link[target='_blank']::after {
		content: none;
	}

	.performance {
		&__header {
			display: flex;
			align-items: center;
			justify-content: center;
			height: 300px;
			background: linear-gradient(to top, #004a98 0%, #152740 100%);
			@include media-breakpoint-down(sm) {
				height: 250px;
			}
			&-ttl {
				color: $color-white;
				@include ff-serif();
				@include fz(2rem,1.8rem);
				font-weight: bold;
				text-align: center;
				white-space: nowrap;
				span {
					display: block;
					margin-bottom: 10px;
					@include ff-en();
					@include fz(10rem,5rem);
					line-height: 1.2;
				}
			}
		}

		&__ttl {
			&-style1 {
				@include ff-serif();
				@include fz(3rem,2.6rem);
				font-weight: bold;
				text-align: center;
			}
		}

		&__notes {
			@include fz(1.4rem);
			@include media-breakpoint-down(sm) {
				padding-left: 1em;
				text-indent: -1em;
			}
		}

		&__results {
			position: relative;
			padding: 60px 0 120px;
			background: url("/career/performance/img/results_bg.jpg") no-repeat center top / cover;
			text-align: center;
			@include media-breakpoint-down(sm) {
				padding: 60px 0 100px;
				background-image: url("/career/performance/img/results_bg-sp.jpg");
			}
			&::after {
				content: "";
				position: absolute;
				left: 0;
				right: 0;
				bottom: 0;
				display: block;
				width: 1px;
				height: 60px;
				margin: auto;
				background-color: $color-black;
			}
			&-ttl {
				@include ff-serif();
				@include fz(4rem,2.4rem);
				font-weight: bold;
			}
			&-number {
				@include ff-en();
				@include fz(15rem,10rem);
				line-height: 1;
				& > span {
					&:nth-child(1), &:nth-child(4) {
						color: $color-main;
					}
					&:nth-child(3) {
						color: #aebdd4;
					}
				}
			}
			&-year {
				@include ff-serif();
				@include fz(2.4rem,2rem);
				font-weight: bold;
			}
			&-txt {
				@include media-breakpoint-down(sm) {
					text-align: left;
				}
			}
		}

		&__rate {
			padding: 80px 0 90px;
			background: url("/career/performance/img/rate_bg.jpg") no-repeat center top / cover;
			color: $color-white;
			text-align: center;
			@include media-breakpoint-down(sm) {
				padding: 60px 0;
				background-image: url("/career/performance/img/rate_bg-sp.jpg");
			}
			&-content {
				padding: 30px 0;
				border-top: 3px solid $color-white;
				border-bottom: 3px solid $color-white;
				@include media-breakpoint-down(sm) {
					padding: 40px 0 50px;
				}
			}
			&-number {
				@include ff-en();
				@include fz(5.8rem,3.9rem);
				line-height: 1;
				& > span {
					display: inline-block;
					margin-right: 10px;
					@include fz(19.5rem,13.2rem);
				}
			}
			&-graphs {
				padding: 40px;
				background-color: rgba($color-white, 0.4);
				@include media-breakpoint-down(sm) {
					max-width: 240px;
					margin: 0 auto;
					padding: 30px;
				}
				&_list {
					display: flex;
					margin: -2.6%;
					padding: 0;
					list-style: none;
					@include media-breakpoint-down(sm) {
						display: block;
						margin: -15px 0;
					}
				}
				&_item {
					width: 100%;
					padding: 2.6%;
					@include media-breakpoint-down(sm) {
						padding: 15px 0;
					}
				}
				&_ttl {
					margin-bottom: 20px;
					padding: 5px;
					background-color: $color-white;
					@include fz(1.6rem);
					color: $color-black;
					font-weight: bold;
					@include media-breakpoint-down(sm) {
						margin-bottom: 10px;
					}
				}
			}
			&-type {
				&_list {
					display: flex;
					padding: 0;
					list-style: none;
					@include media-breakpoint-down(sm) {
						display: block;
						max-width: 240px;
						margin: -30px auto;
					}
				}
				&_item {
					position: relative;
					display: flex;
					align-items: center;
					justify-content: center;
					width: 100%;
					@include media-breakpoint-down(sm) {
						justify-content: space-between;
						padding: 30px 0;
					}
					&:not(:first-child) {
						border-left: 1px solid $color-white;
						@include media-breakpoint-down(sm) {
							border: none;
							&::before {
								content: "";
								position: absolute;
								left: 50%;
								top: 0;
								transform: translateX(-50%);
								width: 60px;
								height: 1px;
								background-color: $color-white;
							}
						}
					}
				}
				&_ttl {
					margin: 0 25px 0 0;
					@include fz(1.6rem);
					font-weight: bold;
					@include media-breakpoint-down(sm) {
						width: 4em;
						margin: 0;
					}
					& > span {
						display: block;
						margin-bottom: 10px;
					}
				}
				&_number {
					@include ff-serif();
					@include fz(2.8rem);
					letter-spacing: normal;
					line-height: 1;
					& > span {
						display: inline-block;
						@include ff-en();
						@include fz(9.4rem);
					}
				}
			}
		}

		&__ratio {
			padding: 80px 0 90px;
			@include media-breakpoint-down(sm) {
				padding: 60px 0 90px;
			}
			&-list {
				display: flex;
				margin: -15px;
				padding: 0;
				list-style: none;
				@include media-breakpoint-down(sm) {
					display: block;
					max-width: 216px;
					margin: -15px auto;
				}
				&_item {
					width: 100%;
					padding: 15px;
					@include media-breakpoint-down(sm) {
						padding: 15px 0;
					}
				}
			}
			&-labels {
				display: flex;
				flex-wrap: wrap;
				align-items: center;
				justify-content: center;
				margin: -7px -15px;
				padding: 0;
				list-style: none;
				@include media-breakpoint-down(sm) {
					justify-content: flex-start;
				}
				li {
					display: flex;
					align-items: center;
					padding: 7px 15px;
				}
				&_color {
					display: inline-block;
					width: 16px;
					height: 16px;
					margin-right: 10px;
					&.-color1 {
						background-color: $color-white;
						border: 1px solid $color-gray-light;
					}
					&.-color2 {
						background-color: $color-gray-light2;
					}
					&.-color3 {
						background-color: $color-gray-light;
					}
					&.-color4 {
						background-color: #aebdd4;
					}
					&.-color5 {
						background-color: #4874ae;
					}
					&.-color6 {
						background-color: $color-main;
					}
					&.-color7 {
						background-color: #013d7b;
					}
					&.-color8 {
						background-color: $color-black;
					}
				}
			}
		}

		&__gender {
			padding: 80px 0 90px;
			background: url("/career/performance/img/gender_bg.jpg") no-repeat center top / cover;
			color: $color-main;
			text-align: center;
			@include media-breakpoint-down(sm) {
				padding: 60px 0;
				background-image: url("/career/performance/img/gender_bg-sp.jpg");
			}
			&-list {
				display: flex;
				padding: 50px 0;
				list-style: none;
				border-top: 2px solid $color-main;
				border-bottom: 1px solid $color-main;
				@include media-breakpoint-down(sm) {
					display: block;
					padding: 0;
				}
				&_item {
					position: relative;
					width: 50%;
					padding: 0 80px;
					@include media-breakpoint-down(sm) {
						width: 100%;
						padding: 30px 0 40px;
					}
					&:nth-child(2n) {
						border-left: 1px solid $color-main;
						@include media-breakpoint-down(sm) {
							border: none;
							&::before {
								content: "";
								position: absolute;
								left: 50%;
								top: 0;
								transform: translateX(-50%);
								width: 77%;
								height: 1px;
								background-color: $color-main;
							}
						}
					}
				}
			}
		}

		&__global {
			padding: 80px 0 90px;
			background: url("/career/performance/img/global_bg.jpg") no-repeat center center / cover;
			color: $color-white;
			text-align: center;
			@include media-breakpoint-down(sm) {
				padding: 60px 0;
				background-image: url("/career/performance/img/global_bg-sp.jpg");
			}
			&-number {
				display: flex;
				flex-direction: column;
				align-items: center;
				justify-content: center;
				width: 300px;
				height: 300px;
				margin: 80px auto 120px;
				padding-bottom: 20px;
				border: 1px solid $color-white;
				border-radius: 100%;
				@include ff-en();
				@include fz(2.9rem,1.9rem);
				line-height: .9;
				@include media-breakpoint-down(sm) {
					width: 200px;
					height: 200px;
					margin: 120px auto 40px;
					padding-bottom: 10px;
				}
				& > span {
					display: block;
					@include fz(16.9rem,11.2rem);
				}
			}
		}

		&__support {
			padding: 80px 0 90px;
			background: url("/career/performance/img/support_bg.jpg") no-repeat center top / cover;
			color: $color-main;
			text-align: center;
			@include media-breakpoint-down(sm) {
				padding: 60px 0;
				background-image: url("/career/performance/img/support_bg-sp.jpg");
			}
			&-content {
				border-top: 3px solid $color-main;
				border-bottom: 3px solid $color-main;
			}
			&-wrapper {
				display: flex;
				align-items: center;
				padding: 30px 0;
				@include media-breakpoint-down(sm) {
					display: block;
				}
			}
			&-number {
				flex-basis: 47.5%;
				margin: 0;
				@include ff-en();
				@include fz(2.9rem,2.2rem);
				line-height: 1;
				& > span {
					display: block;
					background-image: linear-gradient(to bottom, #3d659b, #063167);
					-webkit-background-clip: text;
					-webkit-text-fill-color: transparent;
					@include fz(20rem,12rem);
					@at-root .ie11 .performance__support-number > span {
						color: $color-main;
						background: none !important;
					}
				}
			}
			&-program {
				position: relative;
				flex: 1 0 0%;
				text-align: left;
				&_box{
					background: $color-gray-light2;
					padding: 30px;
					margin-top: 30px;
				}
				&_lead{
					color: $color-black;
					text-align: center;
					@include fz(1.8rem,1.4rem);
				}
				&_ttl {
					@include fz(2.4rem,2rem);
					text-align: center;
					font-weight: bold;
				}
				&_list {
					padding: 0;
					list-style: none;
					@include fz(1.6rem);
					display: flex;
					flex-wrap: wrap;
					@include media-breakpoint-down(sm) {
						display: block;
					}
					li {
						position: relative;
						padding-left: 1em;
						color: $color-black;
						margin-right: 20px;
						width: calc(33.333% - 20px);
						@include media-breakpoint-down(sm) {
							margin-right: auto;
							width: 100%;
						}
						& + li {
							margin-top: 0.5em;
						}
						&::before {
							content: "・";
							position: absolute;
							left: 0;
							top: 0;
							color: $color-main;
						}
					}
				}
			}
			&-obog {
				display: flex;
				align-items: center;
				justify-content: center;
				padding: 15px 0;
				border-top: 1px solid $color-main;
				@include media-breakpoint-down(sm) {
					padding: 30px 0;
				}
				&_ttl {
					margin: 0;
					@include fz(2.4rem,1.8rem);
					font-weight: bold;
					@include media-breakpoint-down(sm) {
						text-align: left;
					}
				}
				&_number {
					display: flex;
					align-items: center;
					justify-content: center;
					width: 120px;
					height: 120px;
					margin-left: 15px;
					background-color: rgba($color-main, .2);
					border-radius: 100%;
					@include fz(2rem,1.5rem);
					letter-spacing: normal;
					line-height: 1;
					white-space: nowrap;
					@include media-breakpoint-down(sm) {
						width: 90px;
						height: 90px;
					}
					span {
						@include ff-en();
						@include fz(6rem,4.5rem);
					}
				}
			}
		}

		&__status {
			padding: 80px 0 90px;
			background: url("/career/performance/img/status_bg.jpg") no-repeat center top / cover;
			@include media-breakpoint-down(sm) {
				padding: 60px 0;
				background-image: url("/career/performance/img/status_bg-sp.jpg");
			}
			div[id]{
				@include media-breakpoint-up(md) {
				margin-top: -70px;
				padding-top: 70px;
				visibility: hidden;
				}
			}
			&-content {
				.accordion{
					background-color: $color-white;
				}
			}
			&-table {
				table-layout: fixed;
				@include media-breakpoint-down(sm) {
					border-left: 1px solid;
				}
				.table-header{
					@include media-breakpoint-down(sm) {
						position: sticky;
						left: -1px;
						width: 90px!important;
						word-break: break-word;
					}
				}
				thead {
					th {
						padding: 10px;
						background: $color-main;
						border: 1px solid $color-gray-light!important;
						color: $color-white;
						text-align: center;
					}
				}
				tbody {
					th {
						background: $color-gray-light2;
						color: $color-main;
						letter-spacing: normal;
						text-align: left;
					}
					td {
						text-align: center;
					}
				}
				tfoot {
					th, td {
						padding: 10px;
						background: #ededed;
						border: 1px solid $color-gray-light!important;
						color: $color-black;
					}
					th {
						text-align: left;
					}
					td {
						text-align: center;
					}
				}
				&1 {
					@include media-breakpoint-down(sm) {
						min-width: 1140px !important;
					}
				}
				&2 {
					@include media-breakpoint-down(sm) {
						min-width: 800px !important;
					}
					tbody {
						th {
							text-align: center;
						}
						td {
							text-align: right;
							 &:last-child {
								background: $color-gray-light2;
								font-weight: bold;
							 }
						}
					}
					tfoot {
						th {
							text-align: center;
						}
						td {
							font-weight: bold;
							text-align: right;
						}
					}
				}
				&3 {
					@include media-breakpoint-down(sm) {
						min-width: 600px !important;
					}
				}
			}
			&-list1 {
				.ttl_style4 {
					margin-bottom: 25px;
					font-size: 1.6rem;
				}
				ul {
					margin-bottom: 30px;
					padding: 0;
				}
			}
			&-accordion {
				&_sub {
					margin-bottom: 5px;
					border: none;
					.accordion__trigger {
						padding: 14px 20px 14px 30px;
						background-color: $color-gray-light;
						color: $color-main;
						font-size: 1.8rem;
					}
					.accordion__icon::before {
						transform: translate(-50%, -50%);
						font-family: fontawesome;
    				content: "\f107";
						display: block;
						width: auto;
						height: auto;
						background: none;
					}
					.accordion__icon::after {
						content: none;
					}
					.accordion__inner {
						padding: 25px 0 0;
					}
					&[open] {
						.accordion__trigger {
							background-color: #aebdd4;
						}
						.accordion__icon::before {
							transform: translate(-50%, -50%) scale(1, -1);
						}
					}
				}
			}
		}

	}


	&.en{
		.performance__status-table .table-header{
			@include media-breakpoint-down(sm) {
				width: 120px!important;
			}
		}
	}
}
