@charset "UTF-8";
/*!
 * Bootstrap v4.1.3 (https://getbootstrap.com/)
 * Copyright 2011-2018 The Bootstrap Authors
 * Copyright 2011-2018 Twitter, Inc.
 * Licensed under MIT (https://github.com/twbs/bootstrap/blob/master/LICENSE)
 */
:root {
  --blue: #007bff;
  --indigo: #6610f2;
  --purple: #6f42c1;
  --pink: #e83e8c;
  --red: #dc3545;
  --orange: #fd7e14;
  --yellow: #ffc107;
  --green: #28a745;
  --teal: #20c997;
  --cyan: #17a2b8;
  --white: #fff;
  --gray: #6c757d;
  --gray-dark: #343a40;
  --primary: #007bff;
  --secondary: #6c757d;
  --success: #28a745;
  --info: #17a2b8;
  --warning: #ffc107;
  --danger: #dc3545;
  --light: #f8f9fa;
  --dark: #343a40;
  --breakpoint-xs: 0;
  --breakpoint-sm: 576px;
  --breakpoint-md: 768px;
  --breakpoint-lg: 1080px;
  --breakpoint-llg: 1280px;
  --breakpoint-xl: 1400px;
  --breakpoint-xxl: 1980px;
  --font-family-sans-serif: "游ゴシック Medium", "Yu Gothic Medium", "游ゴシック体", YuGothic, "ヒラギノ角ゴ ProN W3", "Hiragino Kaku Gothic ProN", "メイリオ", Meiryo, Verdana, Roboto, "Droid Sans", sans-serif;
  --font-family-monospace: SFMono-Regular, Menlo, Monaco, Consolas, "Liberation Mono", "Courier New", monospace; }

*,
*::before,
*::after {
  box-sizing: border-box; }

html {
  font-family: sans-serif;
  line-height: 1.15;
  -webkit-text-size-adjust: 100%;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0); }

article, aside, figcaption, figure, footer, header, hgroup, main, nav, section {
  display: block; }

body {
  margin: 0;
  font-family: "游ゴシック Medium", "Yu Gothic Medium", "游ゴシック体", YuGothic, "ヒラギノ角ゴ ProN W3", "Hiragino Kaku Gothic ProN", "メイリオ", Meiryo, Verdana, Roboto, "Droid Sans", sans-serif;
  font-size: 1.6rem;
  font-weight: 400;
  line-height: 1.8;
  color: #212529;
  text-align: left;
  background-color: #fff; }

[tabindex="-1"]:focus:not(:focus-visible) {
  outline: 0 !important; }

hr {
  box-sizing: content-box;
  height: 0;
  overflow: visible; }

h1, h2, h3, h4, h5, h6 {
  margin-top: 0;
  margin-bottom: 0.5rem; }

p {
  margin-top: 0;
  margin-bottom: 1rem; }

abbr[title],
abbr[data-original-title] {
  text-decoration: underline;
  text-decoration: underline dotted;
  cursor: help;
  border-bottom: 0;
  text-decoration-skip-ink: none; }

address {
  margin-bottom: 1rem;
  font-style: normal;
  line-height: inherit; }

ol,
ul,
dl {
  margin-top: 0;
  margin-bottom: 1rem; }

ol ol,
ul ul,
ol ul,
ul ol {
  margin-bottom: 0; }

dt {
  font-weight: 700; }

dd {
  margin-bottom: .5rem;
  margin-left: 0; }

blockquote {
  margin: 0 0 1rem; }

b,
strong {
  font-weight: bolder; }

small {
  font-size: 80%; }

sub,
sup {
  position: relative;
  font-size: 75%;
  line-height: 0;
  vertical-align: baseline; }

sub {
  bottom: -.25em; }

sup {
  top: -.5em; }

a {
  color: #333;
  text-decoration: underline;
  background-color: transparent; }
  a:hover {
    color: #0d0d0d;
    text-decoration: underline; }

a:not([href]):not([class]) {
  color: inherit;
  text-decoration: none; }
  a:not([href]):not([class]):hover {
    color: inherit;
    text-decoration: none; }

pre,
code,
kbd,
samp {
  font-family: SFMono-Regular, Menlo, Monaco, Consolas, "Liberation Mono", "Courier New", monospace;
  font-size: 1em; }

pre {
  margin-top: 0;
  margin-bottom: 1rem;
  overflow: auto;
  -ms-overflow-style: scrollbar; }

figure {
  margin: 0 0 1rem; }

img {
  vertical-align: middle;
  border-style: none; }

svg {
  overflow: hidden;
  vertical-align: middle; }

table {
  border-collapse: collapse; }

caption {
  padding-top: 0.75rem;
  padding-bottom: 0.75rem;
  color: #6c757d;
  text-align: left;
  caption-side: bottom; }

th {
  text-align: inherit;
  text-align: -webkit-match-parent; }

label {
  display: inline-block;
  margin-bottom: 0.5rem; }

button {
  border-radius: 0; }

button:focus:not(:focus-visible) {
  outline: 0; }

input,
button,
select,
optgroup,
textarea {
  margin: 0;
  font-family: inherit;
  font-size: inherit;
  line-height: inherit; }

button,
input {
  overflow: visible; }

button,
select {
  text-transform: none; }

[role="button"] {
  cursor: pointer; }

select {
  word-wrap: normal; }

button,
[type="button"],
[type="reset"],
[type="submit"] {
  -webkit-appearance: button; }

button:not(:disabled),
[type="button"]:not(:disabled),
[type="reset"]:not(:disabled),
[type="submit"]:not(:disabled) {
  cursor: pointer; }

button::-moz-focus-inner,
[type="button"]::-moz-focus-inner,
[type="reset"]::-moz-focus-inner,
[type="submit"]::-moz-focus-inner {
  padding: 0;
  border-style: none; }

input[type="radio"],
input[type="checkbox"] {
  box-sizing: border-box;
  padding: 0; }

textarea {
  overflow: auto;
  resize: vertical; }

fieldset {
  min-width: 0;
  padding: 0;
  margin: 0;
  border: 0; }

legend {
  display: block;
  width: 100%;
  max-width: 100%;
  padding: 0;
  margin-bottom: .5rem;
  font-size: 1.5rem;
  line-height: inherit;
  color: inherit;
  white-space: normal; }

progress {
  vertical-align: baseline; }

[type="number"]::-webkit-inner-spin-button,
[type="number"]::-webkit-outer-spin-button {
  height: auto; }

[type="search"] {
  outline-offset: -2px;
  -webkit-appearance: none; }

[type="search"]::-webkit-search-decoration {
  -webkit-appearance: none; }

::-webkit-file-upload-button {
  font: inherit;
  -webkit-appearance: button; }

output {
  display: inline-block; }

summary {
  display: list-item;
  cursor: pointer; }

template {
  display: none; }

[hidden] {
  display: none !important; }

h1, h2, h3, h4, h5, h6,
.h1, .h2, .h3, .h4, .h5, .h6 {
  margin-bottom: 0.5rem;
  font-weight: 500;
  line-height: 1.3; }

h1, .h1 {
  font-size: 4.4rem; }

h2, .h2 {
  font-size: 3.2rem; }

h3, .h3 {
  font-size: 2.8rem; }

h4, .h4 {
  font-size: 2.08rem; }

h5, .h5 {
  font-size: 1.92rem; }

h6, .h6 {
  font-size: 1.6rem; }

.lead {
  font-size: 3.2rem;
  font-weight: 300; }

.display-1 {
  font-size: 6rem;
  font-weight: 300;
  line-height: 1.3; }

.display-2 {
  font-size: 5.5rem;
  font-weight: 300;
  line-height: 1.3; }

.display-3 {
  font-size: 4.5rem;
  font-weight: 300;
  line-height: 1.3; }

.display-4 {
  font-size: 3.5rem;
  font-weight: 300;
  line-height: 1.3; }

hr {
  margin-top: 1rem;
  margin-bottom: 1rem;
  border: 0;
  border-top: 1px solid rgba(0, 0, 0, 0.1); }

small,
.small {
  font-size: 85%;
  font-weight: 400; }

mark,
.mark {
  padding: 0.2em;
  background-color: #fcf8e3; }

.list-unstyled {
  padding-left: 0;
  list-style: none; }

.list-inline {
  padding-left: 0;
  list-style: none; }

.list-inline-item {
  display: inline-block; }
  .list-inline-item:not(:last-child) {
    margin-right: 0.5rem; }

.initialism {
  font-size: 90%;
  text-transform: uppercase; }

.blockquote {
  margin-bottom: 1rem;
  font-size: 2rem; }

.blockquote-footer {
  display: block;
  font-size: 85%;
  color: #6c757d; }
  .blockquote-footer::before {
    content: "\2014\00A0"; }

.img-fluid {
  max-width: 100%;
  height: auto; }

.img-thumbnail {
  padding: 0.25rem;
  background-color: #fff;
  border: 1px solid #dee2e6;
  border-radius: 0.25rem;
  max-width: 100%;
  height: auto; }

.figure {
  display: inline-block; }

.figure-img {
  margin-bottom: 0.5rem;
  line-height: 1; }

.figure-caption {
  font-size: 90%;
  color: #6c757d; }

code {
  font-size: 87.5%;
  color: #e83e8c;
  word-wrap: break-word; }
  a > code {
    color: inherit; }

kbd {
  padding: 0.2rem 0.4rem;
  font-size: 87.5%;
  color: #fff;
  background-color: #212529;
  border-radius: 0.2rem; }
  kbd kbd {
    padding: 0;
    font-size: 100%;
    font-weight: 700; }

pre {
  display: block;
  font-size: 87.5%;
  color: #212529; }
  pre code {
    font-size: inherit;
    color: inherit;
    word-break: normal; }

.pre-scrollable {
  max-height: 340px;
  overflow-y: scroll; }

.container,
.container-fluid,
.container-sm,
.container-md,
.container-lg,
.container-xl {
  width: 100%;
  padding-right: 15px;
  padding-left: 15px;
  margin-right: auto;
  margin-left: auto; }

@media (min-width: 576px) {
  .container, .container-sm {
    max-width: 540px; } }

@media (min-width: 768px) {
  .container, .container-sm, .container-md {
    max-width: 720px; } }

@media (min-width: 1080px) {
  .container, .container-sm, .container-md, .container-lg {
    max-width: 960px; } }

@media (min-width: 1400px) {
  .container, .container-sm, .container-md, .container-lg, .container-llg, .container-xl {
    max-width: 1140px; } }

.row {
  display: flex;
  flex-wrap: wrap;
  margin-right: -15px;
  margin-left: -15px; }

.no-gutters {
  margin-right: 0;
  margin-left: 0; }
  .no-gutters > .col,
  .no-gutters > [class*="col-"] {
    padding-right: 0;
    padding-left: 0; }

.col-1, .col-2, .col-3, .col-4, .col-5, .col-6, .col-7, .col-8, .col-9, .col-10, .col-11, .col-12, .col,
.col-auto, .col-sm-1, .col-sm-2, .col-sm-3, .col-sm-4, .col-sm-5, .col-sm-6, .col-sm-7, .col-sm-8, .col-sm-9, .col-sm-10, .col-sm-11, .col-sm-12, .col-sm,
.col-sm-auto, .col-md-1, .col-md-2, .col-md-3, .col-md-4, .col-md-5, .col-md-6, .col-md-7, .col-md-8, .col-md-9, .col-md-10, .col-md-11, .col-md-12, .col-md,
.col-md-auto, .col-lg-1, .col-lg-2, .col-lg-3, .col-lg-4, .col-lg-5, .col-lg-6, .col-lg-7, .col-lg-8, .col-lg-9, .col-lg-10, .col-lg-11, .col-lg-12, .col-lg,
.col-lg-auto, .col-llg-1, .col-llg-2, .col-llg-3, .col-llg-4, .col-llg-5, .col-llg-6, .col-llg-7, .col-llg-8, .col-llg-9, .col-llg-10, .col-llg-11, .col-llg-12, .col-llg,
.col-llg-auto, .col-xl-1, .col-xl-2, .col-xl-3, .col-xl-4, .col-xl-5, .col-xl-6, .col-xl-7, .col-xl-8, .col-xl-9, .col-xl-10, .col-xl-11, .col-xl-12, .col-xl,
.col-xl-auto, .col-xxl-1, .col-xxl-2, .col-xxl-3, .col-xxl-4, .col-xxl-5, .col-xxl-6, .col-xxl-7, .col-xxl-8, .col-xxl-9, .col-xxl-10, .col-xxl-11, .col-xxl-12, .col-xxl,
.col-xxl-auto {
  position: relative;
  width: 100%;
  padding-right: 15px;
  padding-left: 15px; }

.col {
  flex-basis: 0;
  flex-grow: 1;
  max-width: 100%; }

.row-cols-1 > * {
  flex: 0 0 100%;
  max-width: 100%; }

.row-cols-2 > * {
  flex: 0 0 50%;
  max-width: 50%; }

.row-cols-3 > * {
  flex: 0 0 33.33333%;
  max-width: 33.33333%; }

.row-cols-4 > * {
  flex: 0 0 25%;
  max-width: 25%; }

.row-cols-5 > * {
  flex: 0 0 20%;
  max-width: 20%; }

.row-cols-6 > * {
  flex: 0 0 16.66667%;
  max-width: 16.66667%; }

.col-auto {
  flex: 0 0 auto;
  width: auto;
  max-width: 100%; }

.col-1 {
  flex: 0 0 8.33333%;
  max-width: 8.33333%; }

.col-2 {
  flex: 0 0 16.66667%;
  max-width: 16.66667%; }

.col-3 {
  flex: 0 0 25%;
  max-width: 25%; }

.col-4 {
  flex: 0 0 33.33333%;
  max-width: 33.33333%; }

.col-5 {
  flex: 0 0 41.66667%;
  max-width: 41.66667%; }

.col-6 {
  flex: 0 0 50%;
  max-width: 50%; }

.col-7 {
  flex: 0 0 58.33333%;
  max-width: 58.33333%; }

.col-8 {
  flex: 0 0 66.66667%;
  max-width: 66.66667%; }

.col-9 {
  flex: 0 0 75%;
  max-width: 75%; }

.col-10 {
  flex: 0 0 83.33333%;
  max-width: 83.33333%; }

.col-11 {
  flex: 0 0 91.66667%;
  max-width: 91.66667%; }

.col-12 {
  flex: 0 0 100%;
  max-width: 100%; }

.order-first {
  order: -1; }

.order-last {
  order: 13; }

.order-0 {
  order: 0; }

.order-1 {
  order: 1; }

.order-2 {
  order: 2; }

.order-3 {
  order: 3; }

.order-4 {
  order: 4; }

.order-5 {
  order: 5; }

.order-6 {
  order: 6; }

.order-7 {
  order: 7; }

.order-8 {
  order: 8; }

.order-9 {
  order: 9; }

.order-10 {
  order: 10; }

.order-11 {
  order: 11; }

.order-12 {
  order: 12; }

.offset-1 {
  margin-left: 8.33333%; }

.offset-2 {
  margin-left: 16.66667%; }

.offset-3 {
  margin-left: 25%; }

.offset-4 {
  margin-left: 33.33333%; }

.offset-5 {
  margin-left: 41.66667%; }

.offset-6 {
  margin-left: 50%; }

.offset-7 {
  margin-left: 58.33333%; }

.offset-8 {
  margin-left: 66.66667%; }

.offset-9 {
  margin-left: 75%; }

.offset-10 {
  margin-left: 83.33333%; }

.offset-11 {
  margin-left: 91.66667%; }

@media (min-width: 576px) {
  .col-sm {
    flex-basis: 0;
    flex-grow: 1;
    max-width: 100%; }
  .row-cols-sm-1 > * {
    flex: 0 0 100%;
    max-width: 100%; }
  .row-cols-sm-2 > * {
    flex: 0 0 50%;
    max-width: 50%; }
  .row-cols-sm-3 > * {
    flex: 0 0 33.33333%;
    max-width: 33.33333%; }
  .row-cols-sm-4 > * {
    flex: 0 0 25%;
    max-width: 25%; }
  .row-cols-sm-5 > * {
    flex: 0 0 20%;
    max-width: 20%; }
  .row-cols-sm-6 > * {
    flex: 0 0 16.66667%;
    max-width: 16.66667%; }
  .col-sm-auto {
    flex: 0 0 auto;
    width: auto;
    max-width: 100%; }
  .col-sm-1 {
    flex: 0 0 8.33333%;
    max-width: 8.33333%; }
  .col-sm-2 {
    flex: 0 0 16.66667%;
    max-width: 16.66667%; }
  .col-sm-3 {
    flex: 0 0 25%;
    max-width: 25%; }
  .col-sm-4 {
    flex: 0 0 33.33333%;
    max-width: 33.33333%; }
  .col-sm-5 {
    flex: 0 0 41.66667%;
    max-width: 41.66667%; }
  .col-sm-6 {
    flex: 0 0 50%;
    max-width: 50%; }
  .col-sm-7 {
    flex: 0 0 58.33333%;
    max-width: 58.33333%; }
  .col-sm-8 {
    flex: 0 0 66.66667%;
    max-width: 66.66667%; }
  .col-sm-9 {
    flex: 0 0 75%;
    max-width: 75%; }
  .col-sm-10 {
    flex: 0 0 83.33333%;
    max-width: 83.33333%; }
  .col-sm-11 {
    flex: 0 0 91.66667%;
    max-width: 91.66667%; }
  .col-sm-12 {
    flex: 0 0 100%;
    max-width: 100%; }
  .order-sm-first {
    order: -1; }
  .order-sm-last {
    order: 13; }
  .order-sm-0 {
    order: 0; }
  .order-sm-1 {
    order: 1; }
  .order-sm-2 {
    order: 2; }
  .order-sm-3 {
    order: 3; }
  .order-sm-4 {
    order: 4; }
  .order-sm-5 {
    order: 5; }
  .order-sm-6 {
    order: 6; }
  .order-sm-7 {
    order: 7; }
  .order-sm-8 {
    order: 8; }
  .order-sm-9 {
    order: 9; }
  .order-sm-10 {
    order: 10; }
  .order-sm-11 {
    order: 11; }
  .order-sm-12 {
    order: 12; }
  .offset-sm-0 {
    margin-left: 0; }
  .offset-sm-1 {
    margin-left: 8.33333%; }
  .offset-sm-2 {
    margin-left: 16.66667%; }
  .offset-sm-3 {
    margin-left: 25%; }
  .offset-sm-4 {
    margin-left: 33.33333%; }
  .offset-sm-5 {
    margin-left: 41.66667%; }
  .offset-sm-6 {
    margin-left: 50%; }
  .offset-sm-7 {
    margin-left: 58.33333%; }
  .offset-sm-8 {
    margin-left: 66.66667%; }
  .offset-sm-9 {
    margin-left: 75%; }
  .offset-sm-10 {
    margin-left: 83.33333%; }
  .offset-sm-11 {
    margin-left: 91.66667%; } }

@media (min-width: 768px) {
  .col-md {
    flex-basis: 0;
    flex-grow: 1;
    max-width: 100%; }
  .row-cols-md-1 > * {
    flex: 0 0 100%;
    max-width: 100%; }
  .row-cols-md-2 > * {
    flex: 0 0 50%;
    max-width: 50%; }
  .row-cols-md-3 > * {
    flex: 0 0 33.33333%;
    max-width: 33.33333%; }
  .row-cols-md-4 > * {
    flex: 0 0 25%;
    max-width: 25%; }
  .row-cols-md-5 > * {
    flex: 0 0 20%;
    max-width: 20%; }
  .row-cols-md-6 > * {
    flex: 0 0 16.66667%;
    max-width: 16.66667%; }
  .col-md-auto {
    flex: 0 0 auto;
    width: auto;
    max-width: 100%; }
  .col-md-1 {
    flex: 0 0 8.33333%;
    max-width: 8.33333%; }
  .col-md-2 {
    flex: 0 0 16.66667%;
    max-width: 16.66667%; }
  .col-md-3 {
    flex: 0 0 25%;
    max-width: 25%; }
  .col-md-4 {
    flex: 0 0 33.33333%;
    max-width: 33.33333%; }
  .col-md-5 {
    flex: 0 0 41.66667%;
    max-width: 41.66667%; }
  .col-md-6 {
    flex: 0 0 50%;
    max-width: 50%; }
  .col-md-7 {
    flex: 0 0 58.33333%;
    max-width: 58.33333%; }
  .col-md-8 {
    flex: 0 0 66.66667%;
    max-width: 66.66667%; }
  .col-md-9 {
    flex: 0 0 75%;
    max-width: 75%; }
  .col-md-10 {
    flex: 0 0 83.33333%;
    max-width: 83.33333%; }
  .col-md-11 {
    flex: 0 0 91.66667%;
    max-width: 91.66667%; }
  .col-md-12 {
    flex: 0 0 100%;
    max-width: 100%; }
  .order-md-first {
    order: -1; }
  .order-md-last {
    order: 13; }
  .order-md-0 {
    order: 0; }
  .order-md-1 {
    order: 1; }
  .order-md-2 {
    order: 2; }
  .order-md-3 {
    order: 3; }
  .order-md-4 {
    order: 4; }
  .order-md-5 {
    order: 5; }
  .order-md-6 {
    order: 6; }
  .order-md-7 {
    order: 7; }
  .order-md-8 {
    order: 8; }
  .order-md-9 {
    order: 9; }
  .order-md-10 {
    order: 10; }
  .order-md-11 {
    order: 11; }
  .order-md-12 {
    order: 12; }
  .offset-md-0 {
    margin-left: 0; }
  .offset-md-1 {
    margin-left: 8.33333%; }
  .offset-md-2 {
    margin-left: 16.66667%; }
  .offset-md-3 {
    margin-left: 25%; }
  .offset-md-4 {
    margin-left: 33.33333%; }
  .offset-md-5 {
    margin-left: 41.66667%; }
  .offset-md-6 {
    margin-left: 50%; }
  .offset-md-7 {
    margin-left: 58.33333%; }
  .offset-md-8 {
    margin-left: 66.66667%; }
  .offset-md-9 {
    margin-left: 75%; }
  .offset-md-10 {
    margin-left: 83.33333%; }
  .offset-md-11 {
    margin-left: 91.66667%; } }

@media (min-width: 1080px) {
  .col-lg {
    flex-basis: 0;
    flex-grow: 1;
    max-width: 100%; }
  .row-cols-lg-1 > * {
    flex: 0 0 100%;
    max-width: 100%; }
  .row-cols-lg-2 > * {
    flex: 0 0 50%;
    max-width: 50%; }
  .row-cols-lg-3 > * {
    flex: 0 0 33.33333%;
    max-width: 33.33333%; }
  .row-cols-lg-4 > * {
    flex: 0 0 25%;
    max-width: 25%; }
  .row-cols-lg-5 > * {
    flex: 0 0 20%;
    max-width: 20%; }
  .row-cols-lg-6 > * {
    flex: 0 0 16.66667%;
    max-width: 16.66667%; }
  .col-lg-auto {
    flex: 0 0 auto;
    width: auto;
    max-width: 100%; }
  .col-lg-1 {
    flex: 0 0 8.33333%;
    max-width: 8.33333%; }
  .col-lg-2 {
    flex: 0 0 16.66667%;
    max-width: 16.66667%; }
  .col-lg-3 {
    flex: 0 0 25%;
    max-width: 25%; }
  .col-lg-4 {
    flex: 0 0 33.33333%;
    max-width: 33.33333%; }
  .col-lg-5 {
    flex: 0 0 41.66667%;
    max-width: 41.66667%; }
  .col-lg-6 {
    flex: 0 0 50%;
    max-width: 50%; }
  .col-lg-7 {
    flex: 0 0 58.33333%;
    max-width: 58.33333%; }
  .col-lg-8 {
    flex: 0 0 66.66667%;
    max-width: 66.66667%; }
  .col-lg-9 {
    flex: 0 0 75%;
    max-width: 75%; }
  .col-lg-10 {
    flex: 0 0 83.33333%;
    max-width: 83.33333%; }
  .col-lg-11 {
    flex: 0 0 91.66667%;
    max-width: 91.66667%; }
  .col-lg-12 {
    flex: 0 0 100%;
    max-width: 100%; }
  .order-lg-first {
    order: -1; }
  .order-lg-last {
    order: 13; }
  .order-lg-0 {
    order: 0; }
  .order-lg-1 {
    order: 1; }
  .order-lg-2 {
    order: 2; }
  .order-lg-3 {
    order: 3; }
  .order-lg-4 {
    order: 4; }
  .order-lg-5 {
    order: 5; }
  .order-lg-6 {
    order: 6; }
  .order-lg-7 {
    order: 7; }
  .order-lg-8 {
    order: 8; }
  .order-lg-9 {
    order: 9; }
  .order-lg-10 {
    order: 10; }
  .order-lg-11 {
    order: 11; }
  .order-lg-12 {
    order: 12; }
  .offset-lg-0 {
    margin-left: 0; }
  .offset-lg-1 {
    margin-left: 8.33333%; }
  .offset-lg-2 {
    margin-left: 16.66667%; }
  .offset-lg-3 {
    margin-left: 25%; }
  .offset-lg-4 {
    margin-left: 33.33333%; }
  .offset-lg-5 {
    margin-left: 41.66667%; }
  .offset-lg-6 {
    margin-left: 50%; }
  .offset-lg-7 {
    margin-left: 58.33333%; }
  .offset-lg-8 {
    margin-left: 66.66667%; }
  .offset-lg-9 {
    margin-left: 75%; }
  .offset-lg-10 {
    margin-left: 83.33333%; }
  .offset-lg-11 {
    margin-left: 91.66667%; } }

@media (min-width: 1280px) {
  .col-llg {
    flex-basis: 0;
    flex-grow: 1;
    max-width: 100%; }
  .row-cols-llg-1 > * {
    flex: 0 0 100%;
    max-width: 100%; }
  .row-cols-llg-2 > * {
    flex: 0 0 50%;
    max-width: 50%; }
  .row-cols-llg-3 > * {
    flex: 0 0 33.33333%;
    max-width: 33.33333%; }
  .row-cols-llg-4 > * {
    flex: 0 0 25%;
    max-width: 25%; }
  .row-cols-llg-5 > * {
    flex: 0 0 20%;
    max-width: 20%; }
  .row-cols-llg-6 > * {
    flex: 0 0 16.66667%;
    max-width: 16.66667%; }
  .col-llg-auto {
    flex: 0 0 auto;
    width: auto;
    max-width: 100%; }
  .col-llg-1 {
    flex: 0 0 8.33333%;
    max-width: 8.33333%; }
  .col-llg-2 {
    flex: 0 0 16.66667%;
    max-width: 16.66667%; }
  .col-llg-3 {
    flex: 0 0 25%;
    max-width: 25%; }
  .col-llg-4 {
    flex: 0 0 33.33333%;
    max-width: 33.33333%; }
  .col-llg-5 {
    flex: 0 0 41.66667%;
    max-width: 41.66667%; }
  .col-llg-6 {
    flex: 0 0 50%;
    max-width: 50%; }
  .col-llg-7 {
    flex: 0 0 58.33333%;
    max-width: 58.33333%; }
  .col-llg-8 {
    flex: 0 0 66.66667%;
    max-width: 66.66667%; }
  .col-llg-9 {
    flex: 0 0 75%;
    max-width: 75%; }
  .col-llg-10 {
    flex: 0 0 83.33333%;
    max-width: 83.33333%; }
  .col-llg-11 {
    flex: 0 0 91.66667%;
    max-width: 91.66667%; }
  .col-llg-12 {
    flex: 0 0 100%;
    max-width: 100%; }
  .order-llg-first {
    order: -1; }
  .order-llg-last {
    order: 13; }
  .order-llg-0 {
    order: 0; }
  .order-llg-1 {
    order: 1; }
  .order-llg-2 {
    order: 2; }
  .order-llg-3 {
    order: 3; }
  .order-llg-4 {
    order: 4; }
  .order-llg-5 {
    order: 5; }
  .order-llg-6 {
    order: 6; }
  .order-llg-7 {
    order: 7; }
  .order-llg-8 {
    order: 8; }
  .order-llg-9 {
    order: 9; }
  .order-llg-10 {
    order: 10; }
  .order-llg-11 {
    order: 11; }
  .order-llg-12 {
    order: 12; }
  .offset-llg-0 {
    margin-left: 0; }
  .offset-llg-1 {
    margin-left: 8.33333%; }
  .offset-llg-2 {
    margin-left: 16.66667%; }
  .offset-llg-3 {
    margin-left: 25%; }
  .offset-llg-4 {
    margin-left: 33.33333%; }
  .offset-llg-5 {
    margin-left: 41.66667%; }
  .offset-llg-6 {
    margin-left: 50%; }
  .offset-llg-7 {
    margin-left: 58.33333%; }
  .offset-llg-8 {
    margin-left: 66.66667%; }
  .offset-llg-9 {
    margin-left: 75%; }
  .offset-llg-10 {
    margin-left: 83.33333%; }
  .offset-llg-11 {
    margin-left: 91.66667%; } }

@media (min-width: 1400px) {
  .col-xl {
    flex-basis: 0;
    flex-grow: 1;
    max-width: 100%; }
  .row-cols-xl-1 > * {
    flex: 0 0 100%;
    max-width: 100%; }
  .row-cols-xl-2 > * {
    flex: 0 0 50%;
    max-width: 50%; }
  .row-cols-xl-3 > * {
    flex: 0 0 33.33333%;
    max-width: 33.33333%; }
  .row-cols-xl-4 > * {
    flex: 0 0 25%;
    max-width: 25%; }
  .row-cols-xl-5 > * {
    flex: 0 0 20%;
    max-width: 20%; }
  .row-cols-xl-6 > * {
    flex: 0 0 16.66667%;
    max-width: 16.66667%; }
  .col-xl-auto {
    flex: 0 0 auto;
    width: auto;
    max-width: 100%; }
  .col-xl-1 {
    flex: 0 0 8.33333%;
    max-width: 8.33333%; }
  .col-xl-2 {
    flex: 0 0 16.66667%;
    max-width: 16.66667%; }
  .col-xl-3 {
    flex: 0 0 25%;
    max-width: 25%; }
  .col-xl-4 {
    flex: 0 0 33.33333%;
    max-width: 33.33333%; }
  .col-xl-5 {
    flex: 0 0 41.66667%;
    max-width: 41.66667%; }
  .col-xl-6 {
    flex: 0 0 50%;
    max-width: 50%; }
  .col-xl-7 {
    flex: 0 0 58.33333%;
    max-width: 58.33333%; }
  .col-xl-8 {
    flex: 0 0 66.66667%;
    max-width: 66.66667%; }
  .col-xl-9 {
    flex: 0 0 75%;
    max-width: 75%; }
  .col-xl-10 {
    flex: 0 0 83.33333%;
    max-width: 83.33333%; }
  .col-xl-11 {
    flex: 0 0 91.66667%;
    max-width: 91.66667%; }
  .col-xl-12 {
    flex: 0 0 100%;
    max-width: 100%; }
  .order-xl-first {
    order: -1; }
  .order-xl-last {
    order: 13; }
  .order-xl-0 {
    order: 0; }
  .order-xl-1 {
    order: 1; }
  .order-xl-2 {
    order: 2; }
  .order-xl-3 {
    order: 3; }
  .order-xl-4 {
    order: 4; }
  .order-xl-5 {
    order: 5; }
  .order-xl-6 {
    order: 6; }
  .order-xl-7 {
    order: 7; }
  .order-xl-8 {
    order: 8; }
  .order-xl-9 {
    order: 9; }
  .order-xl-10 {
    order: 10; }
  .order-xl-11 {
    order: 11; }
  .order-xl-12 {
    order: 12; }
  .offset-xl-0 {
    margin-left: 0; }
  .offset-xl-1 {
    margin-left: 8.33333%; }
  .offset-xl-2 {
    margin-left: 16.66667%; }
  .offset-xl-3 {
    margin-left: 25%; }
  .offset-xl-4 {
    margin-left: 33.33333%; }
  .offset-xl-5 {
    margin-left: 41.66667%; }
  .offset-xl-6 {
    margin-left: 50%; }
  .offset-xl-7 {
    margin-left: 58.33333%; }
  .offset-xl-8 {
    margin-left: 66.66667%; }
  .offset-xl-9 {
    margin-left: 75%; }
  .offset-xl-10 {
    margin-left: 83.33333%; }
  .offset-xl-11 {
    margin-left: 91.66667%; } }

@media (min-width: 1980px) {
  .col-xxl {
    flex-basis: 0;
    flex-grow: 1;
    max-width: 100%; }
  .row-cols-xxl-1 > * {
    flex: 0 0 100%;
    max-width: 100%; }
  .row-cols-xxl-2 > * {
    flex: 0 0 50%;
    max-width: 50%; }
  .row-cols-xxl-3 > * {
    flex: 0 0 33.33333%;
    max-width: 33.33333%; }
  .row-cols-xxl-4 > * {
    flex: 0 0 25%;
    max-width: 25%; }
  .row-cols-xxl-5 > * {
    flex: 0 0 20%;
    max-width: 20%; }
  .row-cols-xxl-6 > * {
    flex: 0 0 16.66667%;
    max-width: 16.66667%; }
  .col-xxl-auto {
    flex: 0 0 auto;
    width: auto;
    max-width: 100%; }
  .col-xxl-1 {
    flex: 0 0 8.33333%;
    max-width: 8.33333%; }
  .col-xxl-2 {
    flex: 0 0 16.66667%;
    max-width: 16.66667%; }
  .col-xxl-3 {
    flex: 0 0 25%;
    max-width: 25%; }
  .col-xxl-4 {
    flex: 0 0 33.33333%;
    max-width: 33.33333%; }
  .col-xxl-5 {
    flex: 0 0 41.66667%;
    max-width: 41.66667%; }
  .col-xxl-6 {
    flex: 0 0 50%;
    max-width: 50%; }
  .col-xxl-7 {
    flex: 0 0 58.33333%;
    max-width: 58.33333%; }
  .col-xxl-8 {
    flex: 0 0 66.66667%;
    max-width: 66.66667%; }
  .col-xxl-9 {
    flex: 0 0 75%;
    max-width: 75%; }
  .col-xxl-10 {
    flex: 0 0 83.33333%;
    max-width: 83.33333%; }
  .col-xxl-11 {
    flex: 0 0 91.66667%;
    max-width: 91.66667%; }
  .col-xxl-12 {
    flex: 0 0 100%;
    max-width: 100%; }
  .order-xxl-first {
    order: -1; }
  .order-xxl-last {
    order: 13; }
  .order-xxl-0 {
    order: 0; }
  .order-xxl-1 {
    order: 1; }
  .order-xxl-2 {
    order: 2; }
  .order-xxl-3 {
    order: 3; }
  .order-xxl-4 {
    order: 4; }
  .order-xxl-5 {
    order: 5; }
  .order-xxl-6 {
    order: 6; }
  .order-xxl-7 {
    order: 7; }
  .order-xxl-8 {
    order: 8; }
  .order-xxl-9 {
    order: 9; }
  .order-xxl-10 {
    order: 10; }
  .order-xxl-11 {
    order: 11; }
  .order-xxl-12 {
    order: 12; }
  .offset-xxl-0 {
    margin-left: 0; }
  .offset-xxl-1 {
    margin-left: 8.33333%; }
  .offset-xxl-2 {
    margin-left: 16.66667%; }
  .offset-xxl-3 {
    margin-left: 25%; }
  .offset-xxl-4 {
    margin-left: 33.33333%; }
  .offset-xxl-5 {
    margin-left: 41.66667%; }
  .offset-xxl-6 {
    margin-left: 50%; }
  .offset-xxl-7 {
    margin-left: 58.33333%; }
  .offset-xxl-8 {
    margin-left: 66.66667%; }
  .offset-xxl-9 {
    margin-left: 75%; }
  .offset-xxl-10 {
    margin-left: 83.33333%; }
  .offset-xxl-11 {
    margin-left: 91.66667%; } }

.table {
  width: 100%;
  margin-bottom: 1rem;
  color: #212529; }
  .table th,
  .table td {
    padding: 0.75rem;
    vertical-align: top;
    border-top: 1px solid #CCC; }
  .table thead th {
    vertical-align: bottom;
    border-bottom: 2px solid #CCC; }
  .table tbody + tbody {
    border-top: 2px solid #CCC; }

.table-sm th,
.table-sm td {
  padding: 0.3rem; }

.table-bordered {
  border: 1px solid #CCC; }
  .table-bordered th,
  .table-bordered td {
    border: 1px solid #CCC; }
  .table-bordered thead th,
  .table-bordered thead td {
    border-bottom-width: 2px; }

.table-borderless th,
.table-borderless td,
.table-borderless thead th,
.table-borderless tbody + tbody {
  border: 0; }

.table-striped tbody tr:nth-of-type(odd) {
  background-color: rgba(0, 0, 0, 0.05); }

.table-hover tbody tr:hover {
  color: #212529;
  background-color: rgba(0, 0, 0, 0.075); }

.table-primary,
.table-primary > th,
.table-primary > td {
  background-color: #b8daff; }

.table-primary th,
.table-primary td,
.table-primary thead th,
.table-primary tbody + tbody {
  border-color: #7abaff; }

.table-hover .table-primary:hover {
  background-color: #9fcdff; }
  .table-hover .table-primary:hover > td,
  .table-hover .table-primary:hover > th {
    background-color: #9fcdff; }

.table-secondary,
.table-secondary > th,
.table-secondary > td {
  background-color: #d6d8db; }

.table-secondary th,
.table-secondary td,
.table-secondary thead th,
.table-secondary tbody + tbody {
  border-color: #b3b7bb; }

.table-hover .table-secondary:hover {
  background-color: #c8cbcf; }
  .table-hover .table-secondary:hover > td,
  .table-hover .table-secondary:hover > th {
    background-color: #c8cbcf; }

.table-success,
.table-success > th,
.table-success > td {
  background-color: #c3e6cb; }

.table-success th,
.table-success td,
.table-success thead th,
.table-success tbody + tbody {
  border-color: #8fd19e; }

.table-hover .table-success:hover {
  background-color: #b1dfbb; }
  .table-hover .table-success:hover > td,
  .table-hover .table-success:hover > th {
    background-color: #b1dfbb; }

.table-info,
.table-info > th,
.table-info > td {
  background-color: #bee5eb; }

.table-info th,
.table-info td,
.table-info thead th,
.table-info tbody + tbody {
  border-color: #86cfda; }

.table-hover .table-info:hover {
  background-color: #abdde5; }
  .table-hover .table-info:hover > td,
  .table-hover .table-info:hover > th {
    background-color: #abdde5; }

.table-warning,
.table-warning > th,
.table-warning > td {
  background-color: #ffeeba; }

.table-warning th,
.table-warning td,
.table-warning thead th,
.table-warning tbody + tbody {
  border-color: #ffdf7e; }

.table-hover .table-warning:hover {
  background-color: #ffe8a1; }
  .table-hover .table-warning:hover > td,
  .table-hover .table-warning:hover > th {
    background-color: #ffe8a1; }

.table-danger,
.table-danger > th,
.table-danger > td {
  background-color: #f5c6cb; }

.table-danger th,
.table-danger td,
.table-danger thead th,
.table-danger tbody + tbody {
  border-color: #ed969e; }

.table-hover .table-danger:hover {
  background-color: #f1b0b7; }
  .table-hover .table-danger:hover > td,
  .table-hover .table-danger:hover > th {
    background-color: #f1b0b7; }

.table-light,
.table-light > th,
.table-light > td {
  background-color: #fdfdfe; }

.table-light th,
.table-light td,
.table-light thead th,
.table-light tbody + tbody {
  border-color: #fbfcfc; }

.table-hover .table-light:hover {
  background-color: #ececf6; }
  .table-hover .table-light:hover > td,
  .table-hover .table-light:hover > th {
    background-color: #ececf6; }

.table-dark,
.table-dark > th,
.table-dark > td {
  background-color: #c6c8ca; }

.table-dark th,
.table-dark td,
.table-dark thead th,
.table-dark tbody + tbody {
  border-color: #95999c; }

.table-hover .table-dark:hover {
  background-color: #b9bbbe; }
  .table-hover .table-dark:hover > td,
  .table-hover .table-dark:hover > th {
    background-color: #b9bbbe; }

.table-active,
.table-active > th,
.table-active > td {
  background-color: rgba(0, 0, 0, 0.075); }

.table-hover .table-active:hover {
  background-color: rgba(0, 0, 0, 0.075); }
  .table-hover .table-active:hover > td,
  .table-hover .table-active:hover > th {
    background-color: rgba(0, 0, 0, 0.075); }

.table .thead-dark th {
  color: #fff;
  background-color: #343a40;
  border-color: #454d55; }

.table .thead-light th {
  color: #495057;
  background-color: #e9ecef;
  border-color: #CCC; }

.table-dark {
  color: #fff;
  background-color: #343a40; }
  .table-dark th,
  .table-dark td,
  .table-dark thead th {
    border-color: #454d55; }
  .table-dark.table-bordered {
    border: 0; }
  .table-dark.table-striped tbody tr:nth-of-type(odd) {
    background-color: rgba(255, 255, 255, 0.05); }
  .table-dark.table-hover tbody tr:hover {
    color: #fff;
    background-color: rgba(255, 255, 255, 0.075); }

@media (max-width: 575.98px) {
  .table-responsive-sm {
    display: block;
    width: 100%;
    overflow-x: auto;
    -webkit-overflow-scrolling: touch; }
    .table-responsive-sm > .table-bordered {
      border: 0; } }

@media (max-width: 767.98px) {
  .table-responsive-md {
    display: block;
    width: 100%;
    overflow-x: auto;
    -webkit-overflow-scrolling: touch; }
    .table-responsive-md > .table-bordered {
      border: 0; } }

@media (max-width: 1079.98px) {
  .table-responsive-lg {
    display: block;
    width: 100%;
    overflow-x: auto;
    -webkit-overflow-scrolling: touch; }
    .table-responsive-lg > .table-bordered {
      border: 0; } }

@media (max-width: 1279.98px) {
  .table-responsive-llg {
    display: block;
    width: 100%;
    overflow-x: auto;
    -webkit-overflow-scrolling: touch; }
    .table-responsive-llg > .table-bordered {
      border: 0; } }

@media (max-width: 1399.98px) {
  .table-responsive-xl {
    display: block;
    width: 100%;
    overflow-x: auto;
    -webkit-overflow-scrolling: touch; }
    .table-responsive-xl > .table-bordered {
      border: 0; } }

@media (max-width: 1979.98px) {
  .table-responsive-xxl {
    display: block;
    width: 100%;
    overflow-x: auto;
    -webkit-overflow-scrolling: touch; }
    .table-responsive-xxl > .table-bordered {
      border: 0; } }

.table-responsive {
  display: block;
  width: 100%;
  overflow-x: auto;
  -webkit-overflow-scrolling: touch; }
  .table-responsive > .table-bordered {
    border: 0; }

.form-control {
  display: block;
  width: 100%;
  height: calc(1.8em + 0.75rem + 2px);
  padding: 0.375rem 0.75rem;
  font-size: 1.6rem;
  font-weight: 400;
  line-height: 1.8;
  color: #495057;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid #ced4da;
  border-radius: 0.25rem;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out; }
  @media (prefers-reduced-motion: reduce) {
    .form-control {
      transition: none; } }
  .form-control::-ms-expand {
    background-color: transparent;
    border: 0; }
  .form-control:focus {
    color: #495057;
    background-color: #fff;
    border-color: #80bdff;
    outline: 0;
    box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, 0.25); }
  .form-control::placeholder {
    color: #6c757d;
    opacity: 1; }
  .form-control:disabled, .form-control[readonly] {
    background-color: #e9ecef;
    opacity: 1; }

input[type="date"].form-control,
input[type="time"].form-control,
input[type="datetime-local"].form-control,
input[type="month"].form-control {
  appearance: none; }

select.form-control:-moz-focusring {
  color: transparent;
  text-shadow: 0 0 0 #495057; }

select.form-control:focus::-ms-value {
  color: #495057;
  background-color: #fff; }

.form-control-file,
.form-control-range {
  display: block;
  width: 100%; }

.col-form-label {
  padding-top: calc(0.375rem + 1px);
  padding-bottom: calc(0.375rem + 1px);
  margin-bottom: 0;
  font-size: inherit;
  line-height: 1.8; }

.col-form-label-lg {
  padding-top: calc(0.5rem + 1px);
  padding-bottom: calc(0.5rem + 1px);
  font-size: 2rem;
  line-height: 1.5; }

.col-form-label-sm {
  padding-top: calc(0.25rem + 1px);
  padding-bottom: calc(0.25rem + 1px);
  font-size: 1.4rem;
  line-height: 1.5; }

.form-control-plaintext {
  display: block;
  width: 100%;
  padding: 0.375rem 0;
  margin-bottom: 0;
  font-size: 1.6rem;
  line-height: 1.8;
  color: #212529;
  background-color: transparent;
  border: solid transparent;
  border-width: 1px 0; }
  .form-control-plaintext.form-control-sm, .form-control-plaintext.form-control-lg {
    padding-right: 0;
    padding-left: 0; }

.form-control-sm {
  height: calc(1.5em + 0.5rem + 2px);
  padding: 0.25rem 0.5rem;
  font-size: 1.4rem;
  line-height: 1.5;
  border-radius: 0.2rem; }

.form-control-lg {
  height: calc(1.5em + 1rem + 2px);
  padding: 0.5rem 1rem;
  font-size: 2rem;
  line-height: 1.5;
  border-radius: 0.3rem; }

select.form-control[size], select.form-control[multiple] {
  height: auto; }

textarea.form-control {
  height: auto; }

.form-group {
  margin-bottom: 1rem; }

.form-text {
  display: block;
  margin-top: 0.25rem; }

.form-row {
  display: flex;
  flex-wrap: wrap;
  margin-right: -5px;
  margin-left: -5px; }
  .form-row > .col,
  .form-row > [class*="col-"] {
    padding-right: 5px;
    padding-left: 5px; }

.form-check {
  position: relative;
  display: block;
  padding-left: 1.25rem; }

.form-check-input {
  position: absolute;
  margin-top: 0.3rem;
  margin-left: -1.25rem; }
  .form-check-input[disabled] ~ .form-check-label,
  .form-check-input:disabled ~ .form-check-label {
    color: #6c757d; }

.form-check-label {
  margin-bottom: 0; }

.form-check-inline {
  display: inline-flex;
  align-items: center;
  padding-left: 0;
  margin-right: 0.75rem; }
  .form-check-inline .form-check-input {
    position: static;
    margin-top: 0;
    margin-right: 0.3125rem;
    margin-left: 0; }

.valid-feedback {
  display: none;
  width: 100%;
  margin-top: 0.25rem;
  font-size: 85%;
  color: #28a745; }

.valid-tooltip {
  position: absolute;
  top: 100%;
  left: 0;
  z-index: 5;
  display: none;
  max-width: 100%;
  padding: 0.25rem 0.5rem;
  margin-top: .1rem;
  font-size: 1.4rem;
  line-height: 1.8;
  color: #fff;
  background-color: rgba(40, 167, 69, 0.9);
  border-radius: 0.25rem; }
  .form-row > .col > .valid-tooltip,
  .form-row > [class*="col-"] > .valid-tooltip {
    left: 5px; }

.was-validated :valid ~ .valid-feedback,
.was-validated :valid ~ .valid-tooltip,
.is-valid ~ .valid-feedback,
.is-valid ~ .valid-tooltip {
  display: block; }

.was-validated .form-control:valid, .form-control.is-valid {
  border-color: #28a745;
  padding-right: calc(1.8em + 0.75rem) !important;
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='8' height='8' viewBox='0 0 8 8'%3e%3cpath fill='%2328a745' d='M2.3 6.73L.6 4.53c-.4-1.04.46-1.4 1.1-.8l1.1 1.4 3.4-3.8c.6-.63 1.6-.27 1.2.7l-4 4.6c-.43.5-.8.4-1.1.1z'/%3e%3c/svg%3e");
  background-repeat: no-repeat;
  background-position: right calc(0.45em + 0.1875rem) center;
  background-size: calc(0.9em + 0.375rem) calc(0.9em + 0.375rem); }
  .was-validated .form-control:valid:focus, .form-control.is-valid:focus {
    border-color: #28a745;
    box-shadow: 0 0 0 0.2rem rgba(40, 167, 69, 0.25); }

.was-validated select.form-control:valid, select.form-control.is-valid {
  padding-right: 3rem !important;
  background-position: right 1.5rem center; }

.was-validated textarea.form-control:valid, textarea.form-control.is-valid {
  padding-right: calc(1.8em + 0.75rem);
  background-position: top calc(0.45em + 0.1875rem) right calc(0.45em + 0.1875rem); }

.was-validated .custom-select:valid, .custom-select.is-valid {
  border-color: #28a745;
  padding-right: calc(0.75em + 2.3125rem) !important;
  background: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='4' height='5' viewBox='0 0 4 5'%3e%3cpath fill='%23343a40' d='M2 0L0 2h4zm0 5L0 3h4z'/%3e%3c/svg%3e") right 0.75rem center/8px 10px no-repeat, #fff url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='8' height='8' viewBox='0 0 8 8'%3e%3cpath fill='%2328a745' d='M2.3 6.73L.6 4.53c-.4-1.04.46-1.4 1.1-.8l1.1 1.4 3.4-3.8c.6-.63 1.6-.27 1.2.7l-4 4.6c-.43.5-.8.4-1.1.1z'/%3e%3c/svg%3e") center right 1.75rem/calc(0.9em + 0.375rem) calc(0.9em + 0.375rem) no-repeat; }
  .was-validated .custom-select:valid:focus, .custom-select.is-valid:focus {
    border-color: #28a745;
    box-shadow: 0 0 0 0.2rem rgba(40, 167, 69, 0.25); }

.was-validated .form-check-input:valid ~ .form-check-label, .form-check-input.is-valid ~ .form-check-label {
  color: #28a745; }

.was-validated .form-check-input:valid ~ .valid-feedback,
.was-validated .form-check-input:valid ~ .valid-tooltip, .form-check-input.is-valid ~ .valid-feedback,
.form-check-input.is-valid ~ .valid-tooltip {
  display: block; }

.was-validated .custom-control-input:valid ~ .custom-control-label, .custom-control-input.is-valid ~ .custom-control-label {
  color: #28a745; }
  .was-validated .custom-control-input:valid ~ .custom-control-label::before, .custom-control-input.is-valid ~ .custom-control-label::before {
    border-color: #28a745; }

.was-validated .custom-control-input:valid:checked ~ .custom-control-label::before, .custom-control-input.is-valid:checked ~ .custom-control-label::before {
  border-color: #34ce57;
  background-color: #34ce57; }

.was-validated .custom-control-input:valid:focus ~ .custom-control-label::before, .custom-control-input.is-valid:focus ~ .custom-control-label::before {
  box-shadow: 0 0 0 0.2rem rgba(40, 167, 69, 0.25); }

.was-validated .custom-control-input:valid:focus:not(:checked) ~ .custom-control-label::before, .custom-control-input.is-valid:focus:not(:checked) ~ .custom-control-label::before {
  border-color: #28a745; }

.was-validated .custom-file-input:valid ~ .custom-file-label, .custom-file-input.is-valid ~ .custom-file-label {
  border-color: #28a745; }

.was-validated .custom-file-input:valid:focus ~ .custom-file-label, .custom-file-input.is-valid:focus ~ .custom-file-label {
  border-color: #28a745;
  box-shadow: 0 0 0 0.2rem rgba(40, 167, 69, 0.25); }

.invalid-feedback {
  display: none;
  width: 100%;
  margin-top: 0.25rem;
  font-size: 85%;
  color: #dc3545; }

.invalid-tooltip {
  position: absolute;
  top: 100%;
  left: 0;
  z-index: 5;
  display: none;
  max-width: 100%;
  padding: 0.25rem 0.5rem;
  margin-top: .1rem;
  font-size: 1.4rem;
  line-height: 1.8;
  color: #fff;
  background-color: rgba(220, 53, 69, 0.9);
  border-radius: 0.25rem; }
  .form-row > .col > .invalid-tooltip,
  .form-row > [class*="col-"] > .invalid-tooltip {
    left: 5px; }

.was-validated :invalid ~ .invalid-feedback,
.was-validated :invalid ~ .invalid-tooltip,
.is-invalid ~ .invalid-feedback,
.is-invalid ~ .invalid-tooltip {
  display: block; }

.was-validated .form-control:invalid, .form-control.is-invalid {
  border-color: #dc3545;
  padding-right: calc(1.8em + 0.75rem) !important;
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='12' height='12' fill='none' stroke='%23dc3545' viewBox='0 0 12 12'%3e%3ccircle cx='6' cy='6' r='4.5'/%3e%3cpath stroke-linejoin='round' d='M5.8 3.6h.4L6 6.5z'/%3e%3ccircle cx='6' cy='8.2' r='.6' fill='%23dc3545' stroke='none'/%3e%3c/svg%3e");
  background-repeat: no-repeat;
  background-position: right calc(0.45em + 0.1875rem) center;
  background-size: calc(0.9em + 0.375rem) calc(0.9em + 0.375rem); }
  .was-validated .form-control:invalid:focus, .form-control.is-invalid:focus {
    border-color: #dc3545;
    box-shadow: 0 0 0 0.2rem rgba(220, 53, 69, 0.25); }

.was-validated select.form-control:invalid, select.form-control.is-invalid {
  padding-right: 3rem !important;
  background-position: right 1.5rem center; }

.was-validated textarea.form-control:invalid, textarea.form-control.is-invalid {
  padding-right: calc(1.8em + 0.75rem);
  background-position: top calc(0.45em + 0.1875rem) right calc(0.45em + 0.1875rem); }

.was-validated .custom-select:invalid, .custom-select.is-invalid {
  border-color: #dc3545;
  padding-right: calc(0.75em + 2.3125rem) !important;
  background: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='4' height='5' viewBox='0 0 4 5'%3e%3cpath fill='%23343a40' d='M2 0L0 2h4zm0 5L0 3h4z'/%3e%3c/svg%3e") right 0.75rem center/8px 10px no-repeat, #fff url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='12' height='12' fill='none' stroke='%23dc3545' viewBox='0 0 12 12'%3e%3ccircle cx='6' cy='6' r='4.5'/%3e%3cpath stroke-linejoin='round' d='M5.8 3.6h.4L6 6.5z'/%3e%3ccircle cx='6' cy='8.2' r='.6' fill='%23dc3545' stroke='none'/%3e%3c/svg%3e") center right 1.75rem/calc(0.9em + 0.375rem) calc(0.9em + 0.375rem) no-repeat; }
  .was-validated .custom-select:invalid:focus, .custom-select.is-invalid:focus {
    border-color: #dc3545;
    box-shadow: 0 0 0 0.2rem rgba(220, 53, 69, 0.25); }

.was-validated .form-check-input:invalid ~ .form-check-label, .form-check-input.is-invalid ~ .form-check-label {
  color: #dc3545; }

.was-validated .form-check-input:invalid ~ .invalid-feedback,
.was-validated .form-check-input:invalid ~ .invalid-tooltip, .form-check-input.is-invalid ~ .invalid-feedback,
.form-check-input.is-invalid ~ .invalid-tooltip {
  display: block; }

.was-validated .custom-control-input:invalid ~ .custom-control-label, .custom-control-input.is-invalid ~ .custom-control-label {
  color: #dc3545; }
  .was-validated .custom-control-input:invalid ~ .custom-control-label::before, .custom-control-input.is-invalid ~ .custom-control-label::before {
    border-color: #dc3545; }

.was-validated .custom-control-input:invalid:checked ~ .custom-control-label::before, .custom-control-input.is-invalid:checked ~ .custom-control-label::before {
  border-color: #e4606d;
  background-color: #e4606d; }

.was-validated .custom-control-input:invalid:focus ~ .custom-control-label::before, .custom-control-input.is-invalid:focus ~ .custom-control-label::before {
  box-shadow: 0 0 0 0.2rem rgba(220, 53, 69, 0.25); }

.was-validated .custom-control-input:invalid:focus:not(:checked) ~ .custom-control-label::before, .custom-control-input.is-invalid:focus:not(:checked) ~ .custom-control-label::before {
  border-color: #dc3545; }

.was-validated .custom-file-input:invalid ~ .custom-file-label, .custom-file-input.is-invalid ~ .custom-file-label {
  border-color: #dc3545; }

.was-validated .custom-file-input:invalid:focus ~ .custom-file-label, .custom-file-input.is-invalid:focus ~ .custom-file-label {
  border-color: #dc3545;
  box-shadow: 0 0 0 0.2rem rgba(220, 53, 69, 0.25); }

.form-inline {
  display: flex;
  flex-flow: row wrap;
  align-items: center; }
  .form-inline .form-check {
    width: 100%; }
  @media (min-width: 576px) {
    .form-inline label {
      display: flex;
      align-items: center;
      justify-content: center;
      margin-bottom: 0; }
    .form-inline .form-group {
      display: flex;
      flex: 0 0 auto;
      flex-flow: row wrap;
      align-items: center;
      margin-bottom: 0; }
    .form-inline .form-control {
      display: inline-block;
      width: auto;
      vertical-align: middle; }
    .form-inline .form-control-plaintext {
      display: inline-block; }
    .form-inline .input-group,
    .form-inline .custom-select {
      width: auto; }
    .form-inline .form-check {
      display: flex;
      align-items: center;
      justify-content: center;
      width: auto;
      padding-left: 0; }
    .form-inline .form-check-input {
      position: relative;
      flex-shrink: 0;
      margin-top: 0;
      margin-right: 0.25rem;
      margin-left: 0; }
    .form-inline .custom-control {
      align-items: center;
      justify-content: center; }
    .form-inline .custom-control-label {
      margin-bottom: 0; } }

.btn {
  display: inline-block;
  font-weight: 400;
  color: #212529;
  text-align: center;
  text-decoration: none;
  vertical-align: middle;
  user-select: none;
  background-color: transparent;
  border: 1px solid transparent;
  padding: 0.375rem 0.75rem;
  font-size: 1.6rem;
  line-height: 1.8;
  border-radius: 0.25rem;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out; }
  @media (prefers-reduced-motion: reduce) {
    .btn {
      transition: none; } }
  .btn:hover {
    color: #212529;
    text-decoration: none; }
  .btn:focus, .btn.focus {
    outline: 0;
    box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, 0.25); }
  .btn.disabled, .btn:disabled {
    opacity: 0.65; }
  .btn:not(:disabled):not(.disabled) {
    cursor: pointer; }

a.btn.disabled,
fieldset:disabled a.btn {
  pointer-events: none; }

.btn-primary {
  color: #fff;
  background-color: #007bff;
  border-color: #007bff; }
  .btn-primary:hover {
    color: #fff;
    background-color: #0069d9;
    border-color: #0062cc; }
  .btn-primary:focus, .btn-primary.focus {
    color: #fff;
    background-color: #0069d9;
    border-color: #0062cc;
    box-shadow: 0 0 0 0.2rem rgba(38, 143, 255, 0.5); }
  .btn-primary.disabled, .btn-primary:disabled {
    color: #fff;
    background-color: #007bff;
    border-color: #007bff; }
  .btn-primary:not(:disabled):not(.disabled):active, .btn-primary:not(:disabled):not(.disabled).active,
  .show > .btn-primary.dropdown-toggle {
    color: #fff;
    background-color: #0062cc;
    border-color: #005cbf; }
    .btn-primary:not(:disabled):not(.disabled):active:focus, .btn-primary:not(:disabled):not(.disabled).active:focus,
    .show > .btn-primary.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(38, 143, 255, 0.5); }

.btn-secondary {
  color: #fff;
  background-color: #6c757d;
  border-color: #6c757d; }
  .btn-secondary:hover {
    color: #fff;
    background-color: #5a6268;
    border-color: #545b62; }
  .btn-secondary:focus, .btn-secondary.focus {
    color: #fff;
    background-color: #5a6268;
    border-color: #545b62;
    box-shadow: 0 0 0 0.2rem rgba(130, 138, 145, 0.5); }
  .btn-secondary.disabled, .btn-secondary:disabled {
    color: #fff;
    background-color: #6c757d;
    border-color: #6c757d; }
  .btn-secondary:not(:disabled):not(.disabled):active, .btn-secondary:not(:disabled):not(.disabled).active,
  .show > .btn-secondary.dropdown-toggle {
    color: #fff;
    background-color: #545b62;
    border-color: #4e555b; }
    .btn-secondary:not(:disabled):not(.disabled):active:focus, .btn-secondary:not(:disabled):not(.disabled).active:focus,
    .show > .btn-secondary.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(130, 138, 145, 0.5); }

.btn-success {
  color: #fff;
  background-color: #28a745;
  border-color: #28a745; }
  .btn-success:hover {
    color: #fff;
    background-color: #218838;
    border-color: #1e7e34; }
  .btn-success:focus, .btn-success.focus {
    color: #fff;
    background-color: #218838;
    border-color: #1e7e34;
    box-shadow: 0 0 0 0.2rem rgba(72, 180, 97, 0.5); }
  .btn-success.disabled, .btn-success:disabled {
    color: #fff;
    background-color: #28a745;
    border-color: #28a745; }
  .btn-success:not(:disabled):not(.disabled):active, .btn-success:not(:disabled):not(.disabled).active,
  .show > .btn-success.dropdown-toggle {
    color: #fff;
    background-color: #1e7e34;
    border-color: #1c7430; }
    .btn-success:not(:disabled):not(.disabled):active:focus, .btn-success:not(:disabled):not(.disabled).active:focus,
    .show > .btn-success.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(72, 180, 97, 0.5); }

.btn-info {
  color: #fff;
  background-color: #17a2b8;
  border-color: #17a2b8; }
  .btn-info:hover {
    color: #fff;
    background-color: #138496;
    border-color: #117a8b; }
  .btn-info:focus, .btn-info.focus {
    color: #fff;
    background-color: #138496;
    border-color: #117a8b;
    box-shadow: 0 0 0 0.2rem rgba(58, 176, 195, 0.5); }
  .btn-info.disabled, .btn-info:disabled {
    color: #fff;
    background-color: #17a2b8;
    border-color: #17a2b8; }
  .btn-info:not(:disabled):not(.disabled):active, .btn-info:not(:disabled):not(.disabled).active,
  .show > .btn-info.dropdown-toggle {
    color: #fff;
    background-color: #117a8b;
    border-color: #10707f; }
    .btn-info:not(:disabled):not(.disabled):active:focus, .btn-info:not(:disabled):not(.disabled).active:focus,
    .show > .btn-info.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(58, 176, 195, 0.5); }

.btn-warning {
  color: #212529;
  background-color: #ffc107;
  border-color: #ffc107; }
  .btn-warning:hover {
    color: #212529;
    background-color: #e0a800;
    border-color: #d39e00; }
  .btn-warning:focus, .btn-warning.focus {
    color: #212529;
    background-color: #e0a800;
    border-color: #d39e00;
    box-shadow: 0 0 0 0.2rem rgba(222, 170, 12, 0.5); }
  .btn-warning.disabled, .btn-warning:disabled {
    color: #212529;
    background-color: #ffc107;
    border-color: #ffc107; }
  .btn-warning:not(:disabled):not(.disabled):active, .btn-warning:not(:disabled):not(.disabled).active,
  .show > .btn-warning.dropdown-toggle {
    color: #212529;
    background-color: #d39e00;
    border-color: #c69500; }
    .btn-warning:not(:disabled):not(.disabled):active:focus, .btn-warning:not(:disabled):not(.disabled).active:focus,
    .show > .btn-warning.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(222, 170, 12, 0.5); }

.btn-danger {
  color: #fff;
  background-color: #dc3545;
  border-color: #dc3545; }
  .btn-danger:hover {
    color: #fff;
    background-color: #c82333;
    border-color: #bd2130; }
  .btn-danger:focus, .btn-danger.focus {
    color: #fff;
    background-color: #c82333;
    border-color: #bd2130;
    box-shadow: 0 0 0 0.2rem rgba(225, 83, 97, 0.5); }
  .btn-danger.disabled, .btn-danger:disabled {
    color: #fff;
    background-color: #dc3545;
    border-color: #dc3545; }
  .btn-danger:not(:disabled):not(.disabled):active, .btn-danger:not(:disabled):not(.disabled).active,
  .show > .btn-danger.dropdown-toggle {
    color: #fff;
    background-color: #bd2130;
    border-color: #b21f2d; }
    .btn-danger:not(:disabled):not(.disabled):active:focus, .btn-danger:not(:disabled):not(.disabled).active:focus,
    .show > .btn-danger.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(225, 83, 97, 0.5); }

.btn-light {
  color: #212529;
  background-color: #f8f9fa;
  border-color: #f8f9fa; }
  .btn-light:hover {
    color: #212529;
    background-color: #e2e6ea;
    border-color: #dae0e5; }
  .btn-light:focus, .btn-light.focus {
    color: #212529;
    background-color: #e2e6ea;
    border-color: #dae0e5;
    box-shadow: 0 0 0 0.2rem rgba(216, 217, 219, 0.5); }
  .btn-light.disabled, .btn-light:disabled {
    color: #212529;
    background-color: #f8f9fa;
    border-color: #f8f9fa; }
  .btn-light:not(:disabled):not(.disabled):active, .btn-light:not(:disabled):not(.disabled).active,
  .show > .btn-light.dropdown-toggle {
    color: #212529;
    background-color: #dae0e5;
    border-color: #d3d9df; }
    .btn-light:not(:disabled):not(.disabled):active:focus, .btn-light:not(:disabled):not(.disabled).active:focus,
    .show > .btn-light.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(216, 217, 219, 0.5); }

.btn-dark {
  color: #fff;
  background-color: #343a40;
  border-color: #343a40; }
  .btn-dark:hover {
    color: #fff;
    background-color: #23272b;
    border-color: #1d2124; }
  .btn-dark:focus, .btn-dark.focus {
    color: #fff;
    background-color: #23272b;
    border-color: #1d2124;
    box-shadow: 0 0 0 0.2rem rgba(82, 88, 93, 0.5); }
  .btn-dark.disabled, .btn-dark:disabled {
    color: #fff;
    background-color: #343a40;
    border-color: #343a40; }
  .btn-dark:not(:disabled):not(.disabled):active, .btn-dark:not(:disabled):not(.disabled).active,
  .show > .btn-dark.dropdown-toggle {
    color: #fff;
    background-color: #1d2124;
    border-color: #171a1d; }
    .btn-dark:not(:disabled):not(.disabled):active:focus, .btn-dark:not(:disabled):not(.disabled).active:focus,
    .show > .btn-dark.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(82, 88, 93, 0.5); }

.btn-outline-primary {
  color: #007bff;
  border-color: #007bff; }
  .btn-outline-primary:hover {
    color: #fff;
    background-color: #007bff;
    border-color: #007bff; }
  .btn-outline-primary:focus, .btn-outline-primary.focus {
    box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, 0.5); }
  .btn-outline-primary.disabled, .btn-outline-primary:disabled {
    color: #007bff;
    background-color: transparent; }
  .btn-outline-primary:not(:disabled):not(.disabled):active, .btn-outline-primary:not(:disabled):not(.disabled).active,
  .show > .btn-outline-primary.dropdown-toggle {
    color: #fff;
    background-color: #007bff;
    border-color: #007bff; }
    .btn-outline-primary:not(:disabled):not(.disabled):active:focus, .btn-outline-primary:not(:disabled):not(.disabled).active:focus,
    .show > .btn-outline-primary.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, 0.5); }

.btn-outline-secondary {
  color: #6c757d;
  border-color: #6c757d; }
  .btn-outline-secondary:hover {
    color: #fff;
    background-color: #6c757d;
    border-color: #6c757d; }
  .btn-outline-secondary:focus, .btn-outline-secondary.focus {
    box-shadow: 0 0 0 0.2rem rgba(108, 117, 125, 0.5); }
  .btn-outline-secondary.disabled, .btn-outline-secondary:disabled {
    color: #6c757d;
    background-color: transparent; }
  .btn-outline-secondary:not(:disabled):not(.disabled):active, .btn-outline-secondary:not(:disabled):not(.disabled).active,
  .show > .btn-outline-secondary.dropdown-toggle {
    color: #fff;
    background-color: #6c757d;
    border-color: #6c757d; }
    .btn-outline-secondary:not(:disabled):not(.disabled):active:focus, .btn-outline-secondary:not(:disabled):not(.disabled).active:focus,
    .show > .btn-outline-secondary.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(108, 117, 125, 0.5); }

.btn-outline-success {
  color: #28a745;
  border-color: #28a745; }
  .btn-outline-success:hover {
    color: #fff;
    background-color: #28a745;
    border-color: #28a745; }
  .btn-outline-success:focus, .btn-outline-success.focus {
    box-shadow: 0 0 0 0.2rem rgba(40, 167, 69, 0.5); }
  .btn-outline-success.disabled, .btn-outline-success:disabled {
    color: #28a745;
    background-color: transparent; }
  .btn-outline-success:not(:disabled):not(.disabled):active, .btn-outline-success:not(:disabled):not(.disabled).active,
  .show > .btn-outline-success.dropdown-toggle {
    color: #fff;
    background-color: #28a745;
    border-color: #28a745; }
    .btn-outline-success:not(:disabled):not(.disabled):active:focus, .btn-outline-success:not(:disabled):not(.disabled).active:focus,
    .show > .btn-outline-success.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(40, 167, 69, 0.5); }

.btn-outline-info {
  color: #17a2b8;
  border-color: #17a2b8; }
  .btn-outline-info:hover {
    color: #fff;
    background-color: #17a2b8;
    border-color: #17a2b8; }
  .btn-outline-info:focus, .btn-outline-info.focus {
    box-shadow: 0 0 0 0.2rem rgba(23, 162, 184, 0.5); }
  .btn-outline-info.disabled, .btn-outline-info:disabled {
    color: #17a2b8;
    background-color: transparent; }
  .btn-outline-info:not(:disabled):not(.disabled):active, .btn-outline-info:not(:disabled):not(.disabled).active,
  .show > .btn-outline-info.dropdown-toggle {
    color: #fff;
    background-color: #17a2b8;
    border-color: #17a2b8; }
    .btn-outline-info:not(:disabled):not(.disabled):active:focus, .btn-outline-info:not(:disabled):not(.disabled).active:focus,
    .show > .btn-outline-info.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(23, 162, 184, 0.5); }

.btn-outline-warning {
  color: #ffc107;
  border-color: #ffc107; }
  .btn-outline-warning:hover {
    color: #212529;
    background-color: #ffc107;
    border-color: #ffc107; }
  .btn-outline-warning:focus, .btn-outline-warning.focus {
    box-shadow: 0 0 0 0.2rem rgba(255, 193, 7, 0.5); }
  .btn-outline-warning.disabled, .btn-outline-warning:disabled {
    color: #ffc107;
    background-color: transparent; }
  .btn-outline-warning:not(:disabled):not(.disabled):active, .btn-outline-warning:not(:disabled):not(.disabled).active,
  .show > .btn-outline-warning.dropdown-toggle {
    color: #212529;
    background-color: #ffc107;
    border-color: #ffc107; }
    .btn-outline-warning:not(:disabled):not(.disabled):active:focus, .btn-outline-warning:not(:disabled):not(.disabled).active:focus,
    .show > .btn-outline-warning.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(255, 193, 7, 0.5); }

.btn-outline-danger {
  color: #dc3545;
  border-color: #dc3545; }
  .btn-outline-danger:hover {
    color: #fff;
    background-color: #dc3545;
    border-color: #dc3545; }
  .btn-outline-danger:focus, .btn-outline-danger.focus {
    box-shadow: 0 0 0 0.2rem rgba(220, 53, 69, 0.5); }
  .btn-outline-danger.disabled, .btn-outline-danger:disabled {
    color: #dc3545;
    background-color: transparent; }
  .btn-outline-danger:not(:disabled):not(.disabled):active, .btn-outline-danger:not(:disabled):not(.disabled).active,
  .show > .btn-outline-danger.dropdown-toggle {
    color: #fff;
    background-color: #dc3545;
    border-color: #dc3545; }
    .btn-outline-danger:not(:disabled):not(.disabled):active:focus, .btn-outline-danger:not(:disabled):not(.disabled).active:focus,
    .show > .btn-outline-danger.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(220, 53, 69, 0.5); }

.btn-outline-light {
  color: #f8f9fa;
  border-color: #f8f9fa; }
  .btn-outline-light:hover {
    color: #212529;
    background-color: #f8f9fa;
    border-color: #f8f9fa; }
  .btn-outline-light:focus, .btn-outline-light.focus {
    box-shadow: 0 0 0 0.2rem rgba(248, 249, 250, 0.5); }
  .btn-outline-light.disabled, .btn-outline-light:disabled {
    color: #f8f9fa;
    background-color: transparent; }
  .btn-outline-light:not(:disabled):not(.disabled):active, .btn-outline-light:not(:disabled):not(.disabled).active,
  .show > .btn-outline-light.dropdown-toggle {
    color: #212529;
    background-color: #f8f9fa;
    border-color: #f8f9fa; }
    .btn-outline-light:not(:disabled):not(.disabled):active:focus, .btn-outline-light:not(:disabled):not(.disabled).active:focus,
    .show > .btn-outline-light.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(248, 249, 250, 0.5); }

.btn-outline-dark {
  color: #343a40;
  border-color: #343a40; }
  .btn-outline-dark:hover {
    color: #fff;
    background-color: #343a40;
    border-color: #343a40; }
  .btn-outline-dark:focus, .btn-outline-dark.focus {
    box-shadow: 0 0 0 0.2rem rgba(52, 58, 64, 0.5); }
  .btn-outline-dark.disabled, .btn-outline-dark:disabled {
    color: #343a40;
    background-color: transparent; }
  .btn-outline-dark:not(:disabled):not(.disabled):active, .btn-outline-dark:not(:disabled):not(.disabled).active,
  .show > .btn-outline-dark.dropdown-toggle {
    color: #fff;
    background-color: #343a40;
    border-color: #343a40; }
    .btn-outline-dark:not(:disabled):not(.disabled):active:focus, .btn-outline-dark:not(:disabled):not(.disabled).active:focus,
    .show > .btn-outline-dark.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(52, 58, 64, 0.5); }

.btn-link {
  font-weight: 400;
  color: #333;
  text-decoration: underline; }
  .btn-link:hover {
    color: #0d0d0d;
    text-decoration: underline; }
  .btn-link:focus, .btn-link.focus {
    text-decoration: underline; }
  .btn-link:disabled, .btn-link.disabled {
    color: #6c757d;
    pointer-events: none; }

.btn-lg, .btn-group-lg > .btn {
  padding: 0.5rem 1rem;
  font-size: 2rem;
  line-height: 1.5;
  border-radius: 0.3rem; }

.btn-sm, .btn-group-sm > .btn {
  padding: 0.25rem 0.5rem;
  font-size: 1.4rem;
  line-height: 1.5;
  border-radius: 0.2rem; }

.btn-block {
  display: block;
  width: 100%; }
  .btn-block + .btn-block {
    margin-top: 0.5rem; }

input[type="submit"].btn-block,
input[type="reset"].btn-block,
input[type="button"].btn-block {
  width: 100%; }

.fade {
  transition: opacity 0.15s linear; }
  @media (prefers-reduced-motion: reduce) {
    .fade {
      transition: none; } }
  .fade:not(.show) {
    opacity: 0; }

.collapse:not(.show) {
  display: none; }

.collapsing {
  position: relative;
  height: 0;
  overflow: hidden;
  transition: height 0.35s ease; }
  @media (prefers-reduced-motion: reduce) {
    .collapsing {
      transition: none; } }
  .collapsing.width {
    width: 0;
    height: auto;
    transition: width 0.35s ease; }
    @media (prefers-reduced-motion: reduce) {
      .collapsing.width {
        transition: none; } }

.dropup,
.dropright,
.dropdown,
.dropleft {
  position: relative; }

.dropdown-toggle {
  white-space: nowrap; }
  .dropdown-toggle::after {
    display: inline-block;
    margin-left: 0.255em;
    vertical-align: 0.255em;
    content: "";
    border-top: 0.3em solid;
    border-right: 0.3em solid transparent;
    border-bottom: 0;
    border-left: 0.3em solid transparent; }
  .dropdown-toggle:empty::after {
    margin-left: 0; }

.dropdown-menu {
  position: absolute;
  top: 100%;
  left: 0;
  z-index: 1000;
  display: none;
  float: left;
  min-width: 10rem;
  padding: 0.5rem 0;
  margin: 0.125rem 0 0;
  font-size: 1.6rem;
  color: #212529;
  text-align: left;
  list-style: none;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid rgba(0, 0, 0, 0.15);
  border-radius: 0.25rem; }

.dropdown-menu-left {
  right: auto;
  left: 0; }

.dropdown-menu-right {
  right: 0;
  left: auto; }

@media (min-width: 576px) {
  .dropdown-menu-sm-left {
    right: auto;
    left: 0; }
  .dropdown-menu-sm-right {
    right: 0;
    left: auto; } }

@media (min-width: 768px) {
  .dropdown-menu-md-left {
    right: auto;
    left: 0; }
  .dropdown-menu-md-right {
    right: 0;
    left: auto; } }

@media (min-width: 1080px) {
  .dropdown-menu-lg-left {
    right: auto;
    left: 0; }
  .dropdown-menu-lg-right {
    right: 0;
    left: auto; } }

@media (min-width: 1280px) {
  .dropdown-menu-llg-left {
    right: auto;
    left: 0; }
  .dropdown-menu-llg-right {
    right: 0;
    left: auto; } }

@media (min-width: 1400px) {
  .dropdown-menu-xl-left {
    right: auto;
    left: 0; }
  .dropdown-menu-xl-right {
    right: 0;
    left: auto; } }

@media (min-width: 1980px) {
  .dropdown-menu-xxl-left {
    right: auto;
    left: 0; }
  .dropdown-menu-xxl-right {
    right: 0;
    left: auto; } }

.dropup .dropdown-menu {
  top: auto;
  bottom: 100%;
  margin-top: 0;
  margin-bottom: 0.125rem; }

.dropup .dropdown-toggle::after {
  display: inline-block;
  margin-left: 0.255em;
  vertical-align: 0.255em;
  content: "";
  border-top: 0;
  border-right: 0.3em solid transparent;
  border-bottom: 0.3em solid;
  border-left: 0.3em solid transparent; }

.dropup .dropdown-toggle:empty::after {
  margin-left: 0; }

.dropright .dropdown-menu {
  top: 0;
  right: auto;
  left: 100%;
  margin-top: 0;
  margin-left: 0.125rem; }

.dropright .dropdown-toggle::after {
  display: inline-block;
  margin-left: 0.255em;
  vertical-align: 0.255em;
  content: "";
  border-top: 0.3em solid transparent;
  border-right: 0;
  border-bottom: 0.3em solid transparent;
  border-left: 0.3em solid; }

.dropright .dropdown-toggle:empty::after {
  margin-left: 0; }

.dropright .dropdown-toggle::after {
  vertical-align: 0; }

.dropleft .dropdown-menu {
  top: 0;
  right: 100%;
  left: auto;
  margin-top: 0;
  margin-right: 0.125rem; }

.dropleft .dropdown-toggle::after {
  display: inline-block;
  margin-left: 0.255em;
  vertical-align: 0.255em;
  content: ""; }

.dropleft .dropdown-toggle::after {
  display: none; }

.dropleft .dropdown-toggle::before {
  display: inline-block;
  margin-right: 0.255em;
  vertical-align: 0.255em;
  content: "";
  border-top: 0.3em solid transparent;
  border-right: 0.3em solid;
  border-bottom: 0.3em solid transparent; }

.dropleft .dropdown-toggle:empty::after {
  margin-left: 0; }

.dropleft .dropdown-toggle::before {
  vertical-align: 0; }

.dropdown-menu[x-placement^="top"], .dropdown-menu[x-placement^="right"], .dropdown-menu[x-placement^="bottom"], .dropdown-menu[x-placement^="left"] {
  right: auto;
  bottom: auto; }

.dropdown-divider {
  height: 0;
  margin: 0.5rem 0;
  overflow: hidden;
  border-top: 1px solid #e9ecef; }

.dropdown-item {
  display: block;
  width: 100%;
  padding: 0.25rem 1.5rem;
  clear: both;
  font-weight: 400;
  color: #212529;
  text-align: inherit;
  text-decoration: none;
  white-space: nowrap;
  background-color: transparent;
  border: 0; }
  .dropdown-item:hover, .dropdown-item:focus {
    color: #16181b;
    text-decoration: none;
    background-color: #e9ecef; }
  .dropdown-item.active, .dropdown-item:active {
    color: #fff;
    text-decoration: none;
    background-color: #007bff; }
  .dropdown-item.disabled, .dropdown-item:disabled {
    color: #adb5bd;
    pointer-events: none;
    background-color: transparent; }

.dropdown-menu.show {
  display: block; }

.dropdown-header {
  display: block;
  padding: 0.5rem 1.5rem;
  margin-bottom: 0;
  font-size: 1.4rem;
  color: #6c757d;
  white-space: nowrap; }

.dropdown-item-text {
  display: block;
  padding: 0.25rem 1.5rem;
  color: #212529; }

.btn-group,
.btn-group-vertical {
  position: relative;
  display: inline-flex;
  vertical-align: middle; }
  .btn-group > .btn,
  .btn-group-vertical > .btn {
    position: relative;
    flex: 1 1 auto; }
    .btn-group > .btn:hover,
    .btn-group-vertical > .btn:hover {
      z-index: 1; }
    .btn-group > .btn:focus, .btn-group > .btn:active, .btn-group > .btn.active,
    .btn-group-vertical > .btn:focus,
    .btn-group-vertical > .btn:active,
    .btn-group-vertical > .btn.active {
      z-index: 1; }

.btn-toolbar {
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start; }
  .btn-toolbar .input-group {
    width: auto; }

.btn-group > .btn:not(:first-child),
.btn-group > .btn-group:not(:first-child) {
  margin-left: -1px; }

.btn-group > .btn:not(:last-child):not(.dropdown-toggle),
.btn-group > .btn-group:not(:last-child) > .btn {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0; }

.btn-group > .btn:not(:first-child),
.btn-group > .btn-group:not(:first-child) > .btn {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0; }

.dropdown-toggle-split {
  padding-right: 0.5625rem;
  padding-left: 0.5625rem; }
  .dropdown-toggle-split::after,
  .dropup .dropdown-toggle-split::after,
  .dropright .dropdown-toggle-split::after {
    margin-left: 0; }
  .dropleft .dropdown-toggle-split::before {
    margin-right: 0; }

.btn-sm + .dropdown-toggle-split, .btn-group-sm > .btn + .dropdown-toggle-split {
  padding-right: 0.375rem;
  padding-left: 0.375rem; }

.btn-lg + .dropdown-toggle-split, .btn-group-lg > .btn + .dropdown-toggle-split {
  padding-right: 0.75rem;
  padding-left: 0.75rem; }

.btn-group-vertical {
  flex-direction: column;
  align-items: flex-start;
  justify-content: center; }
  .btn-group-vertical > .btn,
  .btn-group-vertical > .btn-group {
    width: 100%; }
  .btn-group-vertical > .btn:not(:first-child),
  .btn-group-vertical > .btn-group:not(:first-child) {
    margin-top: -1px; }
  .btn-group-vertical > .btn:not(:last-child):not(.dropdown-toggle),
  .btn-group-vertical > .btn-group:not(:last-child) > .btn {
    border-bottom-right-radius: 0;
    border-bottom-left-radius: 0; }
  .btn-group-vertical > .btn:not(:first-child),
  .btn-group-vertical > .btn-group:not(:first-child) > .btn {
    border-top-left-radius: 0;
    border-top-right-radius: 0; }

.btn-group-toggle > .btn,
.btn-group-toggle > .btn-group > .btn {
  margin-bottom: 0; }
  .btn-group-toggle > .btn input[type="radio"],
  .btn-group-toggle > .btn input[type="checkbox"],
  .btn-group-toggle > .btn-group > .btn input[type="radio"],
  .btn-group-toggle > .btn-group > .btn input[type="checkbox"] {
    position: absolute;
    clip: rect(0, 0, 0, 0);
    pointer-events: none; }

.input-group {
  position: relative;
  display: flex;
  flex-wrap: wrap;
  align-items: stretch;
  width: 100%; }
  .input-group > .form-control,
  .input-group > .form-control-plaintext,
  .input-group > .custom-select,
  .input-group > .custom-file {
    position: relative;
    flex: 1 1 auto;
    width: 1%;
    min-width: 0;
    margin-bottom: 0; }
    .input-group > .form-control + .form-control,
    .input-group > .form-control + .custom-select,
    .input-group > .form-control + .custom-file,
    .input-group > .form-control-plaintext + .form-control,
    .input-group > .form-control-plaintext + .custom-select,
    .input-group > .form-control-plaintext + .custom-file,
    .input-group > .custom-select + .form-control,
    .input-group > .custom-select + .custom-select,
    .input-group > .custom-select + .custom-file,
    .input-group > .custom-file + .form-control,
    .input-group > .custom-file + .custom-select,
    .input-group > .custom-file + .custom-file {
      margin-left: -1px; }
  .input-group > .form-control:focus,
  .input-group > .custom-select:focus,
  .input-group > .custom-file .custom-file-input:focus ~ .custom-file-label {
    z-index: 3; }
  .input-group > .custom-file .custom-file-input:focus {
    z-index: 4; }
  .input-group > .form-control:not(:first-child),
  .input-group > .custom-select:not(:first-child) {
    border-top-left-radius: 0;
    border-bottom-left-radius: 0; }
  .input-group > .custom-file {
    display: flex;
    align-items: center; }
    .input-group > .custom-file:not(:last-child) .custom-file-label,
    .input-group > .custom-file:not(:last-child) .custom-file-label::after {
      border-top-right-radius: 0;
      border-bottom-right-radius: 0; }
    .input-group > .custom-file:not(:first-child) .custom-file-label {
      border-top-left-radius: 0;
      border-bottom-left-radius: 0; }
  .input-group:not(.has-validation) > .form-control:not(:last-child),
  .input-group:not(.has-validation) > .custom-select:not(:last-child),
  .input-group:not(.has-validation) > .custom-file:not(:last-child) .custom-file-label,
  .input-group:not(.has-validation) > .custom-file:not(:last-child) .custom-file-label::after {
    border-top-right-radius: 0;
    border-bottom-right-radius: 0; }
  .input-group.has-validation > .form-control:nth-last-child(n + 3),
  .input-group.has-validation > .custom-select:nth-last-child(n + 3),
  .input-group.has-validation > .custom-file:nth-last-child(n + 3) .custom-file-label,
  .input-group.has-validation > .custom-file:nth-last-child(n + 3) .custom-file-label::after {
    border-top-right-radius: 0;
    border-bottom-right-radius: 0; }

.input-group-prepend,
.input-group-append {
  display: flex; }
  .input-group-prepend .btn,
  .input-group-append .btn {
    position: relative;
    z-index: 2; }
    .input-group-prepend .btn:focus,
    .input-group-append .btn:focus {
      z-index: 3; }
  .input-group-prepend .btn + .btn,
  .input-group-prepend .btn + .input-group-text,
  .input-group-prepend .input-group-text + .input-group-text,
  .input-group-prepend .input-group-text + .btn,
  .input-group-append .btn + .btn,
  .input-group-append .btn + .input-group-text,
  .input-group-append .input-group-text + .input-group-text,
  .input-group-append .input-group-text + .btn {
    margin-left: -1px; }

.input-group-prepend {
  margin-right: -1px; }

.input-group-append {
  margin-left: -1px; }

.input-group-text {
  display: flex;
  align-items: center;
  padding: 0.375rem 0.75rem;
  margin-bottom: 0;
  font-size: 1.6rem;
  font-weight: 400;
  line-height: 1.8;
  color: #495057;
  text-align: center;
  white-space: nowrap;
  background-color: #e9ecef;
  border: 1px solid #ced4da;
  border-radius: 0.25rem; }
  .input-group-text input[type="radio"],
  .input-group-text input[type="checkbox"] {
    margin-top: 0; }

.input-group-lg > .form-control:not(textarea),
.input-group-lg > .custom-select {
  height: calc(1.5em + 1rem + 2px); }

.input-group-lg > .form-control,
.input-group-lg > .custom-select,
.input-group-lg > .input-group-prepend > .input-group-text,
.input-group-lg > .input-group-append > .input-group-text,
.input-group-lg > .input-group-prepend > .btn,
.input-group-lg > .input-group-append > .btn {
  padding: 0.5rem 1rem;
  font-size: 2rem;
  line-height: 1.5;
  border-radius: 0.3rem; }

.input-group-sm > .form-control:not(textarea),
.input-group-sm > .custom-select {
  height: calc(1.5em + 0.5rem + 2px); }

.input-group-sm > .form-control,
.input-group-sm > .custom-select,
.input-group-sm > .input-group-prepend > .input-group-text,
.input-group-sm > .input-group-append > .input-group-text,
.input-group-sm > .input-group-prepend > .btn,
.input-group-sm > .input-group-append > .btn {
  padding: 0.25rem 0.5rem;
  font-size: 1.4rem;
  line-height: 1.5;
  border-radius: 0.2rem; }

.input-group-lg > .custom-select,
.input-group-sm > .custom-select {
  padding-right: 1.75rem; }

.input-group > .input-group-prepend > .btn,
.input-group > .input-group-prepend > .input-group-text,
.input-group:not(.has-validation) > .input-group-append:not(:last-child) > .btn,
.input-group:not(.has-validation) > .input-group-append:not(:last-child) > .input-group-text,
.input-group.has-validation > .input-group-append:nth-last-child(n + 3) > .btn,
.input-group.has-validation > .input-group-append:nth-last-child(n + 3) > .input-group-text,
.input-group > .input-group-append:last-child > .btn:not(:last-child):not(.dropdown-toggle),
.input-group > .input-group-append:last-child > .input-group-text:not(:last-child) {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0; }

.input-group > .input-group-append > .btn,
.input-group > .input-group-append > .input-group-text,
.input-group > .input-group-prepend:not(:first-child) > .btn,
.input-group > .input-group-prepend:not(:first-child) > .input-group-text,
.input-group > .input-group-prepend:first-child > .btn:not(:first-child),
.input-group > .input-group-prepend:first-child > .input-group-text:not(:first-child) {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0; }

.custom-control {
  position: relative;
  z-index: 1;
  display: block;
  min-height: 2.88rem;
  padding-left: 1.5rem;
  print-color-adjust: exact; }

.custom-control-inline {
  display: inline-flex;
  margin-right: 1rem; }

.custom-control-input {
  position: absolute;
  left: 0;
  z-index: -1;
  width: 1rem;
  height: 1.94rem;
  opacity: 0; }
  .custom-control-input:checked ~ .custom-control-label::before {
    color: #fff;
    border-color: #007bff;
    background-color: #007bff; }
  .custom-control-input:focus ~ .custom-control-label::before {
    box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, 0.25); }
  .custom-control-input:focus:not(:checked) ~ .custom-control-label::before {
    border-color: #80bdff; }
  .custom-control-input:not(:disabled):active ~ .custom-control-label::before {
    color: #fff;
    background-color: #b3d7ff;
    border-color: #b3d7ff; }
  .custom-control-input[disabled] ~ .custom-control-label, .custom-control-input:disabled ~ .custom-control-label {
    color: #6c757d; }
    .custom-control-input[disabled] ~ .custom-control-label::before, .custom-control-input:disabled ~ .custom-control-label::before {
      background-color: #e9ecef; }

.custom-control-label {
  position: relative;
  margin-bottom: 0;
  vertical-align: top; }
  .custom-control-label::before {
    position: absolute;
    top: 0.94rem;
    left: -1.5rem;
    display: block;
    width: 1rem;
    height: 1rem;
    pointer-events: none;
    content: "";
    background-color: #fff;
    border: 1px solid #adb5bd; }
  .custom-control-label::after {
    position: absolute;
    top: 0.94rem;
    left: -1.5rem;
    display: block;
    width: 1rem;
    height: 1rem;
    content: "";
    background: 50% / 50% 50% no-repeat; }

.custom-checkbox .custom-control-label::before {
  border-radius: 0.25rem; }

.custom-checkbox .custom-control-input:checked ~ .custom-control-label::after {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='8' height='8' viewBox='0 0 8 8'%3e%3cpath fill='%23fff' d='M6.564.75l-3.59 3.612-1.538-1.55L0 4.26l2.974 2.99L8 2.193z'/%3e%3c/svg%3e"); }

.custom-checkbox .custom-control-input:indeterminate ~ .custom-control-label::before {
  border-color: #007bff;
  background-color: #007bff; }

.custom-checkbox .custom-control-input:indeterminate ~ .custom-control-label::after {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='4' height='4' viewBox='0 0 4 4'%3e%3cpath stroke='%23fff' d='M0 2h4'/%3e%3c/svg%3e"); }

.custom-checkbox .custom-control-input:disabled:checked ~ .custom-control-label::before {
  background-color: rgba(0, 123, 255, 0.5); }

.custom-checkbox .custom-control-input:disabled:indeterminate ~ .custom-control-label::before {
  background-color: rgba(0, 123, 255, 0.5); }

.custom-radio .custom-control-label::before {
  border-radius: 50%; }

.custom-radio .custom-control-input:checked ~ .custom-control-label::after {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='12' height='12' viewBox='-4 -4 8 8'%3e%3ccircle r='3' fill='%23fff'/%3e%3c/svg%3e"); }

.custom-radio .custom-control-input:disabled:checked ~ .custom-control-label::before {
  background-color: rgba(0, 123, 255, 0.5); }

.custom-switch {
  padding-left: 2.25rem; }
  .custom-switch .custom-control-label::before {
    left: -2.25rem;
    width: 1.75rem;
    pointer-events: all;
    border-radius: 0.5rem; }
  .custom-switch .custom-control-label::after {
    top: calc(0.94rem + 2px);
    left: calc(-2.25rem + 2px);
    width: calc(1rem - 4px);
    height: calc(1rem - 4px);
    background-color: #adb5bd;
    border-radius: 0.5rem;
    transition: transform 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out; }
    @media (prefers-reduced-motion: reduce) {
      .custom-switch .custom-control-label::after {
        transition: none; } }
  .custom-switch .custom-control-input:checked ~ .custom-control-label::after {
    background-color: #fff;
    transform: translateX(0.75rem); }
  .custom-switch .custom-control-input:disabled:checked ~ .custom-control-label::before {
    background-color: rgba(0, 123, 255, 0.5); }

.custom-select {
  display: inline-block;
  width: 100%;
  height: calc(1.8em + 0.75rem + 2px);
  padding: 0.375rem 1.75rem 0.375rem 0.75rem;
  font-size: 1.6rem;
  font-weight: 400;
  line-height: 1.8;
  color: #495057;
  vertical-align: middle;
  background: #fff url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='4' height='5' viewBox='0 0 4 5'%3e%3cpath fill='%23343a40' d='M2 0L0 2h4zm0 5L0 3h4z'/%3e%3c/svg%3e") right 0.75rem center/8px 10px no-repeat;
  border: 1px solid #ced4da;
  border-radius: 0.25rem;
  appearance: none; }
  .custom-select:focus {
    border-color: #80bdff;
    outline: 0;
    box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, 0.25); }
    .custom-select:focus::-ms-value {
      color: #495057;
      background-color: #fff; }
  .custom-select[multiple], .custom-select[size]:not([size="1"]) {
    height: auto;
    padding-right: 0.75rem;
    background-image: none; }
  .custom-select:disabled {
    color: #6c757d;
    background-color: #e9ecef; }
  .custom-select::-ms-expand {
    display: none; }
  .custom-select:-moz-focusring {
    color: transparent;
    text-shadow: 0 0 0 #495057; }

.custom-select-sm {
  height: calc(1.5em + 0.5rem + 2px);
  padding-top: 0.25rem;
  padding-bottom: 0.25rem;
  padding-left: 0.5rem;
  font-size: 1.4rem; }

.custom-select-lg {
  height: calc(1.5em + 1rem + 2px);
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
  padding-left: 1rem;
  font-size: 2rem; }

.custom-file {
  position: relative;
  display: inline-block;
  width: 100%;
  height: calc(1.8em + 0.75rem + 2px);
  margin-bottom: 0; }

.custom-file-input {
  position: relative;
  z-index: 2;
  width: 100%;
  height: calc(1.8em + 0.75rem + 2px);
  margin: 0;
  overflow: hidden;
  opacity: 0; }
  .custom-file-input:focus ~ .custom-file-label {
    border-color: #80bdff;
    box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, 0.25); }
  .custom-file-input[disabled] ~ .custom-file-label,
  .custom-file-input:disabled ~ .custom-file-label {
    background-color: #e9ecef; }
  .custom-file-input:lang(en) ~ .custom-file-label::after {
    content: "Browse"; }
  .custom-file-input ~ .custom-file-label[data-browse]::after {
    content: attr(data-browse); }

.custom-file-label {
  position: absolute;
  top: 0;
  right: 0;
  left: 0;
  z-index: 1;
  height: calc(1.8em + 0.75rem + 2px);
  padding: 0.375rem 0.75rem;
  overflow: hidden;
  font-weight: 400;
  line-height: 1.8;
  color: #495057;
  background-color: #fff;
  border: 1px solid #ced4da;
  border-radius: 0.25rem; }
  .custom-file-label::after {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    z-index: 3;
    display: block;
    height: calc(1.8em + 0.75rem);
    padding: 0.375rem 0.75rem;
    line-height: 1.8;
    color: #495057;
    content: "Browse";
    background-color: #e9ecef;
    border-left: inherit;
    border-radius: 0 0.25rem 0.25rem 0; }

.custom-range {
  width: 100%;
  height: 1.4rem;
  padding: 0;
  background-color: transparent;
  appearance: none; }
  .custom-range:focus {
    outline: 0; }
    .custom-range:focus::-webkit-slider-thumb {
      box-shadow: 0 0 0 1px #fff, 0 0 0 0.2rem rgba(0, 123, 255, 0.25); }
    .custom-range:focus::-moz-range-thumb {
      box-shadow: 0 0 0 1px #fff, 0 0 0 0.2rem rgba(0, 123, 255, 0.25); }
    .custom-range:focus::-ms-thumb {
      box-shadow: 0 0 0 1px #fff, 0 0 0 0.2rem rgba(0, 123, 255, 0.25); }
  .custom-range::-moz-focus-outer {
    border: 0; }
  .custom-range::-webkit-slider-thumb {
    width: 1rem;
    height: 1rem;
    margin-top: -0.25rem;
    background-color: #007bff;
    border: 0;
    border-radius: 1rem;
    transition: background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
    appearance: none; }
    @media (prefers-reduced-motion: reduce) {
      .custom-range::-webkit-slider-thumb {
        transition: none; } }
    .custom-range::-webkit-slider-thumb:active {
      background-color: #b3d7ff; }
  .custom-range::-webkit-slider-runnable-track {
    width: 100%;
    height: 0.5rem;
    color: transparent;
    cursor: pointer;
    background-color: #dee2e6;
    border-color: transparent;
    border-radius: 1rem; }
  .custom-range::-moz-range-thumb {
    width: 1rem;
    height: 1rem;
    background-color: #007bff;
    border: 0;
    border-radius: 1rem;
    transition: background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
    appearance: none; }
    @media (prefers-reduced-motion: reduce) {
      .custom-range::-moz-range-thumb {
        transition: none; } }
    .custom-range::-moz-range-thumb:active {
      background-color: #b3d7ff; }
  .custom-range::-moz-range-track {
    width: 100%;
    height: 0.5rem;
    color: transparent;
    cursor: pointer;
    background-color: #dee2e6;
    border-color: transparent;
    border-radius: 1rem; }
  .custom-range::-ms-thumb {
    width: 1rem;
    height: 1rem;
    margin-top: 0;
    margin-right: 0.2rem;
    margin-left: 0.2rem;
    background-color: #007bff;
    border: 0;
    border-radius: 1rem;
    transition: background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
    appearance: none; }
    @media (prefers-reduced-motion: reduce) {
      .custom-range::-ms-thumb {
        transition: none; } }
    .custom-range::-ms-thumb:active {
      background-color: #b3d7ff; }
  .custom-range::-ms-track {
    width: 100%;
    height: 0.5rem;
    color: transparent;
    cursor: pointer;
    background-color: transparent;
    border-color: transparent;
    border-width: 0.5rem; }
  .custom-range::-ms-fill-lower {
    background-color: #dee2e6;
    border-radius: 1rem; }
  .custom-range::-ms-fill-upper {
    margin-right: 15px;
    background-color: #dee2e6;
    border-radius: 1rem; }
  .custom-range:disabled::-webkit-slider-thumb {
    background-color: #adb5bd; }
  .custom-range:disabled::-webkit-slider-runnable-track {
    cursor: default; }
  .custom-range:disabled::-moz-range-thumb {
    background-color: #adb5bd; }
  .custom-range:disabled::-moz-range-track {
    cursor: default; }
  .custom-range:disabled::-ms-thumb {
    background-color: #adb5bd; }

.custom-control-label::before,
.custom-file-label,
.custom-select {
  transition: background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out; }
  @media (prefers-reduced-motion: reduce) {
    .custom-control-label::before,
    .custom-file-label,
    .custom-select {
      transition: none; } }

.nav {
  display: flex;
  flex-wrap: wrap;
  padding-left: 0;
  margin-bottom: 0;
  list-style: none; }

.nav-link {
  display: block;
  padding: 0.5rem 1rem;
  text-decoration: none; }
  .nav-link:hover, .nav-link:focus {
    text-decoration: none; }
  .nav-link.disabled {
    color: #6c757d;
    pointer-events: none;
    cursor: default; }

.nav-tabs {
  border-bottom: 1px solid #dee2e6; }
  .nav-tabs .nav-link {
    margin-bottom: -1px;
    background-color: transparent;
    border: 1px solid transparent;
    border-top-left-radius: 0.25rem;
    border-top-right-radius: 0.25rem; }
    .nav-tabs .nav-link:hover, .nav-tabs .nav-link:focus {
      isolation: isolate;
      border-color: #e9ecef #e9ecef #dee2e6; }
    .nav-tabs .nav-link.disabled {
      color: #6c757d;
      background-color: transparent;
      border-color: transparent; }
  .nav-tabs .nav-link.active,
  .nav-tabs .nav-item.show .nav-link {
    color: #495057;
    background-color: #fff;
    border-color: #dee2e6 #dee2e6 #fff; }
  .nav-tabs .dropdown-menu {
    margin-top: -1px;
    border-top-left-radius: 0;
    border-top-right-radius: 0; }

.nav-pills .nav-link {
  background: none;
  border: 0;
  border-radius: 0.25rem; }

.nav-pills .nav-link.active,
.nav-pills .show > .nav-link {
  color: #fff;
  background-color: #007bff; }

.nav-fill > .nav-link,
.nav-fill .nav-item {
  flex: 1 1 auto;
  text-align: center; }

.nav-justified > .nav-link,
.nav-justified .nav-item {
  flex-basis: 0;
  flex-grow: 1;
  text-align: center; }

.tab-content > .tab-pane {
  display: none; }

.tab-content > .active {
  display: block; }

.navbar {
  position: relative;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: space-between;
  padding: 0.5rem 1rem; }
  .navbar .container,
  .navbar .container-fluid, .navbar .container-sm, .navbar .container-md, .navbar .container-lg, .navbar .container-xl {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: space-between; }

.navbar-brand {
  display: inline-block;
  padding-top: 0.14rem;
  padding-bottom: 0.14rem;
  margin-right: 1rem;
  font-size: 2rem;
  line-height: inherit;
  white-space: nowrap; }
  .navbar-brand:hover, .navbar-brand:focus {
    text-decoration: none; }

.navbar-nav {
  display: flex;
  flex-direction: column;
  padding-left: 0;
  margin-bottom: 0;
  list-style: none; }
  .navbar-nav .nav-link {
    padding-right: 0;
    padding-left: 0; }
  .navbar-nav .dropdown-menu {
    position: static;
    float: none; }

.navbar-text {
  display: inline-block;
  padding-top: 0.5rem;
  padding-bottom: 0.5rem; }

.navbar-collapse {
  flex-basis: 100%;
  flex-grow: 1;
  align-items: center; }

.navbar-toggler {
  padding: 0.25rem 0.75rem;
  font-size: 2rem;
  line-height: 1;
  background-color: transparent;
  border: 1px solid transparent;
  border-radius: 0.25rem; }
  .navbar-toggler:hover, .navbar-toggler:focus {
    text-decoration: none; }

.navbar-toggler-icon {
  display: inline-block;
  width: 1.5em;
  height: 1.5em;
  vertical-align: middle;
  content: "";
  background: 50% / 100% 100% no-repeat; }

.navbar-nav-scroll {
  max-height: 75vh;
  overflow-y: auto; }

@media (max-width: 575.98px) {
  .navbar-expand-sm > .container,
  .navbar-expand-sm > .container-fluid, .navbar-expand-sm > .container-sm, .navbar-expand-sm > .container-md, .navbar-expand-sm > .container-lg, .navbar-expand-sm > .container-xl {
    padding-right: 0;
    padding-left: 0; } }

@media (min-width: 576px) {
  .navbar-expand-sm {
    flex-flow: row nowrap;
    justify-content: flex-start; }
    .navbar-expand-sm .navbar-nav {
      flex-direction: row; }
      .navbar-expand-sm .navbar-nav .dropdown-menu {
        position: absolute; }
      .navbar-expand-sm .navbar-nav .nav-link {
        padding-right: 0.5rem;
        padding-left: 0.5rem; }
    .navbar-expand-sm > .container,
    .navbar-expand-sm > .container-fluid, .navbar-expand-sm > .container-sm, .navbar-expand-sm > .container-md, .navbar-expand-sm > .container-lg, .navbar-expand-sm > .container-xl {
      flex-wrap: nowrap; }
    .navbar-expand-sm .navbar-nav-scroll {
      overflow: visible; }
    .navbar-expand-sm .navbar-collapse {
      display: flex !important;
      flex-basis: auto; }
    .navbar-expand-sm .navbar-toggler {
      display: none; } }

@media (max-width: 767.98px) {
  .navbar-expand-md > .container,
  .navbar-expand-md > .container-fluid, .navbar-expand-md > .container-sm, .navbar-expand-md > .container-md, .navbar-expand-md > .container-lg, .navbar-expand-md > .container-xl {
    padding-right: 0;
    padding-left: 0; } }

@media (min-width: 768px) {
  .navbar-expand-md {
    flex-flow: row nowrap;
    justify-content: flex-start; }
    .navbar-expand-md .navbar-nav {
      flex-direction: row; }
      .navbar-expand-md .navbar-nav .dropdown-menu {
        position: absolute; }
      .navbar-expand-md .navbar-nav .nav-link {
        padding-right: 0.5rem;
        padding-left: 0.5rem; }
    .navbar-expand-md > .container,
    .navbar-expand-md > .container-fluid, .navbar-expand-md > .container-sm, .navbar-expand-md > .container-md, .navbar-expand-md > .container-lg, .navbar-expand-md > .container-xl {
      flex-wrap: nowrap; }
    .navbar-expand-md .navbar-nav-scroll {
      overflow: visible; }
    .navbar-expand-md .navbar-collapse {
      display: flex !important;
      flex-basis: auto; }
    .navbar-expand-md .navbar-toggler {
      display: none; } }

@media (max-width: 1079.98px) {
  .navbar-expand-lg > .container,
  .navbar-expand-lg > .container-fluid, .navbar-expand-lg > .container-sm, .navbar-expand-lg > .container-md, .navbar-expand-lg > .container-lg, .navbar-expand-lg > .container-xl {
    padding-right: 0;
    padding-left: 0; } }

@media (min-width: 1080px) {
  .navbar-expand-lg {
    flex-flow: row nowrap;
    justify-content: flex-start; }
    .navbar-expand-lg .navbar-nav {
      flex-direction: row; }
      .navbar-expand-lg .navbar-nav .dropdown-menu {
        position: absolute; }
      .navbar-expand-lg .navbar-nav .nav-link {
        padding-right: 0.5rem;
        padding-left: 0.5rem; }
    .navbar-expand-lg > .container,
    .navbar-expand-lg > .container-fluid, .navbar-expand-lg > .container-sm, .navbar-expand-lg > .container-md, .navbar-expand-lg > .container-lg, .navbar-expand-lg > .container-xl {
      flex-wrap: nowrap; }
    .navbar-expand-lg .navbar-nav-scroll {
      overflow: visible; }
    .navbar-expand-lg .navbar-collapse {
      display: flex !important;
      flex-basis: auto; }
    .navbar-expand-lg .navbar-toggler {
      display: none; } }

@media (max-width: 1279.98px) {
  .navbar-expand-llg > .container,
  .navbar-expand-llg > .container-fluid, .navbar-expand-llg > .container-sm, .navbar-expand-llg > .container-md, .navbar-expand-llg > .container-lg, .navbar-expand-llg > .container-xl {
    padding-right: 0;
    padding-left: 0; } }

@media (min-width: 1280px) {
  .navbar-expand-llg {
    flex-flow: row nowrap;
    justify-content: flex-start; }
    .navbar-expand-llg .navbar-nav {
      flex-direction: row; }
      .navbar-expand-llg .navbar-nav .dropdown-menu {
        position: absolute; }
      .navbar-expand-llg .navbar-nav .nav-link {
        padding-right: 0.5rem;
        padding-left: 0.5rem; }
    .navbar-expand-llg > .container,
    .navbar-expand-llg > .container-fluid, .navbar-expand-llg > .container-sm, .navbar-expand-llg > .container-md, .navbar-expand-llg > .container-lg, .navbar-expand-llg > .container-xl {
      flex-wrap: nowrap; }
    .navbar-expand-llg .navbar-nav-scroll {
      overflow: visible; }
    .navbar-expand-llg .navbar-collapse {
      display: flex !important;
      flex-basis: auto; }
    .navbar-expand-llg .navbar-toggler {
      display: none; } }

@media (max-width: 1399.98px) {
  .navbar-expand-xl > .container,
  .navbar-expand-xl > .container-fluid, .navbar-expand-xl > .container-sm, .navbar-expand-xl > .container-md, .navbar-expand-xl > .container-lg, .navbar-expand-xl > .container-xl {
    padding-right: 0;
    padding-left: 0; } }

@media (min-width: 1400px) {
  .navbar-expand-xl {
    flex-flow: row nowrap;
    justify-content: flex-start; }
    .navbar-expand-xl .navbar-nav {
      flex-direction: row; }
      .navbar-expand-xl .navbar-nav .dropdown-menu {
        position: absolute; }
      .navbar-expand-xl .navbar-nav .nav-link {
        padding-right: 0.5rem;
        padding-left: 0.5rem; }
    .navbar-expand-xl > .container,
    .navbar-expand-xl > .container-fluid, .navbar-expand-xl > .container-sm, .navbar-expand-xl > .container-md, .navbar-expand-xl > .container-lg, .navbar-expand-xl > .container-xl {
      flex-wrap: nowrap; }
    .navbar-expand-xl .navbar-nav-scroll {
      overflow: visible; }
    .navbar-expand-xl .navbar-collapse {
      display: flex !important;
      flex-basis: auto; }
    .navbar-expand-xl .navbar-toggler {
      display: none; } }

@media (max-width: 1979.98px) {
  .navbar-expand-xxl > .container,
  .navbar-expand-xxl > .container-fluid, .navbar-expand-xxl > .container-sm, .navbar-expand-xxl > .container-md, .navbar-expand-xxl > .container-lg, .navbar-expand-xxl > .container-xl {
    padding-right: 0;
    padding-left: 0; } }

@media (min-width: 1980px) {
  .navbar-expand-xxl {
    flex-flow: row nowrap;
    justify-content: flex-start; }
    .navbar-expand-xxl .navbar-nav {
      flex-direction: row; }
      .navbar-expand-xxl .navbar-nav .dropdown-menu {
        position: absolute; }
      .navbar-expand-xxl .navbar-nav .nav-link {
        padding-right: 0.5rem;
        padding-left: 0.5rem; }
    .navbar-expand-xxl > .container,
    .navbar-expand-xxl > .container-fluid, .navbar-expand-xxl > .container-sm, .navbar-expand-xxl > .container-md, .navbar-expand-xxl > .container-lg, .navbar-expand-xxl > .container-xl {
      flex-wrap: nowrap; }
    .navbar-expand-xxl .navbar-nav-scroll {
      overflow: visible; }
    .navbar-expand-xxl .navbar-collapse {
      display: flex !important;
      flex-basis: auto; }
    .navbar-expand-xxl .navbar-toggler {
      display: none; } }

.navbar-expand {
  flex-flow: row nowrap;
  justify-content: flex-start; }
  .navbar-expand > .container,
  .navbar-expand > .container-fluid, .navbar-expand > .container-sm, .navbar-expand > .container-md, .navbar-expand > .container-lg, .navbar-expand > .container-xl {
    padding-right: 0;
    padding-left: 0; }
  .navbar-expand .navbar-nav {
    flex-direction: row; }
    .navbar-expand .navbar-nav .dropdown-menu {
      position: absolute; }
    .navbar-expand .navbar-nav .nav-link {
      padding-right: 0.5rem;
      padding-left: 0.5rem; }
  .navbar-expand > .container,
  .navbar-expand > .container-fluid, .navbar-expand > .container-sm, .navbar-expand > .container-md, .navbar-expand > .container-lg, .navbar-expand > .container-xl {
    flex-wrap: nowrap; }
  .navbar-expand .navbar-nav-scroll {
    overflow: visible; }
  .navbar-expand .navbar-collapse {
    display: flex !important;
    flex-basis: auto; }
  .navbar-expand .navbar-toggler {
    display: none; }

.navbar-light .navbar-brand {
  color: rgba(0, 0, 0, 0.9); }
  .navbar-light .navbar-brand:hover, .navbar-light .navbar-brand:focus {
    color: rgba(0, 0, 0, 0.9); }

.navbar-light .navbar-nav .nav-link {
  color: rgba(0, 0, 0, 0.5); }
  .navbar-light .navbar-nav .nav-link:hover, .navbar-light .navbar-nav .nav-link:focus {
    color: rgba(0, 0, 0, 0.7); }
  .navbar-light .navbar-nav .nav-link.disabled {
    color: rgba(0, 0, 0, 0.3); }

.navbar-light .navbar-nav .show > .nav-link,
.navbar-light .navbar-nav .active > .nav-link,
.navbar-light .navbar-nav .nav-link.show,
.navbar-light .navbar-nav .nav-link.active {
  color: rgba(0, 0, 0, 0.9); }

.navbar-light .navbar-toggler {
  color: rgba(0, 0, 0, 0.5);
  border-color: rgba(0, 0, 0, 0.1); }

.navbar-light .navbar-toggler-icon {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='30' height='30' viewBox='0 0 30 30'%3e%3cpath stroke='rgba%280, 0, 0, 0.5%29' stroke-linecap='round' stroke-miterlimit='10' stroke-width='2' d='M4 7h22M4 15h22M4 23h22'/%3e%3c/svg%3e"); }

.navbar-light .navbar-text {
  color: rgba(0, 0, 0, 0.5); }
  .navbar-light .navbar-text a {
    color: rgba(0, 0, 0, 0.9); }
    .navbar-light .navbar-text a:hover, .navbar-light .navbar-text a:focus {
      color: rgba(0, 0, 0, 0.9); }

.navbar-dark .navbar-brand {
  color: #fff; }
  .navbar-dark .navbar-brand:hover, .navbar-dark .navbar-brand:focus {
    color: #fff; }

.navbar-dark .navbar-nav .nav-link {
  color: rgba(255, 255, 255, 0.5); }
  .navbar-dark .navbar-nav .nav-link:hover, .navbar-dark .navbar-nav .nav-link:focus {
    color: rgba(255, 255, 255, 0.75); }
  .navbar-dark .navbar-nav .nav-link.disabled {
    color: rgba(255, 255, 255, 0.25); }

.navbar-dark .navbar-nav .show > .nav-link,
.navbar-dark .navbar-nav .active > .nav-link,
.navbar-dark .navbar-nav .nav-link.show,
.navbar-dark .navbar-nav .nav-link.active {
  color: #fff; }

.navbar-dark .navbar-toggler {
  color: rgba(255, 255, 255, 0.5);
  border-color: rgba(255, 255, 255, 0.1); }

.navbar-dark .navbar-toggler-icon {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='30' height='30' viewBox='0 0 30 30'%3e%3cpath stroke='rgba%28255, 255, 255, 0.5%29' stroke-linecap='round' stroke-miterlimit='10' stroke-width='2' d='M4 7h22M4 15h22M4 23h22'/%3e%3c/svg%3e"); }

.navbar-dark .navbar-text {
  color: rgba(255, 255, 255, 0.5); }
  .navbar-dark .navbar-text a {
    color: #fff; }
    .navbar-dark .navbar-text a:hover, .navbar-dark .navbar-text a:focus {
      color: #fff; }

.card {
  position: relative;
  display: flex;
  flex-direction: column;
  min-width: 0;
  word-wrap: break-word;
  background-color: #fff;
  background-clip: border-box;
  border: 1px solid rgba(0, 0, 0, 0.125);
  border-radius: 0.25rem; }
  .card > hr {
    margin-right: 0;
    margin-left: 0; }
  .card > .list-group {
    border-top: inherit;
    border-bottom: inherit; }
    .card > .list-group:first-child {
      border-top-width: 0;
      border-top-left-radius: calc(0.25rem - 1px);
      border-top-right-radius: calc(0.25rem - 1px); }
    .card > .list-group:last-child {
      border-bottom-width: 0;
      border-bottom-right-radius: calc(0.25rem - 1px);
      border-bottom-left-radius: calc(0.25rem - 1px); }
  .card > .card-header + .list-group,
  .card > .list-group + .card-footer {
    border-top: 0; }

.card-body {
  flex: 1 1 auto;
  min-height: 1px;
  padding: 1.25rem; }

.card-title {
  margin-bottom: 0.75rem; }

.card-subtitle {
  margin-top: -0.375rem;
  margin-bottom: 0; }

.card-text:last-child {
  margin-bottom: 0; }

.card-link:hover {
  text-decoration: none; }

.card-link + .card-link {
  margin-left: 1.25rem; }

.card-header {
  padding: 0.75rem 1.25rem;
  margin-bottom: 0;
  background-color: rgba(0, 0, 0, 0.03);
  border-bottom: 1px solid rgba(0, 0, 0, 0.125); }
  .card-header:first-child {
    border-radius: calc(0.25rem - 1px) calc(0.25rem - 1px) 0 0; }

.card-footer {
  padding: 0.75rem 1.25rem;
  background-color: rgba(0, 0, 0, 0.03);
  border-top: 1px solid rgba(0, 0, 0, 0.125); }
  .card-footer:last-child {
    border-radius: 0 0 calc(0.25rem - 1px) calc(0.25rem - 1px); }

.card-header-tabs {
  margin-right: -0.625rem;
  margin-bottom: -0.75rem;
  margin-left: -0.625rem;
  border-bottom: 0; }

.card-header-pills {
  margin-right: -0.625rem;
  margin-left: -0.625rem; }

.card-img-overlay {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  padding: 1.25rem;
  border-radius: calc(0.25rem - 1px); }

.card-img,
.card-img-top,
.card-img-bottom {
  flex-shrink: 0;
  width: 100%; }

.card-img,
.card-img-top {
  border-top-left-radius: calc(0.25rem - 1px);
  border-top-right-radius: calc(0.25rem - 1px); }

.card-img,
.card-img-bottom {
  border-bottom-right-radius: calc(0.25rem - 1px);
  border-bottom-left-radius: calc(0.25rem - 1px); }

.card-deck .card {
  margin-bottom: 15px; }

@media (min-width: 576px) {
  .card-deck {
    display: flex;
    flex-flow: row wrap;
    margin-right: -15px;
    margin-left: -15px; }
    .card-deck .card {
      flex: 1 0 0%;
      margin-right: 15px;
      margin-bottom: 0;
      margin-left: 15px; } }

.card-group > .card {
  margin-bottom: 15px; }

@media (min-width: 576px) {
  .card-group {
    display: flex;
    flex-flow: row wrap; }
    .card-group > .card {
      flex: 1 0 0%;
      margin-bottom: 0; }
      .card-group > .card + .card {
        margin-left: 0;
        border-left: 0; }
      .card-group > .card:not(:last-child) {
        border-top-right-radius: 0;
        border-bottom-right-radius: 0; }
        .card-group > .card:not(:last-child) .card-img-top,
        .card-group > .card:not(:last-child) .card-header {
          border-top-right-radius: 0; }
        .card-group > .card:not(:last-child) .card-img-bottom,
        .card-group > .card:not(:last-child) .card-footer {
          border-bottom-right-radius: 0; }
      .card-group > .card:not(:first-child) {
        border-top-left-radius: 0;
        border-bottom-left-radius: 0; }
        .card-group > .card:not(:first-child) .card-img-top,
        .card-group > .card:not(:first-child) .card-header {
          border-top-left-radius: 0; }
        .card-group > .card:not(:first-child) .card-img-bottom,
        .card-group > .card:not(:first-child) .card-footer {
          border-bottom-left-radius: 0; } }

.card-columns .card {
  margin-bottom: 0.75rem; }

@media (min-width: 576px) {
  .card-columns {
    column-count: 3;
    column-gap: 1.25rem;
    orphans: 1;
    widows: 1; }
    .card-columns .card {
      display: inline-block;
      width: 100%; } }

.accordion {
  overflow-anchor: none; }
  .accordion > .card {
    overflow: hidden; }
    .accordion > .card:not(:last-of-type) {
      border-bottom: 0;
      border-bottom-right-radius: 0;
      border-bottom-left-radius: 0; }
    .accordion > .card:not(:first-of-type) {
      border-top-left-radius: 0;
      border-top-right-radius: 0; }
    .accordion > .card > .card-header {
      border-radius: 0;
      margin-bottom: -1px; }

.breadcrumb {
  display: flex;
  flex-wrap: wrap;
  padding: 0.75rem 1rem;
  margin-bottom: 1rem;
  list-style: none;
  background-color: transparent;
  border-radius: 0.25rem; }

.breadcrumb-item + .breadcrumb-item {
  padding-left: 0.5rem; }
  .breadcrumb-item + .breadcrumb-item::before {
    float: left;
    padding-right: 0.5rem;
    color: #6c757d;
    content: "/"; }

.breadcrumb-item + .breadcrumb-item:hover::before {
  text-decoration: underline; }

.breadcrumb-item + .breadcrumb-item:hover::before {
  text-decoration: none; }

.breadcrumb-item.active {
  color: #6c757d; }

.pagination {
  display: flex;
  padding-left: 0;
  list-style: none;
  border-radius: 0.25rem; }

.page-link {
  position: relative;
  display: block;
  padding: 0.5rem 0.75rem;
  margin-left: -1px;
  line-height: 1.25;
  color: #333;
  text-decoration: none;
  background-color: #fff;
  border: 1px solid #dee2e6; }
  .page-link:hover {
    z-index: 2;
    color: #0d0d0d;
    text-decoration: none;
    background-color: #e9ecef;
    border-color: #dee2e6; }
  .page-link:focus {
    z-index: 3;
    outline: 0;
    box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, 0.25); }

.page-item:first-child .page-link {
  margin-left: 0;
  border-top-left-radius: 0.25rem;
  border-bottom-left-radius: 0.25rem; }

.page-item:last-child .page-link {
  border-top-right-radius: 0.25rem;
  border-bottom-right-radius: 0.25rem; }

.page-item.active .page-link {
  z-index: 3;
  color: #fff;
  background-color: #007bff;
  border-color: #007bff; }

.page-item.disabled .page-link {
  color: #6c757d;
  pointer-events: none;
  cursor: auto;
  background-color: #fff;
  border-color: #dee2e6; }

.pagination-lg .page-link {
  padding: 0.75rem 1.5rem;
  font-size: 2rem;
  line-height: 1.5; }

.pagination-lg .page-item:first-child .page-link {
  border-top-left-radius: 0.3rem;
  border-bottom-left-radius: 0.3rem; }

.pagination-lg .page-item:last-child .page-link {
  border-top-right-radius: 0.3rem;
  border-bottom-right-radius: 0.3rem; }

.pagination-sm .page-link {
  padding: 0.25rem 0.5rem;
  font-size: 1.4rem;
  line-height: 1.5; }

.pagination-sm .page-item:first-child .page-link {
  border-top-left-radius: 0.2rem;
  border-bottom-left-radius: 0.2rem; }

.pagination-sm .page-item:last-child .page-link {
  border-top-right-radius: 0.2rem;
  border-bottom-right-radius: 0.2rem; }

.badge {
  display: inline-block;
  padding: 0.25em 0.4em;
  font-size: 75%;
  font-weight: 700;
  line-height: 1;
  text-align: center;
  white-space: nowrap;
  vertical-align: baseline;
  border-radius: 0.25rem;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out; }
  @media (prefers-reduced-motion: reduce) {
    .badge {
      transition: none; } }
  a.badge:hover, a.badge:focus {
    text-decoration: none; }
  .badge:empty {
    display: none; }

.btn .badge {
  position: relative;
  top: -1px; }

.badge-pill {
  padding-right: 0.6em;
  padding-left: 0.6em;
  border-radius: 10rem; }

.badge-primary {
  color: #fff;
  background-color: #007bff; }
  a.badge-primary:hover, a.badge-primary:focus {
    color: #fff;
    background-color: #0062cc; }
  a.badge-primary:focus, a.badge-primary.focus {
    outline: 0;
    box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, 0.5); }

.badge-secondary {
  color: #fff;
  background-color: #6c757d; }
  a.badge-secondary:hover, a.badge-secondary:focus {
    color: #fff;
    background-color: #545b62; }
  a.badge-secondary:focus, a.badge-secondary.focus {
    outline: 0;
    box-shadow: 0 0 0 0.2rem rgba(108, 117, 125, 0.5); }

.badge-success {
  color: #fff;
  background-color: #28a745; }
  a.badge-success:hover, a.badge-success:focus {
    color: #fff;
    background-color: #1e7e34; }
  a.badge-success:focus, a.badge-success.focus {
    outline: 0;
    box-shadow: 0 0 0 0.2rem rgba(40, 167, 69, 0.5); }

.badge-info {
  color: #fff;
  background-color: #17a2b8; }
  a.badge-info:hover, a.badge-info:focus {
    color: #fff;
    background-color: #117a8b; }
  a.badge-info:focus, a.badge-info.focus {
    outline: 0;
    box-shadow: 0 0 0 0.2rem rgba(23, 162, 184, 0.5); }

.badge-warning {
  color: #212529;
  background-color: #ffc107; }
  a.badge-warning:hover, a.badge-warning:focus {
    color: #212529;
    background-color: #d39e00; }
  a.badge-warning:focus, a.badge-warning.focus {
    outline: 0;
    box-shadow: 0 0 0 0.2rem rgba(255, 193, 7, 0.5); }

.badge-danger {
  color: #fff;
  background-color: #dc3545; }
  a.badge-danger:hover, a.badge-danger:focus {
    color: #fff;
    background-color: #bd2130; }
  a.badge-danger:focus, a.badge-danger.focus {
    outline: 0;
    box-shadow: 0 0 0 0.2rem rgba(220, 53, 69, 0.5); }

.badge-light {
  color: #212529;
  background-color: #f8f9fa; }
  a.badge-light:hover, a.badge-light:focus {
    color: #212529;
    background-color: #dae0e5; }
  a.badge-light:focus, a.badge-light.focus {
    outline: 0;
    box-shadow: 0 0 0 0.2rem rgba(248, 249, 250, 0.5); }

.badge-dark {
  color: #fff;
  background-color: #343a40; }
  a.badge-dark:hover, a.badge-dark:focus {
    color: #fff;
    background-color: #1d2124; }
  a.badge-dark:focus, a.badge-dark.focus {
    outline: 0;
    box-shadow: 0 0 0 0.2rem rgba(52, 58, 64, 0.5); }

.jumbotron {
  padding: 2rem 1rem;
  margin-bottom: 2rem;
  background-color: #e9ecef;
  border-radius: 0.3rem; }
  @media (min-width: 576px) {
    .jumbotron {
      padding: 4rem 2rem; } }

.jumbotron-fluid {
  padding-right: 0;
  padding-left: 0;
  border-radius: 0; }

.alert {
  position: relative;
  padding: 0.75rem 1.25rem;
  margin-bottom: 1rem;
  border: 1px solid transparent;
  border-radius: 0.25rem; }

.alert-heading {
  color: inherit; }

.alert-link {
  font-weight: 700; }

.alert-dismissible {
  padding-right: 4.9rem; }
  .alert-dismissible .close {
    position: absolute;
    top: 0;
    right: 0;
    z-index: 2;
    padding: 0.75rem 1.25rem;
    color: inherit; }

.alert-primary {
  color: #004085;
  background-color: #cce5ff;
  border-color: #b8daff; }
  .alert-primary hr {
    border-top-color: #9fcdff; }
  .alert-primary .alert-link {
    color: #002752; }

.alert-secondary {
  color: #383d41;
  background-color: #e2e3e5;
  border-color: #d6d8db; }
  .alert-secondary hr {
    border-top-color: #c8cbcf; }
  .alert-secondary .alert-link {
    color: #202326; }

.alert-success {
  color: #155724;
  background-color: #d4edda;
  border-color: #c3e6cb; }
  .alert-success hr {
    border-top-color: #b1dfbb; }
  .alert-success .alert-link {
    color: #0b2e13; }

.alert-info {
  color: #0c5460;
  background-color: #d1ecf1;
  border-color: #bee5eb; }
  .alert-info hr {
    border-top-color: #abdde5; }
  .alert-info .alert-link {
    color: #062c33; }

.alert-warning {
  color: #856404;
  background-color: #fff3cd;
  border-color: #ffeeba; }
  .alert-warning hr {
    border-top-color: #ffe8a1; }
  .alert-warning .alert-link {
    color: #533f03; }

.alert-danger {
  color: #721c24;
  background-color: #f8d7da;
  border-color: #f5c6cb; }
  .alert-danger hr {
    border-top-color: #f1b0b7; }
  .alert-danger .alert-link {
    color: #491217; }

.alert-light {
  color: #818182;
  background-color: #fefefe;
  border-color: #fdfdfe; }
  .alert-light hr {
    border-top-color: #ececf6; }
  .alert-light .alert-link {
    color: #686868; }

.alert-dark {
  color: #1b1e21;
  background-color: #d6d8d9;
  border-color: #c6c8ca; }
  .alert-dark hr {
    border-top-color: #b9bbbe; }
  .alert-dark .alert-link {
    color: #040505; }

@keyframes progress-bar-stripes {
  from {
    background-position: 1rem 0; }
  to {
    background-position: 0 0; } }

.progress {
  display: flex;
  height: 1rem;
  overflow: hidden;
  line-height: 0;
  font-size: 1.2rem;
  background-color: #e9ecef;
  border-radius: 0.25rem; }

.progress-bar {
  display: flex;
  flex-direction: column;
  justify-content: center;
  overflow: hidden;
  color: #fff;
  text-align: center;
  white-space: nowrap;
  background-color: #007bff;
  transition: width 0.6s ease; }
  @media (prefers-reduced-motion: reduce) {
    .progress-bar {
      transition: none; } }

.progress-bar-striped {
  background-image: linear-gradient(45deg, rgba(255, 255, 255, 0.15) 25%, transparent 25%, transparent 50%, rgba(255, 255, 255, 0.15) 50%, rgba(255, 255, 255, 0.15) 75%, transparent 75%, transparent);
  background-size: 1rem 1rem; }

.progress-bar-animated {
  animation: 1s linear infinite progress-bar-stripes; }
  @media (prefers-reduced-motion: reduce) {
    .progress-bar-animated {
      animation: none; } }

.list-group {
  display: flex;
  flex-direction: column;
  padding-left: 0;
  margin-bottom: 0;
  border-radius: 0.25rem; }

.list-group-item-action {
  width: 100%;
  color: #495057;
  text-align: inherit; }
  .list-group-item-action:hover, .list-group-item-action:focus {
    z-index: 1;
    color: #495057;
    text-decoration: none;
    background-color: #f8f9fa; }
  .list-group-item-action:active {
    color: #212529;
    background-color: #e9ecef; }

.list-group-item {
  position: relative;
  display: block;
  padding: 0.75rem 1.25rem;
  text-decoration: none;
  background-color: #fff;
  border: 1px solid rgba(0, 0, 0, 0.125); }
  .list-group-item:first-child {
    border-top-left-radius: inherit;
    border-top-right-radius: inherit; }
  .list-group-item:last-child {
    border-bottom-right-radius: inherit;
    border-bottom-left-radius: inherit; }
  .list-group-item.disabled, .list-group-item:disabled {
    color: #6c757d;
    pointer-events: none;
    background-color: #fff; }
  .list-group-item.active {
    z-index: 2;
    color: #fff;
    background-color: #007bff;
    border-color: #007bff; }
  .list-group-item + .list-group-item {
    border-top-width: 0; }
    .list-group-item + .list-group-item.active {
      margin-top: -1px;
      border-top-width: 1px; }

.list-group-horizontal {
  flex-direction: row; }
  .list-group-horizontal > .list-group-item:first-child {
    border-bottom-left-radius: 0.25rem;
    border-top-right-radius: 0; }
  .list-group-horizontal > .list-group-item:last-child {
    border-top-right-radius: 0.25rem;
    border-bottom-left-radius: 0; }
  .list-group-horizontal > .list-group-item.active {
    margin-top: 0; }
  .list-group-horizontal > .list-group-item + .list-group-item {
    border-top-width: 1px;
    border-left-width: 0; }
    .list-group-horizontal > .list-group-item + .list-group-item.active {
      margin-left: -1px;
      border-left-width: 1px; }

@media (min-width: 576px) {
  .list-group-horizontal-sm {
    flex-direction: row; }
    .list-group-horizontal-sm > .list-group-item:first-child {
      border-bottom-left-radius: 0.25rem;
      border-top-right-radius: 0; }
    .list-group-horizontal-sm > .list-group-item:last-child {
      border-top-right-radius: 0.25rem;
      border-bottom-left-radius: 0; }
    .list-group-horizontal-sm > .list-group-item.active {
      margin-top: 0; }
    .list-group-horizontal-sm > .list-group-item + .list-group-item {
      border-top-width: 1px;
      border-left-width: 0; }
      .list-group-horizontal-sm > .list-group-item + .list-group-item.active {
        margin-left: -1px;
        border-left-width: 1px; } }

@media (min-width: 768px) {
  .list-group-horizontal-md {
    flex-direction: row; }
    .list-group-horizontal-md > .list-group-item:first-child {
      border-bottom-left-radius: 0.25rem;
      border-top-right-radius: 0; }
    .list-group-horizontal-md > .list-group-item:last-child {
      border-top-right-radius: 0.25rem;
      border-bottom-left-radius: 0; }
    .list-group-horizontal-md > .list-group-item.active {
      margin-top: 0; }
    .list-group-horizontal-md > .list-group-item + .list-group-item {
      border-top-width: 1px;
      border-left-width: 0; }
      .list-group-horizontal-md > .list-group-item + .list-group-item.active {
        margin-left: -1px;
        border-left-width: 1px; } }

@media (min-width: 1080px) {
  .list-group-horizontal-lg {
    flex-direction: row; }
    .list-group-horizontal-lg > .list-group-item:first-child {
      border-bottom-left-radius: 0.25rem;
      border-top-right-radius: 0; }
    .list-group-horizontal-lg > .list-group-item:last-child {
      border-top-right-radius: 0.25rem;
      border-bottom-left-radius: 0; }
    .list-group-horizontal-lg > .list-group-item.active {
      margin-top: 0; }
    .list-group-horizontal-lg > .list-group-item + .list-group-item {
      border-top-width: 1px;
      border-left-width: 0; }
      .list-group-horizontal-lg > .list-group-item + .list-group-item.active {
        margin-left: -1px;
        border-left-width: 1px; } }

@media (min-width: 1280px) {
  .list-group-horizontal-llg {
    flex-direction: row; }
    .list-group-horizontal-llg > .list-group-item:first-child {
      border-bottom-left-radius: 0.25rem;
      border-top-right-radius: 0; }
    .list-group-horizontal-llg > .list-group-item:last-child {
      border-top-right-radius: 0.25rem;
      border-bottom-left-radius: 0; }
    .list-group-horizontal-llg > .list-group-item.active {
      margin-top: 0; }
    .list-group-horizontal-llg > .list-group-item + .list-group-item {
      border-top-width: 1px;
      border-left-width: 0; }
      .list-group-horizontal-llg > .list-group-item + .list-group-item.active {
        margin-left: -1px;
        border-left-width: 1px; } }

@media (min-width: 1400px) {
  .list-group-horizontal-xl {
    flex-direction: row; }
    .list-group-horizontal-xl > .list-group-item:first-child {
      border-bottom-left-radius: 0.25rem;
      border-top-right-radius: 0; }
    .list-group-horizontal-xl > .list-group-item:last-child {
      border-top-right-radius: 0.25rem;
      border-bottom-left-radius: 0; }
    .list-group-horizontal-xl > .list-group-item.active {
      margin-top: 0; }
    .list-group-horizontal-xl > .list-group-item + .list-group-item {
      border-top-width: 1px;
      border-left-width: 0; }
      .list-group-horizontal-xl > .list-group-item + .list-group-item.active {
        margin-left: -1px;
        border-left-width: 1px; } }

@media (min-width: 1980px) {
  .list-group-horizontal-xxl {
    flex-direction: row; }
    .list-group-horizontal-xxl > .list-group-item:first-child {
      border-bottom-left-radius: 0.25rem;
      border-top-right-radius: 0; }
    .list-group-horizontal-xxl > .list-group-item:last-child {
      border-top-right-radius: 0.25rem;
      border-bottom-left-radius: 0; }
    .list-group-horizontal-xxl > .list-group-item.active {
      margin-top: 0; }
    .list-group-horizontal-xxl > .list-group-item + .list-group-item {
      border-top-width: 1px;
      border-left-width: 0; }
      .list-group-horizontal-xxl > .list-group-item + .list-group-item.active {
        margin-left: -1px;
        border-left-width: 1px; } }

.list-group-flush {
  border-radius: 0; }
  .list-group-flush > .list-group-item {
    border-width: 0 0 1px; }
    .list-group-flush > .list-group-item:last-child {
      border-bottom-width: 0; }

.list-group-item-primary {
  color: #004085;
  background-color: #b8daff; }
  .list-group-item-primary.list-group-item-action:hover, .list-group-item-primary.list-group-item-action:focus {
    color: #004085;
    background-color: #9fcdff; }
  .list-group-item-primary.list-group-item-action.active {
    color: #fff;
    background-color: #004085;
    border-color: #004085; }

.list-group-item-secondary {
  color: #383d41;
  background-color: #d6d8db; }
  .list-group-item-secondary.list-group-item-action:hover, .list-group-item-secondary.list-group-item-action:focus {
    color: #383d41;
    background-color: #c8cbcf; }
  .list-group-item-secondary.list-group-item-action.active {
    color: #fff;
    background-color: #383d41;
    border-color: #383d41; }

.list-group-item-success {
  color: #155724;
  background-color: #c3e6cb; }
  .list-group-item-success.list-group-item-action:hover, .list-group-item-success.list-group-item-action:focus {
    color: #155724;
    background-color: #b1dfbb; }
  .list-group-item-success.list-group-item-action.active {
    color: #fff;
    background-color: #155724;
    border-color: #155724; }

.list-group-item-info {
  color: #0c5460;
  background-color: #bee5eb; }
  .list-group-item-info.list-group-item-action:hover, .list-group-item-info.list-group-item-action:focus {
    color: #0c5460;
    background-color: #abdde5; }
  .list-group-item-info.list-group-item-action.active {
    color: #fff;
    background-color: #0c5460;
    border-color: #0c5460; }

.list-group-item-warning {
  color: #856404;
  background-color: #ffeeba; }
  .list-group-item-warning.list-group-item-action:hover, .list-group-item-warning.list-group-item-action:focus {
    color: #856404;
    background-color: #ffe8a1; }
  .list-group-item-warning.list-group-item-action.active {
    color: #fff;
    background-color: #856404;
    border-color: #856404; }

.list-group-item-danger {
  color: #721c24;
  background-color: #f5c6cb; }
  .list-group-item-danger.list-group-item-action:hover, .list-group-item-danger.list-group-item-action:focus {
    color: #721c24;
    background-color: #f1b0b7; }
  .list-group-item-danger.list-group-item-action.active {
    color: #fff;
    background-color: #721c24;
    border-color: #721c24; }

.list-group-item-light {
  color: #818182;
  background-color: #fdfdfe; }
  .list-group-item-light.list-group-item-action:hover, .list-group-item-light.list-group-item-action:focus {
    color: #818182;
    background-color: #ececf6; }
  .list-group-item-light.list-group-item-action.active {
    color: #fff;
    background-color: #818182;
    border-color: #818182; }

.list-group-item-dark {
  color: #1b1e21;
  background-color: #c6c8ca; }
  .list-group-item-dark.list-group-item-action:hover, .list-group-item-dark.list-group-item-action:focus {
    color: #1b1e21;
    background-color: #b9bbbe; }
  .list-group-item-dark.list-group-item-action.active {
    color: #fff;
    background-color: #1b1e21;
    border-color: #1b1e21; }

.close {
  float: right;
  font-size: 2.4rem;
  font-weight: 700;
  line-height: 1;
  color: #000;
  text-shadow: 0 1px 0 #fff;
  opacity: .5; }
  .close:hover {
    color: #000;
    text-decoration: none; }
  .close:not(:disabled):not(.disabled):hover, .close:not(:disabled):not(.disabled):focus {
    opacity: .75; }

button.close {
  padding: 0;
  background-color: transparent;
  border: 0; }

a.close.disabled {
  pointer-events: none; }

.modal-open {
  overflow: hidden; }
  .modal-open .modal {
    overflow-x: hidden;
    overflow-y: auto; }

.modal {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1050;
  display: none;
  width: 100%;
  height: 100%;
  overflow: hidden;
  outline: 0; }

.modal-dialog {
  position: relative;
  width: auto;
  margin: 0.5rem;
  pointer-events: none; }
  .modal.fade .modal-dialog {
    transition: transform 0.3s ease-out;
    transform: translate(0, -50px); }
    @media (prefers-reduced-motion: reduce) {
      .modal.fade .modal-dialog {
        transition: none; } }
  .modal.show .modal-dialog {
    transform: none; }
  .modal.modal-static .modal-dialog {
    transform: scale(1.02); }

.modal-dialog-scrollable {
  display: flex;
  max-height: calc(100% - 1rem); }
  .modal-dialog-scrollable .modal-content {
    max-height: calc(100vh - 1rem);
    overflow: hidden; }
  .modal-dialog-scrollable .modal-header,
  .modal-dialog-scrollable .modal-footer {
    flex-shrink: 0; }
  .modal-dialog-scrollable .modal-body {
    overflow-y: auto; }

.modal-dialog-centered {
  display: flex;
  align-items: center;
  min-height: calc(100% - 1rem); }
  .modal-dialog-centered::before {
    display: block;
    height: calc(100vh - 1rem);
    height: min-content;
    content: ""; }
  .modal-dialog-centered.modal-dialog-scrollable {
    flex-direction: column;
    justify-content: center;
    height: 100%; }
    .modal-dialog-centered.modal-dialog-scrollable .modal-content {
      max-height: none; }
    .modal-dialog-centered.modal-dialog-scrollable::before {
      content: none; }

.modal-content {
  position: relative;
  display: flex;
  flex-direction: column;
  width: 100%;
  pointer-events: auto;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid rgba(0, 0, 0, 0.2);
  border-radius: 0.3rem;
  outline: 0; }

.modal-backdrop {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1040;
  width: 100vw;
  height: 100vh;
  background-color: #000; }
  .modal-backdrop.fade {
    opacity: 0; }
  .modal-backdrop.show {
    opacity: 0.5; }

.modal-header {
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  padding: 1rem 1rem;
  border-bottom: 1px solid #dee2e6;
  border-top-left-radius: calc(0.3rem - 1px);
  border-top-right-radius: calc(0.3rem - 1px); }
  .modal-header .close {
    padding: 1rem 1rem;
    margin: -1rem -1rem -1rem auto; }

.modal-title {
  margin-bottom: 0;
  line-height: 1.8; }

.modal-body {
  position: relative;
  flex: 1 1 auto;
  padding: 1rem; }

.modal-footer {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: flex-end;
  padding: 0.75rem;
  border-top: 1px solid #dee2e6;
  border-bottom-right-radius: calc(0.3rem - 1px);
  border-bottom-left-radius: calc(0.3rem - 1px); }
  .modal-footer > * {
    margin: 0.25rem; }

.modal-scrollbar-measure {
  position: absolute;
  top: -9999px;
  width: 50px;
  height: 50px;
  overflow: scroll; }

@media (min-width: 576px) {
  .modal-dialog {
    max-width: 500px;
    margin: 1.75rem auto; }
  .modal-dialog-scrollable {
    max-height: calc(100% - 3.5rem); }
    .modal-dialog-scrollable .modal-content {
      max-height: calc(100vh - 3.5rem); }
  .modal-dialog-centered {
    min-height: calc(100% - 3.5rem); }
    .modal-dialog-centered::before {
      height: calc(100vh - 3.5rem);
      height: min-content; }
  .modal-sm {
    max-width: 300px; } }

@media (min-width: 1080px) {
  .modal-lg,
  .modal-xl {
    max-width: 800px; } }

@media (min-width: 1400px) {
  .modal-xl {
    max-width: 1140px; } }

.tooltip {
  position: absolute;
  z-index: 1070;
  display: block;
  margin: 0;
  font-family: "游ゴシック Medium", "Yu Gothic Medium", "游ゴシック体", YuGothic, "ヒラギノ角ゴ ProN W3", "Hiragino Kaku Gothic ProN", "メイリオ", Meiryo, Verdana, Roboto, "Droid Sans", sans-serif;
  font-style: normal;
  font-weight: 400;
  line-height: 1.8;
  text-align: left;
  text-align: start;
  text-decoration: none;
  text-shadow: none;
  text-transform: none;
  letter-spacing: normal;
  word-break: normal;
  white-space: normal;
  word-spacing: normal;
  line-break: auto;
  font-size: 1.4rem;
  word-wrap: break-word;
  opacity: 0; }
  .tooltip.show {
    opacity: 0.9; }
  .tooltip .arrow {
    position: absolute;
    display: block;
    width: 0.8rem;
    height: 0.4rem; }
    .tooltip .arrow::before {
      position: absolute;
      content: "";
      border-color: transparent;
      border-style: solid; }

.bs-tooltip-top, .bs-tooltip-auto[x-placement^="top"] {
  padding: 0.4rem 0; }
  .bs-tooltip-top .arrow, .bs-tooltip-auto[x-placement^="top"] .arrow {
    bottom: 0; }
    .bs-tooltip-top .arrow::before, .bs-tooltip-auto[x-placement^="top"] .arrow::before {
      top: 0;
      border-width: 0.4rem 0.4rem 0;
      border-top-color: #000; }

.bs-tooltip-right, .bs-tooltip-auto[x-placement^="right"] {
  padding: 0 0.4rem; }
  .bs-tooltip-right .arrow, .bs-tooltip-auto[x-placement^="right"] .arrow {
    left: 0;
    width: 0.4rem;
    height: 0.8rem; }
    .bs-tooltip-right .arrow::before, .bs-tooltip-auto[x-placement^="right"] .arrow::before {
      right: 0;
      border-width: 0.4rem 0.4rem 0.4rem 0;
      border-right-color: #000; }

.bs-tooltip-bottom, .bs-tooltip-auto[x-placement^="bottom"] {
  padding: 0.4rem 0; }
  .bs-tooltip-bottom .arrow, .bs-tooltip-auto[x-placement^="bottom"] .arrow {
    top: 0; }
    .bs-tooltip-bottom .arrow::before, .bs-tooltip-auto[x-placement^="bottom"] .arrow::before {
      bottom: 0;
      border-width: 0 0.4rem 0.4rem;
      border-bottom-color: #000; }

.bs-tooltip-left, .bs-tooltip-auto[x-placement^="left"] {
  padding: 0 0.4rem; }
  .bs-tooltip-left .arrow, .bs-tooltip-auto[x-placement^="left"] .arrow {
    right: 0;
    width: 0.4rem;
    height: 0.8rem; }
    .bs-tooltip-left .arrow::before, .bs-tooltip-auto[x-placement^="left"] .arrow::before {
      left: 0;
      border-width: 0.4rem 0 0.4rem 0.4rem;
      border-left-color: #000; }

.tooltip-inner {
  max-width: 200px;
  padding: 0.25rem 0.5rem;
  color: #fff;
  text-align: center;
  background-color: #000;
  border-radius: 0.25rem; }

.popover {
  position: absolute;
  top: 0;
  left: 0;
  z-index: 1060;
  display: block;
  max-width: 276px;
  font-family: "游ゴシック Medium", "Yu Gothic Medium", "游ゴシック体", YuGothic, "ヒラギノ角ゴ ProN W3", "Hiragino Kaku Gothic ProN", "メイリオ", Meiryo, Verdana, Roboto, "Droid Sans", sans-serif;
  font-style: normal;
  font-weight: 400;
  line-height: 1.8;
  text-align: left;
  text-align: start;
  text-decoration: none;
  text-shadow: none;
  text-transform: none;
  letter-spacing: normal;
  word-break: normal;
  white-space: normal;
  word-spacing: normal;
  line-break: auto;
  font-size: 1.4rem;
  word-wrap: break-word;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid rgba(0, 0, 0, 0.2);
  border-radius: 0.3rem; }
  .popover .arrow {
    position: absolute;
    display: block;
    width: 1rem;
    height: 0.5rem;
    margin: 0 0.3rem; }
    .popover .arrow::before, .popover .arrow::after {
      position: absolute;
      display: block;
      content: "";
      border-color: transparent;
      border-style: solid; }

.bs-popover-top, .bs-popover-auto[x-placement^="top"] {
  margin-bottom: 0.5rem; }
  .bs-popover-top > .arrow, .bs-popover-auto[x-placement^="top"] > .arrow {
    bottom: calc(-0.5rem - 1px); }
    .bs-popover-top > .arrow::before, .bs-popover-auto[x-placement^="top"] > .arrow::before {
      bottom: 0;
      border-width: 0.5rem 0.5rem 0;
      border-top-color: rgba(0, 0, 0, 0.25); }
    .bs-popover-top > .arrow::after, .bs-popover-auto[x-placement^="top"] > .arrow::after {
      bottom: 1px;
      border-width: 0.5rem 0.5rem 0;
      border-top-color: #fff; }

.bs-popover-right, .bs-popover-auto[x-placement^="right"] {
  margin-left: 0.5rem; }
  .bs-popover-right > .arrow, .bs-popover-auto[x-placement^="right"] > .arrow {
    left: calc(-0.5rem - 1px);
    width: 0.5rem;
    height: 1rem;
    margin: 0.3rem 0; }
    .bs-popover-right > .arrow::before, .bs-popover-auto[x-placement^="right"] > .arrow::before {
      left: 0;
      border-width: 0.5rem 0.5rem 0.5rem 0;
      border-right-color: rgba(0, 0, 0, 0.25); }
    .bs-popover-right > .arrow::after, .bs-popover-auto[x-placement^="right"] > .arrow::after {
      left: 1px;
      border-width: 0.5rem 0.5rem 0.5rem 0;
      border-right-color: #fff; }

.bs-popover-bottom, .bs-popover-auto[x-placement^="bottom"] {
  margin-top: 0.5rem; }
  .bs-popover-bottom > .arrow, .bs-popover-auto[x-placement^="bottom"] > .arrow {
    top: calc(-0.5rem - 1px); }
    .bs-popover-bottom > .arrow::before, .bs-popover-auto[x-placement^="bottom"] > .arrow::before {
      top: 0;
      border-width: 0 0.5rem 0.5rem 0.5rem;
      border-bottom-color: rgba(0, 0, 0, 0.25); }
    .bs-popover-bottom > .arrow::after, .bs-popover-auto[x-placement^="bottom"] > .arrow::after {
      top: 1px;
      border-width: 0 0.5rem 0.5rem 0.5rem;
      border-bottom-color: #fff; }
  .bs-popover-bottom .popover-header::before, .bs-popover-auto[x-placement^="bottom"] .popover-header::before {
    position: absolute;
    top: 0;
    left: 50%;
    display: block;
    width: 1rem;
    margin-left: -0.5rem;
    content: "";
    border-bottom: 1px solid #f7f7f7; }

.bs-popover-left, .bs-popover-auto[x-placement^="left"] {
  margin-right: 0.5rem; }
  .bs-popover-left > .arrow, .bs-popover-auto[x-placement^="left"] > .arrow {
    right: calc(-0.5rem - 1px);
    width: 0.5rem;
    height: 1rem;
    margin: 0.3rem 0; }
    .bs-popover-left > .arrow::before, .bs-popover-auto[x-placement^="left"] > .arrow::before {
      right: 0;
      border-width: 0.5rem 0 0.5rem 0.5rem;
      border-left-color: rgba(0, 0, 0, 0.25); }
    .bs-popover-left > .arrow::after, .bs-popover-auto[x-placement^="left"] > .arrow::after {
      right: 1px;
      border-width: 0.5rem 0 0.5rem 0.5rem;
      border-left-color: #fff; }

.popover-header {
  padding: 0.5rem 0.75rem;
  margin-bottom: 0;
  font-size: 1.6rem;
  background-color: #f7f7f7;
  border-bottom: 1px solid #ebebeb;
  border-top-left-radius: calc(0.3rem - 1px);
  border-top-right-radius: calc(0.3rem - 1px); }
  .popover-header:empty {
    display: none; }

.popover-body {
  padding: 0.5rem 0.75rem;
  color: #212529; }

.carousel {
  position: relative; }

.carousel.pointer-event {
  touch-action: pan-y; }

.carousel-inner {
  position: relative;
  width: 100%;
  overflow: hidden; }
  .carousel-inner::after {
    display: block;
    clear: both;
    content: ""; }

.carousel-item {
  position: relative;
  display: none;
  float: left;
  width: 100%;
  margin-right: -100%;
  backface-visibility: hidden;
  transition: transform 0.6s ease-in-out; }
  @media (prefers-reduced-motion: reduce) {
    .carousel-item {
      transition: none; } }

.carousel-item.active,
.carousel-item-next,
.carousel-item-prev {
  display: block; }

.carousel-item-next:not(.carousel-item-left),
.active.carousel-item-right {
  transform: translateX(100%); }

.carousel-item-prev:not(.carousel-item-right),
.active.carousel-item-left {
  transform: translateX(-100%); }

.carousel-fade .carousel-item {
  opacity: 0;
  transition-property: opacity;
  transform: none; }

.carousel-fade .carousel-item.active,
.carousel-fade .carousel-item-next.carousel-item-left,
.carousel-fade .carousel-item-prev.carousel-item-right {
  z-index: 1;
  opacity: 1; }

.carousel-fade .active.carousel-item-left,
.carousel-fade .active.carousel-item-right {
  z-index: 0;
  opacity: 0;
  transition: opacity 0s 0.6s; }
  @media (prefers-reduced-motion: reduce) {
    .carousel-fade .active.carousel-item-left,
    .carousel-fade .active.carousel-item-right {
      transition: none; } }

.carousel-control-prev,
.carousel-control-next {
  position: absolute;
  top: 0;
  bottom: 0;
  z-index: 1;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 15%;
  padding: 0;
  color: #fff;
  text-align: center;
  background: none;
  border: 0;
  opacity: 0.5;
  transition: opacity 0.15s ease; }
  @media (prefers-reduced-motion: reduce) {
    .carousel-control-prev,
    .carousel-control-next {
      transition: none; } }
  .carousel-control-prev:hover, .carousel-control-prev:focus,
  .carousel-control-next:hover,
  .carousel-control-next:focus {
    color: #fff;
    text-decoration: none;
    outline: 0;
    opacity: 0.9; }

.carousel-control-prev {
  left: 0; }

.carousel-control-next {
  right: 0; }

.carousel-control-prev-icon,
.carousel-control-next-icon {
  display: inline-block;
  width: 20px;
  height: 20px;
  background: 50% / 100% 100% no-repeat; }

.carousel-control-prev-icon {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' fill='%23fff' width='8' height='8' viewBox='0 0 8 8'%3e%3cpath d='M5.25 0l-4 4 4 4 1.5-1.5L4.25 4l2.5-2.5L5.25 0z'/%3e%3c/svg%3e"); }

.carousel-control-next-icon {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' fill='%23fff' width='8' height='8' viewBox='0 0 8 8'%3e%3cpath d='M2.75 0l-1.5 1.5L3.75 4l-2.5 2.5L2.75 8l4-4-4-4z'/%3e%3c/svg%3e"); }

.carousel-indicators {
  position: absolute;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 15;
  display: flex;
  justify-content: center;
  padding-left: 0;
  margin-right: 15%;
  margin-left: 15%;
  list-style: none; }
  .carousel-indicators li {
    box-sizing: content-box;
    flex: 0 1 auto;
    width: 30px;
    height: 3px;
    margin-right: 3px;
    margin-left: 3px;
    text-indent: -999px;
    cursor: pointer;
    background-color: #fff;
    background-clip: padding-box;
    border-top: 10px solid transparent;
    border-bottom: 10px solid transparent;
    opacity: .5;
    transition: opacity 0.6s ease; }
    @media (prefers-reduced-motion: reduce) {
      .carousel-indicators li {
        transition: none; } }
  .carousel-indicators .active {
    opacity: 1; }

.carousel-caption {
  position: absolute;
  right: 15%;
  bottom: 20px;
  left: 15%;
  z-index: 10;
  padding-top: 20px;
  padding-bottom: 20px;
  color: #fff;
  text-align: center; }

.align-baseline {
  vertical-align: baseline !important; }

.align-top {
  vertical-align: top !important; }

.align-middle {
  vertical-align: middle !important; }

.align-bottom {
  vertical-align: bottom !important; }

.align-text-bottom {
  vertical-align: text-bottom !important; }

.align-text-top {
  vertical-align: text-top !important; }

.bg-primary {
  background-color: #007bff !important; }

a.bg-primary:hover, a.bg-primary:focus,
button.bg-primary:hover,
button.bg-primary:focus {
  background-color: #0062cc !important; }

.bg-secondary {
  background-color: #6c757d !important; }

a.bg-secondary:hover, a.bg-secondary:focus,
button.bg-secondary:hover,
button.bg-secondary:focus {
  background-color: #545b62 !important; }

.bg-success {
  background-color: #28a745 !important; }

a.bg-success:hover, a.bg-success:focus,
button.bg-success:hover,
button.bg-success:focus {
  background-color: #1e7e34 !important; }

.bg-info {
  background-color: #17a2b8 !important; }

a.bg-info:hover, a.bg-info:focus,
button.bg-info:hover,
button.bg-info:focus {
  background-color: #117a8b !important; }

.bg-warning {
  background-color: #ffc107 !important; }

a.bg-warning:hover, a.bg-warning:focus,
button.bg-warning:hover,
button.bg-warning:focus {
  background-color: #d39e00 !important; }

.bg-danger {
  background-color: #dc3545 !important; }

a.bg-danger:hover, a.bg-danger:focus,
button.bg-danger:hover,
button.bg-danger:focus {
  background-color: #bd2130 !important; }

.bg-light {
  background-color: #f8f9fa !important; }

a.bg-light:hover, a.bg-light:focus,
button.bg-light:hover,
button.bg-light:focus {
  background-color: #dae0e5 !important; }

.bg-dark {
  background-color: #343a40 !important; }

a.bg-dark:hover, a.bg-dark:focus,
button.bg-dark:hover,
button.bg-dark:focus {
  background-color: #1d2124 !important; }

.bg-white {
  background-color: #fff !important; }

.bg-transparent {
  background-color: transparent !important; }

.border {
  border: 1px solid #dee2e6 !important; }

.border-top {
  border-top: 1px solid #dee2e6 !important; }

.border-right {
  border-right: 1px solid #dee2e6 !important; }

.border-bottom {
  border-bottom: 1px solid #dee2e6 !important; }

.border-left {
  border-left: 1px solid #dee2e6 !important; }

.border-0 {
  border: 0 !important; }

.border-top-0 {
  border-top: 0 !important; }

.border-right-0 {
  border-right: 0 !important; }

.border-bottom-0 {
  border-bottom: 0 !important; }

.border-left-0 {
  border-left: 0 !important; }

.border-primary {
  border-color: #007bff !important; }

.border-secondary {
  border-color: #6c757d !important; }

.border-success {
  border-color: #28a745 !important; }

.border-info {
  border-color: #17a2b8 !important; }

.border-warning {
  border-color: #ffc107 !important; }

.border-danger {
  border-color: #dc3545 !important; }

.border-light {
  border-color: #f8f9fa !important; }

.border-dark {
  border-color: #343a40 !important; }

.border-white {
  border-color: #fff !important; }

.rounded-sm {
  border-radius: 0.2rem !important; }

.rounded {
  border-radius: 0.25rem !important; }

.rounded-top {
  border-top-left-radius: 0.25rem !important;
  border-top-right-radius: 0.25rem !important; }

.rounded-right {
  border-top-right-radius: 0.25rem !important;
  border-bottom-right-radius: 0.25rem !important; }

.rounded-bottom {
  border-bottom-right-radius: 0.25rem !important;
  border-bottom-left-radius: 0.25rem !important; }

.rounded-left {
  border-top-left-radius: 0.25rem !important;
  border-bottom-left-radius: 0.25rem !important; }

.rounded-lg {
  border-radius: 0.3rem !important; }

.rounded-circle {
  border-radius: 50% !important; }

.rounded-pill {
  border-radius: 50rem !important; }

.rounded-0 {
  border-radius: 0 !important; }

.clearfix::after {
  display: block;
  clear: both;
  content: ""; }

.d-none {
  display: none !important; }

.d-inline {
  display: inline !important; }

.d-inline-block {
  display: inline-block !important; }

.d-block {
  display: block !important; }

.d-table {
  display: table !important; }

.d-table-row {
  display: table-row !important; }

.d-table-cell {
  display: table-cell !important; }

.d-flex {
  display: flex !important; }

.d-inline-flex {
  display: inline-flex !important; }

@media (min-width: 576px) {
  .d-sm-none {
    display: none !important; }
  .d-sm-inline {
    display: inline !important; }
  .d-sm-inline-block {
    display: inline-block !important; }
  .d-sm-block {
    display: block !important; }
  .d-sm-table {
    display: table !important; }
  .d-sm-table-row {
    display: table-row !important; }
  .d-sm-table-cell {
    display: table-cell !important; }
  .d-sm-flex {
    display: flex !important; }
  .d-sm-inline-flex {
    display: inline-flex !important; } }

@media (min-width: 768px) {
  .d-md-none {
    display: none !important; }
  .d-md-inline {
    display: inline !important; }
  .d-md-inline-block {
    display: inline-block !important; }
  .d-md-block {
    display: block !important; }
  .d-md-table {
    display: table !important; }
  .d-md-table-row {
    display: table-row !important; }
  .d-md-table-cell {
    display: table-cell !important; }
  .d-md-flex {
    display: flex !important; }
  .d-md-inline-flex {
    display: inline-flex !important; } }

@media (min-width: 1080px) {
  .d-lg-none {
    display: none !important; }
  .d-lg-inline {
    display: inline !important; }
  .d-lg-inline-block {
    display: inline-block !important; }
  .d-lg-block {
    display: block !important; }
  .d-lg-table {
    display: table !important; }
  .d-lg-table-row {
    display: table-row !important; }
  .d-lg-table-cell {
    display: table-cell !important; }
  .d-lg-flex {
    display: flex !important; }
  .d-lg-inline-flex {
    display: inline-flex !important; } }

@media (min-width: 1280px) {
  .d-llg-none {
    display: none !important; }
  .d-llg-inline {
    display: inline !important; }
  .d-llg-inline-block {
    display: inline-block !important; }
  .d-llg-block {
    display: block !important; }
  .d-llg-table {
    display: table !important; }
  .d-llg-table-row {
    display: table-row !important; }
  .d-llg-table-cell {
    display: table-cell !important; }
  .d-llg-flex {
    display: flex !important; }
  .d-llg-inline-flex {
    display: inline-flex !important; } }

@media (min-width: 1400px) {
  .d-xl-none {
    display: none !important; }
  .d-xl-inline {
    display: inline !important; }
  .d-xl-inline-block {
    display: inline-block !important; }
  .d-xl-block {
    display: block !important; }
  .d-xl-table {
    display: table !important; }
  .d-xl-table-row {
    display: table-row !important; }
  .d-xl-table-cell {
    display: table-cell !important; }
  .d-xl-flex {
    display: flex !important; }
  .d-xl-inline-flex {
    display: inline-flex !important; } }

@media (min-width: 1980px) {
  .d-xxl-none {
    display: none !important; }
  .d-xxl-inline {
    display: inline !important; }
  .d-xxl-inline-block {
    display: inline-block !important; }
  .d-xxl-block {
    display: block !important; }
  .d-xxl-table {
    display: table !important; }
  .d-xxl-table-row {
    display: table-row !important; }
  .d-xxl-table-cell {
    display: table-cell !important; }
  .d-xxl-flex {
    display: flex !important; }
  .d-xxl-inline-flex {
    display: inline-flex !important; } }

@media print {
  .d-print-none {
    display: none !important; }
  .d-print-inline {
    display: inline !important; }
  .d-print-inline-block {
    display: inline-block !important; }
  .d-print-block {
    display: block !important; }
  .d-print-table {
    display: table !important; }
  .d-print-table-row {
    display: table-row !important; }
  .d-print-table-cell {
    display: table-cell !important; }
  .d-print-flex {
    display: flex !important; }
  .d-print-inline-flex {
    display: inline-flex !important; } }

.embed-responsive {
  position: relative;
  display: block;
  width: 100%;
  padding: 0;
  overflow: hidden; }
  .embed-responsive::before {
    display: block;
    content: ""; }
  .embed-responsive .embed-responsive-item,
  .embed-responsive iframe,
  .embed-responsive embed,
  .embed-responsive object,
  .embed-responsive video {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 100%;
    border: 0; }

.embed-responsive-21by9::before {
  padding-top: 42.85714%; }

.embed-responsive-16by9::before {
  padding-top: 56.25%; }

.embed-responsive-4by3::before {
  padding-top: 75%; }

.embed-responsive-1by1::before {
  padding-top: 100%; }

.flex-row {
  flex-direction: row !important; }

.flex-column {
  flex-direction: column !important; }

.flex-row-reverse {
  flex-direction: row-reverse !important; }

.flex-column-reverse {
  flex-direction: column-reverse !important; }

.flex-wrap {
  flex-wrap: wrap !important; }

.flex-nowrap {
  flex-wrap: nowrap !important; }

.flex-wrap-reverse {
  flex-wrap: wrap-reverse !important; }

.flex-fill {
  flex: 1 1 auto !important; }

.flex-grow-0 {
  flex-grow: 0 !important; }

.flex-grow-1 {
  flex-grow: 1 !important; }

.flex-shrink-0 {
  flex-shrink: 0 !important; }

.flex-shrink-1 {
  flex-shrink: 1 !important; }

.justify-content-start {
  justify-content: flex-start !important; }

.justify-content-end {
  justify-content: flex-end !important; }

.justify-content-center {
  justify-content: center !important; }

.justify-content-between {
  justify-content: space-between !important; }

.justify-content-around {
  justify-content: space-around !important; }

.align-items-start {
  align-items: flex-start !important; }

.align-items-end {
  align-items: flex-end !important; }

.align-items-center {
  align-items: center !important; }

.align-items-baseline {
  align-items: baseline !important; }

.align-items-stretch {
  align-items: stretch !important; }

.align-content-start {
  align-content: flex-start !important; }

.align-content-end {
  align-content: flex-end !important; }

.align-content-center {
  align-content: center !important; }

.align-content-between {
  align-content: space-between !important; }

.align-content-around {
  align-content: space-around !important; }

.align-content-stretch {
  align-content: stretch !important; }

.align-self-auto {
  align-self: auto !important; }

.align-self-start {
  align-self: flex-start !important; }

.align-self-end {
  align-self: flex-end !important; }

.align-self-center {
  align-self: center !important; }

.align-self-baseline {
  align-self: baseline !important; }

.align-self-stretch {
  align-self: stretch !important; }

@media (min-width: 576px) {
  .flex-sm-row {
    flex-direction: row !important; }
  .flex-sm-column {
    flex-direction: column !important; }
  .flex-sm-row-reverse {
    flex-direction: row-reverse !important; }
  .flex-sm-column-reverse {
    flex-direction: column-reverse !important; }
  .flex-sm-wrap {
    flex-wrap: wrap !important; }
  .flex-sm-nowrap {
    flex-wrap: nowrap !important; }
  .flex-sm-wrap-reverse {
    flex-wrap: wrap-reverse !important; }
  .flex-sm-fill {
    flex: 1 1 auto !important; }
  .flex-sm-grow-0 {
    flex-grow: 0 !important; }
  .flex-sm-grow-1 {
    flex-grow: 1 !important; }
  .flex-sm-shrink-0 {
    flex-shrink: 0 !important; }
  .flex-sm-shrink-1 {
    flex-shrink: 1 !important; }
  .justify-content-sm-start {
    justify-content: flex-start !important; }
  .justify-content-sm-end {
    justify-content: flex-end !important; }
  .justify-content-sm-center {
    justify-content: center !important; }
  .justify-content-sm-between {
    justify-content: space-between !important; }
  .justify-content-sm-around {
    justify-content: space-around !important; }
  .align-items-sm-start {
    align-items: flex-start !important; }
  .align-items-sm-end {
    align-items: flex-end !important; }
  .align-items-sm-center {
    align-items: center !important; }
  .align-items-sm-baseline {
    align-items: baseline !important; }
  .align-items-sm-stretch {
    align-items: stretch !important; }
  .align-content-sm-start {
    align-content: flex-start !important; }
  .align-content-sm-end {
    align-content: flex-end !important; }
  .align-content-sm-center {
    align-content: center !important; }
  .align-content-sm-between {
    align-content: space-between !important; }
  .align-content-sm-around {
    align-content: space-around !important; }
  .align-content-sm-stretch {
    align-content: stretch !important; }
  .align-self-sm-auto {
    align-self: auto !important; }
  .align-self-sm-start {
    align-self: flex-start !important; }
  .align-self-sm-end {
    align-self: flex-end !important; }
  .align-self-sm-center {
    align-self: center !important; }
  .align-self-sm-baseline {
    align-self: baseline !important; }
  .align-self-sm-stretch {
    align-self: stretch !important; } }

@media (min-width: 768px) {
  .flex-md-row {
    flex-direction: row !important; }
  .flex-md-column {
    flex-direction: column !important; }
  .flex-md-row-reverse {
    flex-direction: row-reverse !important; }
  .flex-md-column-reverse {
    flex-direction: column-reverse !important; }
  .flex-md-wrap {
    flex-wrap: wrap !important; }
  .flex-md-nowrap {
    flex-wrap: nowrap !important; }
  .flex-md-wrap-reverse {
    flex-wrap: wrap-reverse !important; }
  .flex-md-fill {
    flex: 1 1 auto !important; }
  .flex-md-grow-0 {
    flex-grow: 0 !important; }
  .flex-md-grow-1 {
    flex-grow: 1 !important; }
  .flex-md-shrink-0 {
    flex-shrink: 0 !important; }
  .flex-md-shrink-1 {
    flex-shrink: 1 !important; }
  .justify-content-md-start {
    justify-content: flex-start !important; }
  .justify-content-md-end {
    justify-content: flex-end !important; }
  .justify-content-md-center {
    justify-content: center !important; }
  .justify-content-md-between {
    justify-content: space-between !important; }
  .justify-content-md-around {
    justify-content: space-around !important; }
  .align-items-md-start {
    align-items: flex-start !important; }
  .align-items-md-end {
    align-items: flex-end !important; }
  .align-items-md-center {
    align-items: center !important; }
  .align-items-md-baseline {
    align-items: baseline !important; }
  .align-items-md-stretch {
    align-items: stretch !important; }
  .align-content-md-start {
    align-content: flex-start !important; }
  .align-content-md-end {
    align-content: flex-end !important; }
  .align-content-md-center {
    align-content: center !important; }
  .align-content-md-between {
    align-content: space-between !important; }
  .align-content-md-around {
    align-content: space-around !important; }
  .align-content-md-stretch {
    align-content: stretch !important; }
  .align-self-md-auto {
    align-self: auto !important; }
  .align-self-md-start {
    align-self: flex-start !important; }
  .align-self-md-end {
    align-self: flex-end !important; }
  .align-self-md-center {
    align-self: center !important; }
  .align-self-md-baseline {
    align-self: baseline !important; }
  .align-self-md-stretch {
    align-self: stretch !important; } }

@media (min-width: 1080px) {
  .flex-lg-row {
    flex-direction: row !important; }
  .flex-lg-column {
    flex-direction: column !important; }
  .flex-lg-row-reverse {
    flex-direction: row-reverse !important; }
  .flex-lg-column-reverse {
    flex-direction: column-reverse !important; }
  .flex-lg-wrap {
    flex-wrap: wrap !important; }
  .flex-lg-nowrap {
    flex-wrap: nowrap !important; }
  .flex-lg-wrap-reverse {
    flex-wrap: wrap-reverse !important; }
  .flex-lg-fill {
    flex: 1 1 auto !important; }
  .flex-lg-grow-0 {
    flex-grow: 0 !important; }
  .flex-lg-grow-1 {
    flex-grow: 1 !important; }
  .flex-lg-shrink-0 {
    flex-shrink: 0 !important; }
  .flex-lg-shrink-1 {
    flex-shrink: 1 !important; }
  .justify-content-lg-start {
    justify-content: flex-start !important; }
  .justify-content-lg-end {
    justify-content: flex-end !important; }
  .justify-content-lg-center {
    justify-content: center !important; }
  .justify-content-lg-between {
    justify-content: space-between !important; }
  .justify-content-lg-around {
    justify-content: space-around !important; }
  .align-items-lg-start {
    align-items: flex-start !important; }
  .align-items-lg-end {
    align-items: flex-end !important; }
  .align-items-lg-center {
    align-items: center !important; }
  .align-items-lg-baseline {
    align-items: baseline !important; }
  .align-items-lg-stretch {
    align-items: stretch !important; }
  .align-content-lg-start {
    align-content: flex-start !important; }
  .align-content-lg-end {
    align-content: flex-end !important; }
  .align-content-lg-center {
    align-content: center !important; }
  .align-content-lg-between {
    align-content: space-between !important; }
  .align-content-lg-around {
    align-content: space-around !important; }
  .align-content-lg-stretch {
    align-content: stretch !important; }
  .align-self-lg-auto {
    align-self: auto !important; }
  .align-self-lg-start {
    align-self: flex-start !important; }
  .align-self-lg-end {
    align-self: flex-end !important; }
  .align-self-lg-center {
    align-self: center !important; }
  .align-self-lg-baseline {
    align-self: baseline !important; }
  .align-self-lg-stretch {
    align-self: stretch !important; } }

@media (min-width: 1280px) {
  .flex-llg-row {
    flex-direction: row !important; }
  .flex-llg-column {
    flex-direction: column !important; }
  .flex-llg-row-reverse {
    flex-direction: row-reverse !important; }
  .flex-llg-column-reverse {
    flex-direction: column-reverse !important; }
  .flex-llg-wrap {
    flex-wrap: wrap !important; }
  .flex-llg-nowrap {
    flex-wrap: nowrap !important; }
  .flex-llg-wrap-reverse {
    flex-wrap: wrap-reverse !important; }
  .flex-llg-fill {
    flex: 1 1 auto !important; }
  .flex-llg-grow-0 {
    flex-grow: 0 !important; }
  .flex-llg-grow-1 {
    flex-grow: 1 !important; }
  .flex-llg-shrink-0 {
    flex-shrink: 0 !important; }
  .flex-llg-shrink-1 {
    flex-shrink: 1 !important; }
  .justify-content-llg-start {
    justify-content: flex-start !important; }
  .justify-content-llg-end {
    justify-content: flex-end !important; }
  .justify-content-llg-center {
    justify-content: center !important; }
  .justify-content-llg-between {
    justify-content: space-between !important; }
  .justify-content-llg-around {
    justify-content: space-around !important; }
  .align-items-llg-start {
    align-items: flex-start !important; }
  .align-items-llg-end {
    align-items: flex-end !important; }
  .align-items-llg-center {
    align-items: center !important; }
  .align-items-llg-baseline {
    align-items: baseline !important; }
  .align-items-llg-stretch {
    align-items: stretch !important; }
  .align-content-llg-start {
    align-content: flex-start !important; }
  .align-content-llg-end {
    align-content: flex-end !important; }
  .align-content-llg-center {
    align-content: center !important; }
  .align-content-llg-between {
    align-content: space-between !important; }
  .align-content-llg-around {
    align-content: space-around !important; }
  .align-content-llg-stretch {
    align-content: stretch !important; }
  .align-self-llg-auto {
    align-self: auto !important; }
  .align-self-llg-start {
    align-self: flex-start !important; }
  .align-self-llg-end {
    align-self: flex-end !important; }
  .align-self-llg-center {
    align-self: center !important; }
  .align-self-llg-baseline {
    align-self: baseline !important; }
  .align-self-llg-stretch {
    align-self: stretch !important; } }

@media (min-width: 1400px) {
  .flex-xl-row {
    flex-direction: row !important; }
  .flex-xl-column {
    flex-direction: column !important; }
  .flex-xl-row-reverse {
    flex-direction: row-reverse !important; }
  .flex-xl-column-reverse {
    flex-direction: column-reverse !important; }
  .flex-xl-wrap {
    flex-wrap: wrap !important; }
  .flex-xl-nowrap {
    flex-wrap: nowrap !important; }
  .flex-xl-wrap-reverse {
    flex-wrap: wrap-reverse !important; }
  .flex-xl-fill {
    flex: 1 1 auto !important; }
  .flex-xl-grow-0 {
    flex-grow: 0 !important; }
  .flex-xl-grow-1 {
    flex-grow: 1 !important; }
  .flex-xl-shrink-0 {
    flex-shrink: 0 !important; }
  .flex-xl-shrink-1 {
    flex-shrink: 1 !important; }
  .justify-content-xl-start {
    justify-content: flex-start !important; }
  .justify-content-xl-end {
    justify-content: flex-end !important; }
  .justify-content-xl-center {
    justify-content: center !important; }
  .justify-content-xl-between {
    justify-content: space-between !important; }
  .justify-content-xl-around {
    justify-content: space-around !important; }
  .align-items-xl-start {
    align-items: flex-start !important; }
  .align-items-xl-end {
    align-items: flex-end !important; }
  .align-items-xl-center {
    align-items: center !important; }
  .align-items-xl-baseline {
    align-items: baseline !important; }
  .align-items-xl-stretch {
    align-items: stretch !important; }
  .align-content-xl-start {
    align-content: flex-start !important; }
  .align-content-xl-end {
    align-content: flex-end !important; }
  .align-content-xl-center {
    align-content: center !important; }
  .align-content-xl-between {
    align-content: space-between !important; }
  .align-content-xl-around {
    align-content: space-around !important; }
  .align-content-xl-stretch {
    align-content: stretch !important; }
  .align-self-xl-auto {
    align-self: auto !important; }
  .align-self-xl-start {
    align-self: flex-start !important; }
  .align-self-xl-end {
    align-self: flex-end !important; }
  .align-self-xl-center {
    align-self: center !important; }
  .align-self-xl-baseline {
    align-self: baseline !important; }
  .align-self-xl-stretch {
    align-self: stretch !important; } }

@media (min-width: 1980px) {
  .flex-xxl-row {
    flex-direction: row !important; }
  .flex-xxl-column {
    flex-direction: column !important; }
  .flex-xxl-row-reverse {
    flex-direction: row-reverse !important; }
  .flex-xxl-column-reverse {
    flex-direction: column-reverse !important; }
  .flex-xxl-wrap {
    flex-wrap: wrap !important; }
  .flex-xxl-nowrap {
    flex-wrap: nowrap !important; }
  .flex-xxl-wrap-reverse {
    flex-wrap: wrap-reverse !important; }
  .flex-xxl-fill {
    flex: 1 1 auto !important; }
  .flex-xxl-grow-0 {
    flex-grow: 0 !important; }
  .flex-xxl-grow-1 {
    flex-grow: 1 !important; }
  .flex-xxl-shrink-0 {
    flex-shrink: 0 !important; }
  .flex-xxl-shrink-1 {
    flex-shrink: 1 !important; }
  .justify-content-xxl-start {
    justify-content: flex-start !important; }
  .justify-content-xxl-end {
    justify-content: flex-end !important; }
  .justify-content-xxl-center {
    justify-content: center !important; }
  .justify-content-xxl-between {
    justify-content: space-between !important; }
  .justify-content-xxl-around {
    justify-content: space-around !important; }
  .align-items-xxl-start {
    align-items: flex-start !important; }
  .align-items-xxl-end {
    align-items: flex-end !important; }
  .align-items-xxl-center {
    align-items: center !important; }
  .align-items-xxl-baseline {
    align-items: baseline !important; }
  .align-items-xxl-stretch {
    align-items: stretch !important; }
  .align-content-xxl-start {
    align-content: flex-start !important; }
  .align-content-xxl-end {
    align-content: flex-end !important; }
  .align-content-xxl-center {
    align-content: center !important; }
  .align-content-xxl-between {
    align-content: space-between !important; }
  .align-content-xxl-around {
    align-content: space-around !important; }
  .align-content-xxl-stretch {
    align-content: stretch !important; }
  .align-self-xxl-auto {
    align-self: auto !important; }
  .align-self-xxl-start {
    align-self: flex-start !important; }
  .align-self-xxl-end {
    align-self: flex-end !important; }
  .align-self-xxl-center {
    align-self: center !important; }
  .align-self-xxl-baseline {
    align-self: baseline !important; }
  .align-self-xxl-stretch {
    align-self: stretch !important; } }

.float-left {
  float: left !important; }

.float-right {
  float: right !important; }

.float-none {
  float: none !important; }

@media (min-width: 576px) {
  .float-sm-left {
    float: left !important; }
  .float-sm-right {
    float: right !important; }
  .float-sm-none {
    float: none !important; } }

@media (min-width: 768px) {
  .float-md-left {
    float: left !important; }
  .float-md-right {
    float: right !important; }
  .float-md-none {
    float: none !important; } }

@media (min-width: 1080px) {
  .float-lg-left {
    float: left !important; }
  .float-lg-right {
    float: right !important; }
  .float-lg-none {
    float: none !important; } }

@media (min-width: 1280px) {
  .float-llg-left {
    float: left !important; }
  .float-llg-right {
    float: right !important; }
  .float-llg-none {
    float: none !important; } }

@media (min-width: 1400px) {
  .float-xl-left {
    float: left !important; }
  .float-xl-right {
    float: right !important; }
  .float-xl-none {
    float: none !important; } }

@media (min-width: 1980px) {
  .float-xxl-left {
    float: left !important; }
  .float-xxl-right {
    float: right !important; }
  .float-xxl-none {
    float: none !important; } }

.user-select-all {
  user-select: all !important; }

.user-select-auto {
  user-select: auto !important; }

.user-select-none {
  user-select: none !important; }

.overflow-auto {
  overflow: auto !important; }

.overflow-hidden {
  overflow: hidden !important; }

.overflow-visible {
  overflow: visible !important; }

.position-static {
  position: static !important; }

.position-relative {
  position: relative !important; }

.position-absolute {
  position: absolute !important; }

.position-fixed {
  position: fixed !important; }

.position-sticky {
  position: sticky !important; }

.fixed-top {
  position: fixed;
  top: 0;
  right: 0;
  left: 0;
  z-index: 1030; }

.fixed-bottom {
  position: fixed;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1030; }

@supports (position: sticky) {
  .sticky-top {
    position: sticky;
    top: 0;
    z-index: 1020; } }

.sr-only {
  position: absolute;
  width: 1px;
  height: 1px;
  padding: 0;
  margin: -1px;
  overflow: hidden;
  clip: rect(0, 0, 0, 0);
  white-space: nowrap;
  border: 0; }

.sr-only-focusable:active, .sr-only-focusable:focus {
  position: static;
  width: auto;
  height: auto;
  overflow: visible;
  clip: auto;
  white-space: normal; }

.shadow-sm {
  box-shadow: 0 0.125rem 0.25rem rgba(0, 0, 0, 0.075) !important; }

.shadow {
  box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.15) !important; }

.shadow-lg {
  box-shadow: 0 1rem 3rem rgba(0, 0, 0, 0.175) !important; }

.shadow-none {
  box-shadow: none !important; }

.w-25 {
  width: 25% !important; }

.w-50 {
  width: 50% !important; }

.w-75 {
  width: 75% !important; }

.w-100 {
  width: 100% !important; }

.w-auto {
  width: auto !important; }

.w-5 {
  width: 5% !important; }

.w-10 {
  width: 10% !important; }

.w-15 {
  width: 15% !important; }

.w-20 {
  width: 20% !important; }

.w-30 {
  width: 30% !important; }

.w-35 {
  width: 35% !important; }

.w-40 {
  width: 40% !important; }

.w-45 {
  width: 45% !important; }

.w-60 {
  width: 60% !important; }

.w-70 {
  width: 70% !important; }

.w-80 {
  width: 80% !important; }

.w-90 {
  width: 90% !important; }

.h-25 {
  height: 25% !important; }

.h-50 {
  height: 50% !important; }

.h-75 {
  height: 75% !important; }

.h-100 {
  height: 100% !important; }

.h-auto {
  height: auto !important; }

.h-5 {
  height: 5% !important; }

.h-10 {
  height: 10% !important; }

.h-15 {
  height: 15% !important; }

.h-20 {
  height: 20% !important; }

.h-30 {
  height: 30% !important; }

.h-35 {
  height: 35% !important; }

.h-40 {
  height: 40% !important; }

.h-45 {
  height: 45% !important; }

.h-60 {
  height: 60% !important; }

.h-70 {
  height: 70% !important; }

.h-80 {
  height: 80% !important; }

.h-90 {
  height: 90% !important; }

.mw-100 {
  max-width: 100% !important; }

.mh-100 {
  max-height: 100% !important; }

.min-vw-100 {
  min-width: 100vw !important; }

.min-vh-100 {
  min-height: 100vh !important; }

.vw-100 {
  width: 100vw !important; }

.vh-100 {
  height: 100vh !important; }

.m-0 {
  margin: 0 !important; }

.mt-0,
.my-0 {
  margin-top: 0 !important; }

.mr-0,
.mx-0 {
  margin-right: 0 !important; }

.mb-0,
.my-0 {
  margin-bottom: 0 !important; }

.ml-0,
.mx-0 {
  margin-left: 0 !important; }

.m-1 {
  margin: 0.25rem !important; }

.mt-1,
.my-1 {
  margin-top: 0.25rem !important; }

.mr-1,
.mx-1 {
  margin-right: 0.25rem !important; }

.mb-1,
.my-1 {
  margin-bottom: 0.25rem !important; }

.ml-1,
.mx-1 {
  margin-left: 0.25rem !important; }

.m-2 {
  margin: 0.5rem !important; }

.mt-2,
.my-2 {
  margin-top: 0.5rem !important; }

.mr-2,
.mx-2 {
  margin-right: 0.5rem !important; }

.mb-2,
.my-2 {
  margin-bottom: 0.5rem !important; }

.ml-2,
.mx-2 {
  margin-left: 0.5rem !important; }

.m-3 {
  margin: 1rem !important; }

.mt-3,
.my-3 {
  margin-top: 1rem !important; }

.mr-3,
.mx-3 {
  margin-right: 1rem !important; }

.mb-3,
.my-3 {
  margin-bottom: 1rem !important; }

.ml-3,
.mx-3 {
  margin-left: 1rem !important; }

.m-4 {
  margin: 1.5rem !important; }

.mt-4,
.my-4 {
  margin-top: 1.5rem !important; }

.mr-4,
.mx-4 {
  margin-right: 1.5rem !important; }

.mb-4,
.my-4 {
  margin-bottom: 1.5rem !important; }

.ml-4,
.mx-4 {
  margin-left: 1.5rem !important; }

.m-5 {
  margin: 3rem !important; }

.mt-5,
.my-5 {
  margin-top: 3rem !important; }

.mr-5,
.mx-5 {
  margin-right: 3rem !important; }

.mb-5,
.my-5 {
  margin-bottom: 3rem !important; }

.ml-5,
.mx-5 {
  margin-left: 3rem !important; }

.p-0 {
  padding: 0 !important; }

.pt-0,
.py-0 {
  padding-top: 0 !important; }

.pr-0,
.px-0 {
  padding-right: 0 !important; }

.pb-0,
.py-0 {
  padding-bottom: 0 !important; }

.pl-0,
.px-0 {
  padding-left: 0 !important; }

.p-1 {
  padding: 0.25rem !important; }

.pt-1,
.py-1 {
  padding-top: 0.25rem !important; }

.pr-1,
.px-1 {
  padding-right: 0.25rem !important; }

.pb-1,
.py-1 {
  padding-bottom: 0.25rem !important; }

.pl-1,
.px-1 {
  padding-left: 0.25rem !important; }

.p-2 {
  padding: 0.5rem !important; }

.pt-2,
.py-2 {
  padding-top: 0.5rem !important; }

.pr-2,
.px-2 {
  padding-right: 0.5rem !important; }

.pb-2,
.py-2 {
  padding-bottom: 0.5rem !important; }

.pl-2,
.px-2 {
  padding-left: 0.5rem !important; }

.p-3 {
  padding: 1rem !important; }

.pt-3,
.py-3 {
  padding-top: 1rem !important; }

.pr-3,
.px-3 {
  padding-right: 1rem !important; }

.pb-3,
.py-3 {
  padding-bottom: 1rem !important; }

.pl-3,
.px-3 {
  padding-left: 1rem !important; }

.p-4 {
  padding: 1.5rem !important; }

.pt-4,
.py-4 {
  padding-top: 1.5rem !important; }

.pr-4,
.px-4 {
  padding-right: 1.5rem !important; }

.pb-4,
.py-4 {
  padding-bottom: 1.5rem !important; }

.pl-4,
.px-4 {
  padding-left: 1.5rem !important; }

.p-5 {
  padding: 3rem !important; }

.pt-5,
.py-5 {
  padding-top: 3rem !important; }

.pr-5,
.px-5 {
  padding-right: 3rem !important; }

.pb-5,
.py-5 {
  padding-bottom: 3rem !important; }

.pl-5,
.px-5 {
  padding-left: 3rem !important; }

.m-n1 {
  margin: -0.25rem !important; }

.mt-n1,
.my-n1 {
  margin-top: -0.25rem !important; }

.mr-n1,
.mx-n1 {
  margin-right: -0.25rem !important; }

.mb-n1,
.my-n1 {
  margin-bottom: -0.25rem !important; }

.ml-n1,
.mx-n1 {
  margin-left: -0.25rem !important; }

.m-n2 {
  margin: -0.5rem !important; }

.mt-n2,
.my-n2 {
  margin-top: -0.5rem !important; }

.mr-n2,
.mx-n2 {
  margin-right: -0.5rem !important; }

.mb-n2,
.my-n2 {
  margin-bottom: -0.5rem !important; }

.ml-n2,
.mx-n2 {
  margin-left: -0.5rem !important; }

.m-n3 {
  margin: -1rem !important; }

.mt-n3,
.my-n3 {
  margin-top: -1rem !important; }

.mr-n3,
.mx-n3 {
  margin-right: -1rem !important; }

.mb-n3,
.my-n3 {
  margin-bottom: -1rem !important; }

.ml-n3,
.mx-n3 {
  margin-left: -1rem !important; }

.m-n4 {
  margin: -1.5rem !important; }

.mt-n4,
.my-n4 {
  margin-top: -1.5rem !important; }

.mr-n4,
.mx-n4 {
  margin-right: -1.5rem !important; }

.mb-n4,
.my-n4 {
  margin-bottom: -1.5rem !important; }

.ml-n4,
.mx-n4 {
  margin-left: -1.5rem !important; }

.m-n5 {
  margin: -3rem !important; }

.mt-n5,
.my-n5 {
  margin-top: -3rem !important; }

.mr-n5,
.mx-n5 {
  margin-right: -3rem !important; }

.mb-n5,
.my-n5 {
  margin-bottom: -3rem !important; }

.ml-n5,
.mx-n5 {
  margin-left: -3rem !important; }

.m-auto {
  margin: auto !important; }

.mt-auto,
.my-auto {
  margin-top: auto !important; }

.mr-auto,
.mx-auto {
  margin-right: auto !important; }

.mb-auto,
.my-auto {
  margin-bottom: auto !important; }

.ml-auto,
.mx-auto {
  margin-left: auto !important; }

@media (min-width: 576px) {
  .m-sm-0 {
    margin: 0 !important; }
  .mt-sm-0,
  .my-sm-0 {
    margin-top: 0 !important; }
  .mr-sm-0,
  .mx-sm-0 {
    margin-right: 0 !important; }
  .mb-sm-0,
  .my-sm-0 {
    margin-bottom: 0 !important; }
  .ml-sm-0,
  .mx-sm-0 {
    margin-left: 0 !important; }
  .m-sm-1 {
    margin: 0.25rem !important; }
  .mt-sm-1,
  .my-sm-1 {
    margin-top: 0.25rem !important; }
  .mr-sm-1,
  .mx-sm-1 {
    margin-right: 0.25rem !important; }
  .mb-sm-1,
  .my-sm-1 {
    margin-bottom: 0.25rem !important; }
  .ml-sm-1,
  .mx-sm-1 {
    margin-left: 0.25rem !important; }
  .m-sm-2 {
    margin: 0.5rem !important; }
  .mt-sm-2,
  .my-sm-2 {
    margin-top: 0.5rem !important; }
  .mr-sm-2,
  .mx-sm-2 {
    margin-right: 0.5rem !important; }
  .mb-sm-2,
  .my-sm-2 {
    margin-bottom: 0.5rem !important; }
  .ml-sm-2,
  .mx-sm-2 {
    margin-left: 0.5rem !important; }
  .m-sm-3 {
    margin: 1rem !important; }
  .mt-sm-3,
  .my-sm-3 {
    margin-top: 1rem !important; }
  .mr-sm-3,
  .mx-sm-3 {
    margin-right: 1rem !important; }
  .mb-sm-3,
  .my-sm-3 {
    margin-bottom: 1rem !important; }
  .ml-sm-3,
  .mx-sm-3 {
    margin-left: 1rem !important; }
  .m-sm-4 {
    margin: 1.5rem !important; }
  .mt-sm-4,
  .my-sm-4 {
    margin-top: 1.5rem !important; }
  .mr-sm-4,
  .mx-sm-4 {
    margin-right: 1.5rem !important; }
  .mb-sm-4,
  .my-sm-4 {
    margin-bottom: 1.5rem !important; }
  .ml-sm-4,
  .mx-sm-4 {
    margin-left: 1.5rem !important; }
  .m-sm-5 {
    margin: 3rem !important; }
  .mt-sm-5,
  .my-sm-5 {
    margin-top: 3rem !important; }
  .mr-sm-5,
  .mx-sm-5 {
    margin-right: 3rem !important; }
  .mb-sm-5,
  .my-sm-5 {
    margin-bottom: 3rem !important; }
  .ml-sm-5,
  .mx-sm-5 {
    margin-left: 3rem !important; }
  .p-sm-0 {
    padding: 0 !important; }
  .pt-sm-0,
  .py-sm-0 {
    padding-top: 0 !important; }
  .pr-sm-0,
  .px-sm-0 {
    padding-right: 0 !important; }
  .pb-sm-0,
  .py-sm-0 {
    padding-bottom: 0 !important; }
  .pl-sm-0,
  .px-sm-0 {
    padding-left: 0 !important; }
  .p-sm-1 {
    padding: 0.25rem !important; }
  .pt-sm-1,
  .py-sm-1 {
    padding-top: 0.25rem !important; }
  .pr-sm-1,
  .px-sm-1 {
    padding-right: 0.25rem !important; }
  .pb-sm-1,
  .py-sm-1 {
    padding-bottom: 0.25rem !important; }
  .pl-sm-1,
  .px-sm-1 {
    padding-left: 0.25rem !important; }
  .p-sm-2 {
    padding: 0.5rem !important; }
  .pt-sm-2,
  .py-sm-2 {
    padding-top: 0.5rem !important; }
  .pr-sm-2,
  .px-sm-2 {
    padding-right: 0.5rem !important; }
  .pb-sm-2,
  .py-sm-2 {
    padding-bottom: 0.5rem !important; }
  .pl-sm-2,
  .px-sm-2 {
    padding-left: 0.5rem !important; }
  .p-sm-3 {
    padding: 1rem !important; }
  .pt-sm-3,
  .py-sm-3 {
    padding-top: 1rem !important; }
  .pr-sm-3,
  .px-sm-3 {
    padding-right: 1rem !important; }
  .pb-sm-3,
  .py-sm-3 {
    padding-bottom: 1rem !important; }
  .pl-sm-3,
  .px-sm-3 {
    padding-left: 1rem !important; }
  .p-sm-4 {
    padding: 1.5rem !important; }
  .pt-sm-4,
  .py-sm-4 {
    padding-top: 1.5rem !important; }
  .pr-sm-4,
  .px-sm-4 {
    padding-right: 1.5rem !important; }
  .pb-sm-4,
  .py-sm-4 {
    padding-bottom: 1.5rem !important; }
  .pl-sm-4,
  .px-sm-4 {
    padding-left: 1.5rem !important; }
  .p-sm-5 {
    padding: 3rem !important; }
  .pt-sm-5,
  .py-sm-5 {
    padding-top: 3rem !important; }
  .pr-sm-5,
  .px-sm-5 {
    padding-right: 3rem !important; }
  .pb-sm-5,
  .py-sm-5 {
    padding-bottom: 3rem !important; }
  .pl-sm-5,
  .px-sm-5 {
    padding-left: 3rem !important; }
  .m-sm-n1 {
    margin: -0.25rem !important; }
  .mt-sm-n1,
  .my-sm-n1 {
    margin-top: -0.25rem !important; }
  .mr-sm-n1,
  .mx-sm-n1 {
    margin-right: -0.25rem !important; }
  .mb-sm-n1,
  .my-sm-n1 {
    margin-bottom: -0.25rem !important; }
  .ml-sm-n1,
  .mx-sm-n1 {
    margin-left: -0.25rem !important; }
  .m-sm-n2 {
    margin: -0.5rem !important; }
  .mt-sm-n2,
  .my-sm-n2 {
    margin-top: -0.5rem !important; }
  .mr-sm-n2,
  .mx-sm-n2 {
    margin-right: -0.5rem !important; }
  .mb-sm-n2,
  .my-sm-n2 {
    margin-bottom: -0.5rem !important; }
  .ml-sm-n2,
  .mx-sm-n2 {
    margin-left: -0.5rem !important; }
  .m-sm-n3 {
    margin: -1rem !important; }
  .mt-sm-n3,
  .my-sm-n3 {
    margin-top: -1rem !important; }
  .mr-sm-n3,
  .mx-sm-n3 {
    margin-right: -1rem !important; }
  .mb-sm-n3,
  .my-sm-n3 {
    margin-bottom: -1rem !important; }
  .ml-sm-n3,
  .mx-sm-n3 {
    margin-left: -1rem !important; }
  .m-sm-n4 {
    margin: -1.5rem !important; }
  .mt-sm-n4,
  .my-sm-n4 {
    margin-top: -1.5rem !important; }
  .mr-sm-n4,
  .mx-sm-n4 {
    margin-right: -1.5rem !important; }
  .mb-sm-n4,
  .my-sm-n4 {
    margin-bottom: -1.5rem !important; }
  .ml-sm-n4,
  .mx-sm-n4 {
    margin-left: -1.5rem !important; }
  .m-sm-n5 {
    margin: -3rem !important; }
  .mt-sm-n5,
  .my-sm-n5 {
    margin-top: -3rem !important; }
  .mr-sm-n5,
  .mx-sm-n5 {
    margin-right: -3rem !important; }
  .mb-sm-n5,
  .my-sm-n5 {
    margin-bottom: -3rem !important; }
  .ml-sm-n5,
  .mx-sm-n5 {
    margin-left: -3rem !important; }
  .m-sm-auto {
    margin: auto !important; }
  .mt-sm-auto,
  .my-sm-auto {
    margin-top: auto !important; }
  .mr-sm-auto,
  .mx-sm-auto {
    margin-right: auto !important; }
  .mb-sm-auto,
  .my-sm-auto {
    margin-bottom: auto !important; }
  .ml-sm-auto,
  .mx-sm-auto {
    margin-left: auto !important; } }

@media (min-width: 768px) {
  .m-md-0 {
    margin: 0 !important; }
  .mt-md-0,
  .my-md-0 {
    margin-top: 0 !important; }
  .mr-md-0,
  .mx-md-0 {
    margin-right: 0 !important; }
  .mb-md-0,
  .my-md-0 {
    margin-bottom: 0 !important; }
  .ml-md-0,
  .mx-md-0 {
    margin-left: 0 !important; }
  .m-md-1 {
    margin: 0.25rem !important; }
  .mt-md-1,
  .my-md-1 {
    margin-top: 0.25rem !important; }
  .mr-md-1,
  .mx-md-1 {
    margin-right: 0.25rem !important; }
  .mb-md-1,
  .my-md-1 {
    margin-bottom: 0.25rem !important; }
  .ml-md-1,
  .mx-md-1 {
    margin-left: 0.25rem !important; }
  .m-md-2 {
    margin: 0.5rem !important; }
  .mt-md-2,
  .my-md-2 {
    margin-top: 0.5rem !important; }
  .mr-md-2,
  .mx-md-2 {
    margin-right: 0.5rem !important; }
  .mb-md-2,
  .my-md-2 {
    margin-bottom: 0.5rem !important; }
  .ml-md-2,
  .mx-md-2 {
    margin-left: 0.5rem !important; }
  .m-md-3 {
    margin: 1rem !important; }
  .mt-md-3,
  .my-md-3 {
    margin-top: 1rem !important; }
  .mr-md-3,
  .mx-md-3 {
    margin-right: 1rem !important; }
  .mb-md-3,
  .my-md-3 {
    margin-bottom: 1rem !important; }
  .ml-md-3,
  .mx-md-3 {
    margin-left: 1rem !important; }
  .m-md-4 {
    margin: 1.5rem !important; }
  .mt-md-4,
  .my-md-4 {
    margin-top: 1.5rem !important; }
  .mr-md-4,
  .mx-md-4 {
    margin-right: 1.5rem !important; }
  .mb-md-4,
  .my-md-4 {
    margin-bottom: 1.5rem !important; }
  .ml-md-4,
  .mx-md-4 {
    margin-left: 1.5rem !important; }
  .m-md-5 {
    margin: 3rem !important; }
  .mt-md-5,
  .my-md-5 {
    margin-top: 3rem !important; }
  .mr-md-5,
  .mx-md-5 {
    margin-right: 3rem !important; }
  .mb-md-5,
  .my-md-5 {
    margin-bottom: 3rem !important; }
  .ml-md-5,
  .mx-md-5 {
    margin-left: 3rem !important; }
  .p-md-0 {
    padding: 0 !important; }
  .pt-md-0,
  .py-md-0 {
    padding-top: 0 !important; }
  .pr-md-0,
  .px-md-0 {
    padding-right: 0 !important; }
  .pb-md-0,
  .py-md-0 {
    padding-bottom: 0 !important; }
  .pl-md-0,
  .px-md-0 {
    padding-left: 0 !important; }
  .p-md-1 {
    padding: 0.25rem !important; }
  .pt-md-1,
  .py-md-1 {
    padding-top: 0.25rem !important; }
  .pr-md-1,
  .px-md-1 {
    padding-right: 0.25rem !important; }
  .pb-md-1,
  .py-md-1 {
    padding-bottom: 0.25rem !important; }
  .pl-md-1,
  .px-md-1 {
    padding-left: 0.25rem !important; }
  .p-md-2 {
    padding: 0.5rem !important; }
  .pt-md-2,
  .py-md-2 {
    padding-top: 0.5rem !important; }
  .pr-md-2,
  .px-md-2 {
    padding-right: 0.5rem !important; }
  .pb-md-2,
  .py-md-2 {
    padding-bottom: 0.5rem !important; }
  .pl-md-2,
  .px-md-2 {
    padding-left: 0.5rem !important; }
  .p-md-3 {
    padding: 1rem !important; }
  .pt-md-3,
  .py-md-3 {
    padding-top: 1rem !important; }
  .pr-md-3,
  .px-md-3 {
    padding-right: 1rem !important; }
  .pb-md-3,
  .py-md-3 {
    padding-bottom: 1rem !important; }
  .pl-md-3,
  .px-md-3 {
    padding-left: 1rem !important; }
  .p-md-4 {
    padding: 1.5rem !important; }
  .pt-md-4,
  .py-md-4 {
    padding-top: 1.5rem !important; }
  .pr-md-4,
  .px-md-4 {
    padding-right: 1.5rem !important; }
  .pb-md-4,
  .py-md-4 {
    padding-bottom: 1.5rem !important; }
  .pl-md-4,
  .px-md-4 {
    padding-left: 1.5rem !important; }
  .p-md-5 {
    padding: 3rem !important; }
  .pt-md-5,
  .py-md-5 {
    padding-top: 3rem !important; }
  .pr-md-5,
  .px-md-5 {
    padding-right: 3rem !important; }
  .pb-md-5,
  .py-md-5 {
    padding-bottom: 3rem !important; }
  .pl-md-5,
  .px-md-5 {
    padding-left: 3rem !important; }
  .m-md-n1 {
    margin: -0.25rem !important; }
  .mt-md-n1,
  .my-md-n1 {
    margin-top: -0.25rem !important; }
  .mr-md-n1,
  .mx-md-n1 {
    margin-right: -0.25rem !important; }
  .mb-md-n1,
  .my-md-n1 {
    margin-bottom: -0.25rem !important; }
  .ml-md-n1,
  .mx-md-n1 {
    margin-left: -0.25rem !important; }
  .m-md-n2 {
    margin: -0.5rem !important; }
  .mt-md-n2,
  .my-md-n2 {
    margin-top: -0.5rem !important; }
  .mr-md-n2,
  .mx-md-n2 {
    margin-right: -0.5rem !important; }
  .mb-md-n2,
  .my-md-n2 {
    margin-bottom: -0.5rem !important; }
  .ml-md-n2,
  .mx-md-n2 {
    margin-left: -0.5rem !important; }
  .m-md-n3 {
    margin: -1rem !important; }
  .mt-md-n3,
  .my-md-n3 {
    margin-top: -1rem !important; }
  .mr-md-n3,
  .mx-md-n3 {
    margin-right: -1rem !important; }
  .mb-md-n3,
  .my-md-n3 {
    margin-bottom: -1rem !important; }
  .ml-md-n3,
  .mx-md-n3 {
    margin-left: -1rem !important; }
  .m-md-n4 {
    margin: -1.5rem !important; }
  .mt-md-n4,
  .my-md-n4 {
    margin-top: -1.5rem !important; }
  .mr-md-n4,
  .mx-md-n4 {
    margin-right: -1.5rem !important; }
  .mb-md-n4,
  .my-md-n4 {
    margin-bottom: -1.5rem !important; }
  .ml-md-n4,
  .mx-md-n4 {
    margin-left: -1.5rem !important; }
  .m-md-n5 {
    margin: -3rem !important; }
  .mt-md-n5,
  .my-md-n5 {
    margin-top: -3rem !important; }
  .mr-md-n5,
  .mx-md-n5 {
    margin-right: -3rem !important; }
  .mb-md-n5,
  .my-md-n5 {
    margin-bottom: -3rem !important; }
  .ml-md-n5,
  .mx-md-n5 {
    margin-left: -3rem !important; }
  .m-md-auto {
    margin: auto !important; }
  .mt-md-auto,
  .my-md-auto {
    margin-top: auto !important; }
  .mr-md-auto,
  .mx-md-auto {
    margin-right: auto !important; }
  .mb-md-auto,
  .my-md-auto {
    margin-bottom: auto !important; }
  .ml-md-auto,
  .mx-md-auto {
    margin-left: auto !important; } }

@media (min-width: 1080px) {
  .m-lg-0 {
    margin: 0 !important; }
  .mt-lg-0,
  .my-lg-0 {
    margin-top: 0 !important; }
  .mr-lg-0,
  .mx-lg-0 {
    margin-right: 0 !important; }
  .mb-lg-0,
  .my-lg-0 {
    margin-bottom: 0 !important; }
  .ml-lg-0,
  .mx-lg-0 {
    margin-left: 0 !important; }
  .m-lg-1 {
    margin: 0.25rem !important; }
  .mt-lg-1,
  .my-lg-1 {
    margin-top: 0.25rem !important; }
  .mr-lg-1,
  .mx-lg-1 {
    margin-right: 0.25rem !important; }
  .mb-lg-1,
  .my-lg-1 {
    margin-bottom: 0.25rem !important; }
  .ml-lg-1,
  .mx-lg-1 {
    margin-left: 0.25rem !important; }
  .m-lg-2 {
    margin: 0.5rem !important; }
  .mt-lg-2,
  .my-lg-2 {
    margin-top: 0.5rem !important; }
  .mr-lg-2,
  .mx-lg-2 {
    margin-right: 0.5rem !important; }
  .mb-lg-2,
  .my-lg-2 {
    margin-bottom: 0.5rem !important; }
  .ml-lg-2,
  .mx-lg-2 {
    margin-left: 0.5rem !important; }
  .m-lg-3 {
    margin: 1rem !important; }
  .mt-lg-3,
  .my-lg-3 {
    margin-top: 1rem !important; }
  .mr-lg-3,
  .mx-lg-3 {
    margin-right: 1rem !important; }
  .mb-lg-3,
  .my-lg-3 {
    margin-bottom: 1rem !important; }
  .ml-lg-3,
  .mx-lg-3 {
    margin-left: 1rem !important; }
  .m-lg-4 {
    margin: 1.5rem !important; }
  .mt-lg-4,
  .my-lg-4 {
    margin-top: 1.5rem !important; }
  .mr-lg-4,
  .mx-lg-4 {
    margin-right: 1.5rem !important; }
  .mb-lg-4,
  .my-lg-4 {
    margin-bottom: 1.5rem !important; }
  .ml-lg-4,
  .mx-lg-4 {
    margin-left: 1.5rem !important; }
  .m-lg-5 {
    margin: 3rem !important; }
  .mt-lg-5,
  .my-lg-5 {
    margin-top: 3rem !important; }
  .mr-lg-5,
  .mx-lg-5 {
    margin-right: 3rem !important; }
  .mb-lg-5,
  .my-lg-5 {
    margin-bottom: 3rem !important; }
  .ml-lg-5,
  .mx-lg-5 {
    margin-left: 3rem !important; }
  .p-lg-0 {
    padding: 0 !important; }
  .pt-lg-0,
  .py-lg-0 {
    padding-top: 0 !important; }
  .pr-lg-0,
  .px-lg-0 {
    padding-right: 0 !important; }
  .pb-lg-0,
  .py-lg-0 {
    padding-bottom: 0 !important; }
  .pl-lg-0,
  .px-lg-0 {
    padding-left: 0 !important; }
  .p-lg-1 {
    padding: 0.25rem !important; }
  .pt-lg-1,
  .py-lg-1 {
    padding-top: 0.25rem !important; }
  .pr-lg-1,
  .px-lg-1 {
    padding-right: 0.25rem !important; }
  .pb-lg-1,
  .py-lg-1 {
    padding-bottom: 0.25rem !important; }
  .pl-lg-1,
  .px-lg-1 {
    padding-left: 0.25rem !important; }
  .p-lg-2 {
    padding: 0.5rem !important; }
  .pt-lg-2,
  .py-lg-2 {
    padding-top: 0.5rem !important; }
  .pr-lg-2,
  .px-lg-2 {
    padding-right: 0.5rem !important; }
  .pb-lg-2,
  .py-lg-2 {
    padding-bottom: 0.5rem !important; }
  .pl-lg-2,
  .px-lg-2 {
    padding-left: 0.5rem !important; }
  .p-lg-3 {
    padding: 1rem !important; }
  .pt-lg-3,
  .py-lg-3 {
    padding-top: 1rem !important; }
  .pr-lg-3,
  .px-lg-3 {
    padding-right: 1rem !important; }
  .pb-lg-3,
  .py-lg-3 {
    padding-bottom: 1rem !important; }
  .pl-lg-3,
  .px-lg-3 {
    padding-left: 1rem !important; }
  .p-lg-4 {
    padding: 1.5rem !important; }
  .pt-lg-4,
  .py-lg-4 {
    padding-top: 1.5rem !important; }
  .pr-lg-4,
  .px-lg-4 {
    padding-right: 1.5rem !important; }
  .pb-lg-4,
  .py-lg-4 {
    padding-bottom: 1.5rem !important; }
  .pl-lg-4,
  .px-lg-4 {
    padding-left: 1.5rem !important; }
  .p-lg-5 {
    padding: 3rem !important; }
  .pt-lg-5,
  .py-lg-5 {
    padding-top: 3rem !important; }
  .pr-lg-5,
  .px-lg-5 {
    padding-right: 3rem !important; }
  .pb-lg-5,
  .py-lg-5 {
    padding-bottom: 3rem !important; }
  .pl-lg-5,
  .px-lg-5 {
    padding-left: 3rem !important; }
  .m-lg-n1 {
    margin: -0.25rem !important; }
  .mt-lg-n1,
  .my-lg-n1 {
    margin-top: -0.25rem !important; }
  .mr-lg-n1,
  .mx-lg-n1 {
    margin-right: -0.25rem !important; }
  .mb-lg-n1,
  .my-lg-n1 {
    margin-bottom: -0.25rem !important; }
  .ml-lg-n1,
  .mx-lg-n1 {
    margin-left: -0.25rem !important; }
  .m-lg-n2 {
    margin: -0.5rem !important; }
  .mt-lg-n2,
  .my-lg-n2 {
    margin-top: -0.5rem !important; }
  .mr-lg-n2,
  .mx-lg-n2 {
    margin-right: -0.5rem !important; }
  .mb-lg-n2,
  .my-lg-n2 {
    margin-bottom: -0.5rem !important; }
  .ml-lg-n2,
  .mx-lg-n2 {
    margin-left: -0.5rem !important; }
  .m-lg-n3 {
    margin: -1rem !important; }
  .mt-lg-n3,
  .my-lg-n3 {
    margin-top: -1rem !important; }
  .mr-lg-n3,
  .mx-lg-n3 {
    margin-right: -1rem !important; }
  .mb-lg-n3,
  .my-lg-n3 {
    margin-bottom: -1rem !important; }
  .ml-lg-n3,
  .mx-lg-n3 {
    margin-left: -1rem !important; }
  .m-lg-n4 {
    margin: -1.5rem !important; }
  .mt-lg-n4,
  .my-lg-n4 {
    margin-top: -1.5rem !important; }
  .mr-lg-n4,
  .mx-lg-n4 {
    margin-right: -1.5rem !important; }
  .mb-lg-n4,
  .my-lg-n4 {
    margin-bottom: -1.5rem !important; }
  .ml-lg-n4,
  .mx-lg-n4 {
    margin-left: -1.5rem !important; }
  .m-lg-n5 {
    margin: -3rem !important; }
  .mt-lg-n5,
  .my-lg-n5 {
    margin-top: -3rem !important; }
  .mr-lg-n5,
  .mx-lg-n5 {
    margin-right: -3rem !important; }
  .mb-lg-n5,
  .my-lg-n5 {
    margin-bottom: -3rem !important; }
  .ml-lg-n5,
  .mx-lg-n5 {
    margin-left: -3rem !important; }
  .m-lg-auto {
    margin: auto !important; }
  .mt-lg-auto,
  .my-lg-auto {
    margin-top: auto !important; }
  .mr-lg-auto,
  .mx-lg-auto {
    margin-right: auto !important; }
  .mb-lg-auto,
  .my-lg-auto {
    margin-bottom: auto !important; }
  .ml-lg-auto,
  .mx-lg-auto {
    margin-left: auto !important; } }

@media (min-width: 1280px) {
  .m-llg-0 {
    margin: 0 !important; }
  .mt-llg-0,
  .my-llg-0 {
    margin-top: 0 !important; }
  .mr-llg-0,
  .mx-llg-0 {
    margin-right: 0 !important; }
  .mb-llg-0,
  .my-llg-0 {
    margin-bottom: 0 !important; }
  .ml-llg-0,
  .mx-llg-0 {
    margin-left: 0 !important; }
  .m-llg-1 {
    margin: 0.25rem !important; }
  .mt-llg-1,
  .my-llg-1 {
    margin-top: 0.25rem !important; }
  .mr-llg-1,
  .mx-llg-1 {
    margin-right: 0.25rem !important; }
  .mb-llg-1,
  .my-llg-1 {
    margin-bottom: 0.25rem !important; }
  .ml-llg-1,
  .mx-llg-1 {
    margin-left: 0.25rem !important; }
  .m-llg-2 {
    margin: 0.5rem !important; }
  .mt-llg-2,
  .my-llg-2 {
    margin-top: 0.5rem !important; }
  .mr-llg-2,
  .mx-llg-2 {
    margin-right: 0.5rem !important; }
  .mb-llg-2,
  .my-llg-2 {
    margin-bottom: 0.5rem !important; }
  .ml-llg-2,
  .mx-llg-2 {
    margin-left: 0.5rem !important; }
  .m-llg-3 {
    margin: 1rem !important; }
  .mt-llg-3,
  .my-llg-3 {
    margin-top: 1rem !important; }
  .mr-llg-3,
  .mx-llg-3 {
    margin-right: 1rem !important; }
  .mb-llg-3,
  .my-llg-3 {
    margin-bottom: 1rem !important; }
  .ml-llg-3,
  .mx-llg-3 {
    margin-left: 1rem !important; }
  .m-llg-4 {
    margin: 1.5rem !important; }
  .mt-llg-4,
  .my-llg-4 {
    margin-top: 1.5rem !important; }
  .mr-llg-4,
  .mx-llg-4 {
    margin-right: 1.5rem !important; }
  .mb-llg-4,
  .my-llg-4 {
    margin-bottom: 1.5rem !important; }
  .ml-llg-4,
  .mx-llg-4 {
    margin-left: 1.5rem !important; }
  .m-llg-5 {
    margin: 3rem !important; }
  .mt-llg-5,
  .my-llg-5 {
    margin-top: 3rem !important; }
  .mr-llg-5,
  .mx-llg-5 {
    margin-right: 3rem !important; }
  .mb-llg-5,
  .my-llg-5 {
    margin-bottom: 3rem !important; }
  .ml-llg-5,
  .mx-llg-5 {
    margin-left: 3rem !important; }
  .p-llg-0 {
    padding: 0 !important; }
  .pt-llg-0,
  .py-llg-0 {
    padding-top: 0 !important; }
  .pr-llg-0,
  .px-llg-0 {
    padding-right: 0 !important; }
  .pb-llg-0,
  .py-llg-0 {
    padding-bottom: 0 !important; }
  .pl-llg-0,
  .px-llg-0 {
    padding-left: 0 !important; }
  .p-llg-1 {
    padding: 0.25rem !important; }
  .pt-llg-1,
  .py-llg-1 {
    padding-top: 0.25rem !important; }
  .pr-llg-1,
  .px-llg-1 {
    padding-right: 0.25rem !important; }
  .pb-llg-1,
  .py-llg-1 {
    padding-bottom: 0.25rem !important; }
  .pl-llg-1,
  .px-llg-1 {
    padding-left: 0.25rem !important; }
  .p-llg-2 {
    padding: 0.5rem !important; }
  .pt-llg-2,
  .py-llg-2 {
    padding-top: 0.5rem !important; }
  .pr-llg-2,
  .px-llg-2 {
    padding-right: 0.5rem !important; }
  .pb-llg-2,
  .py-llg-2 {
    padding-bottom: 0.5rem !important; }
  .pl-llg-2,
  .px-llg-2 {
    padding-left: 0.5rem !important; }
  .p-llg-3 {
    padding: 1rem !important; }
  .pt-llg-3,
  .py-llg-3 {
    padding-top: 1rem !important; }
  .pr-llg-3,
  .px-llg-3 {
    padding-right: 1rem !important; }
  .pb-llg-3,
  .py-llg-3 {
    padding-bottom: 1rem !important; }
  .pl-llg-3,
  .px-llg-3 {
    padding-left: 1rem !important; }
  .p-llg-4 {
    padding: 1.5rem !important; }
  .pt-llg-4,
  .py-llg-4 {
    padding-top: 1.5rem !important; }
  .pr-llg-4,
  .px-llg-4 {
    padding-right: 1.5rem !important; }
  .pb-llg-4,
  .py-llg-4 {
    padding-bottom: 1.5rem !important; }
  .pl-llg-4,
  .px-llg-4 {
    padding-left: 1.5rem !important; }
  .p-llg-5 {
    padding: 3rem !important; }
  .pt-llg-5,
  .py-llg-5 {
    padding-top: 3rem !important; }
  .pr-llg-5,
  .px-llg-5 {
    padding-right: 3rem !important; }
  .pb-llg-5,
  .py-llg-5 {
    padding-bottom: 3rem !important; }
  .pl-llg-5,
  .px-llg-5 {
    padding-left: 3rem !important; }
  .m-llg-n1 {
    margin: -0.25rem !important; }
  .mt-llg-n1,
  .my-llg-n1 {
    margin-top: -0.25rem !important; }
  .mr-llg-n1,
  .mx-llg-n1 {
    margin-right: -0.25rem !important; }
  .mb-llg-n1,
  .my-llg-n1 {
    margin-bottom: -0.25rem !important; }
  .ml-llg-n1,
  .mx-llg-n1 {
    margin-left: -0.25rem !important; }
  .m-llg-n2 {
    margin: -0.5rem !important; }
  .mt-llg-n2,
  .my-llg-n2 {
    margin-top: -0.5rem !important; }
  .mr-llg-n2,
  .mx-llg-n2 {
    margin-right: -0.5rem !important; }
  .mb-llg-n2,
  .my-llg-n2 {
    margin-bottom: -0.5rem !important; }
  .ml-llg-n2,
  .mx-llg-n2 {
    margin-left: -0.5rem !important; }
  .m-llg-n3 {
    margin: -1rem !important; }
  .mt-llg-n3,
  .my-llg-n3 {
    margin-top: -1rem !important; }
  .mr-llg-n3,
  .mx-llg-n3 {
    margin-right: -1rem !important; }
  .mb-llg-n3,
  .my-llg-n3 {
    margin-bottom: -1rem !important; }
  .ml-llg-n3,
  .mx-llg-n3 {
    margin-left: -1rem !important; }
  .m-llg-n4 {
    margin: -1.5rem !important; }
  .mt-llg-n4,
  .my-llg-n4 {
    margin-top: -1.5rem !important; }
  .mr-llg-n4,
  .mx-llg-n4 {
    margin-right: -1.5rem !important; }
  .mb-llg-n4,
  .my-llg-n4 {
    margin-bottom: -1.5rem !important; }
  .ml-llg-n4,
  .mx-llg-n4 {
    margin-left: -1.5rem !important; }
  .m-llg-n5 {
    margin: -3rem !important; }
  .mt-llg-n5,
  .my-llg-n5 {
    margin-top: -3rem !important; }
  .mr-llg-n5,
  .mx-llg-n5 {
    margin-right: -3rem !important; }
  .mb-llg-n5,
  .my-llg-n5 {
    margin-bottom: -3rem !important; }
  .ml-llg-n5,
  .mx-llg-n5 {
    margin-left: -3rem !important; }
  .m-llg-auto {
    margin: auto !important; }
  .mt-llg-auto,
  .my-llg-auto {
    margin-top: auto !important; }
  .mr-llg-auto,
  .mx-llg-auto {
    margin-right: auto !important; }
  .mb-llg-auto,
  .my-llg-auto {
    margin-bottom: auto !important; }
  .ml-llg-auto,
  .mx-llg-auto {
    margin-left: auto !important; } }

@media (min-width: 1400px) {
  .m-xl-0 {
    margin: 0 !important; }
  .mt-xl-0,
  .my-xl-0 {
    margin-top: 0 !important; }
  .mr-xl-0,
  .mx-xl-0 {
    margin-right: 0 !important; }
  .mb-xl-0,
  .my-xl-0 {
    margin-bottom: 0 !important; }
  .ml-xl-0,
  .mx-xl-0 {
    margin-left: 0 !important; }
  .m-xl-1 {
    margin: 0.25rem !important; }
  .mt-xl-1,
  .my-xl-1 {
    margin-top: 0.25rem !important; }
  .mr-xl-1,
  .mx-xl-1 {
    margin-right: 0.25rem !important; }
  .mb-xl-1,
  .my-xl-1 {
    margin-bottom: 0.25rem !important; }
  .ml-xl-1,
  .mx-xl-1 {
    margin-left: 0.25rem !important; }
  .m-xl-2 {
    margin: 0.5rem !important; }
  .mt-xl-2,
  .my-xl-2 {
    margin-top: 0.5rem !important; }
  .mr-xl-2,
  .mx-xl-2 {
    margin-right: 0.5rem !important; }
  .mb-xl-2,
  .my-xl-2 {
    margin-bottom: 0.5rem !important; }
  .ml-xl-2,
  .mx-xl-2 {
    margin-left: 0.5rem !important; }
  .m-xl-3 {
    margin: 1rem !important; }
  .mt-xl-3,
  .my-xl-3 {
    margin-top: 1rem !important; }
  .mr-xl-3,
  .mx-xl-3 {
    margin-right: 1rem !important; }
  .mb-xl-3,
  .my-xl-3 {
    margin-bottom: 1rem !important; }
  .ml-xl-3,
  .mx-xl-3 {
    margin-left: 1rem !important; }
  .m-xl-4 {
    margin: 1.5rem !important; }
  .mt-xl-4,
  .my-xl-4 {
    margin-top: 1.5rem !important; }
  .mr-xl-4,
  .mx-xl-4 {
    margin-right: 1.5rem !important; }
  .mb-xl-4,
  .my-xl-4 {
    margin-bottom: 1.5rem !important; }
  .ml-xl-4,
  .mx-xl-4 {
    margin-left: 1.5rem !important; }
  .m-xl-5 {
    margin: 3rem !important; }
  .mt-xl-5,
  .my-xl-5 {
    margin-top: 3rem !important; }
  .mr-xl-5,
  .mx-xl-5 {
    margin-right: 3rem !important; }
  .mb-xl-5,
  .my-xl-5 {
    margin-bottom: 3rem !important; }
  .ml-xl-5,
  .mx-xl-5 {
    margin-left: 3rem !important; }
  .p-xl-0 {
    padding: 0 !important; }
  .pt-xl-0,
  .py-xl-0 {
    padding-top: 0 !important; }
  .pr-xl-0,
  .px-xl-0 {
    padding-right: 0 !important; }
  .pb-xl-0,
  .py-xl-0 {
    padding-bottom: 0 !important; }
  .pl-xl-0,
  .px-xl-0 {
    padding-left: 0 !important; }
  .p-xl-1 {
    padding: 0.25rem !important; }
  .pt-xl-1,
  .py-xl-1 {
    padding-top: 0.25rem !important; }
  .pr-xl-1,
  .px-xl-1 {
    padding-right: 0.25rem !important; }
  .pb-xl-1,
  .py-xl-1 {
    padding-bottom: 0.25rem !important; }
  .pl-xl-1,
  .px-xl-1 {
    padding-left: 0.25rem !important; }
  .p-xl-2 {
    padding: 0.5rem !important; }
  .pt-xl-2,
  .py-xl-2 {
    padding-top: 0.5rem !important; }
  .pr-xl-2,
  .px-xl-2 {
    padding-right: 0.5rem !important; }
  .pb-xl-2,
  .py-xl-2 {
    padding-bottom: 0.5rem !important; }
  .pl-xl-2,
  .px-xl-2 {
    padding-left: 0.5rem !important; }
  .p-xl-3 {
    padding: 1rem !important; }
  .pt-xl-3,
  .py-xl-3 {
    padding-top: 1rem !important; }
  .pr-xl-3,
  .px-xl-3 {
    padding-right: 1rem !important; }
  .pb-xl-3,
  .py-xl-3 {
    padding-bottom: 1rem !important; }
  .pl-xl-3,
  .px-xl-3 {
    padding-left: 1rem !important; }
  .p-xl-4 {
    padding: 1.5rem !important; }
  .pt-xl-4,
  .py-xl-4 {
    padding-top: 1.5rem !important; }
  .pr-xl-4,
  .px-xl-4 {
    padding-right: 1.5rem !important; }
  .pb-xl-4,
  .py-xl-4 {
    padding-bottom: 1.5rem !important; }
  .pl-xl-4,
  .px-xl-4 {
    padding-left: 1.5rem !important; }
  .p-xl-5 {
    padding: 3rem !important; }
  .pt-xl-5,
  .py-xl-5 {
    padding-top: 3rem !important; }
  .pr-xl-5,
  .px-xl-5 {
    padding-right: 3rem !important; }
  .pb-xl-5,
  .py-xl-5 {
    padding-bottom: 3rem !important; }
  .pl-xl-5,
  .px-xl-5 {
    padding-left: 3rem !important; }
  .m-xl-n1 {
    margin: -0.25rem !important; }
  .mt-xl-n1,
  .my-xl-n1 {
    margin-top: -0.25rem !important; }
  .mr-xl-n1,
  .mx-xl-n1 {
    margin-right: -0.25rem !important; }
  .mb-xl-n1,
  .my-xl-n1 {
    margin-bottom: -0.25rem !important; }
  .ml-xl-n1,
  .mx-xl-n1 {
    margin-left: -0.25rem !important; }
  .m-xl-n2 {
    margin: -0.5rem !important; }
  .mt-xl-n2,
  .my-xl-n2 {
    margin-top: -0.5rem !important; }
  .mr-xl-n2,
  .mx-xl-n2 {
    margin-right: -0.5rem !important; }
  .mb-xl-n2,
  .my-xl-n2 {
    margin-bottom: -0.5rem !important; }
  .ml-xl-n2,
  .mx-xl-n2 {
    margin-left: -0.5rem !important; }
  .m-xl-n3 {
    margin: -1rem !important; }
  .mt-xl-n3,
  .my-xl-n3 {
    margin-top: -1rem !important; }
  .mr-xl-n3,
  .mx-xl-n3 {
    margin-right: -1rem !important; }
  .mb-xl-n3,
  .my-xl-n3 {
    margin-bottom: -1rem !important; }
  .ml-xl-n3,
  .mx-xl-n3 {
    margin-left: -1rem !important; }
  .m-xl-n4 {
    margin: -1.5rem !important; }
  .mt-xl-n4,
  .my-xl-n4 {
    margin-top: -1.5rem !important; }
  .mr-xl-n4,
  .mx-xl-n4 {
    margin-right: -1.5rem !important; }
  .mb-xl-n4,
  .my-xl-n4 {
    margin-bottom: -1.5rem !important; }
  .ml-xl-n4,
  .mx-xl-n4 {
    margin-left: -1.5rem !important; }
  .m-xl-n5 {
    margin: -3rem !important; }
  .mt-xl-n5,
  .my-xl-n5 {
    margin-top: -3rem !important; }
  .mr-xl-n5,
  .mx-xl-n5 {
    margin-right: -3rem !important; }
  .mb-xl-n5,
  .my-xl-n5 {
    margin-bottom: -3rem !important; }
  .ml-xl-n5,
  .mx-xl-n5 {
    margin-left: -3rem !important; }
  .m-xl-auto {
    margin: auto !important; }
  .mt-xl-auto,
  .my-xl-auto {
    margin-top: auto !important; }
  .mr-xl-auto,
  .mx-xl-auto {
    margin-right: auto !important; }
  .mb-xl-auto,
  .my-xl-auto {
    margin-bottom: auto !important; }
  .ml-xl-auto,
  .mx-xl-auto {
    margin-left: auto !important; } }

@media (min-width: 1980px) {
  .m-xxl-0 {
    margin: 0 !important; }
  .mt-xxl-0,
  .my-xxl-0 {
    margin-top: 0 !important; }
  .mr-xxl-0,
  .mx-xxl-0 {
    margin-right: 0 !important; }
  .mb-xxl-0,
  .my-xxl-0 {
    margin-bottom: 0 !important; }
  .ml-xxl-0,
  .mx-xxl-0 {
    margin-left: 0 !important; }
  .m-xxl-1 {
    margin: 0.25rem !important; }
  .mt-xxl-1,
  .my-xxl-1 {
    margin-top: 0.25rem !important; }
  .mr-xxl-1,
  .mx-xxl-1 {
    margin-right: 0.25rem !important; }
  .mb-xxl-1,
  .my-xxl-1 {
    margin-bottom: 0.25rem !important; }
  .ml-xxl-1,
  .mx-xxl-1 {
    margin-left: 0.25rem !important; }
  .m-xxl-2 {
    margin: 0.5rem !important; }
  .mt-xxl-2,
  .my-xxl-2 {
    margin-top: 0.5rem !important; }
  .mr-xxl-2,
  .mx-xxl-2 {
    margin-right: 0.5rem !important; }
  .mb-xxl-2,
  .my-xxl-2 {
    margin-bottom: 0.5rem !important; }
  .ml-xxl-2,
  .mx-xxl-2 {
    margin-left: 0.5rem !important; }
  .m-xxl-3 {
    margin: 1rem !important; }
  .mt-xxl-3,
  .my-xxl-3 {
    margin-top: 1rem !important; }
  .mr-xxl-3,
  .mx-xxl-3 {
    margin-right: 1rem !important; }
  .mb-xxl-3,
  .my-xxl-3 {
    margin-bottom: 1rem !important; }
  .ml-xxl-3,
  .mx-xxl-3 {
    margin-left: 1rem !important; }
  .m-xxl-4 {
    margin: 1.5rem !important; }
  .mt-xxl-4,
  .my-xxl-4 {
    margin-top: 1.5rem !important; }
  .mr-xxl-4,
  .mx-xxl-4 {
    margin-right: 1.5rem !important; }
  .mb-xxl-4,
  .my-xxl-4 {
    margin-bottom: 1.5rem !important; }
  .ml-xxl-4,
  .mx-xxl-4 {
    margin-left: 1.5rem !important; }
  .m-xxl-5 {
    margin: 3rem !important; }
  .mt-xxl-5,
  .my-xxl-5 {
    margin-top: 3rem !important; }
  .mr-xxl-5,
  .mx-xxl-5 {
    margin-right: 3rem !important; }
  .mb-xxl-5,
  .my-xxl-5 {
    margin-bottom: 3rem !important; }
  .ml-xxl-5,
  .mx-xxl-5 {
    margin-left: 3rem !important; }
  .p-xxl-0 {
    padding: 0 !important; }
  .pt-xxl-0,
  .py-xxl-0 {
    padding-top: 0 !important; }
  .pr-xxl-0,
  .px-xxl-0 {
    padding-right: 0 !important; }
  .pb-xxl-0,
  .py-xxl-0 {
    padding-bottom: 0 !important; }
  .pl-xxl-0,
  .px-xxl-0 {
    padding-left: 0 !important; }
  .p-xxl-1 {
    padding: 0.25rem !important; }
  .pt-xxl-1,
  .py-xxl-1 {
    padding-top: 0.25rem !important; }
  .pr-xxl-1,
  .px-xxl-1 {
    padding-right: 0.25rem !important; }
  .pb-xxl-1,
  .py-xxl-1 {
    padding-bottom: 0.25rem !important; }
  .pl-xxl-1,
  .px-xxl-1 {
    padding-left: 0.25rem !important; }
  .p-xxl-2 {
    padding: 0.5rem !important; }
  .pt-xxl-2,
  .py-xxl-2 {
    padding-top: 0.5rem !important; }
  .pr-xxl-2,
  .px-xxl-2 {
    padding-right: 0.5rem !important; }
  .pb-xxl-2,
  .py-xxl-2 {
    padding-bottom: 0.5rem !important; }
  .pl-xxl-2,
  .px-xxl-2 {
    padding-left: 0.5rem !important; }
  .p-xxl-3 {
    padding: 1rem !important; }
  .pt-xxl-3,
  .py-xxl-3 {
    padding-top: 1rem !important; }
  .pr-xxl-3,
  .px-xxl-3 {
    padding-right: 1rem !important; }
  .pb-xxl-3,
  .py-xxl-3 {
    padding-bottom: 1rem !important; }
  .pl-xxl-3,
  .px-xxl-3 {
    padding-left: 1rem !important; }
  .p-xxl-4 {
    padding: 1.5rem !important; }
  .pt-xxl-4,
  .py-xxl-4 {
    padding-top: 1.5rem !important; }
  .pr-xxl-4,
  .px-xxl-4 {
    padding-right: 1.5rem !important; }
  .pb-xxl-4,
  .py-xxl-4 {
    padding-bottom: 1.5rem !important; }
  .pl-xxl-4,
  .px-xxl-4 {
    padding-left: 1.5rem !important; }
  .p-xxl-5 {
    padding: 3rem !important; }
  .pt-xxl-5,
  .py-xxl-5 {
    padding-top: 3rem !important; }
  .pr-xxl-5,
  .px-xxl-5 {
    padding-right: 3rem !important; }
  .pb-xxl-5,
  .py-xxl-5 {
    padding-bottom: 3rem !important; }
  .pl-xxl-5,
  .px-xxl-5 {
    padding-left: 3rem !important; }
  .m-xxl-n1 {
    margin: -0.25rem !important; }
  .mt-xxl-n1,
  .my-xxl-n1 {
    margin-top: -0.25rem !important; }
  .mr-xxl-n1,
  .mx-xxl-n1 {
    margin-right: -0.25rem !important; }
  .mb-xxl-n1,
  .my-xxl-n1 {
    margin-bottom: -0.25rem !important; }
  .ml-xxl-n1,
  .mx-xxl-n1 {
    margin-left: -0.25rem !important; }
  .m-xxl-n2 {
    margin: -0.5rem !important; }
  .mt-xxl-n2,
  .my-xxl-n2 {
    margin-top: -0.5rem !important; }
  .mr-xxl-n2,
  .mx-xxl-n2 {
    margin-right: -0.5rem !important; }
  .mb-xxl-n2,
  .my-xxl-n2 {
    margin-bottom: -0.5rem !important; }
  .ml-xxl-n2,
  .mx-xxl-n2 {
    margin-left: -0.5rem !important; }
  .m-xxl-n3 {
    margin: -1rem !important; }
  .mt-xxl-n3,
  .my-xxl-n3 {
    margin-top: -1rem !important; }
  .mr-xxl-n3,
  .mx-xxl-n3 {
    margin-right: -1rem !important; }
  .mb-xxl-n3,
  .my-xxl-n3 {
    margin-bottom: -1rem !important; }
  .ml-xxl-n3,
  .mx-xxl-n3 {
    margin-left: -1rem !important; }
  .m-xxl-n4 {
    margin: -1.5rem !important; }
  .mt-xxl-n4,
  .my-xxl-n4 {
    margin-top: -1.5rem !important; }
  .mr-xxl-n4,
  .mx-xxl-n4 {
    margin-right: -1.5rem !important; }
  .mb-xxl-n4,
  .my-xxl-n4 {
    margin-bottom: -1.5rem !important; }
  .ml-xxl-n4,
  .mx-xxl-n4 {
    margin-left: -1.5rem !important; }
  .m-xxl-n5 {
    margin: -3rem !important; }
  .mt-xxl-n5,
  .my-xxl-n5 {
    margin-top: -3rem !important; }
  .mr-xxl-n5,
  .mx-xxl-n5 {
    margin-right: -3rem !important; }
  .mb-xxl-n5,
  .my-xxl-n5 {
    margin-bottom: -3rem !important; }
  .ml-xxl-n5,
  .mx-xxl-n5 {
    margin-left: -3rem !important; }
  .m-xxl-auto {
    margin: auto !important; }
  .mt-xxl-auto,
  .my-xxl-auto {
    margin-top: auto !important; }
  .mr-xxl-auto,
  .mx-xxl-auto {
    margin-right: auto !important; }
  .mb-xxl-auto,
  .my-xxl-auto {
    margin-bottom: auto !important; }
  .ml-xxl-auto,
  .mx-xxl-auto {
    margin-left: auto !important; } }

.stretched-link::after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1;
  pointer-events: auto;
  content: "";
  background-color: rgba(0, 0, 0, 0); }

.text-monospace {
  font-family: SFMono-Regular, Menlo, Monaco, Consolas, "Liberation Mono", "Courier New", monospace !important; }

.text-justify {
  text-align: justify !important; }

.text-wrap {
  white-space: normal !important; }

.text-nowrap {
  white-space: nowrap !important; }

.text-truncate {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap; }

.text-left {
  text-align: left !important; }

.text-right {
  text-align: right !important; }

.text-center {
  text-align: center !important; }

@media (min-width: 576px) {
  .text-sm-left {
    text-align: left !important; }
  .text-sm-right {
    text-align: right !important; }
  .text-sm-center {
    text-align: center !important; } }

@media (min-width: 768px) {
  .text-md-left {
    text-align: left !important; }
  .text-md-right {
    text-align: right !important; }
  .text-md-center {
    text-align: center !important; } }

@media (min-width: 1080px) {
  .text-lg-left {
    text-align: left !important; }
  .text-lg-right {
    text-align: right !important; }
  .text-lg-center {
    text-align: center !important; } }

@media (min-width: 1280px) {
  .text-llg-left {
    text-align: left !important; }
  .text-llg-right {
    text-align: right !important; }
  .text-llg-center {
    text-align: center !important; } }

@media (min-width: 1400px) {
  .text-xl-left {
    text-align: left !important; }
  .text-xl-right {
    text-align: right !important; }
  .text-xl-center {
    text-align: center !important; } }

@media (min-width: 1980px) {
  .text-xxl-left {
    text-align: left !important; }
  .text-xxl-right {
    text-align: right !important; }
  .text-xxl-center {
    text-align: center !important; } }

.text-lowercase {
  text-transform: lowercase !important; }

.text-uppercase {
  text-transform: uppercase !important; }

.text-capitalize {
  text-transform: capitalize !important; }

.font-weight-light {
  font-weight: 300 !important; }

.font-weight-lighter {
  font-weight: lighter !important; }

.font-weight-normal {
  font-weight: 400 !important; }

.font-weight-bold {
  font-weight: 700 !important; }

.font-weight-bolder {
  font-weight: bolder !important; }

.font-italic {
  font-style: italic !important; }

.text-white {
  color: #fff !important; }

.text-primary {
  color: #007bff !important; }

a.text-primary:hover, a.text-primary:focus {
  color: #0056b3 !important; }

.text-secondary {
  color: #6c757d !important; }

a.text-secondary:hover, a.text-secondary:focus {
  color: #494f54 !important; }

.text-success {
  color: #28a745 !important; }

a.text-success:hover, a.text-success:focus {
  color: #19692c !important; }

.text-info {
  color: #17a2b8 !important; }

a.text-info:hover, a.text-info:focus {
  color: #0f6674 !important; }

.text-warning {
  color: #ffc107 !important; }

a.text-warning:hover, a.text-warning:focus {
  color: #ba8b00 !important; }

.text-danger {
  color: #dc3545 !important; }

a.text-danger:hover, a.text-danger:focus {
  color: #a71d2a !important; }

.text-light {
  color: #f8f9fa !important; }

a.text-light:hover, a.text-light:focus {
  color: #cbd3da !important; }

.text-dark {
  color: #343a40 !important; }

a.text-dark:hover, a.text-dark:focus {
  color: #121416 !important; }

.text-body {
  color: #212529 !important; }

.text-muted {
  color: #6c757d !important; }

.text-black-50 {
  color: rgba(0, 0, 0, 0.5) !important; }

.text-white-50 {
  color: rgba(255, 255, 255, 0.5) !important; }

.text-hide {
  font: 0/0 a;
  color: transparent;
  text-shadow: none;
  background-color: transparent;
  border: 0; }

.text-decoration-none {
  text-decoration: none !important; }

.text-break {
  word-break: break-word !important;
  word-wrap: break-word !important; }

.text-reset {
  color: inherit !important; }

.visible {
  visibility: visible !important; }

.invisible {
  visibility: hidden !important; }

@media screen and (min-width: 812px) and (orientation: landscape) and (-webkit-device-pixel-ratio: 3) {
  .breadcrumb {
    padding-right: constant(safe-area-inset-right);
    padding-left: constant(safe-area-inset-left);
    padding-right: env(safe-area-inset-right);
    padding-left: env(safe-area-inset-left); } }

.w-25 {
  width: 25% !important; }

.w-50 {
  width: 50% !important; }

.w-75 {
  width: 75% !important; }

.w-100 {
  width: 100% !important; }

.w-auto {
  width: auto !important; }

.w-5 {
  width: 5% !important; }

.w-10 {
  width: 10% !important; }

.w-15 {
  width: 15% !important; }

.w-20 {
  width: 20% !important; }

.w-30 {
  width: 30% !important; }

.w-35 {
  width: 35% !important; }

.w-40 {
  width: 40% !important; }

.w-45 {
  width: 45% !important; }

.w-60 {
  width: 60% !important; }

.w-70 {
  width: 70% !important; }

.w-80 {
  width: 80% !important; }

.w-90 {
  width: 90% !important; }

.h-25 {
  height: 25% !important; }

.h-50 {
  height: 50% !important; }

.h-75 {
  height: 75% !important; }

.h-100 {
  height: 100% !important; }

.h-auto {
  height: auto !important; }

.h-5 {
  height: 5% !important; }

.h-10 {
  height: 10% !important; }

.h-15 {
  height: 15% !important; }

.h-20 {
  height: 20% !important; }

.h-30 {
  height: 30% !important; }

.h-35 {
  height: 35% !important; }

.h-40 {
  height: 40% !important; }

.h-45 {
  height: 45% !important; }

.h-60 {
  height: 60% !important; }

.h-70 {
  height: 70% !important; }

.h-80 {
  height: 80% !important; }

.h-90 {
  height: 90% !important; }

@media (min-width: 576px) {
  .w-sm-25 {
    width: 25% !important; }
  .w-sm-50 {
    width: 50% !important; }
  .w-sm-75 {
    width: 75% !important; }
  .w-sm-100 {
    width: 100% !important; }
  .w-sm-auto {
    width: auto !important; }
  .w-sm-5 {
    width: 5% !important; }
  .w-sm-10 {
    width: 10% !important; }
  .w-sm-15 {
    width: 15% !important; }
  .w-sm-20 {
    width: 20% !important; }
  .w-sm-30 {
    width: 30% !important; }
  .w-sm-35 {
    width: 35% !important; }
  .w-sm-40 {
    width: 40% !important; }
  .w-sm-45 {
    width: 45% !important; }
  .w-sm-60 {
    width: 60% !important; }
  .w-sm-70 {
    width: 70% !important; }
  .w-sm-80 {
    width: 80% !important; }
  .w-sm-90 {
    width: 90% !important; }
  .h-sm-25 {
    height: 25% !important; }
  .h-sm-50 {
    height: 50% !important; }
  .h-sm-75 {
    height: 75% !important; }
  .h-sm-100 {
    height: 100% !important; }
  .h-sm-auto {
    height: auto !important; }
  .h-sm-5 {
    height: 5% !important; }
  .h-sm-10 {
    height: 10% !important; }
  .h-sm-15 {
    height: 15% !important; }
  .h-sm-20 {
    height: 20% !important; }
  .h-sm-30 {
    height: 30% !important; }
  .h-sm-35 {
    height: 35% !important; }
  .h-sm-40 {
    height: 40% !important; }
  .h-sm-45 {
    height: 45% !important; }
  .h-sm-60 {
    height: 60% !important; }
  .h-sm-70 {
    height: 70% !important; }
  .h-sm-80 {
    height: 80% !important; }
  .h-sm-90 {
    height: 90% !important; } }

@media (min-width: 768px) {
  .w-md-25 {
    width: 25% !important; }
  .w-md-50 {
    width: 50% !important; }
  .w-md-75 {
    width: 75% !important; }
  .w-md-100 {
    width: 100% !important; }
  .w-md-auto {
    width: auto !important; }
  .w-md-5 {
    width: 5% !important; }
  .w-md-10 {
    width: 10% !important; }
  .w-md-15 {
    width: 15% !important; }
  .w-md-20 {
    width: 20% !important; }
  .w-md-30 {
    width: 30% !important; }
  .w-md-35 {
    width: 35% !important; }
  .w-md-40 {
    width: 40% !important; }
  .w-md-45 {
    width: 45% !important; }
  .w-md-60 {
    width: 60% !important; }
  .w-md-70 {
    width: 70% !important; }
  .w-md-80 {
    width: 80% !important; }
  .w-md-90 {
    width: 90% !important; }
  .h-md-25 {
    height: 25% !important; }
  .h-md-50 {
    height: 50% !important; }
  .h-md-75 {
    height: 75% !important; }
  .h-md-100 {
    height: 100% !important; }
  .h-md-auto {
    height: auto !important; }
  .h-md-5 {
    height: 5% !important; }
  .h-md-10 {
    height: 10% !important; }
  .h-md-15 {
    height: 15% !important; }
  .h-md-20 {
    height: 20% !important; }
  .h-md-30 {
    height: 30% !important; }
  .h-md-35 {
    height: 35% !important; }
  .h-md-40 {
    height: 40% !important; }
  .h-md-45 {
    height: 45% !important; }
  .h-md-60 {
    height: 60% !important; }
  .h-md-70 {
    height: 70% !important; }
  .h-md-80 {
    height: 80% !important; }
  .h-md-90 {
    height: 90% !important; } }

@media (min-width: 1080px) {
  .w-lg-25 {
    width: 25% !important; }
  .w-lg-50 {
    width: 50% !important; }
  .w-lg-75 {
    width: 75% !important; }
  .w-lg-100 {
    width: 100% !important; }
  .w-lg-auto {
    width: auto !important; }
  .w-lg-5 {
    width: 5% !important; }
  .w-lg-10 {
    width: 10% !important; }
  .w-lg-15 {
    width: 15% !important; }
  .w-lg-20 {
    width: 20% !important; }
  .w-lg-30 {
    width: 30% !important; }
  .w-lg-35 {
    width: 35% !important; }
  .w-lg-40 {
    width: 40% !important; }
  .w-lg-45 {
    width: 45% !important; }
  .w-lg-60 {
    width: 60% !important; }
  .w-lg-70 {
    width: 70% !important; }
  .w-lg-80 {
    width: 80% !important; }
  .w-lg-90 {
    width: 90% !important; }
  .h-lg-25 {
    height: 25% !important; }
  .h-lg-50 {
    height: 50% !important; }
  .h-lg-75 {
    height: 75% !important; }
  .h-lg-100 {
    height: 100% !important; }
  .h-lg-auto {
    height: auto !important; }
  .h-lg-5 {
    height: 5% !important; }
  .h-lg-10 {
    height: 10% !important; }
  .h-lg-15 {
    height: 15% !important; }
  .h-lg-20 {
    height: 20% !important; }
  .h-lg-30 {
    height: 30% !important; }
  .h-lg-35 {
    height: 35% !important; }
  .h-lg-40 {
    height: 40% !important; }
  .h-lg-45 {
    height: 45% !important; }
  .h-lg-60 {
    height: 60% !important; }
  .h-lg-70 {
    height: 70% !important; }
  .h-lg-80 {
    height: 80% !important; }
  .h-lg-90 {
    height: 90% !important; } }

@media (min-width: 1280px) {
  .w-llg-25 {
    width: 25% !important; }
  .w-llg-50 {
    width: 50% !important; }
  .w-llg-75 {
    width: 75% !important; }
  .w-llg-100 {
    width: 100% !important; }
  .w-llg-auto {
    width: auto !important; }
  .w-llg-5 {
    width: 5% !important; }
  .w-llg-10 {
    width: 10% !important; }
  .w-llg-15 {
    width: 15% !important; }
  .w-llg-20 {
    width: 20% !important; }
  .w-llg-30 {
    width: 30% !important; }
  .w-llg-35 {
    width: 35% !important; }
  .w-llg-40 {
    width: 40% !important; }
  .w-llg-45 {
    width: 45% !important; }
  .w-llg-60 {
    width: 60% !important; }
  .w-llg-70 {
    width: 70% !important; }
  .w-llg-80 {
    width: 80% !important; }
  .w-llg-90 {
    width: 90% !important; }
  .h-llg-25 {
    height: 25% !important; }
  .h-llg-50 {
    height: 50% !important; }
  .h-llg-75 {
    height: 75% !important; }
  .h-llg-100 {
    height: 100% !important; }
  .h-llg-auto {
    height: auto !important; }
  .h-llg-5 {
    height: 5% !important; }
  .h-llg-10 {
    height: 10% !important; }
  .h-llg-15 {
    height: 15% !important; }
  .h-llg-20 {
    height: 20% !important; }
  .h-llg-30 {
    height: 30% !important; }
  .h-llg-35 {
    height: 35% !important; }
  .h-llg-40 {
    height: 40% !important; }
  .h-llg-45 {
    height: 45% !important; }
  .h-llg-60 {
    height: 60% !important; }
  .h-llg-70 {
    height: 70% !important; }
  .h-llg-80 {
    height: 80% !important; }
  .h-llg-90 {
    height: 90% !important; } }

@media (min-width: 1400px) {
  .w-xl-25 {
    width: 25% !important; }
  .w-xl-50 {
    width: 50% !important; }
  .w-xl-75 {
    width: 75% !important; }
  .w-xl-100 {
    width: 100% !important; }
  .w-xl-auto {
    width: auto !important; }
  .w-xl-5 {
    width: 5% !important; }
  .w-xl-10 {
    width: 10% !important; }
  .w-xl-15 {
    width: 15% !important; }
  .w-xl-20 {
    width: 20% !important; }
  .w-xl-30 {
    width: 30% !important; }
  .w-xl-35 {
    width: 35% !important; }
  .w-xl-40 {
    width: 40% !important; }
  .w-xl-45 {
    width: 45% !important; }
  .w-xl-60 {
    width: 60% !important; }
  .w-xl-70 {
    width: 70% !important; }
  .w-xl-80 {
    width: 80% !important; }
  .w-xl-90 {
    width: 90% !important; }
  .h-xl-25 {
    height: 25% !important; }
  .h-xl-50 {
    height: 50% !important; }
  .h-xl-75 {
    height: 75% !important; }
  .h-xl-100 {
    height: 100% !important; }
  .h-xl-auto {
    height: auto !important; }
  .h-xl-5 {
    height: 5% !important; }
  .h-xl-10 {
    height: 10% !important; }
  .h-xl-15 {
    height: 15% !important; }
  .h-xl-20 {
    height: 20% !important; }
  .h-xl-30 {
    height: 30% !important; }
  .h-xl-35 {
    height: 35% !important; }
  .h-xl-40 {
    height: 40% !important; }
  .h-xl-45 {
    height: 45% !important; }
  .h-xl-60 {
    height: 60% !important; }
  .h-xl-70 {
    height: 70% !important; }
  .h-xl-80 {
    height: 80% !important; }
  .h-xl-90 {
    height: 90% !important; } }

@media (min-width: 1980px) {
  .w-xxl-25 {
    width: 25% !important; }
  .w-xxl-50 {
    width: 50% !important; }
  .w-xxl-75 {
    width: 75% !important; }
  .w-xxl-100 {
    width: 100% !important; }
  .w-xxl-auto {
    width: auto !important; }
  .w-xxl-5 {
    width: 5% !important; }
  .w-xxl-10 {
    width: 10% !important; }
  .w-xxl-15 {
    width: 15% !important; }
  .w-xxl-20 {
    width: 20% !important; }
  .w-xxl-30 {
    width: 30% !important; }
  .w-xxl-35 {
    width: 35% !important; }
  .w-xxl-40 {
    width: 40% !important; }
  .w-xxl-45 {
    width: 45% !important; }
  .w-xxl-60 {
    width: 60% !important; }
  .w-xxl-70 {
    width: 70% !important; }
  .w-xxl-80 {
    width: 80% !important; }
  .w-xxl-90 {
    width: 90% !important; }
  .h-xxl-25 {
    height: 25% !important; }
  .h-xxl-50 {
    height: 50% !important; }
  .h-xxl-75 {
    height: 75% !important; }
  .h-xxl-100 {
    height: 100% !important; }
  .h-xxl-auto {
    height: auto !important; }
  .h-xxl-5 {
    height: 5% !important; }
  .h-xxl-10 {
    height: 10% !important; }
  .h-xxl-15 {
    height: 15% !important; }
  .h-xxl-20 {
    height: 20% !important; }
  .h-xxl-30 {
    height: 30% !important; }
  .h-xxl-35 {
    height: 35% !important; }
  .h-xxl-40 {
    height: 40% !important; }
  .h-xxl-45 {
    height: 45% !important; }
  .h-xxl-60 {
    height: 60% !important; }
  .h-xxl-70 {
    height: 70% !important; }
  .h-xxl-80 {
    height: 80% !important; }
  .h-xxl-90 {
    height: 90% !important; } }

.w-em-1 {
  width: 1em !important; }

.w-em-2 {
  width: 2em !important; }

.w-em-3 {
  width: 3em !important; }

.w-em-4 {
  width: 4em !important; }

.w-em-5 {
  width: 5em !important; }

.w-em-6 {
  width: 6em !important; }

.w-em-7 {
  width: 7em !important; }

.w-em-8 {
  width: 8em !important; }

.w-em-9 {
  width: 9em !important; }

.w-em-10 {
  width: 10em !important; }

.h-em-1 {
  height: 1em !important; }

.h-em-2 {
  height: 2em !important; }

.h-em-3 {
  height: 3em !important; }

.h-em-4 {
  height: 4em !important; }

.h-em-5 {
  height: 5em !important; }

.h-em-6 {
  height: 6em !important; }

.h-em-7 {
  height: 7em !important; }

.h-em-8 {
  height: 8em !important; }

.h-em-9 {
  height: 9em !important; }

.h-em-10 {
  height: 10em !important; }

@media (min-width: 576px) {
  .w-em-sm-1 {
    width: 1em !important; }
  .w-em-sm-2 {
    width: 2em !important; }
  .w-em-sm-3 {
    width: 3em !important; }
  .w-em-sm-4 {
    width: 4em !important; }
  .w-em-sm-5 {
    width: 5em !important; }
  .w-em-sm-6 {
    width: 6em !important; }
  .w-em-sm-7 {
    width: 7em !important; }
  .w-em-sm-8 {
    width: 8em !important; }
  .w-em-sm-9 {
    width: 9em !important; }
  .w-em-sm-10 {
    width: 10em !important; }
  .h-em-sm-1 {
    height: 1em !important; }
  .h-em-sm-2 {
    height: 2em !important; }
  .h-em-sm-3 {
    height: 3em !important; }
  .h-em-sm-4 {
    height: 4em !important; }
  .h-em-sm-5 {
    height: 5em !important; }
  .h-em-sm-6 {
    height: 6em !important; }
  .h-em-sm-7 {
    height: 7em !important; }
  .h-em-sm-8 {
    height: 8em !important; }
  .h-em-sm-9 {
    height: 9em !important; }
  .h-em-sm-10 {
    height: 10em !important; } }

@media (min-width: 768px) {
  .w-em-md-1 {
    width: 1em !important; }
  .w-em-md-2 {
    width: 2em !important; }
  .w-em-md-3 {
    width: 3em !important; }
  .w-em-md-4 {
    width: 4em !important; }
  .w-em-md-5 {
    width: 5em !important; }
  .w-em-md-6 {
    width: 6em !important; }
  .w-em-md-7 {
    width: 7em !important; }
  .w-em-md-8 {
    width: 8em !important; }
  .w-em-md-9 {
    width: 9em !important; }
  .w-em-md-10 {
    width: 10em !important; }
  .h-em-md-1 {
    height: 1em !important; }
  .h-em-md-2 {
    height: 2em !important; }
  .h-em-md-3 {
    height: 3em !important; }
  .h-em-md-4 {
    height: 4em !important; }
  .h-em-md-5 {
    height: 5em !important; }
  .h-em-md-6 {
    height: 6em !important; }
  .h-em-md-7 {
    height: 7em !important; }
  .h-em-md-8 {
    height: 8em !important; }
  .h-em-md-9 {
    height: 9em !important; }
  .h-em-md-10 {
    height: 10em !important; } }

@media (min-width: 1080px) {
  .w-em-lg-1 {
    width: 1em !important; }
  .w-em-lg-2 {
    width: 2em !important; }
  .w-em-lg-3 {
    width: 3em !important; }
  .w-em-lg-4 {
    width: 4em !important; }
  .w-em-lg-5 {
    width: 5em !important; }
  .w-em-lg-6 {
    width: 6em !important; }
  .w-em-lg-7 {
    width: 7em !important; }
  .w-em-lg-8 {
    width: 8em !important; }
  .w-em-lg-9 {
    width: 9em !important; }
  .w-em-lg-10 {
    width: 10em !important; }
  .h-em-lg-1 {
    height: 1em !important; }
  .h-em-lg-2 {
    height: 2em !important; }
  .h-em-lg-3 {
    height: 3em !important; }
  .h-em-lg-4 {
    height: 4em !important; }
  .h-em-lg-5 {
    height: 5em !important; }
  .h-em-lg-6 {
    height: 6em !important; }
  .h-em-lg-7 {
    height: 7em !important; }
  .h-em-lg-8 {
    height: 8em !important; }
  .h-em-lg-9 {
    height: 9em !important; }
  .h-em-lg-10 {
    height: 10em !important; } }

@media (min-width: 1280px) {
  .w-em-llg-1 {
    width: 1em !important; }
  .w-em-llg-2 {
    width: 2em !important; }
  .w-em-llg-3 {
    width: 3em !important; }
  .w-em-llg-4 {
    width: 4em !important; }
  .w-em-llg-5 {
    width: 5em !important; }
  .w-em-llg-6 {
    width: 6em !important; }
  .w-em-llg-7 {
    width: 7em !important; }
  .w-em-llg-8 {
    width: 8em !important; }
  .w-em-llg-9 {
    width: 9em !important; }
  .w-em-llg-10 {
    width: 10em !important; }
  .h-em-llg-1 {
    height: 1em !important; }
  .h-em-llg-2 {
    height: 2em !important; }
  .h-em-llg-3 {
    height: 3em !important; }
  .h-em-llg-4 {
    height: 4em !important; }
  .h-em-llg-5 {
    height: 5em !important; }
  .h-em-llg-6 {
    height: 6em !important; }
  .h-em-llg-7 {
    height: 7em !important; }
  .h-em-llg-8 {
    height: 8em !important; }
  .h-em-llg-9 {
    height: 9em !important; }
  .h-em-llg-10 {
    height: 10em !important; } }

@media (min-width: 1400px) {
  .w-em-xl-1 {
    width: 1em !important; }
  .w-em-xl-2 {
    width: 2em !important; }
  .w-em-xl-3 {
    width: 3em !important; }
  .w-em-xl-4 {
    width: 4em !important; }
  .w-em-xl-5 {
    width: 5em !important; }
  .w-em-xl-6 {
    width: 6em !important; }
  .w-em-xl-7 {
    width: 7em !important; }
  .w-em-xl-8 {
    width: 8em !important; }
  .w-em-xl-9 {
    width: 9em !important; }
  .w-em-xl-10 {
    width: 10em !important; }
  .h-em-xl-1 {
    height: 1em !important; }
  .h-em-xl-2 {
    height: 2em !important; }
  .h-em-xl-3 {
    height: 3em !important; }
  .h-em-xl-4 {
    height: 4em !important; }
  .h-em-xl-5 {
    height: 5em !important; }
  .h-em-xl-6 {
    height: 6em !important; }
  .h-em-xl-7 {
    height: 7em !important; }
  .h-em-xl-8 {
    height: 8em !important; }
  .h-em-xl-9 {
    height: 9em !important; }
  .h-em-xl-10 {
    height: 10em !important; } }

@media (min-width: 1980px) {
  .w-em-xxl-1 {
    width: 1em !important; }
  .w-em-xxl-2 {
    width: 2em !important; }
  .w-em-xxl-3 {
    width: 3em !important; }
  .w-em-xxl-4 {
    width: 4em !important; }
  .w-em-xxl-5 {
    width: 5em !important; }
  .w-em-xxl-6 {
    width: 6em !important; }
  .w-em-xxl-7 {
    width: 7em !important; }
  .w-em-xxl-8 {
    width: 8em !important; }
  .w-em-xxl-9 {
    width: 9em !important; }
  .w-em-xxl-10 {
    width: 10em !important; }
  .h-em-xxl-1 {
    height: 1em !important; }
  .h-em-xxl-2 {
    height: 2em !important; }
  .h-em-xxl-3 {
    height: 3em !important; }
  .h-em-xxl-4 {
    height: 4em !important; }
  .h-em-xxl-5 {
    height: 5em !important; }
  .h-em-xxl-6 {
    height: 6em !important; }
  .h-em-xxl-7 {
    height: 7em !important; }
  .h-em-xxl-8 {
    height: 8em !important; }
  .h-em-xxl-9 {
    height: 9em !important; }
  .h-em-xxl-10 {
    height: 10em !important; } }

.mt-0 {
  margin-top: 0px !important; }

.mt-n0 {
  margin-top: 0px !important; }

.mt-5 {
  margin-top: 5px !important; }

.mt-n5 {
  margin-top: -5px !important; }

.mt-10 {
  margin-top: 10px !important; }

.mt-n10 {
  margin-top: -10px !important; }

.mt-15 {
  margin-top: 15px !important; }

.mt-n15 {
  margin-top: -15px !important; }

.mt-20 {
  margin-top: 20px !important; }

.mt-n20 {
  margin-top: -20px !important; }

.mt-25 {
  margin-top: 25px !important; }

.mt-n25 {
  margin-top: -25px !important; }

.mt-30 {
  margin-top: 30px !important; }

.mt-n30 {
  margin-top: -30px !important; }

.mt-35 {
  margin-top: 35px !important; }

.mt-n35 {
  margin-top: -35px !important; }

.mt-40 {
  margin-top: 40px !important; }

.mt-n40 {
  margin-top: -40px !important; }

.mt-45 {
  margin-top: 45px !important; }

.mt-n45 {
  margin-top: -45px !important; }

.mt-50 {
  margin-top: 50px !important; }

.mt-n50 {
  margin-top: -50px !important; }

.mt-55 {
  margin-top: 55px !important; }

.mt-n55 {
  margin-top: -55px !important; }

.mt-60 {
  margin-top: 60px !important; }

.mt-n60 {
  margin-top: -60px !important; }

.mt-65 {
  margin-top: 65px !important; }

.mt-n65 {
  margin-top: -65px !important; }

.mt-70 {
  margin-top: 70px !important; }

.mt-n70 {
  margin-top: -70px !important; }

.mt-75 {
  margin-top: 75px !important; }

.mt-n75 {
  margin-top: -75px !important; }

.mt-80 {
  margin-top: 80px !important; }

.mt-n80 {
  margin-top: -80px !important; }

.mt-85 {
  margin-top: 85px !important; }

.mt-n85 {
  margin-top: -85px !important; }

.mt-90 {
  margin-top: 90px !important; }

.mt-n90 {
  margin-top: -90px !important; }

.mt-95 {
  margin-top: 95px !important; }

.mt-n95 {
  margin-top: -95px !important; }

.mt-100 {
  margin-top: 100px !important; }

.mt-n100 {
  margin-top: -100px !important; }

.mr-0 {
  margin-right: 0px !important; }

.mr-n0 {
  margin-right: 0px !important; }

.mr-5 {
  margin-right: 5px !important; }

.mr-n5 {
  margin-right: -5px !important; }

.mr-10 {
  margin-right: 10px !important; }

.mr-n10 {
  margin-right: -10px !important; }

.mr-15 {
  margin-right: 15px !important; }

.mr-n15 {
  margin-right: -15px !important; }

.mr-20 {
  margin-right: 20px !important; }

.mr-n20 {
  margin-right: -20px !important; }

.mr-25 {
  margin-right: 25px !important; }

.mr-n25 {
  margin-right: -25px !important; }

.mr-30 {
  margin-right: 30px !important; }

.mr-n30 {
  margin-right: -30px !important; }

.mr-35 {
  margin-right: 35px !important; }

.mr-n35 {
  margin-right: -35px !important; }

.mr-40 {
  margin-right: 40px !important; }

.mr-n40 {
  margin-right: -40px !important; }

.mr-45 {
  margin-right: 45px !important; }

.mr-n45 {
  margin-right: -45px !important; }

.mr-50 {
  margin-right: 50px !important; }

.mr-n50 {
  margin-right: -50px !important; }

.mr-55 {
  margin-right: 55px !important; }

.mr-n55 {
  margin-right: -55px !important; }

.mr-60 {
  margin-right: 60px !important; }

.mr-n60 {
  margin-right: -60px !important; }

.mr-65 {
  margin-right: 65px !important; }

.mr-n65 {
  margin-right: -65px !important; }

.mr-70 {
  margin-right: 70px !important; }

.mr-n70 {
  margin-right: -70px !important; }

.mr-75 {
  margin-right: 75px !important; }

.mr-n75 {
  margin-right: -75px !important; }

.mr-80 {
  margin-right: 80px !important; }

.mr-n80 {
  margin-right: -80px !important; }

.mr-85 {
  margin-right: 85px !important; }

.mr-n85 {
  margin-right: -85px !important; }

.mr-90 {
  margin-right: 90px !important; }

.mr-n90 {
  margin-right: -90px !important; }

.mr-95 {
  margin-right: 95px !important; }

.mr-n95 {
  margin-right: -95px !important; }

.mr-100 {
  margin-right: 100px !important; }

.mr-n100 {
  margin-right: -100px !important; }

.mb-0 {
  margin-bottom: 0px !important; }

.mb-n0 {
  margin-bottom: 0px !important; }

.mb-5 {
  margin-bottom: 5px !important; }

.mb-n5 {
  margin-bottom: -5px !important; }

.mb-10 {
  margin-bottom: 10px !important; }

.mb-n10 {
  margin-bottom: -10px !important; }

.mb-15 {
  margin-bottom: 15px !important; }

.mb-n15 {
  margin-bottom: -15px !important; }

.mb-20 {
  margin-bottom: 20px !important; }

.mb-n20 {
  margin-bottom: -20px !important; }

.mb-25 {
  margin-bottom: 25px !important; }

.mb-n25 {
  margin-bottom: -25px !important; }

.mb-30 {
  margin-bottom: 30px !important; }

.mb-n30 {
  margin-bottom: -30px !important; }

.mb-35 {
  margin-bottom: 35px !important; }

.mb-n35 {
  margin-bottom: -35px !important; }

.mb-40 {
  margin-bottom: 40px !important; }

.mb-n40 {
  margin-bottom: -40px !important; }

.mb-45 {
  margin-bottom: 45px !important; }

.mb-n45 {
  margin-bottom: -45px !important; }

.mb-50 {
  margin-bottom: 50px !important; }

.mb-n50 {
  margin-bottom: -50px !important; }

.mb-55 {
  margin-bottom: 55px !important; }

.mb-n55 {
  margin-bottom: -55px !important; }

.mb-60 {
  margin-bottom: 60px !important; }

.mb-n60 {
  margin-bottom: -60px !important; }

.mb-65 {
  margin-bottom: 65px !important; }

.mb-n65 {
  margin-bottom: -65px !important; }

.mb-70 {
  margin-bottom: 70px !important; }

.mb-n70 {
  margin-bottom: -70px !important; }

.mb-75 {
  margin-bottom: 75px !important; }

.mb-n75 {
  margin-bottom: -75px !important; }

.mb-80 {
  margin-bottom: 80px !important; }

.mb-n80 {
  margin-bottom: -80px !important; }

.mb-85 {
  margin-bottom: 85px !important; }

.mb-n85 {
  margin-bottom: -85px !important; }

.mb-90 {
  margin-bottom: 90px !important; }

.mb-n90 {
  margin-bottom: -90px !important; }

.mb-95 {
  margin-bottom: 95px !important; }

.mb-n95 {
  margin-bottom: -95px !important; }

.mb-100 {
  margin-bottom: 100px !important; }

.mb-n100 {
  margin-bottom: -100px !important; }

.ml-0 {
  margin-left: 0px !important; }

.ml-n0 {
  margin-left: 0px !important; }

.ml-5 {
  margin-left: 5px !important; }

.ml-n5 {
  margin-left: -5px !important; }

.ml-10 {
  margin-left: 10px !important; }

.ml-n10 {
  margin-left: -10px !important; }

.ml-15 {
  margin-left: 15px !important; }

.ml-n15 {
  margin-left: -15px !important; }

.ml-20 {
  margin-left: 20px !important; }

.ml-n20 {
  margin-left: -20px !important; }

.ml-25 {
  margin-left: 25px !important; }

.ml-n25 {
  margin-left: -25px !important; }

.ml-30 {
  margin-left: 30px !important; }

.ml-n30 {
  margin-left: -30px !important; }

.ml-35 {
  margin-left: 35px !important; }

.ml-n35 {
  margin-left: -35px !important; }

.ml-40 {
  margin-left: 40px !important; }

.ml-n40 {
  margin-left: -40px !important; }

.ml-45 {
  margin-left: 45px !important; }

.ml-n45 {
  margin-left: -45px !important; }

.ml-50 {
  margin-left: 50px !important; }

.ml-n50 {
  margin-left: -50px !important; }

.ml-55 {
  margin-left: 55px !important; }

.ml-n55 {
  margin-left: -55px !important; }

.ml-60 {
  margin-left: 60px !important; }

.ml-n60 {
  margin-left: -60px !important; }

.ml-65 {
  margin-left: 65px !important; }

.ml-n65 {
  margin-left: -65px !important; }

.ml-70 {
  margin-left: 70px !important; }

.ml-n70 {
  margin-left: -70px !important; }

.ml-75 {
  margin-left: 75px !important; }

.ml-n75 {
  margin-left: -75px !important; }

.ml-80 {
  margin-left: 80px !important; }

.ml-n80 {
  margin-left: -80px !important; }

.ml-85 {
  margin-left: 85px !important; }

.ml-n85 {
  margin-left: -85px !important; }

.ml-90 {
  margin-left: 90px !important; }

.ml-n90 {
  margin-left: -90px !important; }

.ml-95 {
  margin-left: 95px !important; }

.ml-n95 {
  margin-left: -95px !important; }

.ml-100 {
  margin-left: 100px !important; }

.ml-n100 {
  margin-left: -100px !important; }

.pt-0 {
  padding-top: 0px !important; }

.pt-n0 {
  padding-top: 0px !important; }

.pt-5 {
  padding-top: 5px !important; }

.pt-n5 {
  padding-top: -5px !important; }

.pt-10 {
  padding-top: 10px !important; }

.pt-n10 {
  padding-top: -10px !important; }

.pt-15 {
  padding-top: 15px !important; }

.pt-n15 {
  padding-top: -15px !important; }

.pt-20 {
  padding-top: 20px !important; }

.pt-n20 {
  padding-top: -20px !important; }

.pt-25 {
  padding-top: 25px !important; }

.pt-n25 {
  padding-top: -25px !important; }

.pt-30 {
  padding-top: 30px !important; }

.pt-n30 {
  padding-top: -30px !important; }

.pt-35 {
  padding-top: 35px !important; }

.pt-n35 {
  padding-top: -35px !important; }

.pt-40 {
  padding-top: 40px !important; }

.pt-n40 {
  padding-top: -40px !important; }

.pt-45 {
  padding-top: 45px !important; }

.pt-n45 {
  padding-top: -45px !important; }

.pt-50 {
  padding-top: 50px !important; }

.pt-n50 {
  padding-top: -50px !important; }

.pt-55 {
  padding-top: 55px !important; }

.pt-n55 {
  padding-top: -55px !important; }

.pt-60 {
  padding-top: 60px !important; }

.pt-n60 {
  padding-top: -60px !important; }

.pt-65 {
  padding-top: 65px !important; }

.pt-n65 {
  padding-top: -65px !important; }

.pt-70 {
  padding-top: 70px !important; }

.pt-n70 {
  padding-top: -70px !important; }

.pt-75 {
  padding-top: 75px !important; }

.pt-n75 {
  padding-top: -75px !important; }

.pt-80 {
  padding-top: 80px !important; }

.pt-n80 {
  padding-top: -80px !important; }

.pt-85 {
  padding-top: 85px !important; }

.pt-n85 {
  padding-top: -85px !important; }

.pt-90 {
  padding-top: 90px !important; }

.pt-n90 {
  padding-top: -90px !important; }

.pt-95 {
  padding-top: 95px !important; }

.pt-n95 {
  padding-top: -95px !important; }

.pt-100 {
  padding-top: 100px !important; }

.pt-n100 {
  padding-top: -100px !important; }

.pr-0 {
  padding-right: 0px !important; }

.pr-n0 {
  padding-right: 0px !important; }

.pr-5 {
  padding-right: 5px !important; }

.pr-n5 {
  padding-right: -5px !important; }

.pr-10 {
  padding-right: 10px !important; }

.pr-n10 {
  padding-right: -10px !important; }

.pr-15 {
  padding-right: 15px !important; }

.pr-n15 {
  padding-right: -15px !important; }

.pr-20 {
  padding-right: 20px !important; }

.pr-n20 {
  padding-right: -20px !important; }

.pr-25 {
  padding-right: 25px !important; }

.pr-n25 {
  padding-right: -25px !important; }

.pr-30 {
  padding-right: 30px !important; }

.pr-n30 {
  padding-right: -30px !important; }

.pr-35 {
  padding-right: 35px !important; }

.pr-n35 {
  padding-right: -35px !important; }

.pr-40 {
  padding-right: 40px !important; }

.pr-n40 {
  padding-right: -40px !important; }

.pr-45 {
  padding-right: 45px !important; }

.pr-n45 {
  padding-right: -45px !important; }

.pr-50 {
  padding-right: 50px !important; }

.pr-n50 {
  padding-right: -50px !important; }

.pr-55 {
  padding-right: 55px !important; }

.pr-n55 {
  padding-right: -55px !important; }

.pr-60 {
  padding-right: 60px !important; }

.pr-n60 {
  padding-right: -60px !important; }

.pr-65 {
  padding-right: 65px !important; }

.pr-n65 {
  padding-right: -65px !important; }

.pr-70 {
  padding-right: 70px !important; }

.pr-n70 {
  padding-right: -70px !important; }

.pr-75 {
  padding-right: 75px !important; }

.pr-n75 {
  padding-right: -75px !important; }

.pr-80 {
  padding-right: 80px !important; }

.pr-n80 {
  padding-right: -80px !important; }

.pr-85 {
  padding-right: 85px !important; }

.pr-n85 {
  padding-right: -85px !important; }

.pr-90 {
  padding-right: 90px !important; }

.pr-n90 {
  padding-right: -90px !important; }

.pr-95 {
  padding-right: 95px !important; }

.pr-n95 {
  padding-right: -95px !important; }

.pr-100 {
  padding-right: 100px !important; }

.pr-n100 {
  padding-right: -100px !important; }

.pb-0 {
  padding-bottom: 0px !important; }

.pb-n0 {
  padding-bottom: 0px !important; }

.pb-5 {
  padding-bottom: 5px !important; }

.pb-n5 {
  padding-bottom: -5px !important; }

.pb-10 {
  padding-bottom: 10px !important; }

.pb-n10 {
  padding-bottom: -10px !important; }

.pb-15 {
  padding-bottom: 15px !important; }

.pb-n15 {
  padding-bottom: -15px !important; }

.pb-20 {
  padding-bottom: 20px !important; }

.pb-n20 {
  padding-bottom: -20px !important; }

.pb-25 {
  padding-bottom: 25px !important; }

.pb-n25 {
  padding-bottom: -25px !important; }

.pb-30 {
  padding-bottom: 30px !important; }

.pb-n30 {
  padding-bottom: -30px !important; }

.pb-35 {
  padding-bottom: 35px !important; }

.pb-n35 {
  padding-bottom: -35px !important; }

.pb-40 {
  padding-bottom: 40px !important; }

.pb-n40 {
  padding-bottom: -40px !important; }

.pb-45 {
  padding-bottom: 45px !important; }

.pb-n45 {
  padding-bottom: -45px !important; }

.pb-50 {
  padding-bottom: 50px !important; }

.pb-n50 {
  padding-bottom: -50px !important; }

.pb-55 {
  padding-bottom: 55px !important; }

.pb-n55 {
  padding-bottom: -55px !important; }

.pb-60 {
  padding-bottom: 60px !important; }

.pb-n60 {
  padding-bottom: -60px !important; }

.pb-65 {
  padding-bottom: 65px !important; }

.pb-n65 {
  padding-bottom: -65px !important; }

.pb-70 {
  padding-bottom: 70px !important; }

.pb-n70 {
  padding-bottom: -70px !important; }

.pb-75 {
  padding-bottom: 75px !important; }

.pb-n75 {
  padding-bottom: -75px !important; }

.pb-80 {
  padding-bottom: 80px !important; }

.pb-n80 {
  padding-bottom: -80px !important; }

.pb-85 {
  padding-bottom: 85px !important; }

.pb-n85 {
  padding-bottom: -85px !important; }

.pb-90 {
  padding-bottom: 90px !important; }

.pb-n90 {
  padding-bottom: -90px !important; }

.pb-95 {
  padding-bottom: 95px !important; }

.pb-n95 {
  padding-bottom: -95px !important; }

.pb-100 {
  padding-bottom: 100px !important; }

.pb-n100 {
  padding-bottom: -100px !important; }

.pl-0 {
  padding-left: 0px !important; }

.pl-n0 {
  padding-left: 0px !important; }

.pl-5 {
  padding-left: 5px !important; }

.pl-n5 {
  padding-left: -5px !important; }

.pl-10 {
  padding-left: 10px !important; }

.pl-n10 {
  padding-left: -10px !important; }

.pl-15 {
  padding-left: 15px !important; }

.pl-n15 {
  padding-left: -15px !important; }

.pl-20 {
  padding-left: 20px !important; }

.pl-n20 {
  padding-left: -20px !important; }

.pl-25 {
  padding-left: 25px !important; }

.pl-n25 {
  padding-left: -25px !important; }

.pl-30 {
  padding-left: 30px !important; }

.pl-n30 {
  padding-left: -30px !important; }

.pl-35 {
  padding-left: 35px !important; }

.pl-n35 {
  padding-left: -35px !important; }

.pl-40 {
  padding-left: 40px !important; }

.pl-n40 {
  padding-left: -40px !important; }

.pl-45 {
  padding-left: 45px !important; }

.pl-n45 {
  padding-left: -45px !important; }

.pl-50 {
  padding-left: 50px !important; }

.pl-n50 {
  padding-left: -50px !important; }

.pl-55 {
  padding-left: 55px !important; }

.pl-n55 {
  padding-left: -55px !important; }

.pl-60 {
  padding-left: 60px !important; }

.pl-n60 {
  padding-left: -60px !important; }

.pl-65 {
  padding-left: 65px !important; }

.pl-n65 {
  padding-left: -65px !important; }

.pl-70 {
  padding-left: 70px !important; }

.pl-n70 {
  padding-left: -70px !important; }

.pl-75 {
  padding-left: 75px !important; }

.pl-n75 {
  padding-left: -75px !important; }

.pl-80 {
  padding-left: 80px !important; }

.pl-n80 {
  padding-left: -80px !important; }

.pl-85 {
  padding-left: 85px !important; }

.pl-n85 {
  padding-left: -85px !important; }

.pl-90 {
  padding-left: 90px !important; }

.pl-n90 {
  padding-left: -90px !important; }

.pl-95 {
  padding-left: 95px !important; }

.pl-n95 {
  padding-left: -95px !important; }

.pl-100 {
  padding-left: 100px !important; }

.pl-n100 {
  padding-left: -100px !important; }

@media (min-width: 768px) {
  .mt-md-0 {
    margin-top: 0px !important; }
  .mt-n0 {
    margin-top: 0px !important; }
  .mt-md-5 {
    margin-top: 5px !important; }
  .mt-n5 {
    margin-top: -5px !important; }
  .mt-md-10 {
    margin-top: 10px !important; }
  .mt-n10 {
    margin-top: -10px !important; }
  .mt-md-15 {
    margin-top: 15px !important; }
  .mt-n15 {
    margin-top: -15px !important; }
  .mt-md-20 {
    margin-top: 20px !important; }
  .mt-n20 {
    margin-top: -20px !important; }
  .mt-md-25 {
    margin-top: 25px !important; }
  .mt-n25 {
    margin-top: -25px !important; }
  .mt-md-30 {
    margin-top: 30px !important; }
  .mt-n30 {
    margin-top: -30px !important; }
  .mt-md-35 {
    margin-top: 35px !important; }
  .mt-n35 {
    margin-top: -35px !important; }
  .mt-md-40 {
    margin-top: 40px !important; }
  .mt-n40 {
    margin-top: -40px !important; }
  .mt-md-45 {
    margin-top: 45px !important; }
  .mt-n45 {
    margin-top: -45px !important; }
  .mt-md-50 {
    margin-top: 50px !important; }
  .mt-n50 {
    margin-top: -50px !important; }
  .mt-md-55 {
    margin-top: 55px !important; }
  .mt-n55 {
    margin-top: -55px !important; }
  .mt-md-60 {
    margin-top: 60px !important; }
  .mt-n60 {
    margin-top: -60px !important; }
  .mt-md-65 {
    margin-top: 65px !important; }
  .mt-n65 {
    margin-top: -65px !important; }
  .mt-md-70 {
    margin-top: 70px !important; }
  .mt-n70 {
    margin-top: -70px !important; }
  .mt-md-75 {
    margin-top: 75px !important; }
  .mt-n75 {
    margin-top: -75px !important; }
  .mt-md-80 {
    margin-top: 80px !important; }
  .mt-n80 {
    margin-top: -80px !important; }
  .mt-md-85 {
    margin-top: 85px !important; }
  .mt-n85 {
    margin-top: -85px !important; }
  .mt-md-90 {
    margin-top: 90px !important; }
  .mt-n90 {
    margin-top: -90px !important; }
  .mt-md-95 {
    margin-top: 95px !important; }
  .mt-n95 {
    margin-top: -95px !important; }
  .mt-md-100 {
    margin-top: 100px !important; }
  .mt-n100 {
    margin-top: -100px !important; }
  .mr-md-0 {
    margin-right: 0px !important; }
  .mr-n0 {
    margin-right: 0px !important; }
  .mr-md-5 {
    margin-right: 5px !important; }
  .mr-n5 {
    margin-right: -5px !important; }
  .mr-md-10 {
    margin-right: 10px !important; }
  .mr-n10 {
    margin-right: -10px !important; }
  .mr-md-15 {
    margin-right: 15px !important; }
  .mr-n15 {
    margin-right: -15px !important; }
  .mr-md-20 {
    margin-right: 20px !important; }
  .mr-n20 {
    margin-right: -20px !important; }
  .mr-md-25 {
    margin-right: 25px !important; }
  .mr-n25 {
    margin-right: -25px !important; }
  .mr-md-30 {
    margin-right: 30px !important; }
  .mr-n30 {
    margin-right: -30px !important; }
  .mr-md-35 {
    margin-right: 35px !important; }
  .mr-n35 {
    margin-right: -35px !important; }
  .mr-md-40 {
    margin-right: 40px !important; }
  .mr-n40 {
    margin-right: -40px !important; }
  .mr-md-45 {
    margin-right: 45px !important; }
  .mr-n45 {
    margin-right: -45px !important; }
  .mr-md-50 {
    margin-right: 50px !important; }
  .mr-n50 {
    margin-right: -50px !important; }
  .mr-md-55 {
    margin-right: 55px !important; }
  .mr-n55 {
    margin-right: -55px !important; }
  .mr-md-60 {
    margin-right: 60px !important; }
  .mr-n60 {
    margin-right: -60px !important; }
  .mr-md-65 {
    margin-right: 65px !important; }
  .mr-n65 {
    margin-right: -65px !important; }
  .mr-md-70 {
    margin-right: 70px !important; }
  .mr-n70 {
    margin-right: -70px !important; }
  .mr-md-75 {
    margin-right: 75px !important; }
  .mr-n75 {
    margin-right: -75px !important; }
  .mr-md-80 {
    margin-right: 80px !important; }
  .mr-n80 {
    margin-right: -80px !important; }
  .mr-md-85 {
    margin-right: 85px !important; }
  .mr-n85 {
    margin-right: -85px !important; }
  .mr-md-90 {
    margin-right: 90px !important; }
  .mr-n90 {
    margin-right: -90px !important; }
  .mr-md-95 {
    margin-right: 95px !important; }
  .mr-n95 {
    margin-right: -95px !important; }
  .mr-md-100 {
    margin-right: 100px !important; }
  .mr-n100 {
    margin-right: -100px !important; }
  .mb-md-0 {
    margin-bottom: 0px !important; }
  .mb-n0 {
    margin-bottom: 0px !important; }
  .mb-md-5 {
    margin-bottom: 5px !important; }
  .mb-n5 {
    margin-bottom: -5px !important; }
  .mb-md-10 {
    margin-bottom: 10px !important; }
  .mb-n10 {
    margin-bottom: -10px !important; }
  .mb-md-15 {
    margin-bottom: 15px !important; }
  .mb-n15 {
    margin-bottom: -15px !important; }
  .mb-md-20 {
    margin-bottom: 20px !important; }
  .mb-n20 {
    margin-bottom: -20px !important; }
  .mb-md-25 {
    margin-bottom: 25px !important; }
  .mb-n25 {
    margin-bottom: -25px !important; }
  .mb-md-30 {
    margin-bottom: 30px !important; }
  .mb-n30 {
    margin-bottom: -30px !important; }
  .mb-md-35 {
    margin-bottom: 35px !important; }
  .mb-n35 {
    margin-bottom: -35px !important; }
  .mb-md-40 {
    margin-bottom: 40px !important; }
  .mb-n40 {
    margin-bottom: -40px !important; }
  .mb-md-45 {
    margin-bottom: 45px !important; }
  .mb-n45 {
    margin-bottom: -45px !important; }
  .mb-md-50 {
    margin-bottom: 50px !important; }
  .mb-n50 {
    margin-bottom: -50px !important; }
  .mb-md-55 {
    margin-bottom: 55px !important; }
  .mb-n55 {
    margin-bottom: -55px !important; }
  .mb-md-60 {
    margin-bottom: 60px !important; }
  .mb-n60 {
    margin-bottom: -60px !important; }
  .mb-md-65 {
    margin-bottom: 65px !important; }
  .mb-n65 {
    margin-bottom: -65px !important; }
  .mb-md-70 {
    margin-bottom: 70px !important; }
  .mb-n70 {
    margin-bottom: -70px !important; }
  .mb-md-75 {
    margin-bottom: 75px !important; }
  .mb-n75 {
    margin-bottom: -75px !important; }
  .mb-md-80 {
    margin-bottom: 80px !important; }
  .mb-n80 {
    margin-bottom: -80px !important; }
  .mb-md-85 {
    margin-bottom: 85px !important; }
  .mb-n85 {
    margin-bottom: -85px !important; }
  .mb-md-90 {
    margin-bottom: 90px !important; }
  .mb-n90 {
    margin-bottom: -90px !important; }
  .mb-md-95 {
    margin-bottom: 95px !important; }
  .mb-n95 {
    margin-bottom: -95px !important; }
  .mb-md-100 {
    margin-bottom: 100px !important; }
  .mb-n100 {
    margin-bottom: -100px !important; }
  .ml-md-0 {
    margin-left: 0px !important; }
  .ml-n0 {
    margin-left: 0px !important; }
  .ml-md-5 {
    margin-left: 5px !important; }
  .ml-n5 {
    margin-left: -5px !important; }
  .ml-md-10 {
    margin-left: 10px !important; }
  .ml-n10 {
    margin-left: -10px !important; }
  .ml-md-15 {
    margin-left: 15px !important; }
  .ml-n15 {
    margin-left: -15px !important; }
  .ml-md-20 {
    margin-left: 20px !important; }
  .ml-n20 {
    margin-left: -20px !important; }
  .ml-md-25 {
    margin-left: 25px !important; }
  .ml-n25 {
    margin-left: -25px !important; }
  .ml-md-30 {
    margin-left: 30px !important; }
  .ml-n30 {
    margin-left: -30px !important; }
  .ml-md-35 {
    margin-left: 35px !important; }
  .ml-n35 {
    margin-left: -35px !important; }
  .ml-md-40 {
    margin-left: 40px !important; }
  .ml-n40 {
    margin-left: -40px !important; }
  .ml-md-45 {
    margin-left: 45px !important; }
  .ml-n45 {
    margin-left: -45px !important; }
  .ml-md-50 {
    margin-left: 50px !important; }
  .ml-n50 {
    margin-left: -50px !important; }
  .ml-md-55 {
    margin-left: 55px !important; }
  .ml-n55 {
    margin-left: -55px !important; }
  .ml-md-60 {
    margin-left: 60px !important; }
  .ml-n60 {
    margin-left: -60px !important; }
  .ml-md-65 {
    margin-left: 65px !important; }
  .ml-n65 {
    margin-left: -65px !important; }
  .ml-md-70 {
    margin-left: 70px !important; }
  .ml-n70 {
    margin-left: -70px !important; }
  .ml-md-75 {
    margin-left: 75px !important; }
  .ml-n75 {
    margin-left: -75px !important; }
  .ml-md-80 {
    margin-left: 80px !important; }
  .ml-n80 {
    margin-left: -80px !important; }
  .ml-md-85 {
    margin-left: 85px !important; }
  .ml-n85 {
    margin-left: -85px !important; }
  .ml-md-90 {
    margin-left: 90px !important; }
  .ml-n90 {
    margin-left: -90px !important; }
  .ml-md-95 {
    margin-left: 95px !important; }
  .ml-n95 {
    margin-left: -95px !important; }
  .ml-md-100 {
    margin-left: 100px !important; }
  .ml-n100 {
    margin-left: -100px !important; }
  .pt-md-0 {
    padding-top: 0px !important; }
  .pt-n0 {
    padding-top: 0px !important; }
  .pt-md-5 {
    padding-top: 5px !important; }
  .pt-n5 {
    padding-top: -5px !important; }
  .pt-md-10 {
    padding-top: 10px !important; }
  .pt-n10 {
    padding-top: -10px !important; }
  .pt-md-15 {
    padding-top: 15px !important; }
  .pt-n15 {
    padding-top: -15px !important; }
  .pt-md-20 {
    padding-top: 20px !important; }
  .pt-n20 {
    padding-top: -20px !important; }
  .pt-md-25 {
    padding-top: 25px !important; }
  .pt-n25 {
    padding-top: -25px !important; }
  .pt-md-30 {
    padding-top: 30px !important; }
  .pt-n30 {
    padding-top: -30px !important; }
  .pt-md-35 {
    padding-top: 35px !important; }
  .pt-n35 {
    padding-top: -35px !important; }
  .pt-md-40 {
    padding-top: 40px !important; }
  .pt-n40 {
    padding-top: -40px !important; }
  .pt-md-45 {
    padding-top: 45px !important; }
  .pt-n45 {
    padding-top: -45px !important; }
  .pt-md-50 {
    padding-top: 50px !important; }
  .pt-n50 {
    padding-top: -50px !important; }
  .pt-md-55 {
    padding-top: 55px !important; }
  .pt-n55 {
    padding-top: -55px !important; }
  .pt-md-60 {
    padding-top: 60px !important; }
  .pt-n60 {
    padding-top: -60px !important; }
  .pt-md-65 {
    padding-top: 65px !important; }
  .pt-n65 {
    padding-top: -65px !important; }
  .pt-md-70 {
    padding-top: 70px !important; }
  .pt-n70 {
    padding-top: -70px !important; }
  .pt-md-75 {
    padding-top: 75px !important; }
  .pt-n75 {
    padding-top: -75px !important; }
  .pt-md-80 {
    padding-top: 80px !important; }
  .pt-n80 {
    padding-top: -80px !important; }
  .pt-md-85 {
    padding-top: 85px !important; }
  .pt-n85 {
    padding-top: -85px !important; }
  .pt-md-90 {
    padding-top: 90px !important; }
  .pt-n90 {
    padding-top: -90px !important; }
  .pt-md-95 {
    padding-top: 95px !important; }
  .pt-n95 {
    padding-top: -95px !important; }
  .pt-md-100 {
    padding-top: 100px !important; }
  .pt-n100 {
    padding-top: -100px !important; }
  .pr-md-0 {
    padding-right: 0px !important; }
  .pr-n0 {
    padding-right: 0px !important; }
  .pr-md-5 {
    padding-right: 5px !important; }
  .pr-n5 {
    padding-right: -5px !important; }
  .pr-md-10 {
    padding-right: 10px !important; }
  .pr-n10 {
    padding-right: -10px !important; }
  .pr-md-15 {
    padding-right: 15px !important; }
  .pr-n15 {
    padding-right: -15px !important; }
  .pr-md-20 {
    padding-right: 20px !important; }
  .pr-n20 {
    padding-right: -20px !important; }
  .pr-md-25 {
    padding-right: 25px !important; }
  .pr-n25 {
    padding-right: -25px !important; }
  .pr-md-30 {
    padding-right: 30px !important; }
  .pr-n30 {
    padding-right: -30px !important; }
  .pr-md-35 {
    padding-right: 35px !important; }
  .pr-n35 {
    padding-right: -35px !important; }
  .pr-md-40 {
    padding-right: 40px !important; }
  .pr-n40 {
    padding-right: -40px !important; }
  .pr-md-45 {
    padding-right: 45px !important; }
  .pr-n45 {
    padding-right: -45px !important; }
  .pr-md-50 {
    padding-right: 50px !important; }
  .pr-n50 {
    padding-right: -50px !important; }
  .pr-md-55 {
    padding-right: 55px !important; }
  .pr-n55 {
    padding-right: -55px !important; }
  .pr-md-60 {
    padding-right: 60px !important; }
  .pr-n60 {
    padding-right: -60px !important; }
  .pr-md-65 {
    padding-right: 65px !important; }
  .pr-n65 {
    padding-right: -65px !important; }
  .pr-md-70 {
    padding-right: 70px !important; }
  .pr-n70 {
    padding-right: -70px !important; }
  .pr-md-75 {
    padding-right: 75px !important; }
  .pr-n75 {
    padding-right: -75px !important; }
  .pr-md-80 {
    padding-right: 80px !important; }
  .pr-n80 {
    padding-right: -80px !important; }
  .pr-md-85 {
    padding-right: 85px !important; }
  .pr-n85 {
    padding-right: -85px !important; }
  .pr-md-90 {
    padding-right: 90px !important; }
  .pr-n90 {
    padding-right: -90px !important; }
  .pr-md-95 {
    padding-right: 95px !important; }
  .pr-n95 {
    padding-right: -95px !important; }
  .pr-md-100 {
    padding-right: 100px !important; }
  .pr-n100 {
    padding-right: -100px !important; }
  .pb-md-0 {
    padding-bottom: 0px !important; }
  .pb-n0 {
    padding-bottom: 0px !important; }
  .pb-md-5 {
    padding-bottom: 5px !important; }
  .pb-n5 {
    padding-bottom: -5px !important; }
  .pb-md-10 {
    padding-bottom: 10px !important; }
  .pb-n10 {
    padding-bottom: -10px !important; }
  .pb-md-15 {
    padding-bottom: 15px !important; }
  .pb-n15 {
    padding-bottom: -15px !important; }
  .pb-md-20 {
    padding-bottom: 20px !important; }
  .pb-n20 {
    padding-bottom: -20px !important; }
  .pb-md-25 {
    padding-bottom: 25px !important; }
  .pb-n25 {
    padding-bottom: -25px !important; }
  .pb-md-30 {
    padding-bottom: 30px !important; }
  .pb-n30 {
    padding-bottom: -30px !important; }
  .pb-md-35 {
    padding-bottom: 35px !important; }
  .pb-n35 {
    padding-bottom: -35px !important; }
  .pb-md-40 {
    padding-bottom: 40px !important; }
  .pb-n40 {
    padding-bottom: -40px !important; }
  .pb-md-45 {
    padding-bottom: 45px !important; }
  .pb-n45 {
    padding-bottom: -45px !important; }
  .pb-md-50 {
    padding-bottom: 50px !important; }
  .pb-n50 {
    padding-bottom: -50px !important; }
  .pb-md-55 {
    padding-bottom: 55px !important; }
  .pb-n55 {
    padding-bottom: -55px !important; }
  .pb-md-60 {
    padding-bottom: 60px !important; }
  .pb-n60 {
    padding-bottom: -60px !important; }
  .pb-md-65 {
    padding-bottom: 65px !important; }
  .pb-n65 {
    padding-bottom: -65px !important; }
  .pb-md-70 {
    padding-bottom: 70px !important; }
  .pb-n70 {
    padding-bottom: -70px !important; }
  .pb-md-75 {
    padding-bottom: 75px !important; }
  .pb-n75 {
    padding-bottom: -75px !important; }
  .pb-md-80 {
    padding-bottom: 80px !important; }
  .pb-n80 {
    padding-bottom: -80px !important; }
  .pb-md-85 {
    padding-bottom: 85px !important; }
  .pb-n85 {
    padding-bottom: -85px !important; }
  .pb-md-90 {
    padding-bottom: 90px !important; }
  .pb-n90 {
    padding-bottom: -90px !important; }
  .pb-md-95 {
    padding-bottom: 95px !important; }
  .pb-n95 {
    padding-bottom: -95px !important; }
  .pb-md-100 {
    padding-bottom: 100px !important; }
  .pb-n100 {
    padding-bottom: -100px !important; }
  .pl-md-0 {
    padding-left: 0px !important; }
  .pl-n0 {
    padding-left: 0px !important; }
  .pl-md-5 {
    padding-left: 5px !important; }
  .pl-n5 {
    padding-left: -5px !important; }
  .pl-md-10 {
    padding-left: 10px !important; }
  .pl-n10 {
    padding-left: -10px !important; }
  .pl-md-15 {
    padding-left: 15px !important; }
  .pl-n15 {
    padding-left: -15px !important; }
  .pl-md-20 {
    padding-left: 20px !important; }
  .pl-n20 {
    padding-left: -20px !important; }
  .pl-md-25 {
    padding-left: 25px !important; }
  .pl-n25 {
    padding-left: -25px !important; }
  .pl-md-30 {
    padding-left: 30px !important; }
  .pl-n30 {
    padding-left: -30px !important; }
  .pl-md-35 {
    padding-left: 35px !important; }
  .pl-n35 {
    padding-left: -35px !important; }
  .pl-md-40 {
    padding-left: 40px !important; }
  .pl-n40 {
    padding-left: -40px !important; }
  .pl-md-45 {
    padding-left: 45px !important; }
  .pl-n45 {
    padding-left: -45px !important; }
  .pl-md-50 {
    padding-left: 50px !important; }
  .pl-n50 {
    padding-left: -50px !important; }
  .pl-md-55 {
    padding-left: 55px !important; }
  .pl-n55 {
    padding-left: -55px !important; }
  .pl-md-60 {
    padding-left: 60px !important; }
  .pl-n60 {
    padding-left: -60px !important; }
  .pl-md-65 {
    padding-left: 65px !important; }
  .pl-n65 {
    padding-left: -65px !important; }
  .pl-md-70 {
    padding-left: 70px !important; }
  .pl-n70 {
    padding-left: -70px !important; }
  .pl-md-75 {
    padding-left: 75px !important; }
  .pl-n75 {
    padding-left: -75px !important; }
  .pl-md-80 {
    padding-left: 80px !important; }
  .pl-n80 {
    padding-left: -80px !important; }
  .pl-md-85 {
    padding-left: 85px !important; }
  .pl-n85 {
    padding-left: -85px !important; }
  .pl-md-90 {
    padding-left: 90px !important; }
  .pl-n90 {
    padding-left: -90px !important; }
  .pl-md-95 {
    padding-left: 95px !important; }
  .pl-n95 {
    padding-left: -95px !important; }
  .pl-md-100 {
    padding-left: 100px !important; }
  .pl-n100 {
    padding-left: -100px !important; } }

.form-control {
  width: auto; }

.ttl__style_1 {
  text-align: center; }

.ttl__style_2 {
  border-bottom: 1px #ddd solid; }
  .ttl__style_2 span {
    position: relative;
    display: inline-block;
    padding-bottom: 6px; }
    .ttl__style_2 span::before {
      content: '';
      position: absolute;
      bottom: -1px;
      left: 0;
      right: 0;
      height: 2px;
      background-color: #646464; }

.ttl__style_3 {
  padding: .3em 0 .3em 24px;
  position: relative;
  background-color: #fff; }
  .ttl__style_3::before {
    content: '';
    display: block;
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    width: 2px;
    background-color: #646464; }

.ttl__style_4 {
  padding: .3em 0 .3em 1.5em;
  position: relative; }
  .ttl__style_4::before {
    content: '';
    display: block;
    position: absolute;
    top: 50%;
    left: 0;
    width: 1em;
    height: 1px;
    background-color: #646464; }

.h1.ttl__style_3,
h1.ttl__style_3 {
  padding-left: 24px; }

.h2.ttl__style_3,
h2.ttl__style_3 {
  padding-left: 20px; }

.h3.ttl__style_3,
h3.ttl__style_3 {
  padding-left: 18px; }

.h4.ttl__style_3,
.h5.ttl__style_3,
h4.ttl__style_3,
h5.ttl__style_3 {
  padding-left: 14px; }

.nav_btn {
  display: inline-block;
  overflow: visible; }
  .nav_btn a {
    position: relative;
    display: block;
    padding: 1em 2em 1em 1em;
    line-height: 1.4;
    color: #333;
    border: 1px solid #333;
    text-align: center;
    background-color: #fff;
    outline: none; }
    .nav_btn a::after {
      content: '\f105';
      font-family: 'FontAwesome';
      display: block;
      position: absolute;
      right: 4px;
      top: 50%;
      margin-top: -.5em;
      width: 1em;
      height: 1em;
      line-height: 1;
      text-align: center !important;
      font-size: 1.3em; }
  .nav_btn.btn_wide {
    display: block; }
  .nav_btn.btn_thin a {
    padding: 6px 2em 6px 1em; }
  .nav_btn.btn_fill a {
    background-color: #333;
    color: #fff; }
    .nav_btn.btn_fill a::before {
      color: #fff; }
  .nav_btn.btn_clr1 a {
    border-color: #004A98;
    color: #004A98; }
  .nav_btn.btn_clr1.btn_fill a {
    background-color: #004A98;
    color: #fff; }
  .nav_btn.btn_clr2 a {
    border-color: #AE67BE;
    color: #AE67BE; }
  .nav_btn.btn_clr2.btn_fill a {
    background-color: #AE67BE;
    color: #fff; }
  .nav_btn.btn_clr3 a {
    border-color: #D96D74;
    color: #D96D74; }
  .nav_btn.btn_clr3.btn_fill a {
    background-color: #D96D74;
    color: #fff; }
  .nav_btn.icon-external a {
    padding-right: 1em; }
  .nav_btn.icon-external a::after {
    content: '\f08e';
    top: 8px;
    margin-top: 0;
    font-size: 1.1rem; }
  .nav_btn.icon-xls a {
    padding-right: 1em; }
  .nav_btn.icon-xls a::after {
    content: '\f1c3';
    top: 8px;
    margin-top: 0;
    font-size: 1.1rem;
    color: green; }
  .nav_btn.icon-xls.btn_fill a::after {
    color: #fff; }
  .nav_btn.icon-word a {
    padding-right: 1em; }
  .nav_btn.icon-word a::after {
    content: '\f1c2';
    top: 8px;
    margin-top: 0;
    font-size: 1.1rem;
    color: blue; }
  .nav_btn.icon-word.btn_fill a::after {
    color: #fff; }
  .nav_btn.icon-pdf a {
    padding-right: 1em; }
  .nav_btn.icon-pdf a::after {
    content: '\f1c1';
    top: 8px;
    margin-top: 0;
    font-size: 1.1rem;
    color: red; }
  .nav_btn.btn_fill a::after {
    color: #fff; }

.pagination {
  border-radius: 0; }
  .pagination .page-link {
    font-family: "游ゴシック Medium", "Yu Gothic Medium", "游ゴシック体", YuGothic, "ヒラギノ角ゴ ProN W3", "Hiragino Kaku Gothic ProN", "メイリオ", Meiryo, Verdana, Roboto, "Droid Sans", sans-serif;
    color: #333; }
    @media (min-width: 576px) {
      .pagination .page-link {
        margin-left: 4px;
        margin-right: 4px; } }
  .pagination .page-item:first-child .page-link,
  .pagination .page-item:last-child .page-link {
    border-radius: 0; }
  .pagination .page-ite.active .page-link {
    background-color: #004A98;
    color: #fff;
    border: 1px #004A98 solid; }

.tabnav .nav-tabs {
  display: none; }

@media (min-width: 768px) {
  .tabnav .nav-tabs {
    display: flex; } }

.tabnav .selecton {
  position: relative;
  z-index: 10;
  background-color: #fff;
  border: 1px #adb5bd solid; }
  .tabnav .selecton::after {
    content: '\f078';
    font-family: 'FontAwesome';
    position: absolute;
    display: block;
    width: 0;
    height: 0;
    top: 50%;
    right: 10px;
    margin-top: -.5em;
    width: 1em;
    height: 1em;
    line-height: 1;
    z-index: -1;
    color: #333; }
  .tabnav .selecton select {
    appearance: none;
    display: block;
    width: 100%;
    padding: 10px;
    background-color: transparent;
    border: 0; }
    .tabnav .selecton select option {
      background-color: transparent; }
  @media (min-width: 768px) {
    .tabnav .selecton {
      display: none; } }

.panel-group {
  border-bottom: 1px #dee2e6 solid; }
  .panel-group .panel {
    border-radius: 0;
    border: 0;
    box-shadow: none; }
    .panel-group .panel .panel-heading {
      padding: 0;
      background-color: transparent;
      border-top: 1px #dee2e6 solid;
      border-radius: 0; }
      .panel-group .panel .panel-heading .panel-title {
        position: relative; }
        .panel-group .panel .panel-heading .panel-title a {
          position: relative;
          display: block;
          padding: 14px 1.8em 14px 0;
          color: #333;
          text-decoration: none; }
          .panel-group .panel .panel-heading .panel-title a .icon {
            display: flex;
            align-items: center;
            justify-content: center;
            position: absolute;
            top: 50%;
            right: 2px;
            margin-top: -.5em;
            width: 1.3em;
            height: 1.3em;
            line-height: 1;
            border-radius: 50%;
            background-color: #ffb0b0; }
            .panel-group .panel .panel-heading .panel-title a .icon::before {
              content: '\f107';
              font-family: 'FontAwesome';
              color: #fff;
              font-size: .9em; }
    .panel-group .panel .panel-body {
      padding: 10px; }
  .panel-group .collapse_show > .panel-heading > .panel-title > a {
    color: #ffb0b0; }
    .panel-group .collapse_show > .panel-heading > .panel-title > a .icon {
      background-color: #adb5bd; }
      .panel-group .collapse_show > .panel-heading > .panel-title > a .icon::before {
        content: '\f106'; }

.pageanchor .nav {
  display: none; }

@media (min-width: 768px) {
  .pageanchor {
    padding: 10px 18px;
    background-color: #e9ecef; }
    .pageanchor .nav {
      display: flex; } }

.pageanchor .selecton {
  position: relative;
  z-index: 10;
  background-color: #fff;
  border: 1px #adb5bd solid; }
  .pageanchor .selecton::after {
    content: '\f078';
    font-family: 'FontAwesome';
    position: absolute;
    display: block;
    width: 0;
    height: 0;
    top: 50%;
    right: 10px;
    margin-top: -.5em;
    width: 1em;
    height: 1em;
    line-height: 1;
    z-index: -1;
    color: #333; }
  .pageanchor .selecton select {
    appearance: none;
    display: block;
    width: 100%;
    padding: 10px;
    background-color: transparent;
    border: 0; }
    .pageanchor .selecton select option {
      background-color: transparent; }
  @media (min-width: 768px) {
    .pageanchor .selecton {
      display: none; } }

.table thead tr > th {
  vertical-align: middle;
  text-align: center;
  line-height: 1.4; }

.table tbody tr > th {
  line-height: 1.4; }

.table_layout_fixed {
  table-layout: fixed; }

.table-responsive {
  -webkit-overflow-scrolling: touch; }
  .table-responsive .table tbody tr td,
  .table-responsive .table tbody tr th,
  .table-responsive .table tfoot tr td,
  .table-responsive .table tfoot tr th,
  .table-responsive .table thead tr td,
  .table-responsive .table thead tr th {
    background-color: #fff; }
  .table-responsive .table tbody th,
  .table-responsive .table tfoot th,
  .table-responsive .table thead th {
    white-space: nowrap; }

@media (max-width: 767.98px) {
  .table__break_sm {
    width: 100%; }
    .table__break_sm tbody > tr th,
    .table__break_sm thead > tr th {
      display: block;
      width: 100%;
      border-width: 1px;
      border-color: inherit;
      border-style: solid; }
    .table__break_sm tbody > tr td,
    .table__break_sm thead > tr td {
      display: block;
      width: 100%;
      border-width: 1px;
      border-color: inherit;
      border-style: solid; } }

.table.tbl__style_1 {
  width: 100%;
  border-collapse: collapse; }
  .table.tbl__style_1 td,
  .table.tbl__style_1 th {
    border: 1px #CCC solid;
    word-wrap: break-word;
    overflow-wrap: break-word; }
  .table.tbl__style_1 thead tr th {
    border-bottom-width: 1px;
    font-weight: bold;
    color: #fff;
    background-color: #333; }
  .table.tbl__style_1 thead tr td {
    border-bottom-width: 1px; }
  .table.tbl__style_1 tbody tr th {
    background-color: #D3D3D3;
    vertical-align: middle; }
  .table.tbl__style_1 tbody tr td {
    vertical-align: middle; }
  .table.tbl__style_1 tbody tr .thead_th {
    border-bottom-width: 1px;
    font-weight: bold;
    color: #fff;
    background-color: #333; }
  @media (max-width: 767.98px) {
    .table.tbl__style_1.table__break_sm {
      border-top: 1px #CCC solid; }
      .table.tbl__style_1.table__break_sm s thead tr td,
      .table.tbl__style_1.table__break_sm s thead tr th,
      .table.tbl__style_1.table__break_sm tbody tr td,
      .table.tbl__style_1.table__break_sm tbody tr th {
        border-top: 0; } }

.table.tbl__style_2 td,
.table.tbl__style_2 th {
  border: 0; }

.table.tbl__style_2 thead tr th {
  border-left: 1px #fff solid;
  border-bottom: 1px #fff solid;
  background-color: #498ed0;
  color: #fff; }

.table.tbl__style_2 thead tr th:first-child {
  border-left: 0; }

.table.tbl__style_2 tbody {
  border-top: 1px #CCC solid; }
  .table.tbl__style_2 tbody::before {
    display: none; }
  .table.tbl__style_2 tbody tr td {
    border: 0;
    border-bottom: 1px #CCC solid;
    border-left: 1px #ccc solid; }
    .table.tbl__style_2 tbody tr td:first-child {
      border-left: 0; }
  .table.tbl__style_2 tbody tr th {
    background-color: #fff;
    color: #D96D74;
    background-color: #fdf6f6;
    border: 0;
    border-bottom: 1px #CCC solid;
    border-left: 1px #ccc solid; }
  .table.tbl__style_2 tbody tr .thead_th {
    background-color: #498ed0;
    color: #fff;
    border-bottom-color: #fff; }
  .table.tbl__style_2 tbody tr:last-child .thead_th {
    border-bottom-color: #CCC; }
  .table.tbl__style_2 tbody tr th:first-child {
    border-left: 0; }

@media (max-width: 767.98px) {
  .table.tbl__style_2.table__break_sm {
    border: 1px #CCC solid; }
    .table.tbl__style_2.table__break_sm tbody tr td,
    .table.tbl__style_2.table__break_sm tbody tr th,
    .table.tbl__style_2.table__break_sm thead tr td,
    .table.tbl__style_2.table__break_sm thead tr th {
      border-top: 0;
      border-left: 0; } }

.subbox1 {
  padding: 24px;
  background-color: #D3D3D3; }
  .subbox1__inner {
    border: 1px #ccc solid;
    background-color: #fff;
    padding: 16px; }
  .subbox1.small_box {
    padding: 1em 1.2em; }
  @media (min-width: 768px) {
    .subbox1 {
      padding: 32px; }
      .subbox1__inner {
        padding: 24px; } }

.subbox2 {
  padding: 24px;
  border: 1px #ccc solid;
  background-color: #fff; }
  .subbox2__inner {
    background-color: #e5edf6;
    padding: 16px; }
  .subbox2.small_box {
    padding: 1em 1.2em; }
  @media (min-width: 768px) {
    .subbox2 {
      padding: 32px; }
      .subbox2__inner {
        padding: 24px; } }

.inner, .header_category__wrapper {
  width: 100%;
  max-width: 1080px;
  margin-left: auto;
  margin-right: auto; }
  @media (min-width: 1080px) {
    .inner, .header_category__wrapper {
      width: 1080px; } }

.list__reset,
.list__reset li,
.list__reset ol,
.list__reset ul {
  margin: 0;
  padding: 0;
  list-style: none; }

.list__disc,
.list__disc > li {
  margin: 0;
  padding: 0;
  list-style: none; }

.list__disc > li {
  text-indent: -1em;
  padding-left: 1em;
  list-style-type: disc;
  list-style-position: inside; }
  .list__disc > li > * {
    text-indent: 0; }

.list__num,
.list__num > li {
  margin: 0;
  padding: 0;
  list-style: none; }

.list__num > li {
  text-indent: -1em;
  padding-left: 1em;
  list-style-type: decimal;
  list-style-position: inside; }
  .list__num > li > * {
    text-indent: 0; }

.list__num_kakko {
  counter-reset: item; }
  .list__num_kakko,
  .list__num_kakko > li {
    margin: 0;
    padding: 0;
    list-style: none; }
  .list__num_kakko > li {
    counter-increment: item;
    list-style: none;
    text-indent: -1.5em;
    padding-left: 1.5em; }
    .list__num_kakko > li::before {
      content: "(" counter(item, decimal) ")"; }

.list__num_kome {
  counter-reset: item; }
  .list__num_kome,
  .list__num_kome > li {
    margin: 0;
    padding: 0;
    list-style: none; }
  .list__num_kome > li {
    counter-increment: item;
    list-style: none;
    text-indent: -2em;
    padding-left: 2em; }
    .list__num_kome > li::before {
      content: "※" counter(item, decimal) " "; }

.list__num_zero,
.list__num_zero > li {
  margin: 0;
  padding: 0;
  list-style: none; }

.list__num_zero li {
  text-indent: -1em;
  padding-left: 1em;
  list-style-type: decimal-leading-zero;
  list-style-position: inside; }
  .list__num_zero li > * {
    text-indent: 0; }

.list__num_zero_kakko {
  counter-reset: item; }
  .list__num_zero_kakko,
  .list__num_zero_kakko > li {
    margin: 0;
    padding: 0;
    list-style: none; }
  .list__num_zero_kakko > li {
    counter-increment: item;
    list-style: none;
    text-indent: -2em;
    padding-left: 2em; }
    .list__num_zero_kakko > li::before {
      content: "(" counter(item, decimal-leading-zero) ")"; }

.list__lower_roman,
.list__lower_roman > li {
  margin: 0;
  padding: 0;
  list-style: none; }

.list__lower_roman > li {
  text-indent: -1em;
  padding-left: 1em;
  list-style-type: lower-roman;
  list-style-position: inside; }
  .list__lower_roman > li > * {
    text-indent: 0; }

.list__lower_roman_kakko {
  counter-reset: item; }
  .list__lower_roman_kakko,
  .list__lower_roman_kakko > li {
    margin: 0;
    padding: 0;
    list-style: none; }
  .list__lower_roman_kakko > li {
    counter-increment: item;
    list-style: none;
    text-indent: -1.5em;
    padding-left: 1.5em; }
    .list__lower_roman_kakko > li::before {
      content: "(" counter(item, lower-roman) ")"; }

.list__upper_roman,
.list__upper_roman > li {
  margin: 0;
  padding: 0;
  list-style: none; }

.list__upper_roman > li {
  text-indent: -1em;
  padding-left: 1em;
  list-style-type: upper-roman;
  list-style-position: inside; }
  .list__upper_roman > li > * {
    text-indent: 0; }

.list__upper_roman_kakko {
  counter-reset: item; }
  .list__upper_roman_kakko,
  .list__upper_roman_kakko > li {
    margin: 0;
    padding: 0;
    list-style: none; }
  .list__upper_roman_kakko > li {
    counter-increment: item;
    list-style: none;
    text-indent: -1.5em;
    padding-left: 1.5em; }
    .list__upper_roman_kakko > li::before {
      content: "(" counter(item, upper-roman) ")"; }

.list__lower_latin,
.list__lower_latin > li {
  margin: 0;
  padding: 0;
  list-style: none; }

.list__lower_latin > li {
  text-indent: -1em;
  padding-left: 1em;
  list-style-type: lower-latin;
  list-style-position: inside; }
  .list__lower_latin > li > * {
    text-indent: 0; }

.list__lower_latin_kakko {
  counter-reset: item; }
  .list__lower_latin_kakko,
  .list__lower_latin_kakko > li {
    margin: 0;
    padding: 0;
    list-style: none; }
  .list__lower_latin_kakko > li {
    counter-increment: item;
    list-style: none;
    text-indent: -1.5em;
    padding-left: 1.5em; }
    .list__lower_latin_kakko > li::before {
      content: "(" counter(item, lower-latin) ")"; }

.list__upper_latin,
.list__upper_latin > li {
  margin: 0;
  padding: 0;
  list-style: none; }

.list__upper_latin > li {
  text-indent: -1em;
  padding-left: 1em;
  list-style-type: upper-latin;
  list-style-position: inside; }
  .list__upper_latin > li > * {
    text-indent: 0; }

.list__upper_latin_kakko {
  counter-reset: item; }
  .list__upper_latin_kakko,
  .list__upper_latin_kakko > li {
    margin: 0;
    padding: 0;
    list-style: none; }
  .list__upper_latin_kakko > li {
    counter-increment: item;
    list-style: none;
    text-indent: -1.5em;
    padding-left: 1.5em; }
    .list__upper_latin_kakko > li::before {
      content: "(" counter(item, upper-latin) ")"; }

.list__hiragana,
.list__hiragana > li {
  margin: 0;
  padding: 0;
  list-style: none; }

.list__hiragana > li {
  text-indent: -1.5em;
  padding-left: 1.5em;
  list-style-type: hiragana;
  list-style-position: inside; }
  .list__hiragana > li > * {
    text-indent: 0; }

.list__hiragana_kakko {
  counter-reset: item; }
  .list__hiragana_kakko,
  .list__hiragana_kakko > li {
    margin: 0;
    padding: 0;
    list-style: none; }
  .list__hiragana_kakko > li {
    counter-increment: item;
    list-style: none;
    text-indent: -2em;
    padding-left: 2em; }
    .list__hiragana_kakko > li::before {
      content: "(" counter(item, hiragana) ")"; }

.list__katakana,
.list__katakana > li {
  margin: 0;
  padding: 0;
  list-style: none; }

.list__katakana > li {
  text-indent: -1.5em;
  padding-left: 1.5em;
  list-style-type: katakana;
  list-style-position: inside; }
  .list__katakana > li > * {
    text-indent: 0; }

.list__katakana_kakko {
  counter-reset: item; }
  .list__katakana_kakko,
  .list__katakana_kakko > li {
    margin: 0;
    padding: 0;
    list-style: none; }
  .list__katakana_kakko > li {
    counter-increment: item;
    list-style: none;
    text-indent: -2em;
    padding-left: 2em; }
    .list__katakana_kakko > li::before {
      content: "(" counter(item, katakana) ")"; }

.list__hiragana-iroha,
.list__hiragana-iroha > li {
  margin: 0;
  padding: 0;
  list-style: none; }

.list__hiragana-iroha > li {
  text-indent: -1.5em;
  padding-left: 1.5em;
  list-style-type: hiragana-iroha;
  list-style-position: inside; }
  .list__hiragana-iroha > li > * {
    text-indent: 0; }

.list__hiragana-iroha_kakko {
  counter-reset: item; }
  .list__hiragana-iroha_kakko,
  .list__hiragana-iroha_kakko > li {
    margin: 0;
    padding: 0;
    list-style: none; }
  .list__hiragana-iroha_kakko > li {
    counter-increment: item;
    list-style: none;
    text-indent: -2em;
    padding-left: 2em; }
    .list__hiragana-iroha_kakko > li::before {
      content: "(" counter(item, hiragana-iroha) ")"; }

.list__katakana-iroha,
.list__katakana-iroha > li {
  margin: 0;
  padding: 0;
  list-style: none; }

.list__katakana-iroha > li {
  text-indent: -1.5em;
  padding-left: 1.5em;
  list-style-type: katakana-iroha;
  list-style-position: inside; }
  .list__katakana-iroha > li > * {
    text-indent: 0; }

.list__katakana-iroha_kakko {
  counter-reset: item; }
  .list__katakana-iroha_kakko,
  .list__katakana-iroha_kakko > li {
    margin: 0;
    padding: 0;
    list-style: none; }
  .list__katakana-iroha_kakko > li {
    counter-increment: item;
    list-style: none;
    text-indent: -2em;
    padding-left: 2em; }
    .list__katakana-iroha_kakko > li::before {
      content: "(" counter(item, katakana-iroha) ")"; }

.txt__lead {
  margin-bottom: 1em;
  line-height: 1.4;
  font-size: 2.56rem;
  font-weight: 300; }
  @media (min-width: 768px) {
    .txt__lead {
      font-size: 3.2rem; } }

.txt__lead_s {
  margin-bottom: 1em;
  line-height: 1.4;
  font-size: 2.08rem;
  font-weight: 400; }
  @media (min-width: 768px) {
    .txt__lead_s {
      font-size: 2.56rem; } }

.txt__line_ylw {
  background: linear-gradient(transparent 60%, #ffff26 60%); }

.txt__cap {
  text-indent: -1em;
  padding-left: 1em; }

.fz__large {
  font-size: 120%;
  line-height: 1.4; }

.fz__small {
  font-size: 85%;
  line-height: 1.3; }

.fz__bold {
  font-weight: 700; }

.fz__normal {
  font-weight: 400; }

.color__main {
  color: #004A98; }

.color__sub1 {
  color: #AE67BE; }

.color__sub2 {
  color: #D96D74; }

.color__sub3 {
  color: #F6EBB3; }

.color__red {
  color: #E81826; }

.color__white {
  color: #fff; }

.color__black {
  color: #333; }

.ff__gothic {
  font-family: "游ゴシック Medium", "Yu Gothic Medium", "游ゴシック体", YuGothic, "ヒラギノ角ゴ ProN W3", "Hiragino Kaku Gothic ProN", "メイリオ", Meiryo, Verdana, Roboto, "Droid Sans", sans-serif; }

.ff__mincho {
  font-family: "Noto Serif JP", "游明朝", "Hiragino Mincho ProN", "ヒラギノ明朝 W3 JIS2004", "Times New Roman", Times, serif; }

a[target='_blank']::after {
  content: '\f08e';
  font-family: 'FontAwesome'; }

a[target='_blank'] .arrow-r.yazirushi::after {
  content: '\f08e';
  font-family: 'FontAwesome';
  width: auto;
  height: auto;
  font-size: 1.6rem;
  background: none; }

a[href$='.pdf']::after {
  content: '\f1c1';
  font-family: 'FontAwesome';
  color: red; }

a[href$='.pdf'] .arrow-r.yazirushi::after {
  content: '\f1c1';
  font-family: 'FontAwesome';
  color: red;
  width: auto;
  height: auto;
  font-size: 1.6rem;
  background: none; }

a[href$='.doc']::after, a[href$='.docx']::after {
  content: '\f1c2';
  font-family: 'FontAwesome';
  color: blue; }

a[href$='.doc'] .arrow-r.yazirushi::after, a[href$='.docx'] .arrow-r.yazirushi::after {
  content: '\f1c2';
  font-family: 'FontAwesome';
  color: blue;
  width: auto;
  height: auto;
  font-size: 1.6rem;
  background: none; }

a[href$='.xls']::after, a[href$='.xlsx']::after {
  content: '\f1c3';
  font-family: 'FontAwesome';
  color: green; }

a[href$='.xls'] .arrow-r.yazirushi::after, a[href$='.xlsx'] .arrow-r.yazirushi::after {
  content: '\f1c3';
  font-family: 'FontAwesome';
  color: green;
  width: auto;
  height: auto;
  font-size: 1.6rem;
  background: none; }

a.no-icon::after {
  content: none; }

.spmenu-active a[target='_blank']::after {
  content: "" !important;
  font-family: 'FontAwesome';
  background: none !important;
  height: auto !important; }

body .mrg__btm0 {
  margin-bottom: 0 !important; }

body .mrg__btm05 {
  margin-bottom: .5em !important; }

body .mrg__btm1 {
  margin-bottom: 1em !important; }

body .mrg__btm2 {
  margin-bottom: 2em !important; }

body .mrg__btm3 {
  margin-bottom: 3em !important; }

body .mrg__btm4 {
  margin-bottom: 4em !important; }

body .mrg__btm5 {
  margin-bottom: 5em !important; }

body .mrg__btm6 {
  margin-bottom: 6em !important; }

body .mrg__btm_gutter {
  margin-bottom: 30px !important; }

body .mrg__top0 {
  margin-top: 0 !important; }

body .mrg__top05 {
  margin-top: .5em !important; }

body .mrg__top1 {
  margin-top: 1em !important; }

body .mrg__top2 {
  margin-top: 2em !important; }

body .mrg__top3 {
  margin-top: 3em !important; }

body .mrg__top_gutter {
  margin-top: 30px !important; }

body .mrg__left0 {
  margin-left: 0 !important; }

body .mrg__left1 {
  margin-left: 1em !important; }

body .mrg__left2 {
  margin-left: 2em !important; }

body .mrg__left3 {
  margin-left: 3em !important; }

body .mrg__left_gutter {
  margin-left: 30px !important; }

body .mrg__right0 {
  margin-right: 0 !important; }

body .mrg__right1 {
  margin-right: 1em !important; }

body .mrg__right2 {
  margin-right: 2em !important; }

body .mrg__right3 {
  margin-right: 3em !important; }

body .mrg__right_gutter {
  margin-right: 30px !important; }

body .pad__top0 {
  padding-top: 0 !important; }

body .pad__top1 {
  padding-top: 1em !important; }

body .pad__top2 {
  padding-top: 2em !important; }

body .pad__top3 {
  padding-top: 3em !important; }

body .pad__top_gutter {
  padding-top: 30px !important; }

body .pad__btm0 {
  padding-bottom: 0 !important; }

body .pad__btm1 {
  padding-bottom: 1em !important; }

body .pad__btm2 {
  padding-bottom: 2em !important; }

body .pad__btm3 {
  padding-bottom: 3em !important; }

body .pad__btm_gutter {
  padding-bottom: 30px !important; }

body .pad__left0 {
  padding-left: 0 !important; }

body .pad__left1 {
  padding-left: 1em !important; }

body .pad__left2 {
  padding-left: 2em !important; }

body .pad__left3 {
  padding-left: 3em !important; }

body .pad__left_gutter {
  padding-left: 30px !important; }

body .pad__right0 {
  padding-right: 0 !important; }

body .pad__right1 {
  padding-right: 1em !important; }

body .pad__right2 {
  padding-right: 2em !important; }

body .pad__right3 {
  padding-right: 3em !important; }

body .pad__right_gutter {
  padding-right: 30px !important; }

@media (min-width: 576px) {
  body .mrg_btm_sm_0 {
    margin-bottom: 0 !important; } }

@media (min-width: 768px) {
  body .mrg_btm_md_0 {
    margin-bottom: 0 !important; } }

@media (min-width: 1080px) {
  body .mrg_btm_lg_0 {
    margin-bottom: 0 !important; } }

@media (min-width: 1400px) {
  body .mrg_btm_xl_0 {
    margin-bottom: 0 !important; } }

@media (min-width: 576px) {
  body .pad_btm_sm_0 {
    padding-bottom: 0 !important; } }

@media (min-width: 768px) {
  body .pad_btm_md_0 {
    padding-bottom: 0 !important; } }

@media (min-width: 1080px) {
  body .pad_btm_lg_0 {
    padding-bottom: 0 !important; } }

@media (min-width: 1400px) {
  body .pad_btm_xl_0 {
    padding-bottom: 0 !important; } }

@-ms-viewport {
  width: auto;
  initial-scale: 1; }

img {
  -webkit-backface-visibility: hidden; }

html {
  font-size: 62.5%;
  word-break: break-word; }
  html.en main {
    font-family: Roboto, "游ゴシック Medium", "Yu Gothic Medium", "游ゴシック体", YuGothic, "ヒラギノ角ゴ ProN W3", "Hiragino Kaku Gothic ProN", "メイリオ", Meiryo, Verdana, "Droid Sans", sans-serif !important; }

body {
  width: 100%;
  letter-spacing: .06em;
  color: #333;
  background-color: #fff;
  font-feature-settings: 'palt';
  font-size: 1.44rem; }
  @media (min-width: 768px) {
    body {
      font-size: 1.6rem; } }
  @media (min-width: 768px) {
    body.menu-open {
      overflow: hidden; } }
  body.search a[target='_blank']::after {
    display: none; }

img {
  max-width: 100%;
  height: auto; }
  @media (max-width: 767.98px) {
    img {
      width: auto; } }

a {
  transition: all .2s linear;
  opacity: 1; }
  a:hover {
    opacity: .6; }

ol,
ul {
  padding-left: 1em; }
  ol li,
  ul li {
    padding-left: 0; }

input[type='number']::-webkit-outer-spin-button,
input[type='number']::-webkit-inner-spin-button {
  margin: 0;
  -webkit-appearance: none; }

input[type='number'] {
  -moz-appearance: textfield; }

.h1,
h1 {
  font-size: 3.52rem;
  font-weight: bold;
  margin-top: 0;
  margin-bottom: 1.5em; }
  @media (min-width: 768px) {
    .h1,
    h1 {
      font-size: 4.4rem; } }

.h2,
h2 {
  font-size: 2.56rem;
  font-weight: bold;
  margin-top: 0;
  margin-bottom: .8em; }
  @media (min-width: 768px) {
    .h2,
    h2 {
      font-size: 3.2rem;
      margin-bottom: 1em; } }

.h3,
h3 {
  font-size: 2.24rem;
  font-weight: bold;
  margin-top: 0;
  margin-bottom: .8em; }
  @media (min-width: 768px) {
    .h3,
    h3 {
      font-size: 2.8rem;
      margin-bottom: 1em; } }

.h4,
h4 {
  font-size: 1.768rem;
  font-weight: bold;
  margin-top: 0;
  margin-bottom: .8em; }
  @media (min-width: 768px) {
    .h4,
    h4 {
      font-size: 2.08rem;
      margin-bottom: 1em; } }

.h5,
h5 {
  font-size: 1.632rem;
  font-weight: bold;
  margin-top: 0;
  margin-bottom: .8em; }
  @media (min-width: 768px) {
    .h5,
    h5 {
      font-size: 1.92rem;
      margin-bottom: 1em; } }

figcaption {
  font-size: 1.1rem;
  display: block;
  padding-top: .2em;
  text-align: center;
  color: #999; }

*:last-child {
  margin-bottom: 0; }

.fa-file-pdf-o {
  color: red; }

.fa-file-word-o {
  color: blue; }

.fa-file-excel-o {
  color: green; }

@media print, screen and (min-width: 768px) {
  .sp_only {
    display: none !important; } }

@media screen and (max-width: 767px) {
  .pc_only {
    display: none !important; } }

.mt-be-column iframe[src^="https://www.youtube.com/"] {
  width: 100%; }

iframe[src^="https://www.google.com/maps/"] {
  width: 100%;
  height: auto;
  aspect-ratio: 16/9; }
  @media (max-width: 767.98px) {
    iframe[src^="https://www.google.com/maps/"] {
      aspect-ratio: 1/1; } }

iframe[src^="https://www.youtube.com/"] {
  width: 50%;
  height: auto;
  aspect-ratio: 16/9; }
  @media (max-width: 767.98px) {
    iframe[src^="https://www.youtube.com/"] {
      width: 100%; } }

.indent {
  padding-left: 1em; }

.bg-gray {
  background-color: #EFEFEF; }
  .bg-gray .box_style1 {
    background-color: #fff; }

.page-ttl_style1-ttl {
  font-family: "Noto Serif JP", "游明朝", "Hiragino Mincho ProN", "ヒラギノ明朝 W3 JIS2004", "Times New Roman", Times, serif;
  font-weight: 400;
  font-weight: bold;
  display: flex;
  justify-content: space-between;
  padding-top: 80px;
  padding-bottom: 40px; }
  @media (min-width: 768px) {
    .page-ttl_style1-ttl {
      font-size: 3.4rem; } }
  @media (max-width: 767.98px) {
    .page-ttl_style1-ttl {
      font-size: 3rem; } }
  @media (min-width: 768px) {
    .page-ttl_style1-ttl {
      margin-bottom: 0px; } }
  @media (max-width: 767.98px) {
    .page-ttl_style1-ttl {
      margin-bottom: 0px; } }
  @media (max-width: 767.98px) {
    .page-ttl_style1-ttl {
      display: block;
      padding-top: 40px; } }
  .page-ttl_style1-ttl .subcategory {
    display: flex;
    color: #004A98;
    font-family: "Crimson Text", Times, serif;
    font-weight: 400;
    align-items: center; }
    @media (min-width: 768px) {
      .page-ttl_style1-ttl .subcategory {
        font-size: 2rem; } }
    @media (max-width: 767.98px) {
      .page-ttl_style1-ttl .subcategory {
        font-size: 1.8rem; } }
    @media (max-width: 767.98px) {
      .page-ttl_style1-ttl .subcategory {
        margin-top: 5px; } }
    .page-ttl_style1-ttl .subcategory::before {
      content: "";
      background: #004A98;
      width: 50px;
      height: 1px;
      margin-right: 5px; }

.page-ttl_style1 .page-ttl_style1-mv {
  text-align: center; }

.page-ttl_style2 {
  position: relative;
  background: #fff; }
  .page-ttl_style2::before {
    content: "";
    width: 50%;
    height: 100%;
    background: linear-gradient(#004a98 0%, #152740 100%);
    display: block;
    position: absolute;
    z-index: 0; }
  .page-ttl_style2-inner {
    display: flex;
    align-items: flex-start;
    position: relative;
    z-index: 1;
    padding-top: 120px;
    padding-bottom: 80px; }
    @media (max-width: 767.98px) {
      .page-ttl_style2-inner {
        padding-top: 60px;
        padding-bottom: 70px; } }
    .page-ttl_style2-inner .ttl_style6 {
      -ms-writing-mode: tb-rl;
      writing-mode: vertical-rl;
      color: #fff;
      padding: 0; }
      @media (max-width: 767.98px) {
        .page-ttl_style2-inner .ttl_style6 {
          margin-left: 0;
          margin-right: 15px;
          font-size: 4rem; } }
      .page-ttl_style2-inner .ttl_style6 span {
        color: #fff; }
  .page-ttl_style2-box {
    background: #fff;
    width: 100%;
    padding: 30px 20px;
    margin-bottom: 30px; }
    @media (max-width: 767.98px) {
      .page-ttl_style2-box {
        padding: 30px 20px 30px 20px; } }
    .page-ttl_style2-box:last-child {
      margin-bottom: 0px; }

.page-ttl_style3-ttl {
  font-family: "Noto Serif JP", "游明朝", "Hiragino Mincho ProN", "ヒラギノ明朝 W3 JIS2004", "Times New Roman", Times, serif;
  font-weight: 400;
  text-align: center;
  font-weight: 600;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 80px 0 20px; }
  @media (min-width: 768px) {
    .page-ttl_style3-ttl {
      font-size: 3.4rem; } }
  @media (max-width: 767.98px) {
    .page-ttl_style3-ttl {
      font-size: 3rem; } }
  .page-ttl_style3-ttl::after {
    content: "";
    height: 50px;
    width: 1px;
    background: #333;
    margin-top: 12px; }

.page-ttl_style4-ttl {
  position: absolute;
  top: 0;
  left: 50%;
  transform: translateX(-50%);
  color: #fff;
  display: flex;
  justify-content: flex-start;
  flex-direction: column;
  align-items: center;
  height: 100%;
  font-family: "Noto Serif JP", "游明朝", "Hiragino Mincho ProN", "ヒラギノ明朝 W3 JIS2004", "Times New Roman", Times, serif;
  font-weight: 400;
  font-weight: 600; }
  @media (min-width: 768px) {
    .page-ttl_style4-ttl {
      font-size: 3.4rem; } }
  @media (max-width: 767.98px) {
    .page-ttl_style4-ttl {
      font-size: 2rem; } }
  .page-ttl_style4-ttl::before {
    content: "";
    background: #fff;
    width: 1px;
    height: 40%; }

.page-ttl_style4-mv {
  position: relative;
  text-align: center; }

.ttl_style1, h2:not([class]) {
  font-family: "Noto Serif JP", "游明朝", "Hiragino Mincho ProN", "ヒラギノ明朝 W3 JIS2004", "Times New Roman", Times, serif;
  font-weight: 400;
  font-weight: bold; }
  @media (min-width: 768px) {
    .ttl_style1, h2:not([class]) {
      font-size: 3rem; } }
  @media (max-width: 767.98px) {
    .ttl_style1, h2:not([class]) {
      font-size: 2.4rem; } }
  @media (min-width: 768px) {
    .ttl_style1, h2:not([class]) {
      margin-top: 90px; } }
  @media (max-width: 767.98px) {
    .ttl_style1, h2:not([class]) {
      margin-top: 90px; } }
  @media (min-width: 768px) {
    .ttl_style1, h2:not([class]) {
      margin-bottom: 70px; } }
  @media (max-width: 767.98px) {
    .ttl_style1, h2:not([class]) {
      margin-bottom: 70px; } }

@media (max-width: 767.98px) and (min-width: 768px) {
  .ttl_style1, h2:not([class]) {
    margin-top: 70px; } }

@media (max-width: 767.98px) and (max-width: 767.98px) {
  .ttl_style1, h2:not([class]) {
    margin-top: 70px; } }

@media (max-width: 767.98px) and (min-width: 768px) {
  .ttl_style1, h2:not([class]) {
    margin-bottom: 50px; } }

@media (max-width: 767.98px) and (max-width: 767.98px) {
  .ttl_style1, h2:not([class]) {
    margin-bottom: 50px; } }
  .ttl_style1::before, h2:not([class])::before {
    content: "";
    background: #004A98;
    width: 135px;
    height: 5px;
    display: block; }
    @media (min-width: 768px) {
      .ttl_style1::before, h2:not([class])::before {
        margin-bottom: 40px; } }
    @media (max-width: 767.98px) {
      .ttl_style1::before, h2:not([class])::before {
        margin-bottom: 40px; } }

@media (max-width: 767.98px) and (min-width: 768px) {
  .ttl_style1::before, h2:not([class])::before {
    margin-bottom: 20px; } }

@media (max-width: 767.98px) and (max-width: 767.98px) {
  .ttl_style1::before, h2:not([class])::before {
    margin-bottom: 20px; } }

.ttl_style2, h3:not([class]) {
  font-family: "Noto Serif JP", "游明朝", "Hiragino Mincho ProN", "ヒラギノ明朝 W3 JIS2004", "Times New Roman", Times, serif;
  font-weight: 400;
  font-weight: bold; }
  @media (min-width: 768px) {
    .ttl_style2, h3:not([class]) {
      font-size: 2.6rem; } }
  @media (max-width: 767.98px) {
    .ttl_style2, h3:not([class]) {
      font-size: 2.2rem; } }
  @media (min-width: 768px) {
    .ttl_style2, h3:not([class]) {
      margin-top: 90px; } }
  @media (max-width: 767.98px) {
    .ttl_style2, h3:not([class]) {
      margin-top: 90px; } }
  @media (min-width: 768px) {
    .ttl_style2, h3:not([class]) {
      margin-bottom: 40px; } }
  @media (max-width: 767.98px) {
    .ttl_style2, h3:not([class]) {
      margin-bottom: 40px; } }
  .ttl_style2::after, h3:not([class])::after {
    content: "";
    width: 100%;
    height: 3px;
    display: block;
    background: linear-gradient(90deg, #004A98 0%, #004A98 100px, #D3D3D3 100px, #D3D3D3 100%); }
    @media (min-width: 768px) {
      .ttl_style2::after, h3:not([class])::after {
        margin-top: 10px; } }
    @media (max-width: 767.98px) {
      .ttl_style2::after, h3:not([class])::after {
        margin-top: 10px; } }

.ttl_style3, h4:not([class]) {
  font-weight: bold;
  display: flex;
  line-height: 1; }
  @media (min-width: 768px) {
    .ttl_style3, h4:not([class]) {
      font-size: 2.4rem; } }
  @media (max-width: 767.98px) {
    .ttl_style3, h4:not([class]) {
      font-size: 2rem; } }
  @media (min-width: 768px) {
    .ttl_style3, h4:not([class]) {
      margin-top: 60px; } }
  @media (max-width: 767.98px) {
    .ttl_style3, h4:not([class]) {
      margin-top: 60px; } }
  @media (min-width: 768px) {
    .ttl_style3, h4:not([class]) {
      margin-bottom: 30px; } }
  @media (max-width: 767.98px) {
    .ttl_style3, h4:not([class]) {
      margin-bottom: 30px; } }
  .ttl_style3::before, h4:not([class])::before {
    content: "";
    background: #004A98;
    width: 5px;
    margin-right: 10px; }

.ttl_style4, h5:not([class]) {
  color: #004A98;
  border-bottom: 1px solid #333;
  font-weight: bold; }
  @media (min-width: 768px) {
    .ttl_style4, h5:not([class]) {
      margin-top: 40px; } }
  @media (max-width: 767.98px) {
    .ttl_style4, h5:not([class]) {
      margin-top: 40px; } }
  @media (min-width: 768px) {
    .ttl_style4, h5:not([class]) {
      margin-bottom: 30px; } }
  @media (max-width: 767.98px) {
    .ttl_style4, h5:not([class]) {
      margin-bottom: 30px; } }
  @media (min-width: 768px) {
    .ttl_style4, h5:not([class]) {
      padding-bottom: 10px; } }
  @media (max-width: 767.98px) {
    .ttl_style4, h5:not([class]) {
      padding-bottom: 10px; } }
  @media (min-width: 768px) {
    .ttl_style4, h5:not([class]) {
      font-size: 2rem; } }
  @media (max-width: 767.98px) {
    .ttl_style4, h5:not([class]) {
      font-size: 1.8rem; } }

.ttl_style5, h6:not([class]) {
  color: #333;
  font-weight: bold; }
  @media (min-width: 768px) {
    .ttl_style5, h6:not([class]) {
      margin-top: 30px; } }
  @media (max-width: 767.98px) {
    .ttl_style5, h6:not([class]) {
      margin-top: 30px; } }
  @media (min-width: 768px) {
    .ttl_style5, h6:not([class]) {
      margin-bottom: 30px; } }
  @media (max-width: 767.98px) {
    .ttl_style5, h6:not([class]) {
      margin-bottom: 30px; } }
  @media (min-width: 768px) {
    .ttl_style5, h6:not([class]) {
      font-size: 1.8rem; } }
  @media (max-width: 767.98px) {
    .ttl_style5, h6:not([class]) {
      font-size: 1.6rem; } }
  .ttl_style5.color__main, h6.color__main:not([class]) {
    color: #004A98; }

.ttl_style6 {
  font-family: "Crimson Text", Times, serif;
  font-weight: 400;
  line-height: 1.0;
  margin-bottom: 30px; }
  @media (min-width: 768px) {
    .ttl_style6 {
      font-size: 8rem; } }
  @media (max-width: 767.98px) {
    .ttl_style6 {
      font-size: 6rem; } }
  .ttl_style6 span {
    color: #004A98;
    font-family: "游ゴシック Medium", "Yu Gothic Medium", "游ゴシック体", YuGothic, "ヒラギノ角ゴ ProN W3", "Hiragino Kaku Gothic ProN", "メイリオ", Meiryo, Verdana, Roboto, "Droid Sans", sans-serif;
    font-weight: 400;
    font-weight: bold;
    display: block;
    margin-top: 7px; }
    @media (min-width: 768px) {
      .ttl_style6 span {
        font-size: 1.8rem; } }
    @media (max-width: 767.98px) {
      .ttl_style6 span {
        font-size: 1.6rem; } }

.ttl_style7 {
  color: #004A98;
  display: flex;
  align-items: flex-start;
  font-weight: bold; }
  @media (min-width: 768px) {
    .ttl_style7 {
      font-size: 1.8rem; } }
  @media (max-width: 767.98px) {
    .ttl_style7 {
      font-size: 1.6rem; } }
  .ttl_style7::before {
    content: "";
    height: 2px;
    width: 20px;
    background-color: #004A98;
    margin-top: 16px;
    font-weight: bold;
    margin-right: 10px; }

h2:not([class]) {
  margin-top: 0 !important; }

h3:not([class]) {
  margin-top: 0 !important; }

h4:not([class]) {
  margin-top: 0 !important; }

h5:not([class]) {
  margin-top: 0 !important; }

h6:not([class]) {
  margin-top: 0 !important; }

.lead_style1 {
  font-family: "Noto Serif JP", "游明朝", "Hiragino Mincho ProN", "ヒラギノ明朝 W3 JIS2004", "Times New Roman", Times, serif;
  font-weight: 400;
  font-weight: bold; }
  @media (min-width: 768px) {
    .lead_style1 {
      font-size: 2.6rem; } }
  @media (max-width: 767.98px) {
    .lead_style1 {
      font-size: 2.2rem; } }
  @media (min-width: 768px) {
    .lead_style1 {
      margin-bottom: 30px; } }
  @media (max-width: 767.98px) {
    .lead_style1 {
      margin-bottom: 30px; } }

[class^="ttl_style"] + [class^="ttl_style"],
[class^="ttl_style"]:first-child {
  margin-top: 0; }

.link_style1 {
  margin: 10px 0; }
  .link_style1 a {
    color: #333;
    position: relative;
    padding-left: 15px; }
    .link_style1 a::before {
      content: '\f105';
      font-family: 'FontAwesome';
      position: absolute;
      left: 0px;
      top: 50%;
      transform: translateY(-50%); }
    .link_style1 a:hover {
      color: #333; }

.btn_style {
  margin: 10px 0; }
  .btn_style.w-half a {
    width: 50%; }
    @media (max-width: 767.98px) {
      .btn_style.w-half a {
        width: 100% !important; } }
  .btn_style.m-center a {
    margin: 0 auto; }
  .btn_style.m-right a {
    margin: 0 0 0 auto; }
  .btn_style a {
    display: block;
    width: 100%;
    padding: 15px 30px 15px 20px;
    position: relative;
    text-decoration: none; }
    .btn_style a::after {
      position: absolute;
      right: 20px;
      top: 50%;
      transform: translateY(-50%); }
    .btn_style a:hover {
      text-decoration: none;
      opacity: 1; }
  .btn_style1 a {
    border: 1px solid #333; }
    .btn_style1 a:not([target="_blank"]):not([href*=".pdf"]):not([href*=".doc"]):not([href*=".docx"]):not([href*=".xls"]):not([href*=".xlsx"])::after {
      content: "";
      background-image: url(/img/common/arrow_next_black.svg);
      background-repeat: no-repeat;
      width: 15px;
      height: 7px;
      margin-left: 10px; }
    .btn_style1 a:hover {
      background: #004A98;
      border: 1px solid #004A98;
      color: #fff; }
      .btn_style1 a:hover::after {
        color: #fff !important; }
      .btn_style1 a:hover:not([target="_blank"]):not([href*=".pdf"]):not([href*=".doc"]):not([href*=".docx"]):not([href*=".xls"]):not([href*=".xlsx"])::after {
        background-image: url(/img/common/arrow_next_white2.svg); }
  .btn_style2 {
    margin: 0; }
    .btn_style2 a {
      border-bottom: 1px solid #333;
      padding: 12px 2.5em 12px 0; }
      .btn_style2 a:not([target="_blank"]):not([href*=".pdf"]):not([href*=".doc"]):not([href*=".docx"]):not([href*=".xls"]):not([href*=".xlsx"])::after {
        font-family: 'FontAwesome';
        content: '\f105';
        position: absolute;
        display: inline-block; }
      .btn_style2 a::after {
        right: 10px; }
      .btn_style2 a:hover {
        color: #004A98; }
  .btn_style3 a {
    border: 1px solid #004A98;
    background-color: #004A98;
    color: #fff; }
    .btn_style3 a::after {
      color: #fff; }
    .btn_style3 a:not([target="_blank"]):not([href*=".pdf"]):not([href*=".doc"]):not([href*=".docx"]):not([href*=".xls"]):not([href*=".xlsx"])::after {
      content: "";
      background-image: url(/img/common/arrow_next_white2.svg);
      background-repeat: no-repeat;
      width: 15px;
      height: 7px;
      margin-left: 10px; }
    .btn_style3 a:hover {
      background: #fff;
      border: 1px solid #004A98;
      color: #004A98; }
      .btn_style3 a:hover[target="_blank"]::after {
        color: #333; }
      .btn_style3 a:hover[href*=".pdf"]::after {
        color: red; }
      .btn_style3 a:hover[href*=".doc"][href*=".docx"]::after {
        color: blue; }
      .btn_style3 a:hover[href*=".xls"][href*=".xlsx"]::after {
        color: green; }
      .btn_style3 a:hover:not([target="_blank"]):not([href*=".pdf"]):not([href*=".doc"]):not([href*=".docx"]):not([href*=".xls"]):not([href*=".xlsx"])::after {
        background-image: url(/img/common/arrow_next_main.svg); }
  .btn_style_adm a {
    display: flex;
    align-items: center;
    height: 80px;
    background: #fff;
    border: 1px solid #fff;
    font-weight: bold;
    line-height: 1.56; }
    @media (min-width: 768px) {
      .btn_style_adm a {
        font-size: 1.8rem; } }
    @media (max-width: 767.98px) {
      .btn_style_adm a {
        font-size: 1.6rem; } }
    .btn_style_adm a::after {
      content: "";
      background-image: url(/img/common/arrow_next_black.svg);
      background-repeat: no-repeat;
      width: 15px;
      height: 7px;
      margin-left: 10px; }
    .btn_style_adm a:hover {
      background: #004A98;
      border: 1px solid #004A98;
      color: #fff; }
      .btn_style_adm a:hover::after {
        background-image: url(/img/common/arrow_next_white2.svg); }
    .btn_style_adm a img {
      position: absolute;
      left: 30px;
      top: 50%;
      transform: translateY(-50%);
      display: inline-block;
      width: 50px; }
  .btn_style_adm.text-center a {
    justify-content: center; }
  .btn_style_adm.text-right a {
    justify-content: flex-end; }

.tab_style1 {
  display: flex;
  flex-wrap: wrap;
  padding-left: 0;
  justify-content: space-between;
  margin-bottom: 1em; }
  .tab_style1 li {
    list-style: none;
    margin-top: 1em !important;
    text-align: center;
    border-bottom: 3px solid #D3D3D3;
    width: 24%; }
    @media (max-width: 767.98px) {
      .tab_style1 li {
        margin-top: 0 !important; } }
    .tab_style1 li:first-child:nth-last-child(2), .tab_style1 li:first-child:nth-last-child(2) ~ li {
      width: 48%; }
    .tab_style1 li:first-child:nth-last-child(3), .tab_style1 li:first-child:nth-last-child(3) ~ li, .tab_style1 li:first-child:nth-last-child(6), .tab_style1 li:first-child:nth-last-child(6) ~ li {
      width: 32.5%; }
    .tab_style1 li:first-child:nth-last-child(4), .tab_style1 li:first-child:nth-last-child(4) ~ li, .tab_style1 li:first-child:nth-last-child(7), .tab_style1 li:first-child:nth-last-child(7) ~ li, .tab_style1 li:first-child:nth-last-child(8), .tab_style1 li:first-child:nth-last-child(8) ~ li {
      width: 24%; }
    .tab_style1 li:first-child:nth-last-child(5), .tab_style1 li:first-child:nth-last-child(5) ~ li, .tab_style1 li:first-child:nth-last-child(n + 9), .tab_style1 li:first-child:nth-last-child(n + 9) ~ li {
      width: 19%; }
    @media (max-width: 767.98px) {
      .tab_style1 li {
        width: 100% !important;
        border-bottom: 0;
        padding: 0; } }
    .tab_style1 li.active {
      border-bottom: 3px solid #004A98; }
      .tab_style1 li.active a, .tab_style1 li.active p {
        position: relative;
        color: #004A98; }
    .tab_style1 li a, .tab_style1 li p {
      padding-bottom: 5px;
      text-decoration: none;
      display: block;
      color: #D3D3D3;
      font-weight: bold; }
      @media (max-width: 767.98px) {
        .tab_style1 li a, .tab_style1 li p {
          padding: 1em 0; }
          .tab_style1 li a:hover, .tab_style1 li p:hover {
            background: #D3D3D3; } }
  .tab_style1box {
    position: relative;
    margin-top: 1em; }
  @media (max-width: 767.98px) {
    .tab_style1head {
      width: 100%;
      display: block;
      padding: 1em;
      border: 1px solid #333;
      cursor: pointer; }
      .tab_style1head:hover {
        text-decoration: none; }
      .tab_style1head.dropdown-toggle {
        display: flex;
        justify-content: space-between;
        align-items: center; }
        .tab_style1head.dropdown-toggle::after {
          font-family: FontAwesome;
          content: '\f107';
          border: none;
          color: #004A98; } }

ul:not([class]) > li {
  list-style: none;
  position: relative;
  padding-left: 1.2em;
  margin-bottom: 5px; }
  ul:not([class]) > li::before {
    content: "";
    position: absolute;
    top: 0.6em;
    left: 0;
    display: inline-flex;
    justify-content: center;
    align-items: center;
    width: 6px;
    height: 6px;
    text-align: center;
    background: #004A98;
    font-family: "Crimson Text", Times, serif;
    font-weight: 400; }
    @media (min-width: 768px) {
      ul:not([class]) > li::before {
        font-size: 1.6rem; } }
    @media (max-width: 767.98px) {
      ul:not([class]) > li::before {
        font-size: 1.6rem; } }

ol {
  padding-left: 0; }
  ol:not([class]) > li {
    list-style: none;
    counter-increment: item;
    position: relative;
    padding-left: 2.2em;
    margin-bottom: 5px; }
    ol:not([class]) > li::before {
      position: absolute;
      top: 0.2em;
      left: 0;
      display: inline-flex;
      justify-content: center;
      align-items: center;
      content: counter(item);
      width: 24px;
      height: 24px;
      text-align: center;
      color: #fff;
      background: #004A98;
      font-family: "Crimson Text", Times, serif;
      font-weight: 400; }
      @media (min-width: 768px) {
        ol:not([class]) > li::before {
          font-size: 1.6rem; } }
      @media (max-width: 767.98px) {
        ol:not([class]) > li::before {
          font-size: 1.6rem; } }
    ol:not([class]) > li a {
      color: #333;
      text-decoration: underline; }
      ol:not([class]) > li a:hover {
        color: #333; }

.list_style1 {
  padding-left: 1em;
  list-style-type: '\f105'; }
  .list_style1 > li {
    margin-bottom: 5px; }
    .list_style1 > li a {
      text-decoration: none; }
    .list_style1 > li::marker {
      content: "\f105";
      color: #004A98;
      font-family: fontawesome;
      letter-spacing: 0.4em; }

.tag_style1 {
  margin: 0;
  padding: 0;
  list-style: none;
  display: inline-block; }
  .tag_style1 > li {
    display: inline-block;
    color: #fff;
    background: #004A98;
    padding: 0 0.4em; }
    .tag_style1 > li:not(:first-child) {
      margin-left: 10px; }

.box_style1 {
  border: 1px solid #D3D3D3;
  padding: 30px !important; }
  @media (max-width: 767.98px) {
    .box_style1 {
      padding: 30px 15px; } }
  @media (min-width: 768px) {
    .box_style1 + .box_style1 {
      margin-top: 40px; } }
  @media (max-width: 767.98px) {
    .box_style1 + .box_style1 {
      margin-top: 30px; } }
  .box_style1.bg-gray {
    background-color: #EFEFEF; }

.pagelink_style1 {
  margin: 0;
  padding: 0;
  list-style: none;
  display: flex;
  flex-wrap: wrap;
  justify-content: center; }
  @media (max-width: 767.98px) {
    .pagelink_style1 {
      display: block; } }
  .pagelink_style1 li {
    border-right: 1px solid #999;
    border-left: 1px solid #999;
    margin-left: -1px;
    margin-bottom: 30px; }
    @media (max-width: 767.98px) {
      .pagelink_style1 li {
        margin-left: 0;
        border-right: none;
        border-left: none;
        border-bottom: 1px solid #999; } }
    .pagelink_style1 li:last-child {
      border-right: 1px solid #999; }
      @media (max-width: 767.98px) {
        .pagelink_style1 li:last-child {
          border-right: none; } }
    .pagelink_style1 li a {
      padding: 0 20px;
      display: block;
      position: relative;
      text-decoration: none; }
      @media (max-width: 767.98px) {
        .pagelink_style1 li a {
          padding: 0 27px 0 10px; } }
      .pagelink_style1 li a::after {
        font-family: fontawesome;
        content: "\f107";
        position: absolute;
        color: #004A98;
        transition: .3s;
        left: 50%;
        bottom: -25px;
        transform: translateX(-50%); }
        @media (max-width: 767.98px) {
          .pagelink_style1 li a::after {
            left: auto;
            transform: none;
            bottom: 0;
            right: 10px; } }

/****
** アコーディオン
***/
summary::-webkit-details-marker {
  display: none; }

div[id] + .accordion {
  border-top: none; }
  div[id] + .accordion:first-of-type {
    border-top: 1px solid #333; }

div[id]:empty {
  visibility: hidden;
  padding-top: 70px !important;
  margin-top: -70px !important; }
  @media (max-width: 767.98px) {
    div[id]:empty {
      padding-top: 40px !important;
      margin-top: -40px !important; } }

.accordion {
  border-top: 1px solid #333;
  border-bottom: 1px solid #333; }
  .accordion + .accordion {
    border-top: none; }
  .accordion__trigger {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 20px 25px 20px;
    font-weight: bold; }
    @media (min-width: 768px) {
      .accordion__trigger {
        font-size: 2rem; } }
    @media (max-width: 767.98px) {
      .accordion__trigger {
        font-size: 1.8rem; } }
  .accordion__icon {
    position: relative;
    display: block;
    width: 30px;
    height: 30px;
    border-radius: 50%;
    padding: 2px;
    min-width: 30px;
    margin-left: 10px; }
    .accordion__icon::before, .accordion__icon::after {
      display: flex;
      justify-content: center;
      align-items: center;
      content: "";
      position: absolute;
      background: #333;
      transition: all 0.25s ease-in-out;
      transform-origin: center center;
      top: 50%;
      left: 50%;
      width: 25px;
      height: 1px; }
    .accordion__icon::before {
      transform: translateX(-50%) rotate(90deg); }
    .accordion__icon::after {
      transform: translateX(-50%); }
  .accordion__target {
    overflow: hidden;
    height: auto; }
  .accordion__inner {
    padding: 25px 25px 60px 25px; }
  .accordion.faq {
    border-top: 1px solid #D3D3D3;
    border-bottom: 1px solid #D3D3D3; }
    .accordion.faq + .accordion.faq {
      border-top: none; }
    .accordion.faq .accordion__trigger {
      color: #0c2444;
      position: relative;
      flex: 1;
      padding: 25px 50px 25px 70px; }
      @media (min-width: 768px) {
        .accordion.faq .accordion__trigger {
          font-size: 1.8rem; } }
      @media (max-width: 767.98px) {
        .accordion.faq .accordion__trigger {
          font-size: 1.6rem; } }
      .accordion.faq .accordion__trigger::before {
        position: absolute;
        top: -0.1em;
        left: 10px;
        width: 1em;
        content: 'Q';
        font-family: "Crimson Text", Times, serif;
        font-weight: 400;
        color: #004A98; }
        @media (min-width: 768px) {
          .accordion.faq .accordion__trigger::before {
            font-size: 4rem; } }
        @media (max-width: 767.98px) {
          .accordion.faq .accordion__trigger::before {
            font-size: 3.6rem; } }
        @media (min-width: 768px) {
          .accordion.faq .accordion__trigger::before {
            left: 25px;
            top: 0em; } }
    .accordion.faq .accordion__icon {
      position: absolute;
      top: 50%;
      right: 10px;
      transform: translateY(-50%);
      width: 30px;
      height: 30px;
      border-radius: 50%;
      background-color: #D3D3D3; }
      .accordion.faq .accordion__icon::before, .accordion.faq .accordion__icon::after {
        background: #004A98;
        width: 20px;
        height: 1px; }
    .accordion.faq .accordion__inner {
      padding: 25px 25px 60px 70px;
      background-color: #EFEFEF;
      position: relative; }
      .accordion.faq .accordion__inner::before {
        position: absolute;
        top: 0.1em;
        left: 10px;
        width: 1em;
        content: 'A';
        color: #333;
        font-family: "Crimson Text", Times, serif;
        font-weight: 400; }
        @media (min-width: 768px) {
          .accordion.faq .accordion__inner::before {
            font-size: 4rem; } }
        @media (max-width: 767.98px) {
          .accordion.faq .accordion__inner::before {
            font-size: 3.6rem; } }
        @media (min-width: 768px) {
          .accordion.faq .accordion__inner::before {
            top: 0;
            left: 25px; } }
  .accordion.system {
    border: none; }
    @media (min-width: 768px) {
      .accordion.system {
        margin-bottom: 90px; } }
    @media (max-width: 767.98px) {
      .accordion.system {
        margin-bottom: 60px; } }
    .accordion.system .accordion__trigger {
      background: #004A98;
      color: #fff;
      position: relative;
      flex: 1;
      padding: 15px 70px 15px 15px;
      font-family: "Noto Serif JP", "游明朝", "Hiragino Mincho ProN", "ヒラギノ明朝 W3 JIS2004", "Times New Roman", Times, serif;
      font-weight: 400; }
      @media (min-width: 768px) {
        .accordion.system .accordion__trigger {
          font-size: 1.8rem; } }
      @media (max-width: 767.98px) {
        .accordion.system .accordion__trigger {
          font-size: 1.6rem; } }
      @media (min-width: 768px) {
        .accordion.system .accordion__trigger {
          padding: 15px 85px 15px 30px; } }
    .accordion.system .accordion__icon {
      position: absolute;
      top: 0;
      right: 10px;
      width: 60px;
      height: 100%;
      border-radius: 0%;
      background: #D3D3D3; }
      .accordion.system .accordion__icon::before, .accordion.system .accordion__icon::after {
        background: #004A98;
        height: 2px; }
      .accordion.system .accordion__icon::before {
        transform: translateX(-50%) rotate(90deg); }
      .accordion.system .accordion__icon::after {
        transform: translateX(-50%); }
    .accordion.system .accordion__inner {
      background: #D3D3D3;
      padding: 20px 15px;
      position: relative; }
      @media (min-width: 768px) {
        .accordion.system .accordion__inner {
          padding: 40px 60px; } }

.accordion[open] > .accordion__trigger {
  background-color: #004A98;
  color: #fff; }
  .accordion[open] > .accordion__trigger .accordion__icon::before, .accordion[open] > .accordion__trigger .accordion__icon::after {
    background: #fff; }
  .accordion[open] > .accordion__trigger .accordion__icon::before {
    transform: translateX(-50%) rotate(135deg); }
  .accordion[open] > .accordion__trigger .accordion__icon::after {
    transform: translateX(-50%) rotate(45deg); }

.accordion[open].faq .accordion__trigger::before {
  color: #fff; }

.accordion[open].faq .accordion__icon::before, .accordion[open].faq .accordion__icon::after {
  background: #004A98; }

@media (max-width: 767.98px) {
  .mt-be-columns + .mt-be-columns {
    margin-top: 20px !important; } }

@media (max-width: 767.98px) {
  .mt-be-columns {
    display: block !important; } }

.mt-be-columns.mt-be-columns_gap30 {
  gap: 30px; }

@media (min-width: 768px) {
  .mt-be-columns {
    width: 100%;
    display: flex;
    gap: 60px; } }

.mt-be-columns.sp-column-block {
  display: flex !important;
  gap: 30px; }

.mt-be-columns.sp-column-block .mt-be-column {
  margin-top: 0px !important; }

.mt-be-columns .mt-be-column {
  width: 100%; }
  @media (max-width: 767.98px) {
    .mt-be-columns .mt-be-column + .mt-be-column {
      margin-top: 20px; } }

.flex-3-7.mt-be-columns {
  gap: 30px; }
  .flex-3-7.mt-be-columns > .mt-be-column:first-child {
    width: 100%; }
    @media (min-width: 768px) {
      .flex-3-7.mt-be-columns > .mt-be-column:first-child {
        width: 30%; } }
  .flex-3-7.mt-be-columns > .mt-be-column:nth-child(2) {
    width: 100%; }
    @media (min-width: 768px) {
      .flex-3-7.mt-be-columns > .mt-be-column:nth-child(2) {
        width: 70%; } }

.flex-7-3.mt-be-columns {
  gap: 30px; }
  .flex-7-3.mt-be-columns > .mt-be-column:first-child {
    width: 100%; }
    @media (min-width: 768px) {
      .flex-7-3.mt-be-columns > .mt-be-column:first-child {
        width: 70%; } }
  .flex-7-3.mt-be-columns > .mt-be-column:nth-child(2) {
    width: 100%; }
    @media (min-width: 768px) {
      .flex-7-3.mt-be-columns > .mt-be-column:nth-child(2) {
        width: 30%; } }
  @media (max-width: 767.98px) {
    .flex-7-3.mt-be-columns.flex-row-reverse {
      display: flex !important;
      flex-direction: column-reverse !important; } }

.flex-4-1.mt-be-columns {
  gap: 30px; }
  .flex-4-1.mt-be-columns > .mt-be-column:first-child {
    width: 100%; }
    @media (min-width: 768px) {
      .flex-4-1.mt-be-columns > .mt-be-column:first-child {
        width: 80%; } }
  .flex-4-1.mt-be-columns > .mt-be-column:nth-child(2) {
    width: 100%; }
    @media (min-width: 768px) {
      .flex-4-1.mt-be-columns > .mt-be-column:nth-child(2) {
        width: 20%; } }
  @media (max-width: 767.98px) {
    .flex-4-1.mt-be-columns.flex-row-reverse {
      display: flex !important;
      flex-direction: column-reverse !important; } }

.flex-1-4.mt-be-columns {
  gap: 30px; }
  .flex-1-4.mt-be-columns > .mt-be-column:first-child {
    width: 100%; }
    @media (min-width: 768px) {
      .flex-1-4.mt-be-columns > .mt-be-column:first-child {
        width: 20%; } }
  .flex-1-4.mt-be-columns > .mt-be-column:nth-child(2) {
    width: 100%; }
    @media (min-width: 768px) {
      .flex-1-4.mt-be-columns > .mt-be-column:nth-child(2) {
        width: 80%; } }
  @media (max-width: 767.98px) {
    .flex-1-4.mt-be-columns.flex-row-reverse {
      display: flex !important;
      flex-direction: column-reverse !important; } }

.flex-3-2.mt-be-columns {
  gap: 30px; }
  .flex-3-2.mt-be-columns > .mt-be-column:first-child {
    width: 100%; }
    @media (min-width: 768px) {
      .flex-3-2.mt-be-columns > .mt-be-column:first-child {
        width: 60%; } }
  .flex-3-2.mt-be-columns > .mt-be-column:nth-child(2) {
    width: 100%; }
    @media (min-width: 768px) {
      .flex-3-2.mt-be-columns > .mt-be-column:nth-child(2) {
        width: 40%; } }
  @media (max-width: 767.98px) {
    .flex-3-2.mt-be-columns.flex-row-reverse {
      display: flex !important;
      flex-direction: column-reverse !important; } }

.flex-2-3.mt-be-columns {
  gap: 30px; }
  .flex-2-3.mt-be-columns > .mt-be-column:first-child {
    width: 100%; }
    @media (min-width: 768px) {
      .flex-2-3.mt-be-columns > .mt-be-column:first-child {
        width: 40%; } }
  .flex-2-3.mt-be-columns > .mt-be-column:nth-child(2) {
    width: 100%; }
    @media (min-width: 768px) {
      .flex-2-3.mt-be-columns > .mt-be-column:nth-child(2) {
        width: 60%; } }
  @media (max-width: 767.98px) {
    .flex-2-3.mt-be-columns.flex-row-reverse {
      display: flex !important;
      flex-direction: column-reverse !important; } }

.table_style1 tr:first-child th {
  background: #004A98;
  color: #fff;
  text-align: center; }

table {
  width: 100%; }
  table tbody tr th, table tbody tr td {
    padding: 10px;
    border: 1px solid #D3D3D3 !important; }
    @media (max-width: 767.98px) {
      table tbody tr th, table tbody tr td {
        padding: 5px; } }
  table tbody tr th {
    background: #EFEFEF;
    color: #004A98; }

.js-scrollable table {
  min-width: 945px; }
  @media (max-width: 767.98px) {
    .js-scrollable table {
      min-width: 715px !important; } }

@media (max-width: 767.98px) {
  .js-scrollable img {
    width: auto !important;
    max-width: 200% !important; } }

.js-scrollable .scroll-hint-icon {
  background: #004A98;
  width: 100px;
  height: 100px;
  border-radius: 50%; }

.js-scrollable.sp-scrollable table {
  min-width: auto !important; }
  @media (max-width: 767.98px) {
    .js-scrollable.sp-scrollable table {
      min-width: 715px !important; } }

@media (max-width: 767.98px) {
  .sp-down table {
    border: none; }
    .sp-down table th, .sp-down table td {
      display: block !important;
      width: 100% !important;
      height: auto !important; }
  .admissions .sp-down table tr {
    border-bottom: 2px solid #004A98; }
    .admissions .sp-down table tr:last-child {
      border-bottom: none; }
      .admissions .sp-down table tr:last-child td {
        border-bottom: 1px solid #D3D3D3 !important; } }

.simple-table__key {
  background-color: #F2D8DD;
  font-weight: bold;
  text-align: center;
  border: 1px solid #CCC;
  margin-bottom: 0;
  padding: .7rem; }

.simple-table__val {
  border: 1px solid #CCC;
  padding: .7rem;
  border-top: none; }

.simple-table__tr:not(:last-child) .simple-table__val {
  border-bottom: none; }

div[class^="border-"] {
  border-color: #CCC !important;
  margin: 1em 0; }

html.en .page-ttl_style1-ttl {
  font-family: "Crimson Text", Times, serif;
  font-weight: 400; }
  @media (min-width: 768px) {
    html.en .page-ttl_style1-ttl {
      font-size: 3.4rem; } }
  @media (max-width: 767.98px) {
    html.en .page-ttl_style1-ttl {
      font-size: 2.8rem; } }

html.en .page-ttl_style3-ttl {
  font-family: "Crimson Text", Times, serif;
  font-weight: 400; }
  @media (min-width: 768px) {
    html.en .page-ttl_style3-ttl {
      font-size: 3.4rem; } }
  @media (max-width: 767.98px) {
    html.en .page-ttl_style3-ttl {
      font-size: 2.8rem; } }

html.en .page-ttl_style4-ttl {
  font-family: "Crimson Text", Times, serif;
  font-weight: 400;
  font-weight: 600; }
  @media (min-width: 768px) {
    html.en .page-ttl_style4-ttl {
      font-size: 3.4rem; } }
  @media (max-width: 767.98px) {
    html.en .page-ttl_style4-ttl {
      font-size: 2.8rem; } }

html.en .ttl_style1, html.en h2:not([class]) {
  font-family: "Crimson Text", Times, serif;
  font-weight: 400; }
  @media (min-width: 768px) {
    html.en .ttl_style1, html.en h2:not([class]) {
      font-size: 3rem; } }
  @media (max-width: 767.98px) {
    html.en .ttl_style1, html.en h2:not([class]) {
      font-size: 2.6rem; } }

html.en .ttl_style2, html.en h3:not([class]) {
  font-family: "Crimson Text", Times, serif;
  font-weight: 400;
  font-weight: bold; }
  @media (min-width: 768px) {
    html.en .ttl_style2, html.en h3:not([class]) {
      font-size: 2.6rem; } }
  @media (max-width: 767.98px) {
    html.en .ttl_style2, html.en h3:not([class]) {
      font-size: 2rem; } }

html.en .lead_style1 {
  font-family: "Crimson Text", Times, serif;
  font-weight: 400;
  font-weight: bold; }
  @media (min-width: 768px) {
    html.en .lead_style1 {
      font-size: 2.6rem; } }
  @media (max-width: 767.98px) {
    html.en .lead_style1 {
      font-size: 2.6rem; } }

.ff__en {
  font-family: "Crimson Text", Times, serif;
  font-weight: 400; }

@media (max-width: 767.98px) {
  main.contents {
    padding-top: 70px; } }

.page.mv-1 .block {
  padding: 80px 0; }
  .page.mv-1 .block:last-child {
    padding-bottom: 80px !important; }
    @media (min-width: 768px) {
      .page.mv-1 .block:last-child {
        padding-bottom: 120px !important; } }

.page.mv-1 main.contents {
  padding-bottom: 0px; }

.page.post-sys.detail main.contents {
  padding-top: 120px; }
  @media (min-width: 768px) {
    .page.post-sys.detail main.contents {
      padding-top: 210px; } }

.page.post-sys.detail .block .bg-gray {
  padding-left: 30px;
  padding-right: 30px; }
  @media (max-width: 767.98px) {
    .page.post-sys.detail .block .bg-gray {
      padding-left: 20px;
      padding-right: 20px; } }

@media (max-width: 767.98px) {
  .page.post-sys.detail .block .inner-wide {
    padding-left: 0px;
    padding-right: 0px; } }

.page main.contents {
  padding-bottom: 80px; }
  @media (min-width: 768px) {
    .page main.contents {
      padding-top: 130px;
      padding-bottom: 120px; } }
  .page main.contents .block {
    padding-top: 80px;
    padding-bottom: 30px; }
    .page main.contents .block:first-child {
      padding-top: 0; }
    .page main.contents .block:last-child {
      padding-bottom: 0; }
    .page main.contents .block.bg-gray {
      background: #EFEFEF;
      padding-bottom: 80px; }
    .page main.contents .block-top {
      padding-top: 80px; }

a[class] {
  color: currentColor; }

a:hover {
  text-decoration: none; }

@media (min-width: 768px) {
  .inner, .header_category__wrapper {
    max-width: 1080px;
    margin: 0 auto; } }

@media (max-width: 1279.98px) {
  .inner, .header_category__wrapper {
    padding-right: 20px;
    padding-left: 20px; } }

.inner-flexible {
  padding: 0 85px; }
  @media (max-width: 1279.98px) {
    .inner-flexible {
      padding: 0 40px; } }
  @media (max-width: 767.98px) {
    .inner-flexible {
      padding: 0 20px; } }

.inner-wide {
  max-width: 1200px;
  margin: 0 auto; }
  @media (max-width: 1279.98px) {
    .inner-wide {
      padding-right: 20px;
      padding-left: 20px; } }

.breadcrumb {
  padding: 0; }
  .breadcrumb-item.active {
    color: #333; }
  .breadcrumb-item a {
    color: #333;
    text-decoration: none; }
    .breadcrumb-item a:hover {
      text-decoration: underline; }
  .breadcrumb-item + .breadcrumb-item::before {
    content: "ー";
    color: #333; }

.page-btn_style1 a {
  padding: 10px 0;
  display: flex;
  justify-content: space-between;
  align-items: center;
  text-decoration: none; }
  .page-btn_style1 a::after {
    content: "";
    background-image: url("/img/common/arrow_next_white2.svg");
    background-repeat: no-repeat;
    width: 20px;
    height: 10px;
    margin-left: 10px; }
  .page-btn_style1 a:hover {
    opacity: 1; }

.page-btn_style1::before, .page-btn_style1::after {
  content: "";
  background-color: #333;
  height: 1px;
  width: 100%;
  display: block;
  transition: 0.15s linear;
  position: relative;
  bottom: 0; }

.page-btn_style1:hover::after {
  position: relative;
  bottom: -5px; }

.page-imglink a {
  text-decoration: none; }
  .page-imglink a::after {
    content: none; }

.page-imglink_box {
  position: relative; }
  @media (min-width: 768px) {
    .page-imglink_box {
      width: 100%; } }
  .page-imglink_box::after {
    content: "";
    background-color: #fff;
    width: 45px;
    height: 45px;
    position: absolute;
    bottom: 0px;
    right: 0px; }
  .page-imglink_box::before {
    content: "";
    background-image: url("/img/common/arrow_next_black.svg");
    background-repeat: no-repeat;
    width: 20px;
    height: 10px;
    position: absolute;
    right: 10px;
    bottom: 15px;
    z-index: 1; }

.page-imglink_text {
  font-weight: bold; }
  @media (min-width: 768px) {
    .page-imglink_text {
      font-size: 2.2rem; } }
  @media (max-width: 767.98px) {
    .page-imglink_text {
      font-size: 1.8rem; } }

ul {
  padding-left: 0; }
  ul:not([class]) {
    padding-left: 1em; }

.arrow {
  display: block; }
  .arrow-r {
    position: relative;
    padding-right: 14px; }
    .arrow-r::after {
      font-family: fontawesome;
      content: "\f105";
      position: absolute;
      color: #333;
      transition: 0.3s;
      right: 0;
      top: 50%;
      transform: translateY(-50%); }
    .arrow-r.yazirushi::after {
      content: "";
      background-image: url(/img/common/arrow_next_black.svg);
      background-repeat: no-repeat;
      width: 15px;
      height: 7px;
      margin-left: 10px; }

.gheader li,
.gheader ol,
.gnav li,
.gnav ol {
  list-style: none; }
  .gheader li a,
  .gheader ol a,
  .gnav li a,
  .gnav ol a {
    text-decoration: none; }

.nakaguro {
  color: transparent;
  display: inline-block;
  position: relative; }

.nakaguro::before {
  content: "";
  width: 3px;
  height: 3px;
  border-radius: 50%;
  background: #fff;
  margin: auto;
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0; }

.slider-label-1,
.post-label-1 {
  color: #fff;
  background-color: #004A98;
  padding: 1px 10px;
  display: inline-block;
  border-radius: 20px;
  margin: 0 3px; }
  @media (min-width: 768px) {
    .slider-label-1,
    .post-label-1 {
      font-size: 1.2rem; } }
  @media (max-width: 767.98px) {
    .slider-label-1,
    .post-label-1 {
      font-size: 1.2rem; } }

@media (min-width: 768px) {
  .slider-label-2,
  .post-label-2 {
    font-size: 1.4rem; } }

@media (max-width: 767.98px) {
  .slider-label-2,
  .post-label-2 {
    font-size: 1.4rem; } }

.slider-text-1,
.post-text-1 {
  color: #fff;
  display: block; }
  @media (min-width: 768px) {
    .slider-text-1,
    .post-text-1 {
      font-size: 1.8rem; } }
  @media (max-width: 767.98px) {
    .slider-text-1,
    .post-text-1 {
      font-size: 1.6rem; } }

.slider-text-2,
.post-text-2 {
  display: block; }

.slider-text-3,
.post-text-3 {
  display: block;
  color: #fff; }
  @media (min-width: 768px) {
    .slider-text-3,
    .post-text-3 {
      font-size: 1.4rem; } }
  @media (max-width: 767.98px) {
    .slider-text-3,
    .post-text-3 {
      font-size: 1.4rem; } }

.slider-dateinner-wide,
.post-dateinner-wide {
  color: #fff;
  font-family: "Barlow", Times, serif;
  font-weight: 400; }
  @media (min-width: 768px) {
    .slider-dateinner-wide,
    .post-dateinner-wide {
      font-size: 1.4rem; } }
  @media (max-width: 767.98px) {
    .slider-dateinner-wide,
    .post-dateinner-wide {
      font-size: 1.4rem; } }

.slider-date-2,
.post-date-2 {
  font-family: "Barlow", Times, serif;
  font-weight: 400; }
  @media (min-width: 768px) {
    .slider-date-2,
    .post-date-2 {
      font-size: 1.4rem; } }
  @media (max-width: 767.98px) {
    .slider-date-2,
    .post-date-2 {
      font-size: 1.4rem; } }

.slider-event-date span,
.post-event-date span {
  line-height: 1;
  text-align: center; }

.slider-event-date .year,
.post-event-date .year {
  font-family: "Barlow", Times, serif;
  font-weight: 400;
  color: #004A98;
  margin-bottom: 10px; }
  @media (min-width: 768px) {
    .slider-event-date .year,
    .post-event-date .year {
      font-size: 1.2rem; } }
  @media (max-width: 767.98px) {
    .slider-event-date .year,
    .post-event-date .year {
      font-size: 1.2rem; } }

.slider-event-date .month,
.post-event-date .month {
  font-family: "Barlow", Times, serif;
  font-weight: 400; }
  @media (min-width: 768px) {
    .slider-event-date .month,
    .post-event-date .month {
      font-size: 1.8rem; } }
  @media (max-width: 767.98px) {
    .slider-event-date .month,
    .post-event-date .month {
      font-size: 1.8rem; } }

.slider-event-date .day,
.post-event-date .day {
  font-family: "Barlow", Times, serif;
  font-weight: 400;
  word-break: normal; }
  @media (min-width: 768px) {
    .slider-event-date .day,
    .post-event-date .day {
      font-size: 5.2rem; } }
  @media (max-width: 767.98px) {
    .slider-event-date .day,
    .post-event-date .day {
      font-size: 5.2rem; } }

.slider-event-content,
.post-event-content {
  font-weight: bold; }

@media (min-width: 768px) {
  .slider-event-hostday,
  .post-event-hostday {
    font-size: 1.4rem; } }

@media (max-width: 767.98px) {
  .slider-event-hostday,
  .post-event-hostday {
    font-size: 1.4rem; } }

.slider-event-hostday::before,
.post-event-hostday::before {
  content: "";
  background-image: url("/img/common/time_icon.svg");
  background-repeat: no-repeat;
  width: 16px;
  height: 16px;
  display: inline-block;
  margin-right: 5px;
  vertical-align: sub; }

@media (min-width: 768px) {
  .slider-event-place,
  .post-event-place {
    font-size: 1.4rem; } }

@media (max-width: 767.98px) {
  .slider-event-place,
  .post-event-place {
    font-size: 1.4rem; } }

.slider-event-place::before,
.post-event-place::before {
  content: "";
  background-image: url("/img/common/place_icon.svg");
  background-repeat: no-repeat;
  width: 16px;
  height: 18px;
  display: inline-block;
  margin: 0 1.5px;
  vertical-align: sub;
  text-align: center; }

.slider-event-important,
.post-event-important {
  color: #E81826; }
  @media (min-width: 768px) {
    .slider-event-important,
    .post-event-important {
      font-size: 1.4rem; } }
  @media (max-width: 767.98px) {
    .slider-event-important,
    .post-event-important {
      font-size: 1.4rem; } }

.scroll-hint-icon {
  top: 50%;
  transform: translateY(-50%); }

.post-sys__list {
  display: flex;
  flex-wrap: wrap; }

.post-sys__item {
  list-style: none;
  margin-bottom: 30px; }
  @media (max-width: 767.98px) {
    .post-sys__item {
      width: 100%; } }
  @media (min-width: 768px) {
    .post-sys__item.column-3 {
      width: calc(33.333% - 20px);
      margin-right: 30px;
      margin-bottom: 60px; }
      .post-sys__item.column-3:nth-child(3n) {
        margin-right: 0; } }
  @media (min-width: 768px) {
    .post-sys__item.column-4 {
      width: calc(25% - 20px);
      margin-right: 26.6px;
      margin-bottom: 60px; }
      .post-sys__item.column-4:nth-child(4n) {
        margin-right: 0; } }
  .post-sys__item.fixed-item {
    position: relative; }
    .post-sys__item.fixed-item::before {
      content: "";
      width: 30px;
      height: 30px;
      background-image: url("/img/common/news_fixed_icon.png");
      position: absolute;
      top: 0;
      right: 0;
      z-index: 1; }

.post-sys__link {
  text-decoration: none; }

.post-sys__thumnail {
  margin-bottom: 5px;
  border: 2px solid #D3D3D3; }
  .post-sys__thumnail img {
    object-fit: cover;
    width: 100%;
    aspect-ratio: 4/2.5; }
  .post-sys__thumnail.blogid-147 {
    background-color: #efefef; }
    .post-sys__thumnail.blogid-147 img {
      object-fit: contain !important;
      width: 100% !important;
      height: 100% !important; }

.post-sys__important {
  border: 1px solid #E81826;
  color: #E81826;
  display: inline-block;
  padding: 0px 10px;
  line-height: 1.5;
  vertical-align: middle; }
  @media (min-width: 768px) {
    .post-sys__important {
      font-size: 1.4rem; } }
  @media (max-width: 767.98px) {
    .post-sys__important {
      font-size: 1.4rem; } }

.post-sys__date {
  font-family: "Barlow", Times, serif;
  font-weight: 400;
  margin-bottom: 5px;
  display: inline-block; }
  @media (min-width: 768px) {
    .post-sys__date {
      font-size: 1.4rem; } }
  @media (max-width: 767.98px) {
    .post-sys__date {
      font-size: 1.4rem; } }

.post-sys__cate {
  background: #EFEFEF;
  display: inline-block;
  padding: 3px;
  margin-bottom: 5px; }
  @media (min-width: 768px) {
    .post-sys__cate {
      font-size: 1.2rem; } }
  @media (max-width: 767.98px) {
    .post-sys__cate {
      font-size: 1.2rem; } }

.post-sys__media {
  border-left: 1px solid #333;
  padding-left: 10px;
  margin-left: 10px;
  display: inline-block;
  line-height: 1; }

.post-sys__related {
  background: linear-gradient(to bottom, #004A98 0%, #152740 100%);
  padding: 40px; }
  @media (max-width: 767.98px) {
    .post-sys__related {
      padding: 40px 20px; } }
  .post-sys__related_area {
    margin-bottom: 80px; }
  .post-sys__related_ttl {
    color: #fff;
    font-weight: bold;
    border: 1px solid #fff;
    display: inline-block;
    font-family: "Noto Serif JP", "游明朝", "Hiragino Mincho ProN", "ヒラギノ明朝 W3 JIS2004", "Times New Roman", Times, serif;
    font-weight: 400;
    padding: 0 20px; }
    @media (min-width: 768px) {
      .post-sys__related_ttl {
        font-size: 2.4rem; } }
    @media (max-width: 767.98px) {
      .post-sys__related_ttl {
        font-size: 2.4rem; } }
    @media (max-width: 767.98px) {
      .post-sys__related_ttl {
        padding: 0 20px; } }
  .post-sys__related .btn_style2 {
    border-bottom: 1px solid #fff; }
    .post-sys__related .btn_style2 a {
      color: #fff; }
      .post-sys__related .btn_style2 a:hover {
        opacity: 0.3; }
  .post-sys__related .ttl_style3, .post-sys__related h4:not([class]) {
    color: #fff; }
    .post-sys__related .ttl_style3::before, .post-sys__related h4:not([class])::before {
      background: #fff; }
  .post-sys__related .post-sys__item {
    margin-bottom: 0; }
    @media (max-width: 767.98px) {
      .post-sys__related .post-sys__item {
        margin-bottom: 30px; } }
  .post-sys__related .post-sys__text {
    color: #fff; }

.post-sys__relatednews {
  background: #EFEFEF;
  padding: 40px;
  margin-top: 40px; }
  @media (max-width: 767.98px) {
    .post-sys__relatednews {
      padding: 40px 20px; } }
  .post-sys__relatednews_ttl {
    color: #004A98;
    font-weight: bold;
    border: 1px solid #004A98;
    display: inline-block;
    font-family: "Noto Serif JP", "游明朝", "Hiragino Mincho ProN", "ヒラギノ明朝 W3 JIS2004", "Times New Roman", Times, serif;
    font-weight: 400;
    padding: 0 20px;
    margin-bottom: 30px; }
    @media (min-width: 768px) {
      .post-sys__relatednews_ttl {
        font-size: 2.4rem; } }
    @media (max-width: 767.98px) {
      .post-sys__relatednews_ttl {
        font-size: 2.4rem; } }
    @media (max-width: 767.98px) {
      .post-sys__relatednews_ttl {
        padding: 0 20px; } }
  .post-sys__relatednews .post-sys__date {
    color: #333 !important;
    font-size: 1.4rem !important; }
  .post-sys__relatednews .post-sys__cate {
    margin-bottom: 5px !important;
    background: #fff; }

.post-sys.detail .post-sys__date {
  color: #004A98;
  vertical-align: middle;
  margin-right: 10px; }
  @media (min-width: 768px) {
    .post-sys.detail .post-sys__date {
      font-size: 1.8rem; } }
  @media (max-width: 767.98px) {
    .post-sys.detail .post-sys__date {
      font-size: 1.8rem; } }

.post-sys.detail .post-sys__cate {
  margin-bottom: 0px; }

.post-sys.detail .post-sys__important {
  margin-bottom: 0px; }

.post-sys.detail .post-sys__contents {
  margin: 0 0 80px; }

.img-arrow {
  position: relative;
  display: block; }
  .img-arrow::after {
    content: "";
    position: absolute;
    background: url(/img/common/img_arrow.jpg);
    width: 45px;
    height: 45px;
    display: block;
    right: 0;
    bottom: 0; }

.cookie {
  background-color: #004A98;
  padding: 20px 50px;
  z-index: 99999;
  display: none; }
  .cookie.firstTime {
    display: block !important; }
  @media (max-width: 767.98px) {
    .cookie {
      padding: 10px 20px 20px;
      bottom: 60px; } }
  .cookie__inner {
    display: flex;
    justify-content: space-between;
    align-items: center;
    max-width: 1000px;
    margin: 0 auto; }
    @media (max-width: 767.98px) {
      .cookie__inner {
        display: block; } }
  .cookie__btn {
    display: flex; }
    @media (max-width: 767.98px) {
      .cookie__btn {
        justify-content: center;
        width: 100%; } }
    @media (max-width: 767.98px) {
      .cookie__btn_item {
        flex: 0 0 50%; } }
    .cookie__btn_item:first-child {
      margin-right: 20px; }
  .cookie p {
    color: #fff; }
  .cookie .plink_type_underline {
    text-decoration: underline; }
  .cookie .cookie__link {
    padding: 15px 20px;
    border: 1px solid #fff;
    color: #fff;
    display: block;
    min-width: 120px;
    text-align: center; }
    @media (max-width: 767.98px) {
      .cookie .cookie__link {
        padding: 10px;
        min-width: auto;
        width: 100%; } }
    .cookie .cookie__link.true {
      background: #fff;
      color: #004A98; }

.slick-slider.slick-initialized.row {
  margin-left: -20px;
  margin-right: -20px; }

.slick-slider .slick-arrow {
  z-index: 1; }
  .slick-slider .slick-arrow.slick-prev {
    left: 0px; }
    .slick-slider .slick-arrow.slick-prev::before {
      content: "\f104"; }
  .slick-slider .slick-arrow.slick-next {
    right: 0px; }
    .slick-slider .slick-arrow.slick-next::before {
      content: "\f105"; }
  .slick-slider .slick-arrow::before {
    font-family: "FontAwesome";
    font-size: 2rem;
    display: inline-block;
    width: 1em;
    height: 1em;
    position: absolute;
    top: 50%;
    left: 50%;
    margin-top: -0.5em;
    margin-left: -0.5em;
    line-height: 1; }

.slick-slider > .slick-list {
  margin-left: 0px;
  margin-right: 0px;
  margin-bottom: 0 !important; }
  .slick-slider > .slick-list > .slick-track > .slick-slide {
    border: none;
    margin-left: 0px;
    margin-right: 0px; }
    .slick-slider > .slick-list > .slick-track > .slick-slide img {
      width: 100%; }

.slick-slider.slick-gutter-20 > .slick-list {
  margin-left: -10px;
  margin-right: -10px; }
  .slick-slider.slick-gutter-20 > .slick-list > .slick-track > .slick-slide {
    margin-left: 10px;
    margin-right: 10px; }
    .slick-slider.slick-gutter-20 > .slick-list > .slick-track > .slick-slide.col {
      padding-left: 0;
      padding-right: 0; }

@media (max-width: 767.98px) {
  .slick-slider.slick-sp-gutter-20 > .slick-list {
    margin-left: -10px;
    margin-right: -10px; }
    .slick-slider.slick-sp-gutter-20 > .slick-list > .slick-track > .slick-slide {
      margin-left: 10px;
      margin-right: 10px; }
      .slick-slider.slick-sp-gutter-20 > .slick-list > .slick-track > .slick-slide.col {
        padding-left: 0;
        padding-right: 0; } }

@media (min-width: 768px) {
  .slick-slider.slick-pc-gutter-20 > .slick-list {
    margin-left: -10px;
    margin-right: -10px; }
    .slick-slider.slick-pc-gutter-20 > .slick-list > .slick-track > .slick-slide {
      margin-left: 10px;
      margin-right: 10px; }
      .slick-slider.slick-pc-gutter-20 > .slick-list > .slick-track > .slick-slide.col {
        padding-left: 0;
        padding-right: 0; } }

.slick-slider.slick-gutter-40 > .slick-list {
  margin-left: -20px;
  margin-right: -20px; }
  .slick-slider.slick-gutter-40 > .slick-list > .slick-track > .slick-slide {
    margin-left: 20px;
    margin-right: 20px; }
    .slick-slider.slick-gutter-40 > .slick-list > .slick-track > .slick-slide.col {
      padding-left: 0;
      padding-right: 0; }

@media (max-width: 767.98px) {
  .slick-slider.slick-sp-gutter-40 > .slick-list {
    margin-left: -20px;
    margin-right: -20px; }
    .slick-slider.slick-sp-gutter-40 > .slick-list > .slick-track > .slick-slide {
      margin-left: 20px;
      margin-right: 20px; }
      .slick-slider.slick-sp-gutter-40 > .slick-list > .slick-track > .slick-slide.col {
        padding-left: 0;
        padding-right: 0; } }

@media (min-width: 768px) {
  .slick-slider.slick-pc-gutter-40 > .slick-list {
    margin-left: -20px;
    margin-right: -20px; }
    .slick-slider.slick-pc-gutter-40 > .slick-list > .slick-track > .slick-slide {
      margin-left: 20px;
      margin-right: 20px; }
      .slick-slider.slick-pc-gutter-40 > .slick-list > .slick-track > .slick-slide.col {
        padding-left: 0;
        padding-right: 0; } }

.slick-slider.slick-gutter-60 > .slick-list {
  margin-left: -30px;
  margin-right: -30px; }
  .slick-slider.slick-gutter-60 > .slick-list > .slick-track > .slick-slide {
    margin-left: 30px;
    margin-right: 30px; }
    .slick-slider.slick-gutter-60 > .slick-list > .slick-track > .slick-slide.col {
      padding-left: 0;
      padding-right: 0; }

@media (max-width: 767.98px) {
  .slick-slider.slick-sp-gutter-60 > .slick-list {
    margin-left: -30px;
    margin-right: -30px; }
    .slick-slider.slick-sp-gutter-60 > .slick-list > .slick-track > .slick-slide {
      margin-left: 30px;
      margin-right: 30px; }
      .slick-slider.slick-sp-gutter-60 > .slick-list > .slick-track > .slick-slide.col {
        padding-left: 0;
        padding-right: 0; } }

@media (min-width: 768px) {
  .slick-slider.slick-pc-gutter-60 > .slick-list {
    margin-left: -30px;
    margin-right: -30px; }
    .slick-slider.slick-pc-gutter-60 > .slick-list > .slick-track > .slick-slide {
      margin-left: 30px;
      margin-right: 30px; }
      .slick-slider.slick-pc-gutter-60 > .slick-list > .slick-track > .slick-slide.col {
        padding-left: 0;
        padding-right: 0; } }

.slick-slider.slick-margin-arrow > .slick-list {
  margin-left: 50px;
  margin-right: 50px; }

@media (max-width: 767.98px) {
  .slick-slider.slick-sp-margin-arrow > .slick-list {
    margin-left: 50px;
    margin-right: 50px; } }

@media (min-width: 768px) {
  .slick-slider.slick-pc-margin-arrow > .slick-list {
    margin-left: 50px;
    margin-right: 50px; } }

.slick-slider.slick-type-fade > .slick-list {
  margin-left: 0;
  margin-right: 0; }
  .slick-slider.slick-type-fade > .slick-list > .slick-track > .slick-slide {
    margin-left: 0;
    margin-right: 0; }

@media (max-width: 767.98px) {
  .slick-slider.slick-type-sp-fade > .slick-list {
    margin-left: 0;
    margin-right: 0; }
    .slick-slider.slick-type-sp-fade > .slick-list > .slick-track > .slick-slide {
      margin-left: 0;
      margin-right: 0; } }

@media (min-width: 768px) {
  .slick-slider.slick-type-pc-fade > .slick-list {
    margin-left: 0;
    margin-right: 0; }
    .slick-slider.slick-type-pc-fade > .slick-list > .slick-track > .slick-slide {
      margin-left: 0;
      margin-right: 0; } }

.slick-slider.slick-type-noarrow > .slick-arrow {
  display: none !important; }

@media (max-width: 767.98px) {
  .slick-slider.slick-type-sp-noarrow > .slick-arrow {
    display: none !important; } }

@media (min-width: 768px) {
  .slick-slider.slick-type-pc-noarrow > .slick-arrow {
    display: none !important; } }

.slick-slider.slick-type-arrow > .slick-arrow {
  width: 0px;
  height: 100%;
  transition: 0.2s; }
  .slick-slider.slick-type-arrow > .slick-arrow::before {
    font-size: 2rem;
    color: #333; }
  .slick-slider.slick-type-arrow > .slick-arrow:hover {
    background: rgba(204, 204, 204, 0.3); }

@media (max-width: 767.98px) {
  .slick-slider.slick-type-sp-arrow > .slick-arrow {
    width: 0px;
    height: 100%;
    transition: 0.2s; }
    .slick-slider.slick-type-sp-arrow > .slick-arrow::before {
      font-size: 2rem;
      color: #333; }
    .slick-slider.slick-type-sp-arrow > .slick-arrow:hover {
      background: rgba(204, 204, 204, 0.3); } }

@media (min-width: 768px) {
  .slick-slider.slick-type-pc-arrow > .slick-arrow {
    width: 0px;
    height: 100%;
    transition: 0.2s; }
    .slick-slider.slick-type-pc-arrow > .slick-arrow::before {
      font-size: 2rem;
      color: #333; }
    .slick-slider.slick-type-pc-arrow > .slick-arrow:hover {
      background: rgba(204, 204, 204, 0.3); } }

.slick-slider.slick-type-arrow-box > .slick-arrow {
  font-size: 3rem;
  width: 50px !important;
  height: 50px !important;
  background: #333;
  box-shadow: 3px 3px 3px 0 rgba(0, 0, 0, 0.3); }
  .slick-slider.slick-type-arrow-box > .slick-arrow::before {
    color: #fff; }

@media (max-width: 767.98px) {
  .slick-slider.slick-type-sp-arrow-box > .slick-arrow {
    font-size: 3rem;
    width: 50px !important;
    height: 50px !important;
    background: #333;
    box-shadow: 3px 3px 3px 0 rgba(0, 0, 0, 0.3); }
    .slick-slider.slick-type-sp-arrow-box > .slick-arrow::before {
      color: #fff; } }

@media (min-width: 768px) {
  .slick-slider.slick-type-pc-arrow-box > .slick-arrow {
    font-size: 3rem;
    width: 50px !important;
    height: 50px !important;
    background: #333;
    box-shadow: 3px 3px 3px 0 rgba(0, 0, 0, 0.3); }
    .slick-slider.slick-type-pc-arrow-box > .slick-arrow::before {
      color: #fff; } }

.slick-slider.slick-type-arrow-circle > .slick-arrow {
  font-size: 3rem;
  width: 50px !important;
  height: 50px !important;
  background: #333;
  border-radius: 50rem; }
  .slick-slider.slick-type-arrow-circle > .slick-arrow::before {
    color: #fff; }

@media (max-width: 767.98px) {
  .slick-slider.slick-type-sp-arrow-circle > .slick-arrow {
    font-size: 3rem;
    width: 50px !important;
    height: 50px !important;
    background: #333;
    border-radius: 50rem; }
    .slick-slider.slick-type-sp-arrow-circle > .slick-arrow::before {
      color: #fff; } }

@media (min-width: 768px) {
  .slick-slider.slick-type-pc-arrow-circle > .slick-arrow {
    font-size: 3rem;
    width: 50px !important;
    height: 50px !important;
    background: #333;
    border-radius: 50rem; }
    .slick-slider.slick-type-pc-arrow-circle > .slick-arrow::before {
      color: #fff; } }

.slick-slider.slick-type-arrow-outside > .slick-arrow.slick-prev {
  left: -65px; }

.slick-slider.slick-type-arrow-outside > .slick-arrow.slick-next {
  right: -65px; }

@media (max-width: 767.98px) {
  .slick-slider.slick-type-sp-arrow-outside > .slick-arrow.slick-prev {
    left: -65px; }
  .slick-slider.slick-type-sp-arrow-outside > .slick-arrow.slick-next {
    right: -65px; } }

@media (min-width: 768px) {
  .slick-slider.slick-type-pc-arrow-outside > .slick-arrow.slick-prev {
    left: -65px; }
  .slick-slider.slick-type-pc-arrow-outside > .slick-arrow.slick-next {
    right: -65px; } }

.slick-slider.slick-type-nodot > .slick-dots {
  display: none !important; }

@media (max-width: 767.98px) {
  .slick-slider.slick-type-sp-nodot > .slick-dots {
    display: none !important; } }

@media (min-width: 768px) {
  .slick-slider.slick-type-pc-nodot > .slick-dots {
    display: none !important; } }

.slick-slider.slick-dotted {
  margin-bottom: 60px !important; }
  .slick-slider.slick-dotted .slick-dots {
    position: absolute;
    bottom: 0;
    margin: 0;
    bottom: -30px;
    display: flex;
    justify-content: center; }
    .slick-slider.slick-dotted .slick-dots li {
      height: 10px;
      width: 10px;
      margin-left: 10px;
      margin-right: 10px;
      cursor: pointer; }
      .slick-slider.slick-dotted .slick-dots li button {
        height: 100%;
        width: 100%;
        padding: 0; }
        .slick-slider.slick-dotted .slick-dots li button:hover:before, .slick-slider.slick-dotted .slick-dots li button:focus:before {
          opacity: 0.75; }
        .slick-slider.slick-dotted .slick-dots li button:before {
          content: "";
          width: 100%;
          height: 100%;
          background: #fff;
          border-radius: 50%;
          opacity: 1; }
      .slick-slider.slick-dotted .slick-dots li.slick-active button:before {
        background: #fff;
        opacity: 1; }
  .slick-slider.slick-dotted.slick-type-dot1 .slick-dots li {
    width: 100%;
    max-width: 50px;
    height: 4px;
    margin-left: 5px;
    margin-right: 5px; }
    .slick-slider.slick-dotted.slick-type-dot1 .slick-dots li button:before {
      border-radius: 0; }
  .slick-slider.slick-dotted.slick-type-dot-color-white li button:before {
    background: rgba(255, 255, 255, 0.4); }
  .slick-slider.slick-dotted.slick-type-dot-color-white li.slick-active button:before {
    background: #fff; }
  .slick-slider.slick-dotted.slick-type-dot-color-main li button:before {
    background: rgba(0, 74, 152, 0.4); }
  .slick-slider.slick-dotted.slick-type-dot-color-main li.slick-active button:before {
    background: #004A98; }

.home .this-is_logo {
  display: none; }

.page .gheader.nav-active .gheader__contents {
  transition: none;
  opacity: 1; }

.page .this-is_logo {
  display: none; }

@media (max-width: 767.98px) {
  .gheader__megamenu {
    opacity: 0;
    visibility: hidden; } }

.gheader {
  position: absolute;
  width: 100%;
  padding: 30px 20px 20px; }
  @media (max-width: 767.98px) {
    .gheader {
      padding: 0; } }
  .gheader__contents {
    position: relative;
    opacity: 0;
    visibility: hidden;
    top: -300px; }
    @media (max-width: 767.98px) {
      .gheader__contents {
        opacity: 1;
        visibility: visible;
        top: 0px;
        position: static; } }
  .gheader__logo {
    max-width: 270px; }
    @media (max-width: 767.98px) {
      .gheader__logo {
        max-width: none;
        position: fixed;
        z-index: 999;
        background: #fff;
        width: 100%;
        padding: 10px 0; } }
    @media (max-width: 767.98px) {
      .gheader__logo a {
        width: 80%;
        margin: 0 auto;
        display: block;
        max-width: 220px; } }
  .gheader__inner {
    display: flex;
    justify-content: space-between;
    z-index: 1;
    position: relative; }
    @media (max-width: 767.98px) {
      .gheader__inner {
        display: none; } }
  @media (max-width: 767.98px) {
    .gheader__common {
      display: none; } }
  .gheader__common-list {
    display: flex;
    position: relative; }
  .gheader__common-item {
    color: #fff;
    margin-right: 20px; }
    .gheader__common-item p {
      margin-bottom: 0; }
    .gheader__common-item:last-child {
      margin-right: 0; }
    .gheader__common-item.menu-contents .top_menu-close::after {
      content: "×";
      position: absolute;
      z-index: 1;
      font-size: 34px;
      line-height: 1;
      cursor: pointer;
      right: 5px;
      top: 68px;
      transition: 0.3s linear;
      visibility: hidden;
      opacity: 0; }
    .gheader__common-item.menu-contents .gheader__common-link {
      cursor: pointer;
      transition: all .2s linear;
      padding-bottom: 10px;
      margin-bottom: -10px; }
      .gheader__common-item.menu-contents .gheader__common-link:hover {
        opacity: .6; }
    .gheader__common-item.menu-contents .gheader__subcommon-list {
      position: absolute;
      width: 100%;
      padding: 30px 20px 20px 20px;
      opacity: 0;
      visibility: hidden;
      top: 40px;
      transition: 0.2s linear;
      background: #004A98;
      min-width: 400px; }
      .gheader__common-item.menu-contents .gheader__subcommon-list::before {
        content: "";
        position: absolute;
        display: block;
        top: -14px;
        left: 15px;
        width: 0;
        height: 0;
        border-style: solid;
        border-width: 0 15px 25px 15px;
        border-color: transparent transparent #004A98 transparent; }
      .gheader__common-item.menu-contents .gheader__subcommon-list.menu-research {
        right: 0px; }
        .gheader__common-item.menu-contents .gheader__subcommon-list.menu-research::before {
          left: auto;
          right: 0px; }
        .gheader__common-item.menu-contents .gheader__subcommon-list.menu-research .gheader__subcommon-item {
          border: none; }
        .gheader__common-item.menu-contents .gheader__subcommon-list.menu-research input {
          border: none;
          width: 85%; }
        .gheader__common-item.menu-contents .gheader__subcommon-list.menu-research button {
          background: none;
          border: none; }
    .gheader__common-item.menu-contents .gheader__subcommon-item {
      border-bottom: 1px solid #fff;
      margin-bottom: 10px; }
    .gheader__common-item.menu-contents .gheader__subcommon-link {
      color: #fff;
      padding-bottom: 10px;
      display: block; }
    .gheader__common-item.menu-contents.top_menu-active .gheader__subcommon-list {
      opacity: 1;
      visibility: visible;
      top: 65px; }
    .gheader__common-item.menu-contents.top_menu-active .gheader__subcommon-detailbox .top_menu-close::after {
      visibility: visible;
      opacity: 1; }
    .gheader__common-item.menu-search .gheader__common-link {
      cursor: pointer; }
  .gheader__common-link {
    color: #fff;
    display: block;
    text-shadow: 0px 3px 6px #000; }
    @media (min-width: 768px) {
      .gheader__common-link {
        font-size: 1.4rem; } }
    @media (max-width: 767.98px) {
      .gheader__common-link {
        font-size: 1.4rem; } }
  .gheader__common-icon {
    display: block;
    margin: 0 auto 5px;
    height: 20px; }
  .gheader.nav-active {
    position: fixed;
    z-index: 99;
    padding: 0 !important;
    top: 0; }
    @media (max-width: 767.98px) {
      .gheader.nav-active {
        z-index: 0;
        opacity: 0;
        visibility: hidden; } }
    @media (max-width: 767.98px) {
      .gheader.nav-active.spmenu-active {
        z-index: 99;
        opacity: 1;
        visibility: visible; } }
    @media (max-width: 767.98px) {
      .gheader.nav-active.spmenu-active .gheader__contents {
        opacity: 1;
        visibility: visible; } }
    .gheader.nav-active .top-header {
      display: none; }
    .gheader.nav-active .contents-header {
      display: flex;
      align-items: center;
      background: #fff;
      padding: 10px 20px 10px !important; }
      @media (max-width: 767.98px) {
        .gheader.nav-active .contents-header {
          display: none; } }
    .gheader.nav-active .gheader__contents {
      position: relative;
      top: 0px !important;
      opacity: 1;
      visibility: visible;
      transition: 1s; }
      @media (max-width: 767.98px) {
        .gheader.nav-active .gheader__contents {
          opacity: 0;
          transition: none;
          visibility: hidden; } }
    .gheader.nav-active .gheader__logo a {
      width: 80%;
      display: block; }
    .gheader.nav-active .gheader__common-link {
      color: #333;
      text-shadow: none; }
    .gheader.nav-active .gheader__common-icon {
      height: 20px; }
    .gheader.nav-active .gheader__megamenu {
      width: 100%;
      background: #fff; }
      @media screen and (max-width: 1035px) {
        .gheader.nav-active .gheader__megamenu {
          overflow-x: auto;
          -ms-overflow-style: none;
          scrollbar-width: none; }
          .gheader.nav-active .gheader__megamenu::-webkit-scrollbar {
            display: none; } }
      @media (max-width: 767.98px) {
        .gheader.nav-active .gheader__megamenu {
          opacity: 0;
          visibility: hidden;
          background: #fff;
          transition: all .3s;
          height: 100vh; }
          .gheader.nav-active .gheader__megamenu.spmenu-active {
            opacity: 1;
            visibility: visible; } }
      @media (max-width: 767.98px) {
        .gheader.nav-active .gheader__megamenu li:last-child {
          margin-bottom: 100px; } }
      .gheader.nav-active .gheader__megamenu-list {
        background-color: #EFEFEF;
        display: flex;
        gap: 0px 30px;
        justify-content: flex-end;
        padding: 16px 20px 16px 0;
        margin-bottom: 0; }
        @media screen and (max-width: 1035px) {
          .gheader.nav-active .gheader__megamenu-list {
            min-width: 1020px; } }
        @media (max-width: 767.98px) {
          .gheader.nav-active .gheader__megamenu-list {
            position: static;
            min-width: auto;
            display: block;
            padding: 70px 20px 20px;
            background: #fff; } }
        @media (min-width: 768px) {
          .gheader.nav-active .gheader__megamenu-list .menu-closebtn::after {
            content: "";
            position: absolute;
            z-index: 1;
            top: 140px;
            right: 10px;
            background-image: url(/img/common/menu-close.png);
            background-size: 40px;
            background-repeat: no-repeat;
            width: 40px;
            height: 40px;
            cursor: pointer;
            display: none; }
          .gheader.nav-active .gheader__megamenu-list .menu-closebtn.btn-active::after {
            display: block; } }
      @media (min-width: 768px) {
        .gheader.nav-active .gheader__megamenu-item .collapse:not(.show) {
          display: block; } }
      .gheader.nav-active .gheader__megamenu-item.menu-active .gheader__megamenu-contents {
        opacity: 1;
        visibility: visible; }
      .gheader.nav-active .gheader__megamenu-item.menu-active .gheader__megamenu-link {
        opacity: 1; }
        .gheader.nav-active .gheader__megamenu-item.menu-active .gheader__megamenu-link::after {
          opacity: 1;
          visibility: visible; }
      .gheader.nav-active .gheader__megamenu-link {
        padding: 20px 0 20px;
        position: relative;
        cursor: pointer; }
        @media screen and (max-width: 1140px) {
          .gheader.nav-active .gheader__megamenu-link {
            font-size: 1.4rem; } }
        @media (max-width: 767.98px) {
          .gheader.nav-active .gheader__megamenu-link {
            display: block;
            padding: 10px 0;
            margin-bottom: 0px;
            border-bottom: 1px solid #D3D3D3;
            font-weight: bold;
            position: relative;
            padding-right: 20px;
            padding-left: 10px;
            line-height: 2 !important; } }
        .gheader.nav-active .gheader__megamenu-link::before {
          content: "";
          height: 15px;
          width: 1px;
          display: block;
          background: #333;
          position: absolute;
          margin-top: 6.4px;
          left: -15px;
          pointer-events: none; }
          @media (max-width: 767.98px) {
            .gheader.nav-active .gheader__megamenu-link::before {
              content: none;
              margin-top: auto; } }
        .gheader.nav-active .gheader__megamenu-link::after {
          content: "";
          width: 70%;
          height: 3px;
          background-color: #004A98;
          position: absolute;
          bottom: 0px;
          left: 50%;
          transform: translateX(-50%);
          opacity: 0;
          visibility: hidden;
          transition: all .3s;
          border-radius: 20px; }
          @media (max-width: 767.98px) {
            .gheader.nav-active .gheader__megamenu-link::after {
              content: "";
              background-image: url("/img/common/arrow_next_black.svg");
              background-repeat: no-repeat;
              width: 20px;
              height: 10px;
              display: block;
              position: absolute;
              right: 0px;
              left: auto;
              bottom: auto;
              top: 50%;
              transform: translateY(-50%);
              opacity: 1;
              visibility: visible;
              background-color: inherit; } }
        @media (max-width: 767.98px) {
          .gheader.nav-active .gheader__megamenu-link.sp-aclink[aria-expanded="true"]::after {
            content: none; }
          .gheader.nav-active .gheader__megamenu-link.sp-aclink::before {
            content: "";
            background-image: none;
            background-color: #333;
            width: 15px;
            height: 2px;
            right: 7px;
            top: 50%;
            transform: translateY(-50%);
            left: auto; }
          .gheader.nav-active .gheader__megamenu-link.sp-aclink::after {
            content: "";
            background-image: none;
            background-color: #333;
            width: 2px;
            height: 15px;
            right: 13px;
            top: 50%;
            transform: translateY(-50%);
            left: auto; } }
      .gheader.nav-active .gheader__megamenu-contents {
        position: absolute;
        top: 130px;
        left: 0;
        display: grid;
        grid-template-columns: 8% 25% 67%;
        width: 100%;
        background: #fff;
        opacity: 0;
        visibility: hidden;
        transition: all .3s;
        max-height: 600px;
        height: 70vh; }
        @media (max-width: 767.98px) {
          .gheader.nav-active .gheader__megamenu-contents {
            background: #EFEFEF;
            height: auto;
            max-height: inherit;
            display: flex;
            flex-direction: column-reverse;
            top: auto;
            left: auto;
            opacity: 1;
            visibility: visible;
            position: static;
            margin-bottom: 30px; } }
    .gheader.nav-active .gheader__submenu-title {
      background: #0c2444;
      color: #fff;
      -ms-writing-mode: tb-rl;
      writing-mode: vertical-rl;
      text-align: center;
      display: flex;
      align-items: center;
      padding: 30px 0; }
      @media (max-width: 767.98px) {
        .gheader.nav-active .gheader__submenu-title {
          display: none; } }
      .gheader.nav-active .gheader__submenu-title .text {
        text-align: center;
        margin: 0; }
      .gheader.nav-active .gheader__submenu-title span {
        text-align: center; }
      .gheader.nav-active .gheader__submenu-title .en {
        font-family: "Crimson Text", Times, serif;
        font-weight: 400;
        line-height: 1; }
        @media (min-width: 768px) {
          .gheader.nav-active .gheader__submenu-title .en {
            font-size: 4.6rem; } }
        @media (max-width: 767.98px) {
          .gheader.nav-active .gheader__submenu-title .en {
            font-size: 4.6rem; } }
      .gheader.nav-active .gheader__submenu-title .ja {
        margin-top: 10px; }
        @media (min-width: 768px) {
          .gheader.nav-active .gheader__submenu-title .ja {
            font-size: 1.4rem; } }
        @media (max-width: 767.98px) {
          .gheader.nav-active .gheader__submenu-title .ja {
            font-size: 1.4rem; } }
    .gheader.nav-active .gheader__submenu-news {
      background: #004A98;
      color: #fff;
      padding: 30px 20px; }
      .gheader.nav-active .gheader__submenu-news-img img {
        aspect-ratio: 4/3;
        object-fit: cover;
        width: 100%; }
      .gheader.nav-active .gheader__submenu-news .post-date-1 {
        color: #fff; }
      .gheader.nav-active .gheader__submenu-news a::after {
        color: #fff; }
    .gheader.nav-active .gheader__submenu-listbox {
      position: relative; }
      @media (max-width: 767.98px) {
        .gheader.nav-active .gheader__submenu-listbox {
          position: static;
          background: #EFEFEF; } }
    .gheader.nav-active .gheader__submenu-list {
      padding: 30px 10px 20px 20px;
      width: 50%;
      overflow-y: auto;
      overflow-x: hidden;
      max-height: 600px;
      height: 70vh; }
      @media (max-width: 767.98px) {
        .gheader.nav-active .gheader__submenu-list {
          padding: 20px;
          width: 100%;
          height: auto;
          max-height: inherit; } }
      .gheader.nav-active .gheader__submenu-list .ttl_style2, .gheader.nav-active .gheader__submenu-list h3:not([class]) {
        margin-bottom: 20px; }
        @media (min-width: 768px) {
          .gheader.nav-active .gheader__submenu-list .ttl_style2, .gheader.nav-active .gheader__submenu-list h3:not([class]) {
            font-size: 2.4rem; } }
        @media (max-width: 767.98px) {
          .gheader.nav-active .gheader__submenu-list .ttl_style2, .gheader.nav-active .gheader__submenu-list h3:not([class]) {
            font-size: 1.8rem; } }
        .gheader.nav-active .gheader__submenu-list .ttl_style2 a, .gheader.nav-active .gheader__submenu-list h3:not([class]) a {
          display: block; }
      .gheader.nav-active .gheader__submenu-list .submenu-title {
        background-color: #999;
        color: #fff;
        padding: 3px 5px;
        margin-bottom: 10px; }
    .gheader.nav-active .gheader__submenu-item:hover a::after {
      right: -5px; }
    .gheader.nav-active .gheader__submenu-item:hover .gheader__submenu-list2 {
      opacity: 1;
      visibility: visible;
      transform: translateX(0); }
    .gheader.nav-active .gheader__submenu-item:hover .gheader__submenu-link {
      color: #004A98;
      opacity: 1; }
    @media (max-width: 767.98px) {
      .gheader.nav-active .gheader__submenu-item .sp-aclink::before {
        top: 42%; }
      .gheader.nav-active .gheader__submenu-item .sp-aclink::after {
        top: 42%; } }
    .gheader.nav-active .gheader__submenu-link {
      font-weight: bold;
      display: block;
      border-bottom: 1px solid #D3D3D3;
      margin-bottom: 10px;
      padding-bottom: 10px;
      position: relative;
      padding-right: 20px;
      line-height: 2 !important; }
      @media (max-width: 767.98px) {
        .gheader.nav-active .gheader__submenu-link {
          padding-right: 24px; } }
      .gheader.nav-active .gheader__submenu-link::after {
        content: "";
        background-image: url("/img/common/arrow_next_black.svg");
        background-repeat: no-repeat;
        width: 20px;
        height: 10px;
        display: block;
        position: absolute;
        right: 0px;
        top: 50%;
        transform: translateY(-50%);
        transition: .3s; }
      .gheader.nav-active .gheader__submenu-link.no-link {
        pointer-events: none; }
    .gheader.nav-active .gheader__submenu-list2 {
      background: #EFEFEF;
      padding: 15px 20px;
      width: 47%;
      bottom: 0px;
      right: 20px;
      top: 35px;
      position: absolute;
      overflow-y: auto;
      overflow-x: hidden;
      max-height: 565px;
      height: 565px;
      opacity: 0;
      visibility: hidden;
      transition: .3s;
      -ms-overflow-style: none;
      scrollbar-width: none;
      transform: translateX(-10px); }
      .gheader.nav-active .gheader__submenu-list2::-webkit-scrollbar {
        display: none; }
      @media (max-width: 767.98px) {
        .gheader.nav-active .gheader__submenu-list2 {
          display: none; } }
    .gheader.nav-active .gheader__submenu-link2 {
      display: block;
      border-bottom: 1px solid #D3D3D3;
      margin-bottom: 10px;
      padding-bottom: 10px;
      line-height: 2 !important; }
      @media (max-width: 767.98px) {
        .gheader.nav-active .gheader__submenu-link2 {
          display: none; } }
  .gheader__spmenu {
    display: none; }
    @media (max-width: 767.98px) {
      .gheader__spmenu {
        display: flex;
        justify-content: center;
        align-items: flex-end;
        position: fixed;
        bottom: 0;
        background-color: #004A98;
        color: #fff;
        z-index: 9999;
        width: 100%;
        height: 60px; } }
    .gheader__spmenu.en .gheader__spmenu-item {
      width: 138px;
      border: none; }
      .gheader__spmenu.en .gheader__spmenu-item a {
        width: 100px;
        text-align: center;
        display: flex;
        align-items: center;
        justify-content: center;
        font-size: 15px; }
    .gheader__spmenu.en .gheader__common-icon {
      margin: 0 5px 0 0; }
    @media (max-width: 767.98px) {
      .gheader__spmenu-item {
        height: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
        width: 72px;
        padding-top: 5px; }
        .gheader__spmenu-item:first-child {
          border-right: 1px solid #fff; }
        .gheader__spmenu-item:last-child {
          border-left: 1px solid #fff; }
        .gheader__spmenu-item a {
          font-size: 10px;
          white-space: nowrap;
          text-decoration: none; } }
  @media (max-width: 767.98px) {
    .gheader__submenu-item .gheader__megamenu-link {
      margin-bottom: 10px !important;
      padding-top: 0 !important;
      padding-left: 0 !important;
      padding-bottom: 10px !important; } }
  @media (max-width: 767.98px) {
    .gheader__submenu-item .gheader__submenu-list2 {
      display: block !important;
      position: static !important;
      padding: 5px 0 0 !important;
      margin: 0 !important;
      width: auto !important;
      height: auto !important;
      opacity: 1 !important;
      visibility: visible !important;
      transform: none !important; }
      .gheader__submenu-item .gheader__submenu-list2 .gheader__submenu-link2 {
        display: block !important; } }

.sp_graymenu {
  background: #EFEFEF; }
  .sp_graymenu .gheader__megamenu-link.link::after {
    right: 2px !important; }
  .sp_graymenu .gheader__submenu-list {
    background: #004A98; }
    .sp_graymenu .gheader__submenu-list .gheader__submenu-link {
      color: #fff; }
      .sp_graymenu .gheader__submenu-list .gheader__submenu-link::after {
        background-image: url(/img/common/arrow_next_white2.svg); }

.sp_bottom-item {
  display: flex;
  justify-content: center;
  margin-top: 30px; }
  .sp_bottom-item .gheader__common-icon {
    display: inline;
    margin-right: 5px; }
  .sp_bottom-item span {
    width: 2px;
    height: 20px;
    background-color: #D3D3D3;
    display: block;
    margin-right: 30px;
    margin-left: 40px; }
  .sp_bottom-item a:first-child {
    margin-left: 38px; }
  .sp_bottom-item.en a {
    margin-left: 0; }

.sp_sns .gfooter__sns {
  margin-top: 40px; }
  .sp_sns .gfooter__sns a::after {
    content: none !important; }

.sp_search {
  margin-top: 30px; }
  .sp_search .search-content {
    display: flex; }
  .sp_search input {
    width: 100%;
    border: 2px solid #004A98;
    font-size: 16px;
    outline: none; }
    .sp_search input[type="text"]:focus {
      border-radius: none; }
  .sp_search button {
    width: 40px;
    flex: 0 0 40px;
    height: 40px;
    background: #004A98;
    border: none; }
    .sp_search button img {
      width: 20px !important;
      height: 20px !important; }

/*　ハンバーガーボタン　*/
.hamburger {
  display: block;
  position: relative;
  width: 70px;
  height: 70px;
  cursor: pointer;
  text-align: center;
  background-color: #333;
  border-radius: 50%;
  bottom: -10px;
  margin: 0 5px; }
  .hamburger::before {
    content: "";
    background-color: #004A98;
    border: 2px solid #fff;
    display: block;
    width: 90px;
    height: 90px;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    z-index: -1;
    position: absolute;
    border-radius: 50%; }

.hamburger span {
  display: block;
  position: absolute;
  width: 27px;
  height: 2px;
  left: 50%;
  transform: translateX(-50%);
  z-index: 3;
  background: #fff;
  -webkit-transition: 0.3s ease-in-out;
  -moz-transition: 0.3s ease-in-out;
  transition: 0.3s ease-in-out; }

.hamburger span:nth-child(1) {
  top: 20px; }

.hamburger span:nth-child(2) {
  top: 30px; }

.hamburger span:nth-child(3) {
  top: 40px; }

/* ナビ開いてる時のボタン */
.hamburger.active span:nth-child(1) {
  top: 30px;
  background: #fff;
  -webkit-transform: rotate(-45deg);
  -moz-transform: rotate(-45deg);
  transform: translateX(-50%) rotate(-45deg); }

.hamburger.active span:nth-child(2),
.hamburger.active span:nth-child(3) {
  top: 30px;
  background: #fff;
  -webkit-transform: rotate(45deg);
  -moz-transform: rotate(45deg);
  transform: translateX(-50%) rotate(45deg); }

.gfooter {
  position: relative;
  z-index: 1;
  background-color: #fff;
  border-top: 1px solid #D3D3D3; }
  .gfooter a {
    text-decoration: none; }
  .gfooter-link {
    position: relative;
    padding-left: 15px;
    display: inline-block; }
    .gfooter-link::before {
      font-family: fontawesome;
      content: "\f105";
      position: absolute;
      color: #333;
      left: 0;
      top: 50%;
      transform: translateY(-50%); }
  .gfooter__logo {
    max-width: 200px;
    margin-right: 60px; }
    @media (max-width: 767.98px) {
      .gfooter__logo {
        margin: 0 auto 20px;
        margin-right: auto; } }
  .gfooter__sns {
    text-align: center; }
    .gfooter__sns img {
      max-width: 40px;
      margin: 0 5px; }
    .gfooter__sns-box {
      display: flex;
      justify-content: space-between;
      margin: 40px 0 20px; }
      @media (max-width: 767.98px) {
        .gfooter__sns-box {
          display: block; } }
    .gfooter__sns-head {
      display: flex;
      align-items: center;
      margin-bottom: 40px; }
      @media (max-width: 767.98px) {
        .gfooter__sns-head {
          display: block; } }
    .gfooter__sns-btn {
      text-decoration: none;
      border: 1px solid #333;
      padding: 3px 15px;
      border-radius: 30px;
      display: flex;
      align-items: center; }
      @media (max-width: 767.98px) {
        .gfooter__sns-btn {
          display: flex;
          justify-content: center;
          width: 120px;
          margin: 20px auto 0; } }
      .gfooter__sns-btn::before {
        font-family: fontawesome;
        content: "\f105";
        color: #333;
        margin-right: 5px;
        height: 29px; }
  .gfooter__mainmenu-inner {
    display: flex;
    align-items: flex-start; }
    @media (max-width: 767.98px) {
      .gfooter__mainmenu-inner {
        display: block; } }
  .gfooter__mainmenu-list {
    display: flex;
    list-style: none;
    flex-wrap: wrap; }
    @media (max-width: 767.98px) {
      .gfooter__mainmenu-list {
        width: 100%;
        justify-content: space-between; } }
  .gfooter__mainmenu-item {
    width: 25%;
    margin-bottom: 10px; }
    @media (max-width: 1079.98px) {
      .gfooter__mainmenu-item {
        width: auto;
        margin-right: 30px; } }
    @media (max-width: 767.98px) {
      .gfooter__mainmenu-item {
        width: 50%;
        margin-right: 0px; } }
  .gfooter__mainmenu-subblock {
    width: 25%; }
    @media (max-width: 767.98px) {
      .gfooter__mainmenu-subblock {
        width: 100%;
        margin-top: 10px; } }
  .gfooter__mainmenu-sublist {
    list-style: none; }
    @media (max-width: 767.98px) {
      .gfooter__mainmenu-sublist {
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;
        margin-top: 10px; } }
  .gfooter__mainmenu-subitem {
    margin-bottom: 10px; }
    @media (max-width: 767.98px) {
      .gfooter__mainmenu-subitem {
        width: 50%; } }
  .gfooter__mainmenu-subtitle {
    font-weight: bold;
    border-bottom: 1px solid #333;
    padding-bottom: 5px;
    margin-bottom: 5px; }
    @media (min-width: 768px) {
      .gfooter__mainmenu-subtitle {
        font-size: 1.4rem; } }
    @media (max-width: 767.98px) {
      .gfooter__mainmenu-subtitle {
        font-size: 1.4rem; } }
  .gfooter__school {
    background: #EFEFEF;
    padding-top: 20px;
    padding-bottom: 20px; }
    .gfooter__school-inner {
      width: 100%;
      margin: 0 auto;
      max-width: 1200px; }
      @media (max-width: 767.98px) {
        .gfooter__school-inner {
          width: 100%; } }
    .gfooter__school-title {
      font-weight: bold;
      padding-bottom: 10px;
      text-align: center;
      margin-bottom: 10px;
      border-bottom: 2px solid #fff; }
      @media (max-width: 767.98px) {
        .gfooter__school-title {
          text-align: left; } }
    .gfooter__school-list {
      list-style: none;
      display: flex;
      justify-content: center;
      gap: 30px;
      flex-wrap: wrap; }
      @media (max-width: 767.98px) {
        .gfooter__school-list {
          justify-content: start;
          gap: 0px; } }
    @media (max-width: 767.98px) {
      .gfooter__school-item {
        width: 50%;
        margin-top: 10px; } }
  .gfooter__policy {
    margin: 40px 0 30px; }
    @media (min-width: 768px) {
      .gfooter__policy {
        font-size: 1.4rem; } }
    @media (max-width: 767.98px) {
      .gfooter__policy {
        font-size: 1.4rem; } }
    @media (max-width: 767.98px) {
      .gfooter__policy {
        margin: 20px 0 30px; } }
    .gfooter__policy-list {
      list-style: none;
      display: flex;
      justify-content: center;
      align-items: center; }
      @media (max-width: 767.98px) {
        .gfooter__policy-list {
          display: block;
          text-align: center; } }
    .gfooter__policy-item {
      border-right: 1px solid #333;
      padding: 0 20px; }
      @media (max-width: 767.98px) {
        .gfooter__policy-item {
          border-right: none;
          margin-bottom: 10px; } }
      .gfooter__policy-item:last-child {
        border-right: none; }
      .gfooter__policy-item.style2 {
        padding: 0; }
        .gfooter__policy-item.style2 a {
          border: 1px solid #333;
          border-radius: 30px;
          padding: 5px 8px;
          display: inline-block;
          text-align: center; }
        .gfooter__policy-item.style2-prev {
          border-right: none; }
  @media (max-width: 767.98px) {
    .gfooter.en .gfooter__sns-block {
      width: 100%; } }
  .gfooter.en .gfooter__sns-head {
    margin: 40px 0 20px; }
  @media (max-width: 767.98px) {
    .gfooter.en .gfooter__mainmenu-item {
      width: 50%; } }
  @media (max-width: 767.98px) {
    .gfooter.en .gfooter__mainmenu-sublist {
      display: block; } }
  @media (max-width: 767.98px) {
    .gfooter.en .gfooter__mainmenu-subitem {
      width: 100%;
      margin: 30px 0; } }
  @media (max-width: 767.98px) {
    .gfooter.en .gfooter__policy {
      margin-bottom: 60px; } }

.copy {
  text-align: center;
  font-size: 1.1rem;
  background-color: #004A98;
  color: #fff;
  padding: 10px 0; }

.pagetop {
  position: fixed;
  bottom: 40px;
  right: 30px;
  z-index: 9999;
  width: 40px;
  height: 40px;
  overflow: hidden;
  transition: .3s;
  opacity: 0;
  visibility: hidden; }
  @media (max-width: 767.98px) {
    .pagetop {
      bottom: 60px;
      right: 10px; } }
  .pagetop.active {
    opacity: 1;
    visibility: visible; }
  .pagetop a {
    position: relative;
    background-color: #fff;
    border: 1px solid #004A98;
    width: 100%;
    height: 100%;
    border-radius: 50%;
    display: block;
    padding: .5em 15px;
    text-align: center; }
    .pagetop a::before {
      content: '';
      background-image: url(/img/common/pagetop_arrow.svg);
      background-repeat: no-repeat;
      display: inline-block;
      width: 10px;
      height: 15px;
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%); }

.en .gfooter__sns-box {
  align-items: flex-end; }

.en .gfooter__mainmenu-subblock {
  width: auto !important; }

.en .gfooter__sns-block {
  width: 70%; }

.en .gfooter__mainmenu-item {
  width: 16%; }

.gnav {
  position: absolute;
  top: calc(100vh - 390px);
  right: 20px; }
  @media (max-width: 767.98px) {
    .gnav {
      display: none; } }
  .gnav.heightover {
    position: absolute;
    top: 750px; }
  .gnav__inner {
    display: flex; }
  .gnav__title {
    color: #fff;
    font-family: "Crimson Text", Times, serif;
    font-weight: 400;
    -ms-writing-mode: tb-rl;
    writing-mode: vertical-rl;
    border-right: 1px solid #fff;
    text-shadow: 2px 1px 2px rgba(0, 0, 0, 0.6); }
    @media (min-width: 768px) {
      .gnav__title {
        font-size: 2.6rem; } }
    @media (max-width: 767.98px) {
      .gnav__title {
        font-size: 2.6rem; } }
  .gnav__common {
    color: #fff;
    padding-left: 20px;
    padding-bottom: 20px; }
    .gnav__common-item {
      margin-bottom: 5px;
      text-shadow: 2px 1px 2px rgba(0, 0, 0, 0.6); }

.overlay, .youtube {
  position: fixed;
  top: 0;
  right: 0;
  min-width: 100%;
  min-height: 100%;
  width: 177.77778vh !important;
  /* 16:9 の幅なので 177.77% (= 16 ÷ 9) */
  height: 56.25vw !important;
  /* 16:9 の高さなので 56.25% (= 9 ÷ 16) */ }
  @media (max-width: 767.98px) {
    .overlay, .youtube {
      right: auto;
      left: 50%;
      transform: translateX(-50%); } }

.overlay {
  z-index: -1; }

.youtube {
  z-index: -2; }

#movieStart {
  background-size: cover !important;
  top: 70px;
  right: 0;
  position: fixed;
  width: 100%;
  height: 100%;
  visibility: inherit;
  z-index: -1; }
  @media (max-width: 767.98px) {
    #movieStart {
      right: auto;
      left: 50%;
      transform: translateX(-50%);
      background-position: center; } }

.home #movieStart {
  background: url("/video/home/top_moviethum.jpg") no-repeat 0 0; }
  @media (max-width: 767.98px) {
    .home #movieStart {
      background: url("/video/home/top_moviethum.jpg") no-repeat center center; } }

.about #movieStart {
  background: url("/video/about/about_moviethum.jpg") no-repeat 0 0; }
  @media (max-width: 767.98px) {
    .about #movieStart {
      background: url("/video/about/about_moviethum.jpg") no-repeat 70% 30%; } }

.academics #movieStart {
  background: url("/video/academics/academics_moviethum.jpg") no-repeat 0 0; }
  @media (max-width: 767.98px) {
    .academics #movieStart {
      background: url("/video/academics/academics_moviethum.jpg") no-repeat center top; } }

.research #movieStart {
  background: url("/video/research/research_moviethum.jpg") no-repeat 0 0; }
  @media (max-width: 767.98px) {
    .research #movieStart {
      background: url("/video/research/research_moviethum.jpg") no-repeat 45% top; } }

.global #movieStart {
  background: url("/video/global/global_moviethum.jpg") no-repeat 0 0; }
  @media (max-width: 767.98px) {
    .global #movieStart {
      background: url("/video/global/global_moviethum.jpg") no-repeat 45% top; } }

.contribution #movieStart {
  background: url("/video/contribution/contribution_moviethum.jpg") no-repeat 0 0; }
  @media (max-width: 767.98px) {
    .contribution #movieStart {
      background: url("/video/contribution/contribution_moviethum.jpg") no-repeat 45% top; } }

.career #movieStart {
  background: url("/video/career/career_moviethum.jpg") no-repeat 0 0; }
  @media (max-width: 767.98px) {
    .career #movieStart {
      background: url("/video/career/career_moviethum.jpg") no-repeat 45% top; } }

.life #movieStart {
  background: url("/video/life/life_moviethum.jpg") no-repeat 0 0; }
  @media (max-width: 767.98px) {
    .life #movieStart {
      background: url("/video/life/life_moviethum.jpg") no-repeat 40% top; } }

.device-js-on #movieStart {
  display: none; }

@media (max-width: 767.98px) {
  .pc {
    display: none; } }

@media (min-width: 768px) {
  .sp {
    display: none; } }

.contents {
  width: 100%; }
  @media (min-width: 1080px) {
    .contents {
      margin-left: auto;
      margin-right: auto; } }
  .contents main {
    padding: 120px 0; }
    @media screen and (min-width: 812px) and (orientation: landscape) and (-webkit-device-pixel-ratio: 3) {
      .contents main {
        padding-right: constant(safe-area-inset-right);
        padding-left: constant(safe-area-inset-left);
        padding-right: env(safe-area-inset-right);
        padding-left: env(safe-area-inset-left); } }

.header_category {
  padding: 30px 15px;
  background-color: #f8f9fa;
  text-align: center; }
  @media screen and (min-width: 812px) and (orientation: landscape) and (-webkit-device-pixel-ratio: 3) {
    .header_category__wrapper {
      padding-right: constant(safe-area-inset-right);
      padding-left: constant(safe-area-inset-left);
      padding-right: env(safe-area-inset-right);
      padding-left: env(safe-area-inset-left); } }

.pagination {
  justify-content: center;
  align-items: center;
  font-family: "Crimson Text", Times, serif;
  font-weight: 400; }
  @media (min-width: 768px) {
    .pagination {
      font-size: 1.8rem; } }
  @media (max-width: 767.98px) {
    .pagination {
      font-size: 1.8rem; } }
  .pagination .link_page {
    color: #D3D3D3; }
  .pagination .current_page {
    color: #004A98;
    background: #EFEFEF;
    padding: 5px 12px;
    border-radius: 50%; }
  .pagination .link_last, .pagination .link_first {
    font-weight: bold;
    padding-top: 3px; }
    @media (min-width: 768px) {
      .pagination .link_last, .pagination .link_first {
        font-size: 1.2rem; } }
    @media (max-width: 767.98px) {
      .pagination .link_last, .pagination .link_first {
        font-size: 1.2rem; } }
  .pagination .link_first {
    margin-right: 10px; }
  .pagination .link_last {
    margin-left: 10px; }
  .pagination span, .pagination a {
    margin: 0 5px;
    text-decoration: none; }

* {
  min-height: 0;
  min-width: 0; }

.home a {
  text-decoration: none; }

.home__btn-style1 {
  width: 110px; }
  .home__btn-style1 a {
    padding: 5px 0;
    display: flex;
    justify-content: space-between;
    align-items: center;
    font-family: "Crimson Text", Times, serif;
    font-weight: 400; }
    .home__btn-style1 a::after {
      content: "";
      background-image: url("/img/common/arrow_next_black.svg");
      background-repeat: no-repeat;
      width: 20px;
      height: 10px;
      margin-left: 10px; }
    .home__btn-style1 a:hover {
      opacity: 1; }
  .home__btn-style1::before, .home__btn-style1::after {
    content: "";
    background-color: #333;
    height: 1px;
    width: 100%;
    display: block;
    transition: .15s linear;
    position: relative;
    bottom: 0; }
  .home__btn-style1:hover::after {
    position: relative;
    bottom: -5px; }

.home__wrapper {
  position: relative; }
  .home__wrapper .top-box {
    display: flex;
    flex-direction: column; }
    @media (max-width: 767.98px) {
      .home__wrapper .top-box {
        flex-direction: column-reverse; } }

.home__important {
  position: relative;
  z-index: 2;
  display: none;
  overflow: hidden;
  top: 0; }
  @media (min-width: 768px) {
    .home__important {
      font-size: 1.4rem; } }
  @media (max-width: 767.98px) {
    .home__important {
      font-size: 1.4rem; } }
  .home__important.active {
    display: flex; }
    @media (max-width: 767.98px) {
      .home__important.active {
        display: block; } }
  .home__important .slick-list {
    z-index: 1; }
  .home__important .slick-dotted {
    margin-bottom: 0px !important; }
  .home__important .slick-dots {
    justify-content: flex-start !important;
    left: -70px;
    top: 13px;
    bottom: auto !important; }
    @media (max-width: 767.98px) {
      .home__important .slick-dots {
        left: 150px;
        top: -22px; } }
  .home__important .slick-dots > li:first-child:last-child {
    display: none; }
  .home__important .slick-dots li {
    margin-left: 0px !important; }
    @media (max-width: 767.98px) {
      .home__important .slick-dots li {
        margin-right: 20px !important; } }
    .home__important .slick-dots li.slick-active {
      position: relative; }
      .home__important .slick-dots li.slick-active button {
        color: #feff50 !important; }
      .home__important .slick-dots li.slick-active::after {
        content: "";
        position: absolute;
        width: 8px;
        height: 1px;
        background-color: #feff50;
        bottom: -5px;
        left: 1px; }
  .home__important .slick-dots li button {
    font-size: initial;
    color: white;
    background: initial; }
  .home__important .slick-dots li button:before {
    content: none !important; }
  .home__important-title {
    background: #000;
    color: #fff;
    padding: 5px 0;
    flex: 0 0 300px;
    text-align: center; }
    @media (max-width: 767.98px) {
      .home__important-title {
        width: 100%;
        text-align: left;
        padding-left: 20px; } }
  .home__important-list {
    width: calc(100% - 200px);
    background: #E81826; }
    @media (max-width: 767.98px) {
      .home__important-list {
        width: 100%; } }
  .home__important-item {
    list-style: none;
    color: #fff;
    margin-left: 20px; }
  .home__important-link {
    text-decoration: none;
    padding: 5px 0 5px 20px;
    display: block;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis; }
    @media (max-width: 767.98px) {
      .home__important-link {
        padding: 10px 0 10px 20px; } }
    .home__important-link .post-date-1 {
      border-right: 1px solid #fff;
      margin-right: 10px;
      padding-right: 10px;
      line-height: 1; }
      @media (max-width: 767.98px) {
        .home__important-link .post-date-1 {
          display: block;
          border-right: none;
          margin-bottom: 5px; } }

.home__mv {
  position: relative;
  padding-bottom: 1100px; }
  @media (max-width: 767.98px) {
    .home__mv {
      padding-bottom: 60px; } }
  .home__mv-video {
    position: fixed;
    z-index: -1;
    width: 100%;
    height: 100vh;
    object-fit: cover; }
    .home__mv-video img {
      width: 100%;
      height: 100vh;
      object-fit: cover; }
  .home__mv-copy-box {
    display: flex;
    align-items: flex-end;
    position: absolute;
    left: 100px;
    top: 250px; }
    @media (max-width: 1079.98px) {
      .home__mv-copy-box {
        left: 20px; } }
    @media (max-width: 767.98px) {
      .home__mv-copy-box {
        display: flex;
        align-items: flex-start;
        flex-direction: column-reverse;
        position: static;
        padding: 100px 20px 0; } }
  .home__mv-copy-title {
    padding-left: 320px;
    padding-bottom: 350px;
    width: 100%; }
    @media (max-width: 767.98px) {
      .home__mv-copy-title {
        padding-left: 30px;
        padding-bottom: 0px;
        width: 30%;
        max-width: 100px;
        margin-bottom: 30px; } }
    .home__mv-copy-title img {
      width: 100%; }
  .home__mv-copy-text {
    position: absolute;
    top: 475px;
    right: 0px;
    color: #fff;
    font-family: "Noto Serif JP", "游明朝", "Hiragino Mincho ProN", "ヒラギノ明朝 W3 JIS2004", "Times New Roman", Times, serif;
    font-weight: 400;
    font-weight: 100;
    line-height: 2.4;
    text-shadow: 0px 3px 6px #000; }
    @media (min-width: 768px) {
      .home__mv-copy-text {
        font-size: 1.7rem; } }
    @media (max-width: 767.98px) {
      .home__mv-copy-text {
        font-size: 1.6rem; } }
    @media (max-width: 767.98px) {
      .home__mv-copy-text {
        position: static;
        width: 100%;
        max-width: 300px; } }

.home__about {
  background: #fff;
  overflow-x: hidden; }
  .home__about-inner {
    width: 100%;
    display: block;
    padding-top: 160px;
    background-image: url("/img/home/about_bk.jpg");
    background-repeat: no-repeat;
    background-size: cover;
    display: block;
    margin-top: 350px; }
    @media (max-width: 767.98px) {
      .home__about-inner {
        padding-top: 0;
        margin-top: 550px; } }
  .home__about-content {
    display: flex;
    margin-left: 85px;
    min-width: 1600px;
    margin-top: -352px; }
    @media (max-width: 1079.98px) {
      .home__about-content {
        margin-left: 20px;
        margin-top: -325px; } }
    @media (max-width: 767.98px) {
      .home__about-content {
        display: block;
        margin-left: 0px;
        margin-right: 0px;
        position: relative;
        padding-top: 70px;
        min-width: auto; } }
  .home__about-title {
    margin-right: 30px; }
    @media (max-width: 767.98px) {
      .home__about-title {
        margin-right: 0;
        position: absolute;
        top: -146px;
        left: 50%;
        transform: translateX(-50%); } }
    .home__about-title img {
      max-width: 140px; }
      @media (max-width: 1079.98px) {
        .home__about-title img {
          max-width: 120px; } }
      @media (max-width: 767.98px) {
        .home__about-title img {
          max-width: 140px; } }
  .home__about-slider1 {
    width: 80%; }
    @media (max-width: 767.98px) {
      .home__about-slider1 {
        width: 100%;
        padding: 0 20px; } }
    .home__about-slider1 .home-slider1-item {
      padding: 0 15px; }
      @media (max-width: 767.98px) {
        .home__about-slider1 .home-slider1-item {
          padding: 0; } }
    .home__about-slider1 .home-slider1-link {
      transition: all 0.2s linear;
      color: currentColor; }
      .home__about-slider1 .home-slider1-link:hover {
        color: currentColor;
        text-decoration: none;
        opacity: 1; }
      .home__about-slider1 .home-slider1-link figure {
        overflow: hidden; }
      .home__about-slider1 .home-slider1-link img {
        transition: all 0.6s; }
      .home__about-slider1 .home-slider1-link:hover a {
        opacity: 1; }
      .home__about-slider1 .home-slider1-link:hover img {
        transform: scale(1.1); }
      .home__about-slider1 .home-slider1-link img {
        aspect-ratio: 4/3;
        object-fit: cover; }
      .home__about-slider1 .home-slider1-link figure {
        position: relative; }
        .home__about-slider1 .home-slider1-link figure::before {
          content: "";
          background-image: url("/img/common/arrow_next_black.svg");
          background-position: center;
          background-repeat: no-repeat;
          width: 20px;
          height: 10px;
          background-color: #fff;
          position: absolute;
          bottom: 0;
          right: -1px;
          opacity: 1;
          z-index: 2;
          padding: 20px; }
    .home__about-slider1 .home-slider1-num {
      position: absolute;
      font-family: "Crimson Text", Times, serif;
      font-weight: 400;
      top: 0;
      right: 0;
      line-height: 1;
      border-left: 1px solid #333;
      padding-top: 10px;
      padding-right: 10px;
      padding-left: 8px; }
      @media (min-width: 768px) {
        .home__about-slider1 .home-slider1-num {
          font-size: 1.4rem; } }
      @media (max-width: 767.98px) {
        .home__about-slider1 .home-slider1-num {
          font-size: 1.4rem; } }
      .home__about-slider1 .home-slider1-num span {
        font-weight: 100;
        display: block;
        line-height: .8;
        margin-top: 5px;
        letter-spacing: 3px; }
        @media (min-width: 768px) {
          .home__about-slider1 .home-slider1-num span {
            font-size: 5.6rem; } }
        @media (max-width: 767.98px) {
          .home__about-slider1 .home-slider1-num span {
            font-size: 5.6rem; } }
    .home__about-slider1 .home-slider1-text {
      color: #fff; }
    .home__about-slider1 .home-slider1 .slick-arrow {
      background: #000;
      padding: 20px; }
      @media (min-width: 768px) {
        .home__about-slider1 .home-slider1 .slick-arrow {
          background: none; } }
      .home__about-slider1 .home-slider1 .slick-arrow::before {
        content: "" !important;
        position: absolute;
        top: 50%;
        left: 15px;
        margin: 0 auto;
        text-align: centers;
        transform: translateY(-50%);
        width: 10px;
        height: 19px;
        opacity: 1;
        margin-top: 0em; }
    .home__about-slider1 .home-slider1 .slick-prev {
      left: -20px; }
      @media (min-width: 768px) {
        .home__about-slider1 .home-slider1 .slick-prev {
          left: -165px;
          top: auto !important;
          bottom: -65px; } }
      @media (max-width: 1080px) {
        .home__about-slider1 .home-slider1 .slick-prev {
          bottom: -95px; } }
    .home__about-slider1 .home-slider1 .slick-next {
      right: -20px; }
      @media (min-width: 768px) {
        .home__about-slider1 .home-slider1 .slick-next {
          right: auto;
          left: -115px;
          top: auto !important;
          bottom: -65px; } }
      @media (max-width: 1080px) {
        .home__about-slider1 .home-slider1 .slick-next {
          bottom: -95px; } }
    .home__about-slider1 .home-slider1 .slick-next:before {
      background-image: url("/img/common/arrow_next_white.svg") !important;
      background-repeat: no-repeat;
      background-size: 100%; }
      @media (min-width: 768px) {
        .home__about-slider1 .home-slider1 .slick-next:before {
          width: 15px;
          height: 30px; } }
    .home__about-slider1 .home-slider1 .slick-prev:before {
      background-image: url("/img/common/arrow_prev_white.svg") !important;
      background-repeat: no-repeat;
      background-size: 100%; }
      @media (min-width: 768px) {
        .home__about-slider1 .home-slider1 .slick-prev:before {
          width: 15px;
          height: 30px; } }
  .home__about-slider2 {
    width: 100%;
    background-color: rgba(0, 0, 0, 0.6);
    margin-top: 60px; }
    @media (max-width: 1079.98px) {
      .home__about-slider2 {
        margin-top: 30px; } }
    .home__about-slider2 .home-slider2 {
      max-width: 1600px;
      margin: 0 auto;
      padding: 70px 100px; }
      @media (max-width: 1079.98px) {
        .home__about-slider2 .home-slider2 {
          max-width: none;
          padding: 40px 70px; } }
      @media (max-width: 767.98px) {
        .home__about-slider2 .home-slider2 {
          padding: 20px; } }
      .home__about-slider2 .home-slider2-item {
        padding: 0 10px; }
      .home__about-slider2 .home-slider2 .slick-arrow:before {
        content: "" !important;
        position: absolute;
        top: -4px;
        width: 20px;
        height: 33px;
        opacity: 1;
        margin-top: 0em; }
      .home__about-slider2 .home-slider2 .slick-prev {
        left: 50px; }
        @media (max-width: 1079.98px) {
          .home__about-slider2 .home-slider2 .slick-prev {
            left: 20px; } }
      .home__about-slider2 .home-slider2 .slick-next {
        right: 50px; }
        @media (max-width: 1079.98px) {
          .home__about-slider2 .home-slider2 .slick-next {
            right: 20px; } }
      .home__about-slider2 .home-slider2 .slick-next:before {
        background-image: url("/img/common/arrow_next_white.svg") !important;
        background-repeat: no-repeat; }
      .home__about-slider2 .home-slider2 .slick-prev:before {
        background-image: url("/img/common/arrow_prev_white.svg") !important;
        background-repeat: no-repeat; }

.home__section {
  position: relative;
  background: #fff;
  padding-bottom: 160px; }
  .home__section::before {
    content: "";
    background: linear-gradient(#004a98 0%, #152740 100%);
    width: 300px;
    height: 100%;
    position: absolute;
    z-index: 0; }
    @media (max-width: 1079.98px) {
      .home__section::before {
        width: 262px; } }
    @media (max-width: 767.98px) {
      .home__section::before {
        width: 129px; } }

.home__discover {
  background: #fff;
  overflow-x: hidden !important; }
  .home__discover-inner {
    padding: 160px 0;
    position: relative;
    z-index: 1; }
    @media (max-width: 767.98px) {
      .home__discover-inner {
        padding: 80px 0; } }
  .home__discover-titlebox {
    display: flex;
    justify-content: space-between;
    align-items: flex-end;
    margin-left: 85px;
    padding-right: 30px; }
    @media (max-width: 1079.98px) {
      .home__discover-titlebox {
        display: block;
        margin-left: 70px; } }
    @media (max-width: 767.98px) {
      .home__discover-titlebox {
        margin-left: 20px; } }
  .home__discover-title {
    max-width: 500px; }
    @media (max-width: 1079.98px) {
      .home__discover-title {
        max-width: 450px; } }
    @media (max-width: 767.98px) {
      .home__discover-title {
        max-width: 265px; } }
  .home__discover-text {
    padding-bottom: 40px;
    margin-left: 20px; }
    @media (min-width: 768px) {
      .home__discover-text {
        font-size: 1.4rem; } }
    @media (max-width: 767.98px) {
      .home__discover-text {
        font-size: 1.4rem; } }
    @media (max-width: 1079.98px) {
      .home__discover-text {
        margin: 0 0 0 auto;
        width: 50%; } }
  .home__discover-slider {
    position: relative; }
    .home__discover-slider .swiper-option {
      position: absolute;
      right: 100px;
      bottom: -50px;
      display: flex; }
      @media (max-width: 767.98px) {
        .home__discover-slider .swiper-option {
          position: static;
          display: block; } }
    .home__discover-slider .swiper-pagination {
      font-family: "Crimson Text", Times, serif;
      font-weight: 400;
      display: flex;
      align-items: center;
      position: static; }
      @media (max-width: 767.98px) {
        .home__discover-slider .swiper-pagination {
          justify-content: flex-end;
          padding: 10px 20px 0 0; } }
      .home__discover-slider .swiper-pagination::after {
        content: "";
        width: 1px;
        height: 15px;
        background-color: #333;
        margin: 0 20px 0 30px; }
        @media (max-width: 767.98px) {
          .home__discover-slider .swiper-pagination::after {
            content: none; } }
      .home__discover-slider .swiper-pagination span {
        margin: 0 5px; }
    .home__discover-slider .swiper-arrow-box {
      position: relative; }
      @media (max-width: 767.98px) {
        .home__discover-slider .swiper-arrow-box {
          position: absolute;
          top: 50%;
          transform: translateY(-50%);
          z-index: 1;
          width: 100%; } }
    .home__discover-slider .swiper-arrow {
      position: absolute; }
      @media (max-width: 767.98px) {
        .home__discover-slider .swiper-arrow {
          background: #000;
          padding: 20px; } }
      .home__discover-slider .swiper-arrow::after {
        background-repeat: no-repeat;
        width: 20px;
        height: 10px; }
        @media (max-width: 767.98px) {
          .home__discover-slider .swiper-arrow::after {
            position: absolute;
            width: 10px !important;
            height: 19px !important;
            background-size: 100%; } }
    .home__discover-slider .swiper-button-next {
      right: -70px; }
      @media (max-width: 767.98px) {
        .home__discover-slider .swiper-button-next {
          right: 0; } }
      .home__discover-slider .swiper-button-next::after {
        content: "";
        background-image: url("/img/common/arrow_next_black.svg"); }
        @media (max-width: 767.98px) {
          .home__discover-slider .swiper-button-next::after {
            background-image: url("/img/common/arrow_next_white.svg") !important; } }
    .home__discover-slider .swiper-button-prev {
      right: 0px; }
      @media (max-width: 767.98px) {
        .home__discover-slider .swiper-button-prev {
          right: auto;
          left: 0; } }
      .home__discover-slider .swiper-button-prev::after {
        content: "";
        background-image: url("/img/common/arrow_prev_black.svg"); }
        @media (max-width: 767.98px) {
          .home__discover-slider .swiper-button-prev::after {
            background-image: url("/img/common/arrow_prev_white.svg") !important; } }
    .home__discover-slider .home__btn-style1 {
      position: absolute;
      right: 100px;
      bottom: -160px; }
      @media (max-width: 767.98px) {
        .home__discover-slider .home__btn-style1 {
          right: 20px;
          bottom: -120px; } }
  .home__discover .slideswiper {
    min-width: 1400px; }
    @media (max-width: 767.98px) {
      .home__discover .slideswiper {
        min-width: auto;
        overflow-x: hidden;
        margin: 0 20px; } }
    .home__discover .slideswiper .swiper-wrapper {
      margin-left: 85px; }
      @media (max-width: 767.98px) {
        .home__discover .slideswiper .swiper-wrapper {
          margin-left: auto !important; } }
      .home__discover .slideswiper .swiper-wrapper .swiper-slide-active {
        transform: scale(1.2) !important;
        margin: 0 40px 0 30px !important; }
        @media (max-width: 767.98px) {
          .home__discover .slideswiper .swiper-wrapper .swiper-slide-active {
            transform: none !important;
            margin: auto !important; } }
      .home__discover .slideswiper .swiper-wrapper .swiper-slide {
        transform: scale(1);
        transform-origin: top;
        transition: opacity .5s, transform .5s;
        transition: all 0.2s linear;
        color: currentColor; }
        .home__discover .slideswiper .swiper-wrapper .swiper-slide:hover {
          color: currentColor;
          text-decoration: none;
          opacity: 1; }
        .home__discover .slideswiper .swiper-wrapper .swiper-slide figure {
          overflow: hidden; }
        .home__discover .slideswiper .swiper-wrapper .swiper-slide img {
          transition: all 0.6s; }
        .home__discover .slideswiper .swiper-wrapper .swiper-slide:hover a {
          opacity: 1; }
        .home__discover .slideswiper .swiper-wrapper .swiper-slide:hover img {
          transform: scale(1.1); }
        @media (max-width: 767.98px) {
          .home__discover .slideswiper .swiper-wrapper .swiper-slide {
            transform: none;
            transform-origin: none; } }
        .home__discover .slideswiper .swiper-wrapper .swiper-slide img {
          width: 100%;
          object-fit: cover;
          height: 445px; }
        .home__discover .slideswiper .swiper-wrapper .swiper-slide a {
          display: block;
          position: relative; }
          .home__discover .slideswiper .swiper-wrapper .swiper-slide a::before {
            content: "";
            background-image: url("/img/common/arrow_next_black.svg");
            background-position: center;
            background-repeat: no-repeat;
            width: 20px;
            height: 10px;
            background-color: #fff;
            position: absolute;
            bottom: 0;
            right: -1px;
            opacity: 1;
            z-index: 2;
            padding: 20px; }
            @media (max-width: 767.98px) {
              .home__discover .slideswiper .swiper-wrapper .swiper-slide a::before {
                right: 0;
                bottom: -1px; } }
          .home__discover .slideswiper .swiper-wrapper .swiper-slide a::after {
            content: "";
            width: 100%;
            height: 100%;
            background: linear-gradient(rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0.4) 100%);
            display: block;
            position: absolute;
            bottom: 0; }
    .home__discover .slideswiper .slider-content {
      position: absolute;
      bottom: 0;
      padding: 20px;
      z-index: 1; }

.home__innovative {
  background: #fff; }
  @media (max-width: 767.98px) {
    .home__innovative {
      overflow-x: hidden; } }
  .home__innovative-inner {
    position: relative;
    z-index: 1;
    background: #fff;
    padding: 0 0px 40px 40px; }
    @media (max-width: 767.98px) {
      .home__innovative-inner {
        padding: 20px 0px 20px 20px; } }
  .home__innovative-box {
    display: flex;
    justify-content: space-between; }
    @media (max-width: 767.98px) {
      .home__innovative-box {
        display: block;
        width: 100%; } }
    @media (max-width: 767.98px) {
      .home__innovative-boxsp {
        display: flex; } }
  .home__innovative-link {
    transition: all 0.2s linear;
    color: currentColor;
    display: block;
    position: relative; }
    .home__innovative-link:hover {
      color: currentColor;
      text-decoration: none;
      opacity: 1; }
    .home__innovative-link figure {
      overflow: hidden; }
    .home__innovative-link img {
      transition: all 0.6s; }
    .home__innovative-link:hover a {
      opacity: 1; }
    .home__innovative-link:hover img {
      transform: scale(1.1); }
    .home__innovative-link::after {
      display: none; }
    .home__innovative-link .post-content {
      position: absolute;
      bottom: 0;
      padding: 20px 40px 20px 20px;
      z-index: 1; }
      .home__innovative-link .post-content .post-date-1 {
        color: #fff; }
      @media (max-width: 767.98px) {
        .home__innovative-link .post-content {
          position: static;
          padding: 10px 0px 0px 0px; }
          .home__innovative-link .post-content .post-date-1, .home__innovative-link .post-content .post-text-1, .home__innovative-link .post-content .post-text-3 {
            color: #333; }
          .home__innovative-link .post-content .post-text-1 {
            font-weight: bold;
            margin-top: 5px; } }
    .home__innovative-link figure {
      position: relative;
      display: block; }
      .home__innovative-link figure::before {
        content: "";
        background-image: url("/img/common/arrow_next_black.svg");
        background-position: center;
        background-repeat: no-repeat;
        width: 20px;
        height: 10px;
        background-color: #fff;
        position: absolute;
        bottom: 0;
        right: -1px;
        opacity: 1;
        z-index: 2;
        padding: 20px; }
      .home__innovative-link figure::after {
        content: "";
        width: 100%;
        height: 100%;
        background: linear-gradient(rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0.4) 100%);
        display: block;
        position: absolute;
        bottom: 0; }
        @media (max-width: 767.98px) {
          .home__innovative-link figure::after {
            content: none; } }
      .home__innovative-link figure img {
        aspect-ratio: 4/3;
        object-fit: cover;
        width: 100% !important; }
  .home__innovative-mainpost {
    width: 50%;
    padding-top: 60px; }
    @media (max-width: 767.98px) {
      .home__innovative-mainpost {
        width: 100%; } }
    .home__innovative-mainpost .home__btn-style1 {
      margin: 40px 0 0 auto; }
  .home__innovative-post {
    width: 50%;
    padding-top: 60px; }
    @media (max-width: 767.98px) {
      .home__innovative-post {
        width: 100%;
        padding-top: 30px; } }
    .home__innovative-post-title {
      font-weight: bold;
      border-bottom: 1px solid #333;
      padding-bottom: 10px;
      margin-bottom: 0; }
      @media (min-width: 768px) {
        .home__innovative-post-title {
          font-size: 1.8rem; } }
      @media (max-width: 767.98px) {
        .home__innovative-post-title {
          font-size: 1.6rem; } }
    .home__innovative-post-item {
      list-style: none;
      border-bottom: 1px solid #D3D3D3;
      padding: 14px 0; }
    .home__innovative-post-link {
      position: relative;
      padding-right: 15px;
      display: block; }
      .home__innovative-post-link:hover::after {
        right: -5px; }
      .home__innovative-post-link::after {
        font-family: fontawesome;
        content: "\f105";
        position: absolute;
        color: #333;
        transition: .3s;
        right: 0;
        top: 50%;
        transform: translateY(-50%); }
      .home__innovative-post-link .post-date-1 {
        color: #999; }
    .home__innovative-post .home__btn-style1 {
      margin: 40px 0 0 auto; }
  .home__innovative-title {
    margin: 0 40px 0 60px;
    width: 60px; }
    @media (max-width: 767.98px) {
      .home__innovative-title {
        flex: 0 0 40px;
        margin: 0px 20px 0 0; } }

.home__research-c {
  margin-top: 40px; }
  .home__research-c-title {
    margin-bottom: 30px;
    max-width: 420px; }
    @media (max-width: 767.98px) {
      .home__research-c-title {
        width: 80%;
        max-width: 350px; } }
  @media (max-width: 767.98px) {
    .home__research-c-list {
      width: 100vw;
      padding-bottom: 50px; } }
  .home__research-c-list .slick-arrow {
    background: #000;
    padding: 20px; }
    .home__research-c-list .slick-arrow::before {
      content: "" !important;
      position: absolute;
      top: 50%;
      left: 15px;
      margin: 0 auto;
      text-align: centers;
      transform: translateY(-50%);
      width: 10px;
      height: 19px;
      opacity: 1;
      margin-top: 0em; }
  .home__research-c-list .slick-prev {
    top: 48% !important;
    left: -20px !important; }
    @media (max-width: 767.98px) {
      .home__research-c-list .slick-prev {
        top: auto !important;
        left: 5px !important;
        bottom: 0;
        transform: none; } }
  .home__research-c-list .slick-next {
    top: 48% !important;
    right: -20px !important; }
    @media (max-width: 767.98px) {
      .home__research-c-list .slick-next {
        top: auto !important;
        right: auto !important;
        left: 55px;
        bottom: 0;
        transform: none; } }
  .home__research-c-list .slick-next:before {
    background-image: url("/img/common/arrow_next_white.svg") !important;
    background-repeat: no-repeat;
    background-size: 100%; }
  .home__research-c-list .slick-prev:before {
    background-image: url("/img/common/arrow_prev_white.svg") !important;
    background-repeat: no-repeat;
    background-size: 100%; }
  .home__research-c-item a {
    display: block;
    position: relative;
    margin: 0 2.5px; }
    .home__research-c-item a .post-content {
      position: absolute;
      bottom: 0;
      padding: 0 0 10px 10px;
      font-weight: bold;
      z-index: 1; }
      .home__research-c-item a .post-content .post-text-1 {
        line-height: 1.2; }
        @media (min-width: 768px) {
          .home__research-c-item a .post-content .post-text-1 {
            font-size: 1.4rem; } }
        @media (max-width: 767.98px) {
          .home__research-c-item a .post-content .post-text-1 {
            font-size: 1.4rem; } }
    .home__research-c-item a::after {
      content: "";
      width: 100%;
      height: 100%;
      background: linear-gradient(rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0.4) 100%);
      display: block;
      position: absolute;
      bottom: 0; }

@media (max-width: 767.98px) {
  .home__news {
    border-left: 5px solid #333;
    padding: 60px 15px; } }

.home__news-area {
  position: relative;
  z-index: 1;
  margin: 160px 0; }
  @media (max-width: 767.98px) {
    .home__news-area {
      margin: 0;
      padding: 0 !important; } }
  .home__news-area-inner {
    position: relative;
    padding: 60px 80px; }
    @media (max-width: 1079.98px) {
      .home__news-area-inner {
        padding: 30px 40px; } }
    @media (max-width: 767.98px) {
      .home__news-area-inner {
        padding: 0; } }
    .home__news-area-inner::after {
      content: "";
      display: block;
      width: 100%;
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      backdrop-filter: blur(10px);
      filter: brightness(1.4);
      background: rgba(255, 255, 255, 0.6);
      z-index: -1; }

.home__news .home__btn-style1 {
  margin: 40px 0 0 auto; }

.home__news-inner {
  display: flex;
  align-items: flex-start; }
  @media (max-width: 767.98px) {
    .home__news-inner {
      display: block; } }

.home__news-title {
  flex: 0 0 40px;
  margin-right: 60px;
  display: flex; }
  @media (max-width: 767.98px) {
    .home__news-title {
      width: 35%;
      max-width: 120px; } }

.home__news-list {
  list-style: none;
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr;
  gap: 20px;
  padding-top: 65px; }
  @media (max-width: 1079.98px) {
    .home__news-list {
      grid-template-columns: 1fr 1fr; } }
  @media (max-width: 767.98px) {
    .home__news-list {
      display: block;
      padding-top: 10px; } }

.home__news-item {
  transition: all 0.2s linear;
  color: currentColor; }
  .home__news-item:hover {
    color: currentColor;
    text-decoration: none;
    opacity: 1; }
  .home__news-item figure {
    overflow: hidden; }
  .home__news-item img {
    transition: all 0.6s; }
  .home__news-item:hover a {
    opacity: 1; }
  .home__news-item:hover img {
    transform: scale(1.1); }
  @media (max-width: 767.98px) {
    .home__news-item {
      margin-bottom: 20px; } }
  .home__news-item .post-text-box {
    display: block; }
  .home__news-item .post-text-2 {
    margin-top: 5px; }
    @media (max-width: 767.98px) {
      .home__news-item .post-text-2 {
        margin-top: 0; } }
  .home__news-item .post-label-2 {
    border-left: 1px solid #333;
    padding-left: 10px;
    margin-left: 5px; }
  .home__news-item figure img {
    width: 100%;
    aspect-ratio: 4/2.5;
    object-fit: cover; }
  .home__news-item.fixed-item {
    position: relative; }
    .home__news-item.fixed-item::before {
      content: "";
      width: 30px;
      height: 30px;
      background-image: url("/img/common/news_fixed_icon.png");
      position: absolute;
      top: 0;
      right: 0;
      z-index: 1; }

.home__press {
  padding-left: 100px;
  margin: 60px 0; }
  @media (max-width: 767.98px) {
    .home__press {
      margin: 40px 0 0;
      padding: 0 20px; } }
  .home__press-wrap {
    display: flex;
    align-items: flex-end; }
    @media (max-width: 767.98px) {
      .home__press-wrap {
        display: block; } }
    .home__press-wrap .home__btn-style1 {
      flex: 0 0 110px;
      margin-left: 30px; }
      @media (max-width: 767.98px) {
        .home__press-wrap .home__btn-style1 {
          margin-top: 20px;
          margin-left: auto; } }
  .home__press-inner {
    display: flex;
    align-items: flex-start;
    width: 100%; }
    @media (max-width: 767.98px) {
      .home__press-inner {
        display: block; } }
  .home__press-box {
    display: flex;
    align-items: center;
    white-space: nowrap;
    flex: 0 0 150px; }
    @media (max-width: 767.98px) {
      .home__press-box {
        margin-bottom: 10px; } }
    .home__press-box .post-press-title {
      color: #004A98;
      font-weight: bold;
      display: flex;
      align-items: center;
      margin-right: 16px; }
      @media (min-width: 768px) {
        .home__press-box .post-press-title {
          font-size: 1.8rem; } }
      @media (max-width: 767.98px) {
        .home__press-box .post-press-title {
          font-size: 1.8rem; } }
      .home__press-box .post-press-title::after {
        content: "";
        width: 1px;
        height: 20px;
        background: #333;
        display: block;
        margin-left: 16px; }
  .home__press-text {
    margin: 0 20px; }
    @media (max-width: 767.98px) {
      .home__press-text {
        margin: 0; } }
  .home__press-item {
    margin-top: 16px; }
    .home__press-item:first-child {
      margin-top: 0; }

.home__event {
  margin-top: 40px; }
  @media (max-width: 767.98px) {
    .home__event {
      border-left: 5px solid #004A98;
      margin-top: 0;
      padding: 0px 15px 60px; } }
  .home__event .home__btn-style1 {
    margin: 40px 0 0 auto; }
  .home__event-inner {
    display: flex;
    align-items: flex-start; }
    @media (max-width: 767.98px) {
      .home__event-inner {
        display: block; } }
  .home__event-title {
    flex: 0 0 40px;
    margin-right: 60px;
    display: flex; }
    @media (max-width: 767.98px) {
      .home__event-title {
        width: 35%;
        max-width: 120px; } }
  .home__event-post {
    flex: 1; }
  .home__event-list {
    list-style: none;
    padding-top: 65px;
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap; }
    @media (max-width: 767.98px) {
      .home__event-list {
        display: block;
        padding-top: 10px; } }
  .home__event-item {
    width: 45%; }
    @media (max-width: 767.98px) {
      .home__event-item {
        width: 100%;
        margin-bottom: 20px; } }
    .home__event-item:nth-child(n+3) {
      margin-top: 40px; }
      @media (max-width: 767.98px) {
        .home__event-item:nth-child(n+3) {
          margin-top: auto; } }
  .home__event-link {
    display: inline-flex;
    width: 100%; }
    .home__event-link .post-label-1 {
      display: inline-block; }
    .home__event-link .post-event-content {
      margin: 5px 0; }
    .home__event-link .post-event-date {
      border-right: 1px solid #004A98;
      padding-right: 21px;
      margin-right: 30px;
      flex: 0 0 80px; }
      @media (max-width: 1079.98px) {
        .home__event-link .post-event-date {
          padding-right: 15px;
          margin-right: 15px; } }
    .home__event-link span {
      display: block; }

.home__data-inner {
  position: relative;
  z-index: 1;
  background: #fff;
  padding: 120px 0 60px; }
  @media (max-width: 1079.98px) {
    .home__data-inner.inner-flexible {
      padding: 60px 40px 60px; } }
  @media (max-width: 767.98px) {
    .home__data-inner.inner-flexible {
      padding: 80px 0px 80px; } }

.home__data-title {
  text-align: center;
  margin-bottom: 60px; }
  .home__data-title img {
    width: 250px; }
    @media (max-width: 767.98px) {
      .home__data-title img {
        width: 140px; } }

.home__data-list {
  list-style: none;
  padding: 0 60px;
  margin-top: 60px;
  max-width: 1400px;
  margin: 0 auto; }
  @media (max-width: 767.98px) {
    .home__data-list {
      padding: 0px; } }
  @media (max-width: 767.98px) {
    .home__data-list .slick-arrow {
      background: #000;
      padding: 20px;
      margin-top: 30px; } }
  .home__data-list .slick-arrow::before {
    content: "" !important;
    position: absolute;
    top: 0;
    width: 25px;
    height: 46px;
    opacity: 1;
    margin-top: 0em;
    background-size: 100%; }
    @media (max-width: 767.98px) {
      .home__data-list .slick-arrow::before {
        transform: translateY(-50%);
        width: 10px;
        height: 19px;
        margin: 0 auto;
        top: 50%;
        transform: translateY(-50%);
        left: 15px; } }
  .home__data-list .slick-prev {
    left: 20px !important; }
  .home__data-list .slick-next {
    right: 20px !important; }
  .home__data-list .slick-next:before {
    background-image: url("/img/common/arrow_next_black2.svg") !important;
    background-repeat: no-repeat; }
    @media (max-width: 767.98px) {
      .home__data-list .slick-next:before {
        background-image: url("/img/common/arrow_next_white.svg") !important; } }
  .home__data-list .slick-prev:before {
    background-image: url("/img/common/arrow_prev_black2.svg") !important;
    background-repeat: no-repeat; }
    @media (max-width: 767.98px) {
      .home__data-list .slick-prev:before {
        background-image: url("/img/common/arrow_prev_white.svg") !important; } }

.home__data-item {
  margin: 0 10px !important; }

.home__special {
  background: #fff;
  padding: 120px 0 180px; }
  @media (max-width: 767.98px) {
    .home__special {
      padding: 0 0 100px; } }
  .home__special-inner {
    position: relative;
    z-index: 1;
    padding-top: 60px;
    padding-bottom: 60px;
    max-width: 1850px;
    margin: -510px auto 0 auto; }
    @media screen and (max-width: 1355px) {
      .home__special-inner {
        padding: 0 30px;
        margin: -450px auto 0 auto; } }
    @media (max-width: 767.98px) {
      .home__special-inner {
        margin: -370px auto 0 auto; } }
  .home__special-bg img {
    object-fit: cover;
    width: 100%;
    height: 545px; }
    @media (max-width: 767.98px) {
      .home__special-bg img {
        height: 400px; } }
  .home__special-block {
    display: flex; }
    @media (max-width: 767.98px) {
      .home__special-block {
        display: grid;
        grid-template-rows: 370px 1fr 1fr; } }
  .home__special-title {
    margin: 0 60px;
    flex: 0 0 50px; }
    @media screen and (max-width: 1355px) {
      .home__special-title {
        margin: 0 30px; } }
    @media (max-width: 767.98px) {
      .home__special-title {
        order: 1;
        display: flex;
        flex-direction: row-reverse;
        justify-content: space-between;
        margin: 0; } }
    .home__special-title img {
      width: 100% !important; }
    @media (max-width: 767.98px) {
      .home__special-title .title {
        width: 20% !important;
        max-width: 80px; } }
    @media (max-width: 767.98px) {
      .home__special-title .text {
        width: 33% !important;
        max-width: 110px; } }
  .home__special-box {
    max-width: 655px;
    margin: 165px auto 0 auto;
    width: 45%; }
    @media (max-width: 767.98px) {
      .home__special-box {
        order: 2;
        margin: 0;
        width: 100%; } }
    .home__special-box .home__btn-style1 {
      margin: 40px auto 0; }
    @media (max-width: 767.98px) {
      .home__special-box.box1 {
        order: 2;
        margin-top: -100px; } }
    @media (max-width: 767.98px) {
      .home__special-box.box2 {
        order: 3; } }

.home__test {
  background-color: #fff;
  padding: 60px 0; }
  .home__test .inner-wide {
    padding: 0 40px; }
    .home__test .inner-wide ul:not([class]) > li {
      margin-bottom: 0; }
    .home__test .inner-wide h2 {
      font-family: "Noto Serif JP", "游明朝", "Hiragino Mincho ProN", "ヒラギノ明朝 W3 JIS2004", "Times New Roman", Times, serif;
      font-weight: 400;
      margin-top: 40px;
      margin-bottom: 50px;
      border: none; }
      .home__test .inner-wide h2::after {
        content: none; }
    .home__test .inner-wide ul {
      display: flex;
      flex-wrap: wrap; }
      .home__test .inner-wide ul li {
        list-style: none;
        margin: 0 30px 0 0;
        width: calc(50% - 20px); }
        .home__test .inner-wide ul li:nth-child(2n) {
          margin-right: 0; }
        .home__test .inner-wide ul li a {
          border-bottom: 1px solid #333;
          padding-bottom: 14px;
          margin-bottom: 14px;
          display: block;
          position: relative;
          padding-right: 20px; }
          .home__test .inner-wide ul li a::after {
            font-family: 'FontAwesome';
            content: '\f105';
            position: absolute;
            display: inline-block;
            right: 10px; }
  @media (min-width: 768px) {
    .home__test .resize1 a {
      font-size: 1.7rem; } }
  @media (max-width: 767.98px) {
    .home__test .resize1 a {
      font-size: 1.7rem; } }
  @media (min-width: 768px) {
    .home__test .resize2 a {
      font-size: 1.8rem; } }
  @media (max-width: 767.98px) {
    .home__test .resize2 a {
      font-size: 1.8rem; } }
  .home__test_box {
    display: flex;
    border-left: 3px solid #004A98; }
  .home__test_ttl {
    flex: 0 0 240px;
    font-weight: bold;
    padding-left: 15px; }
    @media (min-width: 768px) {
      .home__test_ttl {
        font-size: 2rem; } }
    @media (max-width: 767.98px) {
      .home__test_ttl {
        font-size: 2rem; } }

.home.en .home__mv-copy-box {
  left: 50px;
  top: calc(100vh - 390px); }
  @media (max-width: 767.98px) {
    .home.en .home__mv-copy-box {
      padding: 200px 20px 0; } }

.home.en .home__mv-copy-title {
  padding: 0;
  width: 65vw;
  max-width: 750px; }
  @media (max-width: 767.98px) {
    .home.en .home__mv-copy-title {
      width: 125px; } }

.home.en .home__about-content {
  display: block; }
  @media (max-width: 767.98px) {
    .home.en .home__about-content {
      margin-top: 0;
      padding-bottom: 70px; } }

.home.en .home__about-title {
  font-family: "Crimson Text", Times, serif;
  font-weight: 400;
  padding-left: 15px;
  line-height: 1;
  margin-bottom: 30px; }
  @media (min-width: 768px) {
    .home.en .home__about-title {
      font-size: 7rem; } }
  @media (max-width: 767.98px) {
    .home.en .home__about-title {
      font-size: 4.8rem; } }
  @media (max-width: 767.98px) {
    .home.en .home__about-title {
      padding-left: 20px;
      transform: none;
      position: static;
      margin-top: 60px; } }

.home.en .home__about-inner {
  padding-bottom: 100px;
  padding-top: 15px;
  margin-top: 530px; }
  @media (max-width: 767.98px) {
    .home.en .home__about-inner {
      margin-top: 0;
      padding-bottom: 0;
      padding-top: 0; } }

@media (max-width: 767.98px) {
  .home.en .home__section {
    padding-bottom: 100px; } }

.home.en .home__section::before {
  width: 300px; }
  @media (max-width: 767.98px) {
    .home.en .home__section::before {
      width: 120px; } }

.home.en .home__discover-titlebox {
  margin-left: 130px;
  padding-right: 0px;
  align-items: center;
  display: grid;
  grid-template-columns: 500px 1fr; }
  @media (max-width: 1079.98px) {
    .home.en .home__discover-titlebox {
      margin-left: 130px; } }
  @media (max-width: 767.98px) {
    .home.en .home__discover-titlebox {
      display: block;
      margin-left: 0px; } }

.home.en .home__discover-title {
  flex: 0 0 500px; }
  @media (max-width: 1079.98px) {
    .home.en .home__discover-title {
      max-width: 500px; } }
  @media (max-width: 767.98px) {
    .home.en .home__discover-title {
      max-width: 260px;
      padding-left: 20px; } }

.home.en .home__btn_en-list {
  display: flex;
  justify-content: space-between;
  list-style-type: none;
  padding: 0 60px; }
  @media (max-width: 767.98px) {
    .home.en .home__btn_en-list {
      display: block;
      padding: 0 40px; } }

.home.en .home__btn_en-item {
  position: relative;
  width: 30%;
  border-radius: 30px;
  margin-right: 40px; }
  @media (max-width: 767.98px) {
    .home.en .home__btn_en-item {
      width: 100%;
      margin-bottom: 20px; } }
  .home.en .home__btn_en-item:last-child {
    margin-right: 0px; }
    @media (max-width: 767.98px) {
      .home.en .home__btn_en-item:last-child {
        margin-bottom: 0px; } }
  .home.en .home__btn_en-item a figure {
    position: relative; }
    .home.en .home__btn_en-item a figure .textbox {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      z-index: 1;
      color: #fff; }
      .home.en .home__btn_en-item a figure .textbox .text {
        font-family: "Crimson Text", Times, serif;
        font-weight: 400;
        white-space: nowrap; }
        @media (min-width: 768px) {
          .home.en .home__btn_en-item a figure .textbox .text {
            font-size: 4rem; } }
        @media (max-width: 767.98px) {
          .home.en .home__btn_en-item a figure .textbox .text {
            font-size: 4rem; } }
      .home.en .home__btn_en-item a figure .textbox .home__btn-style1::before, .home.en .home__btn_en-item a figure .textbox .home__btn-style1::after {
        background-color: #fff; }
      .home.en .home__btn_en-item a figure .textbox .home__btn-style1 {
        margin: 0 auto; }
        .home.en .home__btn_en-item a figure .textbox .home__btn-style1 span {
          padding: 5px 0;
          display: flex;
          justify-content: space-between;
          align-items: center;
          font-family: "Crimson Text", Times, serif;
          font-weight: 400;
          font-weight: 400; }
          .home.en .home__btn_en-item a figure .textbox .home__btn-style1 span::after {
            content: "";
            background-image: url(/img/common/arrow_next_white2.svg);
            background-repeat: no-repeat;
            width: 20px;
            height: 8px;
            margin-left: 10px; }

.home.en .home__news-area {
  margin-bottom: 0; }

.home.en .en-sns {
  position: relative;
  background-image: url("/img/home/en_sns-bg.svg");
  background-size: contain;
  background-repeat: no-repeat;
  width: 710px;
  height: 370px;
  margin: 80px auto 0; }
  @media (max-width: 767.98px) {
    .home.en .en-sns {
      left: 50%;
      transform: translateX(-50%);
      background-position: center; } }
  .home.en .en-sns_inner {
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
    bottom: 30px; }
  .home.en .en-sns_title {
    font-family: "Crimson Text", Times, serif;
    font-weight: 400;
    color: #004A98;
    display: flex;
    flex-direction: column-reverse;
    justify-content: center;
    align-items: center;
    line-height: 1;
    margin-bottom: 30px; }
    @media (min-width: 768px) {
      .home.en .en-sns_title {
        font-size: 5rem; } }
    @media (max-width: 767.98px) {
      .home.en .en-sns_title {
        font-size: 4rem; } }
    .home.en .en-sns_title::before {
      content: "";
      width: 1px;
      height: 40px;
      background: #004A98;
      margin-top: 10px; }
  .home.en .en-sns .home__btn-style1 {
    margin: 20px auto 0; }

.page.mv-1 .block {
  background: #fff; }

.page.mv-1 .breadcrumb-item {
  text-shadow: 0px 2px 3px rgba(0, 0, 0, 0.8);
  color: #fff; }
  .page.mv-1 .breadcrumb-item.active {
    color: #fff; }
  .page.mv-1 .breadcrumb-item a {
    color: #fff; }
  .page.mv-1 .breadcrumb-item + .breadcrumb-item::before {
    content: "ー";
    color: #fff; }

.page.mv-1 .page__mv {
  position: relative;
  padding-bottom: 330px; }
  @media (max-width: 767.98px) {
    .page.mv-1 .page__mv {
      padding-bottom: 60px; } }
  .page.mv-1 .page__mv-video {
    position: fixed;
    z-index: -2;
    width: 100%;
    height: 100vh;
    object-fit: cover; }
    .page.mv-1 .page__mv-video img {
      width: 100%;
      height: 100vh;
      object-fit: cover; }
  .page.mv-1 .page__mv-copy {
    color: #fff;
    height: 400px;
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
    text-shadow: 0px 3px 6px rgba(0, 0, 0, 0.8); }
    @media (max-width: 767.98px) {
      .page.mv-1 .page__mv-copy {
        height: 350px; } }
    .page.mv-1 .page__mv-copy::before {
      content: "";
      background: #fff;
      width: 1px;
      height: 100%;
      margin-bottom: 30px; }
      @media (max-width: 767.98px) {
        .page.mv-1 .page__mv-copy::before {
          content: none; } }
    .page.mv-1 .page__mv-copy-title {
      font-family: "Crimson Text", Times, serif;
      font-weight: 400;
      text-align: center; }
      @media (min-width: 768px) {
        .page.mv-1 .page__mv-copy-title {
          font-size: 9rem; } }
      @media (max-width: 767.98px) {
        .page.mv-1 .page__mv-copy-title {
          font-size: 5rem; } }
      .page.mv-1 .page__mv-copy-title span {
        font-family: nomal;
        font-family: "Noto Serif JP", "游明朝", "Hiragino Mincho ProN", "ヒラギノ明朝 W3 JIS2004", "Times New Roman", Times, serif;
        font-weight: 400;
        display: block; }
        @media (min-width: 768px) {
          .page.mv-1 .page__mv-copy-title span {
            font-size: 3rem; } }
        @media (max-width: 767.98px) {
          .page.mv-1 .page__mv-copy-title span {
            font-size: 1.8rem; } }

.page.mv-1 .page__lead {
  position: relative;
  padding-top: 150px;
  padding-bottom: 150px; }
  @media (max-width: 767.98px) {
    .page.mv-1 .page__lead {
      padding-top: 60px;
      padding-bottom: 60px; } }
  .page.mv-1 .page__lead-box {
    display: flex;
    padding-left: 360px;
    text-shadow: 0px 2px 3px rgba(0, 0, 0, 0.8); }
    @media (max-width: 767.98px) {
      .page.mv-1 .page__lead-box {
        display: block;
        padding-left: 0px; } }
  .page.mv-1 .page__lead-bgtext {
    color: #EFEFEF;
    opacity: .2;
    font-family: "Crimson Text", Times, serif;
    font-weight: 400;
    line-height: 1.25;
    position: absolute;
    text-indent: -6rem;
    padding-left: 6rem; }
    @media (min-width: 768px) {
      .page.mv-1 .page__lead-bgtext {
        font-size: 9rem; } }
    @media (max-width: 767.98px) {
      .page.mv-1 .page__lead-bgtext {
        font-size: 4rem; } }
    @media (max-width: 767.98px) {
      .page.mv-1 .page__lead-bgtext {
        writing-mode: horizontal-tb;
        right: 0;
        text-indent: -1rem;
        padding-left: 1rem;
        padding-top: 14px;
        line-height: 1; } }
  .page.mv-1 .page__lead-title {
    color: #fff;
    -ms-writing-mode: tb-rl;
    writing-mode: vertical-rl;
    font-family: "Noto Serif JP", "游明朝", "Hiragino Mincho ProN", "ヒラギノ明朝 W3 JIS2004", "Times New Roman", Times, serif;
    font-weight: 400;
    font-weight: 500; }
    @media (max-width: 767.98px) {
      .page.mv-1 .page__lead-title {
        writing-mode: horizontal-tb;
        padding-bottom: 40px; } }
    .page.mv-1 .page__lead-title .nakaguro.academics {
      height: 30px; }
      .page.mv-1 .page__lead-title .nakaguro.academics::before {
        width: 5px;
        height: 5px; }
    @media (max-width: 767.98px) {
      .page.mv-1 .page__lead-title .nakaguro {
        display: none; } }
  .page.mv-1 .page__lead-text {
    font-family: "Noto Serif JP", "游明朝", "Hiragino Mincho ProN", "ヒラギノ明朝 W3 JIS2004", "Times New Roman", Times, serif;
    font-weight: 400;
    color: #fff;
    font-weight: 500;
    margin-left: 30px;
    line-height: 2.2; }
    @media (max-width: 767.98px) {
      .page.mv-1 .page__lead-text {
        margin-left: 0px; } }

.page__news-block {
  display: flex;
  gap: 20px;
  align-items: flex-start;
  height: 100%; }
  @media (max-width: 767.98px) {
    .page__news-block {
      display: block; } }

.page__news-flex {
  padding: 40px 10px 40px 40px;
  display: flex; }
  @media (max-width: 767.98px) {
    .page__news-flex {
      display: block;
      padding: 40px 10px 40px 20px; } }
  .page__news-flex.event {
    flex-direction: row-reverse;
    justify-content: space-between;
    padding: 40px 40px 40px 40px; }
    @media (max-width: 767.98px) {
      .page__news-flex.event {
        display: block;
        padding: 40px 10px 40px 20px; } }

.page__news-box {
  background: #EFEFEF;
  width: 100%; }
  @media (max-width: 767.98px) {
    .page__news-box {
      margin-bottom: 30px;
      padding-bottom: 30px; } }
  .page__news-box.box-left .home__btn-style1 {
    margin: 0 40px 30px auto; }
    .page__news-box.box-left .home__btn-style1 a {
      text-decoration: none; }
  .page__news-box.box-right .home__btn-style1 {
    margin: 0 150px 30px auto; }
    @media (max-width: 767.98px) {
      .page__news-box.box-right .home__btn-style1 {
        margin: 0 40px 30px auto; } }
    .page__news-box.box-right .home__btn-style1 a {
      text-decoration: none; }

.page__news-title {
  flex: 0 0 40px;
  margin-right: 40px; }
  @media (max-width: 767.98px) {
    .page__news-title {
      margin-right: 0px;
      margin-bottom: 30px; } }

.page__news-list {
  height: 620px;
  overflow: auto;
  padding-right: 30px;
  margin-top: 70px;
  width: 100%; }
  @media (max-width: 767.98px) {
    .page__news-list {
      height: 350px;
      padding-right: 20px;
      margin-top: 0px; } }
  .page__news-list .home__btn-style1 {
    margin: 0 0 0 auto; }
    .page__news-list .home__btn-style1 a {
      text-decoration: none; }

.page__news-item {
  list-style: none;
  margin-bottom: 20px; }
  .page__news-item:last-child {
    margin-bottom: 0; }
  .page__news-item.fixed-item .page__news-textbox::before {
    content: "";
    width: 30px;
    height: 30px;
    background-image: url("/img/common/news_fixed_icon.png");
    margin-right: 10px;
    z-index: 1; }

.page__news-link {
  text-decoration: none;
  border-bottom: 1px solid #999;
  padding-bottom: 10px; }

.page__news-textbox {
  display: flex;
  line-height: 1;
  flex-wrap: wrap;
  align-items: center;
  margin-bottom: 5px; }

.page__news-cate {
  border-left: 1px solid #333;
  padding-left: 10px;
  margin-left: 10px;
  margin-bottom: 8px; }
  @media (min-width: 768px) {
    .page__news-cate {
      font-size: 1.4rem; } }
  @media (max-width: 767.98px) {
    .page__news-cate {
      font-size: 1.4rem; } }

.page__news-date {
  font-family: "Barlow", Times, serif;
  font-weight: 400;
  margin-bottom: 8px; }
  @media (min-width: 768px) {
    .page__news-date {
      font-size: 1.4rem; } }
  @media (max-width: 767.98px) {
    .page__news-date {
      font-size: 1.4rem; } }

.page__news.news_only .page__news-list {
  height: 300px; }

.page__news.news_only .page__news-list {
  margin-top: 0; }

.page__event-link {
  display: flex; }
  .page__event-link .post-event-date {
    flex: 0 0 75px; }

.page__event-item {
  list-style: none;
  margin-bottom: 30px; }
  .page__event-item:last-child {
    margin-bottom: 0; }

.page__event-title {
  flex: 0 0 40px;
  margin-left: 30px; }
  @media (max-width: 767.98px) {
    .page__event-title {
      margin-left: 0px;
      margin-bottom: 30px; } }

.page__event-list {
  height: 620px;
  overflow: auto;
  margin-top: 70px;
  width: 100%; }
  @media (max-width: 767.98px) {
    .page__event-list {
      height: 400px;
      padding-right: 20px;
      margin-top: 0px; } }
  .page__event-list .home__btn-style1 {
    margin: 0 0 0 auto; }
    .page__event-list .home__btn-style1 a {
      text-decoration: none; }

.page__event-link {
  text-decoration: none; }
  .page__event-link .post-event-date {
    text-align: center;
    border-right: 2px solid #004A98;
    padding-right: 20px;
    margin-right: 20px; }
    .page__event-link .post-event-date span {
      display: block; }
  .page__event-link .post-event-content {
    display: block; }
  .page__event-link .post-event-hostday {
    margin-bottom: 0px; }
  .page__event-link .post-event-place {
    margin-bottom: 0px; }

.page__bnrstyle1 {
  border: 1px solid #D3D3D3;
  width: 100%; }
  .page__bnrstyle1 img {
    object-fit: cover;
    aspect-ratio: 3/1;
    width: 100%; }
  .page__bnrstyle1 .arrow-r::after {
    right: 30px; }
    @media (max-width: 767.98px) {
      .page__bnrstyle1 .arrow-r::after {
        right: 10px; } }
  .page__bnrstyle1-text {
    font-weight: bold;
    flex: 0 0 50%;
    padding: 0 40px; }
    @media (min-width: 768px) {
      .page__bnrstyle1-text {
        font-size: 2.2rem; } }
    @media (max-width: 767.98px) {
      .page__bnrstyle1-text {
        font-size: 1.6rem; } }
    @media (max-width: 767.98px) {
      .page__bnrstyle1-text {
        padding: 20px 30px 20px 20px; } }
  .page__bnrstyle1-link {
    display: flex;
    align-items: center;
    text-decoration: none; }
    @media (max-width: 767.98px) {
      .page__bnrstyle1-link {
        display: block; } }
    .page__bnrstyle1-link figure {
      flex: 0 0 50%;
      margin-bottom: 0; }
    .page__bnrstyle1-link::after {
      content: none !important; }

.page .recommend__box {
  background: #EFEFEF;
  padding: 60px 0;
  border-left: 10px solid #004A98; }

.page .recommend__ttl {
  font-family: "Noto Serif JP", "游明朝", "Hiragino Mincho ProN", "ヒラギノ明朝 W3 JIS2004", "Times New Roman", Times, serif;
  font-weight: 400; }
  @media (min-width: 768px) {
    .page .recommend__ttl {
      font-size: 6rem; } }
  @media (max-width: 767.98px) {
    .page .recommend__ttl {
      font-size: 3rem; } }

.page .recommend__list {
  margin-left: 1em;
  display: flex;
  flex-wrap: wrap; }
  @media (max-width: 767.98px) {
    .page .recommend__list {
      margin-left: 0; } }

.page .recommend__item {
  flex: 0 0 49%;
  list-style: none; }

.page .recommend__link {
  text-decoration: none;
  display: inline-flex;
  align-items: flex-start; }
  .page .recommend__link::before {
    content: "";
    background-color: #004A98;
    width: 10px;
    flex: 0 0 10px;
    height: 1px;
    margin-right: 15px;
    margin-top: 11px; }

.page .recommend__slide {
  position: relative; }
  .page .recommend__slide li {
    list-style: none; }
  .page .recommend__slide .slide-arrow {
    position: absolute;
    right: 0;
    bottom: 0px;
    cursor: pointer; }
    .page .recommend__slide .slide-arrow.prev-arrow {
      right: 45px; }
  .page .recommend__slide_text {
    font-weight: bold;
    margin-top: 5px; }
    @media (min-width: 768px) {
      .page .recommend__slide_text {
        font-size: 2rem; } }
    @media (max-width: 767.98px) {
      .page .recommend__slide_text {
        font-size: 1.6rem; } }
  .page .recommend__slide_cap .slick-arrow::before {
    content: none; }

.page .special_bnr {
  position: relative; }
  .page .special_bnr .title {
    position: absolute;
    top: 50px;
    left: 40px;
    font-weight: bold;
    color: #fff;
    font-family: "Crimson Text", Times, serif;
    font-weight: 400;
    line-height: 1.2; }
    @media (min-width: 768px) {
      .page .special_bnr .title {
        font-size: 4rem; } }
    @media (max-width: 767.98px) {
      .page .special_bnr .title {
        font-size: 2.8rem; } }
    @media screen and (min-width: 768px) and (max-width: 1100px) {
      .page .special_bnr .title {
        top: 20px; } }
    @media (max-width: 767.98px) {
      .page .special_bnr .title {
        top: 30px;
        left: 20px; } }
    @media (min-width: 768px) {
      .page .special_bnr .title.ja {
        font-size: 3.4rem; } }
    @media (max-width: 767.98px) {
      .page .special_bnr .title.ja {
        font-size: 2.3rem; } }
  .page .special_bnr .text {
    position: absolute;
    bottom: 40px;
    color: #fff;
    padding: 40px; }
    @media (min-width: 768px) {
      .page .special_bnr .text {
        font-size: 1.8rem; } }
    @media (max-width: 767.98px) {
      .page .special_bnr .text {
        font-size: 1.2rem; } }
    @media screen and (min-width: 768px) and (max-width: 1100px) {
      .page .special_bnr .text {
        padding: 0 40px;
        bottom: 20px; } }
    @media screen and (min-width: 768px) and (max-width: 1100px) {
      .page .special_bnr .text {
        font-size: min(1.4rem, 1.5vw); } }
    @media (max-width: 767.98px) {
      .page .special_bnr .text {
        padding: 20px;
        bottom: 10px;
        line-height: 1.4; } }

.page .home__innovative-link figure::after {
  content: none; }

.page.en .page__news-list {
  margin-top: 70px;
  height: 235px; }

.contact .box_style1 {
  padding: 30px !important; }

.page.about .page__lead-title img {
  min-width: 50px; }

.page.about.mv-1 .about__sitebnr-item {
  width: 100%;
  display: block;
  background-size: cover; }
  .page.about.mv-1 .about__sitebnr-item.site1 {
    background-image: url("/about/img/about_site1bnr.jpg"); }
    @media (max-width: 767.98px) {
      .page.about.mv-1 .about__sitebnr-item.site1 {
        background-image: url("/about/img/about_site1bnr-sp.jpg"); } }
    .page.about.mv-1 .about__sitebnr-item.site1 .about__sitebnr-box {
      border-right: 1px solid #fff;
      text-align: right;
      padding-right: 20px; }
    .page.about.mv-1 .about__sitebnr-item.site1 .about__sitebnr-link {
      padding-right: 100px; }
      @media (max-width: 767.98px) {
        .page.about.mv-1 .about__sitebnr-item.site1 .about__sitebnr-link {
          padding-right: 20px;
          padding-left: 20px; } }
  .page.about.mv-1 .about__sitebnr-item.site2 {
    background-image: url("/about/img/about_site2bnr.jpg"); }
    @media (max-width: 767.98px) {
      .page.about.mv-1 .about__sitebnr-item.site2 {
        background-image: url("/about/img/about_site2bnr-sp.jpg"); } }
    .page.about.mv-1 .about__sitebnr-item.site2 .about__sitebnr-box {
      border-left: 1px solid #fff;
      text-align: left;
      padding-left: 20px; }
    .page.about.mv-1 .about__sitebnr-item.site2 .about__sitebnr-link {
      padding-left: 100px; }
      @media (max-width: 767.98px) {
        .page.about.mv-1 .about__sitebnr-item.site2 .about__sitebnr-link {
          padding-right: 20px;
          padding-left: 20px; } }

.page.about.mv-1 .about__sitebnr-link {
  text-decoration: none;
  display: block;
  height: 100%;
  padding-bottom: 30px; }
  @media (max-width: 767.98px) {
    .page.about.mv-1 .about__sitebnr-link {
      padding-bottom: 120px; } }

.page.about.mv-1 .about__sitebnr-box {
  padding-top: 205px;
  width: 100%;
  margin: 0 auto; }
  @media (max-width: 767.98px) {
    .page.about.mv-1 .about__sitebnr-box {
      padding-top: 40px; } }

.page.about.mv-1 .about__sitebnr-title {
  color: #fff;
  font-family: "Crimson Text", Times, serif;
  font-weight: 400; }
  @media (min-width: 768px) {
    .page.about.mv-1 .about__sitebnr-title {
      font-size: 6rem; } }
  @media (max-width: 767.98px) {
    .page.about.mv-1 .about__sitebnr-title {
      font-size: 3.6rem; } }

.page.about.mv-1 .about__sitebnr-text {
  color: #fff; }

.page.about.mv-1 .about__sitebnr-btnlist {
  background-image: url("/about/img/about_btnbg.jpg");
  padding: 95px 0;
  width: 100%;
  background-size: cover; }
  @media (max-width: 767.98px) {
    .page.about.mv-1 .about__sitebnr-btnlist {
      padding: 70px 0;
      background-image: url("/about/img/about_btnbg-sp.jpg"); } }

.page.about.mv-1 .about__sitebnr-btnbox {
  display: flex; }
  @media (max-width: 767.98px) {
    .page.about.mv-1 .about__sitebnr-btnbox {
      display: block; } }
  .page.about.mv-1 .about__sitebnr-btnbox .page-btn_style1 {
    margin-right: 60px;
    width: calc(50% - 30px); }
    @media (max-width: 767.98px) {
      .page.about.mv-1 .about__sitebnr-btnbox .page-btn_style1 {
        width: 100%;
        margin-bottom: 30px; } }
    .page.about.mv-1 .about__sitebnr-btnbox .page-btn_style1:nth-child(even) {
      margin-right: 0; }
      @media (max-width: 767.98px) {
        .page.about.mv-1 .about__sitebnr-btnbox .page-btn_style1:nth-child(even) {
          margin-bottom: 0; } }
    .page.about.mv-1 .about__sitebnr-btnbox .page-btn_style1 a {
      color: #fff;
      font-weight: bold; }
    .page.about.mv-1 .about__sitebnr-btnbox .page-btn_style1::before, .page.about.mv-1 .about__sitebnr-btnbox .page-btn_style1::after {
      content: "";
      background-color: #fff; }

.page.about.mv-1 .about__info_box-head {
  display: flex;
  flex-wrap: wrap; }
  @media (max-width: 767.98px) {
    .page.about.mv-1 .about__info_box-head {
      display: block; } }
  .page.about.mv-1 .about__info_box-head .btn_style {
    width: calc(50% - 22.5px);
    margin-right: 45px; }
    @media (max-width: 767.98px) {
      .page.about.mv-1 .about__info_box-head .btn_style {
        width: 100%;
        margin-right: 0px; } }
    .page.about.mv-1 .about__info_box-head .btn_style:nth-child(even) {
      margin-right: 0; }

.page.about.mv-1 .about__info_box-bottom {
  display: flex;
  margin-top: 40px; }
  @media (max-width: 767.98px) {
    .page.about.mv-1 .about__info_box-bottom {
      display: block; } }
  .page.about.mv-1 .about__info_box-bottom .page-imglink {
    width: calc(50% - 22.5px);
    margin-right: 45px; }
    @media (max-width: 767.98px) {
      .page.about.mv-1 .about__info_box-bottom .page-imglink {
        width: 100%;
        margin-right: 0px; } }
    .page.about.mv-1 .about__info_box-bottom .page-imglink:nth-child(even) {
      margin-right: 0; }

.page.about.mv-1 .about__linklist_box {
  display: flex;
  border-left: 3px solid #004A98; }
  @media (max-width: 767.98px) {
    .page.about.mv-1 .about__linklist_box {
      display: block;
      border-left: none; } }

.page.about.mv-1 .about__linklist_title {
  flex: 0 0 240px;
  font-weight: bold;
  padding-left: 15px;
  color: #004A98; }
  @media (min-width: 768px) {
    .page.about.mv-1 .about__linklist_title {
      font-size: 2rem; } }
  @media (max-width: 767.98px) {
    .page.about.mv-1 .about__linklist_title {
      font-size: 2rem; } }
  @media (max-width: 767.98px) {
    .page.about.mv-1 .about__linklist_title {
      border-bottom: 3px solid #004A98;
      margin-bottom: 30px; } }

.page.about.mv-1 .about__linklist_list {
  display: flex;
  flex-wrap: wrap;
  width: 100%; }
  @media (max-width: 767.98px) {
    .page.about.mv-1 .about__linklist_list {
      display: block;
      border-left: none; } }
  .page.about.mv-1 .about__linklist_list .btn_style {
    width: calc(50% - 22.5px);
    margin-right: 45px !important;
    margin: 0;
    padding: 0;
    list-style: none; }
    @media (max-width: 767.98px) {
      .page.about.mv-1 .about__linklist_list .btn_style {
        width: 100%;
        margin-right: 0; } }
    .page.about.mv-1 .about__linklist_list .btn_style:nth-child(even) {
      margin-right: 0 !important; }

.search #___gcse_0 * {
  box-sizing: content-box; }

.search .gsc-input-box {
  height: auto; }

.search .gsc-result .gs-title {
  overflow: visible; }

.search .gsc-table-result,
.search .gsc-thumbnail-inside,
.search .gsc-url-top {
  padding-left: 0; }

.search .gsc-cursor-box.gs-bidi-start-align {
  text-align: center; }
  .search .gsc-cursor-box.gs-bidi-start-align .gsc-cursor-page {
    padding: 5px 10px;
    display: inline-block;
    background-color: #eee;
    border: 1px #ccc solid;
    color: #333; }
  .search .gsc-cursor-box.gs-bidi-start-align .gsc-cursor-current-page {
    background-color: #004A98;
    color: #fff; }

.search .gsc-control-wrapper-cse form {
  background-color: #eee;
  padding: 12px 20px;
  box-sizing: border-box !important; }

.guideline .item__color {
  display: flex; }
  .guideline .item__color .color {
    display: flex;
    align-items: center;
    flex: 1 0 50%;
    justify-content: center;
    min-height: 2em;
    color: #fff; }
    .guideline .item__color .color.txt__left {
      justify-content: flex-start; }

.guideline .clr_sub_1_l {
  background-color: #d9b9e1; }

.guideline .clr_sub_2_l {
  background-color: #efc1c4; }

.guideline .clr_sub_3_l {
  background-color: #fefdf8; }

.guideline .clr_sub_4_l {
  background-color: #ffd2d2; }

.guideline .grid_item {
  text-align: center;
  border: 1px #ccc solid;
  background-color: #eee; }

.guideline .label_id {
  display: inline-block !important;
  padding: 2px 10px !important;
  color: #fff !important;
  background-color: #333 !important; }
  .guideline .label_id.txt_id {
    background-color: #900 !important; }

.guideline pre {
  padding: 0;
  border: 0;
  border-radius: 0;
  background-color: #fff; }

.guideline code {
  margin: 0;
  padding: 10px; }

@media print {
  html {
    width: 1280px;
    transform: scale(0.8);
    transform-origin: left top; }
  body {
    padding-left: 0; }
    body::before, body::after {
      display: none; }
  .box__fixed_top {
    position: absolute; }
  .contents main {
    width: 100%; }
  .contents .box__section {
    margin-bottom: 40px; }
    .contents .box__section > .box__section {
      margin-bottom: 30px; }
  *[class^=col-] {
    float: left; }
  .row > *[class^=col-xs-1],
  .row > *[class^=col-sm-1],
  .row > *[class^=col-md-1],
  .row > *[class^=col-lg-1] {
    width: 8.33333%; }
  .row > *[class^=col-xs-2],
  .row > *[class^=col-sm-2],
  .row > *[class^=col-md-2],
  .row > *[class^=col-lg-2] {
    width: 16.66667%; }
  .row > *[class^=col-xs-3],
  .row > *[class^=col-sm-3],
  .row > *[class^=col-md-3],
  .row > *[class^=col-lg-3] {
    width: 25%; }
  .row > *[class^=col-xs-4],
  .row > *[class^=col-sm-4],
  .row > *[class^=col-md-4],
  .row > *[class^=col-lg-4] {
    width: 33.33333%; }
  .row > *[class^=col-xs-5],
  .row > *[class^=col-sm-5],
  .row > *[class^=col-md-5],
  .row > *[class^=col-lg-5] {
    width: 41.66667%; }
  .row > *[class^=col-xs-6],
  .row > *[class^=col-sm-6],
  .row > *[class^=col-md-6],
  .row > *[class^=col-lg-6] {
    width: 50%; }
  .row > *[class^=col-xs-7],
  .row > *[class^=col-sm-7],
  .row > *[class^=col-md-7],
  .row > *[class^=col-lg-7] {
    width: 58.33333%; }
  .row > *[class^=col-xs-8],
  .row > *[class^=col-sm-8],
  .row > *[class^=col-md-8],
  .row > *[class^=col-lg-8] {
    width: 66.66667%; }
  .row > *[class^=col-xs-9],
  .row > *[class^=col-sm-9],
  .row > *[class^=col-md-9],
  .row > *[class^=col-lg-9] {
    width: 75%; }
  .row > *[class^=col-xs-10],
  .row > *[class^=col-sm-10],
  .row > *[class^=col-md-10],
  .row > *[class^=col-lg-10] {
    width: 83.33333%; }
  .row > *[class^=col-xs-11],
  .row > *[class^=col-sm-11],
  .row > *[class^=col-md-11],
  .row > *[class^=col-lg-11] {
    width: 91.66667%; }
  .row > *[class^=col-xs-12],
  .row > *[class^=col-sm-12],
  .row > *[class^=col-md-12],
  .row > *[class^=col-lg-12] {
    width: 100%; }
  .mh {
    height: auto !important; }
  .pagetop {
    display: none !important; }
  .toggle_target {
    display: block !important; }
  .table-responsive .table tbody tr td,
  .table-responsive .table tbody tr th,
  .table-responsive .table tfoot tr td,
  .table-responsive .table tfoot tr th,
  .table-responsive .table thead tr td,
  .table-responsive .table thead tr th {
    white-space: normal; }
  .table-responsive .table tbody th,
  .table-responsive .table tfoot th,
  .table-responsive .table thead th {
    white-space: normal; } }

.news__sort-box {
  background: #EFEFEF;
  padding: 40px; }
  @media (max-width: 767.98px) {
    .news__sort-box {
      padding: 40px 20px;
      margin-top: 30px; } }

.news__sort-btn {
  border: 1px solid #004A98;
  background-color: #fff;
  list-style: none; }
  .news__sort-btn.active {
    background: #004A98; }
    .news__sort-btn.active a {
      color: #fff; }
  .news__sort-btnbox {
    display: flex;
    flex-wrap: wrap;
    gap: 10px; }
    .news__sort-btnbox a {
      display: inline-block;
      text-decoration: none;
      color: #004A98;
      padding: 5px 10px; }

.events__post_end {
  color: #E81826;
  font-weight: bold;
  margin-bottom: 20px; }
  @media (min-width: 768px) {
    .events__post_end {
      font-size: 2rem; } }
  @media (max-width: 767.98px) {
    .events__post_end {
      font-size: 1.8rem; } }

.events__menu {
  display: flex;
  align-items: flex-start; }
  @media (max-width: 767.98px) {
    .events__menu {
      flex-direction: column; } }
  .events__menu:first-child {
    margin-bottom: 10px; }
  .events__menu_box {
    background: #004A98;
    padding: 30px;
    margin-bottom: 40px; }
    @media (max-width: 767.98px) {
      .events__menu_box {
        padding: 20px; } }
  .events__menu_ttl {
    background: #fff;
    padding: 5px 10px;
    margin-right: 25px;
    flex: 0 0 105px; }
    @media (max-width: 767.98px) {
      .events__menu_ttl {
        flex: 0 0 auto;
        margin-right: 0;
        margin-bottom: 10px;
        padding: 2px 5px; } }
  .events__menu_text {
    color: #fff; }
  .events__menu span {
    display: block;
    font-weight: bold; }
  .events__menu .post-event-hostday {
    color: #004A98; }
  .events__menu .post-event-place {
    color: #004A98; }

.events .post-sys__thumnail {
  position: relative; }

.events .post-event-date {
  position: absolute;
  top: 0;
  left: 0;
  background: #fff;
  padding: 5px 10px; }
  .events .post-event-date span {
    display: block; }
  .events .post-event-date .year {
    margin-bottom: 0; }
    @media (min-width: 768px) {
      .events .post-event-date .year {
        font-size: 1.2rem; } }
    @media (max-width: 767.98px) {
      .events .post-event-date .year {
        font-size: 1.2rem; } }
  .events .post-event-date .month {
    margin: 3px 0; }
    @media (min-width: 768px) {
      .events .post-event-date .month {
        font-size: 1.6rem; } }
    @media (max-width: 767.98px) {
      .events .post-event-date .month {
        font-size: 1.6rem; } }
  .events .post-event-date .day {
    word-break: normal; }
    @media (min-width: 768px) {
      .events .post-event-date .day {
        font-size: 3rem; } }
    @media (max-width: 767.98px) {
      .events .post-event-date .day {
        font-size: 3rem; } }

.events .post-event-text .post-label-1 {
  display: inline-block !important; }

.events .post-event-text span {
  display: block; }

@media (min-width: 768px) {
  .events.detail .post-sys__date {
    font-size: 3rem; } }

@media (max-width: 767.98px) {
  .events.detail .post-sys__date {
    font-size: 2.4rem; } }

@media (max-width: 767.98px) {
  .events.detail .post-sys__date {
    margin-bottom: 0; } }

.events.detail .post-label-1 {
  font-family: "游ゴシック Medium", "Yu Gothic Medium", "游ゴシック体", YuGothic, "ヒラギノ角ゴ ProN W3", "Hiragino Kaku Gothic ProN", "メイリオ", Meiryo, Verdana, Roboto, "Droid Sans", sans-serif;
  font-weight: normal;
  line-height: 1;
  padding: 5px 10px; }
  @media (min-width: 768px) {
    .events.detail .post-label-1 {
      font-size: 1.6rem; } }
  @media (max-width: 767.98px) {
    .events.detail .post-label-1 {
      font-size: 1.4rem; } }

.events.event-end .more-btn {
  cursor: pointer;
  max-width: 300px;
  background-color: #004A98;
  color: #fff;
  text-align: center;
  padding: 10px;
  margin: 50px auto  0; }

.events.event-end .post-sys__item.is-hidden {
  display: none; }

.media:not(.detail) .post-sys__list {
  width: 100%; }

.media:not(.detail) .post-sys__item {
  width: 100%;
  margin-bottom: 16px; }

.media:not(.detail) .post-sys__link {
  display: block;
  border-bottom: 1px solid #333;
  padding-bottom: 16px; }

.media:not(.detail) .post-sys__date {
  color: #004A98;
  margin-right: 14px; }
  @media (min-width: 768px) {
    .media:not(.detail) .post-sys__date {
      font-size: 1.8rem; } }
  @media (max-width: 767.98px) {
    .media:not(.detail) .post-sys__date {
      font-size: 1.8rem; } }

.books .post-sys__date {
  margin-bottom: 0; }

.books .post-sys__text {
  font-weight: bold;
  margin-bottom: 0; }

.books:not(.detail) .post-sys__thumnail {
  background: #EFEFEF;
  padding: 30px 10px;
  width: 100%;
  height: 275px; }
  @media (max-width: 767.98px) {
    .books:not(.detail) .post-sys__thumnail {
      height: auto; } }
  .books:not(.detail) .post-sys__thumnail img {
    object-fit: contain;
    aspect-ratio: auto;
    width: 100%;
    height: 100%; }

.books.detail .post-sys__thumnail.media-thumnail {
  background: #EFEFEF;
  padding: 30px;
  width: 100%;
  height: 275px; }
  .books.detail .post-sys__thumnail.media-thumnail img {
    object-fit: contain !important;
    aspect-ratio: auto;
    width: 100%;
    height: 100%; }
  @media (max-width: 767.98px) {
    .books.detail .post-sys__thumnail.media-thumnail {
      margin-bottom: 30px; } }

.books.detail .ttl_style1, .books.detail h2:not([class]) {
  margin-top: 0px; }
  .books.detail .ttl_style1::before, .books.detail h2:not([class])::before {
    margin-bottom: 5px !important; }

.books.detail .post-sys__date {
  margin-bottom: 5px; }

.books.detail .post-sys__text {
  font-weight: normal !important; }

.books.detail .books_authorname {
  font-weight: bold; }
  @media (min-width: 768px) {
    .books.detail .books_authorname {
      font-size: 1.8rem; } }
  @media (max-width: 767.98px) {
    .books.detail .books_authorname {
      font-size: 1.6rem; } }

.page.academics .academics__lead {
  display: flex;
  justify-content: flex-end;
  margin-bottom: 20px;
  font-weight: bold;
  line-height: 1.4;
  text-align: right; }
  @media (min-width: 768px) {
    .page.academics .academics__lead {
      font-size: 2rem; } }
  @media (max-width: 767.98px) {
    .page.academics .academics__lead {
      font-size: 1.6rem; } }
  .page.academics .academics__lead span {
    color: #004A98; }
    @media (min-width: 768px) {
      .page.academics .academics__lead span {
        font-size: 3rem; } }
    @media (max-width: 767.98px) {
      .page.academics .academics__lead span {
        font-size: 2.4rem; } }
  .page.academics .academics__lead::before {
    content: "";
    height: 1px;
    margin-right: 20px;
    margin-top: 1.2em;
    background-color: #333;
    flex: 0 0 100px; }
    @media (max-width: 767.98px) {
      .page.academics .academics__lead::before {
        width: 50px;
        margin-right: 10px; } }

.page.academics .academics__faculty-list {
  display: flex;
  margin: -2.5px; }
  @media (max-width: 767.98px) {
    .page.academics .academics__faculty-list {
      flex-wrap: wrap; } }
  .page.academics .academics__faculty-list_item {
    width: 100%;
    padding: 2.5px; }
  .page.academics .academics__faculty-list_link {
    display: block;
    position: relative;
    transition: all 0.2s linear;
    color: currentColor; }
    .page.academics .academics__faculty-list_link:hover {
      color: currentColor;
      text-decoration: none;
      opacity: 1; }
    .page.academics .academics__faculty-list_link figure {
      overflow: hidden; }
    .page.academics .academics__faculty-list_link img {
      transition: all 0.6s; }
    .page.academics .academics__faculty-list_link:hover a {
      opacity: 1; }
    .page.academics .academics__faculty-list_link:hover img {
      transform: scale(1.1); }
    .page.academics .academics__faculty-list_link::before {
      content: "";
      background-image: url("/img/common/arrow_next_black.svg");
      background-position: center;
      background-repeat: no-repeat;
      width: 20px;
      height: 10px;
      background-color: #fff;
      position: absolute;
      bottom: 0;
      right: -1px;
      opacity: 1;
      z-index: 2;
      padding: 20px; }
      @media (max-width: 767.98px) {
        .page.academics .academics__faculty-list_link::before {
          right: 0;
          bottom: -1px; } }
    .page.academics .academics__faculty-list_link::after {
      content: "";
      width: 100%;
      height: 100%;
      background: rgba(0, 0, 0, 0.3);
      display: block;
      position: absolute;
      bottom: 0; }
  .page.academics .academics__faculty-list_img img {
    width: 100%;
    object-fit: cover;
    height: 470px; }
    @media (max-width: 767.98px) {
      .page.academics .academics__faculty-list_img img {
        height: auto; } }
  .page.academics .academics__faculty-list_content {
    position: absolute;
    left: 0;
    top: 0;
    padding: 30px 15px;
    z-index: 1; }
    @media (max-width: 767.98px) {
      .page.academics .academics__faculty-list_content {
        padding: 20px; } }
  .page.academics .academics__faculty-list_title {
    -ms-writing-mode: tb-rl;
    writing-mode: vertical-rl;
    margin: 0;
    color: #fff;
    font-family: "Crimson Text", Times, serif;
    font-weight: 400;
    line-height: 1.2; }
    @media (min-width: 768px) {
      .page.academics .academics__faculty-list_title {
        font-size: 3rem; } }
    @media (max-width: 767.98px) {
      .page.academics .academics__faculty-list_title {
        font-size: 2.4rem; } }
    @media (max-width: 767.98px) {
      .page.academics .academics__faculty-list_title {
        display: flex;
        flex-direction: column-reverse;
        writing-mode: horizontal-tb; } }
    .page.academics .academics__faculty-list_title span {
      display: block;
      font-family: "Noto Serif JP", "游明朝", "Hiragino Mincho ProN", "ヒラギノ明朝 W3 JIS2004", "Times New Roman", Times, serif;
      font-weight: 400; }
      @media (min-width: 768px) {
        .page.academics .academics__faculty-list_title span {
          font-size: 1.6rem; } }
      @media (max-width: 767.98px) {
        .page.academics .academics__faculty-list_title span {
          font-size: 1.4rem; } }
      @media (max-width: 767.98px) {
        .page.academics .academics__faculty-list_title span {
          margin-bottom: 5px; } }

.page.academics .academics__faculty-list2 {
  display: flex;
  margin: -2.5px;
  gap: 60px; }
  @media (max-width: 767.98px) {
    .page.academics .academics__faculty-list2 {
      flex-wrap: wrap;
      gap: 20px; } }
  .page.academics .academics__faculty-list2_item {
    width: 100%;
    padding: 2.5px; }
  .page.academics .academics__faculty-list2_link {
    display: block;
    position: relative;
    transition: all 0.2s linear;
    color: currentColor; }
    .page.academics .academics__faculty-list2_link:hover {
      color: currentColor;
      text-decoration: none;
      opacity: 1; }
    .page.academics .academics__faculty-list2_link figure {
      overflow: hidden; }
    .page.academics .academics__faculty-list2_link img {
      transition: all 0.6s; }
    .page.academics .academics__faculty-list2_link:hover a {
      opacity: 1; }
    .page.academics .academics__faculty-list2_link:hover img {
      transform: scale(1.1); }
    .page.academics .academics__faculty-list2_link::before {
      content: "";
      background-image: url("/img/common/arrow_next_black.svg");
      background-position: center;
      background-repeat: no-repeat;
      width: 20px;
      height: 10px;
      background-color: #fff;
      position: absolute;
      bottom: 0;
      right: -1px;
      opacity: 1;
      z-index: 2;
      padding: 20px; }
      @media (max-width: 767.98px) {
        .page.academics .academics__faculty-list2_link::before {
          right: 0;
          bottom: -1px; } }
    .page.academics .academics__faculty-list2_link::after {
      content: "";
      width: 100%;
      height: 100%;
      background: rgba(0, 0, 0, 0.3);
      display: block;
      position: absolute;
      bottom: 0; }
  .page.academics .academics__faculty-list2_img img {
    width: 100%;
    object-fit: cover; }
    @media (max-width: 767.98px) {
      .page.academics .academics__faculty-list2_img img {
        height: auto; } }
  .page.academics .academics__faculty-list2_content {
    position: absolute;
    left: 0;
    top: 0;
    padding: 20px 15px;
    z-index: 1; }
    @media (max-width: 767.98px) {
      .page.academics .academics__faculty-list2_content {
        padding: 20px; } }
  .page.academics .academics__faculty-list2_title {
    margin: 0;
    color: #fff;
    display: flex;
    flex-direction: column-reverse;
    gap: 15px;
    font-family: "Crimson Text", Times, serif;
    font-weight: 400;
    line-height: 1.2; }
    @media (min-width: 768px) {
      .page.academics .academics__faculty-list2_title {
        font-size: 3rem; } }
    @media (max-width: 767.98px) {
      .page.academics .academics__faculty-list2_title {
        font-size: 2.4rem; } }
    @media (max-width: 767.98px) {
      .page.academics .academics__faculty-list2_title {
        display: flex;
        flex-direction: column-reverse;
        writing-mode: horizontal-tb;
        gap: 0; } }
    .page.academics .academics__faculty-list2_title span {
      display: block;
      font-family: "Noto Serif JP", "游明朝", "Hiragino Mincho ProN", "ヒラギノ明朝 W3 JIS2004", "Times New Roman", Times, serif;
      font-weight: 400; }
      @media (min-width: 768px) {
        .page.academics .academics__faculty-list2_title span {
          font-size: 1.6rem; } }
      @media (max-width: 767.98px) {
        .page.academics .academics__faculty-list2_title span {
          font-size: 1.4rem; } }
      @media (max-width: 767.98px) {
        .page.academics .academics__faculty-list2_title span {
          margin-bottom: 5px; } }
  @media (max-width: 767.98px) {
    .page.academics .academics__faculty-list2_caption {
      text-indent: -1.2em;
      padding-left: 1.2em; } }

.page.academics .academics__findout {
  position: relative;
  z-index: 2;
  padding: 90px 0 !important;
  background: url("/academics/img/findout_bg.jpg") no-repeat center center/cover;
  color: #fff; }
  @media (max-width: 767.98px) {
    .page.academics .academics__findout {
      padding: 70px 0 100px !important; } }
  .page.academics .academics__findout::before {
    content: "";
    opacity: 0;
    visibility: hidden;
    position: absolute;
    left: 0;
    top: 0;
    z-index: 1;
    width: 100%;
    height: 100%;
    background-image: linear-gradient(to bottom, #004a98, #152740); }
  .page.academics .academics__findout.is-open::before {
    opacity: 0.5;
    visibility: visible; }
  .page.academics .academics__findout::after {
    content: "";
    position: absolute;
    left: -45px;
    top: 50%;
    z-index: -1;
    transform: translateY(-50%);
    display: block;
    width: 198px;
    height: 670px;
    background: url("/academics/img/findout_ttl.png") no-repeat left center/contain; }
    @media (max-width: 767.98px) {
      .page.academics .academics__findout::after {
        left: 50%;
        top: auto;
        bottom: -28px;
        z-index: -1;
        transform: translate(-50%, 0);
        width: 335px;
        height: 129px;
        background-image: url("/academics/img/findout_ttl-sp.png");
        background-position: center bottom; } }
  .page.academics .academics__findout-title {
    margin-bottom: 30px;
    font-weight: bold;
    text-align: center; }
    @media (min-width: 768px) {
      .page.academics .academics__findout-title {
        font-size: 2.4rem; } }
    @media (max-width: 767.98px) {
      .page.academics .academics__findout-title {
        font-size: 2.2rem; } }
    .page.academics .academics__findout-title::before {
      content: "";
      position: absolute;
      left: 0;
      right: 0;
      top: 0;
      display: block;
      width: 1px;
      height: 80px;
      margin: auto;
      background-color: #fff; }
      @media (max-width: 767.98px) {
        .page.academics .academics__findout-title::before {
          height: 60px; } }
  .page.academics .academics__findout-wrapper {
    position: relative; }
  .page.academics .academics__findout-nav {
    display: flex;
    flex-wrap: wrap;
    padding: 0;
    list-style: none;
    transition: all 0.4s ease; }
    .page.academics .academics__findout-nav.is-hide {
      opacity: 0;
      overflow: hidden;
      height: 0; }
    .page.academics .academics__findout-nav_item {
      width: 25%;
      padding: 40px 0; }
      @media (max-width: 1079.98px) {
        .page.academics .academics__findout-nav_item {
          padding: 2vw 0; } }
      @media (max-width: 767.98px) {
        .page.academics .academics__findout-nav_item {
          width: 50%;
          padding: 2.66vw 0; } }
      .page.academics .academics__findout-nav_item button {
        position: relative;
        z-index: 0;
        display: flex;
        align-items: center;
        justify-content: center;
        width: 220px;
        height: 220px;
        margin: 0 auto;
        padding: 0;
        background-color: transparent;
        border: none;
        border-radius: 100%;
        appearance: none;
        color: #fff;
        font-weight: bold;
        line-height: 1.56;
        text-align: center;
        text-decoration: none; }
        @media (min-width: 768px) {
          .page.academics .academics__findout-nav_item button {
            font-size: 2.2rem; } }
        @media (max-width: 767.98px) {
          .page.academics .academics__findout-nav_item button {
            font-size: 2rem; } }
        @media (max-width: 1079.98px) {
          .page.academics .academics__findout-nav_item button {
            width: 20vw;
            height: 20vw; } }
        @media (max-width: 767.98px) {
          .page.academics .academics__findout-nav_item button {
            width: 40vw;
            height: 40vw; } }
        .page.academics .academics__findout-nav_item button::before {
          content: "";
          position: absolute;
          left: 0;
          right: 0;
          top: 0;
          bottom: 0;
          z-index: -1;
          transition: all 0.2s ease;
          margin: auto;
          border-radius: 200px;
          border: 1px solid #fff; }
        .page.academics .academics__findout-nav_item button:hover {
          opacity: 1; }
          .page.academics .academics__findout-nav_item button:hover::before {
            transform: scale(1.1); }
      .page.academics .academics__findout-nav_item p {
        margin: 0;
        text-align: center; }
  .page.academics .academics__findout-panel {
    opacity: 0;
    overflow: hidden;
    height: 0;
    position: relative;
    z-index: 3;
    width: 100%;
    background-color: #fff;
    color: #333;
    transition: all 0.4s ease; }
    .page.academics .academics__findout-panel.is-active {
      opacity: 1;
      overflow: visible;
      height: auto; }
    .page.academics .academics__findout-panel_inner {
      padding: 90px 60px 60px; }
      @media (max-width: 767.98px) {
        .page.academics .academics__findout-panel_inner {
          padding: 70px 44px 60px; } }
    .page.academics .academics__findout-panel_title {
      margin-bottom: 40px;
      font-weight: bold;
      text-align: center; }
      @media (min-width: 768px) {
        .page.academics .academics__findout-panel_title {
          font-size: 2.4rem; } }
      @media (max-width: 767.98px) {
        .page.academics .academics__findout-panel_title {
          font-size: 1.8rem; } }
      .page.academics .academics__findout-panel_title span {
        color: #004A98; }
        @media (min-width: 768px) {
          .page.academics .academics__findout-panel_title span {
            font-size: 3rem; } }
        @media (max-width: 767.98px) {
          .page.academics .academics__findout-panel_title span {
            font-size: 3rem; } }
      .page.academics .academics__findout-panel_title::before {
        content: "";
        position: absolute;
        left: 0;
        right: 0;
        top: 0;
        display: block;
        width: 1px;
        height: 80px;
        margin: auto;
        background-color: #004A98; }
        @media (max-width: 767.98px) {
          .page.academics .academics__findout-panel_title::before {
            height: 60px; } }
    .page.academics .academics__findout-panel_btn {
      position: absolute;
      right: 0;
      top: 0;
      width: 60px;
      height: 60px;
      padding: 0;
      appearance: none;
      background: url("/academics/img/findout_close.svg") no-repeat center center/contain;
      border: 0;
      line-height: 0px;
      font-size: 0px; }
    .page.academics .academics__findout-panel_close {
      width: 120px;
      margin: 60px auto 0; }
      @media (max-width: 767.98px) {
        .page.academics .academics__findout-panel_close {
          margin: 40px auto 0; } }
      .page.academics .academics__findout-panel_close button {
        padding: 5px 0;
        display: flex;
        justify-content: space-between;
        align-items: center;
        width: 100%;
        appearance: none;
        background: transparent;
        border: 0;
        font-family: "Crimson Text", Times, serif;
        font-weight: 400; }
        .page.academics .academics__findout-panel_close button::after {
          content: "";
          background-image: url("/img/common/arrow_next_black.svg");
          background-repeat: no-repeat;
          width: 20px;
          height: 10px;
          margin-left: 10px; }
        .page.academics .academics__findout-panel_close button:hover {
          opacity: 1; }
  .page.academics .academics__findout-result {
    display: flex;
    flex-wrap: wrap;
    margin: -15px;
    padding: 0;
    list-style: none; }
    .page.academics .academics__findout-result_item {
      position: relative;
      width: 25%;
      padding: 15px; }
      @media (max-width: 767.98px) {
        .page.academics .academics__findout-result_item {
          width: 100%;
          padding: 10px; } }
      .page.academics .academics__findout-result_item::before, .page.academics .academics__findout-result_item::after {
        content: "";
        position: absolute;
        z-index: 1;
        width: 15px;
        height: 15px;
        background: url("/academics/img/findout_frame.svg") no-repeat left top/contain; }
      .page.academics .academics__findout-result_item::before {
        left: 15px;
        top: 15px; }
        @media (max-width: 767.98px) {
          .page.academics .academics__findout-result_item::before {
            left: 10px;
            top: 10px; } }
      .page.academics .academics__findout-result_item::after {
        right: 15px;
        top: 15px;
        transform: rotate(90deg); }
        @media (max-width: 767.98px) {
          .page.academics .academics__findout-result_item::after {
            right: 10px;
            top: 10px; } }
    .page.academics .academics__findout-result_frame {
      position: relative;
      z-index: 0;
      height: 100%;
      padding: 30px 20px;
      border: 1px solid #EFEFEF; }
      .page.academics .academics__findout-result_frame::before, .page.academics .academics__findout-result_frame::after {
        content: "";
        position: absolute;
        z-index: 1;
        width: 15px;
        height: 15px;
        background: url("/academics/img/findout_frame.svg") no-repeat left top/contain; }
      .page.academics .academics__findout-result_frame::before {
        left: 0;
        bottom: 0;
        transform: rotate(270deg); }
      .page.academics .academics__findout-result_frame::after {
        right: 0;
        bottom: 0;
        transform: rotate(180deg); }
    .page.academics .academics__findout-result_title {
      position: relative;
      padding-left: 30px;
      color: #004A98;
      font-weight: bold; }
      @media (min-width: 768px) {
        .page.academics .academics__findout-result_title {
          font-size: 2rem; } }
      @media (max-width: 767.98px) {
        .page.academics .academics__findout-result_title {
          font-size: 1.8rem; } }
      .page.academics .academics__findout-result_title::before {
        content: "";
        position: absolute;
        left: 0;
        top: 3px;
        z-index: 1;
        width: 20px;
        height: 20px;
        background: url("/academics/img/findout_arrow.svg") no-repeat center center/contain; }
        @media (max-width: 767.98px) {
          .page.academics .academics__findout-result_title::before {
            top: 2px; } }

.page.research .research__achievement-list {
  display: flex;
  margin: -20px; }
  @media (max-width: 767.98px) {
    .page.research .research__achievement-list {
      display: block;
      margin: -10px 0; } }
  .page.research .research__achievement-list_item {
    width: 33.3333%;
    padding: 20px;
    text-align: center; }
    @media (max-width: 767.98px) {
      .page.research .research__achievement-list_item {
        width: 100%;
        padding: 10px 20px; } }

.page.research .research__achievement-notes {
  text-align: center; }
  .page.research .research__achievement-notes_list {
    display: inline-block;
    list-style: none;
    text-align: left; }

.research__pickup {
  padding: 60px 0;
  background-color: #333; }
  @media (max-width: 767.98px) {
    .research__pickup {
      padding: 40px 0 60px; } }
  .research__pickup-inner {
    position: relative;
    padding-left: 320px; }
    @media (max-width: 767.98px) {
      .research__pickup-inner {
        padding: 0; } }
  .research__pickup-ttl {
    position: absolute;
    left: 0;
    top: 0;
    font-family: "Crimson Text", Times, serif;
    font-weight: 400;
    color: #fff; }
    @media (min-width: 768px) {
      .research__pickup-ttl {
        font-size: 8rem; } }
    @media (max-width: 767.98px) {
      .research__pickup-ttl {
        font-size: 5rem; } }
    @media (max-width: 767.98px) {
      .research__pickup-ttl {
        position: static;
        margin-bottom: 100px; } }
    .research__pickup-ttl span {
      font-family: "Noto Serif JP", "游明朝", "Hiragino Mincho ProN", "ヒラギノ明朝 W3 JIS2004", "Times New Roman", Times, serif;
      font-weight: 400;
      display: block; }
      @media (min-width: 768px) {
        .research__pickup-ttl span {
          font-size: 3rem; } }
      @media (max-width: 767.98px) {
        .research__pickup-ttl span {
          font-size: 1.8rem; } }
  .research__pickup-block {
    flex: 1 0 0%;
    margin-right: -85px;
    padding: 30px 0;
    background-color: #fff; }
    @media (max-width: 1279.98px) {
      .research__pickup-block {
        margin-right: -40px; } }
    @media (max-width: 767.98px) {
      .research__pickup-block {
        margin: 0 -20px;
        padding: 20px 0 30px 35px; } }
  .research__pickup-slider {
    position: relative; }
    @media (max-width: 767.98px) {
      .research__pickup-slider .slick-list {
        padding-right: 15px; } }
    .research__pickup-slider_item {
      margin: 0 10px !important; }
      @media (min-width: 768px) {
        .research__pickup-slider_item {
          min-height: 240px; } }
      @media (max-width: 767.98px) {
        .research__pickup-slider_item {
          margin: 0 20px 0 0 !important; } }
    .research__pickup-slider_txt {
      font-weight: bold; }
      @media (min-width: 768px) {
        .research__pickup-slider_txt {
          font-size: 1.8rem; } }
      @media (max-width: 767.98px) {
        .research__pickup-slider_txt {
          font-size: 1.6rem; } }
    .research__pickup-slider .slick-pagination {
      position: absolute;
      transform: translateX(-100%);
      left: -130px;
      bottom: 35%;
      display: flex;
      align-items: center;
      height: 30px;
      font-family: "Crimson Text", Times, serif;
      font-weight: 400;
      color: #fff; }
      @media (max-width: 767.98px) {
        .research__pickup-slider .slick-pagination {
          display: none; } }
      .research__pickup-slider .slick-pagination::after {
        content: "";
        width: 1px;
        height: 15px;
        background-color: #fff;
        margin: 0 20px 0 30px; }
        @media (max-width: 767.98px) {
          .research__pickup-slider .slick-pagination::after {
            content: none; } }
      .research__pickup-slider .slick-pagination span {
        margin: 0 5px; }
    .research__pickup-slider .slick-arrow {
      position: absolute;
      top: auto;
      bottom: 35%;
      z-index: 1;
      height: 30px; }
      @media (max-width: 767.98px) {
        .research__pickup-slider .slick-arrow {
          top: 30%;
          bottom: auto;
          background: #000;
          padding: 20px; } }
      .research__pickup-slider .slick-arrow::after {
        background-repeat: no-repeat;
        width: 20px;
        height: 10px; }
        @media (max-width: 767.98px) {
          .research__pickup-slider .slick-arrow::after {
            position: absolute;
            width: 10px !important;
            height: 19px !important;
            background-size: 100%; } }
    .research__pickup-slider .slick-next {
      left: -60px !important;
      transform: translateX(-100%); }
      @media (max-width: 767.98px) {
        .research__pickup-slider .slick-next {
          left: auto !important;
          right: -40px !important; } }
      .research__pickup-slider .slick-next::before {
        content: "" !important;
        background: url("/img/common/arrow_next_white2.svg") no-repeat center center/contain; }
        @media (max-width: 767.98px) {
          .research__pickup-slider .slick-next::before {
            background-image: url("/img/common/arrow_next_white.svg") !important; } }
    .research__pickup-slider .slick-prev {
      left: -100px !important;
      transform: translateX(-100%); }
      @media (max-width: 767.98px) {
        .research__pickup-slider .slick-prev {
          left: 5px !important; } }
      .research__pickup-slider .slick-prev::before {
        content: "" !important;
        background: url("/img/common/arrow_prev_white2.svg") no-repeat center center/contain; }
        @media (max-width: 767.98px) {
          .research__pickup-slider .slick-prev::before {
            background-image: url("/img/common/arrow_prev_white.svg") !important; } }
  .research__pickup .page-btn_style1 {
    position: absolute;
    left: -60px;
    bottom: 10%;
    transform: translateX(-100%);
    width: 120px; }
    @media (max-width: 767.98px) {
      .research__pickup .page-btn_style1 {
        left: -15px;
        top: -100px;
        transform: translate(0);
        height: 40px; } }
    .research__pickup .page-btn_style1::before, .research__pickup .page-btn_style1::after {
      background-color: #fff; }
    .research__pickup .page-btn_style1 a {
      color: #fff;
      text-decoration: none; }
      .research__pickup .page-btn_style1 a::after {
        background-image: url("/img/common/arrow_next_white2.svg"); }

.page.career .career__point-tab li {
  width: 50% !important;
  border: none; }
  .page.career .career__point-tab li a {
    border-bottom: 3px solid #D3D3D3; }
    .page.career .career__point-tab li a.active {
      color: #004A98;
      border-bottom: 3px solid #004A98; }

.page.career .career__point-lead {
  font-family: "Noto Serif JP", "游明朝", "Hiragino Mincho ProN", "ヒラギノ明朝 W3 JIS2004", "Times New Roman", Times, serif;
  font-weight: 400;
  color: #004A98;
  font-weight: bold;
  line-height: 1.5; }
  @media (min-width: 768px) {
    .page.career .career__point-lead {
      font-size: 2.6rem; } }
  @media (max-width: 767.98px) {
    .page.career .career__point-lead {
      font-size: 2.2rem; } }

.page.career .career__numbers-title {
  font-feature-settings: normal; }
  .page.career .career__numbers-title_en {
    font-size: 100%; }
    @media (max-width: 767.98px) {
      .page.career .career__numbers-title_en {
        position: absolute;
        writing-mode: horizontal-tb;
        left: 75px;
        top: 0;
        margin-top: 40px;
        color: #004A98; } }

@media (max-width: 767.98px) {
  .page.career .career__numbers .page-ttl_style2::before {
    width: 80px; } }

.page.career .career__numbers .page-ttl_style2-block {
  overflow: hidden !important;
  margin-right: -85px;
  padding: 30px 0 30px 30px;
  background-color: #fff; }
  @media (max-width: 1279.98px) {
    .page.career .career__numbers .page-ttl_style2-block {
      margin-right: -40px; } }
  @media (max-width: 767.98px) {
    .page.career .career__numbers .page-ttl_style2-block {
      margin-top: 60px;
      margin-right: -20px;
      padding: 20px 0 40px 20px; } }

.page.career .career__numbers-slider {
  position: relative;
  padding-bottom: 80px; }
  @media (max-width: 767.98px) {
    .page.career .career__numbers-slider {
      padding-bottom: 120px; } }
  .page.career .career__numbers-slider .slick-slider {
    position: relative; }
  .page.career .career__numbers-slider .slick-list {
    padding-right: 10%; }
  .page.career .career__numbers-slider_item {
    margin-right: 30px !important; }
    @media (max-width: 767.98px) {
      .page.career .career__numbers-slider_item {
        margin-right: 20px !important; } }
  .page.career .career__numbers-slider .slick-pagination {
    position: absolute;
    left: 34px;
    bottom: -30px;
    transform: translate(0, 100%);
    display: flex;
    align-items: center;
    height: 30px;
    font-family: "Crimson Text", Times, serif;
    font-weight: 400; }
    @media (max-width: 767.98px) {
      .page.career .career__numbers-slider .slick-pagination {
        left: 0;
        bottom: -20px; } }
    .page.career .career__numbers-slider .slick-pagination::after {
      content: "";
      width: 1px;
      height: 15px;
      background-color: #333;
      margin: 0 20px 0 25px; }
    .page.career .career__numbers-slider .slick-pagination span {
      margin: 0 5px; }
  .page.career .career__numbers-slider .slick-arrow {
    position: absolute;
    top: auto;
    bottom: -30px;
    transform: translate(0, 100%);
    z-index: 1;
    height: 30px; }
    @media (max-width: 767.98px) {
      .page.career .career__numbers-slider .slick-arrow {
        bottom: -20px; } }
    @media (max-width: 767.98px) {
      .page.career .career__numbers-slider .slick-arrow::before {
        width: 17px; } }
  .page.career .career__numbers-slider .slick-next {
    left: 176px; }
    @media (max-width: 767.98px) {
      .page.career .career__numbers-slider .slick-next {
        left: 127px; } }
    .page.career .career__numbers-slider .slick-next::before {
      content: "" !important;
      background: url("/img/common/arrow_next_black.svg") no-repeat center center/contain; }
  .page.career .career__numbers-slider .slick-prev {
    left: 136px; }
    @media (max-width: 767.98px) {
      .page.career .career__numbers-slider .slick-prev {
        left: 90px; } }
    .page.career .career__numbers-slider .slick-prev::before {
      content: "" !important;
      background: url("/img/common/arrow_prev_black.svg") no-repeat center center/contain; }

.page.career .career__numbers .page-btn_style1 {
  position: absolute;
  right: 96px;
  bottom: 0;
  width: 120px; }
  @media (max-width: 767.98px) {
    .page.career .career__numbers .page-btn_style1 {
      left: 0;
      right: auto; } }
  .page.career .career__numbers .page-btn_style1 a {
    text-decoration: none; }
    .page.career .career__numbers .page-btn_style1 a::after {
      background-image: url("/img/common/arrow_next_black.svg"); }

.page.career .career__center-time_item {
  display: flex; }
  .page.career .career__center-time_item dt {
    width: 6em; }
  .page.career .career__center-time_item dd {
    flex: 1 0 0%; }

.page.career .page__bnrstyle1-link[target='_blank']::after {
  content: none; }

.page.career .performance__header {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 300px;
  background: linear-gradient(to top, #004a98 0%, #152740 100%); }
  @media (max-width: 767.98px) {
    .page.career .performance__header {
      height: 250px; } }
  .page.career .performance__header-ttl {
    color: #fff;
    font-family: "Noto Serif JP", "游明朝", "Hiragino Mincho ProN", "ヒラギノ明朝 W3 JIS2004", "Times New Roman", Times, serif;
    font-weight: 400;
    font-weight: bold;
    text-align: center;
    white-space: nowrap; }
    @media (min-width: 768px) {
      .page.career .performance__header-ttl {
        font-size: 2rem; } }
    @media (max-width: 767.98px) {
      .page.career .performance__header-ttl {
        font-size: 1.8rem; } }
    .page.career .performance__header-ttl span {
      display: block;
      margin-bottom: 10px;
      font-family: "Crimson Text", Times, serif;
      font-weight: 400;
      line-height: 1.2; }
      @media (min-width: 768px) {
        .page.career .performance__header-ttl span {
          font-size: 10rem; } }
      @media (max-width: 767.98px) {
        .page.career .performance__header-ttl span {
          font-size: 5rem; } }

.page.career .performance__ttl-style1 {
  font-family: "Noto Serif JP", "游明朝", "Hiragino Mincho ProN", "ヒラギノ明朝 W3 JIS2004", "Times New Roman", Times, serif;
  font-weight: 400;
  font-weight: bold;
  text-align: center; }
  @media (min-width: 768px) {
    .page.career .performance__ttl-style1 {
      font-size: 3rem; } }
  @media (max-width: 767.98px) {
    .page.career .performance__ttl-style1 {
      font-size: 2.6rem; } }

@media (min-width: 768px) {
  .page.career .performance__notes {
    font-size: 1.4rem; } }

@media (max-width: 767.98px) {
  .page.career .performance__notes {
    font-size: 1.4rem; } }

@media (max-width: 767.98px) {
  .page.career .performance__notes {
    padding-left: 1em;
    text-indent: -1em; } }

.page.career .performance__results {
  position: relative;
  padding: 60px 0 120px;
  background: url("/career/performance/img/results_bg.jpg") no-repeat center top/cover;
  text-align: center; }
  @media (max-width: 767.98px) {
    .page.career .performance__results {
      padding: 60px 0 100px;
      background-image: url("/career/performance/img/results_bg-sp.jpg"); } }
  .page.career .performance__results::after {
    content: "";
    position: absolute;
    left: 0;
    right: 0;
    bottom: 0;
    display: block;
    width: 1px;
    height: 60px;
    margin: auto;
    background-color: #333; }
  .page.career .performance__results-ttl {
    font-family: "Noto Serif JP", "游明朝", "Hiragino Mincho ProN", "ヒラギノ明朝 W3 JIS2004", "Times New Roman", Times, serif;
    font-weight: 400;
    font-weight: bold; }
    @media (min-width: 768px) {
      .page.career .performance__results-ttl {
        font-size: 4rem; } }
    @media (max-width: 767.98px) {
      .page.career .performance__results-ttl {
        font-size: 2.4rem; } }
  .page.career .performance__results-number {
    font-family: "Crimson Text", Times, serif;
    font-weight: 400;
    line-height: 1; }
    @media (min-width: 768px) {
      .page.career .performance__results-number {
        font-size: 15rem; } }
    @media (max-width: 767.98px) {
      .page.career .performance__results-number {
        font-size: 10rem; } }
    .page.career .performance__results-number > span:nth-child(1), .page.career .performance__results-number > span:nth-child(4) {
      color: #004A98; }
    .page.career .performance__results-number > span:nth-child(3) {
      color: #aebdd4; }
  .page.career .performance__results-year {
    font-family: "Noto Serif JP", "游明朝", "Hiragino Mincho ProN", "ヒラギノ明朝 W3 JIS2004", "Times New Roman", Times, serif;
    font-weight: 400;
    font-weight: bold; }
    @media (min-width: 768px) {
      .page.career .performance__results-year {
        font-size: 2.4rem; } }
    @media (max-width: 767.98px) {
      .page.career .performance__results-year {
        font-size: 2rem; } }
  @media (max-width: 767.98px) {
    .page.career .performance__results-txt {
      text-align: left; } }

.page.career .performance__rate {
  padding: 80px 0 90px;
  background: url("/career/performance/img/rate_bg.jpg") no-repeat center top/cover;
  color: #fff;
  text-align: center; }
  @media (max-width: 767.98px) {
    .page.career .performance__rate {
      padding: 60px 0;
      background-image: url("/career/performance/img/rate_bg-sp.jpg"); } }
  .page.career .performance__rate-content {
    padding: 30px 0;
    border-top: 3px solid #fff;
    border-bottom: 3px solid #fff; }
    @media (max-width: 767.98px) {
      .page.career .performance__rate-content {
        padding: 40px 0 50px; } }
  .page.career .performance__rate-number {
    font-family: "Crimson Text", Times, serif;
    font-weight: 400;
    line-height: 1; }
    @media (min-width: 768px) {
      .page.career .performance__rate-number {
        font-size: 5.8rem; } }
    @media (max-width: 767.98px) {
      .page.career .performance__rate-number {
        font-size: 3.9rem; } }
    .page.career .performance__rate-number > span {
      display: inline-block;
      margin-right: 10px; }
      @media (min-width: 768px) {
        .page.career .performance__rate-number > span {
          font-size: 19.5rem; } }
      @media (max-width: 767.98px) {
        .page.career .performance__rate-number > span {
          font-size: 13.2rem; } }
  .page.career .performance__rate-graphs {
    padding: 40px;
    background-color: rgba(255, 255, 255, 0.4); }
    @media (max-width: 767.98px) {
      .page.career .performance__rate-graphs {
        max-width: 240px;
        margin: 0 auto;
        padding: 30px; } }
    .page.career .performance__rate-graphs_list {
      display: flex;
      margin: -2.6%;
      padding: 0;
      list-style: none; }
      @media (max-width: 767.98px) {
        .page.career .performance__rate-graphs_list {
          display: block;
          margin: -15px 0; } }
    .page.career .performance__rate-graphs_item {
      width: 100%;
      padding: 2.6%; }
      @media (max-width: 767.98px) {
        .page.career .performance__rate-graphs_item {
          padding: 15px 0; } }
    .page.career .performance__rate-graphs_ttl {
      margin-bottom: 20px;
      padding: 5px;
      background-color: #fff;
      color: #333;
      font-weight: bold; }
      @media (min-width: 768px) {
        .page.career .performance__rate-graphs_ttl {
          font-size: 1.6rem; } }
      @media (max-width: 767.98px) {
        .page.career .performance__rate-graphs_ttl {
          font-size: 1.6rem; } }
      @media (max-width: 767.98px) {
        .page.career .performance__rate-graphs_ttl {
          margin-bottom: 10px; } }
  .page.career .performance__rate-type_list {
    display: flex;
    padding: 0;
    list-style: none; }
    @media (max-width: 767.98px) {
      .page.career .performance__rate-type_list {
        display: block;
        max-width: 240px;
        margin: -30px auto; } }
  .page.career .performance__rate-type_item {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%; }
    @media (max-width: 767.98px) {
      .page.career .performance__rate-type_item {
        justify-content: space-between;
        padding: 30px 0; } }
    .page.career .performance__rate-type_item:not(:first-child) {
      border-left: 1px solid #fff; }
      @media (max-width: 767.98px) {
        .page.career .performance__rate-type_item:not(:first-child) {
          border: none; }
          .page.career .performance__rate-type_item:not(:first-child)::before {
            content: "";
            position: absolute;
            left: 50%;
            top: 0;
            transform: translateX(-50%);
            width: 60px;
            height: 1px;
            background-color: #fff; } }
  .page.career .performance__rate-type_ttl {
    margin: 0 25px 0 0;
    font-weight: bold; }
    @media (min-width: 768px) {
      .page.career .performance__rate-type_ttl {
        font-size: 1.6rem; } }
    @media (max-width: 767.98px) {
      .page.career .performance__rate-type_ttl {
        font-size: 1.6rem; } }
    @media (max-width: 767.98px) {
      .page.career .performance__rate-type_ttl {
        width: 4em;
        margin: 0; } }
    .page.career .performance__rate-type_ttl > span {
      display: block;
      margin-bottom: 10px; }
  .page.career .performance__rate-type_number {
    font-family: "Noto Serif JP", "游明朝", "Hiragino Mincho ProN", "ヒラギノ明朝 W3 JIS2004", "Times New Roman", Times, serif;
    font-weight: 400;
    letter-spacing: normal;
    line-height: 1; }
    @media (min-width: 768px) {
      .page.career .performance__rate-type_number {
        font-size: 2.8rem; } }
    @media (max-width: 767.98px) {
      .page.career .performance__rate-type_number {
        font-size: 2.8rem; } }
    .page.career .performance__rate-type_number > span {
      display: inline-block;
      font-family: "Crimson Text", Times, serif;
      font-weight: 400; }
      @media (min-width: 768px) {
        .page.career .performance__rate-type_number > span {
          font-size: 9.4rem; } }
      @media (max-width: 767.98px) {
        .page.career .performance__rate-type_number > span {
          font-size: 9.4rem; } }

.page.career .performance__ratio {
  padding: 80px 0 90px; }
  @media (max-width: 767.98px) {
    .page.career .performance__ratio {
      padding: 60px 0 90px; } }
  .page.career .performance__ratio-list {
    display: flex;
    margin: -15px;
    padding: 0;
    list-style: none; }
    @media (max-width: 767.98px) {
      .page.career .performance__ratio-list {
        display: block;
        max-width: 216px;
        margin: -15px auto; } }
    .page.career .performance__ratio-list_item {
      width: 100%;
      padding: 15px; }
      @media (max-width: 767.98px) {
        .page.career .performance__ratio-list_item {
          padding: 15px 0; } }
  .page.career .performance__ratio-labels {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: center;
    margin: -7px -15px;
    padding: 0;
    list-style: none; }
    @media (max-width: 767.98px) {
      .page.career .performance__ratio-labels {
        justify-content: flex-start; } }
    .page.career .performance__ratio-labels li {
      display: flex;
      align-items: center;
      padding: 7px 15px; }
    .page.career .performance__ratio-labels_color {
      display: inline-block;
      width: 16px;
      height: 16px;
      margin-right: 10px; }
      .page.career .performance__ratio-labels_color.-color1 {
        background-color: #fff;
        border: 1px solid #D3D3D3; }
      .page.career .performance__ratio-labels_color.-color2 {
        background-color: #EFEFEF; }
      .page.career .performance__ratio-labels_color.-color3 {
        background-color: #D3D3D3; }
      .page.career .performance__ratio-labels_color.-color4 {
        background-color: #aebdd4; }
      .page.career .performance__ratio-labels_color.-color5 {
        background-color: #4874ae; }
      .page.career .performance__ratio-labels_color.-color6 {
        background-color: #004A98; }
      .page.career .performance__ratio-labels_color.-color7 {
        background-color: #013d7b; }
      .page.career .performance__ratio-labels_color.-color8 {
        background-color: #333; }

.page.career .performance__gender {
  padding: 80px 0 90px;
  background: url("/career/performance/img/gender_bg.jpg") no-repeat center top/cover;
  color: #004A98;
  text-align: center; }
  @media (max-width: 767.98px) {
    .page.career .performance__gender {
      padding: 60px 0;
      background-image: url("/career/performance/img/gender_bg-sp.jpg"); } }
  .page.career .performance__gender-list {
    display: flex;
    padding: 50px 0;
    list-style: none;
    border-top: 2px solid #004A98;
    border-bottom: 1px solid #004A98; }
    @media (max-width: 767.98px) {
      .page.career .performance__gender-list {
        display: block;
        padding: 0; } }
    .page.career .performance__gender-list_item {
      position: relative;
      width: 50%;
      padding: 0 80px; }
      @media (max-width: 767.98px) {
        .page.career .performance__gender-list_item {
          width: 100%;
          padding: 30px 0 40px; } }
      .page.career .performance__gender-list_item:nth-child(2n) {
        border-left: 1px solid #004A98; }
        @media (max-width: 767.98px) {
          .page.career .performance__gender-list_item:nth-child(2n) {
            border: none; }
            .page.career .performance__gender-list_item:nth-child(2n)::before {
              content: "";
              position: absolute;
              left: 50%;
              top: 0;
              transform: translateX(-50%);
              width: 77%;
              height: 1px;
              background-color: #004A98; } }

.page.career .performance__global {
  padding: 80px 0 90px;
  background: url("/career/performance/img/global_bg.jpg") no-repeat center center/cover;
  color: #fff;
  text-align: center; }
  @media (max-width: 767.98px) {
    .page.career .performance__global {
      padding: 60px 0;
      background-image: url("/career/performance/img/global_bg-sp.jpg"); } }
  .page.career .performance__global-number {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 300px;
    height: 300px;
    margin: 80px auto 120px;
    padding-bottom: 20px;
    border: 1px solid #fff;
    border-radius: 100%;
    font-family: "Crimson Text", Times, serif;
    font-weight: 400;
    line-height: .9; }
    @media (min-width: 768px) {
      .page.career .performance__global-number {
        font-size: 2.9rem; } }
    @media (max-width: 767.98px) {
      .page.career .performance__global-number {
        font-size: 1.9rem; } }
    @media (max-width: 767.98px) {
      .page.career .performance__global-number {
        width: 200px;
        height: 200px;
        margin: 120px auto 40px;
        padding-bottom: 10px; } }
    .page.career .performance__global-number > span {
      display: block; }
      @media (min-width: 768px) {
        .page.career .performance__global-number > span {
          font-size: 16.9rem; } }
      @media (max-width: 767.98px) {
        .page.career .performance__global-number > span {
          font-size: 11.2rem; } }

.page.career .performance__support {
  padding: 80px 0 90px;
  background: url("/career/performance/img/support_bg.jpg") no-repeat center top/cover;
  color: #004A98;
  text-align: center; }
  @media (max-width: 767.98px) {
    .page.career .performance__support {
      padding: 60px 0;
      background-image: url("/career/performance/img/support_bg-sp.jpg"); } }
  .page.career .performance__support-content {
    border-top: 3px solid #004A98;
    border-bottom: 3px solid #004A98; }
  .page.career .performance__support-wrapper {
    display: flex;
    align-items: center;
    padding: 30px 0; }
    @media (max-width: 767.98px) {
      .page.career .performance__support-wrapper {
        display: block; } }
  .page.career .performance__support-number {
    flex-basis: 47.5%;
    margin: 0;
    font-family: "Crimson Text", Times, serif;
    font-weight: 400;
    line-height: 1; }
    @media (min-width: 768px) {
      .page.career .performance__support-number {
        font-size: 2.9rem; } }
    @media (max-width: 767.98px) {
      .page.career .performance__support-number {
        font-size: 2.2rem; } }
    .page.career .performance__support-number > span {
      display: block;
      background-image: linear-gradient(to bottom, #3d659b, #063167);
      -webkit-background-clip: text;
      -webkit-text-fill-color: transparent; }
      @media (min-width: 768px) {
        .page.career .performance__support-number > span {
          font-size: 20rem; } }
      @media (max-width: 767.98px) {
        .page.career .performance__support-number > span {
          font-size: 12rem; } }
      .ie11 .performance__support-number > span {
        color: #004A98;
        background: none !important; }
  .page.career .performance__support-program {
    position: relative;
    flex: 1 0 0%;
    text-align: left; }
    .page.career .performance__support-program_box {
      background: #EFEFEF;
      padding: 30px;
      margin-top: 30px; }
    .page.career .performance__support-program_lead {
      color: #333;
      text-align: center; }
      @media (min-width: 768px) {
        .page.career .performance__support-program_lead {
          font-size: 1.8rem; } }
      @media (max-width: 767.98px) {
        .page.career .performance__support-program_lead {
          font-size: 1.4rem; } }
    .page.career .performance__support-program_ttl {
      text-align: center;
      font-weight: bold; }
      @media (min-width: 768px) {
        .page.career .performance__support-program_ttl {
          font-size: 2.4rem; } }
      @media (max-width: 767.98px) {
        .page.career .performance__support-program_ttl {
          font-size: 2rem; } }
    .page.career .performance__support-program_list {
      padding: 0;
      list-style: none;
      display: flex;
      flex-wrap: wrap; }
      @media (min-width: 768px) {
        .page.career .performance__support-program_list {
          font-size: 1.6rem; } }
      @media (max-width: 767.98px) {
        .page.career .performance__support-program_list {
          font-size: 1.6rem; } }
      @media (max-width: 767.98px) {
        .page.career .performance__support-program_list {
          display: block; } }
      .page.career .performance__support-program_list li {
        position: relative;
        padding-left: 1em;
        color: #333;
        margin-right: 20px;
        width: calc(33.333% - 20px); }
        @media (max-width: 767.98px) {
          .page.career .performance__support-program_list li {
            margin-right: auto;
            width: 100%; } }
        .page.career .performance__support-program_list li + li {
          margin-top: 0.5em; }
        .page.career .performance__support-program_list li::before {
          content: "・";
          position: absolute;
          left: 0;
          top: 0;
          color: #004A98; }
  .page.career .performance__support-obog {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 15px 0;
    border-top: 1px solid #004A98; }
    @media (max-width: 767.98px) {
      .page.career .performance__support-obog {
        padding: 30px 0; } }
    .page.career .performance__support-obog_ttl {
      margin: 0;
      font-weight: bold; }
      @media (min-width: 768px) {
        .page.career .performance__support-obog_ttl {
          font-size: 2.4rem; } }
      @media (max-width: 767.98px) {
        .page.career .performance__support-obog_ttl {
          font-size: 1.8rem; } }
      @media (max-width: 767.98px) {
        .page.career .performance__support-obog_ttl {
          text-align: left; } }
    .page.career .performance__support-obog_number {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 120px;
      height: 120px;
      margin-left: 15px;
      background-color: rgba(0, 74, 152, 0.2);
      border-radius: 100%;
      letter-spacing: normal;
      line-height: 1;
      white-space: nowrap; }
      @media (min-width: 768px) {
        .page.career .performance__support-obog_number {
          font-size: 2rem; } }
      @media (max-width: 767.98px) {
        .page.career .performance__support-obog_number {
          font-size: 1.5rem; } }
      @media (max-width: 767.98px) {
        .page.career .performance__support-obog_number {
          width: 90px;
          height: 90px; } }
      .page.career .performance__support-obog_number span {
        font-family: "Crimson Text", Times, serif;
        font-weight: 400; }
        @media (min-width: 768px) {
          .page.career .performance__support-obog_number span {
            font-size: 6rem; } }
        @media (max-width: 767.98px) {
          .page.career .performance__support-obog_number span {
            font-size: 4.5rem; } }

.page.career .performance__status {
  padding: 80px 0 90px;
  background: url("/career/performance/img/status_bg.jpg") no-repeat center top/cover; }
  @media (max-width: 767.98px) {
    .page.career .performance__status {
      padding: 60px 0;
      background-image: url("/career/performance/img/status_bg-sp.jpg"); } }
  @media (min-width: 768px) {
    .page.career .performance__status div[id] {
      margin-top: -70px;
      padding-top: 70px;
      visibility: hidden; } }
  .page.career .performance__status-content .accordion {
    background-color: #fff; }
  .page.career .performance__status-table {
    table-layout: fixed; }
    @media (max-width: 767.98px) {
      .page.career .performance__status-table {
        border-left: 1px solid; } }
    @media (max-width: 767.98px) {
      .page.career .performance__status-table .table-header {
        position: sticky;
        left: -1px;
        width: 90px !important;
        word-break: break-word; } }
    .page.career .performance__status-table thead th {
      padding: 10px;
      background: #004A98;
      border: 1px solid #D3D3D3 !important;
      color: #fff;
      text-align: center; }
    .page.career .performance__status-table tbody th {
      background: #EFEFEF;
      color: #004A98;
      letter-spacing: normal;
      text-align: left; }
    .page.career .performance__status-table tbody td {
      text-align: center; }
    .page.career .performance__status-table tfoot th, .page.career .performance__status-table tfoot td {
      padding: 10px;
      background: #ededed;
      border: 1px solid #D3D3D3 !important;
      color: #333; }
    .page.career .performance__status-table tfoot th {
      text-align: left; }
    .page.career .performance__status-table tfoot td {
      text-align: center; }
    @media (max-width: 767.98px) {
      .page.career .performance__status-table1 {
        min-width: 1140px !important; } }
    @media (max-width: 767.98px) {
      .page.career .performance__status-table2 {
        min-width: 800px !important; } }
    .page.career .performance__status-table2 tbody th {
      text-align: center; }
    .page.career .performance__status-table2 tbody td {
      text-align: right; }
      .page.career .performance__status-table2 tbody td:last-child {
        background: #EFEFEF;
        font-weight: bold; }
    .page.career .performance__status-table2 tfoot th {
      text-align: center; }
    .page.career .performance__status-table2 tfoot td {
      font-weight: bold;
      text-align: right; }
    @media (max-width: 767.98px) {
      .page.career .performance__status-table3 {
        min-width: 600px !important; } }
  .page.career .performance__status-list1 .ttl_style4, .page.career .performance__status-list1 h5:not([class]) {
    margin-bottom: 25px;
    font-size: 1.6rem; }
  .page.career .performance__status-list1 ul {
    margin-bottom: 30px;
    padding: 0; }
  .page.career .performance__status-accordion_sub {
    margin-bottom: 5px;
    border: none; }
    .page.career .performance__status-accordion_sub .accordion__trigger {
      padding: 14px 20px 14px 30px;
      background-color: #D3D3D3;
      color: #004A98;
      font-size: 1.8rem; }
    .page.career .performance__status-accordion_sub .accordion__icon::before {
      transform: translate(-50%, -50%);
      font-family: fontawesome;
      content: "\f107";
      display: block;
      width: auto;
      height: auto;
      background: none; }
    .page.career .performance__status-accordion_sub .accordion__icon::after {
      content: none; }
    .page.career .performance__status-accordion_sub .accordion__inner {
      padding: 25px 0 0; }
    .page.career .performance__status-accordion_sub[open] .accordion__trigger {
      background-color: #aebdd4; }
    .page.career .performance__status-accordion_sub[open] .accordion__icon::before {
      transform: translate(-50%, -50%) scale(1, -1); }

@media (max-width: 767.98px) {
  .page.career.en .performance__status-table .table-header {
    width: 120px !important; } }

.page.admissions.gakubu .page__mv-copy {
  padding-top: 0 !important; }
  .page.admissions.gakubu .page__mv-copy-title span {
    font-size: 6rem; }
    @media (max-width: 767.98px) {
      .page.admissions.gakubu .page__mv-copy-title span {
        font-size: 3rem; } }
  @media (max-width: 767.98px) {
    .page.admissions.gakubu .page__mv-copy {
      height: 200px !important; } }

.page.admissions.gakubu .admissions__mv-btnlist {
  padding-bottom: 250px !important; }

.page.admissions.gakubu .accordion__inner span {
  padding: 0.5rem; }

@media (max-width: 767.98px) {
  .page.admissions.gakubu .admissions__gakubu_gaiyo .mt-be-columns {
    padding: 0 10px 10px 10px;
    border: 1px solid #004A98; } }

.page.admissions.gakubu picture img {
  margin: 0 auto;
  display: block; }

.page.admissions .page__mv {
  padding-bottom: 0; }
  .page.admissions .page__mv .page__mv-copy {
    height: 300px;
    padding-top: 0 !important; }
    @media (max-width: 767.98px) {
      .page.admissions .page__mv .page__mv-copy {
        height: 150px !important;
        padding-top: 0 !important; } }
    .page.admissions .page__mv .page__mv-copy::before {
      content: none; }

.page.admissions .page__news-box .page__news-list {
  height: 400px; }
  @media (max-width: 767.98px) {
    .page.admissions .page__news-box .page__news-list {
      height: 350px; } }

.page.admissions .page__news-box .page__event-list {
  height: 470px; }
  @media (max-width: 767.98px) {
    .page.admissions .page__news-box .page__event-list {
      height: auto; } }

.page.admissions .page__news-box .page-imglink_box::after {
  background-color: #EFEFEF; }

.page.admissions .admissions__mv-btnlist_row {
  display: flex;
  gap: 30px;
  width: 100%; }
  @media (max-width: 767.98px) {
    .page.admissions .admissions__mv-btnlist_row {
      display: block; } }

.page.admissions .admissions__mv-btnlist_item {
  width: 100%; }

.page.admissions .admissions__mv-btnlist_btn a {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 80px;
  background: #fff;
  border: 1px solid #fff;
  font-weight: bold;
  line-height: 1.56;
  text-align: center; }
  @media (min-width: 768px) {
    .page.admissions .admissions__mv-btnlist_btn a {
      font-size: 1.8rem; } }
  @media (max-width: 767.98px) {
    .page.admissions .admissions__mv-btnlist_btn a {
      font-size: 1.6rem; } }
  .page.admissions .admissions__mv-btnlist_btn a::after {
    content: "";
    background-image: url(/img/common/arrow_next_black.svg);
    background-repeat: no-repeat;
    width: 15px;
    height: 7px;
    margin-left: 10px; }
  .page.admissions .admissions__mv-btnlist_btn a:hover {
    background: #004A98;
    border: 1px solid #004A98;
    color: #fff; }
    .page.admissions .admissions__mv-btnlist_btn a:hover::after {
      background-image: url(/img/common/arrow_next_white2.svg); }
  .page.admissions .admissions__mv-btnlist_btn a img {
    display: inline-block;
    width: 50px;
    margin-right: 10px; }

.page.admissions .admissions__mv-btnlist_btn.mainbg-style a {
  background: #004A98 !important;
  color: #fff !important; }
  .page.admissions .admissions__mv-btnlist_btn.mainbg-style a::after {
    background-image: url(/img/common/arrow_next_white2.svg); }
  .page.admissions .admissions__mv-btnlist_btn.mainbg-style a:hover {
    background: #fff !important;
    color: #004A98 !important; }
    .page.admissions .admissions__mv-btnlist_btn.mainbg-style a:hover::after {
      background-image: url(/img/common/arrow_next_black.svg); }

@media (max-width: 767.98px) {
  .page.admissions .admissions__mv-btnlist .btn_style a {
    padding: 15px 25px 15px 20px !important; }
    .page.admissions .admissions__mv-btnlist .btn_style a::after {
      right: 10px !important; } }

.page.admissions .admissions__linklist_box {
  display: flex;
  border-left: 3px solid #004A98; }
  @media (max-width: 767.98px) {
    .page.admissions .admissions__linklist_box {
      display: block;
      border-left: none; } }

.page.admissions .admissions__linklist_title {
  flex: 0 0 240px;
  font-weight: bold;
  padding-left: 15px;
  color: #004A98; }
  @media (min-width: 768px) {
    .page.admissions .admissions__linklist_title {
      font-size: 2rem; } }
  @media (max-width: 767.98px) {
    .page.admissions .admissions__linklist_title {
      font-size: 2rem; } }
  @media (max-width: 767.98px) {
    .page.admissions .admissions__linklist_title {
      border-bottom: 3px solid #004A98;
      margin-bottom: 30px; } }

.page.admissions .admissions__linklist_main {
  width: 100%; }

.page.admissions .admissions__linklist_list {
  display: flex;
  flex-wrap: wrap;
  width: 100%; }
  @media (max-width: 767.98px) {
    .page.admissions .admissions__linklist_list {
      display: block;
      border-left: none; } }
  .page.admissions .admissions__linklist_list .btn_style {
    width: calc(50% - 22.5px);
    margin-right: 45px !important;
    margin: 0;
    padding: 0;
    list-style: none; }
    @media (max-width: 767.98px) {
      .page.admissions .admissions__linklist_list .btn_style {
        width: 100%;
        margin-right: 0; } }
    .page.admissions .admissions__linklist_list .btn_style:nth-child(even) {
      margin-right: 0 !important; }

.page.admissions .admissions__gakubu_gaiyo {
  background: #fff;
  padding: 60px;
  position: relative;
  top: -150px;
  margin-bottom: -150px; }
  @media (max-width: 767.98px) {
    .page.admissions .admissions__gakubu_gaiyo {
      padding: 20px; } }
  .page.admissions .admissions__gakubu_gaiyo .lead_style1 {
    text-align: center; }
  .page.admissions .admissions__gakubu_gaiyo .list_style1 {
    list-style: none;
    padding-left: 0; }
    .page.admissions .admissions__gakubu_gaiyo .list_style1 li a {
      padding-top: 10px;
      position: relative;
      padding-left: 50px;
      padding-bottom: 10px;
      font-weight: bold;
      text-decoration: none;
      border-bottom: 1px solid #D3D3D3;
      display: block; }
      .page.admissions .admissions__gakubu_gaiyo .list_style1 li a::after {
        font-family: 'FontAwesome';
        content: '\f107';
        position: absolute;
        display: inline-block;
        top: 50%;
        transform: translateY(-50%);
        left: 25px;
        color: #004A98; }
    .page.admissions .admissions__gakubu_gaiyo .list_style1 li::marker {
      content: none !important; }
  .page.admissions .admissions__gakubu_gaiyo .mt-be-columns {
    align-items: center; }
    @media (max-width: 767.98px) {
      .page.admissions .admissions__gakubu_gaiyo .mt-be-columns {
        margin-bottom: 20px; } }
    .page.admissions .admissions__gakubu_gaiyo .mt-be-columns .mt-be-column span {
      padding: 0.5rem; }
  .page.admissions .admissions__gakubu_gaiyo .ttl_style4, .page.admissions .admissions__gakubu_gaiyo h5:not([class]) {
    border-bottom: 1px solid #333;
    font-weight: bold;
    padding-bottom: 10px;
    padding-left: 20px;
    margin-bottom: 10px !important; }

.page.granddesign2039 .breadcrumb-item.active {
  color: #fff; }

.page.granddesign2039 .breadcrumb-item a {
  color: #fff; }

.page.granddesign2039 .breadcrumb-item + .breadcrumb-item::before {
  color: #fff; }

.page.granddesign2039 main.contents {
  padding-bottom: 0; }

.page.granddesign2039 .granddesign2039__wrapper {
  position: relative;
  z-index: 0;
  background: url("/about/granddesign2039/img/bg_gradation1.png") repeat-x center top/auto auto; }
  @media (max-width: 767.98px) {
    .page.granddesign2039 .granddesign2039__wrapper {
      background-image: url("/about/granddesign2039/img/bg_gradation1-sp.png");
      overflow: hidden; } }

.page.granddesign2039 .granddesign2039__header {
  padding: 90px 0; }
  @media (max-width: 767.98px) {
    .page.granddesign2039 .granddesign2039__header {
      padding: 50px 0; } }
  .page.granddesign2039 .granddesign2039__header-ttl {
    font-family: "Crimson Text", Times, serif;
    font-weight: 400;
    color: #fff;
    line-height: 1.1;
    text-align: center;
    white-space: nowrap; }
    @media (min-width: 768px) {
      .page.granddesign2039 .granddesign2039__header-ttl {
        font-size: 10rem; } }
    @media (max-width: 767.98px) {
      .page.granddesign2039 .granddesign2039__header-ttl {
        font-size: 4rem; } }

.page.granddesign2039 .granddesign2039__main {
  padding: 60px 0 120px;
  background: url("/about/granddesign2039/img/bg_main.jpg") no-repeat center bottom/100% auto; }
  @media (max-width: 767.98px) {
    .page.granddesign2039 .granddesign2039__main {
      padding: 30px 0 90px;
      background-image: url("/about/granddesign2039/img/bg_main-sp.jpg"); } }

.page.granddesign2039 .granddesign2039__pagelink {
  position: sticky;
  left: 18px;
  top: 180px;
  z-index: 5;
  width: 120px;
  margin: 0; }
  @media (max-width: 767.98px) {
    .page.granddesign2039 .granddesign2039__pagelink {
      position: static;
      width: 100%;
      margin-bottom: 100px; } }
  .page.granddesign2039 .granddesign2039__pagelink::before {
    position: absolute;
    left: 0;
    top: 50%;
    transform: translateY(-50%);
    content: "";
    display: block;
    width: 120px;
    height: 240px;
    background-color: #fff;
    border-radius: 0 120px 120px 0;
    opacity: 0.7; }
    @media (max-width: 767.98px) {
      .page.granddesign2039 .granddesign2039__pagelink::before {
        content: none; } }
  .page.granddesign2039 .granddesign2039__pagelink-list {
    margin: 0;
    padding-left: 20px;
    list-style: none; }
    @media (max-width: 767.98px) {
      .page.granddesign2039 .granddesign2039__pagelink-list {
        padding: 0 20px; } }
    .page.granddesign2039 .granddesign2039__pagelink-list li {
      position: relative;
      padding: 5px 0 5px 11px;
      line-height: 1; }
      @media (min-width: 768px) {
        .page.granddesign2039 .granddesign2039__pagelink-list li {
          font-size: 1.4rem; } }
      @media (max-width: 767.98px) {
        .page.granddesign2039 .granddesign2039__pagelink-list li {
          font-size: 1.6rem; } }
      @media (min-width: 768px) {
        .page.granddesign2039 .granddesign2039__pagelink-list li {
          font-family: "Crimson Text", Times, serif;
          font-weight: 400; } }
      @media (max-width: 767.98px) {
        .page.granddesign2039 .granddesign2039__pagelink-list li {
          padding: 0;
          border-bottom: 1px solid #fff; } }
      .page.granddesign2039 .granddesign2039__pagelink-list li:not(:last-child)::after {
        position: absolute;
        left: 2.5px;
        top: 3px;
        content: "";
        display: block;
        width: 0;
        height: calc(100% + 6px);
        border-right: 1px dotted #999; }
        @media (max-width: 767.98px) {
          .page.granddesign2039 .granddesign2039__pagelink-list li:not(:last-child)::after {
            content: none; } }
      .page.granddesign2039 .granddesign2039__pagelink-list li::before {
        position: absolute;
        left: 0;
        top: 8px;
        content: "";
        display: inline-block;
        width: 6px;
        height: 6px;
        border-radius: 100%;
        background-color: #999; }
        @media (max-width: 767.98px) {
          .page.granddesign2039 .granddesign2039__pagelink-list li::before {
            content: none; } }
      .page.granddesign2039 .granddesign2039__pagelink-list li a {
        text-decoration: none; }
        @media (max-width: 767.98px) {
          .page.granddesign2039 .granddesign2039__pagelink-list li a {
            display: block;
            padding: 16px 0;
            color: #fff; } }
        @media (max-width: 767.98px) {
          .page.granddesign2039 .granddesign2039__pagelink-list li a::after {
            font-family: fontawesome;
            content: "\f107";
            position: absolute;
            right: 0;
            top: 50%;
            transform: translateY(-50%);
            line-height: 1; } }

.page.granddesign2039 .granddesign2039__inner {
  max-width: 840px;
  padding-left: 20px;
  padding-right: 20px; }

.page.granddesign2039 .granddesign2039__ttl-style1 {
  position: relative;
  margin-bottom: 30px;
  padding-bottom: 40px;
  font-family: "Crimson Text", Times, serif;
  font-weight: 400;
  color: #999;
  font-weight: bold;
  line-height: 1;
  text-align: center;
  white-space: nowrap; }
  @media (min-width: 768px) {
    .page.granddesign2039 .granddesign2039__ttl-style1 {
      font-size: 10rem; } }
  @media (max-width: 767.98px) {
    .page.granddesign2039 .granddesign2039__ttl-style1 {
      font-size: 6rem; } }
  .page.granddesign2039 .granddesign2039__ttl-style1::after {
    position: absolute;
    left: 50%;
    bottom: 0;
    transform: translateX(-50%);
    content: "";
    width: 1px;
    height: 36px;
    background-color: #999; }

.page.granddesign2039 .granddesign2039__ttl-style2 {
  margin-bottom: 1em;
  font-family: "Noto Serif JP", "游明朝", "Hiragino Mincho ProN", "ヒラギノ明朝 W3 JIS2004", "Times New Roman", Times, serif;
  font-weight: 400;
  color: #004A98;
  font-weight: bold;
  line-height: 1.67;
  text-align: center; }
  @media (min-width: 768px) {
    .page.granddesign2039 .granddesign2039__ttl-style2 {
      font-size: 2.6rem; } }
  @media (max-width: 767.98px) {
    .page.granddesign2039 .granddesign2039__ttl-style2 {
      font-size: 2.4rem; } }

.page.granddesign2039 .granddesign2039__about {
  position: relative;
  z-index: 2;
  padding: 60px 0;
  text-align: center; }
  @media (max-width: 767.98px) {
    .page.granddesign2039 .granddesign2039__about {
      padding: 40px 0;
      text-align: left; } }
  .page.granddesign2039 .granddesign2039__about::before {
    content: "";
    position: absolute;
    left: 50%;
    top: -160px;
    z-index: -1;
    transform: translateX(-50%);
    display: block;
    width: 900px;
    height: 900px;
    background: url("/about/granddesign2039/img/bg_round1.png") no-repeat center center/contain; }
    @media (max-width: 767.98px) {
      .page.granddesign2039 .granddesign2039__about::before {
        top: -100px;
        width: 800px;
        height: 800px; } }

.page.granddesign2039 .granddesign2039__mission {
  position: relative;
  z-index: 1;
  padding: 60px 0;
  text-align: center; }
  @media (max-width: 767.98px) {
    .page.granddesign2039 .granddesign2039__mission {
      padding: 40px 0;
      text-align: left; } }
  .page.granddesign2039 .granddesign2039__mission::before {
    content: "";
    position: absolute;
    left: 50%;
    top: -600px;
    z-index: -1;
    transform: translateX(-50%);
    display: block;
    width: 1200px;
    max-width: 100%;
    height: 1200px;
    background: url("/about/granddesign2039/img/bg_round2.png") no-repeat center center/contain; }
    @media (max-width: 767.98px) {
      .page.granddesign2039 .granddesign2039__mission::before {
        top: -220px;
        width: 800px;
        max-width: initial;
        height: 800px; } }
  .page.granddesign2039 .granddesign2039__mission-list {
    padding: 0;
    list-style: none;
    counter-reset: number 0;
    border-top: 1px dotted #999; }
    .page.granddesign2039 .granddesign2039__mission-list_item {
      padding: 40px 0;
      border-bottom: 1px dotted #999; }
    .page.granddesign2039 .granddesign2039__mission-list_ttl {
      position: relative;
      margin-bottom: 20px;
      padding-top: 20px;
      font-family: "Noto Serif JP", "游明朝", "Hiragino Mincho ProN", "ヒラギノ明朝 W3 JIS2004", "Times New Roman", Times, serif;
      font-weight: 400;
      font-weight: bold;
      line-height: 1.8;
      text-align: center; }
      @media (min-width: 768px) {
        .page.granddesign2039 .granddesign2039__mission-list_ttl {
          font-size: 2rem; } }
      @media (max-width: 767.98px) {
        .page.granddesign2039 .granddesign2039__mission-list_ttl {
          font-size: 1.8rem; } }
      .page.granddesign2039 .granddesign2039__mission-list_ttl::before {
        position: absolute;
        left: 50%;
        top: -30px;
        transform: translateX(-50%);
        counter-increment: number 1;
        content: counter(number);
        font-family: "Crimson Text", Times, serif;
        font-weight: 400;
        color: #00abc0;
        font-style: italic;
        line-height: 1;
        opacity: .3; }
        @media (min-width: 768px) {
          .page.granddesign2039 .granddesign2039__mission-list_ttl::before {
            font-size: 10rem; } }
        @media (max-width: 767.98px) {
          .page.granddesign2039 .granddesign2039__mission-list_ttl::before {
            font-size: 10rem; } }
        @media (max-width: 767.98px) {
          .page.granddesign2039 .granddesign2039__mission-list_ttl::before {
            width: 60px; } }

.page.granddesign2039 .granddesign2039__vision {
  position: relative;
  z-index: 1;
  padding: 60px 0 0;
  text-align: center; }
  @media (max-width: 767.98px) {
    .page.granddesign2039 .granddesign2039__vision {
      padding: 40px 0 0;
      text-align: left; } }
  @media (max-width: 767.98px) {
    .page.granddesign2039 .granddesign2039__vision::before {
      content: "";
      position: absolute;
      left: 0;
      top: 0;
      z-index: -1;
      display: block;
      width: 100%;
      height: 3654px;
      background: url("/about/granddesign2039/img/bg_gradation2-sp.png") repeat-x center center/auto auto; } }
  .page.granddesign2039 .granddesign2039__vision-block {
    position: relative;
    z-index: 0;
    padding: 50px 0 60px; }
    @media (max-width: 767.98px) {
      .page.granddesign2039 .granddesign2039__vision-block {
        padding: 40px 0; } }
    .page.granddesign2039 .granddesign2039__vision-block::before {
      content: "";
      position: absolute;
      left: 0;
      top: 0;
      z-index: -1;
      width: 92.8%;
      height: 100%;
      background-image: linear-gradient(to right, white, rgba(255, 255, 255, 0) 98%); }
      @media (max-width: 767.98px) {
        .page.granddesign2039 .granddesign2039__vision-block::before {
          width: 100%; } }
    .page.granddesign2039 .granddesign2039__vision-block.-reverse::before {
      left: auto;
      right: 0;
      background-image: linear-gradient(to left, white, rgba(255, 255, 255, 0) 98%); }
    .page.granddesign2039 .granddesign2039__vision-block_ttl {
      font-family: "Noto Serif JP", "游明朝", "Hiragino Mincho ProN", "ヒラギノ明朝 W3 JIS2004", "Times New Roman", Times, serif;
      font-weight: 400;
      color: #004A98;
      font-weight: bold;
      line-height: 1.8;
      text-align: center; }
      @media (min-width: 768px) {
        .page.granddesign2039 .granddesign2039__vision-block_ttl {
          font-size: 2rem; } }
      @media (max-width: 767.98px) {
        .page.granddesign2039 .granddesign2039__vision-block_ttl {
          font-size: 1.8rem; } }

.page.granddesign2039 .granddesign2039__strategy {
  position: relative;
  z-index: 0;
  padding: 240px 0 60px;
  text-align: center; }
  @media (max-width: 767.98px) {
    .page.granddesign2039 .granddesign2039__strategy {
      z-index: 1;
      padding: 120px 0 0;
      text-align: left; } }
  .page.granddesign2039 .granddesign2039__strategy::before {
    content: "";
    position: absolute;
    left: 0;
    top: 100px;
    transform: translateY(-50%);
    z-index: -1;
    display: block;
    width: 100%;
    height: 3687px;
    background: url("/about/granddesign2039/img/bg_gradation2.png") repeat-x center center/auto auto; }
    @media (max-width: 767.98px) {
      .page.granddesign2039 .granddesign2039__strategy::before {
        content: none; } }
  .page.granddesign2039 .granddesign2039__strategy-inner {
    position: relative;
    z-index: 0; }
    .page.granddesign2039 .granddesign2039__strategy-inner::after {
      content: "";
      position: absolute;
      left: 50%;
      top: -300px;
      z-index: -1;
      transform: translateX(-50%);
      display: block;
      width: 900px;
      height: 900px;
      background: url("/about/granddesign2039/img/bg_round1.png") no-repeat center center/contain; }
      @media (max-width: 767.98px) {
        .page.granddesign2039 .granddesign2039__strategy-inner::after {
          top: -180px; } }
  @media (min-width: 768px) {
    .page.granddesign2039 .granddesign2039__strategy .granddesign2039__ttl-style1 {
      font-size: 9rem; } }
  @media (max-width: 767.98px) {
    .page.granddesign2039 .granddesign2039__strategy .granddesign2039__ttl-style1 {
      font-size: 6rem; } }
  .page.granddesign2039 .granddesign2039__strategy-nav {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    padding: 0;
    list-style: none; }
    @media (max-width: 767.98px) {
      .page.granddesign2039 .granddesign2039__strategy-nav li {
        width: 50%;
        padding: 2.66vw 0; } }
    .page.granddesign2039 .granddesign2039__strategy-nav li a {
      position: relative;
      display: flex;
      align-items: center;
      justify-content: center;
      width: 180px;
      height: 180px;
      background: #fff;
      border-radius: 100%;
      color: #004A98;
      font-weight: bold;
      line-height: 1.56;
      text-align: center;
      text-decoration: none; }
      @media (min-width: 768px) {
        .page.granddesign2039 .granddesign2039__strategy-nav li a {
          font-size: 2rem; } }
      @media (max-width: 767.98px) {
        .page.granddesign2039 .granddesign2039__strategy-nav li a {
          font-size: 2rem; } }
      @media (max-width: 767.98px) {
        .page.granddesign2039 .granddesign2039__strategy-nav li a {
          width: 40vw;
          height: 40vw;
          margin: 0 auto; } }
      .page.granddesign2039 .granddesign2039__strategy-nav li a::after {
        font-family: fontawesome;
        content: "\f107";
        position: absolute;
        left: 50%;
        bottom: 25px;
        transform: translateX(-50%);
        transition: .3s;
        line-height: 1; }
  .page.granddesign2039 .granddesign2039__strategy-wrapper {
    margin-top: -40px;
    padding-top: 100px; }
    @media (max-width: 767.98px) {
      .page.granddesign2039 .granddesign2039__strategy-wrapper {
        margin-top: 0px;
        padding-top: 40px; } }
    .page.granddesign2039 .granddesign2039__strategy-wrapper_ttl {
      margin: 0;
      padding: 25px;
      background-color: #004A98;
      color: #fff;
      text-align: center; }
      @media (min-width: 768px) {
        .page.granddesign2039 .granddesign2039__strategy-wrapper_ttl {
          font-size: 2rem; } }
      @media (max-width: 767.98px) {
        .page.granddesign2039 .granddesign2039__strategy-wrapper_ttl {
          font-size: 1.8rem; } }
      @media (max-width: 767.98px) {
        .page.granddesign2039 .granddesign2039__strategy-wrapper_ttl {
          padding: 15px; } }
  .page.granddesign2039 .granddesign2039__strategy-block {
    text-align: left;
    counter-reset: number 0;
    background: #fff; }
    .page.granddesign2039 .granddesign2039__strategy-block_item .accordion__trigger {
      padding-left: 32px;
      line-height: 1.6; }
    .page.granddesign2039 .granddesign2039__strategy-block_item[open] .accordion__trigger {
      background-color: #fff;
      color: #333; }
    .page.granddesign2039 .granddesign2039__strategy-block_item[open] .accordion__icon::before, .page.granddesign2039 .granddesign2039__strategy-block_item[open] .accordion__icon::after {
      background: #004A98; }
    .page.granddesign2039 .granddesign2039__strategy-block_item[open] .accordion__icon::before {
      transform: translateX(-50%) rotate(135deg); }
    .page.granddesign2039 .granddesign2039__strategy-block_item[open] .accordion__icon::after {
      transform: translateX(-50%) rotate(45deg); }
    .page.granddesign2039 .granddesign2039__strategy-block_ttl {
      position: relative;
      z-index: 0; }
      .page.granddesign2039 .granddesign2039__strategy-block_ttl::before {
        position: absolute;
        left: -5px;
        top: -15px;
        z-index: -1;
        width: 50px;
        counter-increment: number 1;
        content: counter(number);
        font-family: "Crimson Text", Times, serif;
        font-weight: 400;
        color: #D3D3D3;
        font-style: italic;
        line-height: 1;
        text-align: center; }
        @media (min-width: 768px) {
          .page.granddesign2039 .granddesign2039__strategy-block_ttl::before {
            font-size: 9rem; } }
        @media (max-width: 767.98px) {
          .page.granddesign2039 .granddesign2039__strategy-block_ttl::before {
            font-size: 6rem; } }
        @media (max-width: 767.98px) {
          .page.granddesign2039 .granddesign2039__strategy-block_ttl::before {
            left: -12px;
            top: -8px; } }

.page.granddesign2039 .granddesign2039__leaflet-bnr {
  display: block;
  background-color: #fff;
  line-height: 1; }
  .page.granddesign2039 .granddesign2039__leaflet-bnr a::after {
    content: none; }

.page.life .life-img_text {
  font-size: 1.6rem; }

.page.life .admissions__mv-btnlist_row {
  display: flex;
  gap: 30px;
  width: 100%; }
  @media (max-width: 767.98px) {
    .page.life .admissions__mv-btnlist_row {
      display: block; } }

.page.life .admissions__mv-btnlist_item {
  width: 100%; }

.page.life .admissions__mv-btnlist_btn a {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 80px;
  background: #fff;
  border: 1px solid #fff;
  font-weight: bold;
  line-height: 1.56;
  text-align: center; }
  @media (min-width: 768px) {
    .page.life .admissions__mv-btnlist_btn a {
      font-size: 1.8rem; } }
  @media (max-width: 767.98px) {
    .page.life .admissions__mv-btnlist_btn a {
      font-size: 1.6rem; } }
  .page.life .admissions__mv-btnlist_btn a::after {
    content: "";
    background-image: url(/img/common/arrow_next_black.svg);
    background-repeat: no-repeat;
    width: 15px;
    height: 7px;
    margin-left: 10px; }
  .page.life .admissions__mv-btnlist_btn a:hover {
    background: #004A98;
    border: 1px solid #004A98;
    color: #fff; }
    .page.life .admissions__mv-btnlist_btn a:hover::after {
      background-image: url(/img/common/arrow_next_white2.svg); }
  .page.life .admissions__mv-btnlist_btn a img {
    position: absolute;
    left: 30px;
    top: 50%;
    transform: translateY(-50%);
    display: inline-block;
    width: 50px; }

.page.life .admissions__linklist_box {
  display: flex;
  border-left: 3px solid #004A98; }
  @media (max-width: 767.98px) {
    .page.life .admissions__linklist_box {
      display: block;
      border-left: none; } }

.page.life .admissions__linklist_title {
  flex: 0 0 240px;
  font-weight: bold;
  padding-left: 15px;
  color: #004A98; }
  @media (min-width: 768px) {
    .page.life .admissions__linklist_title {
      font-size: 2rem; } }
  @media (max-width: 767.98px) {
    .page.life .admissions__linklist_title {
      font-size: 2rem; } }
  @media (max-width: 767.98px) {
    .page.life .admissions__linklist_title {
      border-bottom: 3px solid #004A98;
      margin-bottom: 30px; } }

.page.life .admissions__linklist_main {
  width: 100%; }

.page.life .admissions__linklist_list {
  display: flex;
  flex-wrap: wrap;
  width: 100%; }
  @media (max-width: 767.98px) {
    .page.life .admissions__linklist_list {
      display: block;
      border-left: none; } }
  .page.life .admissions__linklist_list .btn_style {
    width: calc(50% - 22.5px);
    margin-right: 45px !important;
    margin: 0;
    padding: 0;
    list-style: none; }
    @media (max-width: 767.98px) {
      .page.life .admissions__linklist_list .btn_style {
        width: 100%;
        margin-right: 0; } }
    .page.life .admissions__linklist_list .btn_style:nth-child(even) {
      margin-right: 0 !important; }

.r-pick__list {
  margin-bottom: 30px; }

.r-pick__item {
  list-style: none;
  margin-bottom: 40px; }
  .r-pick__item a {
    position: relative; }
    .r-pick__item a::after {
      position: absolute;
      right: 10px;
      bottom: 10px; }
      @media (max-width: 767.98px) {
        .r-pick__item a::after {
          right: 5px; } }

.r-pick__link {
  text-decoration: none;
  display: flex;
  align-items: center;
  background: #EFEFEF; }
  @media (max-width: 767.98px) {
    .r-pick__link {
      display: block; } }
  .r-pick__link figure {
    margin-bottom: 0;
    flex: 0 0 50%; }
    .r-pick__link figure img {
      width: 100%;
      min-height: 270px;
      object-fit: cover;
      aspect-ratio: 2/1; }

.r-pick__text_box {
  flex: 0 0 50%;
  padding: 30px 70px 30px 30px;
  position: relative; }
  @media (max-width: 767.98px) {
    .r-pick__text_box {
      padding: 20px 45px 20px 20px; } }
  .r-pick__text_box-head {
    border-bottom: 1px solid #999; }
    .r-pick__text_box-head .text {
      font-weight: bold; }
      @media (min-width: 768px) {
        .r-pick__text_box-head .text {
          font-size: 2.4rem; } }
      @media (max-width: 767.98px) {
        .r-pick__text_box-head .text {
          font-size: 2.4rem; } }
  .r-pick__text_box-bottom .gakubu {
    border-right: 1px solid #999;
    border-left: 1px solid #999;
    padding: 0 5px;
    margin: 0 5px; }
  .r-pick__text_box::after {
    content: "";
    background-image: url(/research_pickup/img/arrow.png);
    background-repeat: no-repeat;
    background-size: 100%;
    width: 45px;
    height: 45px;
    display: block;
    position: absolute;
    top: 50%;
    right: 0;
    transform: translateY(-50%); }
    @media (max-width: 767.98px) {
      .r-pick__text_box::after {
        width: 30px;
        height: 30px; } }

.r-pick.detail main.contents {
  padding-top: 130px !important; }
  @media (max-width: 767.98px) {
    .r-pick.detail main.contents {
      padding-top: 80px !important; } }

.r-pick.detail .r-pick__mv img {
  width: 100%; }

.r-pick.detail .r-pick__ttlbox {
  background: #fff;
  padding: 50px;
  max-width: 1200px;
  margin: -50px auto 0;
  position: relative;
  z-index: 1; }
  @media (max-width: 767.98px) {
    .r-pick.detail .r-pick__ttlbox {
      margin: -30px auto 0;
      padding: 20px;
      width: 90%; } }
  .r-pick.detail .r-pick__ttlbox .r-pick__text_box-bottom .name {
    display: block;
    font-weight: bold;
    color: #004A98; }
    @media (min-width: 768px) {
      .r-pick.detail .r-pick__ttlbox .r-pick__text_box-bottom .name {
        font-size: 1.8rem; } }
    @media (max-width: 767.98px) {
      .r-pick.detail .r-pick__ttlbox .r-pick__text_box-bottom .name {
        font-size: 1.6rem; } }
  .r-pick.detail .r-pick__ttlbox .r-pick__text_box-bottom .post-sys__date {
    color: #333;
    margin-right: 0px; }
    @media (min-width: 768px) {
      .r-pick.detail .r-pick__ttlbox .r-pick__text_box-bottom .post-sys__date {
        font-size: 1.6rem; } }
    @media (max-width: 767.98px) {
      .r-pick.detail .r-pick__ttlbox .r-pick__text_box-bottom .post-sys__date {
        font-size: 1.6rem; } }
  .r-pick.detail .r-pick__ttlbox .r-pick__text_box-bottom .gakubu {
    border-right: none; }

.r-pick.detail .r-pick__ttl {
  font-family: "Noto Serif JP", "游明朝", "Hiragino Mincho ProN", "ヒラギノ明朝 W3 JIS2004", "Times New Roman", Times, serif;
  font-weight: 400;
  font-weight: bold; }
  @media (min-width: 768px) {
    .r-pick.detail .r-pick__ttl {
      font-size: 3.4rem; } }
  @media (max-width: 767.98px) {
    .r-pick.detail .r-pick__ttl {
      font-size: 2.4rem; } }

.discover.graduate .discover_top_title span {
  display: block; }
  @media (min-width: 768px) {
    .discover.graduate .discover_top_title span {
      font-size: 3rem; } }
  @media (max-width: 767.98px) {
    .discover.graduate .discover_top_title span {
      font-size: 1.8rem; } }

.discover_menu .breadcrumb-item.active {
  color: #fff; }

.discover_menu .breadcrumb-item + .breadcrumb-item::before {
  color: #fff; }

.discover_menu .breadcrumb-item a {
  color: #fff; }

.discover_top {
  background: linear-gradient(#152740 0%, #004a98 100%);
  color: #fff;
  padding: 50px 0 150px; }
  @media (max-width: 767.98px) {
    .discover_top {
      padding: 50px 0 100px; } }
  .discover_top_title {
    margin: 0;
    font-family: "Crimson Text", Times, serif;
    font-weight: 400; }
    @media (min-width: 768px) {
      .discover_top_title {
        font-size: 12rem; } }
    @media (max-width: 767.98px) {
      .discover_top_title {
        font-size: 7rem; } }
  .discover_top_txt {
    margin: 60px 0 90px 0; }

.discover_main_wrap {
  width: 100%; }
  .discover_main_wrap .post-label-1 {
    margin-left: 10px; }
    @media (min-width: 768px) {
      .discover_main_wrap .post-label-1 {
        font-size: 1.4rem; } }
    @media (max-width: 767.98px) {
      .discover_main_wrap .post-label-1 {
        font-size: 1.2rem; } }

.discover_inner {
  width: 30%;
  background-color: #fff;
  margin-bottom: 50px; }
  .discover_inner:nth-child(3n+2) {
    margin-top: 70px; }
    @media (max-width: 767.98px) {
      .discover_inner:nth-child(3n+2) {
        margin-top: 0px; } }
  @media (max-width: 767.98px) {
    .discover_inner {
      width: 100%; } }
  .discover_inner_wrap {
    list-style: none;
    display: flex;
    justify-content: flex-start;
    flex-wrap: wrap;
    gap: 5%; }
    @media (max-width: 767.98px) {
      .discover_inner_wrap {
        display: block; } }
    .discover_inner_wrap .post-sys__date {
      color: #004A98; }
      @media (min-width: 768px) {
        .discover_inner_wrap .post-sys__date {
          font-size: 1.8rem; } }
      @media (max-width: 767.98px) {
        .discover_inner_wrap .post-sys__date {
          font-size: 1.6rem; } }
  .discover_inner a {
    text-decoration: none; }
  .discover_inner img {
    object-fit: cover;
    aspect-ratio: 3/4;
    width: 100%; }
  .discover_inner .page-imglink_box {
    position: relative; }
  .discover_inner .text-box {
    position: absolute;
    bottom: 0;
    padding: 20px 45px 20px 20px; }

.discover_list_title {
  padding-top: 10px;
  font-weight: bold; }
  @media (min-width: 768px) {
    .discover_list_title {
      font-size: 1.8rem; } }
  @media (max-width: 767.98px) {
    .discover_list_title {
      font-size: 1.6rem; } }

@media (min-width: 768px) {
  .discover_list_name {
    font-size: 1.4rem; } }

@media (max-width: 767.98px) {
  .discover_list_name {
    font-size: 1.4rem; } }

.discover_detail_title .post-sys__date {
  color: #333 !important;
  border-left: 1px solid #999;
  margin-left: 10px;
  padding-left: 10px; }

.discover_detail_name {
  color: #004A98;
  font-weight: bold;
  display: inline-block; }
  @media (min-width: 768px) {
    .discover_detail_name {
      font-size: 2.4rem; } }
  @media (max-width: 767.98px) {
    .discover_detail_name {
      font-size: 1.8rem; } }

.discover_detail_mv {
  position: relative;
  padding-bottom: 115px;
  margin-top: 40px; }
  @media (max-width: 767.98px) {
    .discover_detail_mv {
      margin-top: 20px;
      padding-bottom: 60px; } }
  .discover_detail_mv::before {
    content: "Discover";
    position: absolute;
    right: 30px;
    bottom: 0;
    font-family: "Crimson Text", Times, serif;
    font-weight: 400;
    color: #fff;
    z-index: 1;
    line-height: 0.7;
    opacity: .6; }
    @media (min-width: 768px) {
      .discover_detail_mv::before {
        font-size: 14rem; } }
    @media (max-width: 767.98px) {
      .discover_detail_mv::before {
        font-size: 7rem; } }
  .discover_detail_mv::after {
    content: "";
    width: 100%;
    height: 230px;
    background: linear-gradient(#004a98 0%, #152740 100%);
    display: block;
    position: absolute;
    bottom: 0;
    z-index: 0; }
    @media (max-width: 767.98px) {
      .discover_detail_mv::after {
        height: 135px; } }
  .discover_detail_mv-inner {
    max-width: 1300px;
    margin: 0 auto;
    position: relative;
    z-index: 1; }

.discover_category {
  background: #fff;
  box-shadow: 0px 5px 15px 0px rgba(0, 0, 0, 0.35);
  margin-top: -130px;
  padding: 30px;
  margin-bottom: 100px;
  border-bottom: 5px solid #004A98; }
  .discover_category_item {
    margin-bottom: 30px; }
    @media (max-width: 767.98px) {
      .discover_category_item {
        margin-bottom: 15px; } }
    .discover_category_item:last-of-type {
      margin-bottom: 0; }
    .discover_category_item select {
      width: 100%;
      border: 1px solid #004A98;
      padding: 10px 0; }
  .discover_category_title {
    font-family: "Noto Serif JP", "游明朝", "Hiragino Mincho ProN", "ヒラギノ明朝 W3 JIS2004", "Times New Roman", Times, serif;
    font-weight: 400;
    color: #004A98;
    font-weight: bold; }
    @media (min-width: 768px) {
      .discover_category_title {
        font-size: 2rem; } }
    @media (max-width: 767.98px) {
      .discover_category_title {
        font-size: 1.8rem; } }
  .discover_category_list {
    list-style: none; }
    .discover_category_list li {
      display: inline-block;
      border: 1px solid #004A98;
      margin-bottom: 5px; }
      .discover_category_list li a {
        display: block;
        text-decoration: none;
        color: #004A98;
        padding: 3px 10px; }
        .discover_category_list li a:hover {
          text-decoration: none; }
      .discover_category_list li.active {
        background-color: #004A98; }
        .discover_category_list li.active a {
          color: #fff; }
  .discover_category_all a {
    background: #004A98;
    color: #fff;
    padding: 10px;
    text-decoration: none;
    max-width: 200px;
    margin: 0 auto;
    display: block;
    text-align: center; }
    .discover_category_all a:hover {
      text-decoration: none; }

body.this-is.fixed {
  overflow: hidden; }

.this-is .cookie {
  display: none !important; }

.mfp-bg.mfp-ready {
  opacity: 1;
  -webkit-backdrop-filter: blur(10px);
  backdrop-filter: blur(10px);
  -webkit-filter: brightness(1.4);
  filter: brightness(1.4);
  background: rgba(255, 255, 255, 0.3); }
  @media (max-width: 767.98px) {
    .mfp-bg.mfp-ready {
      filter: none; } }

.mfp-bg {
  opacity: 0;
  transition: opacity .5s; }

.mfp-content {
  width: 90%;
  background: #fff;
  max-width: 1200px;
  margin: 0 auto;
  padding: 100px;
  position: relative; }
  @media (max-width: 767.98px) {
    .mfp-content {
      position: static !important;
      padding: 30px !important;
      margin: 30px 0 70px !important; } }
  .mfp-content::after {
    content: "";
    background-image: url("/about/features/img/modal-text.svg");
    background-size: 30%;
    background-repeat: no-repeat;
    width: 100%;
    height: 58px;
    display: block;
    position: absolute;
    bottom: 20px;
    left: 20px;
    margin-top: 20px; }
    @media (max-width: 767.98px) {
      .mfp-content::after {
        position: static !important;
        background-size: 90% !important;
        margin-top: 40px !important; } }

.mfp-container {
  overflow: auto; }

.mfp-close {
  border-radius: 50%;
  box-shadow: 3px 3px 7px 1px rgba(0, 0, 0, 0.5) !important;
  margin-right: 20px;
  margin-top: 20px;
  opacity: 1;
  color: #B1B1B1 !important; }
  .mfp-close:hover {
    opacity: 1; }
  .mfp-close-text {
    display: block;
    font-size: 14px;
    color: #B1B1B1;
    font-family: "EB Garamond", "Noto Serif JP", "游明朝", "Hiragino Mincho ProN", "ヒラギノ明朝 W3 JIS2004", "Times New Roman", Times, serif;
    border-bottom: 1px solid #B1B1B1;
    line-height: 1.6;
    margin-top: 5px; }

@media (max-width: 767.98px) {
  .close-btn {
    cursor: pointer;
    width: 100%;
    border-bottom: 1px solid #333;
    padding: 10px 10px 10px 0;
    position: relative; }
    .close-btn::after {
      content: "×";
      position: absolute;
      right: 0; } }

.popup-modal__box {
  display: flex;
  gap: 30px; }
  @media (max-width: 767.98px) {
    .popup-modal__box {
      display: block !important; } }

.popup-modal__item {
  flex: 0 0 50%; }
  .popup-modal__item.img-center {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center; }
  .popup-modal__item p {
    text-align: justify; }

.popup-modal__title {
  font-weight: bold; }
  @media (min-width: 768px) {
    .popup-modal__title {
      font-size: 3rem; } }
  @media (max-width: 767.98px) {
    .popup-modal__title {
      font-size: 2.4rem; } }
  .popup-modal__title span {
    background-color: #333;
    color: #fff;
    padding: 3px 5px;
    vertical-align: text-bottom;
    margin-right: 10px; }
    @media (min-width: 768px) {
      .popup-modal__title span {
        font-size: 2rem; } }
    @media (max-width: 767.98px) {
      .popup-modal__title span {
        font-size: 1.8rem; } }
    @media (max-width: 767.98px) {
      .popup-modal__title span {
        vertical-align: inherit !important; } }
  .popup-modal__title-2 {
    margin-bottom: 0px; }
    @media (min-width: 768px) {
      .popup-modal__title-2 {
        font-size: 2rem; } }
    @media (max-width: 767.98px) {
      .popup-modal__title-2 {
        font-size: 1.8rem; } }

.popup-sp-btn a {
  color: #333;
  font-weight: bold;
  border-bottom: 1px solid #333;
  padding-bottom: 14px;
  padding-top: 14px;
  text-decoration: none;
  width: 100%;
  display: block; }
  .popup-sp-btn a span {
    background: #333;
    color: #fff;
    padding: 3px 5px; }

.popup-sp-btn:first-child a {
  border-top: 1px solid #333; }

.popup-sp-btn-block {
  margin-top: 40px; }

.section[data-anchor="page-2"] .section2-human-base {
  bottom: -50px !important;
  transition: 1.5s;
  opacity: 0; }

.section[data-anchor="page-2"].active .section2-human-base {
  bottom: 0px !important;
  opacity: 1; }

.section[data-anchor="page-3"] .section3-human-base {
  right: 150px !important;
  transition: 1.5s;
  opacity: 0; }

.section[data-anchor="page-3"].active .section3-human-base {
  right: 200px !important;
  opacity: 1; }

.section[data-anchor="page-4"] .section4-horse-base {
  right: -500px !important;
  transition: 2s; }

.section[data-anchor="page-4"].active .section4-horse-base {
  right: -130px !important; }

.section[data-anchor="page-5"]::before {
  content: "";
  height: 100vh;
  width: 45%;
  display: block;
  background: #000;
  z-index: 1;
  position: absolute;
  background: linear-gradient(to left, rgba(0, 0, 0, 0) 0%, black 130%); }

.section[data-anchor="page-5"] .this-is__section5 {
  background-color: rgba(255, 255, 255, 0.8) !important;
  background-blend-mode: lighten !important;
  transition: 5s; }

.section[data-anchor="page-5"] .section5-title {
  z-index: 2;
  text-shadow: 0px 3px 6px rgba(0, 0, 0, 0.8); }

.section[data-anchor="page-5"].active .this-is__section5 {
  background-color: rgba(255, 255, 255, 0) !important; }

.section[data-anchor="page-6"] .section6-human-base {
  bottom: -50px !important;
  transition: 2s;
  opacity: 0; }

.section[data-anchor="page-6"].active .section6-human-base {
  bottom: 0px !important;
  opacity: 1; }

@media (max-width: 767.98px) {
  .contents {
    overflow: hidden; } }

.fd-left {
  transform: translateX(-50px);
  opacity: 0;
  transition: 2s;
  transition-delay: 2s; }
  @media (max-width: 767.98px) {
    .fd-left {
      transform: none;
      opacity: 1; } }

.fd-bottom {
  transform: translateY(50px);
  opacity: 0;
  transition: 2s;
  transition-delay: 2s; }
  @media (max-width: 767.98px) {
    .fd-bottom {
      transform: none;
      opacity: 1; } }

.this-is {
  animation: fadeIn 4s ease 0s 1 normal; }

@keyframes fadeIn {
  0% {
    opacity: 0; }
  100% {
    opacity: 1; } }
  .this-is .contents_logo {
    display: none; }
  .this-is .this-is_logo {
    display: block !important; }
  @media (min-width: 768px) {
    .this-is .gheader__inner.contents-header {
      background: none; } }
  @media (min-width: 768px) {
    .this-is .gheader__inner.contents-header a, .this-is .gheader__inner.contents-header p {
      color: #fff; } }
  @media (min-width: 768px) {
    .this-is .gheader.nav-active .gheader__megamenu {
      background: none; } }
  @media (min-width: 768px) {
    .this-is .gheader.nav-active .gheader__megamenu-list {
      background: none; } }
  @media (min-width: 768px) {
    .this-is .gheader.nav-active .gheader__megamenu-list .gheader__megamenu-link {
      color: #fff; } }
  @media (min-width: 768px) {
    .this-is .gheader.nav-active .gheader__megamenu-list .gheader__megamenu-link::before, .this-is .gheader.nav-active .gheader__megamenu-list .gheader__megamenu-link::after {
      background-color: #fff; } }
  .this-is .gheader__contents {
    background: linear-gradient(to top, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0.8) 90%); }
    .this-is .gheader__contents .gheader__megamenu-link, .this-is .gheader__contents .gheader__common-link {
      text-shadow: 2px 2px 3px rgba(0, 0, 0, 0.8); }
      @media (max-width: 767.98px) {
        .this-is .gheader__contents .gheader__megamenu-link, .this-is .gheader__contents .gheader__common-link {
          text-shadow: none; } }
      .this-is .gheader__contents .gheader__megamenu-link.sp-aclink, .this-is .gheader__contents .gheader__common-link.sp-aclink {
        text-shadow: none; }
  .this-is .contents {
    padding: 0 !important; }
  .this-is .fullpage__navi {
    position: fixed;
    right: 0px;
    top: 50%;
    transform: translateY(-50%);
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding-top: 105px; }
    .this-is .fullpage__navi ul:not([class]) > li::before {
      content: none; }
    @media (max-width: 767.98px) {
      .this-is .fullpage__navi {
        transform: none;
        top: auto;
        bottom: 100px;
        height: auto;
        right: 10px;
        z-index: 3; } }
    .this-is .fullpage__navi::after {
      content: "";
      background: linear-gradient(to right, rgba(0, 0, 0, 0), rgba(0, 0, 0, 0.5));
      height: 100%;
      display: block;
      top: 0;
      bottom: 0;
      right: 0;
      position: absolute;
      width: 150px;
      z-index: -1; }
      @media (max-width: 767.98px) {
        .this-is .fullpage__navi::after {
          content: none; } }
    .this-is .fullpage__navi li {
      margin-bottom: 25px;
      list-style: none; }
      @media (max-width: 767.98px) {
        .this-is .fullpage__navi li {
          margin: 12px; } }
      .this-is .fullpage__navi li:last-child {
        margin-bottom: 0; }
      .this-is .fullpage__navi li a {
        color: #fff;
        text-decoration: none;
        display: flex;
        justify-content: end;
        align-items: center;
        font-family: "Noto Serif JP", "游明朝", "Hiragino Mincho ProN", "ヒラギノ明朝 W3 JIS2004", "Times New Roman", Times, serif;
        font-weight: 400;
        font-weight: bold; }
        .this-is .fullpage__navi li a::after {
          content: "";
          width: 30px;
          height: 2px;
          background: #fff;
          margin-left: 5px; }
          @media (max-width: 767.98px) {
            .this-is .fullpage__navi li a::after {
              content: "";
              width: 5px;
              height: 5px;
              border-radius: 50%;
              background-color: #CCC; } }
      @media (min-width: 768px) {
        .this-is .fullpage__navi li.active a {
          font-size: 2rem; } }
      @media (max-width: 767.98px) {
        .this-is .fullpage__navi li.active a {
          font-size: 2rem; } }
      .this-is .fullpage__navi li.active a::after {
        height: 6px; }
      @media (max-width: 767.98px) {
        .this-is .fullpage__navi li a.active::after {
          background-color: #004A98; } }
  .this-is.fp-viewing-page-3 .fullpage__navi::after {
    content: none; }
  .this-is.fp-viewing-page-7 .fullpage__navi {
    display: none; }
  .this-is .path {
    position: absolute;
    z-index: 5; }
    @media (max-width: 767.98px) {
      .this-is .path {
        position: static; } }
    .this-is .path a {
      text-decoration: none; }
    .this-is .path-inner {
      position: absolute;
      top: 0;
      bottom: 0;
      text-decoration: none;
      display: flex;
      flex-direction: column;
      justify-content: center;
      background: #fff;
      border-radius: 50%;
      position: absolute;
      z-index: 10;
      font-weight: bold;
      text-align: center;
      line-height: 1;
      text-decoration: none; }
      @media (max-width: 767.98px) {
        .this-is .path-inner {
          position: static; } }
      .this-is .path-inner a {
        height: 100%;
        display: flex;
        flex-direction: column;
        justify-content: center; }
  .this-is .section {
    position: relative; }
    .this-is .section-inner {
      overflow: hidden; }
      .this-is .section-inner.bg-none::after {
        transform: translate(0, -100%); }
    @media (min-width: 768px) {
      .this-is .section-link .fz-small {
        font-size: 1.4rem; } }
    @media (max-width: 767.98px) {
      .this-is .section-link .fz-small {
        font-size: 1.4rem; } }
    .this-is .section-link .fz-large {
      line-height: 1.4; }
      @media (min-width: 768px) {
        .this-is .section-link .fz-large {
          font-size: 1.8rem; } }
      @media (max-width: 767.98px) {
        .this-is .section-link .fz-large {
          font-size: 1.8rem; } }
    .this-is .section-link-btn {
      font-family: "EB Garamond", "Noto Serif JP", "游明朝", "Hiragino Mincho ProN", "ヒラギノ明朝 W3 JIS2004", "Times New Roman", Times, serif;
      color: #CCC;
      text-decoration: underline; }
      @media (min-width: 768px) {
        .this-is .section-link-btn {
          font-size: 1.4rem; } }
      @media (max-width: 767.98px) {
        .this-is .section-link-btn {
          font-size: 1.4rem; } }
    .this-is .section1-path-1 {
      clip-path: url(#p-homeSubMask1);
      top: 55vh;
      right: 15vw;
      width: 180px;
      height: 180px; }
      .this-is .section1-path-1-inner {
        width: 180px;
        height: 180px; }
    .this-is .section1-path-2 {
      clip-path: url(#p-homeSubMask2);
      bottom: 100px;
      left: 28vw;
      width: 150px;
      height: 150px; }
      @media screen and (max-height: 770px) {
        .this-is .section1-path-2 {
          bottom: 50px; } }
      .this-is .section1-path-2-inner {
        width: 150px;
        height: 150px; }
    .this-is .section1-path-3 {
      clip-path: url(#p-homeSubMask3);
      width: 160px;
      height: 160px;
      bottom: 5vw;
      right: 30vw; }
      .this-is .section1-path-3-inner {
        width: 160px;
        height: 160px; }
    .this-is .section2-path-1 {
      clip-path: url(#p-homeSubMask1);
      top: 18vh;
      left: 39vw;
      width: 180px;
      height: 180px; }
      @media screen and (max-width: 1120px) {
        .this-is .section2-path-1 {
          top: auto;
          left: 35vw;
          bottom: 5vh; } }
      @media screen and (max-height: 650px) {
        .this-is .section2-path-1 {
          top: auto;
          left: 35vw;
          bottom: 5vh; } }
      .this-is .section2-path-1-inner {
        width: 180px;
        height: 180px; }
    .this-is .section2-path-2 {
      clip-path: url(#p-homeSubMask2);
      bottom: 50px;
      left: 40vw;
      width: 150px;
      height: 150px; }
      @media screen and (max-width: 1120px) {
        .this-is .section2-path-2 {
          left: 10vw; } }
      @media screen and (max-height: 650px) {
        .this-is .section2-path-2 {
          left: 10vw; } }
      .this-is .section2-path-2-inner {
        width: 150px;
        height: 150px; }
    .this-is .section2-path-3 {
      clip-path: url(#p-homeSubMask3);
      width: 160px;
      height: 160px;
      top: 70vh;
      right: 20vw; }
      @media only screen and (min-device-width: 768px) and (max-device-width: 1024px) {
        .this-is .section2-path-3 {
          top: 55vh !important; } }
      .this-is .section2-path-3-inner {
        width: 160px;
        height: 160px; }
    .this-is .section3-path-1 {
      clip-path: url(#p-homeSubMask1);
      top: 35vh;
      right: 17vw;
      width: 160px;
      height: 160px; }
      .this-is .section3-path-1-inner {
        width: 160px;
        height: 160px; }
    .this-is .section3-path-2 {
      clip-path: url(#p-homeSubMask2);
      top: 67vh;
      left: 15vw;
      width: 150px;
      height: 150px; }
      .this-is .section3-path-2-inner {
        width: 150px;
        height: 150px; }
    .this-is .section3-path-3 {
      clip-path: url(#p-homeSubMask3);
      width: 180px;
      height: 180px;
      top: 75vh;
      right: 10vw; }
      .this-is .section3-path-3-inner {
        width: 180px;
        height: 180px; }
    .this-is .section4-path-1 {
      clip-path: url(#p-homeSubMask1);
      top: 25vh;
      right: 36vw;
      width: 180px;
      height: 180px; }
      .this-is .section4-path-1-inner {
        width: 180px;
        height: 180px; }
    .this-is .section4-path-2 {
      clip-path: url(#p-homeSubMask2);
      top: 65vh;
      left: 35vw;
      width: 160px;
      height: 160px; }
      .this-is .section4-path-2-inner {
        width: 160px;
        height: 160px; }
    .this-is .section4-path-3 {
      clip-path: url(#p-homeSubMask3);
      width: 150px;
      height: 150px;
      top: 35vh;
      right: 15vw; }
      @media screen and (max-height: 670px) {
        .this-is .section4-path-3 {
          top: 28vh; } }
      .this-is .section4-path-3-inner {
        width: 150px;
        height: 150px; }
    .this-is .section5-path-1 {
      clip-path: url(#p-homeSubMask1);
      top: 20vh;
      transform: translateX(-50%) !important;
      left: 50%;
      width: 180px;
      height: 180px; }
      .this-is .section5-path-1-inner {
        width: 180px;
        height: 180px; }
    .this-is .section5-path-2 {
      clip-path: url(#p-homeSubMask2);
      bottom: 5vh;
      left: 30vw;
      width: 160px;
      height: 160px; }
      .this-is .section5-path-2-inner {
        width: 160px;
        height: 160px; }
    .this-is .section5-path-3 {
      clip-path: url(#p-homeSubMask3);
      width: 150px;
      height: 150px;
      bottom: 10vw;
      right: 15vw; }
      .this-is .section5-path-3-inner {
        width: 150px;
        height: 150px; }
    .this-is .section6-path-1 {
      clip-path: url(#p-homeSubMask1);
      top: 20vh;
      right: 25vw;
      width: 180px;
      height: 180px; }
      @media only screen and (min-device-width: 768px) and (max-device-width: 1024px) {
        .this-is .section6-path-1 {
          right: 40vw !important; } }
      @media screen and (max-height: 815px) {
        .this-is .section6-path-1 {
          right: auto;
          left: 30vw; } }
      @media screen and (max-width: 1165px) {
        .this-is .section6-path-1 {
          right: 25vw;
          left: auto; } }
      .this-is .section6-path-1-inner {
        width: 180px;
        height: 180px; }
    .this-is .section6-path-2 {
      clip-path: url(#p-homeSubMask2);
      bottom: 2vh;
      left: 23vw;
      width: 180px;
      height: 180px; }
      @media only screen and (min-device-width: 768px) and (max-device-width: 1024px) {
        .this-is .section6-path-2 {
          bottom: 20vh !important;
          left: 18vw !important; } }
      @media screen and (max-height: 700px) and (max-width: 1400px) {
        .this-is .section6-path-2 {
          bottom: 1vh;
          top: auto;
          right: auto;
          left: 10vw; } }
      .this-is .section6-path-2-inner {
        width: 180px;
        height: 180px; }
    .this-is .section6-path-3 {
      clip-path: url(#p-homeSubMask3);
      width: 180px;
      height: 180px;
      bottom: 2vw;
      right: 39vw; }
      @media screen and (max-height: 700px) and (max-width: 1400px) {
        .this-is .section6-path-3 {
          bottom: 1vh;
          top: auto;
          right: auto;
          left: 29vw; } }
      .this-is .section6-path-3-inner {
        width: 180px;
        height: 180px; }
  .this-is__title {
    font-family: "Shippori Mincho B1", "Noto Serif JP", "游明朝", "Hiragino Mincho ProN", "ヒラギノ明朝 W3 JIS2004", "Times New Roman", Times, serif;
    margin-bottom: 30px; }
    @media (min-width: 768px) {
      .this-is__title {
        font-size: 4.2rem; } }
    @media (max-width: 767.98px) {
      .this-is__title {
        font-size: 3rem; } }
    @media (min-width: 768px) {
      .this-is__title span {
        font-size: 5.5rem; } }
    @media (max-width: 767.98px) {
      .this-is__title span {
        font-size: 4rem; } }
    .this-is__title-box {
      position: absolute;
      top: 50%;
      transform: translateY(-50%);
      left: 60px; }
      @media (max-width: 767.98px) {
        .this-is__title-box {
          position: relative;
          background: #fff;
          background: linear-gradient(rgba(255, 255, 255, 0) 0, #fff 15%);
          padding: 170px 40px 200px 20px;
          top: -225px !important;
          transform: none; } }
    .this-is__title-text {
      font-family: "Shippori Mincho B1", "Noto Serif JP", "游明朝", "Hiragino Mincho ProN", "ヒラギノ明朝 W3 JIS2004", "Times New Roman", Times, serif; }
      @media (min-width: 768px) {
        .this-is__title-text {
          font-size: 1.6rem; } }
      @media (max-width: 767.98px) {
        .this-is__title-text {
          font-size: 1.6rem; } }
  .this-is__section1 {
    background-image: url("/about/features/img/section-1-bg.jpg");
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center center;
    width: 100%;
    height: 100vh;
    display: block;
    position: relative; }
    @media (max-width: 767.98px) {
      .this-is__section1 {
        position: static;
        height: auto;
        margin-bottom: -225px; } }
    .this-is__section1 .section1-title {
      color: #fff;
      position: relative;
      z-index: 3;
      top: 50%;
      transform: translateY(-50%);
      left: 60px; }
      @media (max-width: 767.98px) {
        .this-is__section1 .section1-title {
          color: #333;
          top: auto;
          transform: none;
          left: auto; } }
    .this-is__section1 .section1-bgtext {
      position: absolute;
      left: 62%;
      top: 65%;
      transform: translate(-50%, -50%); }
      @media screen and (min-width: 1500px) {
        .this-is__section1 .section1-bgtext {
          left: 62%; } }
      @media (max-width: 767.98px) {
        .this-is__section1 .section1-bgtext {
          top: 150px;
          transform: translateX(-50%);
          width: 115%;
          left: 50%; } }
      .this-is__section1 .section1-bgtext img {
        max-width: none; }
        @media (max-width: 767.98px) {
          .this-is__section1 .section1-bgtext img {
            max-width: 100%;
            width: 100%; } }
    .this-is__section1 .section1-mori-op {
      position: absolute;
      z-index: 10;
      transition: 5s;
      transition-delay: 0.5s; }
      .this-is__section1 .section1-mori-op img {
        width: 100%;
        height: 100vh; }
      .this-is__section1 .section1-mori-op.right {
        transform: translate(0);
        right: 0; }
      .this-is__section1 .section1-mori-op.left {
        transform: translate(0);
        left: 0; }
    .this-is__section1 .section1-mori-base {
      position: absolute;
      z-index: 1;
      bottom: 0;
      width: 100%; }
      @media (max-width: 767.98px) {
        .this-is__section1 .section1-mori-base {
          position: relative;
          margin-top: 200px;
          bottom: auto;
          margin-top: 70px;
          display: block; } }
      .this-is__section1 .section1-mori-base img {
        object-fit: cover;
        width: 100%;
        height: 100vh; }
        @media (max-width: 767.98px) {
          .this-is__section1 .section1-mori-base img {
            object-fit: contain;
            width: 100%;
            height: 560px; } }
    .this-is__section1 .section1-mori-1 {
      position: absolute;
      z-index: 2;
      bottom: 0;
      transform: translateX(-50px);
      opacity: 0;
      transition: 2s; }
      @media (max-width: 767.98px) {
        .this-is__section1 .section1-mori-1 {
          top: 105vw;
          left: 0;
          width: 40%; } }
      .this-is__section1 .section1-mori-1 img {
        max-width: none; }
        @media (max-width: 767.98px) {
          .this-is__section1 .section1-mori-1 img {
            max-width: 100%; } }
    .this-is__section1 .section1-mori-2 {
      position: absolute;
      z-index: 2;
      bottom: 0;
      right: 0;
      transform: translateX(50px);
      opacity: 0;
      transition: 2s; }
      @media (max-width: 767.98px) {
        .this-is__section1 .section1-mori-2 {
          top: 105vw;
          right: 0;
          width: 40%; } }
      .this-is__section1 .section1-mori-2 img {
        max-width: none; }
        @media (max-width: 767.98px) {
          .this-is__section1 .section1-mori-2 img {
            max-width: 100%; } }
  .this-is__section2 {
    background-image: url("/about/features/img/section-2-bg.jpg");
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center center;
    width: 100%;
    height: 100vh;
    display: block;
    position: relative; }
    @media (max-width: 767.98px) {
      .this-is__section2 {
        background-image: url("/about/features/img/section-2-bg-sp.jpg");
        background-size: contain;
        background-position: top;
        position: static;
        height: auto;
        margin-bottom: -100px; } }
    .this-is__section2::after {
      background: #E8BD2F; }
    .this-is__section2 .section2-human-base {
      position: absolute;
      z-index: 1;
      right: 0;
      bottom: 0;
      margin-left: 257px;
      max-width: 1300px; }
      @media (max-width: 767.98px) {
        .this-is__section2 .section2-human-base {
          position: static;
          margin-left: 0px;
          margin-top: 100px;
          width: 150%; } }
    .this-is__section2 .section2-bgtext {
      position: absolute;
      left: 0; }
      @media (max-width: 767.98px) {
        .this-is__section2 .section2-bgtext {
          position: static; } }
      .this-is__section2 .section2-bgtext img {
        max-width: none;
        width: 100vh; }
        @media (min-width: 768px) {
          .this-is__section2 .section2-bgtext img {
            position: relative;
            left: -85px; } }
      .this-is__section2 .section2-bgtext figure {
        position: relative; }
      @media (max-width: 767.98px) {
        .this-is__section2 .section2-bgtext .section2-title {
          top: -100px !important;
          left: 0; } }
  .this-is__section3 {
    background-image: url("/about/features/img/section-3-bg.jpg");
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center center;
    width: 100%;
    height: 100vh;
    display: block;
    position: relative; }
    @media (max-width: 767.98px) {
      .this-is__section3 {
        background-image: url("/about/features/img/section-3-bg-sp.jpg");
        background-size: contain;
        background-position: top;
        position: static;
        height: auto;
        margin-bottom: -85px; } }
    .this-is__section3::after {
      background: #3278C6; }
    .this-is__section3 .section3-title {
      z-index: 1; }
      .this-is__section3 .section3-title-inner {
        position: relative; }
        @media (max-width: 767.98px) {
          .this-is__section3 .section3-title-inner {
            position: static; } }
        .this-is__section3 .section3-title-inner::after {
          content: "";
          background-image: url("/about/features/img/section-3-textbg.png");
          background-size: contain;
          background-repeat: no-repeat;
          width: 700px;
          height: 640px;
          display: block;
          position: absolute;
          top: 50%;
          left: 26%;
          transform: translate(-50%, -50%);
          z-index: -1; }
    .this-is__section3 .section3-bgtext {
      position: absolute;
      right: 0;
      transform: translateX(145px);
      min-width: 850px; }
      @media screen and (max-width: 1330px) {
        .this-is__section3 .section3-bgtext {
          transform: translateX(500px); } }
      @media screen and (max-height: 720px) {
        .this-is__section3 .section3-bgtext {
          transform: translateX(400px); } }
      .this-is__section3 .section3-bgtext img {
        height: 100vh; }
        @media only screen and (min-device-width: 768px) and (max-device-width: 1024px) {
          .this-is__section3 .section3-bgtext img {
            height: 100vh; } }
        @media screen and (max-height: 720px) {
          .this-is__section3 .section3-bgtext img {
            height: 100%; } }
      .this-is__section3 .section3-bgtext figure {
        position: relative; }
    .this-is__section3 .section3-human-base {
      position: absolute;
      bottom: 0;
      z-index: 1; }
      @media screen and (max-height: 720px) {
        .this-is__section3 .section3-human-base {
          width: 600px; } }
      @media screen and (max-width: 1330px) {
        .this-is__section3 .section3-human-base {
          width: 600px; } }
      @media (max-width: 767.98px) {
        .this-is__section3 .section3-human-base {
          width: auto; } }
      @media (max-width: 767.98px) {
        .this-is__section3 .section3-human-base {
          position: static;
          margin-top: 100px; } }
    @media (max-width: 767.98px) {
      .this-is__section3 .section3-title {
        top: -135px !important;
        left: 0; } }
  .this-is__section4 {
    background-image: url("/about/features/img/section-4-bg.jpg");
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center center;
    width: 100%;
    height: 100vh;
    display: block;
    position: relative; }
    @media (max-width: 767.98px) {
      .this-is__section4 {
        background-image: url("/about/features/img/section-4-bg-sp.jpg");
        background-size: contain;
        background-position: top;
        position: static;
        height: auto;
        margin-bottom: -135px; } }
    .this-is__section4::after {
      background: #33A17D; }
    .this-is__section4 .section4-horse-base {
      position: absolute;
      z-index: 1;
      right: -130px;
      bottom: 0; }
      @media (max-width: 767.98px) {
        .this-is__section4 .section4-horse-base {
          position: static;
          margin-top: 350px;
          margin-right: -100px; } }
    .this-is__section4 .section4-bgtext {
      position: absolute;
      left: 0;
      top: 53%;
      transform: translateY(-50%);
      color: #fff; }
      @media (max-width: 767.98px) {
        .this-is__section4 .section4-bgtext {
          position: static;
          transform: none;
          color: #333; } }
      .this-is__section4 .section4-bgtext img {
        max-width: none; }
      .this-is__section4 .section4-bgtext figure {
        position: relative; }
    @media (max-width: 767.98px) {
      .this-is__section4 .section4-title {
        top: -135px !important;
        left: 0; } }
  .this-is__section5 {
    background-image: url("/about/features/img/section-5-bg.jpg");
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center center;
    width: 100%;
    height: 100vh;
    display: block;
    position: relative; }
    @media (max-width: 767.98px) {
      .this-is__section5 {
        background-image: url("/about/features/img/section-5-bg-sp.jpg");
        background-size: contain;
        background-position: top;
        position: static;
        height: auto;
        margin-bottom: 0px; } }
    .this-is__section5::after {
      background: #8D8D8D; }
    .this-is__section5 .section5-title {
      color: #fff; }
      @media (max-width: 767.98px) {
        .this-is__section5 .section5-title {
          color: #333; } }
    .this-is__section5 .section5-block {
      display: flex; }
    @media (max-width: 767.98px) {
      .this-is__section5 .section5-title {
        top: 0px !important;
        left: 0;
        margin-top: 400px; } }
    .this-is__section5 .section5-title .this-is__title img {
      display: block; }
  .this-is__section6 {
    background-image: url("/about/features/img/section-6-bg.jpg");
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center center;
    width: 100%;
    height: 100vh;
    display: block;
    position: relative; }
    @media (max-width: 767.98px) {
      .this-is__section6 {
        position: static;
        height: auto;
        background-image: url("/about/features/img/section-6-bg-sp.jpg");
        background-size: contain;
        background-position: top;
        margin-bottom: -225px; } }
    .this-is__section6 .section6-human-base {
      position: absolute;
      z-index: 1;
      right: 0;
      bottom: 0;
      margin-left: 200px; }
      @media only screen and (min-device-width: 768px) and (max-device-width: 1024px) {
        .this-is__section6 .section6-human-base {
          margin-left: 0px !important; } }
      @media (max-width: 767.98px) {
        .this-is__section6 .section6-human-base {
          position: relative;
          margin-left: 0px;
          margin-top: 100px; } }
    .this-is__section6 .section6-text2 {
      position: absolute;
      z-index: 1;
      right: 100px;
      bottom: 0; }
      @media screen and (max-width: 1175px) {
        .this-is__section6 .section6-text2 {
          right: 100px; } }
      @media (max-width: 767.98px) {
        .this-is__section6 .section6-text2 {
          position: relative;
          right: 0;
          z-index: 1;
          margin-top: -180px;
          margin-bottom: 90px; } }
      .this-is__section6 .section6-text2 img {
        width: 28vw;
        max-width: 375px; }
        @media (max-width: 767.98px) {
          .this-is__section6 .section6-text2 img {
            width: 70%;
            margin: 0px auto;
            display: block; } }
    .this-is__section6 .section6-bgimg {
      text-align: center; }
      @media only screen and (min-device-width: 768px) and (max-device-width: 1024px) {
        .this-is__section6 .section6-bgimg img {
          height: 100vh; } }
      @media screen and (max-height: 730px) {
        .this-is__section6 .section6-bgimg img {
          transform: translateY(-30px); } }
    @media (max-width: 767.98px) {
      .this-is__section6 .section6-title {
        left: 0; } }
  .this-is .section7-bg {
    margin-bottom: 0;
    position: relative; }
  .this-is .section7-btnarea {
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
    z-index: 2;
    bottom: 20px;
    text-align: center; }
    @media screen and (max-width: 1185px) {
      .this-is .section7-btnarea {
        bottom: 15px; } }
    @media (max-width: 767.98px) {
      .this-is .section7-btnarea {
        text-align: left;
        width: 100%;
        padding: 0 20px; } }
    .this-is .section7-btnarea .text {
      font-family: "Shippori Mincho B1", "Noto Serif JP", "游明朝", "Hiragino Mincho ProN", "ヒラギノ明朝 W3 JIS2004", "Times New Roman", Times, serif; }
    .this-is .section7-btnarea a {
      font-family: "EB Garamond", "Noto Serif JP", "游明朝", "Hiragino Mincho ProN", "ヒラギノ明朝 W3 JIS2004", "Times New Roman", Times, serif;
      max-width: 450px;
      display: block;
      padding: 10px 40px;
      color: #fff;
      text-align: center;
      text-decoration: none;
      border-radius: 30px;
      background: linear-gradient(#5fa0b9 0%, #498bb1 27.46%, #10579d 84.37%, #004898 100%);
      position: relative; }
      @media (min-width: 768px) {
        .this-is .section7-btnarea a {
          font-size: 2rem; } }
      @media (max-width: 767.98px) {
        .this-is .section7-btnarea a {
          font-size: 1.6rem; } }
      @media screen and (max-width: 1185px) {
        .this-is .section7-btnarea a {
          font-size: 1.2rem; } }
      @media (max-width: 767.98px) {
        .this-is .section7-btnarea a {
          max-width: none;
          padding: 10px 30px;
          width: 100%; } }
      .this-is .section7-btnarea a::after {
        font-family: 'FontAwesome';
        content: '\f138';
        position: absolute;
        display: inline-block;
        top: 53%;
        transform: translateY(-50%);
        right: 10px;
        font-size: 24px; }
  @media (max-width: 767.98px) {
    .this-is .section7 .section7-title {
      top: -100px !important;
      left: 0;
      padding-top: 120px;
      padding-bottom: 50px;
      margin-bottom: 60px; } }
  .this-is.en .thisis-slider {
    background-color: #fff;
    z-index: 5;
    max-width: 550px;
    position: absolute;
    bottom: 60px;
    left: 65px;
    padding: 30px 40px; }
    @media (max-width: 767.98px) {
      .this-is.en .thisis-slider {
        display: none; } }
    @media screen and (max-height: 810px) {
      .this-is.en .thisis-slider {
        bottom: 20px; } }
    .this-is.en .thisis-slider_item {
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      min-height: 60px; }
      .this-is.en .thisis-slider_item span {
        background-color: #333;
        color: #fff;
        padding: 3px 5px;
        width: 110px;
        text-align: center;
        display: inline-block; }
        @media (min-width: 768px) {
          .this-is.en .thisis-slider_item span {
            font-size: 1.4rem; } }
        @media (max-width: 767.98px) {
          .this-is.en .thisis-slider_item span {
            font-size: 1.4rem; } }
    .this-is.en .thisis-slider a {
      display: inline-block;
      color: #333;
      text-align: right; }
    .this-is.en .thisis-slider .slick-arrow:before {
      content: "" !important; }
    .this-is.en .thisis-slider .slick-arrow:before {
      content: "" !important;
      width: 100% !important;
      height: 100% !important;
      position: absolute;
      top: 12px; }
    .this-is.en .thisis-slider .slick-next {
      right: 10px; }
      .this-is.en .thisis-slider .slick-next::before {
        background: url(/en/about/features/img/sliderarrow-next.png) !important;
        background-size: contain !important;
        right: 10px; }
    .this-is.en .thisis-slider .slick-prev {
      left: 10px; }
      .this-is.en .thisis-slider .slick-prev::before {
        background: url(/en/about/features/img/sliderarrow-prev.png) !important;
        background-size: contain !important;
        left: 10px; }
  @media (min-width: 768px) {
    .this-is .this-is__title {
      font-size: 3.8rem; } }
  @media (max-width: 767.98px) {
    .this-is .this-is__title {
      font-size: 3rem; } }
  .this-is .this-is__title-box {
    top: 45%; }
  .this-is .section2-bgtext img {
    left: 0; }
    @media screen and (max-width: 1120px) {
      .this-is .section2-bgtext img {
        left: -110px;
        width: 80vw; } }
  .this-is .section4-bgtext {
    top: 47%; }
    .this-is .section4-bgtext .this-is__title-box {
      top: 50%; }
  @media only screen and (min-device-width: 768px) and (max-device-width: 1024px) {
    .this-is .section2-human-base {
      margin-left: 0;
      width: 100%; } }
  @media (min-width: 768px) {
    .this-is .section2-human-base {
      margin-left: 405px; } }
  .this-is .section3-title-inner::after {
    width: 1000px;
    height: 1000px;
    top: 72%; }
  @media (min-width: 768px) {
    .this-is .section6-human-base {
      margin-left: 340px; } }
  .this-is .popup-modal__item p {
    text-align: inherit; }

.staff .staff_index .tab-content {
  display: block !important; }

.staff .staff_index .close_btn {
  display: none !important; }

.staff .staff_index .close_btn-sp {
  display: none !important; }

@media (max-width: 767.98px) {
  .staff .staff_index .staff__tab-content {
    padding: 20px !important; } }

.staff .gojuon_link {
  margin-top: 60px; }

.staff__tab-head .nav-item {
  width: 200px;
  text-align: center; }
  @media (max-width: 767.98px) {
    .staff__tab-head .nav-item {
      width: 33.3333%; }
      .staff__tab-head .nav-item:last-child a {
        border-right: 2px solid #004A98; } }

.staff__tab-head .nav-link {
  padding: 15px 0;
  font-weight: bold;
  border: 2px solid #004A98;
  color: #004A98; }
  @media (max-width: 767.98px) {
    .staff__tab-head .nav-link {
      border-right: none; } }
  .staff__tab-head .nav-link:hover {
    background: #004A98;
    color: #fff;
    opacity: 1; }
  .staff__tab-head .nav-link.active {
    background: #004A98;
    color: #fff; }

.staff__tab .tab-content {
  display: none; }

.staff__tab-labellist {
  display: flex;
  flex-wrap: wrap;
  /*リセット */ }
  .staff__tab-labellist input[type="checkbox"] {
    margin: 0;
    padding: 0;
    background: none;
    border: none;
    border-radius: 0;
    outline: none;
    appearance: none;
    -webkit-appearance: none;
    -moz-appearance: none; }
  .staff__tab-labellist input[type="checkbox"] {
    cursor: pointer;
    padding-left: 30px;
    vertical-align: middle;
    position: relative;
    top: 14px; }
    .staff__tab-labellist input[type="checkbox"]::before, .staff__tab-labellist input[type="checkbox"]::after {
      content: "";
      display: block;
      position: absolute; }
    .staff__tab-labellist input[type="checkbox"]::before {
      background-color: #fff;
      border-radius: 0%;
      border: 1px solid #004A98;
      width: 20px;
      height: 20px;
      transform: translateY(-50%);
      top: 50%;
      left: 5px; }
    .staff__tab-labellist input[type="checkbox"]::after {
      border-bottom: 3px solid #004A98;
      border-left: 3px solid #004A98;
      opacity: 0;
      height: 6px;
      width: 11px;
      transform: rotate(-45deg);
      top: -4px;
      left: 10px; }
    .staff__tab-labellist input[type="checkbox"]:checked::after {
      opacity: 1; }
  .staff__tab-labellist label {
    width: calc(33.3333% - 20px);
    margin-right: 10px;
    display: flex;
    align-items: flex-start; }
  .staff__tab-labellist:nth-child(3n) {
    margin-right: 0; }

.staff__tab-content {
  padding: 60px 40px;
  position: relative;
  background: #004A98;
  margin-top: -2px; }
  @media (max-width: 767.98px) {
    .staff__tab-content {
      padding: 50px 20px 20px; } }
  .staff__tab-content .gakubu-box {
    margin-bottom: 20px; }
  .staff__tab-content .ttl_style4, .staff__tab-content h5:not([class]) {
    color: #fff;
    border-bottom: 1px solid #fff;
    margin-top: 0;
    margin-bottom: 15px; }
  .staff__tab-content .link_item {
    display: inline-block;
    margin-bottom: 10px;
    margin-right: 5px; }
    .staff__tab-content .link_item a {
      background: #fff;
      color: #004A98;
      padding: 5px 10px;
      text-decoration: none;
      font-weight: bold; }
  .staff__tab-content .close_btn::after {
    content: "×";
    font-size: 30px;
    cursor: pointer;
    position: absolute;
    right: 30px;
    top: 10px;
    color: #fff; }
    @media (max-width: 767.98px) {
      .staff__tab-content .close_btn::after {
        right: 10px;
        top: 0px; } }
  .staff__tab-content .close_btn-sp {
    display: none; }
    @media (max-width: 767.98px) {
      .staff__tab-content .close_btn-sp {
        display: block;
        margin: 30px auto 0;
        background: #fff;
        color: #333;
        text-align: center;
        width: 150px;
        padding: 10px 0;
        font-weight: bold; } }

.staff__content {
  margin: 60px 0; }
  .staff__content a {
    text-decoration: none; }
  .staff__content .list_item.is-hide {
    display: none; }
  .staff__content .post-sys__list {
    gap: 26.5px; }

.staff__list {
  display: flex;
  flex-wrap: wrap; }
  @media (max-width: 767.98px) {
    .staff__list {
      display: block; } }
  .staff__list_item {
    list-style: none;
    width: calc(50% - 30px);
    margin-right: 60px;
    margin-bottom: 30px; }
    @media (max-width: 767.98px) {
      .staff__list_item {
        width: 100%;
        margin-right: 0px; } }
    .staff__list_item:nth-child(even) {
      margin-right: 0; }

.staff__link {
  display: flex;
  width: 100%;
  background: #EFEFEF; }
  .staff__link figure {
    position: relative;
    flex: 0 0 250px;
    margin-bottom: 0;
    display: flex;
    flex-direction: column;
    justify-content: end; }
    @media (max-width: 767.98px) {
      .staff__link figure {
        flex: 0 0 150px; } }
    .staff__link figure::before {
      content: "";
      background-image: url(/img/common/img_arrow.jpg);
      width: 45px;
      height: 45px;
      position: absolute;
      bottom: 0;
      right: 0;
      background-size: contain;
      z-index: 1; }
      @media (max-width: 767.98px) {
        .staff__link figure::before {
          width: 25px;
          height: 25px; } }
  .staff__link img {
    width: 100%;
    max-width: 250px;
    object-fit: cover;
    aspect-ratio: 1/1; }
    @media (max-width: 767.98px) {
      .staff__link img {
        max-width: 150px; } }
  .staff__link_text {
    width: 100%;
    padding: 20px; }
    @media (max-width: 767.98px) {
      .staff__link_text {
        padding: 10px; } }
    @media (max-width: 767.98px) {
      .staff__link_text .text1 {
        font-size: 1.3rem; } }
    .staff__link_text .text2 {
      font-weight: bold; }
      @media (max-width: 767.98px) {
        .staff__link_text .text2 {
          font-size: 1.3rem; } }

.staff__column {
  max-width: 800px;
  margin: 180px auto 0; }
  @media (max-width: 767.98px) {
    .staff__column {
      margin: 80px auto 0; } }
  .staff__column p {
    text-align: center; }
  .staff__column .btn_style3 a {
    width: 80%;
    margin: 0 auto; }
    @media (max-width: 767.98px) {
      .staff__column .btn_style3 a {
        width: 100%; } }

.staff.detail .staff__detail-name {
  font-family: "Noto Serif JP", "游明朝", "Hiragino Mincho ProN", "ヒラギノ明朝 W3 JIS2004", "Times New Roman", Times, serif;
  font-weight: 400;
  font-weight: bold; }
  @media (min-width: 768px) {
    .staff.detail .staff__detail-name {
      font-size: 3.4rem; } }
  @media (max-width: 767.98px) {
    .staff.detail .staff__detail-name {
      font-size: 3.4rem; } }
  @media (max-width: 767.98px) {
    .staff.detail .staff__detail-name {
      margin-top: 20px; } }
  .staff.detail .staff__detail-name p {
    font-weight: normal;
    font-family: "游ゴシック Medium", "Yu Gothic Medium", "游ゴシック体", YuGothic, "ヒラギノ角ゴ ProN W3", "Hiragino Kaku Gothic ProN", "メイリオ", Meiryo, Verdana, Roboto, "Droid Sans", sans-serif;
    margin-top: 10px; }
    @media (min-width: 768px) {
      .staff.detail .staff__detail-name p {
        font-size: 1.6rem; } }
    @media (max-width: 767.98px) {
      .staff.detail .staff__detail-name p {
        font-size: 1.6rem; } }

.staff.detail .staff__detail-prof {
  background: #004A98;
  padding: 40px; }
  @media (max-width: 767.98px) {
    .staff.detail .staff__detail-prof {
      padding: 20px; } }
  .staff.detail .staff__detail-proftitle {
    color: #fff;
    margin-bottom: 20px; }
  .staff.detail .staff__detail-profbox {
    display: flex;
    align-items: flex-start; }
    @media (max-width: 767.98px) {
      .staff.detail .staff__detail-profbox {
        display: block; } }
    .staff.detail .staff__detail-profbox .label {
      background: #fff;
      color: #004A98;
      flex: 0 0 90px;
      text-align: center;
      font-weight: bold; }
      @media (max-width: 767.98px) {
        .staff.detail .staff__detail-profbox .label {
          max-width: 90px; } }
    .staff.detail .staff__detail-profbox .text {
      color: #fff;
      margin-left: 10px; }
      @media (max-width: 767.98px) {
        .staff.detail .staff__detail-profbox .text {
          margin-left: 0px; } }

.staff.detail .staff__detail-content {
  margin: 60px 0; }
  .staff.detail .staff__detail-content .r-map-content span {
    margin-right: 5px; }
  .staff.detail .staff__detail-content .r-map-content a {
    display: inline-block;
    vertical-align: top;
    position: relative;
    padding-right: 25px; }
    .staff.detail .staff__detail-content .r-map-content a::after {
      position: absolute;
      right: 0;
      top: 0px;
      padding-left: 10px; }

.staff.detail .staff__detail-item {
  margin-bottom: 40px; }
  .staff.detail .staff__detail-item:last-child {
    margin-bottom: 0; }

.staff.detail .r-year {
  flex-shrink: 0;
  width: 180px; }
  @media (max-width: 767.98px) {
    .staff.detail .r-year {
      display: block; } }

.staff.detail .r-box {
  display: flex; }
  @media (max-width: 767.98px) {
    .staff.detail .r-box {
      display: block; } }

.staff.detail .more {
  cursor: pointer;
  width: 80px;
  height: 80px;
  background: #004A98;
  color: #fff;
  text-align: center;
  font-size: 14px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  margin: 30px auto 0;
  position: relative; }
  .staff.detail .more::after {
    font-family: fontawesome;
    content: "\f107";
    position: absolute;
    color: #fff;
    left: 50%;
    transform: translateX(-50%);
    bottom: 5px; }
  .staff.detail .more.more-close::after {
    content: none !important; }

.staff.detail .staff__detail-item li.is-hidden {
  display: none; }

.sdgs .page__news-list {
  height: 235px; }

.sdgs .main_block {
  text-align: center; }

.sdgs .sdgs_cross {
  display: inline-block;
  position: relative;
  width: 60px;
  height: 60px; }
  .sdgs .sdgs_cross::before, .sdgs .sdgs_cross::after {
    content: "";
    position: absolute;
    top: 50%;
    left: 50%;
    width: 1px;
    height: 60px;
    background-color: #333; }
  .sdgs .sdgs_cross::before {
    transform: translate(-50%, -50%) rotate(45deg); }
  .sdgs .sdgs_cross::after {
    transform: translate(-50%, -50%) rotate(-45deg); }

.sdgs .sdgs_line {
  position: relative;
  margin: 60px 0; }
  .sdgs .sdgs_line::before {
    content: "";
    display: inline-block;
    width: 100px;
    height: 1px;
    background-color: #333;
    position: absolute;
    top: 30px;
    left: 50%;
    transform: translate(-50%); }

.important .contents {
  background: #EFEFEF; }
  .important .contents .inner-wide {
    background: #fff;
    padding: 60px; }
    @media (max-width: 767.98px) {
      .important .contents .inner-wide {
        padding: 30px; } }

.important_ttl {
  background: #004A98;
  color: #fff;
  font-family: "Noto Serif JP", "游明朝", "Hiragino Mincho ProN", "ヒラギノ明朝 W3 JIS2004", "Times New Roman", Times, serif;
  font-weight: 400;
  padding: 30px;
  font-weight: bold; }
  @media (min-width: 768px) {
    .important_ttl {
      font-size: 3rem; } }
  @media (max-width: 767.98px) {
    .important_ttl {
      font-size: 2.4rem; } }

.knowledge-search__mv {
  background-image: url("/about/knowledge_search/img/mv.jpg");
  background-size: cover;
  min-height: 450px;
  display: flex;
  align-items: center;
  justify-content: center; }
  @media (max-width: 767.98px) {
    .knowledge-search__mv {
      background-image: url("/about/knowledge_search/img/mv-sp.jpg"); } }
  .knowledge-search__mv_content {
    max-width: 960px;
    margin: 0 auto; }
    @media (max-width: 1279.98px) {
      .knowledge-search__mv_content {
        padding-right: 20px;
        padding-left: 20px; } }
  .knowledge-search__mv_tag {
    display: flex;
    flex-wrap: wrap;
    margin-top: 60px; }
    .knowledge-search__mv_tag .tag_item {
      margin-bottom: 20px;
      margin-right: 20px; }
      .knowledge-search__mv_tag .tag_item:last-child {
        margin-right: 0; }
      .knowledge-search__mv_tag .tag_item a {
        text-decoration: none;
        color: #004A98;
        font-weight: bold;
        background: #fff;
        padding: 5px 10px;
        border-radius: 20px; }
        @media (min-width: 768px) {
          .knowledge-search__mv_tag .tag_item a {
            font-size: 1.6rem; } }
        @media (max-width: 767.98px) {
          .knowledge-search__mv_tag .tag_item a {
            font-size: 1.4rem; } }
        .knowledge-search__mv_tag .tag_item a::before {
          content: "#";
          margin-right: 3px; }

.knowledge-search__news {
  margin: 80px 0; }
  .knowledge-search__news_box {
    display: flex;
    align-items: flex-start; }
    @media (max-width: 767.98px) {
      .knowledge-search__news_box {
        display: block; } }
  .knowledge-search__news_title {
    flex: 0 0 17%; }
  .knowledge-search__news_list {
    display: flex; }
    @media (max-width: 767.98px) {
      .knowledge-search__news_list {
        display: block; } }
  .knowledge-search__news_item {
    position: relative; }
    .knowledge-search__news_item img {
      width: 100%;
      object-fit: cover;
      aspect-ratio: 4/3; }
    .knowledge-search__news_item figure {
      position: relative;
      margin-bottom: 0; }
      .knowledge-search__news_item figure::after {
        content: "";
        position: absolute;
        top: 0;
        width: 100%;
        height: 100%;
        background: linear-gradient(rgba(0, 0, 0, 0) 45%, rgba(0, 0, 0, 0.5));
        display: block; }
    @media (max-width: 767.98px) {
      .knowledge-search__news_item.main {
        margin-bottom: 20px; } }
    .knowledge-search__news_item.main .post-inner {
      padding: 20px; }
    .knowledge-search__news_item.main .title {
      overflow: hidden;
      display: -webkit-box;
      -webkit-box-orient: vertical;
      -webkit-line-clamp: 3; }
      @media (max-width: 767.98px) {
        .knowledge-search__news_item.main .title {
          -webkit-line-clamp: 2; } }
    .knowledge-search__news_item.sub .post-inner {
      padding: 10px; }
    .knowledge-search__news_item.sub .title {
      overflow: hidden;
      display: -webkit-box;
      -webkit-box-orient: vertical;
      -webkit-line-clamp: 2; }
      @media (max-width: 767.98px) {
        .knowledge-search__news_item.sub .title {
          -webkit-line-clamp: 1; } }
    .knowledge-search__news_item .post-inner {
      position: absolute;
      bottom: 0;
      color: #fff;
      font-weight: bold; }
      .knowledge-search__news_item .post-inner .title {
        margin-bottom: 0;
        line-height: 1.2; }
        @media (min-width: 768px) {
          .knowledge-search__news_item .post-inner .title {
            font-size: 2.2rem; } }
        @media (max-width: 767.98px) {
          .knowledge-search__news_item .post-inner .title {
            font-size: 2rem; } }
      .knowledge-search__news_item .post-inner .date {
        font-family: "Noto Serif JP", "游明朝", "Hiragino Mincho ProN", "ヒラギノ明朝 W3 JIS2004", "Times New Roman", Times, serif;
        font-weight: 400; }
        @media (min-width: 768px) {
          .knowledge-search__news_item .post-inner .date {
            font-size: 1.4rem; } }
        @media (max-width: 767.98px) {
          .knowledge-search__news_item .post-inner .date {
            font-size: 1.4rem; } }
      .knowledge-search__news_item .post-inner .tag_item {
        margin-right: 5px;
        text-decoration: none;
        color: #004A98;
        font-weight: bold;
        background: #fff;
        padding: 0px 10px;
        border-radius: 20px;
        display: inline-block; }
        @media (min-width: 768px) {
          .knowledge-search__news_item .post-inner .tag_item {
            font-size: 1.4rem; } }
        @media (max-width: 767.98px) {
          .knowledge-search__news_item .post-inner .tag_item {
            font-size: 1.2rem; } }
        .knowledge-search__news_item .post-inner .tag_item::before {
          content: "#";
          margin-right: 3px; }
        .knowledge-search__news_item .post-inner .tag_item:last-child {
          margin-right: 0; }
    .knowledge-search__news_item.main {
      flex: 0 0 50%;
      width: calc(50% - 20px);
      margin-right: 10px; }
      @media (max-width: 767.98px) {
        .knowledge-search__news_item.main {
          width: 100%;
          margin-right: 0px; } }
      @media (min-width: 768px) {
        .knowledge-search__news_item.main:last-child {
          margin-right: 0; } }
    .knowledge-search__news_item.sub {
      display: flex;
      flex-wrap: wrap; }
      .knowledge-search__news_item.sub .sub-item {
        width: calc(50% - 5px);
        margin-right: 10px;
        position: relative; }
        .knowledge-search__news_item.sub .sub-item:nth-child(2n) {
          margin-right: 0; }
        .knowledge-search__news_item.sub .sub-item:nth-child(1), .knowledge-search__news_item.sub .sub-item:nth-child(2) {
          margin-bottom: 15px; }
        .knowledge-search__news_item.sub .sub-item figure {
          position: relative;
          margin-bottom: 0; }
          .knowledge-search__news_item.sub .sub-item figure::after {
            content: "";
            position: absolute;
            top: 0;
            width: 100%;
            height: 100%;
            background: linear-gradient(rgba(0, 0, 0, 0) 25%, rgba(0, 0, 0, 0.5));
            display: block; }

.knowledge-search__keyword {
  margin: 80px 0; }
  .knowledge-search__keyword .keyword-bg {
    background-image: url("/about/knowledge_search/img/keyword-bg.jpg");
    background-size: 100%;
    width: 100%;
    height: 100%;
    display: block; }
    .knowledge-search__keyword .keyword-bg .svg-inner {
      padding: 80px 200px; }

.knowledge-search__btn {
  text-align: right;
  margin-top: 10px; }
  .knowledge-search__btn a {
    color: #004A98;
    font-family: "Crimson Text", Times, serif;
    font-weight: 400;
    text-decoration: none;
    position: relative; }
    @media (min-width: 768px) {
      .knowledge-search__btn a {
        font-size: 1.8rem; } }
    @media (max-width: 767.98px) {
      .knowledge-search__btn a {
        font-size: 1.8rem; } }
    .knowledge-search__btn a:hover::after {
      bottom: -5px; }
    .knowledge-search__btn a::after {
      content: "";
      width: 100%;
      height: 1px;
      background: #004A98;
      position: absolute;
      bottom: 0;
      left: 50%;
      transform: translateX(-50%);
      transition: .15s linear; }

.knowledge-search__gd_title img {
  display: block;
  margin: 0 auto 40px; }

@media (max-width: 767.98px) {
  .knowledge-search__gd_title {
    max-width: none;
    margin: 0; }
    .knowledge-search__gd_title img {
      margin: 0 auto 50px; } }

.knowledge-search__gd_flex {
  display: flex;
  justify-content: space-between;
  gap: 80px; }
  @media (max-width: 767.98px) {
    .knowledge-search__gd_flex {
      display: block; } }
  .knowledge-search__gd_flex a {
    text-decoration: none; }

.knowledge-search__gd_list-left {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap; }
  @media (max-width: 767.98px) {
    .knowledge-search__gd_list-left {
      display: block;
      margin-bottom: 60px; } }

.knowledge-search__gd_list-right {
  flex: 0 0 280px; }

.knowledge-search__gd_item-left {
  width: calc(50% - 10px);
  margin-right: 15px; }
  @media (max-width: 767.98px) {
    .knowledge-search__gd_item-left {
      width: 100%;
      margin-right: 0;
      margin-bottom: 30px; } }
  @media (min-width: 768px) {
    .knowledge-search__gd_item-left:nth-child(2n) {
      margin-right: 0; }
    .knowledge-search__gd_item-left:nth-child(1), .knowledge-search__gd_item-left:nth-child(2) {
      margin-bottom: 30px; } }
  .knowledge-search__gd_item-left img {
    object-fit: cover;
    aspect-ratio: 7/3;
    width: 100%;
    border: 2px solid #CCC; }
  .knowledge-search__gd_item-left .title {
    margin-top: 10px; }
  .knowledge-search__gd_item-left .tag_item {
    display: inline-block;
    background-color: #fff;
    color: #004A98;
    box-shadow: 0px 0px 10px -4px #333;
    border-radius: 30px;
    padding: 2px 10px;
    margin-right: 10px; }
    @media (min-width: 768px) {
      .knowledge-search__gd_item-left .tag_item {
        font-size: 1.4rem; } }
    @media (max-width: 767.98px) {
      .knowledge-search__gd_item-left .tag_item {
        font-size: 1.4rem; } }
    .knowledge-search__gd_item-left .tag_item::before {
      content: "#";
      margin-right: 3px; }

.knowledge-search__gd_item-right {
  border-top: 1px solid #333; }
  .knowledge-search__gd_item-right a {
    padding: 20px 20px 20px 5px;
    display: block;
    position: relative; }
    .knowledge-search__gd_item-right a::after {
      font-family: 'FontAwesome';
      content: '\f105';
      position: absolute;
      display: inline-block;
      top: 50%;
      transform: translateY(-50%);
      right: 5px;
      color: #004A98; }
    .knowledge-search__gd_item-right a p {
      overflow: hidden;
      display: -webkit-box;
      -webkit-box-orient: vertical;
      -webkit-line-clamp: 2; }
  .knowledge-search__gd_item-right:last-child {
    border-bottom: 1px solid #333; }

.knowledge-search__gd_tabbox {
  position: relative;
  padding-bottom: 100px; }
  @media (max-width: 767.98px) {
    .knowledge-search__gd_tabbox {
      padding-bottom: 40px; } }
  .knowledge-search__gd_tabbox .tab-opacity {
    opacity: 0;
    visibility: hidden; }
  .knowledge-search__gd_tabbox .image-tab {
    position: absolute;
    top: 0; }

@media (max-width: 767.98px) {
  .knowledge-search__gd_list {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between; } }

@media (max-width: 767.98px) {
  .knowledge-search__gd_item {
    list-style: none;
    width: 48%;
    margin-bottom: 15px; }
    .knowledge-search__gd_item a {
      box-shadow: 0px 0px 15px -10px #333;
      border-radius: 10px;
      display: block;
      text-decoration: none;
      background: #CCC;
      color: #fff;
      padding: 10px 10px 20px 10px;
      text-align: center;
      font-family: "Noto Serif JP", "游明朝", "Hiragino Mincho ProN", "ヒラギノ明朝 W3 JIS2004", "Times New Roman", Times, serif;
      font-weight: 400;
      position: relative; } }
    @media (max-width: 767.98px) and (min-width: 768px) {
      .knowledge-search__gd_item a {
        font-size: 1.8rem; } }
    @media (max-width: 767.98px) and (max-width: 767.98px) {
      .knowledge-search__gd_item a {
        font-size: 1.8rem; } }

@media (max-width: 767.98px) {
      .knowledge-search__gd_item a::after {
        font-family: 'FontAwesome';
        content: '\f107';
        position: absolute;
        display: inline-block;
        left: 50%;
        transform: translateX(-50%);
        bottom: -5px;
        color: #004A98; } }

.knowledge_title {
  background: #004A98;
  padding: 5px 10px;
  width: 200px;
  color: #fff;
  text-align: center;
  margin-bottom: 40px; }
  @media (max-width: 767.98px) {
    .knowledge_title {
      width: 100%; } }

.knowledge_row {
  display: flex;
  justify-content: space-between; }
  .knowledge_row_item {
    width: 49%; }

.page.innovation .page__mv-video {
  width: 71.42857%; }
  @media (max-width: 767.98px) {
    .page.innovation .page__mv-video {
      width: 100%; } }

@media (max-width: 767.98px) {
  .page.innovation .page__mv {
    background-color: #fff;
    height: 700px; } }

.page.innovation .page__lead-box {
  width: 71.42857%;
  padding: 0;
  justify-content: center; }

.page.innovation .page__lead-text {
  margin: 0;
  font-size: 2.4rem;
  letter-spacing: 0.6rem; }
  @media (max-width: 767.98px) {
    .page.innovation .page__lead-text {
      font-size: 1.6rem; } }

.page.innovation .page_ttl {
  position: absolute;
  top: 76px;
  right: 10%; }
  @media (max-width: 767.98px) {
    .page.innovation .page_ttl {
      right: 50%;
      transform: translatex(50%); } }

.page.innovation .breadcrumb-item,
.page.innovation .breadcrumb-item a,
.page.innovation .breadcrumb-item.active,
.page.innovation .breadcrumb-item + .breadcrumb-item::before {
  color: #000;
  text-shadow: none; }

.page.innovation .breadcrumb {
  font-size: 1.4rem;
  margin-bottom: 90px; }

.page.innovation .innovation_center {
  text-align: center; }

.page.innovation .innovation_sub {
  font-size: 2rem;
  font-family: "Noto Serif JP", "游明朝", "Hiragino Mincho ProN", "ヒラギノ明朝 W3 JIS2004", "Times New Roman", Times, serif;
  font-weight: 400;
  font-weight: bold; }

.page.innovation .innovation_main {
  font-size: 5rem;
  font-family: "Noto Serif JP", "游明朝", "Hiragino Mincho ProN", "ヒラギノ明朝 W3 JIS2004", "Times New Roman", Times, serif;
  font-weight: 400;
  font-weight: bold; }

.page.innovation .innovation_txt {
  font-size: 1.6rem;
  font-family: "Noto Serif JP", "游明朝", "Hiragino Mincho ProN", "ヒラギノ明朝 W3 JIS2004", "Times New Roman", Times, serif;
  font-weight: 400; }

.page.innovation #id01 .innovation_flex {
  padding: 120px 10% 60px 6%; }

.page.innovation #id01 .innovation_border {
  border-left: 120px solid #004A98; }
  @media (max-width: 1079.98px) {
    .page.innovation #id01 .innovation_border {
      border-left: 60px solid #004A98; } }
  @media (max-width: 767.98px) {
    .page.innovation #id01 .innovation_border {
      border-left: 10px solid #004A98; } }

.page.innovation #id01 .academics__faculty-list_img {
  padding: 0 10% 60px 0;
  margin-left: -20px; }
  @media (max-width: 767.98px) {
    .page.innovation #id01 .academics__faculty-list_img {
      padding-right: 0;
      margin-left: -10px; } }

.page.innovation #id02 .innovation_flex {
  padding: 120px 6% 60px 10%; }

.page.innovation #id02 .innovation_border {
  border-right: 120px solid #015E55; }
  @media (max-width: 1079.98px) {
    .page.innovation #id02 .innovation_border {
      border-right: 60px solid #015E55; } }
  @media (max-width: 767.98px) {
    .page.innovation #id02 .innovation_border {
      border-right: 10px solid #015E55; } }

.page.innovation #id02 .academics__faculty-list_img {
  padding: 0 0 60px 10%;
  margin-right: -20px; }
  @media (max-width: 767.98px) {
    .page.innovation #id02 .academics__faculty-list_img {
      padding-left: 0;
      margin-right: -10px; } }

@media (max-width: 767.98px) {
  .page.innovation .togo-linkarea {
    padding: 0 30px; } }

.page.innovation .innovation_pic {
  font-family: "Crimson Text", Times, serif;
  font-weight: 400;
  font-size: 5rem; }

.page.innovation .bg_color_blue {
  background-color: #D8E6F3;
  padding: 60px 10% 120px 6%;
  overflow: hidden; }

.page.innovation .research__pickup-slider_txt {
  background: #fff;
  padding: 10px; }

@media (max-width: 767.98px) {
  .page.innovation .research__pickup-slider_item {
    margin-right: 0 !important; } }

.page.innovation .innovation-slider {
  margin-right: -100px;
  margin-bottom: 60px; }
  @media (max-width: 767.98px) {
    .page.innovation .innovation-slider {
      margin-right: auto; } }
  .page.innovation .innovation-slider figure {
    margin-bottom: 0; }
  .page.innovation .innovation-slider a {
    text-decoration: none; }
  .page.innovation .innovation-slider .slick-pagination {
    font-family: "Crimson Text", Times, serif;
    font-weight: 400;
    position: relative;
    display: flex;
    align-items: center;
    margin-top: 10px;
    justify-content: flex-end;
    margin-right: 80px; }
    .page.innovation .innovation-slider .slick-pagination::after {
      content: "";
      width: 1px;
      height: 15px;
      background-color: #333;
      margin: 0 20px 0 30px; }
  .page.innovation .innovation-slider .slick-arrow {
    position: absolute;
    top: auto;
    bottom: 35%;
    z-index: 1;
    height: 30px; }
  .page.innovation .innovation-slider .slick-next {
    right: 0px !important;
    bottom: 0 !important;
    transform: translateX(-100%); }
    .page.innovation .innovation-slider .slick-next::before {
      color: #333 !important;
      content: "" !important;
      background: url(/img/common/arrow_next_black.svg) no-repeat center center/contain; }
  .page.innovation .innovation-slider .slick-prev {
    left: auto !important;
    right: 30px !important;
    bottom: 0 !important;
    transform: translateX(-100%);
    color: #333; }
    .page.innovation .innovation-slider .slick-prev::before {
      color: #333 !important;
      content: "" !important;
      background: url(/img/common/arrow_next_black.svg) no-repeat center center/contain;
      transform: rotate(180deg); }

.page.innovation .innovation_content_ttl,
.page.innovation .innovation_main_ttl {
  font-family: "Noto Serif JP", "游明朝", "Hiragino Mincho ProN", "ヒラギノ明朝 W3 JIS2004", "Times New Roman", Times, serif;
  font-weight: 400;
  font-size: 3rem;
  font-weight: bold;
  padding-bottom: 30px; }

.page.innovation .innovation_content {
  padding: 90px 6% 90px 10%;
  background-color: #D4ECE8; }

.page.innovation .innovation_list {
  border-top: 1px solid #015E55;
  padding-bottom: 20px; }

.page.innovation .innovation_num {
  font-size: 8rem;
  font-family: "Crimson Text", Times, serif;
  font-weight: 400;
  color: #015E55;
  padding-right: 10px;
  font-style: italic;
  line-height: 0;
  margin-top: -10px;
  margin-right: 10px; }

.page.innovation .innovation_text {
  font-family: "Noto Serif JP", "游明朝", "Hiragino Mincho ProN", "ヒラギノ明朝 W3 JIS2004", "Times New Roman", Times, serif;
  font-weight: 400;
  font-size: 2.4rem;
  font-weight: bold;
  line-height: 1.4;
  display: flex;
  align-items: center;
  margin-top: 40px;
  margin-bottom: 20px; }

.page.innovation .innovation_content_sub {
  padding-bottom: 60px; }

.page.innovation .bg_white {
  background-color: #fff; }

.page.innovation .color__green {
  color: #015E55; }

.page.innovation .page__mv {
  position: relative; }

.page.innovation .main_img {
  padding: 60px 0; }

.page.innovation .last_content {
  padding-bottom: 100px; }

.page.innovation .page__bnrstyle1 {
  background-color: #fff; }

.page.innovation .innovation-bnrblock {
  display: flex;
  justify-content: space-between; }
  @media (max-width: 767.98px) {
    .page.innovation .innovation-bnrblock {
      display: block; } }
  .page.innovation .innovation-bnrblock .innovation-bnrlink {
    width: 30%;
    text-decoration: none; }
    @media (max-width: 767.98px) {
      .page.innovation .innovation-bnrblock .innovation-bnrlink {
        width: 100%;
        margin-bottom: 20px;
        display: block; } }
  .page.innovation .innovation-bnrblock.row-2 .innovation-bnrlink {
    width: 47%; }
    @media (max-width: 767.98px) {
      .page.innovation .innovation-bnrblock.row-2 .innovation-bnrlink {
        width: 100%;
        margin-bottom: 20px;
        display: block; } }

@media (max-width: 767.98px) {
  .page.innovation.en .innovation_main_ttl {
    font-size: 2rem; } }

.page.innovation.en .page__lead {
  padding-top: 0px; }

.page.innovation.en .page__lead-box {
  display: block; }
  @media (max-width: 767.98px) {
    .page.innovation.en .page__lead-box {
      margin-top: 120px; } }

@media (max-width: 767.98px) {
  .page.innovation.en .innovation_main {
    font-size: 3rem;
    line-height: 1.6; } }

.page.innovation.en .innovation_num {
  margin-right: 10px; }
  @media (max-width: 767.98px) {
    .page.innovation.en .innovation_num {
      margin-right: 30px; } }

.page.innovation.en .innovation-en-title {
  font-family: "Crimson Text", Times, serif;
  font-weight: 400;
  color: #fff;
  font-size: 7rem;
  margin-bottom: 20px; }
  @media (max-width: 767.98px) {
    .page.innovation.en .innovation-en-title {
      font-size: 4rem; } }

.page.innovation.en .page__lead-text {
  font-family: "Crimson Text", Times, serif;
  font-weight: 400;
  letter-spacing: inherit; }

@media (max-width: 767.98px) {
  .page.innovation.en .page__mv {
    height: auto;
    padding-bottom: 0px; } }

.page.innovation.en .page__mv-video {
  width: 100%; }

.history li {
  list-style: none; }

.history .timeline-bg-01 {
  color: #fff;
  padding: 0px 0 0;
  background-image: url(../about/history/img/bg.jpg);
  background-repeat: no-repeat;
  background-size: cover; }

.history .timeline-contents-inner {
  max-width: 1080px;
  width: 100%;
  margin: 0 auto; }
  @media (max-width: 767.98px) {
    .history .timeline-contents-inner {
      max-width: 100%; } }

.history .timeline-list-01 {
  margin-left: 165px;
  padding: 50px 0 20px;
  border-left: 1px dotted #efefef; }
  @media (max-width: 767.98px) {
    .history .timeline-list-01 {
      margin-left: 10%;
      padding: 20px 0 20px; } }

.history .fadein {
  opacity: 0;
  transform: translateY(20px);
  transition: all 1s; }

.history .timeline-item {
  justify-content: space-around;
  max-width: 780px; }
  @media (max-width: 767.98px) {
    .history .timeline-item {
      max-width: 90%; } }

.history .timeline-year-01,
.history .timeline-year-02 {
  top: 0;
  left: -11px;
  width: 100%;
  color: #fff;
  font-family: "Crimson Text", Times, serif;
  font-weight: 400;
  font-size: 80px;
  font-style: italic;
  font-weight: 500;
  line-height: 1.6;
  margin-top: 50px; }
  @media (max-width: 767.98px) {
    .history .timeline-year-01,
    .history .timeline-year-02 {
      font-size: 50px; } }

.history .timeline-details {
  max-width: 630px;
  width: 100%;
  margin-left: 100px; }
  @media (max-width: 767.98px) {
    .history .timeline-details {
      margin-left: 13%;
      width: 90%; } }

.history .timeline-text {
  font-size: 16px;
  line-height: 1.75; }
  .history .timeline-text span {
    margin-bottom: 20px; }

.history .timeline-details span {
  display: block;
  font-size: 24px;
  font-weight: 700;
  line-height: 1.5; }
  @media (max-width: 767.98px) {
    .history .timeline-details span {
      font-size: 21px; } }

.history .timeline-img {
  text-align: center;
  margin-top: 60px; }

.history .timeline-img img {
  max-width: 100%; }

.history .timeline-year-02:before {
  content: "";
  display: inline-block;
  width: 44px;
  height: 44px;
  background-image: url(../about/history/img/blue_btn.png);
  background-repeat: no-repeat;
  vertical-align: middle;
  margin-right: 56px;
  position: relative;
  left: -22px; }
  @media (max-width: 767.98px) {
    .history .timeline-year-02:before {
      margin-right: 10%;
      background-size: 22px;
      width: 22px;
      height: 22px; } }

.history .timeline-year-022:before {
  content: "";
  display: inline-block;
  width: 44px;
  height: 44px;
  background-image: url(../about/history/img/blue_btn.png);
  background-repeat: no-repeat;
  vertical-align: middle;
  margin-right: 56px;
  position: relative;
  left: -22px; }
  @media (max-width: 767.98px) {
    .history .timeline-year-022:before {
      background-size: 22px;
      margin-right: 0;
      background-size: 22px;
      width: 50px;
      height: 22px; } }

.history .timeline-year-01:before {
  content: "";
  display: inline-block;
  width: 16px;
  height: 16px;
  background-image: url(../about/history/img/white_btn.png);
  vertical-align: middle;
  margin-right: 85px;
  position: relative;
  left: -8px; }
  @media (max-width: 767.98px) {
    .history .timeline-year-01:before {
      margin-right: 10%; } }

.history .top {
  height: 270px;
  font-size: 36px;
  line-height: 1.39;
  font-weight: 800;
  text-align: center;
  font-family: "Noto Serif JP", "游明朝", "Hiragino Mincho ProN", "ヒラギノ明朝 W3 JIS2004", "Times New Roman", Times, serif;
  font-weight: 400;
  padding-top: 100px; }
  @media (max-width: 767.98px) {
    .history .top {
      height: 100px;
      background-size: contain;
      width: 80%;
      margin: auto;
      font-size: 24px;
      padding-top: 30px; } }
  .history .top img {
    margin-top: -30px; }
  .history .top .title {
    font-family: "Noto Serif JP", "游明朝", "Hiragino Mincho ProN", "ヒラギノ明朝 W3 JIS2004", "Times New Roman", Times, serif;
    font-weight: 400;
    font-weight: 700; }

.history .timeline-year-022 {
  font-size: 30px;
  line-height: 1.5;
  font-family: "Noto Serif JP", "游明朝", "Hiragino Mincho ProN", "ヒラギノ明朝 W3 JIS2004", "Times New Roman", Times, serif;
  font-weight: 400;
  top: 0;
  left: -11px;
  width: 100%;
  color: #fff;
  font-weight: 700;
  line-height: 1.6;
  margin-bottom: 20px; }
  @media (max-width: 767.98px) {
    .history .timeline-year-022 {
      font-size: 24px;
      display: flex; } }

.history .timeline-details:first-child {
  display: flex;
  justify-content: space-between; }
  @media (max-width: 767.98px) {
    .history .timeline-details:first-child {
      display: block; } }

.history ul li:nth-child(3) .timeline-img {
  width: 45%; }
  @media (max-width: 767.98px) {
    .history ul li:nth-child(3) .timeline-img {
      width: 100%; } }

.history .timeline-year-022:nth-child(2):before {
  opacity: 1; }

.history .timeline-year-022:first-child {
  margin-top: 100px; }

.history .ayumi {
  max-width: 630px;
  margin: 60px auto 120px 100px; }
  @media (max-width: 767.98px) {
    .history .ayumi {
      margin: 50px 10%; } }
  .history .ayumi .page-btn_style1::before,
  .history .ayumi .page-btn_style1::after {
    background-color: #fff; }
  .history .ayumi a {
    color: #fff; }

.history .timeline-item.text-bg {
  position: relative; }
  .history .timeline-item.text-bg .text-bg-item {
    position: absolute;
    top: 0;
    right: 0;
    background-size: contain;
    background-repeat: no-repeat;
    width: 95px;
    height: 100%; }
    .history .timeline-item.text-bg .text-bg-item.old {
      background-image: url(/about/history/img/kigen.svg); }
    .history .timeline-item.text-bg .text-bg-item.old-en {
      background-image: url(/en/about/history/img/kigen.svg); }
    .history .timeline-item.text-bg .text-bg-item.now {
      background-image: url(/about/history/img/genzai.svg);
      top: -32px; }
      @media (max-width: 767.98px) {
        .history .timeline-item.text-bg .text-bg-item.now {
          top: 0; } }
    .history .timeline-item.text-bg .text-bg-item.now-en {
      background-image: url(/en/about/history/img/genzai.svg); }
  .history .timeline-item.text-bg .timeline-year-022, .history .timeline-item.text-bg .timeline-year-02 {
    margin-left: -12px; }
    @media (max-width: 767.98px) {
      .history .timeline-item.text-bg .timeline-year-022, .history .timeline-item.text-bg .timeline-year-02 {
        padding-top: 40px; } }

.history ul li:first-child .timeline-details {
  top: 175px; }
  @media (max-width: 767.98px) {
    .history ul li:first-child .timeline-details {
      top: 130px; } }

.history ul li:first-child .timeline-year-02:before {
  left: -11px; }
  @media (max-width: 767.98px) {
    .history ul li:first-child .timeline-year-02:before {
      left: 0px; } }

@media (max-width: 767.98px) {
  .history .timeline-list-01 li:nth-child(2) {
    margin-top: 60px; } }

.history .timeline-list-01 li:nth-child(8) {
  margin-top: 120px; }
  @media (max-width: 767.98px) {
    .history .timeline-list-01 li:nth-child(8) {
      margin-top: 60px; } }
  .history .timeline-list-01 li:nth-child(8) .timeline-year-022 {
    padding-left: 8px; }
    @media (max-width: 767.98px) {
      .history .timeline-list-01 li:nth-child(8) .timeline-year-022 {
        margin-left: 0%;
        padding-left: 0px; } }
  .history .timeline-list-01 li:nth-child(8) .timeline-year-022:nth-child(2) {
    top: 40px; }
  .history .timeline-list-01 li:nth-child(8) .kigen {
    text-align: right;
    max-width: 700px;
    padding-bottom: 70px; }
    @media (max-width: 767.98px) {
      .history .timeline-list-01 li:nth-child(8) .kigen {
        padding-bottom: 150px; } }
  .history .timeline-list-01 li:nth-child(8) .timeline-details {
    top: 107px; }
    @media (max-width: 767.98px) {
      .history .timeline-list-01 li:nth-child(8) .timeline-details {
        top: 140px; } }
  .history .timeline-list-01 li:nth-child(8) .timeline-year-022:before {
    left: -18px; }
    @media (max-width: 767.98px) {
      .history .timeline-list-01 li:nth-child(8) .timeline-year-022:before {
        left: -12px;
        top: 8px; } }

@media (max-width: 767.98px) {
  .history .timeline-list-01 li:nth-child(9) .timeline-img {
    margin-top: 60px; } }

.history [data-emergence=hidden] {
  opacity: 0; }

.history [data-emergence=visible] {
  opacity: 1;
  transform: translateX(0);
  transition: 2s; }

.history.en .top {
  height: 200px; }
  @media (max-width: 767.98px) {
    .history.en .top {
      height: 140px; } }

.contact .contact-item {
  background: #EFEFEF;
  padding: 60px 10px; }
  .contact .contact-item form {
    margin: 0 auto;
    max-width: 960px; }
    .contact .contact-item form .aform-name-ul label, .contact .contact-item form .aform-kana-ul label {
      display: block; }
  .contact .contact-item li {
    list-style: none; }
  .contact .contact-item dl {
    display: flex;
    margin-bottom: 30px; }
    @media (max-width: 767.98px) {
      .contact .contact-item dl {
        display: block; } }
    .contact .contact-item dl dt {
      flex: 0 0 300px; }
    @media (max-width: 767.98px) {
      .contact .contact-item dl input {
        width: 100%; } }
  .contact .contact-item .aform-error {
    color: #E81826; }
  .contact .contact-item .aform-name-ul, .contact .contact-item .aform-kana-ul {
    display: flex; }
    @media (max-width: 767.98px) {
      .contact .contact-item .aform-name-ul, .contact .contact-item .aform-kana-ul {
        display: block; } }
    .contact .contact-item .aform-name-ul li:first-child, .contact .contact-item .aform-kana-ul li:first-child {
      margin-right: 10px; }
      @media (max-width: 767.98px) {
        .contact .contact-item .aform-name-ul li:first-child, .contact .contact-item .aform-kana-ul li:first-child {
          margin-right: 0px; } }
  @media (min-width: 768px) {
    .contact .contact-item .aform-label {
      font-size: 2rem; } }
  @media (max-width: 767.98px) {
    .contact .contact-item .aform-label {
      font-size: 1.6rem; } }
  .contact .contact-item .aform-label .aform-required {
    color: #E81826;
    display: block; }
    @media (min-width: 768px) {
      .contact .contact-item .aform-label .aform-required {
        font-size: 1.6rem; } }
    @media (max-width: 767.98px) {
      .contact .contact-item .aform-label .aform-required {
        font-size: 1.4rem; } }
  .contact .contact-item .formmessage {
    display: block; }
  .contact .contact-item .aform-twice-note {
    display: block; }
  .contact .contact-item .dl_formmessage dd {
    width: 100%; }
    .contact .contact-item .dl_formmessage dd textarea {
      width: 100%; }
  .contact .contact-item .form-txt-check {
    text-align: center; }
  .contact .contact-item .submit {
    max-width: 200px;
    margin: 40px auto 0; }
    .contact .contact-item .submit input {
      width: 200px;
      margin: 0 auto;
      padding: 10px 10px;
      color: #fff;
      background-color: #004A98;
      transition: .2s;
      border: none !important; }
      .contact .contact-item .submit input:hover {
        opacity: .6; }
  .contact .contact-item #aform-confirm-form .submit {
    display: flex;
    max-width: 500px;
    justify-content: space-between; }

.about-75 .breadcrumb-item {
  color: #333 !important;
  text-shadow: none !important; }
  .about-75 .breadcrumb-item + .breadcrumb-item::before {
    color: #333 !important; }
  .about-75 .breadcrumb-item a {
    color: #333 !important;
    text-shadow: none !important; }

.about-75 .page__news-item a.no-link {
  pointer-events: none; }
  .about-75 .page__news-item a.no-link::after {
    content: none; }

.about-75 .about__sitebnr-btnlist {
  background-image: url(/about/75th/img/column-mv.jpg) !important; }

.about-75__mv {
  overflow: hidden;
  position: relative; }
  .about-75__mv .mv-slider {
    margin-inline: auto;
    overflow: hidden;
    width: 100vw; }
    .about-75__mv .mv-slider li {
      position: relative; }
      .about-75__mv .mv-slider li::after {
        content: "";
        position: absolute;
        width: 100%;
        height: 100%;
        background-color: rgba(0, 0, 0, 0.3);
        top: 0;
        left: 0; }
      .about-75__mv .mv-slider li img {
        width: 100%; }
        @media (max-width: 767.98px) {
          .about-75__mv .mv-slider li img {
            object-fit: contain; } }

@keyframes zoomUp {
  0% {
    transform: scale(1); }
  100% {
    transform: scale(1.15); } }
    .about-75__mv .mv-slider .add-animation {
      animation: zoomUp 10s linear 0s normal both; }
  .about-75__mv-title {
    position: absolute;
    z-index: 1;
    color: #fff;
    margin-left: 50px; }
    @media (max-width: 767.98px) {
      .about-75__mv-title {
        margin-left: 20px; } }
    .about-75__mv-title .page-ttl_style1-ttl {
      padding-top: 30px; }
      @media (max-width: 767.98px) {
        .about-75__mv-title .page-ttl_style1-ttl {
          font-size: 2.6rem; } }

.about-75__block {
  margin: 130px auto; }
  @media (max-width: 767.98px) {
    .about-75__block {
      margin: 60px auto; } }

.about-75__leadblock {
  max-width: 800px; }

.about-75__title {
  font-family: "Noto Serif JP", "游明朝", "Hiragino Mincho ProN", "ヒラギノ明朝 W3 JIS2004", "Times New Roman", Times, serif;
  font-weight: 400;
  font-weight: bold;
  text-align: center; }
  @media (min-width: 768px) {
    .about-75__title {
      font-size: 3rem; } }
  @media (max-width: 767.98px) {
    .about-75__title {
      font-size: 2.2rem; } }

.about-75__lead {
  text-align: center; }
  @media (max-width: 767.98px) {
    .about-75__lead {
      text-align: justify; } }

.about-75__message {
  position: relative; }
  .about-75__message::after {
    content: "";
    width: 100%;
    height: 90%;
    background: linear-gradient(#004a98 0%, #152740 100%);
    display: block;
    position: absolute;
    z-index: -1;
    bottom: -60px; }
    @media (max-width: 767.98px) {
      .about-75__message::after {
        bottom: auto;
        top: 50px;
        height: 100%; } }
  .about-75__message_block {
    max-width: 1300px;
    margin: 0 auto; }
    @media (max-width: 767.98px) {
      .about-75__message_block {
        padding: 0 10px; } }
  .about-75__message_box {
    display: flex;
    justify-content: center;
    gap: 80px;
    margin-top: -150px;
    position: relative;
    z-index: 1;
    margin-left: 50px;
    margin-right: 50px; }
    @media (max-width: 767.98px) {
      .about-75__message_box {
        display: block;
        margin-top: 20px;
        margin-left: auto;
        margin-right: auto; } }
  .about-75__message_item {
    background-color: #fff;
    max-width: 540px;
    padding: 30px;
    flex: 1; }
    @media (max-width: 767.98px) {
      .about-75__message_item {
        margin: 0 auto 50px; } }
    .about-75__message_item .name {
      text-align: right;
      margin-top: 30px; }
      .about-75__message_item .name span {
        font-weight: bold;
        margin-left: 10px; }
        @media (min-width: 768px) {
          .about-75__message_item .name span {
            font-size: 1.8rem; } }
        @media (max-width: 767.98px) {
          .about-75__message_item .name span {
            font-size: 1.8rem; } }

.about-75__ceremony {
  background-image: url(/about/75th/img/ceremony.jpg);
  padding: 180px 0;
  background-size: cover;
  background-position: bottom;
  margin-top: 60px; }
  @media (max-width: 767.98px) {
    .about-75__ceremony {
      padding: 80px 0; } }
  .about-75__ceremony_title {
    font-family: "Noto Serif JP", "游明朝", "Hiragino Mincho ProN", "ヒラギノ明朝 W3 JIS2004", "Times New Roman", Times, serif;
    font-weight: 400;
    font-weight: bold;
    text-align: center; }
    @media (min-width: 768px) {
      .about-75__ceremony_title {
        font-size: 4.8rem; } }
    @media (max-width: 767.98px) {
      .about-75__ceremony_title {
        font-size: 3rem; } }
  .about-75__ceremony_day {
    text-align: center;
    color: #004A98;
    background: -webkit-linear-gradient(top, #004A98 30%, #152740);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    font-family: "Crimson Text", Times, serif;
    font-weight: 400; }
    .about-75__ceremony_day .day {
      line-height: 1; }
      @media (min-width: 768px) {
        .about-75__ceremony_day .day {
          font-size: 7rem; } }
      @media (max-width: 767.98px) {
        .about-75__ceremony_day .day {
          font-size: 4rem; } }
    .about-75__ceremony_day .yobi {
      margin-left: 10px; }
      @media (min-width: 768px) {
        .about-75__ceremony_day .yobi {
          font-size: 4.6rem; } }
      @media (max-width: 767.98px) {
        .about-75__ceremony_day .yobi {
          font-size: 3rem; } }
  .about-75__ceremony_text {
    max-width: 800px;
    margin: 60px auto 100px; }
    @media (max-width: 767.98px) {
      .about-75__ceremony_text {
        margin: 40px auto 60px; } }

.about-75__slider_block {
  display: flex;
  gap: 80px; }
  @media (max-width: 767.98px) {
    .about-75__slider_block {
      display: block; } }

.about-75__slider_box:only-child {
  width: 80%;
  margin: 0 auto; }
  @media (max-width: 767.98px) {
    .about-75__slider_box:only-child {
      width: 100%; } }

.about-75__slider_box:first-child:nth-last-child(2),
.about-75__slider_box:first-child:nth-last-child(2) ~ * {
  flex: 1; }

@media (max-width: 767.98px) {
  .about-75__slider_box {
    margin-bottom: 80px; } }

.about-75__slider_box .item {
  list-style: none; }
  .about-75__slider_box .item a {
    text-decoration: none; }
  .about-75__slider_box .item:hover a {
    text-decoration: none; }
  .about-75__slider_box .item .img img {
    object-fit: cover;
    aspect-ratio: 4/2.5; }
  .about-75__slider_box .item .slider-text {
    color: #004A98;
    font-weight: bold;
    margin-top: 5px; }
    @media (min-width: 768px) {
      .about-75__slider_box .item .slider-text {
        font-size: 1.8rem; } }
    @media (max-width: 767.98px) {
      .about-75__slider_box .item .slider-text {
        font-size: 1.6rem; } }

.about-75__slider_box .slider {
  padding: 0 30px; }
  .about-75__slider_box .slider .slide-arrow {
    position: absolute;
    top: min(19vw, 150px);
    cursor: pointer; }
  .about-75__slider_box .slider .prev-arrow {
    left: 0 !important; }
  .about-75__slider_box .slider .next-arrow {
    right: 0 !important; }

.about-75__slider_title {
  color: #004A98;
  font-family: "Noto Serif JP", "游明朝", "Hiragino Mincho ProN", "ヒラギノ明朝 W3 JIS2004", "Times New Roman", Times, serif;
  font-weight: 400;
  font-weight: bold;
  text-align: center;
  margin-bottom: 30px; }
  @media (min-width: 768px) {
    .about-75__slider_title {
      font-size: 3.4rem; } }
  @media (max-width: 767.98px) {
    .about-75__slider_title {
      font-size: 2.8rem; } }

.about-75__slider_lead {
  margin-bottom: 30px; }

@media (max-width: 767.98px) {
  .about-75 .about__sitebnr-btnlist {
    background-position: 75% center; } }
