@charset 'utf-8';
.page.innovation {
	.page__mv-video {
		width: (1000%/1400)*100;
		@include media-breakpoint-down(sm) {
    		width: 100%;
    	}
	}
	.page__mv {
		@include media-breakpoint-down(sm) {
			background-color: #fff;
			height: 700px;
		}
	}
	.page__lead-box {
		width: (1000%/1400)*100;
		padding: 0;
		justify-content: center;
	}
	.page__lead-text {
		margin: 0;
		font-size: 2.4rem;
		letter-spacing: 0.6rem;
		@include media-breakpoint-down(sm) {
			font-size: 1.6rem;
		}
	}
	.page_ttl {
		position: absolute;
		top: 76px;
		right: 10%;
	@include media-breakpoint-down(sm) {
			right: 50%;
			transform: translatex(50%);
		}
	}
	.breadcrumb-item,
	.breadcrumb-item a, 
	.breadcrumb-item.active,
	.breadcrumb-item + .breadcrumb-item::before {
		color: #000;
		text-shadow: none;
	}
	.breadcrumb {
		font-size: 1.4rem;
		margin-bottom: 90px;
	}
	.innovation_center {
		text-align: center;
	}
	.innovation_sub{
		font-size: 2rem;
		  @include ff-serif();
		font-weight: bold;
	}
	.innovation_main {
		font-size: 5rem;
		  @include ff-serif();
		font-weight: bold;
	}
	.innovation_txt {
		font-size: 1.6rem;
		  @include ff-serif();
	}
	#id01 {
		.innovation_flex {
			padding: 120px 10% 60px 6%;
		}
		.innovation_border {
			border-left: 120px solid #004A98;
			@include media-breakpoint-down(md) {
				border-left: 60px solid #004A98;
			}
			@include media-breakpoint-down(sm) {
				border-left: 10px solid #004A98;
			}
		}
		.academics__faculty-list_img {
			padding: 0 10% 60px 0;
			margin-left: -20px;
			@include media-breakpoint-down(sm) {
			padding-right: 0;
			margin-left: -10px;
			}
		}
	}
	#id02 {
		.innovation_flex {
			padding: 120px 6% 60px 10%;
		}
		.innovation_border {
			border-right: 120px solid #015E55;
			@include media-breakpoint-down(md) {
				border-right: 60px solid #015E55;
			}
			@include media-breakpoint-down(sm) {
			border-right: 10px solid #015E55;
			}
		}

		.academics__faculty-list_img {
			padding: 0 0 60px 10%;
			margin-right: -20px;
			@include media-breakpoint-down(sm) {
				padding-left: 0;
			margin-right: -10px;
			}
		}
	}

	.togo-linkarea{
		@include media-breakpoint-down(sm) {
			padding: 0 30px;
		}
	}

	.innovation_pic {
		@include ff-en();
		font-size: 5rem;
	}
	.bg_color_blue {
		background-color: #D8E6F3;
		padding: 60px 10% 120px 6%;
		overflow: hidden;
	}
	.research__pickup-slider_txt{
		background: $color-white;
		padding: 10px;
	}
	.research__pickup-slider_item{
		@include media-breakpoint-down(sm) {
			margin-right: 0!important;
		 }
	}
	.innovation-slider{
		margin-right: -100px;
		margin-bottom: 60px;
		@include media-breakpoint-down(sm) {
			margin-right: auto;
		 }
		figure{
			margin-bottom: 0;
		}
		a{
			text-decoration: none;
		}
		.slick-pagination{
			@include ff-en();
			position: relative;
			display: flex;
			align-items: center;
			// margin-left: 10px;
			margin-top: 10px;
			justify-content: flex-end;
			margin-right: 80px;
			&::after{
				content: "";
				width: 1px;
				height: 15px;
				background-color: $color-black;
				margin: 0 20px 0 30px;
			}
		}
		.slick-arrow{
			position: absolute;
			top: auto;
			bottom: 35%;
			z-index: 1;
			height: 30px;
		}
		.slick-next{
			&::before{
				color: $color-black!important;
				content: "" !important;
				background: url(/img/common/arrow_next_black.svg) no-repeat center center/contain;
			}
			// left: 140px !important;
			right: 0px!important;
			bottom: 0 !important;
			transform: translateX(-100%);
		}
		.slick-prev{
			&::before{
				color: $color-black!important;
				content: "" !important;
				background: url(/img/common/arrow_next_black.svg) no-repeat center center/contain;
				transform: rotate(180deg);
			}
			// left: 110px !important;
			left: auto!important;
			right: 30px!important;
			bottom: 0 !important;
			transform: translateX(-100%);
			color: $color-black;
		}
	}
	.innovation_content_ttl,
	.innovation_main_ttl {
			  @include ff-serif();
			font-size: 3rem;
			font-weight: bold;
			padding-bottom: 30px;
	}
	.innovation_content {
		padding: 90px 6% 90px 10%;
		background-color: #D4ECE8;
	}
	.innovation_list {
		border-top: 1px solid #015E55;
		padding-bottom: 20px;
	}
	.innovation_num {
		font-size: 8rem;
		@include ff-en();
		color: #015E55;
		padding-right: 10px;
		font-style: italic;
		line-height: 0;
		margin-top: -10px;
		margin-right: 10px;
	}
	.innovation_text {
		@include ff-serif();
		font-size: 2.4rem;
		font-weight: bold;
		line-height: 1.4;
		display: flex;
		align-items: center;
		margin-top: 40px;
		margin-bottom: 20px;
	}
	.innovation_content_sub {
		padding-bottom: 60px;
	}


	.bg_white {
		background-color: #fff;
	}
	.color__green {
		color: #015E55;
	}
	.page__mv {
		position: relative;
	}

	.main_img {
		padding: 60px 0;
	}

	.last_content {
		padding-bottom: 100px;
	}
	.page__bnrstyle1 {
		background-color: #fff;
	}

	.innovation-bnrblock{
		display: flex;
		justify-content: space-between;
		@include media-breakpoint-down(sm) {
			display: block;
			}
		.innovation-bnrlink{
			width: 30%;
			text-decoration: none;
			@include media-breakpoint-down(sm) {
				width: 100%;
				margin-bottom: 20px;
				display: block;
			}
		}
		&.row-2{
			.innovation-bnrlink{
				width: 47%;
				@include media-breakpoint-down(sm) {
					width: 100%;
					margin-bottom: 20px;
					display: block;
				}
			}
		}
	}



	&.en{
		.innovation_main_ttl{
			@include media-breakpoint-down(sm) {
				font-size: 2rem;
			}
		}
		.page__lead{
			padding-top: 0px;
		}
		.page__lead-box{
			display: block;
			@include media-breakpoint-down(sm) {
				margin-top: 120px
			}
		}
		.innovation_main{
			@include media-breakpoint-down(sm) {
				font-size: 3rem;
				line-height: 1.6
			}
		}
		.innovation_num {
			margin-right: 10px;
			@include media-breakpoint-down(sm) {
				margin-right: 30px;
			}
		}
		.innovation-en-title{
			@include ff-en();
			color: $color-white;
			font-size: 7rem;
			margin-bottom: 20px;
			@include media-breakpoint-down(sm) {
				font-size: 4rem;
			}
		}
		.page__lead-text{
			@include ff-en();
			letter-spacing: inherit;
		}
		.page__mv{
			@include media-breakpoint-down(sm) {
				height: auto;
				padding-bottom: 0px;
			}
		}
		.page__mv-video{
			width: 100%;
		}
	}
}