@charset 'utf-8';
.media{

	&:not(.detail){
		.post-sys__list{
			width: 100%;
		}
		.post-sys__item{
			width: 100%;
			margin-bottom: 16px;
		}
		.post-sys__link{
			// position: relative;
			display: block;
			border-bottom: 1px solid $color-black;
			padding-bottom: 16px;
			// &::after{
			// 	font-family: fontawesome;
			// 	content: "\f105";
			// 	position: absolute;
			// 	color: $color-black;
			// 	transition: .3s;
			// 	right: 0;
			// 	top: 50%;
			// 	transform: translateY(-50%);
			// }
		}
		.post-sys__date{
			color: $color-main;
			@include fz(1.8rem);
			margin-right: 14px;
		}
	}

}