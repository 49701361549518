@charset 'utf-8';
.page.academics {
	.academics {
		&__lead {
			display: flex;
			justify-content: flex-end;
			margin-bottom: 20px;
			@include fz(2rem,1.6rem);
			font-weight: bold;
			line-height: 1.4;
			text-align: right;
			span {
				@include fz(3rem,2.4rem);
				color: $color-main;
			}
			&::before {
				content: "";
				height: 1px;
				margin-right: 20px;
				margin-top: 1.2em;
				background-color: $color-black;
				flex: 0 0 100px;
				@include media-breakpoint-down(sm) {
					width: 50px;
					margin-right: 10px;
				}
			}
		}

		&__faculty {
			&-list {
				display: flex;
				margin: -2.5px;
				@include media-breakpoint-down(sm) {
					flex-wrap: wrap;
				}
				&_item {
					width: 100%;
					padding: 2.5px;
				}
				&_link {
					display: block;
					position: relative;
					@include imghover;
					&::before{
						content: "";
						background-image: url("/img/common/arrow_next_black.svg");
						background-position: center;
						background-repeat: no-repeat;
						width: 20px;
						height: 10px;
						background-color: $color-white;
						position: absolute;
						bottom: 0;
						right: -1px;
						opacity: 1;
						z-index: 2;
						padding: 20px;
						@include media-breakpoint-down(sm) {
							right: 0;
							bottom: -1px;
						}
					}
					&::after {
						content: "";
						width: 100%;
						height: 100%;
						background: rgba(0,0,0,.3);
						display: block;
						position: absolute;
						bottom: 0;
					}
				}
				&_img {
					img {
						width: 100%;
						object-fit: cover;
						height: 470px;
						@include media-breakpoint-down(sm) {
							height: auto;
						}
					}
				}
				&_content {
					position: absolute;
					left: 0;
					top: 0;
					padding: 30px 15px;
					z-index: 1;
					@include media-breakpoint-down(sm) {
						padding: 20px;
					}
				}
				&_title {
					-ms-writing-mode: tb-rl;
					writing-mode: vertical-rl;
					margin: 0;
					color: $color-white;
					@include ff-en();
					@include fz(3rem,2.4rem);
					line-height: 1.2;
					@include media-breakpoint-down(sm) {
						display: flex;
						flex-direction: column-reverse;
						writing-mode: horizontal-tb;
					}
					span {
						display: block;
						@include ff-serif();
						@include fz(1.6rem,1.4rem);
						@include media-breakpoint-down(sm) {
							margin-bottom: 5px;
						}
					}
				}
			}
			&-list2 {
				display: flex;
				margin: -2.5px;
				gap: 60px;
				@include media-breakpoint-down(sm) {
					flex-wrap: wrap;
					gap: 20px;
				}
				&_item {
					width: 100%;
					padding: 2.5px;
				}
				&_link {
					display: block;
					position: relative;
					@include imghover;
					&::before{
						content: "";
						background-image: url("/img/common/arrow_next_black.svg");
						background-position: center;
						background-repeat: no-repeat;
						width: 20px;
						height: 10px;
						background-color: $color-white;
						position: absolute;
						bottom: 0;
						right: -1px;
						opacity: 1;
						z-index: 2;
						padding: 20px;
						@include media-breakpoint-down(sm) {
							right: 0;
							bottom: -1px;
						}
					}
					&::after {
						content: "";
						width: 100%;
						height: 100%;
						background: rgba(0,0,0,.3);
						display: block;
						position: absolute;
						bottom: 0;
					}
				}
				&_img {
					img {
						width: 100%;
						object-fit: cover;
						@include media-breakpoint-down(sm) {
							height: auto;
						}
					}
				}
				&_content {
					position: absolute;
					left: 0;
					top: 0;
					padding: 20px 15px;
					z-index: 1;
					@include media-breakpoint-down(sm) {
						padding: 20px;
					}
				}
				&_title {
					margin: 0;
					color: $color-white;
					display: flex;
					flex-direction: column-reverse;
					gap: 15px;
					@include ff-en();
					@include fz(3rem,2.4rem);
					line-height: 1.2;
					@include media-breakpoint-down(sm) {
						display: flex;
						flex-direction: column-reverse;
						writing-mode: horizontal-tb;
						gap: 0;
					}
					span {
						display: block;
						@include ff-serif();
						@include fz(1.6rem,1.4rem);
						@include media-breakpoint-down(sm) {
							margin-bottom: 5px;
						}
					}
				}
				&_caption{
					@include media-breakpoint-down(sm) {
						text-indent: -1.2em;
						padding-left: 1.2em;
					}
				}
			}
		}

		&__findout {
			position: relative;
			z-index: 2;
			padding: 90px 0 !important;
			background: url("/academics/img/findout_bg.jpg") no-repeat center center / cover;
			color: $color-white;
			@include media-breakpoint-down(sm) {
				padding: 70px 0 100px !important;
			}
			&::before {
				content: "";
				opacity: 0;
				visibility: hidden;
				position: absolute;
				left: 0;
				top: 0;
				z-index: 1;
				width: 100%;
				height: 100%;
				background-image: linear-gradient(to bottom, #004a98, #152740);
			}
			&.is-open::before {
				opacity: 0.5;
				visibility: visible;
			}
			&::after {
				content: "";
				position: absolute;
				left: -45px;
				top: 50%;
				z-index: -1;
				transform: translateY(-50%);
				display: block;
				width: 198px;
				height: 670px;
				background: url("/academics/img/findout_ttl.png") no-repeat left center / contain;
				@include media-breakpoint-down(sm) {
					left: 50%;
					top: auto;
					bottom: -28px;
					z-index: -1;
					transform: translate(-50%, 0);
					width: 335px;
					height: 129px;
					background-image: url("/academics/img/findout_ttl-sp.png");
					background-position: center bottom;
				}
			}
			&-title {
				margin-bottom: 30px;
				@include fz(2.4rem,2.2rem);
				font-weight: bold;
				text-align: center;
				&::before {
					content: "";
					position: absolute;
					left: 0;
					right: 0;
					top: 0;
					display: block;
					width: 1px;
					height: 80px;
					margin: auto;
					background-color: $color-white;
					@include media-breakpoint-down(sm) {
						height: 60px;
					}
				}
			}
			&-wrapper {
				position: relative;
			}
			&-nav {
				display: flex;
				flex-wrap: wrap;
				padding: 0;
				list-style: none;
				transition: all 0.4s ease;
				&.is-hide {
					opacity: 0;
					overflow: hidden;
					height: 0;
				}
				&_item {
					width: 25%;
					padding: 40px 0;
					@include media-breakpoint-down(md) {
						padding: 2vw 0;
					}
					@include media-breakpoint-down(sm) {
						width: 50%;
						padding: 2.66vw 0;
					}
					button {
						position: relative;
						z-index: 0;
						display: flex;
						align-items: center;
						justify-content: center;
						width: 220px;
						height: 220px;
						margin: 0 auto;
						padding: 0;
						background-color: transparent;
						border: none;
						border-radius: 100%;
						appearance: none;
						@include fz(2.2rem,2rem);
						color: $color-white;
						font-weight: bold;
						line-height: 1.56;
						text-align: center;
						text-decoration: none;
						@include media-breakpoint-down(md) {
							width: 20vw;
							height: 20vw;
						}
						@include media-breakpoint-down(sm) {
							width: 40vw;
							height: 40vw;
						}
						&::before {
							content: "";
							position: absolute;
							left: 0;
							right: 0;
							top: 0;
							bottom: 0;
							z-index: -1;
							transition: all 0.2s ease;
							margin: auto;
							border-radius: 200px;
							border: 1px solid $color-white;
						}
						&:hover {
							opacity: 1;
							&::before {
								transform: scale(1.1);
							}
						}
					}
					p {
						margin: 0;
						text-align: center;
					}
				}
			}
			&-detail {
			}
			&-panel {
				opacity: 0;
				overflow: hidden;
				height: 0;
				position: relative;
				z-index: 3;
				width: 100%;
				background-color: $color-white;
				color: $color-black;
				transition: all 0.4s ease;
				&.is-active {
					opacity: 1;
					overflow: visible;
					height: auto;
				}
				&_inner {
					padding: 90px 60px 60px;
					@include media-breakpoint-down(sm) {
						padding: 70px 44px 60px;
					}
				}
				&_title {
					margin-bottom: 40px;
					@include fz(2.4rem,1.8rem);
					font-weight: bold;
					text-align: center;
					span {
						@include fz(3rem);
						color: $color-main;
					}
					&::before {
						content: "";
						position: absolute;
						left: 0;
						right: 0;
						top: 0;
						display: block;
						width: 1px;
						height: 80px;
						margin: auto;
						background-color: $color-main;
						@include media-breakpoint-down(sm) {
							height: 60px;
						}
					}
				}
				&_btn {
					position: absolute;
					right: 0;
					top: 0;
					width: 60px;
					height: 60px;
					padding: 0;
					appearance: none;
					background: url("/academics/img/findout_close.svg") no-repeat center center / contain;
					border: 0;
					line-height: 0px;
					font-size: 0px;
				}
				&_close {
					width: 120px;
					margin: 60px auto 0;
					@include media-breakpoint-down(sm) {
						margin: 40px auto 0;
					}
					button{
						padding: 5px 0;
						display: flex;
						justify-content: space-between;
						align-items: center;
						width: 100%;
						appearance: none;
						background: transparent;
						border: 0;
						@include ff-en();
						&::after{
							content: "";
							background-image: url("/img/common/arrow_next_black.svg");
							background-repeat: no-repeat;
							width: 20px;
							height: 10px;
							margin-left: 10px;
						}
						&:hover{
							opacity: 1;
						}
					}
				}
			}
			&-result {
				display: flex;
				flex-wrap: wrap;
				margin: -15px;
				padding: 0;
				list-style: none;
				&_item {
					position: relative;
					width: 25%;
					padding: 15px;
					@include media-breakpoint-down(sm) {
						width: 100%;
						padding: 10px;
					}
					&::before, &::after {
						content: "";
						position: absolute;
						z-index: 1;
						width: 15px;
						height: 15px;
						background: url("/academics/img/findout_frame.svg") no-repeat left top / contain;
					}
					&::before {
						left: 15px;
						top: 15px;
						@include media-breakpoint-down(sm) {
							left: 10px;
							top: 10px;
						}
					}
					&::after {
						right: 15px;
						top: 15px;
						transform: rotate(90deg);
						@include media-breakpoint-down(sm) {
							right: 10px;
							top: 10px;
						}
					}
				}
				&_frame {
					position: relative;
					z-index: 0;
					height: 100%;
					padding: 30px 20px;
					border: 1px solid $color-gray-light2;
					&::before, &::after {
						content: "";
						position: absolute;
						z-index: 1;
						width: 15px;
						height: 15px;
						background: url("/academics/img/findout_frame.svg") no-repeat left top / contain;
					}
					&::before {
						left: 0;
						bottom: 0;
						transform: rotate(270deg);
					}
					&::after {
						right: 0;
						bottom: 0;
						transform: rotate(180deg);
					}
				}
				&_title {
					position: relative;
					padding-left: 30px;
					@include fz(2rem,1.8rem);
					color: $color-main;
					font-weight: bold;
					&::before {
						content: "";
						position: absolute;
						left: 0;
						top: 3px;
						z-index: 1;
						width: 20px;
						height: 20px;
						background: url("/academics/img/findout_arrow.svg") no-repeat center center / contain;
						@include media-breakpoint-down(sm) {
							top: 2px;
						}
					}
				}
			}
		}
	}
}
