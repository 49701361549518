@charset 'utf-8';
body.this-is.fixed{
    overflow: hidden;
}

.this-is{
    .cookie{
        display: none!important;
    }
}


.mfp-bg.mfp-ready{
    opacity: 1;
    -webkit-backdrop-filter: blur(10px);
    backdrop-filter: blur(10px);
    -webkit-filter: brightness(1.4);
    filter: brightness(1.4);
    background: rgba(255, 255, 255, 0.3);
  @include media-breakpoint-down(sm) {
    filter: none;
 }
}
.mfp-bg{
    opacity: 0;
    transition: opacity .5s;
}
.mfp-content{
    width: 90%;
    background: $color-white;
    max-width: 1200px;
    margin: 0 auto;
    padding:100px;
    position: relative;
    @include media-breakpoint-down(sm) {
        position: static!important;;
        padding: 30px!important;;
        margin: 30px 0 70px!important;;
     }
    &::after{
        content: "";
        background-image: url("/about/features/img/modal-text.svg");
        background-size: 30%;
        background-repeat: no-repeat;
        width: 100%;
        height: 58px;
        display: block;
        position: absolute;
        bottom: 20px;
        left: 20px;
        margin-top: 20px;
        @include media-breakpoint-down(sm) {
            position: static!important;;
            background-size: 90%!important;;
            margin-top: 40px!important;;
         }
    }
}
.mfp-container{
    overflow: auto;
}
.mfp-close{
    border-radius: 50%;
    box-shadow: 3px 3px 7px 1px rgba(0,0,0,.5)!important;
    margin-right: 20px;
    margin-top: 20px;
    opacity: 1;
    color: #B1B1B1!important;
    &:hover{
        opacity: 1;
    }
    &-text{
        display: block;
        font-size: 14px;
        color: #B1B1B1;
        font-family: $font-family-garamond;
        border-bottom: 1px solid #B1B1B1;
        line-height: 1.6;
        margin-top: 5px;
    }
}

.close-btn{
    @include media-breakpoint-down(sm) {
        cursor: pointer;
        width: 100%;
        border-bottom: 1px solid $color-black;
        padding: 10px 10px 10px 0;
        position: relative;
        &::after{
            content: "×";
            position: absolute;
            right: 0;
        }
     }
}

.popup-modal{
    &__inner{

    }
    &__box{
        display: flex;
        gap: 30px;
        @include media-breakpoint-down(sm) {
            display: block!important;
         }
    }
    &__item{
        flex: 0 0 50%;
        &.img-center{
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;
        }

        p{
            text-align: justify;
        }
    }
    &__title{
        @include fz(3.0rem,2.4rem);
        font-weight: bold;
        span{
            background-color: $color-black;
            color: $color-white;
            @include fz(2.0rem,1.8rem);
            padding: 3px 5px;
            vertical-align:text-bottom;
            margin-right: 10px;
            @include media-breakpoint-down(sm) {
                vertical-align:inherit!important;;
             }
        }
        &-2{
            @include fz(2.0rem,1.8rem);
            margin-bottom: 0px;
        }
    }
}

.popup-sp-btn{
    a{
        color: $color-black;
        font-weight: bold;
        border-bottom: 1px solid $color-black;
        padding-bottom: 14px;
        padding-top: 14px;
        text-decoration: none;
        width: 100%;
        display: block;
        span{
            background: $color-black;
            color: $color-white;
            padding: 3px 5px;
        }
    }
    &:first-child{
        a{
            border-top: 1px solid $color-black;
        }
    }
    &-block{
        margin-top: 40px;
    }
}

.section{
    &[data-anchor="page-2"]{
        .section2-human-base{
            bottom: -50px!important;
            transition: 1.5s;
            opacity: 0;
        }
         &.active{
            .section2-human-base{
                bottom: 0px!important;
                opacity: 1;
             }
         }
    }
    &[data-anchor="page-3"]{
        .section3-human-base{
            right: 150px!important;
            transition: 1.5s;
            opacity: 0;
        }
         &.active{
            .section3-human-base{
                right: 200px!important;
                opacity: 1;
             }
         }
    }
    &[data-anchor="page-4"]{
        .section4-horse-base{
            right: -500px!important;
            transition: 2s;
        }
         &.active{
            .section4-horse-base{
                right: -130px!important;
             }
         }
    }
    &[data-anchor="page-5"]{
        &::before{
            content: "";
            height: 100vh;
            width: 45%;
            display: block;
            background: #000;
            z-index: 1;
            position: absolute;
            background: linear-gradient(to left, rgba(0, 0, 0, 0) 0%, black 130%);
        }
        .this-is__section5{
            background-color:rgba(255,255,255,0.8)!important;
            background-blend-mode:lighten!important;
            transition: 5s;
        }
        .section5-title{
            z-index: 2;
            text-shadow: 0px 3px 6px rgba(0,0,0,0.8);
        }
         &.active{
            .this-is__section5{
                background-color:rgba(255,255,255,0)!important;
             }
         }
    }
    &[data-anchor="page-6"]{
        .section6-human-base{
            bottom: -50px!important;
            transition: 2s;
            opacity: 0;
        }
         &.active{
            .section6-human-base{
                bottom: 0px!important;
                opacity: 1;
             }
         }
    }
}

.contents{
    @include media-breakpoint-down(sm) {
        overflow: hidden;
     }

}

.fd-left{
    transform: translateX(-50px);
    opacity:0;
    transition: 2s;
    transition-delay: 2s;
    @include media-breakpoint-down(sm) {
        transform: none;
        opacity:1;
     }
}
.fd-bottom{
    transform: translateY(50px);
    opacity:0;
    transition: 2s;
    transition-delay: 2s;
    @include media-breakpoint-down(sm) {
        transform: none;
        opacity:1;
     }
}

.this-is{
    animation: fadeIn 4s ease 0s 1 normal;

    @keyframes fadeIn {
        0% {
            opacity: 0
        }
        100% {
            opacity: 1
        }
    }

    .contents_logo{
        display: none;
    }
    .this-is_logo{
        display: block!important;
    }
    .gheader__inner.contents-header{
        @include media-breakpoint-up(md) {
            background: none;
         }
        a,p{
             @include media-breakpoint-up(md) {
                color: $color-white;
             }
        }
    }
    .gheader.nav-active .gheader__megamenu{
        @include media-breakpoint-up(md) {
            background: none;
         }
    }
    .gheader.nav-active .gheader__megamenu-list{
        @include media-breakpoint-up(md) {
            background: none;
         }
        .gheader__megamenu-link{
            @include media-breakpoint-up(md) {
                color: $color-white;
             }
            &::before,&::after{
                @include media-breakpoint-up(md) {
                    background-color: $color-white;
                 }
            }
        }
    }
    .gheader__contents{
        background: linear-gradient(to top, rgba(0, 0, 0, 0)0%,  rgba(0, 0, 0, 0.8)90%);
        .gheader__megamenu-link,.gheader__common-link{
            text-shadow: 2px 2px 3px rgba(0,0,0,0.8);
            @include media-breakpoint-down(sm) {
                text-shadow: none;
             }
            &.sp-aclink{
                text-shadow: none;
            }
        }
 
        // &::before{
        //     content: "";
        //     width: 100%;
        //     position: absolute;
        //     top: 0;
        //     width: 100%;
        //     height: 100%;
        //     display: block;
        //     background: #000;
        // }
    }
	.contents{
        padding: 0!important;
    }
    #fullpage{
    }
    .fullpage__navi{
        ul:not([class]) > li::before{
            content: none;
        }
        position: fixed;
        right: 0px;
        top: 50%;
        transform: translateY(-50%);
        height: 100%;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        padding-top: 105px;
        @include media-breakpoint-down(sm) {
            transform: none;
            top: auto;
            bottom: 100px;
            height: auto;
            right: 10px;
            z-index: 3;
        }
        &::after{
            content: "";
            background: linear-gradient(to right ,rgba(0,0,0,0),rgba(0,0,0,.5));
            height: 100%;
            display: block;
            top: 0;
            bottom: 0;
            right: 0;
            position: absolute;
            width: 150px;
            z-index: -1;
            @include media-breakpoint-down(sm) {
                content: none;
            }
        }
        li{
            margin-bottom: 25px;
            list-style: none;
            @include media-breakpoint-down(sm) {
                margin: 12px;
            }
            &:last-child{
                margin-bottom: 0;
            }
            a{
                color: $color-white;
                text-decoration: none;
                display: flex;
                justify-content: end;
                align-items: center;
                @include ff-serif();
                font-weight: bold;
                &::after{
                    content: "";
                    width: 30px;
                    height: 2px;
                    background: $color-white;
                    margin-left: 5px;
                    @include media-breakpoint-down(sm) {
                        content: "";
                        width: 5px;
                        height: 5px;
                        border-radius: 50%;
                        background-color: $color-gray;
                    }
                }
            }
            &.active{
                a{
                    @include fz(2rem);
                    &::after{
                        height: 6px;
                    }
                }
            }
            a{
                &.active{
                    &::after{
                        @include media-breakpoint-down(sm) {
                            background-color: $color-main;
                        }
                    }
                }
            }
        }
    }
    &.fp-viewing-page-3{
        .fullpage__navi::after{
            content: none;
        }
    }
    &.fp-viewing-page-7{
        .fullpage__navi{
            display: none;
        }
    }

    .path{
        position: absolute;
        z-index: 5;
        @include media-breakpoint-down(sm) {
            position: static;
       }
        a{
            text-decoration: none;
        }
        &-inner{
            position: absolute;
                top: 0;
                bottom: 0;
                text-decoration: none;
                display: flex;
                flex-direction: column;
                justify-content: center;
                background: $color-white;
                border-radius: 50%;
                position: absolute;
                z-index: 10;
                font-weight: bold;
                text-align: center;
                line-height: 1;
                text-decoration: none;
                @include media-breakpoint-down(sm) {
                      position: static;
                 }
                a{
                    height: 100%;
                    display: flex;
                    flex-direction: column;
                    justify-content: center;
                }
                
        }
    }
    .section{
        position: relative;
        &-inner{
             overflow: hidden;
            // &::after{
            //     content: "";
            //     position: relative;
            //     display: block;
            //     z-index: 4;
            //     width: 100%;
            //     height: 100vh;
            //     transition: .5s ;
            //     transition-delay: 0.5s;
            //     @include media-breakpoint-down(sm) {
            //         content: none;
            //      }
            // }
            &.bg-none{
                &::after{
                    transform: translate(0,-100%);
                }
            }
        }
        &-link{
            .fz-small{
                @include fz(1.4rem);
            }
            .fz-large{
                @include fz(1.8rem);
                line-height: 1.4;
            }
            &-btn{
                font-family: $font-family-garamond;
                color: $color-gray;
                @include fz(1.4rem);
                text-decoration: underline;
            }
        }
        &1{
            &-path{
                &-1{
                    clip-path: url(#p-homeSubMask1);
                    top: 55vh;
                    right: 15vw;
                    width: 180px;
                    height: 180px;
                    &-inner{
                        width: 180px;
                        height: 180px;
                    }
                }
                &-2{
                    clip-path: url(#p-homeSubMask2);
                    bottom: 100px;
                    left: 28vw;
                    width: 150px;
                    height: 150px;
                    @media screen and (max-height:770px) { 
                        bottom: 50px;
                    }
                    &-inner{
                        width: 150px;
                        height: 150px;
                    }
                }
                &-3{
                    clip-path: url(#p-homeSubMask3);
                    width: 160px;
                    height: 160px;
                    bottom: 5vw;
                    right: 30vw;
                    &-inner{
                        width: 160px;
                    height: 160px;
                    }
                }
            }   
        }     
        &2{
            &-path{
                &-1{
                    clip-path: url(#p-homeSubMask1);
                    top: 18vh;
                    left: 39vw;
                    width: 180px;
                    height: 180px;
                    // @media screen and (max-height:870px) { 
                    //     top: auto;
                    //     bottom: 20vh;
                    //     left: 45vw;
                    // }
                    @media screen and (max-width:1120px) { 
                        top: auto;
                        left: 35vw;
                        bottom: 5vh;
                    }
                    @media screen and (max-height:650px) { 
                        top: auto;
                        left: 35vw;
                        bottom: 5vh;
                    }
                    &-inner{
                        width: 180px;
                        height: 180px;
                    }
                }
                &-2{
                    clip-path: url(#p-homeSubMask2);
                    bottom: 50px;
                    left: 40vw;
                    width: 150px;
                    height: 150px;
                    @media screen and (max-width:1120px) { 
                        left: 10vw;
                    }
                    @media screen and (max-height:650px) { 
                        left: 10vw;
                    }
                    &-inner{
                        width: 150px;
                        height: 150px;
                    }
                }
                &-3{
                    clip-path: url(#p-homeSubMask3);
                    width: 160px;
                    height: 160px;
                    top: 70vh;
                    right: 20vw;
                    @media only screen and (min-device-width: 768px) and (max-device-width: 1024px) {
                        top: 55vh!important;
                    }
                    &-inner{
                        width: 160px;
                    height: 160px;
                    }
                }
            }   
        }  

        &3{
            &-path{
                &-1{
                    clip-path: url(#p-homeSubMask1);
                    top: 35vh;
                    right: 17vw;
                    width: 160px;
                    height: 160px;
                    &-inner{
                        width: 160px;
                        height: 160px;
                    }
                }
                &-2{
                    clip-path: url(#p-homeSubMask2);
                    top: 67vh;
                    left: 15vw;
                    width: 150px;
                    height: 150px;
                    &-inner{
                        width: 150px;
                        height: 150px;
                    }
                }
                &-3{
                    clip-path: url(#p-homeSubMask3);
                    width: 180px;
                    height: 180px;
                    top: 75vh;
                    right: 10vw;
                    &-inner{
                        width: 180px;
                    height: 180px;
                    }
                }
            }   
        }  

        &4{
            &-path{
                &-1{
                    clip-path: url(#p-homeSubMask1);
                    top: 25vh;
                    right: 36vw;
                    width: 180px;
                    height: 180px;
                    &-inner{
                        width: 180px;
                        height: 180px;
                    }
                }
                &-2{
                    clip-path: url(#p-homeSubMask2);
                    top: 65vh;
                    left: 35vw;
                    width: 160px;
                    height: 160px;
                    &-inner{
                        width: 160px;
                        height: 160px;
                    }
                }
                &-3{
                    clip-path: url(#p-homeSubMask3);
                    width: 150px;
                    height: 150px;
                    top: 35vh;
                    right: 15vw;
                    @media screen and (max-height:670px) {
                        top: 28vh;
                     }
                    &-inner{
                        width: 150px;
                    height: 150px;
                    }
                }
            }   
        }  

        &5{
            &-path{
                &-1{
                    clip-path: url(#p-homeSubMask1);
                    top: 20vh;
                    transform: translateX(-50%)!important;
                    left: 50%;
                    width: 180px;
                    height: 180px;
                    &-inner{
                        width: 180px;
                        height: 180px;
                    }
                }
                &-2{
                    clip-path: url(#p-homeSubMask2);
                    bottom: 5vh;
                    left: 30vw;
                    width: 160px;
                    height: 160px;
                    &-inner{
                        width: 160px;
                        height: 160px;
                    }
                }
                &-3{
                    clip-path: url(#p-homeSubMask3);
                    width: 150px;
                    height: 150px;
                    bottom: 10vw;
                    right: 15vw;
                    &-inner{
                        width: 150px;
                    height: 150px;
                    }
                }
            }   
        }  

        &6{
            &-path{
                &-1{
                    clip-path: url(#p-homeSubMask1);
                    top: 20vh;
                    right: 25vw;
                    width: 180px;
                    height: 180px;
                    @media only screen and (min-device-width: 768px) and (max-device-width: 1024px) {
                        right: 40vw!important;
                    }
                     @media screen and (max-height:815px) {
                        // bottom: 20vh;
                        // top: auto;
                         right: auto;
                         left: 30vw;
                     }
                     @media screen and (max-width:1165px) {
                        // bottom: 20vh;
                        // top: auto;
                        right: 25vw;
                         left: auto;
                     }
                    &-inner{
                        width: 180px;
                        height: 180px;
                    }
                }
                &-2{
                    clip-path: url(#p-homeSubMask2);
                    bottom: 2vh;
                    left: 23vw;
                    width: 180px;
                    height: 180px;
                    @media only screen and (min-device-width: 768px) and (max-device-width: 1024px) {
                        bottom: 20vh!important;
                        left: 18vw!important;
                    }
                    @media screen and (max-height:700px) and (max-width: 1400px) {
                        bottom: 1vh;
                        top: auto;
                        right: auto;
                        left: 10vw;
                     }
                    &-inner{
                        width: 180px;
                        height: 180px;
                    }
                }
                &-3{
                    clip-path: url(#p-homeSubMask3);
                    width: 180px;
                    height: 180px;
                    bottom: 2vw;
                    right: 39vw;
                    @media screen and (max-height:700px) and (max-width: 1400px) {
                        bottom: 1vh;
                        top: auto;
                        right: auto;
                        left: 29vw;
                     }
                    &-inner{
                        width: 180px;
                    height: 180px;
                    }
                }
            }   
        }  
    }
    .section:first-child{

    }
    .section:nth-child(2){

    }
    &__title{
        font-family: $font-family-serif-shippori;
        @include fz(4.2rem, 3.0rem);
        margin-bottom: 30px;
        span{
            @include fz(5.5rem,4.0rem);
        }
        &-box{
            position: absolute;
            top: 50%;
            transform: translateY(-50%);
            left: 60px;
            @include media-breakpoint-down(sm) {
                position: relative;
                background: $color-white;
                background:  linear-gradient(rgba(255,255,255,0) 0, #fff 15%);
                padding: 170px 40px 200px 20px;
                top: -225px!important;
                transform: none;
             }
        }
        &-text{
            font-family: $font-family-serif-shippori;
            @include fz(1.6rem);
        }
    }

    &__section1{
        background-image: url("/about/features/img/section-1-bg.jpg");
        background-repeat: no-repeat;
        background-size: cover;
        background-position: center center;
        width: 100%;
        height: 100vh;
        display: block;
        position: relative;
        @include media-breakpoint-down(sm) {
            position: static;
            height: auto;
            margin-bottom: -225px;
         }
        .section1{
            &-title{
                color: $color-white;
                position: relative;
                z-index: 3;
                top: 50%;
                transform: translateY(-50%);
                left: 60px;
                @include media-breakpoint-down(sm) {
                    color: $color-black;
                    top: auto;
                    transform: none;
                    left: auto;
                 }
            }
            &-bgtext{
                position: absolute;
                left: 62%;
                top: 65%;
                transform: translate(-50%,-50%);
                @media screen and (min-width:1500px) { 
                    left: 62%;
                }
                @include media-breakpoint-down(sm) {
                    top: 150px;
                    transform: translateX(-50%);
                    width: 115%;
                    left: 50%;
                }
                img{
                    max-width: none;
                    @include media-breakpoint-down(sm) {
                        max-width: 100%;
                        width: 100%;
                    }
                }
            }
            &-mori{
                &-op{
                    position: absolute;
                    z-index: 10;
                    transition: 5s;
                    transition-delay: 0.5s;

                    img{
                        width: 100%;
                        height: 100vh;
                    }
                    &.right{
                        transform: translate(0);
                        right: 0;
                    }
                    &.left{
                        transform: translate(0);
                        left: 0;
                    }

                }
                &-base{
                    position: absolute;
                    z-index: 1;
                    bottom: 0;
                    width: 100%;
                    @include media-breakpoint-down(sm) {
                        position: relative;
                        margin-top: 200px;
                        bottom: auto;
                        margin-top: 70px;
                        display: block;
                     }
                    img{
                        object-fit: cover;
                        width: 100%;
                        height: 100vh;
                        @include media-breakpoint-down(sm) {
                            object-fit: contain;
                            width: 100%;
                            height: 560px;
                        }
                    }
                }
                &-1{
                    position: absolute;
                    z-index: 2;
                    bottom: 0;

                    transform: translateX(-50px);
                    opacity:0;
                    transition: 2s;
                    // transition-delay: 3s;
                    @include media-breakpoint-down(sm) {
                      top: 105vw;
                      left: 0;
                      width: 40%;
                     }
                    img{
                        max-width: none;
                        @include media-breakpoint-down(sm) {
                            max-width: 100%;
                        }
                    }
                }
                &-2{
                    position: absolute;
                    z-index: 2;
                    bottom: 0;
                    right: 0;
                    transform: translateX(50px);
                    opacity:0;
                    transition: 2s;
                // transition-delay: 3s;
                    @include media-breakpoint-down(sm) {
                      top: 105vw;
                      right: 0;
                      width: 40%;
                     }
                    img{
                        max-width: none;
                        @include media-breakpoint-down(sm) {
                            max-width: 100%;
                        }
                    }
                }
            }
        }
    }

    &__section2{
        background-image: url("/about/features/img/section-2-bg.jpg");
        background-repeat: no-repeat;
        background-size: cover;
        background-position: center center;
        width: 100%;
        height: 100vh;
        display: block;
        position: relative;
        @include media-breakpoint-down(sm) {
            background-image: url("/about/features/img/section-2-bg-sp.jpg");
            background-size: contain;
            background-position: top;
            position: static;
            height: auto;
            margin-bottom: -100px;
         }
        &::after{
            background: #E8BD2F;
        }
        .section2{
            &-title{
                
            }
            &-human{
                &-base{
                    position: absolute;
                    z-index: 1;
                    right: 0;
                    bottom: 0;
                    margin-left: 257px;
                    max-width: 1300px;
                    @include media-breakpoint-down(sm) {
                        position: static;
                        margin-left: 0px;
                        margin-top: 100px;
                        width: 150%;
                     }
                    img{ 
                    }
                }
            }
            &-bgtext{
                position: absolute;
                left: 0;
                @include media-breakpoint-down(sm) {
                    position: static;
                 }
                img{ 
                    max-width: none;
                    width: 100vh;
                    @include media-breakpoint-up(md) {
                        position: relative;
                        left: -85px;
                    }

                }
                figure{
                    position: relative;
                }
                .section2-title{
                    @include media-breakpoint-down(sm) {
                        top: -100px !important;
                        left: 0;
                     }
                }
            }
        }
    }

    &__section3{
        background-image: url("/about/features/img/section-3-bg.jpg");
        background-repeat: no-repeat;
        background-size: cover;
        background-position: center center;
        width: 100%;
        height: 100vh;
        display: block;
        position: relative;
        @include media-breakpoint-down(sm) {
            background-image: url("/about/features/img/section-3-bg-sp.jpg");
            background-size: contain;
            background-position: top;
            position: static;
            height: auto;
            margin-bottom: -85px;
         }
        &::after{
            background: #3278C6;
        }
        .section3{
            &-title{
                
                z-index: 1;
                &-inner{
                    position: relative;
                    @include media-breakpoint-down(sm) {
                        position: static;
                     }
                    &::after{
                        content: "";
                        background-image: url("/about/features/img/section-3-textbg.png");
                        background-size: contain;
                        background-repeat: no-repeat;
                        width: 700px;
                        height: 640px;
                        display: block;
                        position: absolute;
                        top: 50%;
                        left: 26%;
                        transform: translate(-50%,-50%);
                        z-index: -1;
                    }
                }
            }
            &-bgtext{
                position: absolute;
                right: 0;
                transform: translateX(145px);
                min-width: 850px;
                // @include media-breakpoint-up(md) {
                //     right: -80vw;
                // }
                // @media screen and (min-width:800px) { 
                //     right: -70vw;
                // }
                // @media screen and (min-width:950px) { 
                //     right: -50vw;
                // }
                // @media screen and (min-width:1100px) { 
                //     right: -30vw;
                // }
                // @media screen and (min-width:1250px) { 
                //     right: -20vw;
                // }
                // @media screen and (min-width:1400px) { 
                //     right: -10vw;
                // }
                // @media screen and (min-width:1500px) { 
                //     right: 0;
                // }
                // @include media-breakpoint-down(sm) {
                //     position: static;
                //  }
                @media screen and (max-width:1330px) { 
                    transform: translateX(500px);
                }
                @media screen and (max-height:720px) { 
                    transform: translateX(400px);
                }
              
                img{ 
                    height: 100vh;
                    @media only screen and (min-device-width: 768px) and (max-device-width: 1024px) {
                        height: 100vh;
                    }
                    @media screen and (max-height:720px) { 
                        height: 100%;
                    }
                }
                figure{
                    position: relative;
                }
            }
            &-human{
               &-base{
                position: absolute;
                bottom: 0;
                z-index: 1;
                @media screen and (max-height:720px) { 
                    width: 600px;
                }
                @media screen and (max-width:1330px) { 
                    width: 600px;
                }
                @include media-breakpoint-down(sm) {
                    width: auto;
                 }
                //  @include media-breakpoint-up(md) {
                //      right: -20vw;
                //  }
                //  @media screen and (min-width:800px) { 
                //      right: 20vw;
                //  }
                //  @media screen and (min-width:1100px) { 
                //      right: 200px;
                //  }
                @include media-breakpoint-down(sm) {
                    position: static;
                    margin-top: 100px;
                 }
               }
            }
        }
        .section3-title{
            @include media-breakpoint-down(sm) {
                top: -135px !important;
                left: 0;
             }
        }
    }


    &__section4{
        background-image: url("/about/features/img/section-4-bg.jpg");
        background-repeat: no-repeat;
        background-size: cover;
        background-position: center center;
        width: 100%;
        height: 100vh;
        display: block;
        position: relative;
        @include media-breakpoint-down(sm) {
            background-image: url("/about/features/img/section-4-bg-sp.jpg");
            background-size: contain;
            background-position: top;
            position: static;
            height: auto;
            margin-bottom: -135px;
         }
        &::after{
            background: #33A17D;
        }
        .section4{
            &-horse{
                &-base{
                    position: absolute;
                    z-index: 1;
                    right: -130px;
                    bottom: 0;
                    @include media-breakpoint-down(sm) {
                        position: static;
                        margin-top: 350px;
                        margin-right: -100px;
                     }
                }
            }
            &-bgtext{
                position: absolute;
                left: 0;
                top: 53%;
                transform: translateY(-50%);
                color: $color-white;
                @include media-breakpoint-down(sm) {
                    position: static;
                    transform: none;
                    color: $color-black;
                 }
                img{ 
                    max-width: none;
                }
                figure{
                    position: relative;
                }
            }
        }
        .section4-title{
            @include media-breakpoint-down(sm) {
                top: -135px !important;
                left: 0;
             }
        }

    }
    &__section5{
        background-image: url("/about/features/img/section-5-bg.jpg");
        background-repeat: no-repeat;
        background-size: cover;
        background-position: center center;
        width: 100%;
        height: 100vh;
        display: block;
        position: relative;
        @include media-breakpoint-down(sm) {
            background-image: url("/about/features/img/section-5-bg-sp.jpg");
            background-size: contain;
            background-position: top;
            position: static;
            height: auto;
            margin-bottom: 0px;
         }
        &::after{
            background: #8D8D8D;
        }
        .section5{
            &-title{
                color: $color-white;
                @include media-breakpoint-down(sm) {
                    color: $color-black;
                 }
            }
            &-block{
                display: flex;
            }
            // &-item{
            //     background-repeat: no-repeat;
            //     background-size: cover;
            //     background-position: center center;
            //     width: 100%;
            //     display: block;
            //     position: relative;
            //     flex: 0 0 50%;
            //     &.left{
            //         background-image: url("/about/features/img/section-5-l-bg.jpg");
            //     }
            //     &.right{
            //         background-image: url("/about/features/img/section-5-r-bg.jpg");
            //         @include media-breakpoint-down(lg) {
            //             background-position: 25%;
            //         }
            //     }
            // }
        }
        .section5-title{
            @include media-breakpoint-down(sm) {
                top: 0px !important;
                left: 0;
                margin-top: 400px;
             }
             .this-is__title{
                img{
                    display: block;
                }
             }
        }
    }
    &__section6{
        background-image: url("/about/features/img/section-6-bg.jpg");
        background-repeat: no-repeat;
        background-size: cover;
        background-position: center center;
        width: 100%;
        height: 100vh;
        display: block;
        position: relative;
        @include media-breakpoint-down(sm) {
            position: static;
            height: auto;
            background-image: url("/about/features/img/section-6-bg-sp.jpg");
            background-size: contain;
            background-position: top;
            margin-bottom: -225px;
         }
        .section6{
            &-human{
                &-base{
                    position: absolute;
                    z-index: 1;
                    right: 0;
                    bottom: 0;
                    margin-left: 200px;
                    @media only screen and (min-device-width: 768px) and (max-device-width: 1024px) {
                        margin-left: 0px!important;
                    }
                    @include media-breakpoint-down(sm) {
                        position: relative;
                        margin-left: 0px;
                        margin-top: 100px;
                     }
                    img{ 
                    
                    }
                }
            }
            &-text2{
                position: absolute;
                z-index: 1;
                right: 100px;
                bottom: 0;
                @media screen and (max-width:1175px) { 
                    right: 100px;
                }
                @include media-breakpoint-down(sm) {
                    position: relative;
                    right: 0;
                    z-index: 1;
                    margin-top: -180px;
                    margin-bottom: 90px;
                 }
                img{
                    width: 28vw;
                    max-width: 375px;
                    @include media-breakpoint-down(sm) {
                        width: 70%;
                        margin: 0px auto;
                        display: block;
                     }
                }
            }
            &-bgimg{
                text-align: center;
                img{
                    @media only screen and (min-device-width: 768px) and (max-device-width: 1024px) {
                        height: 100vh;
                    }
                    @media screen and (max-height:730px) { 
                        transform: translateY(-30px);

                    }
                }
            }
        }
        .section6-title{
            @include media-breakpoint-down(sm) {
                left: 0;
             }
        }
    }


    .section7{
        &-bg{
            margin-bottom: 0;
            position: relative;
        }
        &-btnarea{
            position: absolute;
            left: 50%;
            transform: translateX(-50%);
            z-index: 2;
            bottom: 20px;
            text-align: center;
            @media screen and (max-width:1185px) { 
                bottom: 15px;
            }
            @include media-breakpoint-down(sm) {
                text-align: left;
                width: 100%;
                padding: 0 20px;
             }
            .text{
                font-family: $font-family-serif-shippori;
            }
            a{
                font-family: $font-family-garamond;
                max-width: 450px;
                display: block;
                padding: 10px 40px;
                color: $color-white;
                text-align: center;
                text-decoration: none;
                border-radius: 30px;
                background: linear-gradient(#5fa0b9 0%, #498bb1 27.46%, #10579d 84.37%, #004898 100%);
                position: relative;
                @include fz(2rem , 1.6rem);
                @media screen and (max-width:1185px) { 
                    font-size: 1.2rem;
                }
                @include media-breakpoint-down(sm) {
                    max-width: none;
                    padding: 10px 30px;
                    width: 100%;
                 }
                &::after{
                    font-family: 'FontAwesome';
                    content: '\f138' ;
                    position: absolute;
                    display: inline-block;
                    top: 53%;
                    transform: translateY(-50%);
                    right: 10px;
                    font-size: 24px;
                }
            }
        }

        .section7-title{
            @include media-breakpoint-down(sm) {
                top: -100px !important;
                left: 0;
                padding-top: 120px;
                padding-bottom: 50px;
                margin-bottom: 60px;
             }
        }
    }



    &.en{
        .thisis-slider{
            background-color: $color-white;
            z-index: 5;
            max-width: 550px;
            position: absolute;
            bottom: 60px;
            left: 65px;
            padding: 30px 40px;
            @include media-breakpoint-down(sm) {
                display: none;
             }
            @media screen and (max-height:810px) { 
                bottom: 20px;
                // max-width: 550px;
            }
            &_item{
                display: flex;
                flex-direction: column;
                justify-content: space-between;
                min-height:60px;
                span{
                    background-color: $color-black;
                    color: $color-white;
                    @include fz(1.4rem);
                    padding: 3px 5px;
                    width: 110px;
                    text-align: center;
                    display: inline-block;
                }
            }
            a{
                display: inline-block;
                color: $color-black;
                text-align: right;
            }
            .slick-arrow:before{
                content:""!important;
            }
            .slick-arrow:before{
                content:""!important;
                width: 100%!important;
                height: 100%!important;
                position: absolute;
                top: 12px;
            }
            
            .slick-next{
                right: 10px;
                &::before{
                    background: url(/en/about/features/img/sliderarrow-next.png)!important;
                    background-size: contain!important;
                    right: 10px;
                }
            }
            
            .slick-prev{
                left: 10px;
                &::before{
                    background: url(/en/about/features/img/sliderarrow-prev.png)!important;
                    background-size: contain!important;
                    left: 10px;
                }
            }
        }
    }

    .this-is__title{
        @include fz(3.8rem, 3.0rem);
    }

    .this-is__title-box{
        top: 45%;
    }
    .section2-bgtext img{
        left: 0;
        @media screen and (max-width:1120px) { 
            left: -110px;
            width: 80vw;
        }
    }

    .section4-bgtext{
        top: 47%;
        .this-is__title-box{
            top: 50%;
        }
    }
    .section2-human-base{

        @media only screen and (min-device-width: 768px) and (max-device-width: 1024px) {
            margin-left: 0;
            width: 100%;
        }
        @include media-breakpoint-up(md) {
            margin-left: 405px;
         }
    }

    .section3-title-inner{
       &::after{
        width: 1000px;
        height: 1000px;
        top: 72%;
       } 
    }
    
    .section3-bgtext{
        // @media screen and (max-width:1040px) { 
        //     right: -65vw!important;
        // }
        // @media screen and (max-width:1340px) { 
        //     right: -60vw;
        // }
        // @media screen and (min-width:1330px) { 
        //     right: -20vw;
        // }
        // @media screen and (min-width:1460px) { 
        //     right: -10vw;
        // }
        // @media screen and (min-width:1630px) { 
        //     right: 0;
        // }


    }
    
    
    .section6-human-base{
        @include media-breakpoint-up(md) {
            margin-left: 340px;
         }
    }

    .popup-modal__item p{
        text-align: inherit;
    }

}