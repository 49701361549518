@charset 'utf-8';
.staff{
	// .bunya{
	// 	background: $color-main;
	// 	color: $color-white;
	// }
	// .syozoku{
	// 	background: $color-gray;
	// 	color: $color-main;
	// 	.link_item{
	// 		a{
	// 			color: $color-main;
	// 		}
	// 	}
	// }
	// .gojyuon{
	// 	border: 2px solid $color-main;
	// 	color: $color-main;
	// 	.link_item{
	// 		a{
	// 			color: $color-main;
	// 		}
	// 	}
	// }

	.staff_index{
		.tab-content{
			display: block!important;
		}
		.close_btn{
			display: none!important;
		}
		.close_btn-sp{
			display: none!important;
		}
		.staff__tab-content{
			@include media-breakpoint-down(sm) {
				padding: 20px!important;
			}
		}
	}

	.gojuon_link{
		margin-top: 60px;
	}

	&__tab{
		&-head{
			.nav-item{
				width: 200px;
				text-align: center;
				@include media-breakpoint-down(sm) {
					width: 33.3333%;
					&:last-child{
						a{
							border-right: 2px solid $color-main;
						}
					}
				}
			}
			.nav-link{
				padding: 15px 0;
				font-weight: bold;
				border: 2px solid $color-main;
				color: $color-main;
				@include media-breakpoint-down(sm) {
					border-right: none;
				}
				&:hover{
					background: $color-main;
					color: $color-white;
					opacity: 1;
				}
				&.active{
					background: $color-main;
					color: $color-white;
				}
			}

		}
		.tab-content{
			display: none;
		}
		&-labellist{
			display: flex;
			flex-wrap: wrap;
			/*リセット */
			input[type="checkbox"]  {
				margin: 0;
				padding: 0;
				background: none;
				border: none;
				border-radius: 0;
				outline: none;
				appearance: none;
				-webkit-appearance: none;
				-moz-appearance: none;
			}
			input[type="checkbox"] {
				cursor: pointer;
				padding-left: 30px;
				vertical-align: middle;
				position: relative;
				top: 14px;
				&::before,&::after{
					content: "";
					display: block; 
					position: absolute;
				}
				&::before{
					background-color: #fff;
					border-radius: 0%;
					border: 1px solid $color-main;
					width: 20px;
					height: 20px;
					transform: translateY(-50%);
					top: 50%;
					left: 5px;
				}
				&::after{
					border-bottom: 3px solid $color-main;
					border-left: 3px solid $color-main;
					opacity: 0;
					height: 6px;
					width: 11px;
					transform: rotate(-45deg);
					top: -4px;
					left: 10px;
				}
				&:checked::after {
					opacity: 1;
				  }
			}
			label{
				width: calc(33.3333% - 20px);
				margin-right: 10px;
				display: flex;
				align-items: flex-start;
			}
			&:nth-child(3n){
				margin-right: 0;
			}
		}
		&-content{
			padding: 60px 40px;
			position: relative;
			background: $color-main;
			margin-top: -2px;
			@include media-breakpoint-down(sm) {
				padding: 50px 20px 20px;
			}
			.gakubu-box{
				margin-bottom: 20px;
			}
			.ttl_style4{
				color: $color-white;
				border-bottom: 1px solid $color-white;
				margin-top: 0;
				margin-bottom: 15px
			}
			.link_item{
				display: inline-block;
				margin-bottom: 10px;
				margin-right: 5px;
				a{
					background: $color-white;
					color: $color-main;
					padding: 5px 10px;
					text-decoration: none;
					font-weight: bold;
				}
			}
			.close_btn{
				&::after{
					content: "×";
					font-size: 30px;
					cursor: pointer;
					position: absolute;
					right: 30px;
					top: 10px;
					color: $color-white;
					@include media-breakpoint-down(sm) {
						right: 10px;
						top: 0px;
					}
				}
			}
			.close_btn-sp{
				display: none;
				@include media-breakpoint-down(sm) {
					display: block;
					margin: 30px auto 0;
					background: $color-white;
					color: $color-black;
					text-align: center;
					width: 150px;
					padding: 10px 0;
					font-weight: bold;
				}
			
			}
		}
	}

	&__content{
		margin: 60px 0;
		a{
			text-decoration: none;
		}
		.list_item{
			&.is-hide{
				display: none;
			}
		}
		.post-sys__list{
			gap: 26.5px;
		}
	}

	&__list{
		display: flex;
		flex-wrap: wrap;
		@include media-breakpoint-down(sm) {
			display: block;
		}
		&_item{
			list-style: none;
			width: calc(50% - 30px);
			margin-right: 60px;
			margin-bottom: 30px;
			@include media-breakpoint-down(sm) {
				width: 100%;
				margin-right: 0px;
			}
			&:nth-child(even){
				margin-right: 0;
			}
		}
	}


	&__link{
		display: flex;
		width: 100%;
		background: $color-gray-light2;
		figure{
			position: relative;
			flex: 0 0 250px;
			margin-bottom: 0;
			display: flex;
			flex-direction: column;
			justify-content: end;
			@include media-breakpoint-down(sm) {
				flex: 0 0 150px;
			}
			&::before{
				content: "";
				background-image: url(/img/common/img_arrow.jpg);
				width: 45px;
				height: 45px;
				position: absolute;
				bottom: 0;
				right: 0;
				background-size: contain;
				z-index: 1;
				@include media-breakpoint-down(sm) {
					width: 25px;
					height: 25px;
				}
			}
		}
		img{
			width: 100%;
			max-width: 250px;
			object-fit: cover;
			aspect-ratio: 1/1;
			@include media-breakpoint-down(sm) {
				max-width: 150px;
			}
		}
		&_text{
			// margin-left: 20px;
			width: 100%;
			padding: 20px;
			@include media-breakpoint-down(sm) {
				padding: 10px;
			}
			.text1{
				@include media-breakpoint-down(sm) {
					font-size: 1.3rem;
				}
			}
			.text2{
				font-weight: bold;
				@include media-breakpoint-down(sm) {
					font-size: 1.3rem;
				}
			}
		}
	}

	&__column{
		max-width: 800px;
		margin: 180px auto 0;
		@include media-breakpoint-down(sm) {
			margin: 80px auto 0;
		}
		p{
			text-align: center;
		}
		.btn_style3{
			a{
				width: 80%;
				margin: 0 auto;
				@include media-breakpoint-down(sm) {
					width: 100%;
				}
			}
		}
	}




	&.detail{
		.staff{
			&__detail{
				&-thum{

				}
				&-name{
					@include fz(3.4rem);
					@include ff-serif();
					font-weight: bold;
					@include media-breakpoint-down(sm) {
						margin-top: 20px
					}
					p{
						@include fz(1.6rem);
						font-weight: normal;
						font-family: $font-family-sans-serif;
						margin-top: 10px;
					}
				}
				&-prof{
					background: $color-main;
					padding: 40px;
					@include media-breakpoint-down(sm) {
						padding: 20px;
					}
					&title{
						color: $color-white;
						margin-bottom: 20px;
					}
					&box{
						display: flex;
						align-items: flex-start;
						@include media-breakpoint-down(sm) {
							display: block;
						}
						.label{
							background: $color-white;
							color: $color-main;
							flex: 0 0 90px;
							text-align: center;
							font-weight: bold;
							@include media-breakpoint-down(sm) {
								max-width: 90px;
							}
						}
						.text{
							color: $color-white;
							margin-left: 10px;
							@include media-breakpoint-down(sm) {
								margin-left: 0px;
							}
						}
					}
				}
				&-content{
					margin: 60px 0;
					.r-map-content {
						span{
							margin-right: 5px;
						}
						a{
							display: inline-block;
							vertical-align: top;
							position: relative;
							padding-right: 25px;
							&::after{
								position: absolute;
								right: 0;
								top: 0px;
								padding-left: 10px;
							}
						}
					}
				}
				&-item{
					margin-bottom: 40px;
					&:last-child{
						margin-bottom: 0;
					}
				}
			}
		}



		//リサーチマップ関連
		.r-year{
			flex-shrink: 0;
			width: 180px;
			@include media-breakpoint-down(sm) {
				display: block;
			}
		}
		.r-box{
			display: flex;
			@include media-breakpoint-down(sm) {
				display: block;
			}
		}
		.more{
			cursor: pointer;
			width: 80px;
			height: 80px;
			background: $color-main;
			color: $color-white;
			text-align: center;
			font-size: 14px;
			display: flex;
			justify-content: center;
			align-items: center;
			border-radius: 50%;
			margin: 30px auto 0;
			position: relative;
			&::after{
				font-family: fontawesome;
				content: "\f107";
				position: absolute;
				color: $color-white;left: 50%;
				transform: translateX(-50%);
				bottom: 5px;
			}
			&.more-close{
				&::after{
					content: none!important;
				}
			}
		}
		.staff__detail-item{
			li{
				&.is-hidden{
					display: none;
				}
			}
		}
		
	}
}