@charset 'utf-8';
.page.life {

	.life-img_text {
		font-size: 1.6rem;
	}


	
	.admissions {
		&__mv {
			&-btnlist {
				&_row {
					display: flex;
					gap: 30px;
					width: 100%;
					@include media-breakpoint-down(sm) {
						display: block;
					}
				}
				&_item {
					width: 100%;
				}
				&_btn{
					a{
						display: flex;
						align-items: center;
						justify-content: center;
						height: 80px;
						background: $color-white;
						border: 1px solid $color-white;
						@include fz(1.8rem,1.6rem);
						font-weight: bold;
						line-height: 1.56;
						text-align: center;
						&::after{
							content: "";
							background-image: url(/img/common/arrow_next_black.svg);
							background-repeat: no-repeat;
							width: 15px;
							height: 7px;			
							margin-left: 10px;
						}
						&:hover {
							background: $color-main;
							border: 1px solid $color-main;
							color: $color-white;
							&::after{
								background-image: url(/img/common/arrow_next_white2.svg);
							}
						}
						img {
							position: absolute;
							left: 30px;
							top: 50%;
							transform: translateY(-50%);
							display: inline-block;
							width: 50px;
						}
					}
				}
			}
		}
		&__linklist{
			&_box{
				display: flex;
				border-left: 3px solid $color-main;
				@include media-breakpoint-down(sm) {
					display: block;
					border-left: none;
				}
			}
			&_title{
				flex: 0 0 240px;
				font-weight: bold;
				@include fz(2rem,2rem);
				padding-left: 15px;
				color: $color-main;
				@include media-breakpoint-down(sm) {
					border-bottom: 3px solid $color-main;
					margin-bottom: 30px;
				}
			}
			&_main{
				width: 100%;
			}
			&_list{
				display: flex;
				flex-wrap: wrap;
				width: 100%;
				@include media-breakpoint-down(sm) {
					display: block;
					border-left: none;
				}
				.btn_style{
					width: calc(50% - 22.5px);
					margin-right: 45px!important;
					@include list-reset();
					@include media-breakpoint-down(sm) {
						width: 100%;
						margin-right: 0;
					}
					&:nth-child(even){
						margin-right: 0!important;
					}
				}
			}
		}
	}
}
