@charset 'utf-8';
.news{
	&__sort{
		&-box{
			background: $color-gray-light2;
			padding: 40px;
			@include media-breakpoint-down(sm) {
				padding: 40px 20px;
				margin-top: 30px;
			}
		}
		&-btn{
			border: 1px solid $color-main;
			background-color: $color-white;
			list-style: none;
			&.active{
				background: $color-main;
				a{
					color: $color-white;
				}
			}
			&box{
				display: flex;
				flex-wrap: wrap;
				gap: 10px;
				a{
					display: inline-block;
					text-decoration: none;
					color: $color-main;
					padding: 5px 10px;
				}
			}
		}
	}
}