@charset 'utf-8';
.contact{
	.contact-item{
		background: $color-gray-light2;
		padding: 60px 10px;
		form{
			margin: 0 auto;
			max-width: 960px;
			.aform-name-ul,.aform-kana-ul{
				label{
					display: block;
				}
			}
		}
		li{
			list-style: none;
		}
		dl{
			display: flex;
			margin-bottom: 30px;
			@include media-breakpoint-down(sm) {
				display: block;
			}
			dt{
				flex: 0 0 300px;
			}
			input{
				@include media-breakpoint-down(sm) {
					width: 100%;
				}
			}
		}
		.aform-error{
			color: $color-red;
		}

		.aform-name-ul,.aform-kana-ul{
			display: flex;
				@include media-breakpoint-down(sm) {
					display: block;
				}
			li:first-child{
				margin-right: 10px;
				@include media-breakpoint-down(sm) {
					margin-right: 0px;
				}
			}
		}

		.aform-label{
			@include fz(2rem,1.6rem);
			.aform-required{
				@include fz(1.6rem,1.4rem);
				color: $color-red;
				display: block;
			}
		}

		.formmessage{
			display: block;
		}
		.aform-twice-note{
			display: block;
		}

		.dl_formmessage{
			dd{
				width: 100%;
				textarea{
					width: 100%;
				}
			}
		}

		.form-txt-check{
			text-align: center;
		}

		.submit{
			max-width: 200px;
			margin: 40px auto 0;
			input{
				width: 200px;
				margin: 0 auto;
				padding: 10px 10px;
				color: $color-white;
				background-color: $color-main;
				transition: .2s;
				border: none!important;
				&:hover{
					opacity: .6;
				}
			}
		}




		//確認画面
		#aform-confirm-form{
			.submit{
				display: flex;
				max-width: 500px;
				justify-content: space-between;
			}
		}
	}
}