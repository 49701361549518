@charset 'utf-8';
.books{
	.post-sys__date{
		margin-bottom: 0;
	}
	.post-sys__text{
		font-weight: bold;
		margin-bottom: 0;
	}
	&:not(.detail){
		.post-sys__thumnail{
			background: $color-gray-light2;
			padding: 30px 10px;
			width: 100%;
			height: 275px;
			@include media-breakpoint-down(sm) {
				height: auto;
			}
			img{
				object-fit: contain;
				aspect-ratio: auto;
				width: 100%;
				height: 100%;
			}
		}
	}



	&.detail{
		.post-sys__thumnail{
			&.media-thumnail{
				background: $color-gray-light2;
				padding: 30px;
				width: 100%;
				height: 275px;
				img{
					object-fit: contain!important;
					aspect-ratio: auto;
					width: 100%;
					height: 100%;
				}
				@include media-breakpoint-down(sm) {
					margin-bottom: 30px;
				}
			}
		}
		.ttl_style1{
			margin-top: 0px;
			&::before{
				margin-bottom: 5px!important;
			}
		}
		.post-sys__date{
			margin-bottom: 5px;
		}
		.post-sys__text{
			font-weight: normal!important;
		}


		.books_authorname{
			font-weight: bold;
			@include fz(1.8rem,1.6rem);
		}
	}
}