@charset 'utf-8';
.important{
    .contents{
        background: $color-gray-light2;
        .inner-wide{
            background: $color-white;
            padding: 60px;
            @include media-breakpoint-down(sm) {
                padding: 30px;
			}
        }
    }
    &_ttl{
        @include fz(3rem,2.4rem);
        background: $color-main;
        color: $color-white;
        @include ff-serif();
        padding: 30px;
        font-weight: bold;
    }
}