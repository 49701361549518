@charset 'utf-8';
.home{
	.this-is_logo{
		display: none;
	}
}
.page{
	.gheader.nav-active{
		.gheader__contents{
			transition: none;
			opacity: 1;
		}
	}
	.this-is_logo{
		display: none;
	}
}
.gheader__megamenu{
	@include media-breakpoint-down(sm) {
		opacity: 0;
		visibility: hidden;
	}
}
.gheader {
	// @include media-breakpoint-up(sm) {
	// }
	// @include media-breakpoint-up(md) {
	// }
	// @include media-breakpoint-up(lg) {
	// }
	// @include media-breakpoint-up(xl) {
	// }
	position: absolute;
	width: 100%;
	padding: 30px 20px 20px;
	@include media-breakpoint-down(sm) {
		padding: 0;
	}
	
	&__contents{
		position: relative;
		opacity: 0;
		visibility: hidden;
		top: -300px;
		@include media-breakpoint-down(sm) {
			opacity: 1;
			visibility: visible;
			top: 0px;
			position: static;
		}
	}
	&__top{
		
	}
	&__logo{
		max-width: 270px;
		@include media-breakpoint-down(sm) {
			max-width: none;
			position: fixed;
			z-index: 999;
			background: $color-white;
			width: 100%;
			padding: 10px 0;
		}
		a{
			@include media-breakpoint-down(sm) {
				width: 80%;
				margin: 0 auto;
				display: block;
				max-width: 220px;
			}
		}
	}
	&__inner{
		display: flex;
		justify-content: space-between;
		z-index: 1;
		position: relative;
		@include media-breakpoint-down(sm) {
			display: none;
		}
	}
	&__common{
		@include media-breakpoint-down(sm) {
			display: none;
		}
		&-list{
			display: flex;
			position: relative;
		}
		&-item{
			color: $color-white;
			margin-right: 20px;
			p{
				margin-bottom: 0;
			}
			&:last-child{
				margin-right: 0;
			}
			// &:hover{
			// 	.gheader__subcommon-list{
			// 		top: 60px!important;
			// 		opacity: 1!important;
			// 		visibility: visible!important;
			// 	}
			// }
			&.menu-contents{
				.top_menu-close{
					&::after{
						content: "×";
						position: absolute;
						z-index: 1;
						font-size: 34px;
						line-height: 1;
						cursor: pointer;
						right: 5px;
						top: 68px;
						transition:  0.3s linear;
						visibility: hidden;
						opacity: 0;
					}
				}
				.gheader{
					&__common{
						&-link{
							cursor: pointer;
							transition: all .2s linear;
							padding-bottom: 10px;
							margin-bottom: -10px;
							&:hover{
								opacity: .6;
							}
						}	
						
					}
					&__subcommon{
						&-list{
							position: absolute;
							width: 100%;
							padding: 30px 20px 20px 20px;
							opacity: 0;
							visibility: hidden;
							top: 40px;
							transition:  0.2s linear;
							background: $color-main;
							min-width: 400px;
							&::before{
								content: "";
								position: absolute;
								display: block;
								top: -14px;
								left: 15px;
								width: 0;
								height: 0;
								border-style: solid;
								border-width: 0 15px 25px 15px;
								border-color: transparent transparent $color-main transparent;
							}		
							
							&.menu-research{
								right: 0px;
								&::before{
									left: auto;
									right: 0px;
								}
								.gheader__subcommon-item{
									border: none;
								}
								input{
									border: none;
									width: 85%;
								}
								button{
									background: none;
									border: none;
								}
							}
						}	
						&-item{
							border-bottom: 1px solid $color-white;
							margin-bottom: 10px;
						}
						&-link{
							color: $color-white;
							padding-bottom: 10px;
							display: block;
						}
					}
				}

				&.top_menu-active{
					.gheader{
						&__subcommon{
							&-list{
								opacity: 1;
								visibility: visible;
								top: 65px;					
							}	
							&-detailbox{
								.top_menu-close{
									&::after{
										visibility: visible;
										opacity: 1;
									}
								}
							}
						}
					}
				}
			}
			&.menu-search{
				.gheader{
					&__common{
						&-link{
							cursor: pointer;
						}	
					}
				}
			}
		}
		&-link{
			@include fz(1.4rem);
			color: $color-white;
			display: block;
			text-shadow:0px 3px 6px #000;
		}
		&-icon{
			display: block;
			margin: 0 auto 5px;
			height: 20px;
		}
	}


//コンテンツヘッダ
	&.nav-active{
		position: fixed;
		z-index: 99;
		padding: 0!important;
		top: 0;
		@include media-breakpoint-down(sm) {
			z-index: 0;
			opacity: 0;
			visibility: hidden;
		}
		&.spmenu-active{
			@include media-breakpoint-down(sm) {
				z-index: 99;
				opacity: 1;
				visibility: visible;
			}
			.gheader__contents{
				@include media-breakpoint-down(sm) {
					opacity: 1;
					visibility: visible;
				}
			}
		}
		.top-header{
			display: none;
		}
		.contents-header{
			display: flex;
			align-items: center;
			background: $color-white;
			padding: 10px 20px 10px!important;
			@include media-breakpoint-down(sm) {
				display: none;
			}
		}

		.gheader{
			&__contents{
				position: relative;
				top: 0px!important;
				opacity: 1;
				visibility: visible;
				transition: 1s;
				@include media-breakpoint-down(sm) {
					opacity: 0;
					transition: none;
					visibility: hidden;
				}
			}
			&__logo{
				a{
					width: 80%;
					display: block;
				}
			}
			&__common{
				.gheader__subcommon-list{
					// top: 131px!important;
				}
				&-link{
					color: $color-black;
					text-shadow: none;
				}
				&-icon{
					height: 20px;
				}
			}

			&__megamenu{
				width: 100%;
				background: $color-white;
				@media screen and (max-width:1035px) { 
					overflow-x: auto;
					-ms-overflow-style: none;
					scrollbar-width: none;
					&::-webkit-scrollbar{
						display:none;
					}
				}
				@include media-breakpoint-down(sm) {
					opacity: 0;
					visibility: hidden;
					background: $color-white;
					transition: all .3s;
					height: 100vh;
					&.spmenu-active{
						opacity: 1;
						visibility: visible;
					}
				}
				li{
					&:last-child{
						@include media-breakpoint-down(sm) {
							margin-bottom: 100px;
						}
					}
				}
				&-list{
					background-color: $color-gray-light2;
					display: flex;
					gap: 0px 30px;
					justify-content: flex-end;
					padding: 16px 20px 16px 0;
					margin-bottom: 0;
					@media screen and (max-width:1035px) { 
						min-width: 1020px;
					}
					@include media-breakpoint-down(sm) {
						position: static;
						min-width:auto;
						display: block;
						padding: 70px 20px 20px;
						background: $color-white;
					}
					@include media-breakpoint-up(md) {
						.menu-closebtn{
							&::after{
								content: "";
								position: absolute;
								z-index: 1;
								top: 140px;
								right: 10px;
								background-image: url(/img/common/menu-close.png);
								background-size: 40px;
								background-repeat: no-repeat;
								width: 40px;
								height: 40px;
								cursor: pointer;
								display: none;
							}
							&.btn-active{
								&::after{
									display: block;
								}
							}
						}
					}
					
				}
				&-item{
					//  &:first-child{
					//  	.gheader__megamenu-link{
					//  		&::before{
					//  			content: none;
					//  		}
					//  	}
					//  }
					.collapse:not(.show){
						@include media-breakpoint-up(md) {
							display: block;
						}
					}
					&.menu-active{
						.gheader__megamenu-contents{
							opacity: 1;
							visibility: visible;
						}
						.gheader__megamenu-link{
							opacity: 1;
							&::after{
								opacity: 1;
								visibility: visible;
							}
						}
					}
					//  &:hover{
					//  	.gheader__megamenu-contents{
					//  		opacity: 1;
					//  		visibility: visible;
					//  	}
					//  	.gheader__megamenu-link{
					//  		opacity: 1;
					//  		&::after{
					//  			opacity: 1;
					//  			visibility: visible;
					//  		}
					//  	}
					//  }
				}
				&-link{
					padding: 20px 0 20px;
					position: relative;
					cursor: pointer;
					@media screen and (max-width:1140px) { 
						font-size: 1.4rem;
					}
					@include media-breakpoint-down(sm) {
						display: block;
						padding: 10px 0;
						margin-bottom: 0px;
						border-bottom: 1px solid $color-gray-light;
						font-weight: bold;
						position: relative;
						padding-right: 20px;
						padding-left: 10px;
						line-height: 2!important;
					}
					&::before{
						content: "";
						height: 15px;
						width: 1px;
						display: block;
						background: $color-black;
						position: absolute;
						margin-top: 6.4px;
						// top: 50%;
						// transform: translateY(-50%);
						left: -15px;
						pointer-events: none;
						@include media-breakpoint-down(sm) {
							content: none;
							margin-top: auto;
						}
					}
					&::after{
						content: "";
						width: 70%;
						height: 3px;
						background-color: $color-main;
						position: absolute;
						bottom: 0px;
						left: 50%;
						transform: translateX(-50%);
						opacity: 0;
						visibility: hidden;
						transition: all .3s;
						border-radius: 20px;
						@include media-breakpoint-down(sm) {
							content: "";
							background-image: url("/img/common/arrow_next_black.svg");
							background-repeat: no-repeat;
							width: 20px;
							height: 10px;
							display: block;
							position: absolute;
							right: 0px;
							left: auto;
							bottom: auto;
							top: 50%;
							transform: translateY(-50%);
							opacity: 1;
							visibility: visible;
							background-color: inherit;
						}
					}

					&.sp-aclink{
						@include media-breakpoint-down(sm) {
							&[aria-expanded="true"]{
								&::after{
									content: none;
								}
							}
							&::before{
								content: "";
								background-image: none;
								background-color: $color-black;
								width: 15px;
								height: 2px;
								right: 7px;
								top: 50%;
								transform: translateY(-50%);
								left: auto;
							}
							&::after{
								content: "";
								background-image: none;
								background-color: $color-black;
								width: 2px;
								height: 15px;
								right: 13px;
								top: 50%;
								transform: translateY(-50%);
								left: auto;
							}
						}
					}
				}

				&-contents{
					position: absolute;
					top: 130px;
					left: 0;
					display: grid;
					grid-template-columns: 8% 25% 67%;
					width: 100%;
					background: $color-white;
					opacity: 0;
					visibility: hidden;
					transition: all .3s;
					max-height: 600px;
					height:  70vh;;
					@include media-breakpoint-down(sm) {
						background: $color-gray-light2;
						height: auto;
						max-height:inherit;
						display: flex;
						flex-direction: column-reverse;
						top: auto;
						left: auto;
						opacity: 1;
						visibility: visible;
						position: static;
						margin-bottom: 30px;
					}
				}
			}

			&__submenu{
				&-title{
					background: $color-main-dark;
					color: $color-white;
					-ms-writing-mode: tb-rl;
					writing-mode: vertical-rl;
					text-align: center;
					display: flex;
					align-items: center;
					padding: 30px 0;
					@include media-breakpoint-down(sm) {
						display: none;
					}
					.text{
						text-align: center;	
						margin: 0;
					}
					span{
						text-align: center;
					}
					.en{
						@include fz(4.6rem);
						@include ff-en();
						line-height: 1;
					}
					.ja{
						@include fz(1.4rem);
						margin-top: 10px;
					}
				}
				&-news{
					background: $color-main;
					color: $color-white;
					padding: 30px 20px;
					&-img{
						img{
							aspect-ratio: 4/3;
							object-fit: cover;
							width: 100%;
						}
					}
					.post-date-1{
						color: $color-white;
					}
					a{
						&::after{
							color: $color-white;
						}
					}

				}
				&-listbox{
					position: relative;
					@include media-breakpoint-down(sm) {
						position: static;
						background: $color-gray-light2;
					}
				}
				&-list{
					padding: 30px 10px 20px 20px;
					width: 50%;
					overflow-y: auto;
					overflow-x: hidden;
					max-height: 600px;
					height: 70vh;
					// -ms-overflow-style: none;
					// scrollbar-width: none;
					// &::-webkit-scrollbar{
					// 	display:none;
					// }
					@include media-breakpoint-down(sm) {
						padding: 20px;
						width: 100%;
						height: auto;
						max-height:inherit;
					}
					.ttl_style2{
						@include fz(2.4rem,1.8rem);
						margin-bottom: 20px;
						a{
							display: block;
						}
					}
					.submenu-title{
						background-color: $color-gray-dark;
						color: $color-white;
						padding: 3px 5px;
						margin-bottom: 10px;
					}
				}
				&-item{
					// &:last-child{
					// 	a{
					// 		margin-bottom: 0;
					// 	}
					// }
					&:hover{
						a{
							&::after{
								right: -5px;
							}
						}
						.gheader__submenu-list2{
							opacity: 1;
							visibility: visible;	
							transform: translateX(0);
						}
						.gheader__submenu-link{
							color: $color-main;
							opacity: 1;
						}
					}
					@include media-breakpoint-down(sm) {
						.sp-aclink{
							&::before{
								top: 42%;
							}
							&::after{
								top: 42%;
							}
						}
					}

				}
				&-link{
					font-weight: bold;
					display: block;
					border-bottom: 1px solid $color-gray-light;
					margin-bottom: 10px;
					padding-bottom: 10px;
					position: relative;
					padding-right: 20px;
					line-height: 2!important;
					@include media-breakpoint-down(sm) {
						padding-right: 24px;
					}
					&::after{
						content: "";
						background-image: url("/img/common/arrow_next_black.svg");
						background-repeat: no-repeat;
						width: 20px;
						height: 10px;
						display: block;
						position: absolute;
						right: 0px;
						top: 50%;
						transform: translateY(-50%);
						transition: .3s;
					}
					&.no-link{
						pointer-events: none;
					}

					&.sp-submenu{
						
					}
				}
				&-list2{
					background: $color-gray-light2;
					padding: 15px 20px;
					width: 47%;
					bottom: 0px;
					right: 20px;
					top: 35px;
					position: absolute;
					overflow-y: auto;
					overflow-x: hidden;
					max-height: 565px;
					height: 565px;
					opacity: 0;
					visibility: hidden;
					transition: .3s;
					-ms-overflow-style: none;
					scrollbar-width: none;
					transform: translateX(-10px);
					&::-webkit-scrollbar{
						display:none;
					}
					@include media-breakpoint-down(sm) {
						display: none;
					}
				}
				&-link2{
					display: block;
					border-bottom: 1px solid $color-gray-light;
					margin-bottom: 10px;
					padding-bottom: 10px;
					line-height: 2!important;
					@include media-breakpoint-down(sm) {
						display: none;
					}
				}
			}
			
		} 
		
	}


		&__spmenu{
			display: none;
			@include media-breakpoint-down(sm) {
				display: flex;
				justify-content: center;
				align-items: flex-end;
				position: fixed;
				bottom: 0;
				background-color: $color-main;
				color: $color-white;
				z-index : 9999;
				width: 100%;
				height: 60px;
			}
			&.en{
				.gheader__spmenu-item{
					width: 138px;
					border: none;
					a{
						width: 100px;
						text-align: center;
						display: flex;
						align-items: center;
						justify-content: center;
						font-size: 15px;
					}
				}
				.gheader__common-icon{
					margin: 0 5px 0 0;
				}
			}
			&-item{
				@include media-breakpoint-down(sm) {
					&:first-child{
						border-right: 1px solid $color-white;
						// padding-right: 13px;
					}
					&:last-child{
						border-left: 1px solid $color-white;
						// padding-left: 13px;
					}
					&:nth-child(2){
						// padding-left: 13px;
					}
					&:nth-child(4){
						// padding-right: 13px;
					}
					height: 100%;
					display: flex;
					align-items: center;
					justify-content: center;
					width: 72px;
					padding-top: 5px;
					a{
						font-size: 10px;
						white-space: nowrap;
						text-decoration: none;
					}
				}
			}
		}

		&__submenu{
			&-item{
				.gheader__megamenu-link{
					@include media-breakpoint-down(sm) {
						margin-bottom: 10px!important;
						padding-top: 0!important;
						padding-left: 0!important;
						// padding-right: 0!important;
						padding-bottom: 10px!important;
					}
				}
				.gheader__submenu-list2{
					@include media-breakpoint-down(sm) {
						display: block!important;
						position: static!important;
						padding: 5px 0 0!important;
						margin: 0!important;
						width: auto!important;
						height: auto!important;
						opacity: 1!important;
						visibility: visible!important;
						transform: none!important;
						.gheader__submenu-link2{
							display: block!important;
						}
					}
				}
			}
		}
}


.sp_graymenu{
	background: $color-gray-light2;
	.gheader__megamenu-link{
		&.link{
			&::after{
				right: 2px!important;
			}
		}
	}
	.gheader__submenu-list{
		background: $color-main;
		.gheader__submenu-link{
			color: $color-white;
			&::after{
				background-image: url(/img/common/arrow_next_white2.svg);
			}
		}
	}
}

.sp_bottom-item{
	display: flex;
	justify-content: center;
	margin-top: 30px;
	.gheader__common-icon{
		display: inline;
		margin-right: 5px;
	}
	span{
		width: 2px;
		height: 20px;
		background-color: $color-gray-light;
		display: block;
		margin-right: 30px;
		margin-left: 40px;
	}
	a{
		&:first-child{
			margin-left: 38px;
		}
	}
	&.en{
		a{
			margin-left: 0;
		}
	}
}

.sp_sns{
	.gfooter__sns{
		margin-top: 40px;
		a{
			&::after{
				content: none!important;
			}
		}
	}
}

.sp_search{
	margin-top: 30px;
	.search-content{
		display: flex;
	}
	input{
		width: 100%;
		border: 2px solid $color-main;
		font-size: 16px;
		outline: none;
		&[type="text"]:focus {
			border-radius: none;
		  }
	}
	button{
		width: 40px;
		flex: 0 0 40px;
		height: 40px;
		background: $color-main;
		border: none;
		img{
			width: 20px!important;
			height: 20px!important;
		}
	}
}



/*　ハンバーガーボタン　*/
.hamburger {
	display : block;
	position: relative;
	width: 70px;
	height: 70px;
	cursor: pointer;
	text-align: center;
	background-color: $color-black;
	border-radius: 50%;
	bottom: -10px;
	margin: 0 5px;
	&::before{
		content: "";
		background-color: $color-main;
		border: 2px solid $color-white;
		display: block;
		width: 90px;
		height: 90px;
		top: 50%;
		left: 50%;
		transform: translate(-50%,-50%);
		z-index: -1;
		position: absolute;
		border-radius: 50%;
	}
  }
  .hamburger span {
	display : block;
	position: absolute;
	width   : 27px;
	height  : 2px ;
	// left    : 22px;
	left: 50%;
	transform: translateX(-50%);
	z-index : 3;
	background : #fff;
	-webkit-transition: 0.3s ease-in-out;
	-moz-transition   : 0.3s ease-in-out;
	transition        : 0.3s ease-in-out;
  }
  .hamburger span:nth-child(1) {
	top: 20px;
  }
  .hamburger span:nth-child(2) {
	top: 30px;
  }
  .hamburger span:nth-child(3) {
	top: 40px;
  }
  
  /* ナビ開いてる時のボタン */
  .hamburger.active span:nth-child(1) {
	top : 30px;
	background :#fff;
	-webkit-transform: rotate(-45deg);
	-moz-transform   : rotate(-45deg);
	transform        : translateX(-50%)rotate(-45deg);
  }
  
  .hamburger.active span:nth-child(2),
  .hamburger.active span:nth-child(3) {
	top : 30px;
	background :#fff;
	-webkit-transform: rotate(45deg);
	-moz-transform   : rotate(45deg);
	transform        : translateX(-50%)rotate(45deg);
  }

