@charset 'utf-8';
.gnav {
	// @include media-breakpoint-up(sm) {
	// }
	// @include media-breakpoint-up(md) {
	// }
	// @include media-breakpoint-up(lg) {
	// }
	// @include media-breakpoint-up(xl) {
	// }
	position: absolute;
	top: calc(100vh - 390px);
	right: 20px;
	@include media-breakpoint-down(sm) {
		display: none;
	}
	//画面の高さが1100px超えている時
	&.heightover{
		position: absolute;
		top: 750px;
	}
	&__inner{
		display: flex;
	}
	&__title{
		color: $color-white;
		@include ff-en();
		@include fz(2.6rem);
		-ms-writing-mode: tb-rl;
		writing-mode: vertical-rl;
		border-right: 1px solid $color-white;
		text-shadow: 2px 1px 2px rgba(0,0,0,0.6);
	}
	&__common{
		color: $color-white;
		padding-left: 20px;
		padding-bottom: 20px;
		&-item{
			margin-bottom: 5px;
			text-shadow: 2px 1px 2px rgba(0,0,0,0.6);
		}
	}
}
